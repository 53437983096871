import { createAction, props } from '@ngrx/store';
import {
  AssignSalesSector,
  UnassignedSalesSector,
} from '~/app/states/main/sales/profile/types/sales-sectors.types';
import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';

export const loadSalesSectors = createAction(
  '[Sales Sector Jobs] Load Sales Sectors',
  props<{ salesId: string }>()
);
export const loadSalesSectorsSuccess = createAction(
  '[Sales Sector Jobs] Load Sales Sectors Success',
  props<{ salesSectors: SectorJobs[] }>()
);
export const loadSalesSectorsFailure = createAction(
  '[Sales Sector Jobs] Load Sales Sectors Failure',
  props<{ error: string }>()
);

export const unassignSalesSector = createAction(
  '[Sales Sector Jobs] Unassign Company Sector Job',
  props<{ salesId: string; salesSector: UnassignedSalesSector }>()
);
export const unassignSalesSectorSuccess = createAction(
  '[Sales Sector Jobs] Unassign Company Sector Job Success',
  props<{ salesSectors: SectorJobs[] }>()
);
export const unassignSalesSectorFailure = createAction(
  '[Sales Sector Jobs] Unassign Company Sector Job Failure',
  props<{ error: string }>()
);

export const assignSalesSectorJob = createAction(
  '[Sales Sector Jobs] Assign Sales Sector Job',
  props<{ salesId: string; salesSector: AssignSalesSector }>()
);
export const assignSalesSectorJobSuccess = createAction(
  '[Sales Sector Jobs] Assign Sales Sector Job Success',
  props<{ salesSectors: SectorJobs[] }>()
);
export const assignSalesSectorJobFailure = createAction(
  '[Sales Sector Jobs] Assign Sales Sector Job Failure',
  props<{ error: string }>()
);

export const salesSectorJobsUnauthorized = createAction(
  '[Sales Sector Jobs] Unauthorized',
  props<{ error: string }>()
);
