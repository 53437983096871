import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/core/state/app.state';
import * as LoginActions from '~/app/auth/state/login/login.actions';

/**
 * Guard that handles successful login by extracting user data from the URL,
 * dispatching a login success action to the store, and allowing route activation.
 *
 * @returns {boolean} Always returns true to allow route activation. TER corre
 */
export const loginSuccessGuard: CanActivateFn = (): boolean => {
  const store = inject(Store<AppState>);

  const user = JSON.parse(atob(window.location.href.split('?user=')[1]));
  store.dispatch(LoginActions.loginSuccess({ userData: user }));

  return true;
};
