<input
  *ngIf="native; else unNative"
  type="checkbox"
  (click)="onClick.emit($event)"
  [checked]="checked"
  [disabled]="disabled" />
<ng-template #unNative>
  <div
    *ngIf="custom; else prime"
    (click)="onClick.emit($event)"
    [class]="checked ? 'ice-checkbox checked' : 'ice-checkbox'">
    <i *ngIf="checked" class="pi pi-check"></i>
  </div>

  <ng-template #prime>
    <p-checkbox
      (onChange)="onChange.emit($event)"
      [binary]="binary"
      [ngModel]="checked"></p-checkbox>
  </ng-template>
</ng-template>
