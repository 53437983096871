import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanColumnsActions from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.actions';
import { KanbanColumn } from '~/app/shared/interfaces/kanban/kanban-column.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';
import { KanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.state';

/**
 * `KanbanColumnsEffects` manages the side effects for kanban column-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban columns,
 * such as loading, creating, updating, deleting columns, and updating column sequences.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests,
 * and displaying notifications.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanColumnsEffects {
  /**
   * Effect to load all kanban columns for a given kanban ID.
   * Listens for the `loadAllKanbanColumns` action, checks permissions, and performs an HTTP GET request to fetch the columns.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbanColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanColumnsActions.loadAllKanbanColumns),
      checkPermission(KanbanColumnsActions.kanbanColumnsUnauthorized),
      mergeMap(({ filters, reloadAll }) =>
        this.http
          .get<KanbanColumnsState>(`${environment.apiUrl}/v1/kanbans/columns`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanbanColumns =>
              KanbanColumnsActions.loadAllKanbanColumnsSuccess({
                kanbanColumns,
                reloadAll,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanColumnsActions.loadAllKanbanColumnsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban column.
   * Listens for the `createKanbanColumn` action, checks permissions, and performs an HTTP POST request to create the column.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanColumnsActions.createKanbanColumn),
      checkPermission(KanbanColumnsActions.kanbanColumnsUnauthorized),
      mergeMap(({ filters, columnData }) =>
        this.http
          .post<KanbanColumn>(
            `${environment.apiUrl}/v1/kanbans/columns`,
            columnData,
            { params: { ...filters }, withCredentials: true }
          )
          .pipe(
            take(1),
            map(column =>
              KanbanColumnsActions.createKanbanColumnSuccess({
                column,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanColumnsActions.createKanbanColumnFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new kanban column.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  createKanbanColumnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanColumnsActions.createKanbanColumnSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Kanban column created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing kanban column.
   * Listens for the `updateKanbanColumn` action, checks permissions, and performs an HTTP PATCH request to update the column.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanColumnsActions.updateKanbanColumn),
      checkPermission(KanbanColumnsActions.kanbanColumnsUnauthorized),
      mergeMap(({ uuid, columnData }) =>
        this.http
          .patch<KanbanColumn>(
            `${environment.apiUrl}/v1/kanbans/columns/${uuid}`,
            columnData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanColumn =>
              KanbanColumnsActions.updateKanbanColumnSuccess({
                kanbanColumn,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanColumnsActions.updateKanbanColumnFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a kanban column.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  updateKanbanColumnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanColumnsActions.updateKanbanColumnSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Kanban column updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete an existing kanban column.
   * Listens for the `deleteKanbanColumn` action, checks permissions, and performs an HTTP DELETE request to delete the column.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanColumnsActions.deleteKanbanColumn),
      checkPermission(KanbanColumnsActions.kanbanColumnsUnauthorized),
      mergeMap(({ uuid, kanbanId }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/kanbans/columns/${uuid}`, {
            body: { kanbanId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => KanbanColumnsActions.deleteKanbanColumnSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanColumnsActions.deleteKanbanColumnFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban column.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteKanbanColumnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanColumnsActions.deleteKanbanColumnSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Kanban column deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the sequences of kanban columns.
   * Listens for the `updateKanbanColumnSequences` action, checks permissions, and performs an HTTP PATCH request to update the column sequences.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanColumnSequences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanColumnsActions.updateKanbanColumnSequences),
      checkPermission(KanbanColumnsActions.kanbanColumnsUnauthorized),
      mergeMap(({ filters, columnSequencesData, oldSequence }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/columns/sequences`,
            columnSequencesData,
            { params: { ...filters }, withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanColumnsActions.updateKanbanColumnSequencesSuccess({
                columnSequencesData,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanColumnsActions.updateKanbanColumnSequencesFailure({
                  error,
                  oldSequence,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating kanban column sequences.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  updateKanbanColumnSequenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanColumnsActions.updateKanbanColumnSequences),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Kanban column sequence updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `KanbanColumnsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
