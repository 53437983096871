import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that filters an array of objects based on a specified attribute's value.
 * This is particularly useful for dynamically filtering lists based on user input or other runtime conditions,
 * such as filtering a list of files or items by type.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'filterFiles', enabling its use within Angular templates
 * to apply dynamic filters to arrays of objects.
 */
@Pipe({
  name: 'filterFiles',
})
export class FilterFilesPipe implements PipeTransform {
  /**
   * Transforms an array of generic objects by filtering out items that do not match the specified type in the specified attribute.
   *
   * @param {T[]} list - The array of generic objects to filter.
   * @param {string} type - The value to filter by.
   * @param {string} [attr='type'] - The attribute within each object to compare against the type. Defaults to 'type'.
   * @returns {T[]} - An array containing only objects where the specified attribute matches the specified type.
   */
  transform<T extends { [key: string]: any }>(
    list: T[],
    type: string,
    attr = 'type'
  ): T[] {
    return list.filter(item => item[attr] === type);
  }
}
