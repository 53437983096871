import { createAction, props } from '@ngrx/store';
import {
  CreateTag,
  UpdateTag,
} from '~/app/states/main/settings/types/tags.types';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

export const loadSalesTags = createAction('[Sales Tags] Load Sales Tags');
export const loadSalesTagsSuccess = createAction(
  '[Sales Tags] Load Sales Tags Success',
  props<{ salesTags: Tag[] }>()
);
export const loadSalesTagsFailure = createAction(
  '[Sales Tags] Load Sales Tags Failure',
  props<{ error: string }>()
);

export const createSalesTag = createAction(
  '[Sales Tags] Create Sales Tag',
  props<{ salesTagData: CreateTag }>()
);
export const createSalesTagSuccess = createAction(
  '[Sales Tags] Create Sales Tag Success',
  props<{ salesTag: Tag }>()
);
export const createSalesTagFailure = createAction(
  '[Sales Tags] Create Sales Tag Failure',
  props<{ error: string }>()
);

export const updateSalesTag = createAction(
  '[Sales Tags] Update Sales Tag',
  props<{ uuid: string; salesTagData: UpdateTag }>()
);
export const updateSalesTagSuccess = createAction(
  '[Sales Tags] Update Sales Tag Success',
  props<{ salesTag: Tag }>()
);
export const updateSalesTagFailure = createAction(
  '[Sales Tags] Update Sales Tag Failure',
  props<{ error: string }>()
);

export const deleteSalesTag = createAction(
  '[Sales Tags] Delete Sales Tag',
  props<{ uuid: string }>()
);
export const deleteSalesTagSuccess = createAction(
  '[Sales Tags] Delete Sales Tag Success',
  props<{ uuid: string }>()
);
export const deleteSalesTagFailure = createAction(
  '[Sales Tags] Delete Sales Tag Failure',
  props<{ error: string }>()
);

export const salesTagUnauthorized = createAction(
  '[Sales Tags] Unauthorized',
  props<{ error: string }>()
);
