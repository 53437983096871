import { createReducer, on } from '@ngrx/store';
import { initialKanbanTasksState } from './kanban-card-tasks.state';
import * as KanbanCardTasksActions from '~/app/states/main/kanban/states/kanban-card-tasks/kanban-card-tasks.actions';

export const kanbanCardTasksReducer = createReducer(
  initialKanbanTasksState,

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/
  on(KanbanCardTasksActions.loadAllKanbanCardTasks, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardTasksActions.loadAllKanbanCardTasksSuccess,
    (state, { kanbanTasks }) => ({
      ...state,
      tasks: kanbanTasks,
      loading: false,
    })
  ),
  on(
    KanbanCardTasksActions.loadAllKanbanCardTasksFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/
  on(KanbanCardTasksActions.createKanbanCardTask, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardTasksActions.createKanbanCardTaskSuccess,
    KanbanCardTasksActions.createKanbanCardTaskServer,
    (state, { kanbanTask }) => ({
      ...state,
      tasks: [...state.tasks, kanbanTask],
      loading: false,
    })
  ),
  on(
    KanbanCardTasksActions.createKanbanCardTaskFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardTasksActions.updateKanbanCardTask, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardTasksActions.updateKanbanCardTaskSuccess,
    KanbanCardTasksActions.updateKanbanCardTaskServer,
    (state, { kanbanTask }) => ({
      ...state,
      tasks: state.tasks.map(task =>
        task.uuid === kanbanTask.uuid ? kanbanTask : task
      ),
      loading: false,
    })
  ),
  on(
    KanbanCardTasksActions.updateKanbanCardTaskFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardTasksActions.deleteKanbanCardTask, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardTasksActions.deleteKanbanCardTaskSuccess,
    KanbanCardTasksActions.deleteKanbanCardTaskServer,
    (state, { uuid }) => ({
      ...state,
      tasks: state.tasks.filter(task => task.uuid !== uuid),
      loading: false,
    })
  ),
  on(
    KanbanCardTasksActions.deleteKanbanCardTaskFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * UN/CHECKED
   * ************************************************************************
   **/
  on(KanbanCardTasksActions.checkKanbanCardTask, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbanCardTasksActions.checkKanbanCardTaskFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardTasksActions.uncheckKanbanCardTask, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardTasksActions.uncheckKanbanCardTaskFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/
  on(
    KanbanCardTasksActions.checkKanbanCardTaskServer,
    (state, { kanbanTask }) => ({
      ...state,
      tasks: state.tasks.map(task =>
        task.uuid === kanbanTask.uuid ? kanbanTask : task
      ),
      loading: false,
    })
  ),

  on(
    KanbanCardTasksActions.uncheckKanbanCardTaskServer,
    (state, { kanbanTask }) => ({
      ...state,
      tasks: state.tasks.map(task =>
        task.uuid === kanbanTask.uuid ? kanbanTask : task
      ),
      loading: false,
    })
  ),

  /**
   * ************************************************************************
   * UNAUTHORIZED
   * ************************************************************************
   **/
  on(
    KanbanCardTasksActions.kanbanCardTasksUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
