import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

/**
 * Custom validator function to check if the password and confirmPassword fields in a form group match.
 * It is intended to be used within a FormGroup context where both fields are present.
 *
 * @param {AbstractControl} control - The control structure (typically a FormGroup) that contains the password fields.
 * @returns {ValidationErrors | null} - Returns a ValidationErrors object with `passwordMismatch` set to true if the validation fails,
 *                                      or null if the validation passes, indicating no error.
 *
 * @example
 * Example Usage:
 * const myFormGroup = new FormGroup({
 *   password: new FormControl(''),
 *   confirmPassword: new FormControl('')
 * }, { validators: passwordMatchValidator });
 *
 * // Now `myFormGroup.errors` will be either null if passwords match, or `{ passwordMismatch: true }` if they do not.
 */
export const passwordMatchValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const formGroup = control as FormGroup;
  const password = formGroup.get('password')?.value;
  const confirmPassword = formGroup.get('confirmPassword')?.value;

  return password === confirmPassword ? null : { passwordMismatch: true };
};
