import { createAction, props } from '@ngrx/store';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

export const loadProfileTags = createAction('[Profile Tags] Load Profile Tags');
export const loadProfileTagsSuccess = createAction(
  '[Profile Tags] Load Profile Tags Success',
  props<{ tags: Tag[] }>()
);
export const loadProfileTagsFailure = createAction(
  '[Profile Tags] Load Profile Tags Failure',
  props<{ error: string }>()
);

export const profileTagsUnauthorized = createAction(
  '[Profile Tags] Unauthorized',
  props<{ error: string }>()
);
