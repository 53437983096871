<div
  class="surface-card p-4 shadow-2 border-round min-w-20rem max-w-30rem bg-cover">
  <app-section-heading
    title="Root user"
    i18n-title
    subtitle="Enter your root user details"
    i18n-subtitle></app-section-heading>
  <form [formGroup]="rootUserForm">
    <div class="grid">
      <ice-input
        class="col-12 sm:col-6"
        [isSubmitted]="submitted"
        [label]="'Firstname'"
        [placeholder]="'Firstname'"
        [formControlName]="'firstname'"
        [formControl]="firstname"
        [required]="true"></ice-input>
      <ice-input
        class="col-12 sm:col-6"
        [isSubmitted]="submitted"
        [label]="'Lastname'"
        [placeholder]="'Lastname'"
        [formControlName]="'lastname'"
        [formControl]="lastname"
        [required]="true"></ice-input>
      <div class="flex col-12 gap-3">
        <ice-phone-iso
          [isSubmitted]="submitted"
          [label]="'iso'"
          [placeholder]="'iso'"
          [required]="true"
          [formControlName]="'phoneIso'"
          [formControl]="phoneIso">
        </ice-phone-iso>
        <ice-phone-number
          class="w-full"
          [isSubmitted]="submitted"
          [label]="'phone'"
          [placeholder]="'Phone'"
          [formControlName]="'phone'"
          [formControl]="phone"
          [iso]="phoneIso.value"
          [required]="true"></ice-phone-number>
      </div>

      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Email'"
        [placeholder]="'Email'"
        [formControlName]="'email'"
        [formControl]="email"
        [required]="true"></ice-input>
      <ice-password
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Password'"
        [placeholder]="'Password'"
        [formControlName]="'password'"
        [formControl]="password"
        [required]="true"></ice-password>
    </div>
  </form>
  <div class="flex justify-content-between">
    <ice-button
      label="Previous"
      i18n-label
      [text]="true"
      icon="pi pi-arrow-left"
      i18n-icon
      routerLink="/register/office"
      iconPos="left"
      i18n-iconPos></ice-button>

    <ice-button
      label="Register"
      i18n-label
      icon="pi pi-arrow-right"
      i18n-icon
      iconPos="right"
      i18n-iconPos
      (onClick)="onRegister()"></ice-button>
  </div>
</div>
