import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { registerUserValidation } from '@npmicedev/icemodule/lib/validations/users';
import { ZodObject } from 'zod';
import * as RegisterAction from '~/app/auth/state/register/register.actions';
import { selectCompanyFormData } from '~/app/auth/state/register/register.selectors';
import { AppState } from '~/app/core/state/app.state';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { UtilsService } from '~/app/shared/services/utils.service';

/**
 * @Component CompanyStepComponent
 * Manages the company registration step in a multi-step registration process.
 * This component provides a form to input company details such as name, type, subdomain, website, and email.
 * It validates input using Zod and Angular validators and saves the data into the application state.
 *
 * @selector 'app-company-step' - CSS selector identifying this component within Angular templates.
 * @templateUrl './company-step.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./company-step.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-company-step',
  templateUrl: './company-step.component.html',
  styleUrls: ['./company-step.component.scss'],
})
export class CompanyStepComponent implements OnInit {
  /**
   * FormGroup for managing and validating the company details form.
   * @type {FormGroup}
   */
  companyForm!: FormGroup;

  /**
   * Flag to check if the form has been submitted to display validation messages.
   * @type {boolean}
   */
  submitted = false;

  /**
   * Data for rendering radio buttons for selecting company type.
   * @type {Array<{label: string, value: string}>}
   */
  radioButtonData = [
    { label: 'Company', value: 'company' },
    { label: 'Consulting', value: 'consulting' },
  ];

  /**
   * Constructs the CompanyStepComponent with necessary dependencies for handling form creation,
   * navigation, state management, utility functions, and displaying notifications.
   *
   * @param {FormBuilder} formBuilder - Provides the API to construct new FormGroup instances used to define the form structure
   * with default values and validators.
   * @param {Router} router - Manages navigation and URL manipulation capabilities. It allows the component to navigate to
   * other routes based on form actions.
   * @param {Store<AppState>} store - Angular Redux store service for managing the state of the app. It is used to dispatch actions
   * to update the global state and to select slices of the state.
   * @param {UtilsService} utils - A service that provides utility functions which can be used across the component, such as
   * filtering form values before submission to ensure they meet business rules.
   * @param {CustomToastService} toast - Service for displaying toast notifications. This service helps to inform the user of the
   * results of their actions, such as successful data submission or errors during form validation.
   */
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private utils: UtilsService,
    private toast: CustomToastService
  ) {}

  /**
   * Getter for accessing the 'name' FormControl in the form group.
   * @type {FormControl}
   */
  get name(): FormControl {
    return this.companyForm.get('name') as FormControl;
  }

  /**
   * Getter for accessing the 'type' FormControl in the form group.
   * @type {FormControl}
   */
  get type(): FormControl {
    return this.companyForm.get('type') as FormControl;
  }

  /**
   * Getter for accessing the 'subdomain' FormControl in the form group.
   * @type {FormControl}
   */
  get subdomain(): FormControl {
    return this.companyForm.get('subdomain') as FormControl;
  }

  /**
   * Getter for accessing the 'website' FormControl in the form group.
   * @type {FormControl}
   */
  get website(): FormControl {
    return this.companyForm.get('website') as FormControl;
  }

  /**
   * Getter for accessing the 'email' FormControl in the form group.
   * @type {FormControl}
   */
  get email(): FormControl {
    return this.companyForm.get('email') as FormControl;
  }

  /**
   * Lifecycle hook that initializes the form on component initialization.
   * @return {void}
   */
  ngOnInit(): void {
    this.initCompanyForm();
  }

  /**
   * Initializes the company form with default values and validators.
   * @return {void}
   */
  initCompanyForm(): void {
    this.companyForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
        ],
      ],
      subdomain: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
          //this.lowercaseValidator(),
        ],
      ],
      website: [null, [Validators.maxLength(255)]],
      email: [null, [Validators.email, Validators.maxLength(100)]],
      type: [null, [Validators.required]], // Go pour un custom validator
    });

    this.store.select(selectCompanyFormData).subscribe(companyData => {
      if (companyData) {
        this.companyForm.patchValue(companyData);
      }
    });
  }

  /**
   * Handles the 'Next' button click to validate the form, save the data into the state,
   * and navigate to the next registration step.
   * @return {void}
   */
  onNext(): void {
    this.submitted = true;

    const companySchema = (registerUserValidation.body as ZodObject<any>).shape
      .company;

    const result = companySchema.safeParse(
      this.utils.filterValues(this.companyForm.value)
    );

    if (result?.success) {
      this.store.dispatch(
        RegisterAction.updateCompanyForm({
          companyData: this.companyForm.value,
        })
      );

      void this.router.navigate(['/register/office']);
      return;
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
