import { createReducer, on } from '@ngrx/store';
import { initialCandidateTagsState } from '~/app/states/main/settings/states/tags/candidate-tags-state/candidate-tags.state';
import * as CandidateTagActions from '~/app/states/main/settings/states/tags/candidate-tags-state/candidate-tags.actions';

export const candidateTagsReducer = createReducer(
  initialCandidateTagsState,

  on(CandidateTagActions.loadCandidateTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateTagActions.loadCandidateTagsSuccess,
    (state, { candidateTags }) => ({
      ...state,
      candidateTags,
      loading: false,
    })
  ),
  on(CandidateTagActions.loadCandidateTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(CandidateTagActions.createCandidateTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateTagActions.createCandidateTagSuccess,
    (state, { candidateTag }) => ({
      ...state,
      candidateTags: [...state.candidateTags, candidateTag],
      loading: false,
    })
  ),
  on(CandidateTagActions.createCandidateTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(CandidateTagActions.updateCandidateTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateTagActions.updateCandidateTagSuccess,
    (state, { candidateTag }) => ({
      ...state,
      candidateTags: state.candidateTags.map(tag =>
        tag.uuid === candidateTag.uuid ? { ...tag, ...candidateTag } : tag
      ),
      loading: false,
    })
  ),
  on(CandidateTagActions.updateCandidateTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(CandidateTagActions.deleteCandidateTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CandidateTagActions.deleteCandidateTagSuccess, (state, { uuid }) => ({
    ...state,
    candidateTags: state.candidateTags.filter(tag => tag.uuid !== uuid),
    loading: false,
  })),
  on(CandidateTagActions.deleteCandidateTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(CandidateTagActions.candidateTagUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
