import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import * as HomeTeamsActions from '~/app/states/main/crm/home/states/home-teams/home-teams.actions';
import { environment } from '~/environments/environment';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

/**
 * `HomeTeamsEffects` orchestrates the handling of asynchronous operations related to home teams in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to home teams, such as fetching data from the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadHomeTeam$`: Fetches home teams from the server.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class HomeTeamsEffects {
  /**
   * Effect to load home teams.
   */
  loadHomeTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeTeamsActions.loadHomeTeamsAction),
      checkPermission(HomeTeamsActions.homeTeamUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            PaginationData<Team>
          >(`${environment.apiUrl}/v1/teams`, { withCredentials: true })
          .pipe(
            take(1),
            map(({ data }) =>
              HomeTeamsActions.loadHomeTeamsActionSuccess({ teams: data })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(HomeTeamsActions.loadHomeTeamsActionFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for HomeTeamsEffects.
   *
   * @param {Actions} actions$ — The stream of actions observed by this effect.
   * @param {HttpClient} http — The HTTP client used for making API calls.
   * @param {HttpErrorsService} httpErrors — Service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
