import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Sales } from '@npmicedev/icemodule/lib/entities/Sales';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ProjectActions from './project.actions';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProjectEffects` handles the side effects related to project operations in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading project details, users, and sales from the server.
 *
 * Effects included in this class:
 * — `loadProject$`: Fetches a project by ID from the server.
 * — `loadProjectUsers$`: Fetches the users associated with a project.
 * — `loadProjectSales$`: Fetches the sales information for a project.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProjectEffects {
  /**
   * Effect to load a project by ID.
   */
  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Project>(`${environment.apiUrl}/v1/projects/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(project => ProjectActions.loadProjectSuccess({ project })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.loadProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load the users associated with a project.
   */
  loadProjectUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjectUsers),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<User[]>(`${environment.apiUrl}/v1/projects/${uuid}/users`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(users => ProjectActions.loadProjectUsersSuccess({ users })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.loadProjectUsersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load the sales information for a project.
   */
  loadProjectSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjectSales),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Sales[]>(`${environment.apiUrl}/v1/projects/${uuid}/sales`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sales => ProjectActions.loadProjectSalesSuccess({ sales })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.loadProjectSalesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to assign a user to a project.
   */
  assignUserToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.assignUserToProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ projectId, userId }) =>
        this.http
          .put<User[]>(
            `${environment.apiUrl}/v1/projects/${projectId}/users/${userId}`,
            {},
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(users =>
              ProjectActions.assignUserToProjectSuccess({
                projectId,
                users,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.assignUserToProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful assignment of a user to a project.
   */
  assignUserToProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.assignUserToProjectSuccess),
        tap(() => {
          this.toastService.successToast(
            'Success',
            'User assigned to project successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a user from a project.
   */
  unassignUserFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.unassignUserFromProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ projectId, userId }) =>
        this.http
          .delete<User[]>(
            `${environment.apiUrl}/v1/projects/${projectId}/users/${userId}`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(users =>
              ProjectActions.unassignUserFromProjectSuccess({
                projectId,
                users,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProjectActions.unassignUserFromProjectFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful unassignment of a user from a project.
   */
  unassignUserFromProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.unassignUserFromProjectSuccess),
        tap(() => {
          this.toastService.successToast(
            'Success',
            'User unassigned from project successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a sale to a project.
   */
  assignSaleToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.assignSaleToProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ projectId, saleId }) =>
        this.http
          .put<Sales[]>(
            `${environment.apiUrl}/v1/projects/${projectId}/sales/${saleId}`,
            {},
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(sales =>
              ProjectActions.assignSaleToProjectSuccess({
                projectId,
                sales,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.assignSaleToProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful assignment of a sale to a project.
   */
  assignSaleToProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.assignSaleToProjectSuccess),
        tap(() => {
          this.toastService.successToast(
            'Success',
            'Sale assigned to project successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a sale from a project.
   */
  unassignSaleFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.unassignSaleFromProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ projectId, saleId }) =>
        this.http
          .delete<Sales[]>(
            `${environment.apiUrl}/v1/projects/${projectId}/sales/${saleId}`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(sales =>
              ProjectActions.unassignSaleFromProjectSuccess({
                projectId,
                sales,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProjectActions.unassignSaleFromProjectFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful unassignment of a sale from a project.
   */
  unassignSaleFromProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.unassignSaleFromProjectSuccess),
        tap(() => {
          this.toastService.successToast(
            'Success',
            'Sale unassigned from project successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign sales to projects. It listens for the `assignSalesToProjects` action, checks permissions,
   * and fetches data from the server based on provided filter criteria. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  assignSalesToProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.assignSalesToProject),
      checkPermission(ProjectActions.projectUnauthorized),
      mergeMap(({ projectId, filters }) =>
        this.http
          .put<
            Sales[]
          >(`${environment.apiUrl}/v1/projects/${projectId}/sales/bulk`, filters, { withCredentials: true })
          .pipe(
            take(1),
            map(sales => {
              return ProjectActions.assignSalesToProjectSuccess({ sales });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectActions.assignSalesToProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect after a success to assign sales to projects. It listens for the `assignSalesToProjectsSuccess` action, send a toast and close the side bar.
   */
  assignSalesToProjectsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.assignSalesToProjectSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales assigned to the project.');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProjectEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {CustomToastService} toastService - Injects the CustomToastService.
   * @param {SidenavFormService} sidebarService - Injects the SidenavFormService.
   * @param {CustomToastService} toast Injected service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toastService: CustomToastService,
    public sidebarService: SidenavFormService,
    private toast: CustomToastService
  ) {}
}
