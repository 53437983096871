import { Pipe, PipeTransform } from '@angular/core';

/**
 * `TimeDifferencePipe` is an Angular pipe that calculates the time difference between a given date and the current date,
 * and returns a human-readable string representing this difference.
 *
 * @Pipe decorator defines metadata for the pipe:
 * @name 'timeDifference' - The name of the pipe.
 */
@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  /**
   * Transforms a given date string into a human-readable time difference string.
   *
   * @param {string} createdAt - The date string to compare with the current date.
   * @returns {string} A human-readable string representing the time difference.
   */
  transform(createdAt: string): string {
    const createdAtDate = new Date(createdAt);
    const diffMs = Math.abs(createdAtDate.getTime() - new Date().getTime());

    const diffYears = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
    if (diffYears > 0) {
      return `${diffYears} year${diffYears > 1 ? 's' : ''}`;
    }

    const diffMonths = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));
    if (diffMonths > 0) {
      return `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
    }

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
    }

    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
    }

    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    if (diffMinutes > 0) {
      return `${diffMinutes} min`;
    }

    return 'less than a minute';
  }
}
