import { createReducer, on } from '@ngrx/store';
import { initialKanbanTagsState } from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.state';
import * as KanbanTagsActions from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.actions';

export const kanbanTagsReducer = createReducer(
  initialKanbanTagsState,

  on(KanbanTagsActions.loadAllKanbanTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbanTagsActions.loadAllKanbanTagsSuccess, (state, { kanbanTags }) => ({
    ...state,
    kanbanTags,
    loading: false,
  })),
  on(KanbanTagsActions.loadAllKanbanTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanTagsActions.createKanbanTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanTagsActions.createKanbanTagSuccess,
    KanbanTagsActions.createKanbanTagServer,
    (state, { kanbanTag }) => ({
      ...state,
      kanbanTags: [...state.kanbanTags, kanbanTag],
      loading: false,
    })
  ),
  on(KanbanTagsActions.createKanbanTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanTagsActions.updateKanbanTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanTagsActions.updateKanbanTagSuccess,
    (state, { uuid, kanbanTag }) => ({
      ...state,
      kanbanTags: state.kanbanTags.map(item =>
        item.uuid === uuid ? kanbanTag : item
      ),
      loading: false,
    })
  ),
  on(KanbanTagsActions.updateKanbanTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanTagsActions.deleteKanbanTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanTagsActions.deleteKanbanTagSuccess,
    KanbanTagsActions.deleteKanbanTagServer,
    (state, { uuid }) => ({
      ...state,
      kanbanTags: state.kanbanTags.filter(item => item.uuid !== uuid),
      loading: false,
    })
  ),
  on(KanbanTagsActions.deleteKanbanTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanTagsActions.kanbanTagsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
