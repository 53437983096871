import { createAction, props } from '@ngrx/store';
import { UpdateProfileFile } from '~/app/states/main/crm/profile/types/profile-files.types';
import { ProfileFile } from '~/app/shared/interfaces/crm/profile/profile-file.interface';

export const loadProfileFiles = createAction(
  '[Profile Files] Load Profile Files',
  props<{ profileId: string }>()
);
export const loadProfileFilesSuccess = createAction(
  '[Profile Files] Load Profile Files Success',
  props<{ profileFiles: ProfileFile[] }>()
);
export const loadProfileFilesFailure = createAction(
  '[Profile Files] Load Profile Files Failure',
  props<{ error: string }>()
);

export const createProfileFile = createAction(
  '[Profile Files] Create Profile File',
  props<{ profileFileData: FormData }>()
);
export const createProfileFileSuccess = createAction(
  '[Profile Files] Create Profile File Success',
  props<{ profileFile: ProfileFile }>()
);
export const createProfileFileFailure = createAction(
  '[Profile Files] Create Profile File Failure',
  props<{ error: string }>()
);

export const updateProfileFile = createAction(
  '[Profile Files] Update Profile File',
  props<{ uuid: string; profileFileData: UpdateProfileFile }>()
);
export const updateProfileFileSuccess = createAction(
  '[Profile Files] Update Profile File Success',
  props<{ profileFile: ProfileFile }>()
);
export const updateProfileFileFailure = createAction(
  '[Profile Files] Update Profile File Failure',
  props<{ error: string }>()
);

export const deleteProfileFile = createAction(
  '[Profile Files] Delete Profile File',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileFileSuccess = createAction(
  '[Profile Files] Delete Profile File Success',
  props<{ uuid: string }>()
);
export const deleteProfileFileFailure = createAction(
  '[Profile Files] Delete Profile File Failure',
  props<{ error: string }>()
);

export const profileFilesUnauthorized = createAction(
  '[Profile Files] Unauthorized',
  props<{ error: string }>()
);
