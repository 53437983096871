import { createAction, props } from '@ngrx/store';
import { AllSkillsFilters } from '~/app/shared/interfaces/data/all-skills-filters.interface';
import { AllSkillsState } from '~/app/shared/states/skills/skills.state';

export const loadAllSkills = createAction(
  '[All Skills] Load All Skills',
  props<{ filters?: AllSkillsFilters }>()
);
export const loadAllSkillsSuccess = createAction(
  '[All Skills] Load All Skills Success',
  props<{ skills: AllSkillsState }>()
);
export const loadAllSkillsFailure = createAction(
  '[All Skills] Load All Skills Failure',
  props<{ error: string }>()
);

export const loadAllSkillsUnauthorized = createAction(
  '[All Skills] Unauthorized',
  props<{ error: string }>()
);
