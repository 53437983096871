import { Pipe, PipeTransform } from '@angular/core';

/**
 * `RelativeTimePipe` is a custom Angular pipe that transforms a given date
 * into a human-readable relative time string (e.g., "today", "yesterday", "3 days ago").
 *
 * @Pipe decorator registers the pipe with the name 'relativeTime'.
 */
@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  /**
   * Transforms the given date into a relative time string based on the current date.
   *
   * It compares the provided `createdAt` date with the current date, ignoring the time component,
   * to display:
   * - "today" if it’s the same day,
   * - "yesterday" if it was the previous calendar day,
   * - "{n} days ago" if within the last 7 days,
   * - "{n} weeks ago" if within the last month,
   * - "{n} months ago" if within the last year,
   * - "{n} years ago" otherwise.
   *
   * @param {string | Date} createdAt - The creation date to be transformed.
   *
   * @returns {string} - A relative time string describing how long ago the date was.
   *
   * @example
   * // Example usage in a template
   * <span>{{ notification.createdAt | relativeTime }}</span>
   */
  transform(createdAt: string | Date): string {
    const createdAtDate = new Date(createdAt);
    const now = new Date();

    // Remove time components to only compare calendar dates
    const createdAtMidnight = new Date(
      createdAtDate.getFullYear(),
      createdAtDate.getMonth(),
      createdAtDate.getDate()
    );
    const nowMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    const diffMs = nowMidnight.getTime() - createdAtMidnight.getTime();
    const diffDays = Math.floor(diffMs / (24 * 60 * 60 * 1000));

    if (diffDays < 1) return 'today';
    if (diffDays === 1) return 'yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    if (diffDays < 365) return `${Math.floor(diffDays / 30)} months ago`;
    return `${Math.floor(diffDays / 365)} years ago`;
  }
}
