import { Project } from '~/app/shared/interfaces/sales/project/project.interface';

/**
 * Interface representing the state of sales projects.
 *
 * @interface SalesProjectsState
 */
export interface SalesProjectsState {
  /**
   * Array of projects in the sales module.
   *
   * @type {Project[]}
   * @memberof SalesProjectsState
   */
  salesProjects: Project[];

  /**
   * Boolean indicating whether the sales projects are currently loading.
   *
   * @type {boolean}
   * @memberof SalesProjectsState
   */
  loading: boolean;

  /**
   * Error message, if any, related to the sales projects state.
   *
   * @type {string | null}
   * @memberof SalesProjectsState
   */
  error: string | null;
}

/**
 * Initial state for the sales projects.
 *
 * @constant
 * @type {SalesProjectsState}
 */
export const initialSalesProjectsState: SalesProjectsState = {
  salesProjects: [],
  loading: false,
  error: null,
};
