import { createAction, props } from '@ngrx/store';
import { AllSearchOfferProposalsState } from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.state';
import { AllSearchOfferProposalsFilters } from '~/app/shared/interfaces/jobhub/proposal/all-search-offer-proposals-filters.interface';

export const loadAllSearchOfferProposals = createAction(
  '[Search Offers] Load All Search Offers',
  props<{ filters?: AllSearchOfferProposalsFilters }>()
);
export const loadAllSearchOfferProposalsSuccess = createAction(
  '[Search Offers] Load All Search Offers Success',
  props<{ searchOfferProposals: AllSearchOfferProposalsState }>()
);
export const loadAllSearchOfferProposalsFailure = createAction(
  '[Search Offers] Load All Search Offers Failure',
  props<{ error: string }>()
);

export const searchOfferProposalsUnauthorized = createAction(
  '[Search Offers] Unauthorized',
  props<{ error: string }>()
);

export const loadAllSearchOfferProposalsPublic = createAction(
  '[Search Offers] Load All Public Search Offers',
  props<{ filters?: AllSearchOfferProposalsFilters }>()
);
export const loadAllSearchOfferProposalsPublicSuccess = createAction(
  '[Search Offers] Load All Public Search Offers Success',
  props<{ searchOfferProposals: AllSearchOfferProposalsState }>()
);
export const loadAllSearchOfferProposalsPublicFailure = createAction(
  '[Search Offers] Load All Public Search Offers Failure',
  props<{ error: string }>()
);

export const searchOfferProposalsPublicUnauthorized = createAction(
  '[Search Offers] Unauthorized',
  props<{ error: string }>()
);
