import { createAction, props } from '@ngrx/store';
import {
  CreateProfile,
  UpdateProfileById,
} from '~/app/main/crm/profile/types/profile.types';
import { Profile } from '~/app/shared/interfaces/crm/profile/profile.interface';

export const loadProfile = createAction(
  '[Profile] Load Profile',
  props<{ uuid: string }>()
);
export const loadProfileSuccess = createAction(
  '[Profile] Load Profile Success',
  props<{ profile: Profile }>()
);
export const loadProfileFailure = createAction(
  '[Profile] Load Profile Failure',
  props<{ error: string }>()
);

export const createProfile = createAction(
  '[Profile] Create Profile',
  props<{ profileData: CreateProfile }>()
);
export const createProfileSuccess = createAction(
  '[Profile] Create Profile Success',
  props<{ profile: Profile }>()
);
export const createProfileFailure = createAction(
  '[Profile] Create Profile Failure',
  props<{ error: string }>()
);

export const updateProfile = createAction(
  '[Profile] Update Profile',
  props<{ uuid: string; profileData: UpdateProfileById }>()
);
export const updateProfileSuccess = createAction(
  '[Profile] Update Profile Success',
  props<{ profile: Profile }>()
);
export const updateProfileFailure = createAction(
  '[Profile] Update Profile Failure',
  props<{ error: string }>()
);

export const profileUnauthorized = createAction(
  '[Profile] Unauthorized',
  props<{ error: string }>()
);

export const assignProfileTag = createAction(
  '[Profile Tags] Assign Profile Tag',
  props<{ uuid: string; tagId: string }>()
);
export const assignProfileTagSuccess = createAction(
  '[Profile Tags] Assign Profile Tag Success',
  props<{ profile: Profile }>()
);
export const assignProfileTagFailure = createAction(
  '[Profile Tags] Assign Profile Tag Failure',
  props<{ error: string }>()
);

export const unassignProfileTag = createAction(
  '[Profile Tags] Unassign Profile Tag',
  props<{ uuid: string; tagId: string }>()
);
export const unassignProfileTagSuccess = createAction(
  '[Profile Tags] Unassign Profile Tag Success',
  props<{ profile: Profile }>()
);
export const unassignProfileTagFailure = createAction(
  '[Profile Tags] Unassign Profile Tag Failure',
  props<{ error: string }>()
);

export const profileTagsUnauthorized = createAction(
  '[Profile Tags] Unauthorized',
  props<{ error: string }>()
);
