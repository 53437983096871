/**
 * Enum for defining comparison operators.
 * This enum is used to represent different comparison operators for use in various logical conditions and evaluations.
 *
 * @enum {string}
 * @property {string} EQ - Represents the equality comparison operator.
 * @property {string} GT - Represents the greater than comparison operator.
 * @property {string} GTE - Represents the greater than or equal to comparison operator.
 * @property {string} LT - Represents the less than comparison operator.
 * @property {string} LTE - Represents the less than or equal to comparison operator.
 */
export enum ComparisonEnum {
  EQ = 'eq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
}
