import { createReducer, on } from '@ngrx/store';
import * as SalesQuickNavActions from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.actions';
import { initialSalesQuickNavState } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.state';

export const salesQuickNavReducer = createReducer(
  initialSalesQuickNavState,

  on(SalesQuickNavActions.loadSalesUUIDs, (state, { uuids }) => ({
    ...state,
    uuids,
  }))
);
