import { Component, Input } from '@angular/core';

/**
 * Component to display a placeholder when a user does not have the necessary permissions to access a certain part of the application.
 * It can be styled dynamically to match the desired look and feel and is typically used to provide visual feedback for restricted access.
 *
 * @Component decorator defines Angular component metadata:
 * @selector 'app-ice-unauthorized-placeholder' - the component's CSS selector.
 * @templateUrl './ice-unauthorized-placeholder.component.html' - the path to the component's HTML template.
 * @styleUrls ['./ice-unauthorized-placeholder.component.scss'] - the path to the styles for this component.
 */
@Component({
  selector: 'app-ice-unauthorized-placeholder',
  templateUrl: './ice-unauthorized-placeholder.component.html',
  styleUrls: ['./ice-unauthorized-placeholder.component.scss'],
})
export class IceUnauthorizedPlaceholderComponent {
  /**
   * Input property to specify the height of the placeholder element. This can be provided as either a numeric value (interpreted as pixels)
   * or a string that specifies units. This flexibility allows the component to be easily integrated into various layouts.
   * Default value is '200', which applies if no specific height is set.
   *
   * @example
   * <app-ice-unauthorized-placeholder [height]="300"></app-ice-unauthorized-placeholder> // 300 pixels high
   * <app-ice-unauthorized-placeholder [height]="'100%'"></app-ice-unauthorized-placeholder> // 100% of the parent container
   *
   * @type {number | string}
   */
  @Input() height: number | string = '200';
}
