/* eslint-disable max-lines */
import { createReducer, on } from '@ngrx/store';
import { initialKanbanCardsState } from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.state';
import * as KanbanCardsActions from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.actions';
import * as KanbanCardCommentsActions from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.actions';
import * as KanbanCardAttachmentsActions from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.actions';

export const kanbanCardsReducer = createReducer(
  initialKanbanCardsState,

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/
  on(KanbanCardsActions.loadAllKanbanCards, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.loadAllKanbanCardsSuccess,
    (state, { kanbanCards, kanbanColumnId }) => {
      const tmp = {
        ...state.allCards,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        [kanbanColumnId]: state.allCards[kanbanColumnId]
          ? {
              ...kanbanCards,
              data: [
                ...state.allCards[kanbanColumnId].data,
                ...kanbanCards.data,
              ].filter(
                (card, index, cards) =>
                  cards.findIndex(c => c.uuid === card.uuid) === index
              ),
            }
          : kanbanCards,
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
        error: null,
      };
    }
  ),
  on(KanbanCardsActions.loadAllKanbanCardsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/
  on(KanbanCardsActions.createKanbanCard, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.createKanbanCardSuccess,
    KanbanCardsActions.createKanbanCardServer,
    (state, { kanbanCard, kanbanColumnId }) => {
      const tmp = {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          count: state.allCards[kanbanColumnId].count + 1,
          data: [
            ...state.allCards[kanbanColumnId].data.map(card => {
              return { ...card, sequence: card.sequence + 1 };
            }),
            { ...kanbanCard, nbComments: 0, nbAttachments: 0 },
          ],
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
        error: null,
      };
    }
  ),
  on(KanbanCardsActions.createKanbanCardFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.updateKanbanCard, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.updateKanbanCardSuccess,
    KanbanCardsActions.updateKanbanCardServer,
    (state, { kanbanCard, kanbanColumnId, nbAttachments, nbComments }) => {
      const tmp = {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: [
            ...state.allCards[kanbanColumnId].data.filter(
              card => kanbanCard.uuid !== card.uuid
            ),
            {
              ...kanbanCard,
              nbAttachments,
              nbComments,
            },
          ],
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.updateKanbanCardFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.deleteKanbanCard, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.deleteKanbanCardSuccess,
    KanbanCardsActions.deleteKanbanCardServer,
    (state, { uuid, kanbanColumnId }) => {
      const tmp = {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          count: state.allCards[kanbanColumnId].count - 1,
          data: state.allCards[kanbanColumnId].data.filter(
            card => uuid !== card.uuid
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.deleteKanbanCardFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * REORDER CARDS + SWITCH COLUMN
   * ************************************************************************
   **/
  on(
    KanbanCardsActions.updateKanbanCardSequences,
    (state, { kanbanCardSequencesData }) => {
      const tmp = {
        ...state.allCards,
        [kanbanCardSequencesData.kanbanColumnId]: {
          ...state.allCards[kanbanCardSequencesData.kanbanColumnId],
          data: state.allCards[kanbanCardSequencesData.kanbanColumnId].data.map(
            card => {
              const kcard = kanbanCardSequencesData.cards.find(
                kCard => kCard.uuid === card.uuid
              );
              return {
                ...card,
                sequence: kcard ? kcard.sequence : 0,
              };
            }
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: true,
        error: null,
      };
    }
  ),
  on(KanbanCardsActions.updateKanbanCardSequencesSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(
    KanbanCardsActions.updateKanbanCardSequencesFailure,
    (state, { error, kanbanColumnId, oldCardSequence }) => ({
      ...state,
      loading: false,
      error,
      allCards: {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card => {
            const kcard = oldCardSequence.find(
              kCard => kCard.uuid === card.uuid
            );
            return {
              ...card,
              sequence: kcard ? kcard.sequence : 0,
            };
          }),
        },
      },
    })
  ),

  on(
    KanbanCardsActions.switchKanbanCardColumn,
    (state, { uuid, kanbanCardsData }) => {
      let newData = state.allCards[kanbanCardsData.newKanbanColumnId].data.map(
        card => {
          return {
            ...card,
            sequence:
              card.sequence >= kanbanCardsData.sequence
                ? card.sequence + 1
                : card.sequence,
          };
        }
      );

      const newCard = state.allCards[kanbanCardsData.oldKanbanColumnId].data
        .filter(card => uuid === card.uuid)
        .at(0);

      let oldSequence = -1;
      if (newCard !== undefined) {
        oldSequence = newCard.sequence;
        newData = [
          ...newData,
          { ...newCard, sequence: kanbanCardsData.sequence },
        ].sort((a, b) => a.sequence - b.sequence);
      }

      const tmp = {
        ...state.allCards,
        [kanbanCardsData.oldKanbanColumnId]: {
          ...state.allCards[kanbanCardsData.oldKanbanColumnId],
          count: state.allCards[kanbanCardsData.oldKanbanColumnId].count - 1,
          data: state.allCards[kanbanCardsData.oldKanbanColumnId].data
            .filter(card => uuid !== card.uuid)
            .map(card => {
              return {
                ...card,
                sequence:
                  card.sequence >= oldSequence
                    ? card.sequence - 1
                    : card.sequence,
              };
            }),
        },
        [kanbanCardsData.newKanbanColumnId]: {
          ...state.allCards[kanbanCardsData.newKanbanColumnId],
          count: state.allCards[kanbanCardsData.newKanbanColumnId].count + 1,
          data: newData,
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: true,
        error: null,
      };
    }
  ),
  on(KanbanCardsActions.switchKanbanCardColumnSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(
    KanbanCardsActions.switchKanbanCardColumnFailure,
    (state, { error, uuid, kanbanCardsData, prevIndex }) => {
      let newData = state.allCards[kanbanCardsData.newKanbanColumnId].data.map(
        card => {
          return {
            ...card,
            sequence:
              card.sequence >= prevIndex ? card.sequence + 1 : card.sequence,
          };
        }
      );

      const newCard = state.allCards[kanbanCardsData.oldKanbanColumnId].data
        .filter(card => uuid === card.uuid)
        .at(0);

      let oldSequence = -1;
      if (newCard !== undefined) {
        oldSequence = newCard.sequence;
        newData = [...newData, { ...newCard, sequence: prevIndex }].sort(
          (a, b) => a.sequence - b.sequence
        );
      }

      const tmp = {
        ...state.allCards,
        [kanbanCardsData.oldKanbanColumnId]: {
          ...state.allCards[kanbanCardsData.oldKanbanColumnId],
          count: state.allCards[kanbanCardsData.oldKanbanColumnId].count - 1,
          data: state.allCards[kanbanCardsData.oldKanbanColumnId].data
            .filter(card => uuid !== card.uuid)
            .map(card => {
              return {
                ...card,
                sequence:
                  card.sequence >= oldSequence
                    ? card.sequence - 1
                    : card.sequence,
              };
            }),
        },
        [kanbanCardsData.newKanbanColumnId]: {
          ...state.allCards[kanbanCardsData.newKanbanColumnId],
          count: state.allCards[kanbanCardsData.newKanbanColumnId].count + 1,
          data: newData,
        },
      };
      return { ...state, loading: false, error, allCards: tmp };
    }
  ),

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/
  on(
    KanbanCardsActions.updateKanbanCardSequencesServer,
    (state, { kanbanCardSequencesData }) => ({
      ...state,
      allCards: {
        ...state.allCards,
        [kanbanCardSequencesData.kanbanColumnId]: {
          ...state.allCards[kanbanCardSequencesData.kanbanColumnId],
          data: state.allCards[kanbanCardSequencesData.kanbanColumnId].data.map(
            card => {
              const kcard = kanbanCardSequencesData.cards.find(
                kCard => kCard.uuid === card.uuid
              );
              return {
                ...card,
                sequence: kcard ? kcard.sequence : 0,
              };
            }
          ),
        },
      },
    })
  ),

  on(
    KanbanCardsActions.switchKanbanCardColumnServer,
    (state, { uuid, kanbanCardsData }) => {
      let newData = state.allCards[kanbanCardsData.newKanbanColumnId].data.map(
        card => {
          return {
            ...card,
            sequence:
              card.sequence >= kanbanCardsData.sequence
                ? card.sequence + 1
                : card.sequence,
          };
        }
      );

      const newCard = state.allCards[kanbanCardsData.oldKanbanColumnId].data
        .filter(card => uuid === card.uuid)
        .at(0);

      let oldSequence = -1;
      if (newCard !== undefined) {
        oldSequence = newCard.sequence;
        newData = [
          ...newData,
          { ...newCard, sequence: kanbanCardsData.sequence },
        ].sort((a, b) => a.sequence - b.sequence);
      }
      return {
        ...state,
        allCards: {
          ...state.allCards,
          [kanbanCardsData.oldKanbanColumnId]: {
            ...state.allCards[kanbanCardsData.oldKanbanColumnId],
            count: state.allCards[kanbanCardsData.oldKanbanColumnId].count - 1,
            data: state.allCards[kanbanCardsData.oldKanbanColumnId].data
              .filter(card => uuid !== card.uuid)
              .map(card => {
                return {
                  ...card,
                  sequence:
                    card.sequence >= oldSequence
                      ? card.sequence - 1
                      : card.sequence,
                };
              }),
          },
          [kanbanCardsData.newKanbanColumnId]: {
            ...state.allCards[kanbanCardsData.newKanbanColumnId],
            count: state.allCards[kanbanCardsData.newKanbanColumnId].count + 1,
            data: newData,
          },
        },
      };
    }
  ),

  /**
   * ************************************************************************
   * ASSIGN/UNASSIGN USER + TAG + PROFILE
   * ************************************************************************
   **/
  on(KanbanCardsActions.assignKanbanCardUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.assignKanbanCardUserSuccess,
    KanbanCardsActions.assignKanbanCardUserServer,
    (state, { uuid, kanbanCardUsers, kanbanColumnId }) => {
      const tmp = {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === uuid
              ? {
                  ...card,
                  users: kanbanCardUsers.map(user => user.user),
                }
              : card
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.assignKanbanCardUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.unassignKanbanCardUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.unassignKanbanCardUserSuccess,
    KanbanCardsActions.unassignKanbanCardUserServer,
    (state, { uuid, kanbanCardUserData }) => {
      const tmp = {
        ...state.allCards,
        [kanbanCardUserData.kanbanColumnId]: {
          ...state.allCards[kanbanCardUserData.kanbanColumnId],
          data: state.allCards[kanbanCardUserData.kanbanColumnId].data.map(
            card =>
              card.uuid === uuid
                ? {
                    ...card,
                    users:
                      typeof kanbanCardUserData.userId === 'string'
                        ? card.users?.filter(
                            user => user.uuid !== kanbanCardUserData.userId
                          )
                        : card.users?.filter(
                            user =>
                              !kanbanCardUserData.userId.includes(user.uuid)
                          ),
                  }
                : card
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.unassignKanbanCardUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.assignKanbanCardTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.assignKanbanCardTagSuccess,
    KanbanCardsActions.assignKanbanCardTagServer,
    (state, { uuid, kanbanCardTags, kanbanColumnId }) => {
      const tmp = {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === uuid
              ? {
                  ...card,
                  kanbanCardTags: kanbanCardTags.map(tag => tag.kanbanTag),
                }
              : card
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.assignKanbanCardTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.unassignKanbanCardTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.unassignKanbanCardTagSuccess,
    KanbanCardsActions.unassignKanbanCardTagServer,
    (state, { uuid, kanbanCardTagData }) => {
      const tmp = {
        ...state.allCards,
        [kanbanCardTagData.kanbanColumnId]: {
          ...state.allCards[kanbanCardTagData.kanbanColumnId],
          data: state.allCards[kanbanCardTagData.kanbanColumnId].data.map(
            card =>
              card.uuid === uuid
                ? {
                    ...card,
                    kanbanCardTags:
                      typeof kanbanCardTagData.tagId === 'string'
                        ? card.kanbanCardTags.filter(
                            tag => tag.uuid !== kanbanCardTagData.tagId
                          )
                        : card.kanbanCardTags.filter(
                            tag => !kanbanCardTagData.tagId.includes(tag.uuid)
                          ),
                  }
                : card
          ),
        },
      };
      return {
        ...state,
        allCards: tmp,
        loading: false,
      };
    }
  ),
  on(KanbanCardsActions.unassignKanbanCardTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanCardsActions.getKanbanCardProfiles, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.getKanbanCardProfilesSuccess,
    (state, { profiles }) => ({
      ...state,
      profiles: profiles,
      loading: false,
    })
  ),
  on(KanbanCardsActions.getKanbanCardProfilesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(KanbanCardsActions.assignKanbanCardProfile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.assignKanbanCardProfileSuccess,
    (state, { kanbanCardProfileData }) => ({
      ...state,
      profiles: kanbanCardProfileData.map(cardProfile => cardProfile.profile),
      loading: false,
    })
  ),
  on(KanbanCardsActions.updateKanbanCardFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(KanbanCardsActions.unassignKanbanCardProfile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardsActions.unassignKanbanCardProfileSuccess,
    (state, { profileId }) => ({
      ...state,
      profiles: state.profiles.filter(profile => profile.uuid !== profileId),
      loading: false,
    })
  ),
  on(
    KanbanCardsActions.unassignKanbanCardProfileFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * ATTACHMENTS + COMMENTS
   * ************************************************************************
   **/
  on(
    KanbanCardAttachmentsActions.createKanbanCardAttachmentSuccess,
    KanbanCardAttachmentsActions.createKanbanCardAttachmentServer,
    (state, { kanbanCardId, kanbanColumnId }) => ({
      ...state,
      allCards: {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === kanbanCardId
              ? {
                  ...card,
                  nbAttachments: card.nbAttachments + 1,
                }
              : card
          ),
        },
      },
    })
  ),
  on(
    KanbanCardAttachmentsActions.deleteKanbanCardAttachmentSuccess,
    KanbanCardAttachmentsActions.deleteKanbanCardAttachmentServer,
    (state, { kanbanCardId, kanbanColumnId }) => ({
      ...state,
      allCards: {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === kanbanCardId
              ? {
                  ...card,
                  nbAttachments: card.nbAttachments - 1,
                }
              : card
          ),
        },
      },
    })
  ),

  on(
    KanbanCardCommentsActions.createKanbanCardCommentSuccess,
    KanbanCardCommentsActions.createKanbanCardCommentServer,
    (state, { kanbanCardId, kanbanColumnId }) => ({
      ...state,
      allCards: {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === kanbanCardId
              ? {
                  ...card,
                  nbComments: card.nbComments + 1,
                }
              : card
          ),
        },
      },
    })
  ),
  on(
    KanbanCardCommentsActions.deleteKanbanCardCommentSuccess,
    KanbanCardCommentsActions.deleteKanbanCardCommentServer,
    (state, { kanbanCardId, kanbanColumnId }) => ({
      ...state,
      allCards: {
        ...state.allCards,
        [kanbanColumnId]: {
          ...state.allCards[kanbanColumnId],
          data: state.allCards[kanbanColumnId].data.map(card =>
            card.uuid === kanbanCardId
              ? {
                  ...card,
                  nbComments: card.nbComments - 1,
                }
              : card
          ),
        },
      },
    })
  ),

  /**
   * ************************************************************************
   * TYPING USERS
   * ************************************************************************
   **/

  on(KanbanCardsActions.userStartTyping, (state, { kanbanCardId, users }) => ({
    ...state,
    typingUsers: {
      ...state.typingUsers,
      [kanbanCardId]: users,
    },
  })),

  on(KanbanCardsActions.userStopTyping, (state, { kanbanCardId, users }) => ({
    ...state,
    typingUsers: {
      ...state.typingUsers,
      [kanbanCardId]: users,
    },
  })),

  /**
   * ************************************************************************
   *  SSE SERVER CONNECTION
   * ************************************************************************
   **/

  on(KanbanCardsActions.connectToChannel, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbanCardsActions.connectToChannelSuccess, state => ({
    ...state,
    connected: true,
    loading: false,
  })),
  on(KanbanCardsActions.connectToChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    connected: false,
    error: error,
  })),

  on(KanbanCardsActions.disconnectFromChannel, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbanCardsActions.disconnectFromChannelSuccess, state => ({
    ...state,
    connected: false,
    token: '',
    loading: false,
  })),
  on(KanbanCardsActions.disconnectFromChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  /**
   * ************************************************************************
   * UNAUTHORIZED
   * ************************************************************************
   **/
  on(KanbanCardsActions.kanbanCardUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
