import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';

/**
 * `GradientPipe` is an Angular pipe that generates a CSS linear gradient based on a given base color.
 * It uses the `tinycolor2` library to manipulate the color and create the gradient.
 *
 * @Pipe decorator defines metadata for the pipe:
 * @name 'gradient' - The name of the pipe.
 */
@Pipe({
  name: 'gradient',
})
export class GradientPipe implements PipeTransform {
  /**
   * Transforms a given base color into a CSS linear gradient string.
   *
   * @param {string} color - The base color to create the gradient from.
   * @returns {string} A CSS linear gradient string.
   */
  transform(color: string): string {
    const baseColor = tinycolor(color);

    const lightColor = baseColor.spin(10).toString();

    const darkColor = baseColor.spin(20).spin(-40).toString();

    return `linear-gradient(84deg, ${lightColor} -5.71%, ${darkColor} 98.93%)`;
  }
}
