import { RandomShuffleList } from '~/app/shared/interfaces/shuffle-list/random-shuffle-list.interface';
import { ShuffleList } from '~/app/shared/interfaces/shuffle-list/shuffle-list.interface';

/**
 * Interface representing the state of all shuffle lists.
 *
 * This state includes the list of shuffle lists, loading status, and any error messages.
 *
 * @export
 * @interface AllShuffleListState
 */
export interface AllShuffleListState {
  /**
   * The list of shuffle lists.
   *
   * @type {ShuffleList[]}
   * @memberof AllShuffleListState
   */
  shuffleLists: ShuffleList[];

  /**
   * The current shuffle list or null it no shuffle list is selected.
   *
   * @type {ShuffleList | null}
   * @memberof AllShuffleListState
   */
  selectedShuffleList: ShuffleList | null;

  /**
   * The current random shuffle list or null it no random shuffle list is selected.
   *
   * @type {RandomShuffleList | null}
   * @memberof AllShuffleListState
   */
  selectedRandomShuffleList: RandomShuffleList | null;

  /**
   * The loading status for fetching shuffle lists.
   *
   * @type {boolean}
   * @memberof AllShuffleListState
   */
  loading: boolean;

  /**
   * The error message if an error occurs while fetching shuffle lists.
   *
   * @type {string | null}
   * @memberof AllShuffleListState
   */
  error: string | null;
}

/**
 * The initial state for all shuffle lists.
 *
 * This state includes an empty list of shuffle lists, a selected shuffle list,
 * a selected random shuffle list, a loading status of false, and no error message.
 *
 * @export
 * @const {AllShuffleListState} initialAllShuffleListState
 */
export const initialAllShuffleListState: AllShuffleListState = {
  shuffleLists: [],
  selectedShuffleList: null,
  selectedRandomShuffleList: null,
  loading: false,
  error: null,
};
