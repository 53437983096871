/* eslint-disable max-lines */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import * as EnumsActions from '~/app/core/state/enum/enums.actions';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { environment } from '~/environments/environment';

/**
 * `EnumsEffects` manages the side effects for fetching enumeration data from the server across different application domains.
 * This class utilizes the NgRx Effects library to manage asynchronous operations involved in fetching enumerated values, such as user roles,
 * status types, and other system-wide categorical data, ensuring centralized and consistent management of these entities.
 * It conducts HTTP requests to fetch necessary data and manages application state in response to both successful and failed operations.
 *
 * The effects in this class include:
 * - `loadCecrls$`: Fetches CEFR levels for language proficiency categorization.
 * - `loadOfferProposalStatuses$`: Retrieves status types for offer proposals.
 * - `loadOfferStatuses$`: Fetches status enumerations for offers.
 * - `loadOfferUnits$`: Retrieves unit types for offers.
 * - `loadOfferVisibilities$`: Fetches visibility types for offers.
 * - `loadOfferDocumentTypes`: Fetches document types for offers.
 * - `loadProfileExperiences$`: Retrieves experience types associated with profiles.
 * - `loadProfileFiles$`: Fetches file types associated with profiles.
 * - `loadProfiles$`: Retrieves profile types.
 * - `loadProfileTimeUnits$`: Fetches time unit types for profiles.
 * - `loadProposalStatuses$`: Retrieves status types for proposals.
 * - `loadReminders$`: Fetches reminder types.
 * - `loadSaleInformations$`: Retrieves sale information types.
 * - `loadSocials$`: Fetches social network types.
 * - `loadGenders$`: Retrieves gender types.
 * - `loadSkillExperience$`: Fetches skill experience levels.
 * - `loadTargets$`: Fetches Goals Targets
 * - `loadGoalTypes$`: Fetches Goals Types
 * - `loadRatesTypes$`: Fetches Rates Types
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class EnumsEffects {
  /**
   * Effect to load CEFR levels for language proficiency categorization from the server.
   */
  loadCecrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadCecrls),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(`${environment.apiUrl}/v1/enums/cecrl`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(cecrls => EnumsActions.loadCecrlsSuccess({ cecrls })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadCecrlsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load offer statuses from the server.
   */
  loadOfferStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadOfferStatuses),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/offerStatus`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(offerStatuses =>
              EnumsActions.loadOfferStatusesSuccess({ offerStatuses })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadOfferStatusesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load offer units from the server.
   */
  loadOfferUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadOfferUnits),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/offerUnitTypes`, { withCredentials: true })
          .pipe(
            take(1),
            map(offerUnits =>
              EnumsActions.loadOfferUnitsSuccess({ offerUnits })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadOfferUnitsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load offer visibilities from the server.
   */
  loadOfferVisibilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadOfferVisibilities),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/offerVisibilityTypes`, { withCredentials: true })
          .pipe(
            take(1),
            map(offerVisibilities =>
              EnumsActions.loadOfferVisibilitiesSuccess({ offerVisibilities })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadOfferVisibilitiesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load offer document types from the server.
   */
  loadOfferDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadOfferDocumentTypes),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/offerDocumentType`, { withCredentials: true })
          .pipe(
            take(1),
            map(offerDocumentTypes =>
              EnumsActions.loadOfferDocumentTypesSuccess({ offerDocumentTypes })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadOfferDocumentTypesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load profile experiences from the server.
   */
  loadProfileExperiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadProfileExperiences),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/profileExperiences`, { withCredentials: true })
          .pipe(
            take(1),
            map(profileExperiences =>
              EnumsActions.loadProfileExperiencesSuccess({ profileExperiences })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadProfileExperiencesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load profile files from the server.
   */
  loadProfileFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadProfileFiles),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/profileFileTypes`, { withCredentials: true })
          .pipe(
            take(1),
            map(profileFiles =>
              EnumsActions.loadProfileFilesSuccess({ profileFiles })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadProfileFilesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load profile types from the server.
   */
  loadProfileTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadProfileTypes),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/profileTypes`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileTypes =>
              EnumsActions.loadProfileTypesSuccess({ profileTypes })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadProfileTypesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load profile time units from the server.
   */
  loadProfileTimeUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadProfileTimeUnits),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/profileTimeUnit`, { withCredentials: true })
          .pipe(
            take(1),
            map(profileTimeUnits =>
              EnumsActions.loadProfileTimeUnitsSuccess({ profileTimeUnits })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadProfileTimeUnitsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load proposal statuses from the server.
   */
  loadProposalStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadProposalStatuses),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/proposalStatusTypes`, { withCredentials: true })
          .pipe(
            take(1),
            map(proposalStatuses =>
              EnumsActions.loadProposalStatusesSuccess({ proposalStatuses })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadProposalStatusesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load reminders from the server.
   */
  loadReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadReminders),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/reminderType`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(reminders => EnumsActions.loadRemindersSuccess({ reminders })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadRemindersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load sale information types from the server.
   */
  loadSaleInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadSaleInformations),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            LabelValueInterface[]
          >(`${environment.apiUrl}/v1/enums/saleInformationTypes`, { withCredentials: true })
          .pipe(
            take(1),
            map(saleInformations =>
              EnumsActions.loadSaleInformationsSuccess({ saleInformations })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadSaleInformationsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load social network types from the server.
   */
  loadSocials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadSocials),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/socialNetwork`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(socials => EnumsActions.loadSocialsSuccess({ socials })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadSocialsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load gender types from the server.
   */
  loadGenders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadGenders),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(`${environment.apiUrl}/v1/enums/gender`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(genders => EnumsActions.loadGendersSuccess({ genders })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadGendersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load skill experience levels from the server.
   */
  loadSkillExperience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadSkillExperience),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/skillExperience`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(skillExperiences =>
              EnumsActions.loadSkillExperienceSuccess({ skillExperiences })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadSkillExperienceFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load goals targets from the server.
   */
  loadTargets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadTarget),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(`${environment.apiUrl}/v1/enums/target`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(targets => EnumsActions.loadTargetSuccess({ targets })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadTargetFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load goals types from the server.
   */
  loadGoalTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadGoalTypes),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(
            `${environment.apiUrl}/v1/enums/goalType`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(goalTypes => EnumsActions.loadGoalTypesSuccess({ goalTypes })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadGoalTypesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load rates types from the server.
   */
  loadRatesTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadRates),
      checkPermission(EnumsActions.enumsUnauthorized),
      mergeMap(() =>
        this.http
          .get<LabelValueInterface[]>(`${environment.apiUrl}/v1/enums/rate`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(rates => EnumsActions.loadRatesSuccess({ rates })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(EnumsActions.loadRatesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor to inject dependencies.
   * @param {Actions} actions$ Actions stream that listens for all actions dispatched to the store.
   * @param {HttpClient} http HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors HttpErrorsService for centralized management of HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
