import { Router } from '@angular/router';
import { ModuleItemSkeleton } from '~/app/shared/interfaces/module/module-item-skeleton.interface';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';

/**
 * Function that generates an array of module items for use in application navigation. Each item includes properties for
 * name, label, icon, and a navigation command. The function takes a Router instance to handle navigation commands.
 *
 * @param {Router} router - The Angular Router instance used to perform navigation actions.
 * @returns {ModuleItem[]} An array of ModuleItem objects each configured with a specific route and action.
 */
export const getAllModuleItems = (router: Router): ModuleItem[] => [
  {
    name: 'crm',
    label: 'CRM',
    icon: 'users',
    command: (): void => {
      void router.navigate(['/crm']); // Update with your CRM route
    },
  },
  {
    name: 'sales',
    label: 'Sales',
    icon: 'clients',
    command: (): void => {
      void router.navigate(['/sales']); // Update with your Sales route
    },
  },
  {
    name: 'jobhub',
    label: 'JobHub',
    icon: 'jobs',
    command: (): void => {
      void router.navigate(['/jobhub']); // Update with your JobHub route
    },
  },
  {
    name: 'kanban',
    label: 'Kanban',
    icon: 'board',
    command: (): void => {
      void router.navigate(['/kanban']); // Update with your Kanban route
    },
  },
];

/**
 * A dictionary of all module items, each mapped by a key. Each item includes properties for
 * name, label, icon, and a route.
 *
 * @type {{ [key: string]: ModuleItemSkeleton | undefined }}
 */
export const allModuleItems: {
  [key: string]: ModuleItemSkeleton | undefined;
} = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Home: {
    name: 'home',
    label: 'Home',
    icon: 'home',
    route: '/home',
  },
  CRM: {
    name: 'crm',
    label: 'CRM',
    icon: 'users',
    route: '/crm', // Update with your CRM route
  },
  Sales: {
    name: 'sales',
    label: 'Sales',
    icon: 'clients',
    route: '/sales',
  },
  JobHub: {
    name: 'jobhub',
    label: 'Jobs',
    icon: 'jobs',
    route: '/jobhub',
  },
  Kanban: {
    name: 'kanban',
    label: 'Board',
    icon: 'board',
    route: '/kanban',
  },
  ClientCompany: {
    name: 'client-company',
    label: 'Corp',
    icon: 'company',
    route: '/client-company',
  },
  ShuffleList: {
    name: 'shuffle-list',
    label: 'Rand',
    icon: 'rand',
    route: '/shuffle-list',
  },
  Stats: {
    name: 'stats',
    label: 'Stats',
    icon: 'stats',
    route: '/stats',
  },
  Settings: {
    name: 'settings',
    label: 'Settings',
    icon: 'setting',
    route: '/settings',
  },
};
