import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAuthLoading } from '~/app/auth/auth.selectors';
import { TwoFactorAuthService } from '~/app/auth/services/two-factor-auth.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';

/**
 * Component for disabling two-factor authentication.
 * It provides a form where users can enter their subdomain, email, and a recovery code to disable 2FA.
 *
 * @selector 'app-disable-two-factor-authentication'
 * @templateUrl './disable-two-factor-auth.component.html'
 * @styleUrls ['./disable-two-factor-auth.component.scss']
 */
@Component({
  selector: 'app-disable-two-factor-authentication',
  templateUrl: './disable-two-factor-auth.component.html',
  styleUrls: ['./disable-two-factor-auth.component.scss'],
})
export class DisableTwoFactorAuthComponent implements OnInit {
  /**
   * FormGroup to handle user input for disabling 2FA.
   */
  disableForm!: FormGroup;

  /**
   * Observable that tracks the loading state during the 2FA disable process.
   */
  isLoading$!: Observable<boolean>;

  /**
   * Constructor initializes services needed for form operations and state management.
   * @param {FormBuilder} formBuilder - Manages the creation and validation of form controls.
   * @param {TwoFactorAuthService} twoFactorAuthService - Executes business logic related to 2FA operations.
   * @param {Store<AppState>}  store - Manages the application's state and reacts to state changes.
   * @param {ThemeService} theme - Manages theming capabilities across the application.
   */
  constructor(
    private formBuilder: FormBuilder,
    private twoFactorAuthService: TwoFactorAuthService,
    private store: Store<AppState>,
    public theme: ThemeService
  ) {}

  /**
   * Getter for accessing the 'subdomain' FormControl within the disable form.
   * @type {FormControl} FormControl for the subdomain input.
   */
  get subdomain(): FormControl {
    return this.disableForm.get('subdomain') as FormControl;
  }

  /**
   * Getter for accessing the 'email' FormControl within the disable form.
   * @type {FormControl} FormControl for the subdomain input.
   */
  get email(): FormControl {
    return this.disableForm.get('email') as FormControl;
  }

  /**
   * Getter for accessing the 'recoveryCode' FormControl within the disable form.
   * @type {FormControl} FormControl for the subdomain input.
   */
  get recoveryCode(): FormControl {
    return this.disableForm.get('recoveryCode') as FormControl;
  }

  /**
   * Initializes the component and form, and sets up the observable for the authentication loading state.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.isLoading$ = this.store.select(selectAuthLoading);
  }

  /**
   * Handles form submission by extracting form data and invoking the service to disable two-factor authentication.
   * @return {void}
   */
  onSubmit(): void {
    const disableData = {
      subdomain: this.disableForm.value.subdomain,
      email: this.disableForm.value.email,
      recoveryCode: this.disableForm.value.recoveryCode,
    };

    this.twoFactorAuthService.disableTwoFactorAuthenticationByRecovery(
      disableData
    );
  }

  /**
   * Initializes the form with the necessary form controls and validators.
   * @return {void}
   */
  private initForm(): void {
    this.disableForm = this.formBuilder.group({
      subdomain: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      recoveryCode: ['', [Validators.required]],
    });
  }
}
