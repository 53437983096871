/* eslint-disable max-lines */
import IceRule from '@npmicedev/icemodule/lib/utils/rules';

/**
 * A map from action types to permissions required for those actions.
 * This constant defines which permissions (represented as `IceRule` or an array of `IceRule`)
 * are required to perform specific actions within the application.
 * Actions related to enums and authentication are included, with some requiring no permissions to execute.
 *
 * @type {Object.<string, (IceRule | IceRule[] | null)>}
 * @property {null} [key] - Represents action types that require no permissions.
 * Each key in this map corresponds to a specific action (e.g., loading enums, handling authentication)
 * and maps to either `null` (no permissions required), a single `IceRule`, or an array of `IceRule` objects
 * that specify the required permissions.
 *
 * Examples of actions include:
 * - `[All Enums] Load Cecrls`: No permissions required to load Cecrls.
 * - `[All Enums] Load Offer Proposal Statuses`: No permissions required.
 * - `[Auth] Login User`: Might map to an `IceRule` or `IceRule[]` specifying required permissions.
 */
export const actionToPermissionMap: {
  [actionType: string]: (IceRule | IceRule[]) | null;
} = {
  /* eslint-disable @typescript-eslint/naming-convention */
  /*********************************/
  /* Enum */
  /* No Permissions required */
  /*********************************/
  '[All Enums] Load Cecrls': null,
  '[All Enums] Load Offer Proposal Statuses': null,
  '[All Enums] Load Offer Statuses': null,
  '[All Enums] Load Offer Units': null,
  '[All Enums] Load Offer Visibilities': null,
  '[All Enums] Load Profile Experiences': null,
  '[All Enums] Load Offer Document Types': null,
  '[All Enums] Load Profile Files': null,
  '[All Enums] Load Profile Time Units': null,
  '[All Enums] Load Profile Types': null,
  '[All Enums] Load Proposal Statuses': null,
  '[All Enums] Load Reminders': null,
  '[All Enums] Load Sale Informations': null,
  '[All Enums] Load Socials': null,
  '[All Enums] Load Genders': null,
  '[All Enums] Load Skill Experiences': null,

  /*********************************/
  /* Auth */
  /* No Permissions required */
  /*********************************/
  '[Auth] Login': null,
  '[Auth] Secure Login': null,
  '[Auth] Logout': null,
  '[Auth] Refresh Token': null,
  '[Auth] Reset Password': null,
  '[Auth] Check Reset Password': null,
  '[Auth] Patch Password': null,
  '[Auth] Register': null,
  '[Auth] Check Token': null,
  '[Auth] Check invitation': null,
  '[Auth] User Register': null,
  '[Auth] Disable Two-Factor Authentication By Recovery': null,
  '[Auth] Refresh Token Interceptor': null,

  /*********************************/
  /* Settings
  /* General settings
  /*********************************/
  '[Company] Load Company': IceRule.ReadCompany,
  '[Company] Update Company': IceRule.UpdateCompany,
  '[Offices] Load Offices': IceRule.ReadOffice,
  '[Offices] Create Office': IceRule.CreateOffice,
  '[Offices] Delete Office': IceRule.DeleteOffice,
  '[Offices] Update Office': IceRule.UpdateOffice,

  /*********************************/
  /* ACCESS-CONTROL SETTINGS
  /* Group Rules settings
  /*********************************/
  '[Rules] Load Rules': null,
  '[Rules] Load Rules By Group': IceRule.ReadGroupRule,
  '[Group Rules] Load Group Rules': IceRule.ReadGroupRule,
  '[Group Rules] Load Group Rule By ID': IceRule.ReadGroupRule,
  '[Group Rules] Create Group Rule': IceRule.CreateGroupRule,
  '[Group Rules] Update Group Rule': IceRule.UpdateGroupRule,
  '[Group Rules] Delete Group Rule': IceRule.DeleteGroupRule,
  '[Group Rules] Assign User To Group': IceRule.UpdateGroupRule,
  '[Group Rules] Unassign User From Group': IceRule.UpdateGroupRule,
  '[Group Rules] Assign Rule To Group': IceRule.UpdateGroupRule,
  '[Group Rules] Unassign Rule From Group': IceRule.UpdateGroupRule,

  /*********************************/
  /* AI Search
  /* Profile Client
  /*********************************/
  '[Client AI Search] Load Ai Client Job Matching Request':
    IceRule.MatchingProfileClient,
  '[Client AI Search] Load Ai Client Job Matching Candidates':
    IceRule.MatchingProfileClient,
  '[Client AI Search] Reset Ai Client Job Matching Candidates':
    IceRule.MatchingProfileClient,
  '[Client AI Search] Load Ai Consulting Job Matching History':
    IceRule.MatchingProfileClient,

  /*********************************/
  /* AI Search
  /* Profile Consulting
  /*********************************/
  '[Consulting AI Search] Load Ai Consulting Job Matching Request':
    IceRule.MatchingProfileConsulting,
  '[Consulting AI Search] Load Ai Consulting Job Matching Candidates':
    IceRule.MatchingProfileConsulting,
  '[Consulting AI Search] Reset Ai Consulting Job Matching Candidates':
    IceRule.MatchingProfileConsulting,

  /*********************************/
  /* AI Creation
  /* Generate Profile
  /*********************************/
  '[AI Creation Profile] Load AI Creation Profile Request':
    IceRule.CreateAIProfile,
  '[AI Creation Profile] Update Advancement': IceRule.CreateAIProfile,
  '[AI Creation Profile] Load Statuses': IceRule.ReadProfileStatus,
  '[AI Creation Profile] Load Offices': IceRule.ReadOffice,
  '[AI Creation Profile] Load Teams': IceRule.ReadTeam,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Industry settings */
  /*********************************/
  '[Industry] Load Industries': IceRule.ReadIndustry,
  '[Industry] Assign Industry': IceRule.UpdateIndustry,
  '[Industry] Unassign Industry': IceRule.UpdateIndustry,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Note category setting */
  /*********************************/
  '[Note Categories] Load Note Categories': IceRule.ReadNoteCategory,
  '[Note Categories] Create Note Category': IceRule.CreateNoteCategory,
  '[Note Categories] Update Note Category': IceRule.UpdateNoteCategory,
  '[Note Categories] Delete Note Category': IceRule.DeleteNoteCategory,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Sector settings */
  /*********************************/
  '[Sector Category] Load Sector Categories': IceRule.ReadSectorJobCategory,
  '[Sector Category] Load Sector Category': IceRule.ReadSectorJobCategory,
  '[Sector Category] Create Sector Category': IceRule.CreateSectorJobCategory,
  '[Sector Category] Update Sector Category': IceRule.UpdateSectorJobCategory,
  '[Sector Category] Delete Sector Category': IceRule.DeleteSectorJobCategory,
  '[Sector Category] Assign Sector Job': IceRule.UpdateSectorJobCategory,
  '[Sector Category] Unassign Sector Job': IceRule.UpdateSectorJobCategory,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Candidate status */
  /*********************************/
  '[Candidate Status] Load Candidate Statuses': IceRule.ReadProfileStatus,
  '[Candidate Status] Create Candidate Status': IceRule.CreateProfileStatus,
  '[Candidate Status] Update Candidate Status': IceRule.UpdateProfileStatus,
  '[Candidate Status] Delete Candidate Status': IceRule.DeleteProfileStatus,
  '[Candidate Status] Update Candidate Status Sequence':
    IceRule.UpdateProfileStatus,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Sales status */
  /*********************************/
  '[Sales Status] Load Sales Statuses': IceRule.ReadSaleStatus,
  '[Sales Status] Create Sales Status': IceRule.CreateSaleStatus,
  '[Sales Status] Update Sales Status': IceRule.UpdateSaleStatus,
  '[Sales Status] Delete Sales Status': IceRule.DeleteSaleStatus,
  '[Sales Status] Update Sales Status Sequence': IceRule.UpdateSaleStatus,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Candidate Tags */
  /*********************************/
  '[Candidate Tags] Load Candidate Tags': IceRule.ReadProfileTag,
  '[Candidate Tags] Create Candidate Tag': IceRule.CreateProfileTag,
  '[Candidate Tags] Update Candidate Tag': IceRule.UpdateProfileTag,
  '[Candidate Tags] Delete Candidate Tag': IceRule.DeleteProfileTag,

  /*********************************/
  /* CRM/HUB SETTINGS
  /* Sales Tags */
  /*********************************/
  '[Sales Tags] Load Sales Tags': IceRule.ReadSaleTag,
  '[Sales Tags] Create Sales Tag': IceRule.CreateSaleTag,
  '[Sales Tags] Update Sales Tag': IceRule.UpdateSaleTag,
  '[Sales Tags] Delete Sales Tag': IceRule.DeleteSaleTag,

  /*********************************/
  /* Team */
  /*********************************/
  '[Teams] Load All Teams': IceRule.ReadTeam,
  '[Teams] Create Team': IceRule.CreateTeam,
  '[Teams] Update Team': IceRule.UpdateTeam,
  '[Teams] Delete Team': IceRule.DeleteTeam,
  '[Teams] Assign Member': IceRule.UpdateTeam,
  '[Teams] Unassign Member': IceRule.UpdateTeam,
  '[Teams] Change Member Status': IceRule.UpdateTeam,

  /*********************************/
  /* Users */
  /*********************************/
  '[Users] Load Users': IceRule.ReadUser,
  '[Users] Invite User': IceRule.InvitationUser,
  '[Users] Update User': null,
  '[Users] Assign Group To User': null,
  '[Users] Unassign Group To User': null,
  '[Users] Activate User': null,
  '[Users] Deactivate User': null,

  /*********************************/
  /* SEARCH PAGE
  /* Candidate
  /*********************************/
  '[All Candidates] Load All Candidates': IceRule.ReadProfile,

  /*********************************/
  /* PROFILE
  /* Candidate Profile
  /*********************************/
  '[Profile] Load Profile': [IceRule.ReadProfile, IceRule.AdminProfile],
  '[Profile] Create Profile': IceRule.CreateProfile,
  '[Profile] Update Profile': IceRule.UpdateProfile,
  '[Profile] Delete Profile': IceRule.DeleteProfile,

  /*********************************/
  /* Profile Industries */
  /*********************************/
  '[Profile Industries] Load Profile Industries': IceRule.ReadIndustry,
  '[Profile Industries] Assign Profile Industry': IceRule.UpdateIndustry,
  '[Profile Industries] Unassign Profile Industry': IceRule.UpdateIndustry,

  /*********************************/
  /* Profile Interviews */
  /*********************************/
  '[Profile Interviews] Load Profile Interviews': IceRule.ReadProfileInterview,
  '[Profile Interviews] Create Profile Interview':
    IceRule.CreateProfileInterview,
  '[Profile Interviews] Update Profile Interview':
    IceRule.UpdateProfileInterview,
  '[Profile Interviews] Delete Profile Interview':
    IceRule.DeleteProfileInterview,

  /*********************************/
  /* Profile Notes */
  /*********************************/
  '[Profile Notes] Load Profile Notes': IceRule.ReadProfileNote,
  '[Profile Notes] Create Profile Note': IceRule.CreateProfileNote,
  '[Profile Notes] Delete Profile Note': IceRule.DeleteProfileNote,
  '[Profile Notes] Update Profile Note': IceRule.UpdateProfileNote,

  /*********************************/
  /* Profile Reminders */
  /*********************************/
  '[Profile Reminders] Load Profile Reminders': IceRule.ReadProfileReminder,
  '[Profile Reminders] Load Profile Reminder': IceRule.ReadProfileReminder,
  '[Profile Reminders] Create Profile Reminder': IceRule.CreateProfileReminder,
  '[Profile Reminders] Update Profile Reminder': IceRule.UpdateProfileReminder,
  '[Profile Reminders] Delete Profile Reminder': IceRule.DeleteProfileReminder,
  '[Profile Reminders] Check Profile Reminder': IceRule.UpdateProfileReminder,
  '[Profile Reminders] Uncheck Profile Reminder': IceRule.UpdateProfileReminder,
  '[Profile Reminders] Add attachment Profile Reminder':
    IceRule.UpdateProfileReminder,
  '[Profile Reminders] Remove attachment Profile Reminder':
    IceRule.UpdateProfileReminder,

  /*********************************/
  /* Profile Sector Jobs */
  /*********************************/
  '[Profile Sector Jobs] Load Profile Sectors': IceRule.ReadProfileSectorJob,
  '[Profile Sector Jobs] Unassign Company Sector Job':
    IceRule.UpdateProfileSectorJob,
  '[Profile Sector Jobs] Assign Profile Sector Job':
    IceRule.UpdateProfileSectorJob,

  /*********************************/
  /* Profile Skills */
  /*********************************/
  '[Profile Skills] Load Profile Skills': IceRule.ReadProfileSkill,
  '[Profile Skills] Assign Profile Skill': IceRule.UpdateProfileSkill,
  '[Profile Skills] Unassign Profile Skill': IceRule.UpdateProfileSkill,
  '[Profile Skills] Create Profile Skill': IceRule.CreateSkill,

  /*********************************/
  /* Profile Files */
  /*********************************/
  '[Profile Files] Load Profile Files': IceRule.ReadProfileFile,
  '[Profile Files] Create Profile File': IceRule.CreateProfileFile,
  '[Profile Files] Update Profile File': IceRule.UpdateProfileFile,
  '[Profile Files] Delete Profile File': IceRule.DeleteProfileFile,

  /*********************************/
  /* Profile Languages */
  /*********************************/
  '[Profile Languages] Load Profile Languages': IceRule.ReadProfileLanguage,
  '[Profile Languages] Create Profile Language': IceRule.CreateProfileLanguage,
  '[Profile Languages] Update Profile Language': IceRule.UpdateProfileLanguage,
  '[Profile Languages] Delete Profile Language': IceRule.DeleteProfileLanguage,

  /*********************************/
  /* Profile Tags */
  /*********************************/
  '[Profile Tags] Load Profile Tags': IceRule.ReadProfileTag,
  '[Profile Tags] Assign Profile Tag': IceRule.UpdateProfileTag,
  '[Profile Tags] Unassign Profile Tag': IceRule.UpdateProfileTag,

  /*********************************/
  /* Profile References */
  /*********************************/
  '[Profile References] Load Profile References': IceRule.ReadProfileReference,
  '[Profile References] Create Profile Reference':
    IceRule.CreateProfileReference,
  '[Profile References] Update Profile Reference':
    IceRule.UpdateProfileReference,
  '[Profile References] Delete Profile Reference':
    IceRule.DeleteProfileReference,

  /*********************************/
  /* Profile Social Networks */
  /*********************************/
  '[Profile Social Networks] Load Social Networks':
    IceRule.ReadProfileSocialNetwork,
  '[Profile Social Networks] Create Social Network':
    IceRule.CreateProfileSocialNetwork,
  '[Profile Social Networks] Update Social Network':
    IceRule.UpdateProfileSocialNetwork,
  '[Profile Social Networks] Delete Social Network':
    IceRule.DeleteProfileSocialNetwork,

  /*********************************/
  /* SEARCH PAGE
  /* Sales
  /*********************************/
  '[All Sales] Load Sales By Filter': IceRule.ReadSale,
  '[All Sales] Assign Sales To Projects': IceRule.UpdateProjects,

  /*********************************/
  /* Sales */
  /*********************************/
  '[Sales] Load Sale': IceRule.ReadSale,
  '[Sales] Update Sale': IceRule.UpdateSale,
  '[Sales] Create Sale': IceRule.CreateSale,

  /*********************************/
  /* Sales Social Networks */
  /*********************************/
  '[Sales Social Networks] Load Social Networks': IceRule.ReadSaleSocialNetwork,
  '[Sales Social Networks] Create Social Network':
    IceRule.CreateSaleSocialNetwork,
  '[Sales Social Networks] Delete Social Network':
    IceRule.DeleteSaleSocialNetwork,

  /*********************************/
  /* Sales Sector Jobs */
  /*********************************/
  '[Sales Sector Jobs] Load Sales Sectors': IceRule.ReadSaleSectorJob,
  '[Sales Sector Jobs] Unassign Company Sector Job':
    IceRule.UpdateSaleSectorJob,
  '[Sales Sector Jobs] Assign Sales Sector Job': IceRule.UpdateSaleSectorJob,

  /*********************************/
  /* Sales Reminders */
  /*********************************/
  '[Sales Reminders] Load Sales Reminders': IceRule.ReadSaleReminder,
  '[Sales Reminders] Load Sales Reminder': IceRule.ReadSaleReminder,
  '[Sales Reminders] Create Sales Reminder': IceRule.CreateSaleReminder,
  '[Sales Reminders] Update Sales Reminder': IceRule.UpdateSaleReminder,
  '[Sales Reminders] Delete Sales Reminder': IceRule.DeleteSaleReminder,
  '[Sales Reminders] Check Sales Reminder': IceRule.UpdateSaleReminder,
  '[Sales Reminders] Uncheck Sales Reminder': IceRule.UpdateSaleReminder,
  '[Sales Reminders] Add Attachment Sales Reminder': IceRule.UpdateSaleReminder,
  '[Sales Reminders] Remove Attachment Sales Reminder':
    IceRule.UpdateSaleReminder,

  /*********************************/
  /* Sales Languages */
  /*********************************/
  '[Sales Languages] Load Sales Languages': IceRule.ReadSaleLanguage,
  '[Sales Languages] Create Sales Language': IceRule.CreateSaleLanguage,
  '[Sales Languages] Delete Sales Language': IceRule.DeleteSaleLanguage,

  /*********************************/
  /* Sales Notes */
  /*********************************/
  '[Sales Notes] Load Sales Notes': IceRule.ReadSaleNote,
  '[Sales Notes] Create Sales Note': IceRule.CreateSaleNote,
  '[Sales Notes] Delete Sales Note': IceRule.DeleteSaleNote,
  '[Sales Notes] Update Sale Note': IceRule.UpdateSaleNote,

  /*********************************/
  /* Sales information */
  /*********************************/
  '[Sales Information] Load Sales Informations': IceRule.ReadSaleInformation,
  '[Sales Information] Create Sales Information': IceRule.CreateSaleInformation,
  '[Sales Information] Update Sales Information': IceRule.UpdateSaleInformation,
  '[Sales Information] Delete Sales Information': IceRule.DeleteSaleInformation,

  /*********************************/
  /* Sales Industries */
  /*********************************/
  '[Sales Industries] Load Sales Industries': IceRule.ReadSaleIndustry,
  '[Sales Industries] Assign Sales Industry': IceRule.UpdateSaleIndustry,
  '[Sales Industries] Unassign Sales Industry': IceRule.UpdateSaleIndustry,

  /*********************************/
  /* Timelines */
  /*********************************/
  '[Timelines] Load Timelines': IceRule.ReadTimeline,
  '[Timelines] Load Selected Timelines': IceRule.ReadTimeline,
  '[Timelines] Create Timelines': IceRule.CreateTimeline,
  '[Timelines] Delete Timelines': IceRule.DeleteTimeline,
  '[Timelines] Update Timelines': [
    IceRule.UpdateTimeline,
    IceRule.UpdateTimelineTask,
    IceRule.CreateTimelineTask,
    IceRule.DeleteTimelineTask,
  ],

  /*********************************/
  /* Projects */
  /*********************************/
  '[Projects] Load All Projects': IceRule.ReadProjects,
  '[Projects] Create Project': IceRule.CreateProjects,
  '[Projects] Update Project': IceRule.UpdateProjects,
  '[Projects] Delete Project': IceRule.DeleteProjects,

  /*********************************/
  /* Project */
  /*********************************/
  '[Project] Load Project': IceRule.ReadProjects,
  '[Project] Load Project Users': [IceRule.ReadProjects, IceRule.ReadUser],
  '[Project] Load Project Sales': [IceRule.ReadProjects, IceRule.ReadSale],
  '[Project] Assign User To Project': IceRule.UpdateProjects,
  '[Project] Unassign User From Project': IceRule.UpdateProjects,
  '[Project] Assign Sale To Project': IceRule.UpdateProjects,
  '[Project] Unassign Sale From Project': IceRule.UpdateProjects,
  '[Project] Assign Sales To Project': IceRule.UpdateProjects,

  /*********************************/
  /* Sales Projects */
  /*********************************/
  '[Sales Projects] Load Sales Projects': IceRule.ReadProjects,
  '[Sales Projects] Update Assigned Task Status': IceRule.UpdateProjects,

  /*********************************/
  /* Home Profile Reminders */
  /*********************************/
  '[Home Profile Reminders] Load Home Profile Reminders':
    IceRule.ReadProfileReminder,
  '[Home Profile Reminders] Check Profile Reminder':
    IceRule.UpdateProfileReminder,
  '[Home Profile Reminders] Uncheck Profile Reminder':
    IceRule.UpdateProfileReminder,

  /*********************************/
  /* Home Profile Interviews */
  /*********************************/
  '[Home Profile Interviews] Load Weekly Profile Interviews':
    IceRule.ReadProfileInterview,

  /*********************************/
  /* Home sales reminders */
  /*********************************/
  '[Home Sales Reminders] Load Home Sales Reminders': IceRule.ReadSaleReminder,
  '[Home Sales Reminders] Check Sales Reminder': IceRule.UpdateSaleReminder,
  '[Home Sales Reminders] Uncheck Sales Reminder': IceRule.UpdateSaleReminder,

  /*********************************/
  /* Home Users */
  /*********************************/
  '[Home Users] Load Home Users': IceRule.ReadUser,

  /*********************************/
  /* Home Teams */
  /*********************************/
  '[Home Teams] Load Home Teams': IceRule.ReadTeam,

  /*********************************/
  /* JOBHUB */
  /* Company Offer */
  /*********************************/
  '[Company Offers] Load All Company Offers': null,

  /*********************************/
  /* JOBHUB */
  /* Offer */
  /*********************************/
  '[Offers] Create Offer': IceRule.CreateOffer,
  '[Offers] Update Offer': IceRule.UpdateOffer,
  '[Offers] Delete Offer': IceRule.DeleteOffer,
  '[Offer] Load Profile Files': IceRule.ReadProfileFile,

  /*********************************/
  /* JOBHUB */
  /* Offer Proposal */
  /*********************************/
  '[Offer Proposal] Load Offer Proposal': IceRule.ReadOfferProposal,
  '[Offer Proposal] Change Offer Proposal Status': IceRule.UpdateOfferProposal,

  /*********************************/
  /* JOBHUB */
  /* Proposal History */
  /*********************************/
  '[History] Load proposals': IceRule.ReadOfferProposal,

  /*********************************/
  /* JOBHUB */
  /* Assign Offer */
  /*********************************/
  '[Search Offers] Load Offer By Id': null,
  '[Search Offers] Load Applied Profiles': null,
  '[Search Offers] Auto Apply Offer': IceRule.UpdateOfferProposal,
  '[Search Offers] Manual Apply Offer': IceRule.UpdateOfferProposal,
  '[Search Offers] Load All Candidates': IceRule.ReadProfile,

  /*********************************/
  /* JOBHUB */
  /* Search Offer Proposals */
  /*********************************/
  '[Search Offers] Load All Search Offers': IceRule.ReadOfferProposal,
  '[Search Offers] Load All Public Search Offers': IceRule.ReadOfferProposal,

  /*********************************/
  /* KANBAN */
  /* Kanbans */
  /*********************************/
  '[Kanbans] Load All Kanbans': IceRule.ReadKanban,
  '[Kanbans] Load Kanban By Id': IceRule.ReadKanban,
  '[Kanbans] Create Kanban': IceRule.CreateKanban,
  '[Kanbans] Update Kanban': IceRule.UpdateKanban,
  '[Kanbans] Delete Kanban': IceRule.DeleteKanban,
  '[Kanbans] Assign Kanban User': IceRule.UpdateKanban,
  '[Kanbans] Unassign Kanban User': IceRule.UpdateKanban,

  /*********************************/
  /* KANBAN */
  /* Kanban Columns */
  /*********************************/
  '[Kanban Columns] Load All Kanban Columns': IceRule.ReadKanbanColumn,
  '[Kanban Columns] Create Kanban Column': IceRule.CreateKanbanColumn,
  '[Kanban Columns] Update Kanban Column': IceRule.UpdateKanbanColumn,
  '[Kanban Columns] Delete Kanban Column': IceRule.DeleteKanbanColumn,
  '[Kanban Columns] Update Kanban Column Sequences': IceRule.UpdateKanbanColumn,

  /*********************************/
  /* KANBAN */
  /* Kanban Cards */
  /*********************************/
  '[Kanban Cards] Load All Kanban Cards': IceRule.ReadKanbanCard,
  '[Kanban Cards] Create Kanban Card': IceRule.CreateKanbanCard,
  '[Kanban Cards] Update Kanban Card': IceRule.UpdateKanbanCard,
  '[Kanban Cards] Delete Kanban Card': IceRule.DeleteKanbanCard,
  '[Kanban Cards] Update Kanban Card Sequences': IceRule.UpdateKanbanCard,
  '[Kanban Cards] Switch Kanban Card Column': IceRule.UpdateKanbanCard,
  '[Kanban Cards] Assign Kanban Card User': [
    IceRule.UpdateKanbanCard,
    IceRule.ReadUser,
  ],
  '[Kanban Cards] Unassign Kanban Card User': [
    IceRule.UpdateKanbanCard,
    IceRule.ReadUser,
  ],
  '[Kanban Cards] Assign Kanban Card Tag': [
    IceRule.UpdateKanbanCard,
    IceRule.ReadKanbanTag,
  ],
  '[Kanban Cards] Unassign Kanban Card Tag': [
    IceRule.UpdateKanbanCard,
    IceRule.ReadKanbanTag,
  ],

  /*********************************/
  /* Kanban Comments */
  /*********************************/
  '[Kanban Card Comments] Load All Card Comments': IceRule.ReadKanbanComment,
  '[Kanban Card Comments] Create Card Comment': IceRule.CreateKanbanComment,
  '[Kanban Card Comments] Update Card Comment': IceRule.UpdateKanbanComment,
  '[Kanban Card Comments] Delete Card Comment': IceRule.DeleteKanbanComment,

  /*********************************/
  /* Kanban Tasks */
  /*********************************/
  '[Kanban Card Tasks] Load All Kanban Card Tasks': IceRule.ReadKanbanTask,
  '[Kanban Card Tasks] Create Kanban Card Task': IceRule.CreateKanbanTask,
  '[Kanban Card Tasks] Update Kanban Card Task': IceRule.UpdateKanbanTask,
  '[Kanban Card Tasks] Delete Kanban Card Task': IceRule.DeleteKanbanTask,
  '[Kanban Card Tasks] Check Kanban Card Task': IceRule.UpdateKanbanTask,
  '[Kanban Card Tasks] Uncheck Kanban Card Task': IceRule.UpdateKanbanTask,

  /*********************************/
  /* Kanban Attachments */
  /*********************************/
  '[Kanban Card Attachments] Load All Kanban Card Attachments':
    IceRule.ReadKanbanAttachment,
  '[Kanban Card Attachments] Create Kanban Card Attachment':
    IceRule.CreateKanbanAttachment,
  '[Kanban Card Attachments] Update Kanban Card Attachment':
    IceRule.UpdateKanbanAttachment,
  '[Kanban Card Attachments] Delete Kanban Card Attachment':
    IceRule.DeleteKanbanAttachment,

  /*********************************/
  /* Kanban Tags */
  /*********************************/
  '[Kanban Tags] Load All Kanban Tags': IceRule.ReadKanbanTag,
  '[Kanban Tags] Create Kanban Tag': IceRule.CreateKanbanTag,
  '[Kanban Tags] Update Kanban Tag': IceRule.UpdateKanbanTag,
  '[Kanban Tags] Delete Kanban Tag': IceRule.DeleteKanbanTag,

  /*********************************/
  /* Kanban Users */
  /*********************************/
  '[Kanban Cards] Load Users By Kanban Card Id': [
    IceRule.ReadKanbanCard,
    IceRule.ReadUser,
  ],
  '[Kanban Users] Load Users': IceRule.ReadUser,

  /*********************************/
  /* Kanban Candidates */
  /*********************************/
  '[Kanban Candidates] Load Kanban Candidates': [
    IceRule.ReadKanbanCard,
    IceRule.ReadProfile,
  ],

  /*********************************/
  /* CLIENT COMPANY */
  /* Client Company */
  /*********************************/
  '[Client Company] Load All Client Companies': IceRule.ReadClientCompany,
  '[Client Company] Create Client Company': IceRule.CreateClientCompany,

  /*********************************/
  /* Client Company Profile */
  /*********************************/
  '[Client Company Profile] Load Client Company By Id':
    IceRule.ReadClientCompany,
  '[Client Company Profile] Update Client Company': IceRule.UpdateClientCompany,
  '[Client Company Profile] Delete Client Company': IceRule.DeleteClientCompany,

  /*********************************/
  /* Client Company Consultants */
  /*********************************/
  '[Client Company Consultants] Load Client Company Consultants': [
    IceRule.ReadClientCompany,
    IceRule.ReadProfile,
  ],

  /*********************************/
  /* Client Company Sales */
  /*********************************/
  '[Client Company Sales] Load Client Company Sales': [
    IceRule.ReadClientCompany,
    IceRule.ReadSale,
  ],

  /*********************************/
  /* Client Company Interviews */
  /*********************************/
  '[Client Company Interviews] Load Client Company Interviews':
    IceRule.ReadClientCompany,

  /*********************************/
  /* Client Company Notes */
  /*********************************/
  '[Client Company Notes] Load Client Company Notes':
    IceRule.ReadClientCompanyNote,
  '[Client Company Notes] Create Client Company Notes':
    IceRule.CreateClientCompanyNote,
  '[Client Company Notes] Update Client Company Notes':
    IceRule.UpdateClientCompanyNote,
  '[Client Company Notes] Delete Client Company Notes':
    IceRule.DeleteClientCompanyNote,

  /*********************************/
  /* Client Company Sector Jobs */
  /*********************************/
  '[Client Company Sector Jobs] Load Client Company Sector Jobs':
    IceRule.ReadClientCompanySectorJob,
  '[Client Company Sector Jobs] Assign Client Company Sector Job':
    IceRule.UpdateClientCompanySectorJob,
  '[Client Company Sector Jobs] Unassign Client Company Sector Job':
    IceRule.UpdateClientCompanySectorJob,

  /*********************************/
  /* Client Company Status */
  /*********************************/
  '[Client Company Status] Load Client Company Statuses':
    IceRule.ReadClientCompanyStatuses,
  '[Client Company Status] Create Client Company Status':
    IceRule.CreateClientCompanyStatuses,
  '[Client Company Status] Update Client Company Status':
    IceRule.UpdateClientCompanyStatuses,
  '[Client Company Status] Delete Client Company Status':
    IceRule.DeleteClientCompanyStatuses,
  '[Client Company Status] Update Client Company Status Sequence':
    IceRule.UpdateClientCompanyStatuses,

  /*********************************/
  /* PROFILE */
  /* Two Factor Authentication */
  /*********************************/
  '[Profile 2FA] Create Two Factor Authentication': null,
  '[Profile 2FA] Confirm Two Factor Authentication': null,
  '[Profile 2FA] Disable Two Factor Authentication By TOTP': null,
  '[Profile 2FA] Disable Two Factor Authentication By Recovery': null,

  /*********************************/
  /* SHUFFLE LIST MODULE */
  /* Shuffle list */
  /*********************************/
  '[Shuffle List] Load All Shuffle List': IceRule.ReadShuffleList,
  '[Shuffle List] Create Shuffle List': IceRule.CreateShuffleList,
  '[Shuffle List] Update Shuffle List': IceRule.UpdateShuffleList,
  '[Shuffle List] Delete Shuffle List': IceRule.DeleteShuffleList,
  '[Shuffle List] Assign Team': IceRule.UpdateShuffleList,
  '[Shuffle List] Unassign Team': IceRule.UpdateShuffleList,
  '[Shuffle List] Get shuffle list by Id': IceRule.ReadShuffleList,
  '[Shuffle List] Get random Team': [IceRule.ReadShuffleList, IceRule.ReadTeam],
  '[Shuffle List] Load All Teams ShuffleList': IceRule.ReadTeam,

  /*********************************/
  /* SHARED MODULE */
  /* Languages */
  /*********************************/
  '[Languages] Load Languages': IceRule.ReadProfileLanguage,

  /*********************************/
  /* Skills */
  /*********************************/
  '[All Skills] Load All Skills': IceRule.ReadProfileSkill,

  /*********************************/
  /* Data */
  /*********************************/
  '[Data Actions] Load Candidates': IceRule.ReadProfile,
  '[Data Actions] Load Candidate Statuses': IceRule.ReadProfileStatus,
  '[Data Actions] Load Candidate Tags': IceRule.ReadProfileTag,
  '[Data Actions] Load Sales Statuses': IceRule.ReadSaleStatus,
  '[Data Actions] Load Note Categories': IceRule.ReadNoteCategory,
  '[Data Actions] Load Categories With Sectors': IceRule.ReadSectorJobCategory,
  '[Data Actions] Load Offices': IceRule.ReadOffice,
  '[Data Actions] Load Industries': IceRule.ReadIndustry,
  '[Data Actions] Load Companies': IceRule.ReadCompany,
  '[Data Actions] Load Company References': IceRule.ReadCompanyReference,
  '[Data Actions] Load Teams': IceRule.ReadTeam,
  '[Data Actions] Load Users': IceRule.ReadUser,
  '[Data Actions] Load Timelines': IceRule.ReadTimeline,

  /*********************************/
  /* Navigation */
  /*********************************/
  '[Navigation] Load All Available Modules': null,
  '[Navigation] Load All Activated Modules': null,
  '[Navigation] Load Current Modules': null,
  '[Navigation] Change Current Modules': null,
  '[Navigation] Change Current Modules Success': null,

  /*********************************/
  /* HOME MODULE */
  /* Module activation */
  /*********************************/
  '[Home] Load Modules': null,
  '[Home] Load Active Module': null,
  '[Home] Activate Module': null,
};
