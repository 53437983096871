import { createSelector } from '@ngrx/store';
import { selectKanbanState } from '~/app/states/main/kanban/kanban.selectors';

export const selectKanbanUsersState = createSelector(
  selectKanbanState,
  state => state.users
);

export const selectUsers = createSelector(
  selectKanbanUsersState,
  state => state.users
);

export const selectUsersData = createSelector(
  selectUsers,
  state => state?.data ?? []
);
