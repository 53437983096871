import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ResetPasswordActions from '~/app/auth/state/reset-password/reset-password.actions';
import * as AuthActions from '~/app/auth/auth.actions';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ResetPasswordEffects` orchestrates the side effects related to password reset functionality within the application.
 * Utilizing the NgRx effects library, this class responds to actions related to the password reset process,
 * managing asynchronous operations such as HTTP requests to handle user requests for password resets,
 * password change confirmations, and the application of new passwords.
 *
 * The effects managed in this class include:
 * - Initiating the password reset process when a user forgets their password.
 * - Handling success and failure of password reset requests.
 * - Verifying reset password tokens.
 * - Applying new passwords after token verification.
 * - Redirecting users after successful or failed operations.
 *
 * @Injectable Marks the class as a provider that can be injected into other components and services, making it available throughout the application.
 */
@Injectable()
export class ResetPasswordEffects {
  /**
   * Effect to handle password reset initiation. It listens for the `resetPassword` action,
   * performs an HTTP POST request to send the user's email to the server, and handles the response
   * by dispatching either a success, or a failure action.
   */
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPasswordActions.resetPassword),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ forgotPasswordData }) =>
        this.http
          .post(
            `${environment.apiUrl}/v1/auth/forgotPassword`,
            forgotPasswordData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => ResetPasswordActions.resetPasswordSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ResetPasswordActions.resetPasswordFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect users upon successful password reset request. It listens for the `resetPasswordSuccess` action
   * and uses the Angular Router to navigate to the 'email-sent' route.
   */
  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPasswordActions.resetPasswordSuccess),
        tap(() => this.router.navigate(['/email-sent']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to verify the reset password token. It listens for the `checkResetPassword` action and performs an HTTP GET
   * request to verify the token. It then handles the response by dispatching a success or failure action based on the result.
   */
  checkResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPasswordActions.checkResetPassword),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ token }) =>
        this.http
          .get(
            `${environment.apiUrl}/v1/auth/forgotPassword/checking?token=${token}`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              ResetPasswordActions.checkResetPasswordSuccess({ token })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ResetPasswordActions.checkResetPasswordFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to redirect to the forgot password page upon a failed token verification.
   */
  checkResetPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPasswordActions.checkResetPasswordFailure),
        tap(() => this.router.navigate(['/forgot-password']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle applying new password. It listens for the `patchPassword` action,
   * performs an HTTP PATCH request to update the user's password, and handles the response
   * by dispatching either a success or a failure action.
   */
  patchPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPasswordActions.patchPassword),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ token, patchPasswordData }) =>
        this.http
          .patch<AuthUser>(
            `${environment.apiUrl}/v1/auth/forgotPassword/reset/${token}`,
            patchPasswordData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData =>
              ResetPasswordActions.patchPasswordSuccess({ userData })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ResetPasswordActions.patchPasswordFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to navigate to the CRM dashboard upon successful password update.
   */
  patchPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPasswordActions.patchPasswordSuccess),
        tap(() => this.router.navigate(['/crm']))
      ),
    { dispatch: false }
  );

  /**
   * Constructs the `TwoFactorAuthEffects` instance.
   *
   * @param {Actions} actions$ Observable of actions, serving as the source of NgRx effects.
   * @param {HttpClient} http Client for making API calls.
   * @param {Router} router Service for navigating between routes.
   * @param {HttpErrorsService} httpErrors Service for managing errors from HTTP requests.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private httpErrors: HttpErrorsService
  ) {}
}
