<section
  class="min-h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="absolute left-0 top-0 ml-3 mt-3">
    <a
      routerLink="/login-secure"
      class="p-button p-button-text p-component no-underline flex gap-3">
      <span class="p-button-icon pi pi-arrow-left"></span>
      <span i18n class="p-button-label">Login</span>
    </a>
  </div>

  <div
    class="surface-card p-4 shadow-2 border-round min-w-20rem w-30rem bg-cover">
    <div class="text-center mb-5">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="50"
        class="mb-3" />
      <div i18n class="text-900 text-3xl font-medium mb-3">
        Disable Two-Factor Authentication
      </div>
      <span i18n class="text-600 font-medium line-height-3">
        Enter your subdomain, email and recovery code to disable 2FA.
      </span>
    </div>

    <form [formGroup]="disableForm" (ngSubmit)="onSubmit()">
      <div>
        <ice-input
          [label]="'Organization'"
          [placeholder]="'Organization name'"
          [formControlName]="'subdomain'"
          [formControl]="subdomain"
          [applyLowercase]="true">
        </ice-input>

        <ice-input
          [label]="'Email'"
          [placeholder]="'Email address'"
          [formControlName]="'email'"
          [formControl]="email">
        </ice-input>
        <ice-password
          [label]="'Recovery code'"
          [placeholder]="'Recovert code'"
          [formControlName]="'recoveryCode'"
          [formControl]="recoveryCode">
        </ice-password>

        <div class="mt-5">
          <ice-button
            [style]="{ width: '100%' }"
            label="Disable 2FA"
            i18n-label
            icon="pi pi-lock-open"
            i18n-icon
            type="submit"
            [disabled]="!disableForm.valid"
            [loading]="(isLoading$ | async) ?? false" />
        </div>
      </div>
    </form>
  </div>
</section>
