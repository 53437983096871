import { SectorCategory } from '~/app/shared/interfaces/shared/sector/sector-category.interface';
import { SectorJob } from '~/app/shared/interfaces/shared/sector/sector-job.interface';

/**
 * Represents the state of sector categories within the application.
 *
 * @interface SectorCategoryState
 */
export interface SectorCategoryState {
  /**
   * The list of all sector categories.
   *
   * @type {SectorCategory[]}
   */
  sectorCategories: SectorCategory[];

  /**
   * The list of all sector jobs.
   *
   * @type {SectorJob[]}
   */
  allSectorJobs: SectorJob[];

  /**
   * The list of sector jobs in the currently selected category, or null if none selected.
   *
   * @type {SectorJob[] | null}
   */
  selectedCategory: SectorJob[] | null;

  /**
   * The list of sector jobs filtered by some criteria, or null if no filter applied.
   *
   * @type {SectorJob[] | null}
   */
  filteredSectorJobs: SectorJob[] | null;

  /**
   * Indicates whether sector category data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message if an error occurred, or null if no error.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of sector categories within the application.
 *
 * @constant {SectorCategoryState} initialSectorCategoryState
 * @description Provides a baseline definition for the sector categories context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSectorCategoryState: SectorCategoryState = {
  sectorCategories: [],
  allSectorJobs: [],
  selectedCategory: null,
  filteredSectorJobs: null,
  loading: false,
  error: null,
};
