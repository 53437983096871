<form
  [formGroup]="shuffleForm"
  class="flex flex-column align-items-start w-19-25rem gap-3-5 family-monserrat">
  <ice-input
    [label]="'Title'"
    [placeholder]="'Title'"
    [required]="true"
    [formControlName]="'name'"
    [formControl]="name"></ice-input>
  <ice-input
    [textArea]="true"
    [label]="'Description'"
    [placeholder]="'Description'"
    [required]="true"
    [formControlName]="'description'"
    [formControl]="description"></ice-input>
  <ng-container *ngIf="initialData">
    <div class="flex flex-column w-full">
      <ice-autocomplete
        [formControl]="teams"
        [formControlName]="'teams'"
        [multiple]="true"
        placeholder="Select Teams"
        i18n-placeholder
        [dropdown]="false"
        (onSelect)="onAssignTeam($event)"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [suggestions]="(teams$ | async) ?? []"></ice-autocomplete>
      <div class="flex flex-wrap gap-2 pb-2">
        <ng-container *ngFor="let team of (shuffleTeams$ | async)?.teams">
          <span
            class="flex align-items-center text-sm block px-2 bg-fancy-lime border-round font-medium">
            {{ team.name }}
            <i
              class="pi pi-times-circle cursor-pointer p-2"
              (click)="onUnassignTeam(team.uuid)"></i>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <p-multiSelect
    label="'Days of the week'"
    [options]="daysOfWeekEnum"
    placeholder="Days of the week"
    [required]="true"
    [style]="{ width: 'full' }"
    [formControlName]="'daysOfWeek'"
    [formControl]="daysOfWeek" />

  <div class="flex w-full flex-row-reverse justify-content-between gap-2 mt-3">
    <ice-button
      (onClick)="onSubmit()"
      icon="pi pi-check"
      i18n-icon
      [label]="initialData ? 'Update' : 'Create'"
      [disabled]="!shuffleForm.valid || !isFormDirty"
      [loading]="(isShuffleListLoading$ | async) ?? false"></ice-button>
    <ice-button
      *ngIf="initialData"
      [text]="true"
      label="Delete"
      i18n-label
      severity="danger"
      i18n-severity
      (onClick)="onDelete()"></ice-button>
  </div>
</form>
