import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanColumnsActions from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.actions';
import * as KanbanTagsActions from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.actions';
import { KanbanTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';
import { KanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.state';

/**
 * `KanbanTagsEffects` manages the side effects for kanban tag-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban tags,
 * such as loading all kanban tags, creating, updating, and deleting kanban tags.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllKanbanTags$`: Loads all kanban tags. It performs an HTTP GET request and dispatches a success action with the fetched tags or a failure action if an error occurs.
 * — `createKanbanTag$`: Creates a new kanban tag. It performs an HTTP POST request and dispatches a success action with the created tag or a failure action if an error occurs.
 * — `updateKanbanTag$`: Updates an existing kanban tag. It performs an HTTP PATCH request and dispatches a success action with the updated tag or a failure action if an error occurs.
 * — `deleteKanbanTag$`: Deletes a kanban tag. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteKanbanTagsSuccess$`: Handles the success of deleting a kanban tag by displaying a success toast message and reloading kanban columns.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanTagsEffects {
  /**
   * Effect to load all kanban tags for a given kanban ID.
   * Listens for the `loadAllKanbanTags` action, checks permissions, and performs an HTTP GET request to fetch the tags.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbanTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanTagsActions.loadAllKanbanTags),
      checkPermission(KanbanTagsActions.kanbanTagsUnauthorized),
      mergeMap(({ kanbanId }) =>
        this.http
          .get<KanbanTag[]>(`${environment.apiUrl}/v1/kanbans/tags`, {
            params: { kanbanId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanbanTags =>
              KanbanTagsActions.loadAllKanbanTagsSuccess({ kanbanTags })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanTagsActions.loadAllKanbanTagsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban tag.
   * Listens for the `createKanbanTag` action, checks permissions, and performs an HTTP POST request to create the tag.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanTagsActions.createKanbanTag),
      checkPermission(KanbanTagsActions.kanbanTagsUnauthorized),
      mergeMap(({ kanbanCardId, kanbanTagData }) => {
        let params = new HttpParams();
        if (kanbanCardId) params = params.append('kanbanCardId', kanbanCardId);

        return this.http
          .post<KanbanTag>(
            `${environment.apiUrl}/v1/kanbans/tags`,
            kanbanTagData,
            {
              params,
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(kanbanTag =>
              KanbanTagsActions.createKanbanTagSuccess({ kanbanTag })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanTagsActions.createKanbanTagFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to update an existing kanban tag.
   * Listens for the `updateKanbanTag` action, checks permissions, and performs an HTTP PATCH request to update the tag.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanTagsActions.updateKanbanTag),
      checkPermission(KanbanTagsActions.kanbanTagsUnauthorized),
      mergeMap(({ uuid, kanbanTagData }) =>
        this.http
          .patch<KanbanTag>(
            `${environment.apiUrl}/v1/kanbans/tags/${uuid}`,
            kanbanTagData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanTag =>
              KanbanTagsActions.updateKanbanTagSuccess({ uuid, kanbanTag })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanTagsActions.updateKanbanTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to delete an existing kanban tag.
   * Listens for the `deleteKanbanTag` action, checks permissions, and performs an HTTP DELETE request to delete the tag.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanTagsActions.deleteKanbanTag),
      checkPermission(KanbanTagsActions.kanbanTagsUnauthorized),
      mergeMap(({ uuid, kanbanId }) =>
        this.http
          .delete<KanbanColumnsState>(
            `${environment.apiUrl}/v1/kanbans/tags/${uuid}`,
            {
              body: { kanbanId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(kanbanColumns =>
              KanbanTagsActions.deleteKanbanTagSuccess({ uuid, kanbanColumns })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanTagsActions.deleteKanbanTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban tag.
   * Displays a success toast message and reloads kanban columns.
   * Dispatches a `loadAllKanbanColumnsSuccess` action.
   */
  deleteKanbanTagsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanTagsActions.deleteKanbanTagSuccess),
        map(({ kanbanColumns }) =>
          KanbanColumnsActions.loadAllKanbanColumnsSuccess({
            kanbanColumns,
          })
        ),
        tap(() => {
          this.toast.successToast('Success', 'Kanban tag deleted successfully');
        })
      ),
    { dispatch: true }
  );

  /**
   * Constructor for `KanbanTagsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
