import { createReducer, on } from '@ngrx/store';
import { initialClientCompanyInterviewsState } from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.state';
import * as ClientCompanyInterviews from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.actions';

export const clientCompanyInterviewsReducer = createReducer(
  initialClientCompanyInterviewsState,

  on(ClientCompanyInterviews.loadClientCompanyInterviews, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyInterviews.loadClientCompanyInterviewsSuccess,
    (state, { clientCompanyInterviews }) => ({
      ...state,
      ...clientCompanyInterviews,
      loading: false,
    })
  ),
  on(
    ClientCompanyInterviews.loadClientCompanyInterviewsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCompanyInterviews.clientCompanyInterviewsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
