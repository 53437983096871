import { Component } from '@angular/core';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * A component that displays a message indicating that an email has been sent successfully.
 * This could be used in various parts of an application where email sending confirmation is required.
 * The component utilizes a service `ThemeService` to possibly adjust its theme based on the current application settings.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-email-sent' - The CSS selector that identifies this component in a template.
 * @templateUrl './email-sent.component.html' - The path to the HTML template associated with this component.
 * @styleUrls ['./email-sent.component.scss'] - The path to the styles for this component.
 *
 * @example
 * Example of Usage:
 * This component can be embedded into any Angular template that requires a notification message after sending an email.
 * It can be particularly useful in user registration flows, password resets, or other scenarios where email communication is confirmed.
 *
 * Example of embedding the `EmailSentComponent` in an Angular template:
 *
 * ```html
 *  <div *ngIf="emailSent">
 *    <app-email-sent></app-email-sent>
 *  </div>
 * ```
 *
 * Here, `emailSent` would be a boolean property in your component that gets set to true once the email is successfully sent.
 * The `EmailSentComponent` would then display a confirmation message styled according to the current theme.
 *
 */
@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
})
export class EmailSentComponent {
  /**
   * Constructor that injects the ThemeService to be used within the component.
   * The service is exposed as a public member, implying it could be accessed from the component's template.
   * This is useful for applying theme-related classes or styles directly in the template based on the active theme.
   *
   * @param {ThemeService} theme - The injected service that manages theme switching and state within the application.
   */
  constructor(public theme: ThemeService) {}
}
