<p-sidebar
  [(visible)]="isVisible"
  position="right"
  i18n-position
  [fullScreen]="fullScreen"
  styleClass="sidebar-dimensions md:min-w-max bg-light-100"
  i18n-styleClass>
  <ng-template #headerTemplate pTemplate="header">
    <app-sidebar-header></app-sidebar-header>
  </ng-template>

  <div class="p-3 pb-0 h-full">
    <ng-template appSidebarHost></ng-template>
  </div>
  <ng-template pTemplate="footer"></ng-template>
</p-sidebar>
