import { createAction, props } from '@ngrx/store';
import {
  CreateProfileReferences,
  UpdateProfileReferences,
} from '~/app/states/main/crm/profile/types/profile-references.types';
import { ProfileReference } from '~/app/shared/interfaces/crm/profile/profile-reference.interface';

export const loadProfileReferences = createAction(
  '[Profile References] Load Profile References',
  props<{ profileId: string }>()
);
export const loadProfileReferencesSuccess = createAction(
  '[Profile References] Load Profile References Success',
  props<{ profileReferences: ProfileReference[] }>()
);
export const loadProfileReferencesFailure = createAction(
  '[Profile References] Load Profile References Failure',
  props<{ error: string }>()
);

export const createProfileReference = createAction(
  '[Profile References] Create Profile Reference',
  props<{ profileReference: CreateProfileReferences }>()
);
export const createProfileReferenceSuccess = createAction(
  '[Profile References] Create Profile Reference Success',
  props<{ profileReference: ProfileReference }>()
);
export const createProfileReferenceFailure = createAction(
  '[Profile References] Create Profile Reference Failure',
  props<{ error: string }>()
);

export const updateProfileReference = createAction(
  '[Profile References] Update Profile Reference',
  props<{ uuid: string; profileReference: UpdateProfileReferences }>()
);
export const updateProfileReferenceSuccess = createAction(
  '[Profile References] Update Profile Reference Success',
  props<{ profileReference: ProfileReference }>()
);
export const updateProfileReferenceFailure = createAction(
  '[Profile References] Update Profile Reference Failure',
  props<{ error: string }>()
);

export const deleteProfileReference = createAction(
  '[Profile References] Delete Profile Reference',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileReferenceSuccess = createAction(
  '[Profile References] Delete Profile Reference Success',
  props<{ uuid: string }>()
);
export const deleteProfileReferenceFailure = createAction(
  '[Profile References] Delete Profile Reference Failure',
  props<{ error: string }>()
);

export const profileReferenceUnauthorized = createAction(
  '[Profile References] Unauthorized',
  props<{ error: string }>()
);
