import { Injectable } from '@angular/core';
import { deflate, inflate } from 'pako';
import { FormValues } from '~/app/shared/interfaces/generic/form-values.interface';

/**
 * Service for compressing and decompressing filter settings and other form values using JSON.
 * This service uses the pako library to deflate and inflate JSON data to and from a compressed format,
 * making it suitable for efficient storage or transmission, such as in URLs.
 *
 * @Injectable marks this class as available to be provided and injected as a singleton
 * within the root injector.
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  /**
   * Compresses a JSON object containing form values and encodes it into a base64 string that is safe for URL use.
   * The method serializes the JSON, compresses it, and then encodes it in base64,
   * replacing characters that are not URL safe.
   *
   * @param {FormValues} uncompressedFilters - The uncompressed form values to be compressed.
   * @returns {string} A base64 encoded string of the compressed JSON that is safe to use in URLs.
   */
  compressJSON(uncompressedFilters: FormValues): string {
    const filtersObject = {
      my: uncompressedFilters,
      puper: [456, 567],
      awesome: 'pako',
    };
    const compressed = deflate(JSON.stringify(filtersObject));
    let compressedBase64 = btoa(
      String.fromCharCode.apply(null, Array.from(compressed))
    );
    // Replace + and / to make them safe for the URL
    compressedBase64 = compressedBase64.replace(/\+/g, '-').replace(/\//g, '_');
    return compressedBase64;
  }

  /**
   * Decompresses a JSON object from a base64 encoded string that was compressed using deflate.
   * This method reverses the operations done by compressJSON, restoring the original JSON object.
   *
   * @param {string} compressedFilters - The compressed and base64 encoded string to be decompressed.
   * @returns {JSON} The decompressed JSON object.
   */
  unCompressJSON(compressedFilters: string): JSON {
    // Replace - and _ with the original characters + and /
    const decodedBase64 = compressedFilters
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const compressedArray = Uint8Array.from(atob(decodedBase64), c =>
      c.charCodeAt(0)
    );
    const restored = JSON.parse(inflate(compressedArray, { to: 'string' }));
    return restored.my;
  }

  /**
   * Decodes a base64 encoded string that has been URL-encoded.
   * This is useful for handling data passed through URLs where characters may be percentage encoded.
   *
   * @param {string} encodedString - The encoded string to be decoded.
   * @returns {string} The decoded string.
   */
  decodeBase64FromURL(encodedString: string): string {
    // Use decodeURIComponent to convert percentage into classic character
    return decodeURIComponent(encodedString);
  }
}
