import { createAction, props } from '@ngrx/store';
import {
  ForgotPasswordResetUser,
  ForgotPasswordUser,
} from '~/app/auth/types/reset-password.types';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';

export const resetPassword = createAction(
  '[Auth] Reset Password',
  props<{ forgotPasswordData: ForgotPasswordUser }>()
);
export const resetPasswordSuccess = createAction(
  '[Auth] Reset Password Success'
);
export const resetPasswordFailure = createAction(
  '[Auth] Reset Password Failure',
  props<{ error: string }>()
);

export const checkResetPassword = createAction(
  '[Aut] Check Reset Password',
  props<{ token: string }>()
);
export const checkResetPasswordSuccess = createAction(
  '[Auth] Check Reset Password Success',
  props<{ token: string }>()
);
export const checkResetPasswordFailure = createAction(
  '[Auth] Check Reset Password Failure',
  props<{ error: string }>()
);

export const patchPassword = createAction(
  '[Auth] Patch Password',
  props<{ token: string; patchPasswordData: ForgotPasswordResetUser }>()
);
export const patchPasswordSuccess = createAction(
  '[Auth] Patch Password Success',
  props<{ userData: AuthUser }>()
);
export const patchPasswordFailure = createAction(
  '[Auth] Patch Password Failure',
  props<{ error: string }>()
);
