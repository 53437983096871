import { createAction, props } from '@ngrx/store';
import { Language } from '~/app/shared/interfaces/shared/language.interface';

export const loadLanguages = createAction('[Languages] Load Languages');
export const loadLanguagesSuccess = createAction(
  '[Languages] Load Languages Success',
  props<{ languages: Language[] }>()
);
export const loadLanguagesFailure = createAction(
  '[Languages] Load Languages Failure',
  props<{ error: string }>()
);

export const languagesUnauthorized = createAction(
  '[Languages] Unauthorized',
  props<{ error: string }>()
);
