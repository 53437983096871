import { createAction, props } from '@ngrx/store';
import { DisableTwoFactorAuthByRecovery } from '~/app/auth/types/two-factor-auth.types';

export const disableTwoFactorAuthenticationByRecovery = createAction(
  '[Auth] Disable Two-Factor Authentication By Recovery',
  props<{ disableData: DisableTwoFactorAuthByRecovery }>()
);
export const disableTwoFactorAuthenticationByRecoverySuccess = createAction(
  '[Auth] Disable Two-Factor Authentication By Recovery Success'
);
export const disableTwoFactorAuthenticationByRecoveryFailure = createAction(
  '[Auth] Disable Two-Factor Authentication By Recovery',
  props<{ error: string }>()
);

export const updateTwoFactorAuthentication = createAction(
  '[Auth] Activate 2FA',
  props<{ twoFactorActivate: boolean }>()
);
