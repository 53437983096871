import { Profile } from '~/app/shared/interfaces/crm/profile/profile.interface';

/**
 * Represents the state of a profile.
 *
 * @interface ProfileState
 */
export interface ProfileState {
  /**
   * The profile data.
   *
   * @type {Profile | null}
   */
  profile: Profile | null;

  /**
   * Indicates if the profile data is being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message if an error occurred while loading the profile data.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the profile.
 *
 * @type {ProfileState}
 */
export const initialProfileState: ProfileState = {
  profile: null,
  loading: false,
  error: null,
};
