import { ClientCompanySectorJobs } from '~/app/shared/interfaces/client-company/profile/client-company-sector-jobs.interface';

/**
 * Interface representing the state of client company sector jobs.
 *
 * @interface ClientCompanySectorJobsState
 */
export interface ClientCompanySectorJobsState {
  /**
   * Array of client company sector jobs.
   *
   * @type {ClientCompanySectorJobs[]}
   * @memberof ClientCompanySectorJobsState
   */
  clientCompanySectorJobs: ClientCompanySectorJobs[];

  /**
   * Indicates if the sector jobs are being loaded.
   *
   * @type {boolean}
   * @memberof ClientCompanySectorJobsState
   */
  loading: boolean;

  /**
   * Error message, if any, related to loading sector jobs.
   *
   * @type {string | null}
   * @memberof ClientCompanySectorJobsState
   */
  error: string | null;
}

/**
 * Initial state for client company sector jobs.
 *
 * @const {ClientCompanySectorJobsState}
 */
export const initialClientCompanySectorJobsState: ClientCompanySectorJobsState =
  {
    clientCompanySectorJobs: [],
    loading: false,
    error: null,
  };
