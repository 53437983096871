import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

/**
 * Represents the state for an offer proposal.
 *
 * @interface OfferProposalState
 */
export interface OfferProposalState {
  /**
   * The offer being proposed.
   *
   * @type {Offer | null}
   */
  offer: Offer | null;

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for an offer proposal.
 *
 * @type {OfferProposalState}
 */
export const initialOfferProposalState: OfferProposalState = {
  offer: null,
  loading: false,
  error: null,
};
