import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from '~/app/auth/services/register.service';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * Component responsible for handling user registration. It provides a form where users can enter their first name, last name,
 * and password to register for an account. The component uses Angular's reactive forms for handling form state and validation,
 * and it interacts with the RegisterService to submit registration requests.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-user-register' - The CSS selector that identifies this component in a template.
 * @templateUrl './user-register.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./user-register.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss'],
})
export class UserRegisterComponent implements OnInit {
  /**
   * FormGroup to manage the registration form
   * @type {FormGroup}
   */
  userRegisterForm!: FormGroup;

  /**
   * Constructs the UserRegisterComponent with the necessary dependencies.
   *
   * @param {FormBuilder} formBuilder - Service to create FormGroup based on provided configuration.
   * @param {RegisterService} registerService - Service to handle the registration logic.
   * @param {ActivatedRoute} activatedRoute - Service that contains the route information.
   * @param {ThemeService} theme - Service to manage theming across the application.
   */
  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    public theme: ThemeService
  ) {
    this.initForm();
  }

  /**
   * Getter for the 'firstname' form control.
   * @return {FormControl} - The form control instance for the firstname.
   */
  get firstname(): FormControl {
    return this.userRegisterForm.get('firstname') as FormControl;
  }

  /**
   * Getter for the 'lastname' form control.
   * @return {FormControl} - The form control instance for the lastname.
   */
  get lastname(): FormControl {
    return this.userRegisterForm.get('lastname') as FormControl;
  }

  /**
   * Getter for the 'password' form control.
   * @return {FormControl} - The form control instance for the password.
   */
  get password(): FormControl {
    return this.userRegisterForm.get('password') as FormControl;
  }

  /**
   * OnInit lifecycle hook that is called after Angular has initialized all data-bound properties.
   * Used to check invitation token for registration.
   * @return {void}
   */
  ngOnInit(): void {
    this.registerService.checkInvitation(
      this.activatedRoute.snapshot.params['token']
    );
  }

  /**
   * Initializes the user registration form with necessary form controls and validators.
   * @return {void}
   */
  initForm(): void {
    this.userRegisterForm = this.formBuilder.nonNullable.group({
      firstname: ['', [Validators.required, Validators.minLength(1)]],
      lastname: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  /**
   * Handles form submission, collects the form data, and submits it to the registration service.
   * Passes the registration data along with the token to the registration service.
   * @return {void}
   */
  onSubmit(): void {
    const userRegisterData = { ...this.userRegisterForm.value };

    this.registerService.userRegister(
      this.activatedRoute.snapshot.params['token'],
      userRegisterData
    );
  }
}
