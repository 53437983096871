import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
  AutoCompleteUnselectEvent,
} from 'primeng/autocomplete';
import { Observable, Subject, take } from 'rxjs';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';

/**
 * `IceAutocompleteComponent` is an Angular component that provides an enhanced input field for autocompletion.
 * It leverages PrimeNG's AutoComplete component to offer a feature-rich and customizable autocomplete experience.
 * This component can be configured to work with single or multiple selection, supports dropdown mode, custom templates,
 * and integrates with Angular forms.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-autocomplete' - The CSS selector that identifies this component.
 * @templateUrl './ice-autocomplete.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-autocomplete.component.scss'] - Path to the styles for this component.
 * @providers NG_VALUE_ACCESSOR - Dependency injection token that allows the component to act as a form control.
 */
@Component({
  selector: 'ice-autocomplete',
  templateUrl: './ice-autocomplete.component.html',
  styleUrls: ['./ice-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceAutocompleteComponent),
      multi: true,
    },
  ],
})
export class IceAutocompleteComponent
  implements OnInit, AfterViewInit, OnChanges
{
  /**
   * ViewChild directive to access the AutoComplete component instance.
   * @type {AutoComplete}
   */
  @ViewChild(AutoComplete) autoComplete!: AutoComplete;

  /**
   * Label for the autocomplete input field.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Placeholder text for the autocomplete input.
   * @type {string}
   */
  @Input() placeholder!: string;

  /**
   * FormControl that is connected to the autocomplete input.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * Name of the form control within a form group.
   * @type {string | undefined}
   */
  @Input() formControlName!: string;

  /**
   * Enables multiple selection mode.
   * @type {boolean}
   */
  @Input() multiple = false;

  /**
   * Enables the dropdown mode which shows a dropdown button to display suggestions.
   * @type {boolean}
   */
  @Input() dropdown = true;

  /**
   * Label field of the suggestion object.
   * @type {string}
   */
  @Input() optionLabel!: string;

  /**
   * Value field of the suggestion object.
   * @type {string}
   */
  @Input() optionValue!: string;

  /**
   * Array of suggestions to display.
   * @type {(unknown & LabelValueInterface)[]}
   */
  @Input() suggestions: (unknown & LabelValueInterface)[] = [];

  /**
   * Error message to display for the input field.
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Specifies if the autocomplete is a required field.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Specifies the group mode for options.
   * @type {boolean}
   */
  @Input() group = false;

  /**
   * Specifies if the autocomplete should be disabled.
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Field used to identify the selected item.
   * @type {string}
   */
  @Input() selectField!: string;

  /**
   * Optional ID for the input element.
   * @type {string | undefined}
   */
  @Input() inputId: string | undefined;

  /**
   * Specifies the rule for permission checks.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Defines the behavior when the user does not have the required permission.
   * @type {PermissionBehaviourEnum | undefined}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum | undefined =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Specifies the template to use for autocomplete items.
   * @type {IceAutocompleteTemplateEnum}
   */
  @Input() iceAutocompleteTemplate: IceAutocompleteTemplateEnum =
    IceAutocompleteTemplateEnum.DEFAULT;

  /**
   * Event emitted when the user search with input.
   * @type {EventEmitter<AutoCompleteSelectEvent>}
   */
  @Output() completeMethod = new EventEmitter<AutoCompleteCompleteEvent>();

  /**
   * Event emitted when an item is selected.
   * @type {EventEmitter<AutoCompleteSelectEvent>}
   */
  @Output() onSelect = new EventEmitter<AutoCompleteSelectEvent>();

  /**
   * Event emitted when an item is unselected.
   * @type {EventEmitter<AutoCompleteUnselectEvent>}
   */
  @Output() onUnselect = new EventEmitter<AutoCompleteUnselectEvent>();

  /**
   * Event emitter that emits an event when the clear action is triggered.
   * @type {EventEmitter<Event>}
   */
  @Output() onClear = new EventEmitter<Event>();

  /**
   * Internal array to hold filtered suggestions based on the query.
   * @type {LabelValueInterface[]}
   */
  filteredSuggestions: LabelValueInterface[] = [];

  /**
   * Current value of the autocomplete.
   * @type {string | null}
   */
  value!: string | null;

  /**
   * Observable stream for the suggestions.
   * @type {Observable<LabelValueInterface[]>}
   */
  suggestions$: Observable<LabelValueInterface[]>;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * A protected property to reference the `IceAutocompleteTemplateEnum`.
   * This enumeration holds the template configuration options available for the autocomplete component.
   * It is used internally within the component to configure the rendering and behavior of the autocomplete suggestions.
   * Being `readonly` ensures that the value cannot be modified after initialization.
   *
   * @type {IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Subject to trigger updates in the suggestions observable.
   * @type {Subject<LabelValueInterface[]>}
   */
  private suggestionsSubscription = new Subject<LabelValueInterface[]>();

  /**
   * Constructor initializes services and setups initial values.
   * @param {PermissionsService} permissionService - The service used to manage permissions.
   * @param {ElementRef} element - The host element reference for this component.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {
    this.suggestions$ = this.suggestionsSubscription.asObservable();
  }

  /**
   * Lifecycle hook that is called after Angular fully initializes the component's views and child views. It checks for
   * permissions related to the component and either hides or disables the component based on the permission rules set.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule) {
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            this.element.nativeElement.removeAllListeners('click');
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.NONE:
                this.hideElement(); // Hides the component if no specific action should be taken
                break;
              case PermissionBehaviourEnum.DISABLE:
              default:
                this.disableElement(); // Disables the component if no specific behavior is set
            }
          }
        });
    }
  }

  /**
   * Lifecycle hook called whenever any data-bound property of a directive changes.
   * This method ensures that the component's internal state reflects any changes to its input properties.
   * Specifically, it updates the list of filtered suggestions whenever the 'suggestions' input changes.
   * @return {void}
   */
  ngOnChanges(): void {
    this.suggestionsSubscription.next(this.suggestions); // Update the suggestions observable
    this.filteredSuggestions = this.suggestions; // Directly update the filtered suggestions array
  }

  /**
   * Lifecycle hook called once, after the first ngOnChanges(). This hook initializes the filtered suggestions based on
   * the initial input, ensuring that the component starts with a correctly filtered set of suggestions.
   * @return {void}
   */
  ngOnInit(): void {
    this.filteredSuggestions = this.suggestions; // Initialize with a copy of the initial suggestions
  }

  /**
   * Triggers the touch event callback when the autocomplete element gains focus.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
    if (this.multiple) this.autoComplete.show();
  }

  /**
   * Triggers the touch event callback when the autocomplete element loses focus.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a function to be called when the input value changes.
   * @param {AnyVoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Clears the AutoComplete input.
   * @return {void}
   */
  clearAutoComplete(): void {
    this.autoComplete.clear();
  }

  /**
   * Filters suggestions based on the input event query.
   * @param {AutoCompleteCompleteEvent} event - Event containing the query string.
   * @return {void}
   */
  filterSuggestions(event: AutoCompleteCompleteEvent): void {
    // This attribut "observers" is deprecated but I cannot find an other way to do this
    // eslint-disable-next-line
    if (this.completeMethod.observers.length) this.completeMethod.emit(event);
    else
      this.filteredSuggestions = this.suggestions.filter(suggestion => {
        return suggestion.label
          .toLowerCase()
          .includes(event.query.toLowerCase());
      });
  }

  /**
   * Retrieves the display label for a suggestion.
   * @param {LabelValueInterface | string} suggestion - The suggestion to retrieve the label for.
   * @returns {string | null} - The label of the suggestion or null if not found.
   */
  getLabel(suggestion: LabelValueInterface | string): string | null {
    /* eslint-disable */
    return typeof suggestion === 'string'
      ? (this.suggestions.find(item => item.value === suggestion)?.label ??
          null)
      : suggestion.label;
    /* eslint-enable */
  }

  /**
   * Retrieves the display color for a suggestion.
   * @param {LabelValueInterface | string} suggestion - The suggestion to retrieve the color for.
   * @returns {string | null} - The color of the suggestion or null if not found.
   */
  getColor(suggestion: LabelValueInterface | string): string | null {
    return (
      (typeof suggestion === 'string'
        ? this.suggestions.find(item => item.value === suggestion)?.color
        : suggestion.color) ?? null
    );
  }

  /**
   * Retrieves the full name of a user based on their UUID.
   *
   * This function searches the suggestions list for a user with the matching UUID
   * and returns the user's full name in the format "Firstname Lastname".
   *
   * @param {string} uuid - The UUID of the user.
   * @returns {string} - The full name of the user, or an empty string if the user is not found.
   */
  getUserName(uuid: string): string {
    const user = this.suggestions.find(item => item.value === uuid);
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  /**
   * Hides the component element by applying a CSS style.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the component by setting its disabled state.
   * @return {void}
   */
  private disableElement(): void {
    this.disabled = true;
  }
}
