import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

/**
 * A custom Angular pipe that transforms an `AbstractControl` to a `FormControl`.
 * This transformation facilitates operations that specifically require instances of `FormControl`,
 * regardless of the original form control type (like FormGroup or FormArray).
 *
 * The pipe checks if the provided control is already a FormControl and returns it directly;
 * otherwise, it initializes a new FormControl with the value of the provided control or `null` if the control is absent.
 *
 * @Pipe decorator marks this class as an Angular pipe with a name 'toFormControl', allowing it to be used within template expressions.
 */
@Pipe({
  name: 'toFormControl',
})
export class ToFormControlPipe implements PipeTransform {
  /**
   * Transforms the provided control into a FormControl.
   * @param {AbstractControl<any, any> | null} control - The control to be transformed. It can be of any type derived from AbstractControl, or null.
   * @returns {FormControl} - A FormControl instance containing the value of the original control, or a new empty FormControl if no control is provided.
   */
  transform(control: AbstractControl<any, any> | null): FormControl {
    // Check if the control is already a FormControl
    if (control instanceof FormControl) {
      return control;
    }
    // Handle other types (FormGroup, FormArray) or return a new FormControl
    // This part will depend on your specific use case
    const value = control?.value ?? null;
    return new FormControl(value);
  }
}
