import { createReducer, on } from '@ngrx/store';
import { initialAllShuffleListState } from '~/app/states/main/shuffle-list/states/shuffle-list/shuffle-list.state';
import * as ShuffleListActions from '~/app/states/main/shuffle-list/states/shuffle-list/shuffle-list.actions';

export const allShuffleListReducer = createReducer(
  initialAllShuffleListState,

  on(ShuffleListActions.loadAllShuffleList, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ShuffleListActions.loadAllShuffleListSuccess,
    (state, { shuffleLists }) => ({
      ...state,
      shuffleLists,
      loading: false,
    })
  ),
  on(ShuffleListActions.loadAllShuffleListFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.createShuffleList, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.createShuffleListSuccess, (state, { shuffleList }) => ({
    ...state,
    shuffleLists: [shuffleList, ...state.shuffleLists],
    loading: false,
  })),
  on(ShuffleListActions.createShuffleListFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.updateShuffleList, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.updateShuffleListSuccess, (state, { shuffleList }) => ({
    ...state,
    shuffleLists: state.shuffleLists.map(item =>
      item.uuid === shuffleList.uuid ? shuffleList : item
    ),
    loading: false,
  })),
  on(ShuffleListActions.updateShuffleListFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.deleteShuffleList, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.deleteShuffleListSuccess, (state, { uuid }) => ({
    ...state,
    shuffleLists: state.shuffleLists.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(ShuffleListActions.deleteShuffleListFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.assignTeam, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.assignTeamSuccess, (state, { shuffleList }) => ({
    ...state,
    shuffleLists: state.shuffleLists.map(item =>
      item.uuid === shuffleList.uuid ? shuffleList : item
    ),
    selectedShuffleList: shuffleList,
    loading: false,
  })),
  on(ShuffleListActions.assignTeamFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.unassignTeam, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.unassignTeamSuccess, (state, { shuffleList }) => ({
    ...state,
    shuffleLists: state.shuffleLists.map(item =>
      item.uuid === shuffleList.uuid ? shuffleList : item
    ),
    selectedShuffleList: shuffleList,
    loading: false,
  })),
  on(ShuffleListActions.unassignTeamFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.shuffleListUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.getShuffleListById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ShuffleListActions.getShuffleListByIdSuccess,
    (state, { shuffleList }) => ({
      ...state,
      selectedShuffleList: shuffleList,
      loading: false,
    })
  ),
  on(ShuffleListActions.getShuffleListByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ShuffleListActions.getRandom, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ShuffleListActions.getRandomSuccess, (state, { randomShuffleList }) => ({
    ...state,
    selectedRandomShuffleList: randomShuffleList,
    loading: false,
  })),
  on(ShuffleListActions.getRandomFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
