<section
  class="h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="surface-card p-4 shadow-2 border-roun bg-cover w-30rem">
    <div class="text-center mb-5">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="50"
        class="mb-3" />
      <div i18n class="text-900 text-3xl font-medium mb-3">Reset Password</div>
      <span i18n class="text-600 font-medium line-height-3"
        >Enter your new password below.</span
      >
    </div>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <!-- New Password Field -->
      <ice-password
        type="password"
        label="New password"
        i18n-label
        placeholder="New Password"
        i18n-placeholder
        [formControl]="password"
        [formControlName]="'password'"
        [required]="true"
        class="w-full mb-3" />

      <!-- Confirm Password Field -->
      <ice-password
        type="password"
        label="Confirm password"
        i18n-label
        placeholder="Confirm Password"
        i18n-placeholder
        [formControl]="confirmPassword"
        [formControlName]="'confirmPassword'"
        [required]="true"
        class="w-full mb-3" />

      <div class="mt-3">
        <!-- Submit Button -->
        <ice-button
          [style]="{ width: '100%' }"
          [disabled]="!resetPasswordForm.valid"
          label="Set New Password"
          i18n-label
          icon="pi pi-check"
          i18n-icon
          type="submit"></ice-button>
      </div>
    </form>
  </div>
</section>
