import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, startWith, Subject, Subscription, takeUntil } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { LoginService } from '~/app/auth/services/login.service';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { CuProfileComponent } from '~/app/main/crm/profile/profile-layout/cu-profile/cu-profile.component';
import { CudOfferComponent } from '~/app/main/jobhub/components/company-offer/cud-offer/cud-offer.component';
import { CuSalesComponent } from '~/app/main/sales/profile/components/sales-layout/u-sales/cu-sales.component';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';
import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';
import { DialogService } from '~/app/shared/services/dialog.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { selectCurrentModule } from '~/app/shared/states/navigation/navigation.selector';
import { selectQuickActions } from '~/app/shared/states/quick-actions/quick-actions.selectors';
import { QuickActionService } from '~/app/shared/services/quick-action.service';
import { HeaderItemGroup } from '~/app/shared/interfaces/navigation/header-item-group.interface';
import { SidenavService } from '~/app/shared/services/sidenav.service';
import { CudKanbanComponent } from '~/app/main/kanban/components/kanban-home/cud-kanban/cud-kanban.component';
import { selectKanban } from '~/app/main/kanban/states/kanbans/kanbans.selectors';
import { CudShuffleListComponent } from '~/app/main/shuffle-list/components/cud-shuffle-list/cud-shuffle-list.component';
import { CudGoalComponent } from '~/app/main/stats/components/goals/components/cud-goal/cud-goal.component';

/**
 * HeaderComponent manages the upper navigation bar of the application, providing quick access to user profile,
 * settings, and thematic changes, along with quick actions for creating or modifying application-specific data.
 *
 * @Component decorator defines this class as an Angular component.
 * @selector 'app-header' - The CSS selector that represents this component in the template.
 * @templateUrl './header.component.html' - The path to the HTML template associated with this component.
 * @styleUrls ['./header.component.scss'] - The styles for this component.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Observable stream of MenuItem objects representing the current URL path segments.
   * @type {Observable<MenuItem[]>}
   */
  public urlSegments$!: Observable<MenuItem[]>;

  /**
   * User currently logged-in.
   * @type {Observable<AuthUser | null>}
   */
  user$: Observable<AuthUser | null>;

  /**
   * Boolean flag indicating if the dark theme is currently active.
   * @type {boolean}
   */
  isDarkTheme = false;

  /**
   * Items to be displayed in the header. Each item can have sub-items and associated actions or navigation links.
   * @type {HeaderItemGroup[]}
   */
  items: HeaderItemGroup[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'My profile',
          escape: false,
          icon: 'pi pi-user',
          iconClass: 'text-xl',
          action: (): void => {
            void this.router.navigateByUrl(`/profile`);
          },
        },
        {
          label: 'Settings',
          escape: false,
          icon: 'pi pi-cog',
          iconClass: 'text-xl',
        },
        {
          label: 'Theme',
          customContent: true,
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          action: (): void => {
            this.logout();
          },
        },
      ],
    },
  ];

  /**
   * Observable stream of QuickActionItem objects representing the quick actions available in the header.
   *
   * @type {Observable<QuickActionItem[]>}
   */
  headerActions$!: Observable<QuickActionItem[]>;

  /**
   * An observable that emits the current module item or null.
   * @type {Observable<ModuleItem | null>}
   */
  currentModule$: Observable<ModuleItem | null>;

  /**
   * An observable for the name of the kanban.
   * @type {Observable<string | undefined>}
   */
  kanbanName$!: Observable<string | undefined>;

  /**
   * A boolean flag to determine the current theme setting.
   *
   * When set to true, the light theme is applied.
   * When set to false, the dark theme is applied.
   * @type {boolean}
   */
  public isLightTheme = true;

  /**
   * An observable for the name of the kanban.
   * @type {Subscription}
   */
  private kanbanNameSubscription: Subscription = new Subscription();

  /**
   * A subject used to signal the destruction of the component.
   * @type {Subject<void>}
   * @private
   */
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * Constructor for HeaderComponent, setting up and initializing various services and state observables necessary for its operation.
   * This component serves as the main header for the application, providing navigation and quick actions.
   *
   * @param {LoginService} loginService - Service handling user authentication and logout procedures.
   * @param {ThemeService} theme - Service for managing the application's theme settings.
   * @param {SidenavFormService} sidebarService - Service for managing the sidebar.
   * @param {Store<AppState>} store - Global state store of the application, used here to select and manage application-wide settings like quick actions.
   * @param {QuickActionService} quickActionService - Service for managing quick actions.
   * @param {Router} router - Angular's Router for subscribing to and handling navigation changes.
   * @param {SidenavService} sidenavService - Service for managing the sidenav.
   * @param {DialogService} dialog - Service for managing dialogs.
   */
  constructor(
    private loginService: LoginService,
    private theme: ThemeService,
    private sidebarService: SidenavFormService,
    private dialog: DialogService,
    private store: Store<AppState>,
    private quickActionService: QuickActionService,
    private router: Router,
    private sidenavService: SidenavService
  ) {
    this.headerActions$ = this.store.select(selectQuickActions);
    this.store.select(selectQuickActions);
    this.user$ = this.store.select(selectCurrentUser);

    this.currentModule$ = this.store.select(selectCurrentModule);

    this.currentModule$.pipe(takeUntil(this.destroy$)).subscribe(te => {
      if (te?.name === 'kanban') {
        this.kanbanName$ = this.store
          .select(selectKanban)
          .pipe(map(kanban => kanban?.name));

        this.kanbanNameSubscription = this.kanbanName$.subscribe();
      }
      this.quickActionService.setQuickActions(te?.name ?? '');
    });

    this.isDarkTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Initializes the component, subscribing to navigation events to update the URL path segments displayed in the header.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.urlSegments$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(new NavigationEnd(0, this.router.url, this.router.url)),
      map(event => this.createMenuItems(event.urlAfterRedirects))
    );
  }

  /**
   * Lifecycle hook that is called when the component is destroyed to unsubscribe
   * observables.
   *
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.kanbanNameSubscription.unsubscribe();
  }

  /**
   * Logs the user out of the application using the LoginService.
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Toggles the application's theme between light and dark.
   * @return {void}
   */
  onThemeToggle(): void {
    const currentTheme = localStorage.getItem('app-theme') ?? 'light';
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    document.body.setAttribute('app-theme', newTheme);
    localStorage.setItem('app-theme', newTheme);
    this.isLightTheme = newTheme === 'light';
    this.theme.initTheme();
  }

  /**
   * Triggers the specified quick action.
   *
   * @param {QuickActionItem} quickAction - The quick action to trigger.
   * @returns {void}
   */
  triggerQuickAction(quickAction: QuickActionItem): void {
    switch (quickAction.name) {
      case 'Profile':
        this.loadSidebarComponent(CuProfileComponent, quickAction);
        break;
      case 'Sale':
        this.loadSidebarComponent(CuSalesComponent, quickAction);
        break;
      case 'Offer':
        this.dialog.loadComponent(CudOfferComponent, {}, true);
        this.dialog.toggleDialog();
        //this.loadSidebarComponent(CudOfferComponent, quickAction);
        break;
      case 'Kanban':
        this.loadSidebarComponent(CudKanbanComponent, quickAction);
        break;
      case 'Shuffle-list':
        this.loadSidebarComponent(CudShuffleListComponent, quickAction);
        break;
      case 'Goals':
        this.loadSidebarComponent(CudGoalComponent, quickAction);
    }
  }

  /**
   * Loads the specified component into the sidebar and toggles the sidebar.
   *
   * @param {Type<any>} component - The component to load into the sidebar.
   * @param {QuickActionItem} quickAction - The quick action triggering the component load.
   * @returns {void}
   */
  loadSidebarComponent(
    component: Type<any>,
    quickAction: QuickActionItem
  ): void {
    this.sidebarService.loadComponent(
      component,
      {},
      quickAction.name,
      'Create'
    );
    this.sidebarService.toggleSidebar();
  }

  /**
   * Opens the mobile sidenav.
   * @return {void}
   */
  openMobileSidenav(): void {
    this.sidenavService.openMobileSidenav();
  }

  /**
   * Creates a list of MenuItem objects representing the current URL path segments.
   * @param {string} url - The current URL path.
   * @return {MenuItem[]} - The MenuItem objects representing the URL path segments.
   */
  private createMenuItems(url: string): MenuItem[] {
    const segments = url.split('/').filter(segment => segment);
    const menuItems: MenuItem[] = [];
    let cumulativePath = '';

    segments.forEach(segment => {
      cumulativePath += `/${segment}`;
      if (segment === 'home') {
        return;
      }
      if (!(menuItems.length !== 0 && menuItems[0].label === 'Kanban')) {
        menuItems.push({
          label: segment.charAt(0).toUpperCase() + segment.slice(1),
          routerLink: cumulativePath,
        });
      }
    });
    return menuItems;
  }
}
