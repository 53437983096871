<section
  class="min-h-screen w-screen flex flex-column align-items-center gap-2 bg-cover p-3">
  <div class="absolute left-0 top-0 ml-3 mt-3">
    <a
      routerLink="/"
      class="p-button p-button-text p-component no-underline flex gap-3">
      <span class="p-button-icon pi pi-arrow-left"></span>
      <span i18n class="p-button-label">Home</span>
    </a>
  </div>
  <section class="stepper">
    <ul class="list-none flex gap-3 lg:flex-row justify-content-between p-0">
      <li
        *ngFor="let step of steps; let isLast = last"
        class="flex align-items-center flex-column gap-3 relative w-full"
        (click)="navigate(step.routerLink)">
        <div
          *ngIf="getFormData(step.label) | async"
          class="flex justify-content-center align-items-center border-circle cursor-pointer w-3rem h-3rem z-4 bg-primary-100">
          <i class="pi pi-check-circle text-xl text-primary-600"></i>
        </div>
        <div
          *ngIf="
            (getFormData(step.label) | async) === null &&
            currentStep !== step.label.toLowerCase()
          "
          class="step-active">
          <i [class]="step.icon + ' text-sm text-primary-200'"></i>
        </div>
        <div
          *ngIf="
            (getFormData(step.label) | async) === null &&
            currentStep === step.label.toLowerCase()
          "
          class="step-inactive">
          <i [class]="step.icon + ' text-sm text-primary-600'"></i>
        </div>

        <div *ngIf="!isLast" class="bar-base bar-background"></div>
        <div
          *ngIf="!isLast && (getFormData(step.label) | async)"
          [@slideIn]="getFormData(step.label) | async"
          class="bar-base bar-foreground"></div>
        <div class="flex flex-column align-items-center">
          <p
            *ngIf="currentStep === step.label.toLowerCase()"
            class="m-0 font-medium text-900 text-sm line-height-3">
            {{ step.label }}
          </p>
          <p
            *ngIf="currentStep !== step.label.toLowerCase()"
            class="m-0 font-medium text-500 text-sm line-height-3">
            {{ step.label }}
          </p>
        </div>
      </li>
    </ul>
  </section>
  <router-outlet></router-outlet>
</section>
