import { createSelector } from '@ngrx/store';
import { selectProfileState } from '~/app/states/main/crm/profile/profile.selector';

export const selectProfileClientCompaniesState = createSelector(
  selectProfileState,
  state => state.clientCompanies
);

export const selectProfileClientCompanies = createSelector(
  selectProfileClientCompaniesState,
  state => state.data
);
