<!--eslint-disable @angular-eslint/template/cyclomatic-complexity-->

<div class="pb-3 relative w-full flex flex-column gap-2">
  <label [for]="formControlName" class="color-dark-80 text-sm mx-0">
    {{ label }} <span *ngIf="required" class="color-danger-100">*</span>
  </label>
  <p-autoComplete
    [inputId]="inputId"
    [type]="'object'"
    [suggestions]="filteredSuggestions"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [multiple]="multiple"
    (completeMethod)="filterSuggestions($event)"
    [delay]="300"
    autoOptionFocus="true"
    [completeOnFocus]="true"
    (onFocus)="onFocus()"
    [showClear]="true"
    [group]="group"
    [dropdown]="dropdown"
    [disabled]="disabled"
    (onSelect)="onSelect.emit($event)"
    (onUnselect)="onUnselect.emit($event)"
    (onClear)="onClear.emit($event)"
    appendTo="body"
    i18n-appendTo>
    <ng-template let-item pTemplate="item">
      <ng-container [ngSwitch]="iceAutocompleteTemplate">
        <ng-container *ngSwitchCase="templateEnum.DEFAULT">
          <div>{{ item.label }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.STATUS">
          <div class="flex align-items-center gap-2">
            <div
              class="border-circle w-1rem h-1rem"
              [ngStyle]="{ 'background-color': item.color }"></div>
            <div>{{ item.label }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.SOCIAL">
          <div class="flex align-items-center gap-2">
            <i [class]="'w-1rem pi pi-' + item.value"></i>
            <div>{{ item.label }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.TAG">
          <div class="flex align-items-center gap-2">
            <div
              class="pi pi-circle-fill"
              [ngStyle]="{ color: item.color }"></div>
            <div>{{ item.label }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.USER">
          <div class="flex gap-2 align-items-center border-round">
            <p-avatar
              shape="circle"
              i18n-shape
              [size]="'normal'"
              [style]="{ backgroundColor: item.color }"
              [label]="
                item.firstname.toUpperCase().charAt(0) +
                item.lastname.toUpperCase().charAt(0)
              "></p-avatar>
            <span class="text-900">{{
              item.firstname + ' ' + item.lastname | titlecase
            }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <ng-container [ngSwitch]="iceAutocompleteTemplate">
        <ng-container *ngSwitchCase="templateEnum.DEFAULT">
          <div *ngIf="item">
            {{ getLabel(item) }}
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.STATUS">
          <div class="flex align-items-center gap-2">
            <div
              class="border-circle w-1rem h-1rem"
              [ngStyle]="{ backgroundColor: getColor(item) }"></div>
            <div>{{ getLabel(item) }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.SOCIAL">
          <div class="flex align-items-center gap-2">
            <i [class]="'pi pi-' + item.value"></i>
            <div>{{ getLabel(item) }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.TAG">
          <div class="flex align-items-center gap-2">
            <div
              class="pi pi-circle-fill"
              [ngStyle]="{ color: getColor(item) }"></div>
            <div>{{ getLabel(item) }}</div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="templateEnum.USER">
          <div class="flex gap-2 align-items-center border-round">
            <span class="text-900">{{ getUserName(item) | titlecase }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="removetokenicon">
      <img
        class="m-0"
        src="assets/icons/shared/small-cross.svg"
        alt="delete token" />
    </ng-template>
  </p-autoComplete>

  <div *ngIf="error" class="text-xs text-red-600 absolute bottom-0">
    {{ error }}
  </div>
</div>
