import { createAction, props } from '@ngrx/store';
import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';

export const loadClientCompanyById = createAction(
  '[Client Company Profile] Load Client Company By Id',
  props<{ uuid: string }>()
);
export const loadClientCompanyByIdSuccess = createAction(
  '[Client Company Profile] Load Client Company By Id Success',
  props<{ clientCompany: ClientCompany }>()
);
export const loadClientCompanyByIdFailure = createAction(
  '[Client Company Profile] Load Client Company By Id Failure',
  props<{ error: string }>()
);

export const updateClientCompany = createAction(
  '[Client Company Profile] Update Client Company',
  props<{ uuid: string; clientCompanyData: FormData }>()
);
export const updateClientCompanySuccess = createAction(
  '[Client Company] Update Client Company Success',
  props<{ clientCompany: ClientCompany }>()
);
export const updateClientCompanyFailure = createAction(
  '[Client Company] Update Client Company Failure',
  props<{ error: string }>()
);

export const deleteClientCompany = createAction(
  '[Client Company Profile] Delete Client Company',
  props<{ uuid: string }>()
);
export const deleteClientCompanySuccess = createAction(
  '[Client Company] Delete Client Company Success'
);
export const deleteClientCompanyFailure = createAction(
  '[Client Company] Delete Client Company Failure',
  props<{ error: string }>()
);

export const clientCompanyUnauthorized = createAction(
  '[Client Company Profile] Unauthorized',
  props<{ error: string }>()
);
