import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IceTextTooltipComponent } from 'src/app/shared/components/ui-elements/ice-text-tooltip/ice-text-tooltip.component';
import { IceLoadingBackdropComponent } from 'src/app/shared/components/ui-elements/ice-loading-backdrop/ice-loading-backdrop.component';
import { ComponentPermissionDirective } from 'src/app/shared/directives/component-permission.directive';
import { NumberDirective } from 'src/app/shared/directives/number.directive';
import { IceButtonComponent } from './components/inputs/ice-button/ice-button.component';
import { IceUnauthorizedPlaceholderComponent } from './components/ui-elements/ice-unauthorized-placeholder/ice-unauthorized-placeholder.component';
import { IceColorPickerComponent } from './components/inputs/ice-color-picker/ice-color-picker.component';
import { IceCheckboxComponent } from './components/inputs/ice-checkbox/ice-checkbox.component';
import { IceFileUploadComponent } from './components/inputs/ice-file-upload/ice-file-upload.component';
import { AlertComponent } from './components/alert/alert.component';
import { PublicHeaderComponent } from './components/navigation/public-header/public-header.component';
import { IceDialogComponent } from './components/ice-components/ice-dialog/ice-dialog.component';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { IceProgressToastComponent } from './components/ice-components/ice-progress-toast/ice-progress-toast.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { DialogHostDirective } from '~/app/shared/directives/dialog-host.directive';
import { TimeDifferencePipe } from '~/app/shared/pipes/time-difference.pipe';
import { GradientPipe } from '~/app/shared/pipes/gradient.pipe';
import { allSkillsReducer } from '~/app/shared/states/skills/skills.reducer';
import { AllSkillsEffects } from '~/app/shared/states/skills/skills.effects';
import { EnumLabelPipe } from '~/app/shared/pipes/enum-label.pipe';
import { CapitalizeWithSpacePipe } from '~/app/shared/pipes/capitalize-with-space.pipe';
import { KanbanUsersToUsersPipe } from '~/app/shared/pipes/kanban-users-to-users.pipe';
import { JoinUrlPipe } from '~/app/shared/pipes/join-url.pipe';
import { LineClipPipe } from '~/app/shared/pipes/line-clip.pipe';
import { IsAllMandatoryFilesPipe } from '~/app/shared/pipes/is-all-mandatory-files.pipe';
import { FilterFilesPipe } from '~/app/shared/pipes/filter-files.pipe';
import { FileNameClipPipe } from '~/app/shared/pipes/file-name-clip.pipe';
import { ModuleToRoutePipe } from '~/app/shared/pipes/module-to-route.pipe';
import { ComparisonIconPipe } from '~/app/shared/pipes/comparison-icon.pipe';
import { IsSalesQuickNavDisablePipe } from '~/app/shared/pipes/quick-nav/is-sales-quick-nav-disable.pipe';
import { IsProfileQuickNavDisablePipe } from '~/app/shared/pipes/quick-nav/is-profile-quick-nav-disable.pipe';
import { languagesReducer } from '~/app/shared/states/languages/languages.reducer';
import { LanguagesEffects } from '~/app/shared/states/languages/languages.effects';
import { navigationReducer } from '~/app/shared/states/navigation/navigation.reducer';
import { NavigationEffects } from '~/app/shared/states/navigation/navigation.effects';
import { GetAvatarGroupPipe } from '~/app/shared/pipes/get-avatar-group.pipe';
import { DataEffects } from '~/app/shared/states/data/data.effects';
import { dataReducer } from '~/app/shared/states/data/data.reducer';
import { PrimengModule } from '~/app/primeng/primeng.module';
import { IceAutocompleteComponent } from '~/app/shared/components/inputs/ice-autocomplete/ice-autocomplete.component';
import { IceInputComponent } from '~/app/shared/components/inputs/ice-input/ice-input.component';
import { IcePasswordComponent } from '~/app/shared/components/inputs/ice-password/ice-password.component';
import { IcePhoneIsoComponent } from '~/app/shared/components/inputs/ice-phone-iso/ice-phone-iso.component';
import { IcePhoneNumberComponent } from '~/app/shared/components/inputs/ice-phone-number/ice-phone-number.component';
import { IceRadioComponent } from '~/app/shared/components/inputs/ice-radio/ice-radio.component';
import { LayoutComponent } from '~/app/shared/components/layout/layout.component';
import { HeaderComponent } from '~/app/shared/components/navigation/header/header.component';
import { SidenavItemComponent } from '~/app/shared/components/navigation/sidenav-item/sidenav-item.component';
import { SidenavComponent } from '~/app/shared/components/navigation/sidenav/sidenav.component';
import { PageHeadingComponent } from '~/app/shared/components/page-heading/page-heading.component';
import { SectionHeadingComponent } from '~/app/shared/components/section-heading/section-heading.component';
import { SidebarComponent } from '~/app/shared/components/sidebar/sidebar.component';
import { ToastComponent } from '~/app/shared/components/toast/toast.component';
import { TypographyComponent } from '~/app/shared/components/typography/typography.component';
import { IceInfoTooltipComponent } from '~/app/shared/components/ui-elements/ice-info-tooltip/ice-info-tooltip.component';
import { FormErrorDirective } from '~/app/shared/directives/form-error.directive';
import { LowercaseDirective } from '~/app/shared/directives/lowercase.directive';
import { SidebarHostDirective } from '~/app/shared/directives/sidebar-host.directive';
import { SmartBackgroundColorPipe } from '~/app/shared/pipes/color-pipes/smart-background-color.pipe';
import { SmartTextColorPipe } from '~/app/shared/pipes/color-pipes/smart-text-color.pipe';
import { LineBreakPipe } from '~/app/shared/pipes/line-break.pipe';
import { ToFormControlPipe } from '~/app/shared/pipes/to-form-control.pipe';
import { IsTaskDonePipe } from '~/app/shared/pipes/is-task-done.pipe';
import { InfoCardComponent } from '~/app/shared/components/ui-elements/info-card/info-card.component';
import '~/app/shared/override/index';
import { IceBackdropComponent } from '~/app/shared/components/ui-elements/ice-backdrop/ice-backdrop.component';
import { IceCardComponent } from '~/app/shared/components/ui-elements/ice-card/ice-card.component';
import { IceAvatarComponent } from '~/app/shared/components/ui-elements/ice-avatar/ice-avatar.component';
import { IceStatusTagComponent } from '~/app/shared/components/ui-elements/ice-status-tag/ice-status-tag.component';
import { IceTagComponent } from '~/app/shared/components/ui-elements/ice-tag/ice-tag.component';
import { IceRangeInputComponent } from '~/app/shared/components/inputs/ice-range-input/ice-range-input.component';
import { IceSelectComponent } from '~/app/shared/components/inputs/ice-select/ice-select.component';
import { IceDropdownComponent } from '~/app/shared/components/ice-components/ice-dropdown/ice-dropdown.component';
import { ReleaseNoteComponent } from '~/app/shared/components/release-note/release-note.component';
import { NewIceCheckboxComponent } from '~/app/shared/components/ice-components/new-ice-checkbox/new-ice-checkbox.component';
import { IsInPipe } from '~/app/shared/pipes/is-in.pipe';
import { NormalCasePipe } from '~/app/shared/pipes/text-transform/normal-case.pipe';
import { IceToggleButtonComponent } from '~/app/shared/components/ice-components/ice-toggle-button/ice-toggle-button.component';
import { NewIceAutocompleteComponent } from '~/app/shared/components/ice-components/new-ice-autocomplete/new-ice-autocomplete.component';
import { LoginFormComponent } from '~/app/shared/components/login-form/login-form.component';
import { IceTabsComponent } from '~/app/shared/components/ice-components/ice-tabs/ice-tabs.component';
import { SidebarHeaderDirective } from '~/app/shared/directives/sidebar-header.directive';
import { SidebarHeaderComponent } from '~/app/shared/components/sidebar/sidebar-header/sidebar-header.component';
import { SidebarHeaderDefaultComponent } from '~/app/shared/components/sidebar/sidebar-header/sidebar-header-default/sidebar-header-default.component';
import { AddDurationPipe } from '~/app/shared/pipes/add-duration.pipe';
import { RelativeTimePipe } from '~/app/shared/pipes/relative-time.pipe';

@NgModule({
  declarations: [
    TypographyComponent,
    SidenavComponent,
    SidenavItemComponent,
    LayoutComponent,
    HeaderComponent,
    PageHeadingComponent,
    LowercaseDirective,
    IceInputComponent,
    IcePasswordComponent,
    FormErrorDirective,
    IcePhoneIsoComponent,
    IcePhoneNumberComponent,
    IceRadioComponent,
    IceDropdownComponent,
    ToastComponent,
    ToFormControlPipe,
    SidebarHostDirective,
    SidebarHeaderDirective,
    DialogHostDirective,
    SidebarComponent,
    IceAutocompleteComponent,
    LineBreakPipe,
    SectionHeadingComponent,
    SmartBackgroundColorPipe,
    SmartTextColorPipe,
    IceInfoTooltipComponent,
    FilterFilesPipe,
    IsAllMandatoryFilesPipe,
    LineClipPipe,
    IceTextTooltipComponent,
    IceLoadingBackdropComponent,
    ComponentPermissionDirective,
    IceUnauthorizedPlaceholderComponent,
    IceButtonComponent,
    IceFileUploadComponent,
    IceCheckboxComponent,
    IceColorPickerComponent,
    IsTaskDonePipe,
    GetAvatarGroupPipe,
    KanbanUsersToUsersPipe,
    CapitalizeWithSpacePipe,
    ModuleToRoutePipe,
    AlertComponent,
    FileNameClipPipe,
    ComparisonIconPipe,
    IsProfileQuickNavDisablePipe,
    IsSalesQuickNavDisablePipe,
    InfoCardComponent,
    IceBackdropComponent,
    EnumLabelPipe,
    NumberDirective,
    IceCardComponent,
    IceAvatarComponent,
    IceStatusTagComponent,
    GradientPipe,
    TimeDifferencePipe,
    IceTagComponent,
    IceRangeInputComponent,
    IceSelectComponent,
    JoinUrlPipe,
    ReleaseNoteComponent,
    NewIceCheckboxComponent,
    IsInPipe,
    IceToggleButtonComponent,
    NewIceAutocompleteComponent,
    PublicHeaderComponent,
    DialogHostDirective,
    LoginFormComponent,
    IceDialogComponent,
    IceTabsComponent,
    NormalCasePipe,
    AccountMenuComponent,
    IceProgressToastComponent,
    RegisterFormComponent,
    SidebarHeaderComponent,
    SidebarHeaderDefaultComponent,
    AddDurationPipe,
    RelativeTimePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    PrimengModule,
    RouterLink,
    RouterLinkActive,
    FormsModule,
    StoreModule.forFeature('shared', {
      data: dataReducer,
      languages: languagesReducer,
      allSkills: allSkillsReducer,
      navigation: navigationReducer,
    }),
    EffectsModule.forFeature([
      DataEffects,
      LanguagesEffects,
      AllSkillsEffects,
      NavigationEffects,
    ]),
  ],
  exports: [
    SidenavComponent,
    SidenavItemComponent,
    LayoutComponent,
    PageHeadingComponent,
    LowercaseDirective,
    IceInputComponent,
    IcePasswordComponent,
    FormErrorDirective,
    IcePhoneIsoComponent,
    IcePhoneNumberComponent,
    IceRadioComponent,
    IceDropdownComponent,
    ToastComponent,
    HeaderComponent,
    PublicHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ToFormControlPipe,
    SidebarComponent,
    SidebarHostDirective,
    SidebarHeaderDirective,
    DialogHostDirective,
    IceAutocompleteComponent,
    LineBreakPipe,
    SectionHeadingComponent,
    SmartTextColorPipe,
    SmartBackgroundColorPipe,
    IceInfoTooltipComponent,
    FilterFilesPipe,
    IsAllMandatoryFilesPipe,
    LineClipPipe,
    IceTextTooltipComponent,
    IceLoadingBackdropComponent,
    ComponentPermissionDirective,
    IceButtonComponent,
    IceFileUploadComponent,
    IceCheckboxComponent,
    IceColorPickerComponent,
    IsTaskDonePipe,
    GetAvatarGroupPipe,
    KanbanUsersToUsersPipe,
    CapitalizeWithSpacePipe,
    ModuleToRoutePipe,
    ComparisonIconPipe,
    IsProfileQuickNavDisablePipe,
    IsSalesQuickNavDisablePipe,
    InfoCardComponent,
    IceBackdropComponent,
    EnumLabelPipe,
    NgOptimizedImage,
    NumberDirective,
    IceCardComponent,
    IceAvatarComponent,
    IceStatusTagComponent,
    GradientPipe,
    TimeDifferencePipe,
    IceTagComponent,
    IceRangeInputComponent,
    IceSelectComponent,
    JoinUrlPipe,
    NewIceCheckboxComponent,
    IsInPipe,
    IceToggleButtonComponent,
    NewIceAutocompleteComponent,
    PublicHeaderComponent,
    LoginFormComponent,
    IceDialogComponent,
    IceTabsComponent,
    NormalCasePipe,
    IceProgressToastComponent,
    RegisterFormComponent,
    AddDurationPipe,
  ],
})
export class SharedModule {}
