/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanCardsActions from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.actions';
import {
  AssignUnassignKanbanCardTag,
  AssignUnassignKanbanCardUser,
  CreateKanbanCard,
  SwitchKanbanCardColumn,
  UpdateKanbanCard,
  UpdateKanbanCardSequences,
} from '~/app/states/main/kanban/types/kanban-cards.types';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { KanbanCard } from '~/app/shared/interfaces/kanban/kanban-card.interface';
import { OldSequence } from '~/app/shared/interfaces/kanban/old-sequence.interface';
import { KanbanCardUser } from '~/app/shared/interfaces/kanban/kanban-user/kanban-card-user.interface';
import { KanbanCardTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-card-tag.interface';
import { KanbanProfileColumn } from '~/app/shared/interfaces/kanban/kanban-profile-column.interface';
import { ConnectionDetails } from '~/app/shared/interfaces/connectionDetails.interface';

/**
 * Service responsible for managing Kanban cards.
 * Provides methods to create, update, delete, assign/unassign users and tags for Kanban cards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanCardsService {
  /**
   * Constructs a new KanbanCardsService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/

  /**
   * Load all cards of the kanban.
   * Dispatched a loadAllKanbanCards action with the provided column uuid and filters.
   *
   * @param {string} kanbanColumnId - The uuid of the column.
   * @param {AllKanbansFilters} filters - The filters for the page & limit.
   * @returns {void}
   */
  loadAllKanbanCards(
    kanbanColumnId: string,
    filters?: AllKanbansFilters
  ): void {
    this.store.dispatch(
      KanbanCardsActions.loadAllKanbanCards({ kanbanColumnId, filters })
    );
  }

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/

  /**
   * Creates a new Kanban card.
   * Dispatches a createKanbanCard action with the provided Kanban card data.
   *
   * @param {CreateKanbanCard} kanbanCardData – The data for the new Kanban card.
   * @returns {void}
   */
  createCard(kanbanCardData: CreateKanbanCard): void {
    this.store.dispatch(
      KanbanCardsActions.createKanbanCard({ kanbanCardData })
    );
  }

  /**
   * Updates an existing Kanban card.
   * Dispatches an updateKanbanCard action with the card UUID and updated data.
   *
   * @param {string} uuid – The UUID of the Kanban card to update.
   * @param {UpdateKanbanCard} kanbanCardData – The updated data for the Kanban card.
   * @param {number} nbAttachments - The number of attachments.
   * @param {number} nbComments - The number of comments.
   * @returns {void}
   */
  updateCard(
    uuid: string,
    kanbanCardData: UpdateKanbanCard,
    nbAttachments: number,
    nbComments: number
  ): void {
    this.store.dispatch(
      KanbanCardsActions.updateKanbanCard({
        uuid,
        kanbanCardData,
        nbAttachments,
        nbComments,
      })
    );
  }

  /**
   * Deletes a Kanban card.
   * Dispatches a deleteKanbanCard action with the card UUID and column ID.
   *
   * @param {string} uuid – The UUID of the Kanban card to delete.
   * @param {string} kanbanColumnId – The ID of the Kanban column containing the card.
   * @returns {void}
   */
  deleteCard(uuid: string, kanbanColumnId: string): void {
    this.store.dispatch(
      KanbanCardsActions.deleteKanbanCard({ uuid, kanbanColumnId })
    );
  }

  /**
   * ************************************************************************
   * REORDER CARDS + SWITCH COLUMN
   * ************************************************************************
   **/

  /**
   * Reorders the sequence of Kanban cards within a column.
   * Dispatches an updateKanbanCardSequences action with the updated card sequences data.
   *
   * @param {UpdateKanbanCardSequences} kanbanCardSequencesData – The updated sequence data for Kanban cards.
   * @param {OldSequence} oldCardSequence - The old sequence of Kanban cards.
   * @returns {void}
   */
  reOrderCardSequence(
    kanbanCardSequencesData: UpdateKanbanCardSequences,
    oldCardSequence: OldSequence[]
  ): void {
    this.store.dispatch(
      KanbanCardsActions.updateKanbanCardSequences({
        kanbanCardSequencesData,
        oldCardSequence,
      })
    );
  }

  /**
   * Switches a Kanban card between columns.
   * Dispatches a switchKanbanCardColumn action with the card ID, card data, and Kanban board ID.
   *
   * @param {string} kanbanCardId – The ID of the Kanban card to switch.
   * @param {SwitchKanbanCardColumn} kanbanCardsData – The data for the Kanban card switch.
   * @param {string} kanbanId – The ID of the Kanban board.
   * @param {number} prevIndex - The previous index of Kanban card.
   * @returns {void}
   */
  switchKanbanCardColumn(
    kanbanCardId: string,
    kanbanCardsData: SwitchKanbanCardColumn,
    kanbanId: string,
    prevIndex: number
  ): void {
    this.store.dispatch(
      KanbanCardsActions.switchKanbanCardColumn({
        uuid: kanbanCardId,
        kanbanCardsData,
        kanbanId,
        prevIndex,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Creates a new Kanban card.
   * Dispatches a createKanbanCard action with the provided Kanban card data.
   *
   * @param {KanbanCard} kanbanCard – The new Kanban card.
   * @param {string} kanbanColumnId - The uuid of the kanban column.
   * @returns {void}
   */
  createCardServer(kanbanCard: KanbanCard, kanbanColumnId: string): void {
    this.store.dispatch(
      KanbanCardsActions.createKanbanCardServer({ kanbanCard, kanbanColumnId })
    );
  }

  /**
   * Updates an existing Kanban card.
   * Dispatches an updateKanbanCard action with the card UUID and updated data.
   *
   * @param {KanbanCard} kanbanCard – The updated data for the Kanban card.
   * @param {string} kanbanColumnId – The UUID of the Kanban card to update.
   * @param {number} nbAttachments - The number of attachments.
   * @param {number} nbComments - The number of comments.
   * @returns {void}
   */
  updateCardServer(
    kanbanCard: KanbanCard,
    kanbanColumnId: string,
    nbAttachments: number,
    nbComments: number
  ): void {
    this.store.dispatch(
      KanbanCardsActions.updateKanbanCardServer({
        kanbanCard,
        kanbanColumnId,
        nbAttachments,
        nbComments,
      })
    );
  }

  /**
   * Deletes a Kanban card.
   * Dispatches a deleteKanbanCard action with the card UUID and column ID.
   *
   * @param {string} uuid – The UUID of the Kanban card to delete.
   * @param {string} kanbanColumnId – The ID of the Kanban column containing the card.
   * @returns {void}
   */
  deleteCardServer(uuid: string, kanbanColumnId: string): void {
    this.store.dispatch(
      KanbanCardsActions.deleteKanbanCardServer({ uuid, kanbanColumnId })
    );
  }

  /**
   * Reorders the sequence of Kanban cards within a column.
   * Dispatches an updateKanbanCardSequences action with the updated card sequences data.
   *
   * @param {UpdateKanbanCardSequences} kanbanCardSequencesData – The updated sequence data for Kanban cards.
   * @param {OldSequence} oldCardSequence - The old sequence of Kanban cards.
   * @returns {void}
   */
  reOrderCardSequenceServer(
    kanbanCardSequencesData: UpdateKanbanCardSequences,
    oldCardSequence: OldSequence[]
  ): void {
    this.store.dispatch(
      KanbanCardsActions.updateKanbanCardSequencesServer({
        kanbanCardSequencesData,
        oldCardSequence,
      })
    );
  }

  /**
   * Switches a Kanban card between columns.
   * Dispatches a switchKanbanCardColumn action with the card ID, card data, and Kanban board ID.
   *
   * @param {string} kanbanCardId – The ID of the Kanban card to switch.
   * @param {SwitchKanbanCardColumn} kanbanCardsData – The data for the Kanban card switch.
   * @returns {void}
   */
  switchKanbanCardColumnServer(
    kanbanCardId: string,
    kanbanCardsData: SwitchKanbanCardColumn
  ): void {
    this.store.dispatch(
      KanbanCardsActions.switchKanbanCardColumnServer({
        uuid: kanbanCardId,
        kanbanCardsData,
      })
    );
  }

  /**
   * Assigns a user to a Kanban card.
   * Dispatches an assignKanbanCardUser action with the card UUID and user data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {KanbanCardUser[]} kanbanCardUsers – The list of users of the card.
   * @param {string} kanbanColumnId - The UUID of the Kanban column.
   * @returns {void}
   */
  assignUserServer(
    uuid: string,
    kanbanCardUsers: KanbanCardUser[],
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardsActions.assignKanbanCardUserServer({
        uuid,
        kanbanCardUsers,
        kanbanColumnId,
      })
    );
  }

  /**
   * Unassigns a user from a Kanban card.
   * Dispatches an unassignKanbanCardUser action with the card UUID and user data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardUser} kanbanCardUserData – The data for the user to unassign.
   * @returns {void}
   */
  unassignUserServer(
    uuid: string,
    kanbanCardUserData: AssignUnassignKanbanCardUser
  ): void {
    this.store.dispatch(
      KanbanCardsActions.unassignKanbanCardUserServer({
        uuid,
        kanbanCardUserData,
      })
    );
  }

  /**
   * Assigns a tag to a Kanban card.
   * Dispatches an assignKanbanCardTag action with the card UUID and tag data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {KanbanCardTag[]} kanbanCardTags – The list of users of the card.
   * @param {string} kanbanColumnId - The UUID of the Kanban column.
   * @returns {void}
   */
  assignTagServer(
    uuid: string,
    kanbanCardTags: KanbanCardTag[],
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardsActions.assignKanbanCardTagServer({
        uuid,
        kanbanCardTags,
        kanbanColumnId,
      })
    );
  }

  /**
   * Unassigns a tag from a Kanban card.
   * Dispatches an unassignKanbanCardTag action with the card UUID and tag data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardTag} kanbanCardTagData – The data for the tag to unassign.
   * @returns {void}
   */
  unassignTagServer(
    uuid: string,
    kanbanCardTagData: AssignUnassignKanbanCardTag
  ): void {
    this.store.dispatch(
      KanbanCardsActions.unassignKanbanCardTagServer({
        uuid,
        kanbanCardTagData,
      })
    );
  }

  /**
   * ************************************************************************
   * ASSIGN/UNASSIGN USER + TAG + PROFILE
   * ************************************************************************
   **/

  /**
   * Assigns a user to a Kanban card.
   * Dispatches an assignKanbanCardUser action with the card UUID and user data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardUser} kanbanCardUserData – The data for the user to assign.
   * @returns {void}
   */
  assignUser(
    uuid: string,
    kanbanCardUserData: AssignUnassignKanbanCardUser
  ): void {
    this.store.dispatch(
      KanbanCardsActions.assignKanbanCardUser({ uuid, kanbanCardUserData })
    );
  }

  /**
   * Unassigns a user from a Kanban card.
   * Dispatches an unassignKanbanCardUser action with the card UUID and user data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardUser} kanbanCardUserData – The data for the user to unassign.
   * @returns {void}
   */
  unassignUser(
    uuid: string,
    kanbanCardUserData: AssignUnassignKanbanCardUser
  ): void {
    this.store.dispatch(
      KanbanCardsActions.unassignKanbanCardUser({ uuid, kanbanCardUserData })
    );
  }

  /**
   * Assigns a tag to a Kanban card.
   * Dispatches an assignKanbanCardTag action with the card UUID and tag data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardTag} kanbanCardTagData – The data for the tag to assign.
   * @returns {void}
   */
  assignTag(
    uuid: string,
    kanbanCardTagData: AssignUnassignKanbanCardTag
  ): void {
    this.store.dispatch(
      KanbanCardsActions.assignKanbanCardTag({ uuid, kanbanCardTagData })
    );
  }

  /**
   * Unassigns a tag from a Kanban card.
   * Dispatches an unassignKanbanCardTag action with the card UUID and tag data.
   *
   * @param {string} uuid – The UUID of the Kanban card.
   * @param {AssignUnassignKanbanCardTag} kanbanCardTagData – The data for the tag to unassign.
   * @returns {void}
   */
  unassignTag(
    uuid: string,
    kanbanCardTagData: AssignUnassignKanbanCardTag
  ): void {
    this.store.dispatch(
      KanbanCardsActions.unassignKanbanCardTag({ uuid, kanbanCardTagData })
    );
  }

  /**
   * Retrieves the profile assigned to a Kanban card.
   * Dispatches a getKanbanCardProfile action with the card UUID.
   *
   * @param {string} kanbanCardId – The UUID of the Kanban card.
   * @param {string} kanbanId – The UUID of the Kanban.
   * @returns {void}
   */
  getProfiles(kanbanCardId: string, kanbanId?: string): void {
    this.store.dispatch(
      KanbanCardsActions.getKanbanCardProfiles({
        kanbanCardId,
        kanbanId,
      })
    );
  }
  /**
   * Assigns a profile to a Kanban card.
   * Dispatches an assignKanbanCardProfile action with the card UUID and tag data.
   *
   * @param {string} kanbanCardId – The UUID of the Kanban card.
   * @param {KanbanProfileColumn} kanbanProfileColumn – The data for the profile to assign.
   * @returns {void}
   */
  assignProfile(
    kanbanCardId: string,
    kanbanProfileColumn: KanbanProfileColumn
  ): void {
    this.store.dispatch(
      KanbanCardsActions.assignKanbanCardProfile({
        kanbanCardId,
        kanbanProfileColumn,
      })
    );
  }
  /**
   * Unassigns a profile to a Kanban card.
   * Dispatches an unassignKanbanCardProfile action with the card UUID and tag data.
   *
   * @param {string} kanbanCardId – The UUID of the Kanban card.
   * @param {KanbanProfileColumn} kanbanProfileColumn – The data for the profile to unassign.
   * @returns {void}
   */
  unassignProfile(
    kanbanCardId: string,
    kanbanProfileColumn: KanbanProfileColumn
  ): void {
    this.store.dispatch(
      KanbanCardsActions.unassignKanbanCardProfile({
        kanbanCardId,
        kanbanProfileColumn,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER CONNECTION
   * ************************************************************************
   **/

  /**
   * Connect to a channel representing the kanban.
   * Dispatches a connectToChannel action.
   * @param {ConnectionDetails[]} connectionDetails - The topic & the channel.
   * @param {string} token - The token for the connection.
   * @returns {void}
   */
  connectToChannel(
    connectionDetails: ConnectionDetails[],
    token: string
  ): void {
    this.store.dispatch(
      KanbanCardsActions.connectToChannel({ connectionDetails, token })
    );
  }
  /**
   * Disconnect from the channel representing the kanban.
   * Dispatches a disconnectFromChannel action.
   * @param {ConnectionDetails[]} connectionDetails - The topic & the channel.
   * @returns {void}
   */
  disconnectFromChannel(connectionDetails: ConnectionDetails[]): void {
    this.store.dispatch(
      KanbanCardsActions.disconnectFromChannel({ connectionDetails })
    );
  }
}
