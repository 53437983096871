import { createAction, props } from '@ngrx/store';

export const loadSalesUUIDs = createAction(
  '[Sales Quick Nav] Load sales UUIDs',
  props<{ uuids: string[] }>()
);

export const salesQuickNavUp = createAction(
  '[Sales Quick Nav] Navigation Up',
  props<{ currentUUID: string }>()
);

export const salesQuickNavDown = createAction(
  '[Sales Quick Nav] Navigation Down',
  props<{ currentUUID: string }>()
);
