import { createAction, props } from '@ngrx/store';
import { KanbanAttachment } from '~/app/shared/interfaces/kanban/kanban-attachment.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllCardAttachments = createAction(
  '[Kanban Card Attachments] Load All Kanban Card Attachments',
  props<{ kanbanCardId: string }>()
);
export const loadAllCardAttachmentsSuccess = createAction(
  '[Kanban Card Attachments] Load All Kanban Card Attachments Success',
  props<{
    kanbanAttachments: KanbanAttachment[];
  }>()
);
export const loadAllCardAttachmentsFailure = createAction(
  '[Kanban Card Attachments] Load All Kanban Card Attachments Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createKanbanCardAttachment = createAction(
  '[Kanban Card Attachments] Create Kanban Card Attachment',
  props<{
    kanbanAttachmentData: FormData;
    kanbanCardId: string;
    kanbanColumnId: string;
  }>()
);
export const createKanbanCardAttachmentSuccess = createAction(
  '[Kanban Card Attachments] Create Kanban Card Attachment Success',
  props<{
    kanbanAttachment: KanbanAttachment;
    kanbanCardId: string;
    kanbanColumnId: string;
  }>()
);
export const createKanbanCardAttachmentFailure = createAction(
  '[Kanban Card Attachments] Create Kanban Card Attachment Failure',
  props<{ error: string }>()
);

export const updateKanbanCardAttachment = createAction(
  '[Kanban Card Attachments] Update Kanban Card Attachment',
  props<{
    uuid: string;
    kanbanAttachmentData: FormData;
  }>()
);
export const updateKanbanCardAttachmentSuccess = createAction(
  '[Kanban Card Attachments] Update Kanban Card Attachment Success',
  props<{
    kanbanAttachment: KanbanAttachment;
  }>()
);
export const updateKanbanCardAttachmentFailure = createAction(
  '[Kanban Card Attachments] Update Kanban Card Attachment Failure',
  props<{ error: string }>()
);

export const deleteKanbanCardAttachment = createAction(
  '[Kanban Card Attachments] Delete Kanban Card Attachment',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardAttachmentSuccess = createAction(
  '[Kanban Card Attachments] Delete Kanban Card Attachment Success',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardAttachmentFailure = createAction(
  '[Kanban Card Attachments] Delete Kanban Card Attachment Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * SSE SERVER
 * ************************************************************************
 **/
export const createKanbanCardAttachmentServer = createAction(
  '[Kanban Card Attachments] Create Kanban Card Attachment Server',
  props<{
    kanbanAttachment: KanbanAttachment;
    kanbanCardId: string;
    kanbanColumnId: string;
  }>()
);

export const updateKanbanCardAttachmentServer = createAction(
  '[Kanban Card Attachments] Update Kanban Card Attachment Server',
  props<{
    kanbanAttachment: KanbanAttachment;
  }>()
);

export const deleteKanbanCardAttachmentServer = createAction(
  '[Kanban Card Attachments] Delete Kanban Card Attachment Server',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const kanbanAttachmentsUnauthorized = createAction(
  '[Kanban Card Attachments] Unauthorized',
  props<{ error: string }>()
);
