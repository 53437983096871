import { createReducer, on } from '@ngrx/store';
import { initialEnumsState } from '~/app/core/state/enum/enums.state';
import * as EnumsActions from '~/app/core/state/enum/enums.actions';

export const enumsReducer = createReducer(
  initialEnumsState,

  on(EnumsActions.loadCecrls, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadCecrlsSuccess, (state, { cecrls }) => ({
    ...state,
    enums: {
      ...state.enums,
      cecrls,
    },
    loading: false,
  })),
  on(EnumsActions.loadCecrlsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProposalStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadProposalStatusesSuccess,
    (state, { proposalStatuses }) => ({
      ...state,
      enums: {
        ...state.enums,
        proposalStatuses,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadProposalStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadOfferStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadOfferStatusesSuccess, (state, { offerStatuses }) => ({
    ...state,
    enums: {
      ...state.enums,
      offerStatuses,
    },
    loading: false,
  })),
  on(EnumsActions.loadOfferStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadOfferUnits, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadOfferUnitsSuccess, (state, { offerUnits }) => ({
    ...state,
    enums: {
      ...state.enums,
      offerUnits,
    },
    loading: false,
  })),
  on(EnumsActions.loadOfferUnitsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadOfferVisibilities, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadOfferVisibilitiesSuccess,
    (state, { offerVisibilities }) => ({
      ...state,
      enums: {
        ...state.enums,
        offerVisibilities,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadOfferVisibilitiesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadOfferDocumentTypes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadOfferDocumentTypesSuccess,
    (state, { offerDocumentTypes }) => ({
      ...state,
      enums: {
        ...state.enums,
        offerDocumentTypes,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadOfferDocumentTypesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProfileExperiences, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadProfileExperiencesSuccess,
    (state, { profileExperiences }) => ({
      ...state,
      enums: {
        ...state.enums,
        profileExperiences,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadProfileExperiencesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProfileFiles, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadProfileFilesSuccess, (state, { profileFiles }) => ({
    ...state,
    enums: {
      ...state.enums,
      profileFiles,
    },
    loading: false,
  })),
  on(EnumsActions.loadProfileFilesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProfileTimeUnits, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadProfileTimeUnitsSuccess,
    (state, { profileTimeUnits }) => ({
      ...state,
      enums: {
        ...state.enums,
        profileTimeUnits,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadProfileTimeUnitsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProfileTypes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadProfileTypesSuccess, (state, { profileTypes }) => ({
    ...state,
    enums: {
      ...state.enums,
      profileTypes,
    },
    loading: false,
  })),
  on(EnumsActions.loadProfileTypesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadProposalStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadProposalStatusesSuccess,
    (state, { proposalStatuses }) => ({
      ...state,
      enums: {
        ...state.enums,
        proposalStatuses,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadProposalStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadReminders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadRemindersSuccess, (state, { reminders }) => ({
    ...state,
    enums: {
      ...state.enums,
      reminders,
    },
    loading: false,
  })),
  on(EnumsActions.loadRemindersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadSaleInformations, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadSaleInformationsSuccess,
    (state, { saleInformations }) => ({
      ...state,
      enums: {
        ...state.enums,
        saleInformations,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadSaleInformationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadSocials, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadSocialsSuccess, (state, { socials }) => ({
    ...state,
    enums: {
      ...state.enums,
      socials,
    },
    loading: false,
  })),
  on(EnumsActions.loadSocialsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadGenders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadGendersSuccess, (state, { genders }) => ({
    ...state,
    enums: {
      ...state.enums,
      genders,
    },
    loading: false,
  })),
  on(EnumsActions.loadGendersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadSkillExperience, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    EnumsActions.loadSkillExperienceSuccess,
    (state, { skillExperiences }) => ({
      ...state,
      enums: {
        ...state.enums,
        skillExperiences,
      },
      loading: false,
    })
  ),
  on(EnumsActions.loadSkillExperienceFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadTarget, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadTargetSuccess, (state, { targets }) => ({
    ...state,
    enums: {
      ...state.enums,
      targets,
    },
    loading: false,
  })),
  on(EnumsActions.loadTargetFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadGoalTypes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadGoalTypesSuccess, (state, { goalTypes }) => ({
    ...state,
    enums: {
      ...state.enums,
      goalTypes,
    },
    loading: false,
  })),
  on(EnumsActions.loadGoalTypesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.loadRates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EnumsActions.loadRatesSuccess, (state, { rates }) => ({
    ...state,
    enums: {
      ...state.enums,
      rates,
    },
    loading: false,
  })),
  on(EnumsActions.loadRatesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(EnumsActions.enumsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
