import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * Animation trigger for fading in an element.
 *
 * @const {AnimationTriggerMetadata} fadeInOutBounce
 * @transition Transition from false to true.
 * @style Initial style with opacity 0.
 * @animate Animates the element to opacity 1 over 300ms with ease-in timing.
 */
export const heroImage: AnimationTriggerMetadata = trigger('heroImage', [
  transition(':enter', [
    style({
      scale: 0.7,
    }),
    animate(
      '1000ms cubic-bezier( 0.44, 0.47, 0.1, 0.94 )',
      style({
        scale: 1,
      })
    ),
  ]),
]);
