import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Component to display a dynamic loading backdrop with progress information. It is used to indicate
 * active processing, such as file analysis, and visually represents the progress of this operation.
 *
 * This component is designed to be flexible, showing both textual updates and a progress bar that updates
 * based on emitted values from an Observable of Progress objects.
 *
 * @Component decorator defines Angular component metadata:
 * @selector 'ice-loading-backdrop' - The component's CSS selector.
 * @templateUrl './ice-loading-backdrop.component.html' - The path to the HTML template.
 * @styleUrls ['./ice-loading-backdrop.component.scss'] - The path to the styles.
 */
@Component({
  selector: 'ice-loading-backdrop',
  templateUrl: './ice-loading-backdrop.component.html',
  styleUrls: ['./ice-loading-backdrop.component.scss'],
})
export class IceLoadingBackdropComponent {
  /**
   * Accepts an Observable that emits Progress objects. These objects contain current progress data
   * which is used to update the progress bar and status message in real-time.
   *
   * The template listens to this Observable and updates the view accordingly, showing a progress bar
   * and a percentage label that indicate how much of the process is complete.
   *
   * @type {Observable<Progress | null>}
   * @example
   * <ice-loading-backdrop [progress]="loadProgress$"></ice-loading-backdrop>
   *
   * // Component class
   * loadProgress$: Observable<Progress | null> = this.someService.getProgressStream();
   */
  @Input() progress!: Observable<Progress | null>;
}
