import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that joins an array of URL segments into a single string, creating a valid URL path.
 * This pipe is useful for dynamically constructing URLs from multiple segments, such as when building routes or links
 * that require multiple parameters or parts to be combined into a single URL string.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'joinUrl', enabling its use within Angular templates
 * to dynamically concatenate URL segments into a single URL path.
 */
@Pipe({
  name: 'joinUrl',
})
export class JoinUrlPipe implements PipeTransform {
  /**
   * Transforms an array of URL segments into a single string by joining them with a forward slash (/) character.
   *
   * @param {string[]} urlSegments - The array of URL segments to be joined into a single URL path.
   * @returns {string} - The concatenated URL path created from the input URL segments.
   */
  transform(urlSegments: string[]): string {
    return urlSegments.join('/');
  }
}
