import { Sale } from '~/app/states/main/sales/components/search-sales/states/search-sales.state';

/**
 * Interface representing the state of client company sales.
 *
 * This state includes the count, pagination, data, loading status, and error information for client company sales.
 *
 * @interface ClientCompanySalesState
 */
export interface ClientCompanySalesState {
  /**
   * The total count of client company sales.
   *
   * @type {number}
   * @memberof ClientCompanySalesState
   */
  count: number;

  /**
   * The current page of client company sales.
   *
   * @type {number}
   * @memberof ClientCompanySalesState
   */
  page: number;

  /**
   * The limit of client company sales per page.
   *
   * @type {number}
   * @memberof ClientCompanySalesState
   */
  limit: number;

  /**
   * Whether there is a next page of client company sales.
   *
   * @type {boolean}
   * @memberof ClientCompanySalesState
   */
  hasNext: boolean;

  /**
   * Whether there is a previous page of client company sales.
   *
   * @type {boolean}
   * @memberof ClientCompanySalesState
   */
  hasPrevious: boolean;

  /**
   * The list of client company sales.
   *
   * @type {Sale[]}
   * @memberof ClientCompanySalesState
   */
  data: Sale[];

  /**
   * Whether the client company sales are currently being loaded.
   *
   * @type {boolean}
   * @memberof ClientCompanySalesState
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   * @memberof ClientCompanySalesState
   */
  error: string | null;
}

/**
 * The initial state for client company sales.
 *
 * @type {ClientCompanySalesState}
 */
export const initialClientCompanySalesState: ClientCompanySalesState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
};
