<div
  @backdropAnimation
  [ngClass]="{ hidden: !isVisible, 'flex-centered': centered }"
  class="dialog-backdrop align-items-end md:align-items-center"
  (click)="closeOnBackdropClick()">
  <div
    [ngClass]="{ dialog: !isPhoneScreen, phoneDialog: isPhoneScreen }"
    (click)="$event.stopPropagation()"
    [@dialogAnimation]="isVisible ? 'visible' : 'void'"
    @dialogAnimation>
    <div class="dialog-header">
      <ice-button [text]="true" (click)="close()"
        ><i class="pi pi-times"></i
      ></ice-button>
    </div>
    <div class="dialog-content">
      <ng-template appDialogHost></ng-template>
    </div>
  </div>
</div>
