import { TourStep } from '~/app/shared/interfaces/generic/tour-step.interface';

/**
 * Contains the date of the release notes.
 *
 * @constant
 * @type {string} - The date of the release notes.
 */
export const releaseNoteDate = '2025-02-28T00:00:01.900Z';

/**
 * Contains the release notes for bug fixes as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteBugFix = ['Correction of a lot of bugs.'];

/**
 * Contains the release notes for upgrades as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteUpgrade = [
  'Candidates and consultant page completely refactored.',
  'With some quick navigation enhancements. You can now use your keyboard to navigate through them',
  'No new section but each of them has some UI/UX improvement.',
];

/**
 * Contains the release notes for features as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteFeature = [
  'Search candidate and profile are now available on mobile! Yeaaay',
];

/**
 * Contains the steps for the release notes tutorial tour.
 *
 * @constant
 * @type {Array}
 */
export const releaseNoteTourSteps: TourStep[] = [
  {
    id: 'step-0',
    attachTo: {
      element:
        'Premiere etape dans le vide pour lancer le tour et pouvoir changer de route pour le premier step',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'ICEBERRY 1.2.2 🎊🍬🎉',
    text: ['Quick tour of the new features.'],
  },

  {
    id: 'step-1',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-1',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Week interviews',
    text: ['Some UX enhancements for the week interviews.'],
    route: '/crm',
  },

  {
    id: 'step-2',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-2',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Profile page',
    text: ['New presentation of the candidate/consultant page.'],
    route: '/crm/profile/74448ef0-06a3-4164-9fdb-824bc8bc2d8f/overview',
  },
  {
    id: 'step-3',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-3',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Tags',
    text: ['Here you can assign/remove tags'],
  },
  {
    id: 'step-4',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-4',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Overview section',
    text: [
      'In overview you now have access to the resume, languages and sector jobs',
    ],
  },
  {
    id: 'step-5',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-5',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Tasks',
    text: ['Task done and not done are now separated like this'],
    route: '/crm/profile/74448ef0-06a3-4164-9fdb-824bc8bc2d8f/tasks',
  },
  {
    id: 'step-6',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-6',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Finish',
        action: 'complete',
      },
    ],
    title: 'References ➡️➡️ Experiences',
    text: ['Here you will find the references like you called them before.'],
    route: '/crm/profile/74448ef0-06a3-4164-9fdb-824bc8bc2d8f/experiences',
  },
];
