import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as CandidateTagActions from '~/app/states/main/settings/states/tags/candidate-tags-state/candidate-tags.actions';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `CandidateTagsEffects` manages the side effects related to candidate tags in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting candidate tags from the server.
 *
 * The effects in this class include:
 * — `loadCandidateTags$`: Fetches candidate tags from the server.
 * — `createCandidateTag$`: Creates a new candidate tag on the server.
 * — `createCandidateTagSuccess$`: Handles successful candidate tag creation.
 * — `updateCandidateTag$`: Updates an existing candidate tag on the server.
 * — `updateCandidateTagSuccess$`: Handles successful candidate tag updates.
 * — `deleteCandidateTag$`: Deletes a candidate tag from the server.
 * — `deleteCandidateTagSuccess$`: Handles successful candidate tag deletion.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class CandidateTagsEffects {
  /**
   * Effect to load candidate tags from the server. It listens for the `loadCandidateTags` action,
   * checks for permissions, and fetches the tags from the server. Upon success, it dispatches a success action with the fetched tags.
   */
  loadCandidateTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateTagActions.loadCandidateTags),
      checkPermission(CandidateTagActions.candidateTagUnauthorized),
      mergeMap(() =>
        this.http
          .get<Tag[]>(`${environment.apiUrl}/v1/profiles/tags`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(candidateTags =>
              CandidateTagActions.loadCandidateTagsSuccess({
                candidateTags,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateTagActions.loadCandidateTagsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new candidate tag on the server. It listens for the `createCandidateTag` action,
   * checks for permissions, and sends the tag data to the server. Upon success, it dispatches a success action with the created tag.
   */
  createCandidateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateTagActions.createCandidateTag),
      checkPermission(CandidateTagActions.candidateTagUnauthorized),
      mergeMap(({ candidateTagData }) =>
        this.http
          .post<Tag>(
            `${environment.apiUrl}/v1/profiles/tags`,
            candidateTagData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(candidateTag =>
              CandidateTagActions.createCandidateTagSuccess({
                candidateTag,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateTagActions.createCandidateTagFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful candidate tag creation. It listens for the `createCandidateTagSuccess` action
   * and displays a success toast message and closes the sidebar.
   */
  createCandidateTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateTagActions.createCandidateTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing candidate tag on the server. It listens for the `updateCandidateTag` action,
   * checks for permissions, and sends the updated tag data to the server. Upon success, it dispatches a success action with the updated tag.
   */
  updateCandidateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateTagActions.updateCandidateTag),
      checkPermission(CandidateTagActions.candidateTagUnauthorized),
      mergeMap(({ uuid, candidateTagData }) =>
        this.http
          .patch<Tag>(
            `${environment.apiUrl}/v1/profiles/tags/${uuid}`,
            candidateTagData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(candidateTag =>
              CandidateTagActions.updateCandidateTagSuccess({
                candidateTag,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateTagActions.updateCandidateTagFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful candidate tag updates. It listens for the `updateCandidateTagSuccess` action
   * and displays a success toast message and closes the sidebar.
   */
  updateCandidateTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateTagActions.updateCandidateTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a candidate tag from the server. It listens for the `deleteCandidateTag` action,
   * checks for permissions, and sends the delete request to the server. Upon success, it dispatches a success action with the deleted tag's UUID.
   */
  deleteCandidateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateTagActions.deleteCandidateTag),
      checkPermission(CandidateTagActions.candidateTagUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/tags/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => CandidateTagActions.deleteCandidateTagSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateTagActions.deleteCandidateTagFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful candidate tag deletion. It listens for the `deleteCandidateTagSuccess` action
   * and displays a success toast message and closes the sidebar.
   */
  deleteCandidateTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateTagActions.deleteCandidateTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `CandidateTagsEffects`.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - Injectable service for displaying custom toast notifications.
   * @param {SidenavFormService} sidebarService - Injectable service for handling sidebar operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
