import { createReducer, on } from '@ngrx/store';
import * as ProfileFilesActions from '~/app/states/main/crm/profile/states/profile-files/profile-files.actions';
import { initialProfileFilesState } from '~/app/states/main/crm/profile/states/profile-files/profile-files.state';

export const profileFilesReducer = createReducer(
  initialProfileFilesState,

  on(ProfileFilesActions.loadProfileFiles, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileFilesActions.loadProfileFilesSuccess,
    (state, { profileFiles }) => ({
      ...state,
      profileFiles,
      loading: false,
    })
  ),
  on(ProfileFilesActions.loadProfileFilesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileFilesActions.createProfileFile, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileFilesActions.createProfileFileSuccess,
    (state, { profileFile }) => ({
      ...state,
      profileFiles: [...state.profileFiles, profileFile],
      loading: false,
    })
  ),
  on(ProfileFilesActions.createProfileFileFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileFilesActions.updateProfileFile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileFilesActions.updateProfileFileSuccess,
    (state, { profileFile }) => ({
      ...state,
      profileFiles: state.profileFiles.map(item =>
        item.uuid === profileFile.uuid ? profileFile : item
      ),
      loading: false,
    })
  ),
  on(ProfileFilesActions.updateProfileFileFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileFilesActions.deleteProfileFile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileFilesActions.deleteProfileFileSuccess, (state, { uuid }) => ({
    ...state,
    profileFiles: state.profileFiles.filter(file => file.uuid !== uuid),
    loading: false,
  })),
  on(ProfileFilesActions.deleteProfileFileFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileFilesActions.profileFilesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
