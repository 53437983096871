import { createAction, props } from '@ngrx/store';

export const loadProfilesUUIDs = createAction(
  '[Profile Quick Nav] Load profiles UUIDs',
  props<{ uuids: string[] }>()
);

export const profileQuickjNavUp = createAction(
  '[Profile Quick Nav] Navigation Up',
  props<{ currentUUID: string }>()
);

export const profileQuickjNavDown = createAction(
  '[Profile Quick Nav] Navigation Down',
  props<{ currentUUID: string }>()
);
