import { createReducer, on } from '@ngrx/store';
import { initialAiCreationProfileState } from '~/app/states/main/crm/ai-creation/states/profile/ai-creation-profile.state';
import * as AiCreationProfileActions from '~/app/states/main/crm/ai-creation/states/profile/ai-creation-profile.actions';

export const aiCreationProfileReducer = createReducer(
  initialAiCreationProfileState,
  on(AiCreationProfileActions.loadAiCreationProfileRequest, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiCreationProfileActions.loadAiCreationProfileRequestSuccess,
    (state, { request }) => ({
      ...state,
      request,
      loading: false,
    })
  ),
  on(
    AiCreationProfileActions.loadAiCreationProfileRequestFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiCreationProfileActions.updateAdvancement, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiCreationProfileActions.updateAdvancementSuccess,
    (state, { progress }) => ({
      ...state,
      progress,
      loading: false,
    })
  ),
  on(AiCreationProfileActions.updateAdvancementFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AiCreationProfileActions.loadStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AiCreationProfileActions.loadStatusesSuccess, (state, { statuses }) => ({
    ...state,
    statuses,
    loading: false,
  })),
  on(AiCreationProfileActions.loadStatusesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(AiCreationProfileActions.loadOffices, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AiCreationProfileActions.loadOfficesSuccess, (state, { offices }) => ({
    ...state,
    offices,
    loading: false,
  })),
  on(AiCreationProfileActions.loadOfficesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(AiCreationProfileActions.loadTeams, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AiCreationProfileActions.loadTeamsSuccess, (state, { teams }) => ({
    ...state,
    teams,
    loading: false,
  })),
  on(AiCreationProfileActions.loadTeamsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);
