import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as UserActions from '~/app/states/main/settings/states/users/users.actions';
import { UsersState } from '~/app/states/main/settings/states/users/users.state';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `UserSettingsEffects` orchestrates the handling of asynchronous operations related to user settings in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to user settings, such as fetching and
 * inviting users.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadUsers$`: Fetches users from the server.
 * — `inviteUser$`: Sends an invitation to a user.
 * — `updateUser$`: Updates a user's information.
 * — `assignGroupToUser$`: Assigns a group to a user.
 * — `unassignGroupToUser$`: Unassigns a group from a user.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class UserSettingsEffects {
  /**
   * Effect to load users. It listens for the `loadUsers` action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsers),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<UsersState>(`${environment.apiUrl}/v1/users`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(users => UserActions.loadUsersSuccess({ users })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.loadUsersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to invite a user. It listens for the `inviteUser` action, checks permissions,
   * and sends an invitation to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  inviteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.inviteUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ inviteData }) =>
        this.http
          .post<User>(
            `${environment.apiUrl}/v1/users/invitations`,
            inviteData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => UserActions.inviteUserSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.inviteUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of inviting a user. It listens for the `inviteUserSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  inviteUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.inviteUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User invited successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a user's information. It listens for the `updateUser` action, checks permissions,
   * and sends an update request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ uuid, userData }) =>
        this.http
          .patch<User>(`${environment.apiUrl}/v1/users/${uuid}`, userData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(user => UserActions.updateUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.updateUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of updating a user. It listens for the `updateUserSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a user's information. It listens for the `updateCurrentUser` action, checks permissions,
   * and sends an update request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateCurrentUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ userData }) =>
        this.http
          .patch<User>(`${environment.apiUrl}/v1/users/`, userData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(user => UserActions.updateCurrentUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.updateCurrentUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of updating a user. It listens for the `updateUserSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  updateCurrentUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateCurrentUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a group to a user. It listens for the `assignGroupToUser` action, checks permissions,
   * and sends an assign request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  assignGroupToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.assignGroupToUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ userId, groupId }) =>
        this.http
          .patch<User>(
            `${environment.apiUrl}/v1/users/assign/group`,
            { userId, groupId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(user => UserActions.assignGroupToUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.assignGroupToUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of assigning a group to a user. It listens for the `assignGroupToUserSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  assignGroupToUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.assignGroupToUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Group assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a group from a user. It listens for the `unassignGroupToUser` action, checks permissions,
   * and sends an unassign request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  unassignGroupToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.unassignGroupToUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ userId, groupId }) =>
        this.http
          .patch<User>(
            `${environment.apiUrl}/v1/users/unassigned/group`,
            { userId, groupId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(user => UserActions.unassignGroupToUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.unassignGroupToUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of unassigning a group from a user. It listens for the `unassignGroupToUserSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  unassignGroupToUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.unassignGroupToUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Group unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to activate a user. It listens for the `activateUser` action, checks permissions,
   * and sends an unassign request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  activateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.activateUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ uuid, activate }) =>
        this.http
          .patch<User>(
            `${environment.apiUrl}/v1/users/${uuid}`,
            { activate },
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(user => UserActions.activateUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.activateUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of activating a user. It listens for the `activateUserSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  activateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.activateUserSuccess),
        tap(() => {
          this.toast.successToast(
            'Activate',
            'You have successfully activated the user.'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to deactivate a user. It listens for the `deactivateUser` action, checks permissions,
   * and sends an unassign request to the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  desactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deactivateUser),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ uuid, activate }) =>
        this.http
          .patch<User>(
            `${environment.apiUrl}/v1/users/${uuid}`,
            { activate, firstname: 'John' },
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(user => UserActions.deactivateUserSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.deactivateUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of deactivating a user. It listens for the `deactivateUserSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  desactivateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.deactivateUserSuccess),
        tap(() => {
          this.toast.successToast(
            'Deactivate',
            'You have successfully deactivated the user.'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of UserSettingsEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {CustomToastService} toast - The service to handle toast notifications.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
