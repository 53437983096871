import { MonoTypeOperatorFunction } from 'rxjs';
import { operate } from 'rxjs/internal/util/lift';
import { createOperatorSubscriber } from 'rxjs/internal/operators/OperatorSubscriber';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import * as AuthActions from '~/app/auth/auth.actions';

/**
 * Custom RxJS operator that listens to actions and dispatches them to the NgRx store.
 *
 * This operator injects the NgRx `Store` and dispatches the given action using the
 * `AuthActions.registerAction` action. It forwards the action to the next operator in the pipeline.
 *
 * @template T - A type that extends `TypedAction<any>`, representing the action to be registered.
 *
 * @returns {MonoTypeOperatorFunction<T>} - A function that takes a source observable of actions
 * and returns an observable that dispatches the actions to the NgRx store and forwards them downstream.
 *
 * @example
 * source$.pipe(
 *   registerAction<MyAction>()
 * ).subscribe();
 *
 * In this example, each action emitted by `source$` is dispatched to the NgRx store using the
 * `AuthActions.registerAction` and then forwarded to the next operator.
 */
export function registerAction<
  T extends TypedAction<any>,
>(): MonoTypeOperatorFunction<T> {
  const store = inject(Store);

  return operate((source, subscriber) => {
    source.subscribe(
      createOperatorSubscriber(subscriber, action => {
        store.dispatch(AuthActions.registerAction({ action }));
        subscriber.next(action);
      })
    );
  });
}
