import { createReducer, on } from '@ngrx/store';
import * as TeamActions from '~/app/states/main/settings/states/teams/teams.actions';
import { initialAllTeamsState } from '~/app/states/main/settings/states/teams/teams.state';

export const allTeamsReducer = createReducer(
  initialAllTeamsState,

  on(TeamActions.loadAllTeams, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.loadAllTeamsSuccess, (state, { teams }) => ({
    ...state,
    ...teams,
    loading: false,
  })),
  on(TeamActions.loadAllTeamsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.createTeam, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.createTeamSuccess, (state, {}) => ({
    ...state,
    loading: false,
  })),
  on(TeamActions.createTeamFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.updateTeam, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.updateTeamSuccess, (state, { team }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === team.uuid ? team : item)),
    loading: false,
  })),
  on(TeamActions.updateTeamFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.deleteTeam, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.deleteTeamSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(TeamActions.deleteTeamFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.assignMember, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.assignMemberSuccess, (state, { team }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === team.uuid ? team : item)),
    loading: false,
  })),
  on(TeamActions.assignMemberFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(TeamActions.unassignMember, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.unassignMemberSuccess, (state, { team }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === team.uuid ? team : item)),
    loading: false,
  })),
  on(TeamActions.unassignMemberFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.changeMemberStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TeamActions.changeMemberStatusSuccess, (state, { team }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === team.uuid ? team : item)),
    loading: false,
  })),
  on(TeamActions.changeMemberStatusFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(TeamActions.teamsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
