import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';

/**
 * Represents the state of note categories.
 *
 * @interface NoteCategoryState
 */
export interface NoteCategoryState {
  /**
   * The list of note categories.
   *
   * @type {NoteCategory[]}
   */
  noteCategories: NoteCategory[];

  /**
   * Indicates whether the note categories are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last failed operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the note categories.
 *
 * @constant {NoteCategoryState} initialNoteCategoriesState
 * @description Provides a baseline definition for the note categories context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialNoteCategoriesState: NoteCategoryState = {
  noteCategories: [],
  loading: false,
  error: null,
};
