<div class="flex flex-column gap-4">
  <div *ngFor="let item of items" class="relative">
    <ng-container *ngIf="item.items; else noItems">
      <a
        class="flex flex-row px-5 gap-5 text-white no-underline hover:bg-bluegray-600 align-items-center p-3 hover:surface-100 border-round transition-colors transition-duration-150 cursor-pointer"
        pStyleClass="@next"
        enterFromClass="hidden"
        [enterActiveClass]="'slidedown'"
        leaveToClass="hidden"
        [leaveActiveClass]="'slideup'"
        [hideOnOutsideClick]="true">
        <img
          [ngSrc]="'assets/icons/modules/light/' + item.icon + '.svg'"
          width="20"
          height="20"
          alt="Item icon" />
        <span class="white-space-nowrap">{{ item.label | titlecase }}</span>
        <i class="pi pi-chevron-down ml-auto"></i>
      </a>
      <ul
        class="list-none pl-3 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
        <app-sidenav-item [items]="item.items"></app-sidenav-item>
      </ul>
    </ng-container>

    <ng-template #noItems>
      <a
        [routerLink]="item.path"
        class="flex flex-row px-5 gap-5 text-white no-underline hover:bg-bluegray-600 align-items-center p-3 hover:surface-100 border-round transition-colors transition-duration-150">
        <img
          [ngSrc]="'assets/icons/modules/light/' + item.icon + '.svg'"
          width="20"
          height="20"
          alt="Item icon" />
        <span class="white-space-nowrap">{{ item.label | titlecase }}</span>
      </a>
    </ng-template>
  </div>
</div>
