<!--
<div *ngIf="label && unit" class="text-xs color-dark-100">
  {{ label }}<span class="color-dark-70"> /{{ unit }}</span>
</div>

<p-slider
  class="m-2-5"
  #slider
  id="slider"
  [range]="true"
  [disabled]="true"
  [min]="min"
  [max]="max"
  [formControl]="formControl"></p-slider>

<div class="flex justify-content-between">
  <div class="floating-handle">
    {{
      isArray(formControl.value)
        ? formControl.value[0] ?? 0
        : formControl.value ?? 0
    }}
  </div>
  <div class="floating-handle">
    {{
      isArray(formControl.value)
        ? formControl.value[1] ?? 0
        : formControl.value ?? 0
    }}
  </div>
</div>-->

<div *ngIf="label && unit" class="text-xs color-dark-100">
  {{ label }}<span class="color-dark-70"> /{{ unit }}</span>
</div>

<div class="slider-container mt-5">
  <input
    id="fromSlider"
    type="range"
    [value]="value[0]"
    [defaultValue]="min"
    (input)="patchValue($event)"
    [min]="min"
    [max]="max | async" />
  <input
    #slider
    id="toSlider"
    type="range"
    [value]="value[1]"
    (input)="patchValue($event)"
    [defaultValue]="max"
    [min]="min"
    [max]="max | async" />

  <div #fromTag id="fromTag" class="absolute bottom-100">{{ value[0] }}</div>
  <div #toTag id="toTag" class="absolute top-50">{{ value[1] }}</div>
</div>
