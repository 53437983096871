import { createReducer, on } from '@ngrx/store';
import {
  companyUnauthorized,
  loadCompany,
  loadCompanyFailure,
  loadCompanySuccess,
  updateCompany,
  updateCompanyFailure,
  updateCompanySuccess,
} from '~/app/states/main/settings/states/company/company.actions';
import { initialCompanyState } from '~/app/states/main/settings/states/company/company.state';

export const companyReducer = createReducer(
  initialCompanyState,

  on(loadCompany, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCompanySuccess, (state, { company }) => ({
    ...state,
    loading: false,
    company: company,
  })),
  on(loadCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(updateCompany, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(updateCompanySuccess, (state, { company }) => ({
    ...state,
    company,
    loading: false,
  })),
  on(updateCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(companyUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
