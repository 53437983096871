import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';
import { Observable, skipWhile } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from '~/app/main/crm/ai-creation/services/profile.service';
import {
  selectOffices,
  selectProgress,
  selectStatuses,
  selectTeams,
} from '~/app/main/crm/ai-creation/states/profile/ai-creation-profile.selector';
import { CrmState } from '~/app/main/crm/crm.state';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Represents a component responsible for creating profiles.
 * Displays a form for creating profiles and allows users to upload files and select statuses, offices, and teams.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-ai-create-profile' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './create-profile.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./create-profile.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-ai-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss'],
})
export class CreateProfileComponent implements OnInit {
  /**
   * FileUpload component reference.
   * @type {FileUpload}
   */
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  /**
   * Form group for file upload.
   * @type {FormGroup}
   */
  fileForm!: FormGroup;

  /**
   * Observable for tracking the request progress.
   * @type {Observable<Progress | null>}
   */
  requestProgress!: Observable<Progress | null>;

  /**
   * Observable for the list of statuses with colors.
   * @type {Observable<({ color: string } & LabelValueInterface)[]>}
   */
  statuses$: Observable<({ color: string } & LabelValueInterface)[]>;

  /**
   * Observable for the list of offices.
   * @type {Observable<LabelValueInterface[]>}
   */
  offices$: Observable<LabelValueInterface[]>;

  /**
   * Observable for the list of teams.
   * @type {Observable<LabelValueInterface[]>}
   */
  teams$: Observable<LabelValueInterface[]>;

  /**
   * Enumeration for autocomplete template.
   * @type {typeof IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Creates an instance of CreateProfileComponent.
   *
   * @param {Store<CrmState>} store - Store to manage state.
   * @param {FormBuilder} formBuilder - Service to create form controls.
   * @param {ProfileService} profileService - Service to manage profile operations.
   */
  constructor(
    private store: Store<CrmState>,
    private formBuilder: FormBuilder,
    private profileService: ProfileService
  ) {
    this.requestProgress = this.store.select(selectProgress);

    this.statuses$ = this.store.select(selectStatuses).pipe(
      skipWhile(items => !items),
      map(statuses =>
        statuses.map(status => ({
          label: status.name,
          value: status.uuid,
          color: status.color,
        }))
      )
    );

    this.offices$ = this.store.select(selectOffices).pipe(
      skipWhile(items => !items),
      map(offices =>
        offices.map(office => ({ label: office.name, value: office.uuid }))
      )
    );

    this.teams$ = this.store.select(selectTeams).pipe(
      skipWhile(items => !items),
      map(teams => teams.map(team => ({ label: team.name, value: team.uuid })))
    );

    this.profileService.loadSuggestions();
  }

  /**
   * Gets the form control for the status ID.
   * @returns {FormControl} The form control for the status ID.
   */
  get statusId(): FormControl {
    return this.fileForm.get('statusId') as FormControl;
  }

  /**
   * Gets the form control for the team ID.
   * @returns {FormControl} The form control for the team ID.
   */
  get teamId(): FormControl {
    return this.fileForm.get('teamId') as FormControl;
  }

  /**
   * Gets the form control for the office ID.
   * @returns {FormControl} The form control for the office ID.
   */
  get officeId(): FormControl {
    return this.fileForm.get('officeId') as FormControl;
  }

  /**
   * Gets the form control for the resume.
   * @returns {FormControl} The form control for the resume.
   */
  get resume(): FormControl {
    return this.fileForm.get('resume') as FormControl;
  }

  /**
   * Initializes the component, setting up the form and loading initial data if available.
   * @returns {void}
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the file upload form with validation rules.
   * @returns {void}
   */
  initForm(): void {
    this.fileForm = this.formBuilder.group({
      resume: [null, Validators.required],
      statusId: ['', Validators.required],
      teamId: ['', Validators.required],
      officeId: ['', Validators.required],
    });
  }

  /**
   * Handles the file selection event and sets the selected file in the form control.
   * @param {FileSelectEvent} event - The file selection event.
   * @returns {void}
   */
  onFileSelect(event: FileSelectEvent): void {
    const file = event.files[0];
    this.resume.setValue(file);
  }

  /**
   * Submits the form data to load a profile request.
   * @returns {void}
   */
  onSubmit(): void {
    this.profileService.loadRequest(
      this.statusId.value,
      this.officeId.value,
      this.teamId.value,
      this.resume.value
    );
  }
}
