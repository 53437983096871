import { createAction, props } from '@ngrx/store';
import { Sales } from '@npmicedev/icemodule/lib/entities/Sales';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { LoadSalesByFilters } from '~/app/states/main/sales/components/search-sales/states/search-sales.state';

export const loadProject = createAction(
  '[Project] Load Project',
  props<{ uuid: string }>()
);
export const loadProjectSuccess = createAction(
  '[Project] Load Project Success',
  props<{ project: Project }>()
);
export const loadProjectFailure = createAction(
  '[Project] Load Project Failure',
  props<{ error: string }>()
);

export const loadProjectUsers = createAction(
  '[Project] Load Project Users',
  props<{ uuid: string }>()
);
export const loadProjectUsersSuccess = createAction(
  '[Project] Load Project Users Success',
  props<{ users: User[] }>()
);
export const loadProjectUsersFailure = createAction(
  '[Project] Load Project Users Failure',
  props<{ error: string }>()
);

export const loadProjectSales = createAction(
  '[Project] Load Project Sales',
  props<{ uuid: string }>()
);
export const loadProjectSalesSuccess = createAction(
  '[Project] Load Project Sales Success',
  props<{ sales: Sales[] }>()
);
export const loadProjectSalesFailure = createAction(
  '[Project] Load Project Sales Failure',
  props<{ error: string }>()
);

export const assignUserToProject = createAction(
  '[Project] Assign User To Project',
  props<{ projectId: string; userId: string }>()
);
export const assignUserToProjectSuccess = createAction(
  '[Project] Assign User To Project Success',
  props<{ projectId: string; users: User[] }>()
);
export const assignUserToProjectFailure = createAction(
  '[Project] Assign User To Project Failure',
  props<{ error: string }>()
);

export const unassignUserFromProject = createAction(
  '[Project] Unassign User From Project',
  props<{ projectId: string; userId: string }>()
);
export const unassignUserFromProjectSuccess = createAction(
  '[Project] Unassign User From Project Success',
  props<{ projectId: string; users: User[] }>()
);
export const unassignUserFromProjectFailure = createAction(
  '[Project] Unassign User From Project Failure',
  props<{ error: string }>()
);

export const assignSaleToProject = createAction(
  '[Project] Assign Sale To Project',
  props<{ projectId: string; saleId: string }>()
);
export const assignSaleToProjectSuccess = createAction(
  '[Project] Assign Sale To Project Success',
  props<{ projectId: string; sales: Sales[] }>()
);
export const assignSaleToProjectFailure = createAction(
  '[Project] Assign Sale To Project Failure',
  props<{ error: string }>()
);

export const unassignSaleFromProject = createAction(
  '[Project] Unassign Sale From Project',
  props<{ projectId: string; saleId: string }>()
);
export const unassignSaleFromProjectSuccess = createAction(
  '[Project] Unassign Sale From Project Success',
  props<{ projectId: string; sales: Sales[] }>()
);
export const unassignSaleFromProjectFailure = createAction(
  '[Project] Unassign Sale From Project Failure',
  props<{ error: string }>()
);

export const assignSalesToProject = createAction(
  '[Project] Assign Sales To Project',
  props<{ projectId: string; filters?: LoadSalesByFilters }>()
);
export const assignSalesToProjectSuccess = createAction(
  '[Project] Assign Sales To Project Success',
  props<{ sales: Sales[] }>()
);
export const assignSalesToProjectFailure = createAction(
  '[Project] Assign Sales To Project Failure',
  props<{ error: string }>()
);

export const clearSelectedProject = createAction(
  '[Project] Clear Selected Project'
);

export const projectUnauthorized = createAction(
  '[Project] Unauthorized',
  props<{ error: string }>()
);
