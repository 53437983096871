import { KanbanComment } from '~/app/shared/interfaces/kanban/kanban-comment.interface';

/**
 * Represents the state of Kanban cards.
 *
 * @interface KanbanCardCommentsState
 */
export interface KanbanCardCommentsState {
  /**
   * The Commentss associated with the Kanban card.
   *
   * @type {KanbanComment[]}
   */
  comments: KanbanComment[];

  /**
   * Indicates if the Kanban cards are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban cards.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of Kanban cards.
 *
 * @type {KanbanCardCommentsState}
 */
export const initialKanbanCardCommentssState: KanbanCardCommentsState = {
  comments: [],
  loading: false,
  error: null,
};
