<!--TODO: Add rules-->
<ng-container>
  <form
    [formGroup]="fileForm"
    class="flex flex-column gap-4 pb-2"
    (ngSubmit)="onSubmit()">
    <div class="flex flex-column align-items-start gap-2">
      <div class="align-self-center mt-4">
        <ice-file-upload
          #fileUpload
          mode="basic"
          i18n-mode
          chooseIcon="pi-paperclip"
          i18n-chooseIcon
          chooseLabel="Add a Resume"
          i18n-chooseLabel
          accept="application/pdf"
          i18n-accept
          [maxFileSize]="20000000"
          (onSelect)="onFileSelect($event)"></ice-file-upload>
      </div>
      <ice-autocomplete
        [label]="'Status'"
        [required]="true"
        id="statusId"
        placeholder="Select candidate status"
        i18n-placeholder
        formControlName="statusId"
        [formControl]="statusId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        [multiple]="false"
        [dropdown]="false"
        [iceAutocompleteTemplate]="templateEnum.STATUS"
        [suggestions]="(statuses$ | async) ?? []">
      </ice-autocomplete>

      <ice-autocomplete
        [label]="'Office'"
        [required]="true"
        placeholder="Assign office"
        i18n-placeholder
        formControlName="officeId"
        [formControl]="officeId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        [multiple]="false"
        [dropdown]="false"
        [suggestions]="(offices$ | async) ?? []"></ice-autocomplete>

      <ice-autocomplete
        [label]="'Team'"
        [required]="true"
        placeholder="Assign team"
        i18n-placeholder
        formControlName="teamId"
        [formControl]="teamId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        [multiple]="false"
        [dropdown]="false"
        [suggestions]="(teams$ | async) ?? []"></ice-autocomplete>
    </div>
    <ice-button
      class="align-self-end"
      type="submit"
      label="Generate Profile"
      i18n-label
      icon="pi pi-search"
      i18n-icon
      [disabled]="!fileForm.valid"></ice-button>
  </form>

  <ice-loading-backdrop
    *ngIf="requestProgress | async"
    [progress]="requestProgress"></ice-loading-backdrop>
</ng-container>
