import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as OfferProposalActions from '~/app/states/main/jobhub/states/offer-proposal/offer-proposal.actions';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `OfferProposalEffects` manages the side effects for offer proposal-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to offer proposals,
 * such as loading offers and changing offer proposal statuses. It performs operations that involve checking permissions,
 * interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadOffer$`: Loads a specific offer based on its UUID. It performs an HTTP GET request and dispatches a success action with the fetched offer or a failure action if an error occurs.
 * — `changeOfferProposalStatus$`: Changes the status of an offer proposal. It performs an HTTP PATCH request and dispatches a success action if successful or a failure action if an error occurs.
 * — `changeOfferProposalStatusSuccess$`: Handles the success of changing an offer proposal status by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class OfferProposalEffects {
  /**
   * Effect to load a specific offer based on its UUID.
   * Listens for the `loadOfferProposal` action, checks permissions, and performs an HTTP GET request to fetch the offer.
   * Dispatches a success or failure action based on the result.
   */
  loadOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferProposalActions.loadOfferProposal),
      checkPermission(OfferProposalActions.offerProposalUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Offer>(`${environment.apiUrl}/v1/offers/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offer =>
              OfferProposalActions.loadOfferProposalSuccess({ offer })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                OfferProposalActions.loadOfferProposalFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to change the status of an offer proposal.
   * Listens for the `changeOfferProposalStatus` action, checks permissions, and performs an HTTP PATCH request to change the status.
   * Dispatches a success or failure action based on the result.
   */
  changeOfferProposalStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferProposalActions.changeOfferProposalStatus),
      checkPermission(OfferProposalActions.offerProposalUnauthorized),
      mergeMap(({ offerProposalId, offerProposalData }) =>
        this.http
          .patch<Offer>(
            `${environment.apiUrl}/v1/offers/proposals/status/${offerProposalId}`,
            offerProposalData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(offer =>
              OfferProposalActions.changeOfferProposalStatusSuccess({ offer })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                OfferProposalActions.changeOfferProposalStatusFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of changing an offer proposal status.
   * Displays a success toast message. This effect does not dispatch any further actions.
   */
  changeOfferProposalStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferProposalActions.changeOfferProposalStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Proposal status has been updated'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `OfferProposalEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
