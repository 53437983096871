import { createReducer, on } from '@ngrx/store';
import * as SalesNotesActions from '~/app/states/main/sales/profile/states/sales-notes/sales-notes.actions';
import { initialSalesNotesState } from '~/app/states/main/sales/profile/states/sales-notes/sales-notes.state';

export const salesNotesReducer = createReducer(
  initialSalesNotesState,

  on(SalesNotesActions.loadSalesNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesNotesActions.loadSalesNotesSuccess, (state, { salesNotes }) => ({
    ...state,
    salesNotes,
    loading: false,
  })),
  on(SalesNotesActions.loadSalesNotesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesNotesActions.createSalesNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesNotesActions.createSalesNoteSuccess, (state, { salesNote }) => ({
    ...state,
    salesNotes: [salesNote, ...state.salesNotes],
    loading: false,
  })),
  on(SalesNotesActions.createSalesNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesNotesActions.updateSalesNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesNotesActions.updateSalesNoteSuccess, (state, { salesNote }) => ({
    ...state,
    salesNotes: state.salesNotes.map(note =>
      note.uuid === salesNote.uuid ? salesNote : note
    ),
    loading: false,
  })),
  on(SalesNotesActions.updateSalesNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesNotesActions.deleteSalesNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesNotesActions.deleteSalesNoteSuccess, (state, { uuid }) => ({
    ...state,
    salesNotes: state.salesNotes.filter(sn => sn.uuid !== uuid),
    loading: false,
  })),
  on(SalesNotesActions.deleteSalesNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesNotesActions.salesNotesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
