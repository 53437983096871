import { createReducer, on } from '@ngrx/store';
import * as IndustryActions from '~/app/states/main/settings/states/industries/industries.actions';
import { initialIndustriesState } from '~/app/states/main/settings/states/industries/industries.state';

export const industriesReducer = createReducer(
  initialIndustriesState,

  on(IndustryActions.loadIndustries, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    IndustryActions.loadIndustriesSuccess,
    (state, { availableIndustries, selectedIndustries }) => ({
      ...state,
      availableIndustries,
      selectedIndustries,
      loading: false,
    })
  ),
  on(IndustryActions.loadIndustriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(IndustryActions.assignIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(IndustryActions.assignIndustrySuccess, (state, { industries }) => ({
    ...state,
    selectedIndustries: industries,
    loading: false,
  })),
  on(IndustryActions.assignIndustryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(IndustryActions.unassignIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(IndustryActions.unassignIndustrySuccess, (state, { industries }) => ({
    ...state,
    selectedIndustries: industries,
    loading: false,
  })),
  on(IndustryActions.unassignIndustryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(IndustryActions.industryUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
