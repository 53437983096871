import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { HomeModule } from '~/app/shared/interfaces/module/home-module.interface';

/**
 * State type for managing home modules.
 *
 * @interface HomeModulesState
 */
export interface HomeModulesState {
  /**
   * The currently active home modules.
   *
   * @type {PaginationData<HomeModule> | null}
   */
  activeModules: PaginationData<HomeModule> | null;

  /**
   * All available home modules.
   *
   * @type {PaginationData<HomeModule> | null}
   */
  homeModules: PaginationData<HomeModule> | null;

  /**
   * Loading state indicator.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message if an error occurred while fetching the modules.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for the `HomeModulesState`.
 *
 * @type {HomeModulesState}
 */
export const initialHomeModulesState: HomeModulesState = {
  activeModules: null,
  homeModules: null,
  loading: false,
  error: null,
};
