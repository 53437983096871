import { Component, Input } from '@angular/core';

/**
 * SidebarHeaderDefaultComponent is a presentational component that displays a header for the sidebar.
 * It allows for a customizable title to be passed as an input.
 *
 * @selector 'app-sidebar-header-default'
 * @templateUrl './sidebar-header-default.component.html'
 * @styleUrl './sidebar-header-default.component.scss'
 */
@Component({
  selector: 'app-sidebar-header-default',
  templateUrl: './sidebar-header-default.component.html',
  styleUrls: ['./sidebar-header-default.component.scss'],
})
export class SidebarHeaderDefaultComponent {
  /**
   * The title to be displayed in the sidebar header.
   * This can be set to null if no title is provided.
   *
   * @type {string | null}
   */
  @Input() title: string | null = null;
}
