import { createAction, props } from '@ngrx/store';
import { AllTeamsFilters } from '~/app/shared/interfaces/crm/team/all-teams-filters.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { AllTeamsState } from '~/app/states/main/settings/states/teams/teams.state';
import {
  ChangeStatus,
  CreateTeam,
  UpdateTeamById,
} from '~/app/states/main/settings/types/team.types';

export const loadAllTeams = createAction(
  '[Teams] Load All Teams',
  props<{ filters?: AllTeamsFilters }>()
);
export const loadAllTeamsSuccess = createAction(
  '[Teams] Load All Teams Success',
  props<{ teams: AllTeamsState }>()
);
export const loadAllTeamsFailure = createAction(
  '[Teams] Load All Teams Failure',
  props<{ error: string }>()
);

export const createTeam = createAction(
  '[Teams] Create Team',
  props<{ teamData: CreateTeam }>()
);
export const createTeamSuccess = createAction(
  '[Teams] Create Team Success',
  props<{ team: Team }>()
);
export const createTeamFailure = createAction(
  '[Teams] Create Team Failure',
  props<{ error: string }>()
);

export const updateTeam = createAction(
  '[Teams] Update Team',
  props<{ teamUuid: string; teamData: UpdateTeamById }>()
);
export const updateTeamSuccess = createAction(
  '[Teams] Update Team Success',
  props<{ team: Team }>()
);
export const updateTeamFailure = createAction(
  '[Teams] Update Team Failure',
  props<{ error: string }>()
);

export const deleteTeam = createAction(
  '[Teams] Delete Team',
  props<{ uuid: string }>()
);
export const deleteTeamSuccess = createAction(
  '[Teams] Delete Team Success',
  props<{ teamId: string }>()
);
export const deleteTeamFailure = createAction(
  '[Teams] Delete Team Failure',
  props<{ error: string }>()
);

export const assignMember = createAction(
  '[Teams] Assign Member',
  props<{ teamUuid: string; userUuid: string }>()
);
export const assignMemberSuccess = createAction(
  '[Teams] Assign Member Success',
  props<{ team: Team }>()
);
export const assignMemberFailure = createAction(
  '[Teams] Assign Member Failure',
  props<{ error: string }>()
);

export const unassignMember = createAction(
  '[Teams] Unassign Member',
  props<{ teamUuid: string; userUuid: string }>()
);
export const unassignMemberSuccess = createAction(
  '[Teams] Unassign Member Success',
  props<{ team: Team }>()
);
export const unassignMemberFailure = createAction(
  '[Teams] Unassign Member Failure',
  props<{ error: string }>()
);

export const changeMemberStatus = createAction(
  '[Teams] Change Member Status',
  props<{
    teamUuid: string;
    userUuid: string;
    memberStatusData: ChangeStatus;
  }>()
);
export const changeMemberStatusSuccess = createAction(
  '[Teams] Change Member Status Success',
  props<{ team: Team }>()
);
export const changeMemberStatusFailure = createAction(
  '[Teams] Change Member Status Failure',
  props<{ error: string }>()
);

export const teamsUnauthorized = createAction(
  '[Teams] Unauthorized',
  props<{ error: string }>()
);
