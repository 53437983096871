import { createReducer, on } from '@ngrx/store';
import {
  HomeModulesState,
  initialHomeModulesState,
} from '~/app/states/main/states/home/home-modules.state';
import * as HomeModulesActions from '~/app/states/main/states/home/home-modules.actions';

export const homeModulesReducer = createReducer(
  initialHomeModulesState,

  on(HomeModulesActions.loadHomeModules, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HomeModulesActions.loadHomeModulesSuccess, (state, { homeModules }) => ({
    ...state,
    homeModules,
    loading: false,
  })),
  on(HomeModulesActions.loadHomeModulesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(HomeModulesActions.loadActiveModule, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    HomeModulesActions.loadActiveModuleSuccess,
    (state, { activeModules }) => ({
      ...state,
      activeModules,
      loading: false,
    })
  ),
  on(HomeModulesActions.loadActiveModuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    HomeModulesActions.activateModule,
    (state, { moduleId }): HomeModulesState => {
      // Optimistic update of the status according to the pendingActivation flag
      const updatedHomeModules = state.homeModules?.data.map(module => {
        if (module.uuid === moduleId) {
          return module.pendingActivation
            ? { ...module, status: 'pending' }
            : { ...module, status: 'activated' };
        }
        return module;
      });

      //If the module is pending activation, we don't need to add it to the activeModules
      const activeModulesToAdd = updatedHomeModules?.filter(
        module => module.uuid === moduleId && module.status === 'activated'
      );
      //Remove the module from the homeModules if it's not activated
      const remainingHomeModules = updatedHomeModules?.filter(
        module => module.uuid !== moduleId || module.status !== 'activated'
      );

      return {
        ...state,
        homeModules: { ...state.homeModules, data: remainingHomeModules },
        activeModules: {
          ...state.activeModules,
          data: [
            ...(state.activeModules?.data ?? []),
            ...(activeModulesToAdd ?? []),
          ],
        },
        loading: false,
        error: null,
      } as HomeModulesState;
    }
  ),

  on(HomeModulesActions.homeModulesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
