import { createAction, props } from '@ngrx/store';
import { ChangeProposalStatus } from '~/app/states/main/jobhub/types/offer-proposal.types';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

export const loadOfferProposal = createAction(
  '[Offer Proposal] Load Offer Proposal',
  props<{ uuid: string }>()
);
export const loadOfferProposalSuccess = createAction(
  '[Offer Proposal] Load Offer Proposal Success',
  props<{ offer: Offer }>()
);
export const loadOfferProposalFailure = createAction(
  '[Offer Proposal] Load Offer Proposal Failure',
  props<{ error: string }>()
);

export const changeOfferProposalStatus = createAction(
  '[Offer Proposal] Change Offer Proposal Status',
  props<{ offerProposalId: string; offerProposalData: ChangeProposalStatus }>()
);
export const changeOfferProposalStatusSuccess = createAction(
  '[Offer Proposal] Change Offer Proposal Status Success',
  props<{ offer: Offer }>()
);
export const changeOfferProposalStatusFailure = createAction(
  '[Offer Proposal] Change Offer Proposal Status Failure',
  props<{ error: string }>()
);

export const offerProposalUnauthorized = createAction(
  '[Offer Proposal] Unauthorized',
  props<{ error: string }>()
);
