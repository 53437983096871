<div class="pb-3 relative w-full flex flex-column gap-2">
  <label class="font-bold text-sm mx-0">
    {{ label }} <span *ngIf="required" class="text-red-600">*</span>
  </label>
  <div class="flex flex-row align-items-center gap-2">
    <p-colorPicker
      [(ngModel)]="value"
      (onChange)="onChange($event.value)"
      [disabled]="disabled"
      [format]="format"></p-colorPicker>
    <span *ngIf="showValue">{{ formControl.value ?? 'No value' }}</span>
  </div>
</div>
