import { createAction, props } from '@ngrx/store';
import { CreateProfileLanguages } from '~/app/states/main/crm/profile/types/profile-languages.types';
import { ProfileLanguage } from '~/app/shared/interfaces/crm/profile/profile-language.interface';

export const loadProfileLanguages = createAction(
  '[Profile Languages] Load Profile Languages',
  props<{ profileId: string; cecrl?: string }>()
);
export const loadProfileLanguagesSuccess = createAction(
  '[Profile Languages] Load Profile Languages Success',
  props<{ profileLanguages: ProfileLanguage[] }>()
);
export const loadProfileLanguagesFailure = createAction(
  '[Profile Languages] Load Profile Languages Failure',
  props<{ error: string }>()
);

export const createProfileLanguage = createAction(
  '[Profile Languages] Create Profile Language',
  props<{ profileLanguage: CreateProfileLanguages }>()
);
export const createProfileLanguageSuccess = createAction(
  '[Profile Languages] Create Profile Language Success',
  props<{ profileLanguage: ProfileLanguage }>()
);
export const createProfileLanguageFailure = createAction(
  '[Profile Languages] Create Profile Language Failure',
  props<{ error: string }>()
);

export const deleteProfileLanguage = createAction(
  '[Profile Languages] Delete Profile Language',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileLanguageSuccess = createAction(
  '[Profile Languages] Delete Profile Language Success',
  props<{ uuid: string }>()
);
export const deleteProfileLanguageFailure = createAction(
  '[Profile Languages] Delete Profile Language Failure',
  props<{ error: string }>()
);

export const profileLanguagesUnauthorized = createAction(
  '[Profile Languages] Unauthorized',
  props<{ error: string }>()
);
