import { createAction, props } from '@ngrx/store';
import { AllProjectsState } from '~/app/states/main/sales/states/search-projects/search-projects.state';
import {
  CreateProject,
  UpdateProject,
} from '~/app/states/main/sales/types/projects.types';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { AllProjectsFilters } from '~/app/shared/interfaces/sales/project/all-projects-filters.interface';

export const loadAllProjects = createAction(
  '[Projects] Load All Projects',
  props<{ filters?: AllProjectsFilters }>()
);
export const loadAllProjectsSuccess = createAction(
  '[Projects] Load All Projects Success',
  props<{ allProjects: AllProjectsState }>()
);
export const loadAllProjectsFailure = createAction(
  '[Projects] Load All Projects Failure',
  props<{ error: string }>()
);

export const createProject = createAction(
  '[Projects] Create Project',
  props<{ projectData: CreateProject }>()
);
export const createProjectSuccess = createAction(
  '[Projects] Create Project Success',
  props<{ project: Project }>()
);
export const createProjectFailure = createAction(
  '[Projects] Create Project Failure',
  props<{ error: string }>()
);

export const updateProject = createAction(
  '[Projects] Update Project',
  props<{ uuid: string; projectData: UpdateProject }>()
);
export const updateProjectSuccess = createAction(
  '[Projects] Update Project Success',
  props<{ project: Project }>()
);
export const updateProjectFailure = createAction(
  '[Projects] Update Project Failure',
  props<{ error: string }>()
);

export const deleteProject = createAction(
  '[Projects] Delete Project',
  props<{ uuid: string }>()
);
export const deleteProjectSuccess = createAction(
  '[Projects] Delete Project Success',
  props<{ uuid: string }>()
);
export const deleteProjectFailure = createAction(
  '[Projects] Delete Project Failure',
  props<{ error: string }>()
);

export const projectsUnauthorized = createAction(
  '[Projects] Unauthorized',
  props<{ error: string }>()
);
