import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfileQuickNavState } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.state';
import * as ProfileQuickNavActions from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.actions';

/**
 * `ProfileQuickNavService` manages quick navigation operations for profiles such as loading profile UUIDs and navigating up or down between profiles.
 * This class interacts with the NgRx store to dispatch actions related to profile quick navigation.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileQuickNavService {
  /**
   * Constructs an instance of the `ProfileQuickNavService`.
   *
   * @param {Store<ProfileQuickNavState>} store - Injectable store for managing profile quick navigation state.
   */
  constructor(private store: Store<ProfileQuickNavState>) {}

  /**
   * Loads the UUIDs of the profiles for quick navigation.
   *
   * @param {string[]} uuids - The array of profile UUIDs.
   * @returns {void}
   */
  loadProfilesUUIDs(uuids: string[]): void {
    this.store.dispatch(ProfileQuickNavActions.loadProfilesUUIDs({ uuids }));
  }

  /**
   * Navigates to the previous profile in the quick navigation list.
   *
   * @param {string} currentUUID - The UUID of the current profile.
   * @returns {void}
   */
  profileNavUp(currentUUID: string): void {
    this.store.dispatch(
      ProfileQuickNavActions.profileQuickjNavUp({ currentUUID })
    );
  }

  /**
   * Navigates to the next profile in the quick navigation list.
   *
   * @param {string} currentUUID - The UUID of the current profile.
   * @returns {void}
   */
  profileNavDown(currentUUID: string): void {
    this.store.dispatch(
      ProfileQuickNavActions.profileQuickjNavDown({ currentUUID })
    );
  }
}
