import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as RegisterActions from '~/app/auth/state/register/register.actions';

import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';
import * as AuthActions from '~/app/auth/auth.actions';

/**
 * `RegisterEffects` contains the side effects for handling user registration-related actions within the application.
 * It uses the `ngrx/effects` library to handle effects, which are responses to actions dispatched from
 * components or services, typically involving asynchronous processes like HTTP requests.
 *
 * The effects in this class include:
 * - Registering a new user and handling the success or failure of such operations.
 * - Redirecting the user upon successful registration.
 * - Verifying registration tokens.
 * - Checking invitation tokens and managing the result.
 * - Registering users who have accepted an invitation.
 * - Redirecting users based on the outcome of the registration processes.
 */
@Injectable()
export class RegisterEffects {
  /**
   * Effect to handle jobseeker registration. It listens for the `jobSeekerRegister` action, sends the registration
   * data via an HTTP POST request, and dispatches a success or failure action based on the result.
   */
  registerJobSeeker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.jobSeekerRegister),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ registerData }) =>
        this.http
          .post(
            `${environment.apiUrl}/v1/auth/register/freelance`,
            registerData,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() => RegisterActions.jobSeekerRegisterSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RegisterActions.jobSeekerRegisterFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle user registration. It listens for the `register` action, performs an HTTP POST request
   * to send the user's registration data to the server, and handles the response by dispatching either
   * a success or a failure action.
   */
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.register),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ registerData }) =>
        this.http
          .post(`${environment.apiUrl}/v1/auth/register`, registerData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => RegisterActions.registerSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RegisterActions.registerFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect users upon successful registration. It listens for the `registerSuccess` action
   * and uses the Angular Router to navigate to the 'register-success' route.
   */
  registerRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegisterActions.registerSuccess),
        tap(() => this.router.navigate(['/register-success']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to verify the registration token. It listens for the `checkToken` action and performs an HTTP POST
   * request to confirm the user's registration via a token. It then handles the response by dispatching a success
   * or failure action based on the result.
   */
  checkToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.checkToken),
      checkPermission(AuthActions.authUnauthorized),
      delay(2000),
      mergeMap(({ token }) =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/auth/register-confirm/${token}`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => RegisterActions.checkTokenSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RegisterActions.checkTokenFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to navigate to the CRM dashboard upon successful token verification.
   */
  checkTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegisterActions.checkTokenSuccess),
        tap(() => this.router.navigate(['/crm']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to check an invitation token. It listens for the `checkInvitation` action and performs an HTTP GET
   * request to verify the invitation. It handles the response by dispatching a success or failure action.
   */
  checkInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.checkInvitation),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ token }) =>
        this.http
          .get(
            `${environment.apiUrl}/v1/users/invitations/checking?token=${token}`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => RegisterActions.checkInvitationSuccess({ token })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RegisterActions.checkInvitationFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect to the homepage upon a failed invitation check.
   */
  checkInvitationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegisterActions.checkInvitationFailure),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle user registration from an invitation. It listens for the `userRegister` action,
   * sends the registration data along with the invitation token via an HTTP POST request, and processes
   * the response by dispatching a success or failure action.
   */
  userRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.userRegister),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ token, userRegisterData }) =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/users/register/${token}`,
            userRegisterData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => RegisterActions.userRegisterSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RegisterActions.userRegisterFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to navigate to the CRM dashboard upon successful user registration from an invitation.
   */
  userRegisterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegisterActions.userRegisterSuccess),
        tap(() => this.router.navigate(['/crm']))
      ),
    { dispatch: false }
  );

  /**
   * Constructs an instance of the class.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {Router} router - Injectable Angular Router for handling navigation and URL manipulation.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private httpErrors: HttpErrorsService
  ) {}
}
