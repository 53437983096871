import { createAction, props } from '@ngrx/store';
import { SalesNoteBody } from '~/app/shared/interfaces/sales/profile/sales-note-body.interface';
import { SalesNote } from '~/app/shared/interfaces/sales/profile/sales-note.interface';

export const loadSalesNotes = createAction(
  '[Sales Notes] Load Sales Notes',
  props<{ saleId: string }>()
);
export const loadSalesNotesSuccess = createAction(
  '[Sales Notes] Load Sales Notes Success',
  props<{ salesNotes: SalesNote[] }>()
);
export const loadSalesNotesFailure = createAction(
  '[Sales Notes] Load Sales Notes Failure',
  props<{ error: string }>()
);

export const createSalesNote = createAction(
  '[Sales Notes] Create Sales Note',
  props<{ salesNoteData: FormData }>()
);
export const createSalesNoteSuccess = createAction(
  '[Sales Notes] Create Sales Note Success',
  props<{ salesNote: SalesNote }>()
);
export const createSalesNoteFailure = createAction(
  '[Sales Notes] Create Sales Note Failure',
  props<{ error: string }>()
);

export const updateSalesNote = createAction(
  '[Sales Notes] Update Sale Note',
  props<{ uuid: string; body: SalesNoteBody }>()
);
export const updateSalesNoteSuccess = createAction(
  '[Sales Notes] Update Sales Note Success',
  props<{ salesNote: SalesNote }>()
);
export const updateSalesNoteFailure = createAction(
  '[Sales Notes] Update Sales Note Failure',
  props<{ error: string }>()
);

export const deleteSalesNote = createAction(
  '[Sales Notes] Delete Sales Note',
  props<{ uuid: string; saleId: string }>()
);
export const deleteSalesNoteSuccess = createAction(
  '[Sales Notes] Delete Sales Note Success',
  props<{ uuid: string }>()
);
export const deleteSalesNoteFailure = createAction(
  '[Sales Notes] Delete Sales Note Failure',
  props<{ error: string }>()
);

export const salesNotesUnauthorized = createAction(
  '[Sales Notes] Unauthorized',
  props<{ error: string }>()
);
