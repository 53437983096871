import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TwoFactorAuthService } from '~/app/auth/services/two-factor-auth.service';
import * as TwoFactorAuthActions from '~/app/auth/state/two-factor-auth/two-factor-auth.actions';
import * as AuthActions from '~/app/auth/auth.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `TwoFactorAuthEffects` manages the side effects for two-factor authentication (2FA) processes within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations that involve 2FA, such as disabling 2FA via recovery methods.
 * It performs HTTP requests to interact with backend APIs for 2FA operations and handles the application logic needed to respond to these actions.
 *
 * The effects in this class include:
 * - Disabling two-factor authentication using recovery codes.
 * - Providing feedback and redirection upon successful or failed 2FA disablement.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class TwoFactorAuthEffects {
  /**
   * Effect to handle the disabling of two-factor authentication by recovery. It triggers on the corresponding action,
   * checks for authorization, and makes an API call to disable 2FA. It processes the success or failure of this operation
   * and dispatches respective actions.
   */
  disableTwoFactorAuthenticationByRecovery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoFactorAuthActions.disableTwoFactorAuthenticationByRecovery),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ disableData }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/auth/two-factor/disable/recovery`, {
            body: disableData,
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              TwoFactorAuthActions.disableTwoFactorAuthenticationByRecoverySuccess()
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                TwoFactorAuthActions.disableTwoFactorAuthenticationByRecoveryFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Effect to provide feedback and redirect the user upon successful disabling of two-factor authentication.
   * It displays a success message and navigates back to the login page.
   */
  disableTwoFactorAuthenticationByRecoverySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TwoFactorAuthActions.disableTwoFactorAuthenticationByRecoverySuccess
        ),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Two-factor Authentication disabled successfully'
          );
          void this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructs the `TwoFactorAuthEffects` instance.
   *
   * @param {TwoFactorAuthService} twoFactorAuthService - Service to manage two-factor authentication operations.
   * @param {Actions} actions$ - Observable of actions used as a source for NgRx effects.
   * @param {HttpClient} http - The Angular HTTP client service for making API requests.
   * @param {Router} router - Angular Router for navigation.
   * @param {CustomToastService} toast - Service to display custom toast messages for user feedback.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors and providing feedback.
   */
  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService
  ) {}
}
