import { HomeProfileReminder } from '~/app/shared/interfaces/crm/home/reminder/home-profile-reminder.interface';

/**
 * Represents the state for home profile reminders.
 *
 * @interface HomeProfileRemindersState
 */
export interface HomeProfileRemindersState {
  /**
   * Total number of profile reminders.
   *
   * @type {number}
   */
  count: number;

  /**
   * Number of reminders per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * Indicates if there is a next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Array of profile reminders.
   *
   * @type {HomeProfileReminder[]}
   */
  data: HomeProfileReminder[];

  /**
   * Indicates if profile reminders are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, or null if no error.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * The date chosen by the user.
   *
   * @type {string}
   */
  currentDate: string;
}

/**
 * Initial state for home profile reminders, including count, limit, page, pagination indicators,
 * data, loading status, and error information.
 *
 * @constant
 * @type {HomeProfileRemindersState}
 */
export const initialHomeProfileRemindersState: HomeProfileRemindersState = {
  count: 0,
  limit: 0,
  page: 0,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
  currentDate: '',
};
