import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanyStatusActions from '~/app/states/main/settings/states/status/client-company-status/client-company-status.actions';
import { ClientCompanyStatus } from '~/app/shared/interfaces/settings/client-company-status.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanyStatusEffects` manages the side effects related to client company statuses in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting client company statuses from the server.
 *
 * The effects in this class include:
 * — `loadClientCompanyStatuses$`: Fetches client company statuses from the server.
 * — `createClientCompanyStatus$`: Creates a new client company status on the server.
 * — `createClientCompanyStatusSuccess$`: Handles successful client company status creation.
 * — `updateClientCompanyStatus$`: Updates an existing client company status on the server.
 * — `updateClientCompanyStatusSuccess$`: Handles successful client company status updates.
 * — `deleteClientCompanyStatus$`: Deletes a client company status from the server.
 * — `deleteClientCompanyStatusSuccess$`: Handles successful client company status deletion.
 * — `updateClientCompanyStatusSequence$`: Updates the sequence of client company statuses on the server.
 * — `updateProfileStatusSequenceSuccess$`: Handles successful client company status sequence updates.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanyStatusEffects {
  /**
   * Effect to load client company statuses.
   * Dispatches `loadClientCompanyStatusesSuccess` on success and `loadClientCompanyStatusesFailure` on failure.
   */
  loadClientCompanyStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyStatusActions.loadClientCompanyStatuses),
      checkPermission(
        ClientCompanyStatusActions.clientCompanyStatusUnauthorized
      ),
      mergeMap(() =>
        this.http
          .get<
            ClientCompanyStatus[]
          >(`${environment.apiUrl}/v1/clientCompanies/status`, { withCredentials: true })
          .pipe(
            take(1),
            map(clientCompanyStatuses =>
              ClientCompanyStatusActions.loadClientCompanyStatusesSuccess({
                clientCompanyStatuses,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyStatusActions.loadClientCompanyStatusesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a client company status.
   * Dispatches `createClientCompanyStatusSuccess` on success and `createClientCompanyStatusFailure` on failure.
   */
  createClientCompanyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyStatusActions.createClientCompanyStatus),
      checkPermission(
        ClientCompanyStatusActions.clientCompanyStatusUnauthorized
      ),
      mergeMap(({ clientCompanyStatusData }) =>
        this.http
          .post<ClientCompanyStatus>(
            `${environment.apiUrl}/v1/clientCompanies/status`,
            clientCompanyStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompanyStatus =>
              ClientCompanyStatusActions.createClientCompanyStatusSuccess({
                clientCompanyStatus,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyStatusActions.createClientCompanyStatusFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Side effect to handle actions after a successful client company status creation.
   * Shows a success toast and closes the sidebar.
   */
  createClientCompanyStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyStatusActions.createClientCompanyStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company status created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a client company status.
   * Dispatches `updateClientCompanyStatusSuccess` on success and `updateClientCompanyStatusFailure` on failure.
   */
  updateClientCompanyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyStatusActions.updateClientCompanyStatus),
      checkPermission(
        ClientCompanyStatusActions.clientCompanyStatusUnauthorized
      ),
      mergeMap(({ uuid, clientCompanyStatusData }) =>
        this.http
          .patch<ClientCompanyStatus>(
            `${environment.apiUrl}/v1/clientCompanies/status/${uuid}`,
            clientCompanyStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(status =>
              ClientCompanyStatusActions.updateClientCompanyStatusSuccess({
                status,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyStatusActions.updateClientCompanyStatusFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Side effect to handle actions after a successful client company status update.
   * Shows a success toast and closes the sidebar.
   */
  updateClientCompanyStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyStatusActions.updateClientCompanyStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company status updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a client company status.
   * Dispatches `deleteClientCompanyStatusSuccess` on success and `deleteClientCompanyStatusFailure` on failure.
   */
  deleteClientCompanyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyStatusActions.deleteClientCompanyStatus),
      checkPermission(
        ClientCompanyStatusActions.clientCompanyStatusUnauthorized
      ),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/clientCompanies/status/${uuid}`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              ClientCompanyStatusActions.deleteClientCompanyStatusSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyStatusActions.deleteClientCompanyStatusFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Side effect to handle actions after a successful client company status deletion.
   * Shows a success toast and closes the sidebar.
   */
  deleteClientCompanyStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyStatusActions.deleteClientCompanyStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company status deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the sequence of client company statuses.
   * Dispatches `updateClientCompanyStatusSequenceSuccess` on success and `updateClientCompanyStatusSequenceFailure` on failure.
   */
  updateClientCompanyStatusSequence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyStatusActions.updateClientCompanyStatusSequence),
        checkPermission(
          ClientCompanyStatusActions.clientCompanyStatusUnauthorized
        ),
        mergeMap(({ statuses }) =>
          this.http
            .patch<void>(
              `${environment.apiUrl}/v1/clientCompanies/status/sequences`,
              { statuses },
              { withCredentials: true }
            )
            .pipe(
              take(1),
              map(() =>
                ClientCompanyStatusActions.updateClientCompanyStatusSequenceSuccess(
                  {
                    statuses,
                  }
                )
              ),
              catchError(error => {
                this.httpErrors.handleError(error);
                return of(
                  ClientCompanyStatusActions.updateClientCompanyStatusSequenceFailure(
                    {
                      error,
                    }
                  )
                );
              })
            )
        )
      ),
    { dispatch: true }
  );

  /**
   * Side effect to handle actions after a successful update of client company status sequence.
   * Shows a success toast.
   */
  updateProfileStatusSequenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClientCompanyStatusActions.updateClientCompanyStatusSequenceSuccess
        ),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company status sequence updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ClientCompanyStatusEffects`.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - Injectable service for showing toast notifications.
   * @param {SidenavFormService} sidebarService - Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
