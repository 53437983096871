import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * Component to create a customizable input field, supporting both text and textarea input types, with additional
 * features like automatic text transformation and integration with permission-based visibility or functionality adjustments.
 *
 * @Component decorator provides Angular metadata for component declaration.
 * @selector 'ice-input' - The CSS selector for using this component.
 * @templateUrl './ice-input.component.html' - HTML template for this component.
 * @styleUrls ['./ice-input.component.scss'] - Styles for this component.
 * @providers NG_VALUE_ACCESSOR - Registers this component as a form control to be used with Angular forms.
 */
@Component({
  selector: 'ice-input',
  templateUrl: './ice-input.component.html',
  styleUrls: ['./ice-input.component.scss'],
  /**
   * Providers array for dependency injection within this component.
   *
   * - `provide: NG_VALUE_ACCESSOR` specifies that this component is going to provide
   *   its own implementation of the NG_VALUE_ACCESSOR token. This token is used for
   *   implementing the `ControlValueAccessor` interface which is essential for integrating
   *   with Angular forms.
   *
   * - `useExisting: forwardRef(() => IceInputComponent)` indicates that the existing
   *   instance of `IceInputComponent` should be used as the value for the NG_VALUE_ACCESSOR
   *   token. The `forwardRef` is used to handle the forward reference, as the `IceInputComponent`
   *   is not yet defined at this point of the code.
   *
   * - `multi: true` allows for multiple values to be associated with NG_VALUE_ACCESSOR token.
   *   It means that this component can be one of many providers that register themselves under
   *   the NG_VALUE_ACCESSOR token.
   */
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceInputComponent),
      multi: true,
    },
  ],
})
export class IceInputComponent implements AfterViewInit {
  /**
   * Determines if the component should render as a textarea instead of a text input.
   * @type {boolean}
   */
  @Input() textArea = false;

  /**
   * The label text for the input field.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Placeholder text for the input field.
   * @type {string | null}
   */
  @Input() placeholder!: string | null;

  /**
   * FormControl linked to the input, used for validation and managing form state.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * The name of the FormControl within a FormGroup, used for accessing and validating the control.
   * @type {string}
   */
  @Input() formControlName!: string;

  /**
   * Specifies if the input text should be automatically converted to lowercase.
   * @type {boolean}
   */
  @Input() applyLowercase = false;

  /**
   * Indicates whether the input field is required.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Error message associated with the input, typically set when validations fail.
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Indicates whether the form control has been submitted. Useful for conditional error messaging.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * Specifies the number of rows for the textarea, applicable when `textArea` is true.
   * @type {number}
   */
  @Input() rows = 5;

  /**
   * Specifies the number of columns for the textarea, applicable when `textArea` is true.
   * @type {number | undefined}
   */
  @Input() cols: number | undefined;

  /**
   * Optional icon to display within the input field.
   * @type {string | undefined}
   */
  @Input() startIcon: string | undefined;

  /**
   * Optional end icon to display within the input field.
   * @type {string | undefined}
   */
  @Input() endIcon: string | undefined;

  /**
   * Determines if the end icon should act as a button.
   * @type {boolean}
   */
  @Input() endIconButton = false;

  /**
   * Determines if the end icon button should be disabled.
   * @type {boolean}
   */
  @Input() endIconButtonDisable = false;

  /**
   * Tooltip text to be displayed. It can be a string or undefined.
   * @type {string | undefined}
   */
  @Input() tooltip: string | undefined;

  /**
   * Optional rule identifier for permissions checking, used to determine if the input should be displayed or modified.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Behavior when the user does not have the required permission. Can be set to disable the input or hide it entirely.
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Boolean to restrict the input value to number when set at true
   *
   * @type {boolean}
   */
  @Input() number = false;

  /**
   * Event emitted when the end icon button is clicked.
   * @type {EventEmitter<void>}
   */
  @Output() endIconAction = new EventEmitter<void>();

  /**
   * Boolean to disable the input
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * The internal data model bound to the input field's value.
   * @type {string}
   */
  public value!: string;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructs the IceInputComponent.
   *
   * @param {PermissionsService} permissionService - Service used to check user permissions, allowing the component to
   *                                                  handle visibility or accessibility based on user permissions.
   * @param {ElementRef} element - A wrapper around the native element this directive is attached to, allowing direct
   *                               access and manipulation of the input element in the DOM.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   * Checks the user's permission for the specified rule and adjusts the component's UI based
   * on the specified unauthorized behavior.
   *
   * This method subscribes to the permission check observable and applies UI changes (disabling
   * or hiding the input element) based on the result of the permission check:
   * - If the user does not have the necessary permission and the unauthorized behavior is set to `DISABLE`,
   *   the input element is disabled.
   * - If the unauthorized behavior is set to `NONE`, the input element is hidden.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.DISABLE:
                this.disableElement();
                break;
              case PermissionBehaviourEnum.NONE:
              default:
                this.hideElement();
            }
          }
        });
  }

  /**
   * Triggers the touch event callback when the input element gains focus.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Triggers the touch event callback when the input element loses focus.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a function to be called when the input value changes.
   * @param {AnyVoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Handles input events, updating the value and triggering change detection.
   * @param {Event} event - The input event (Event).
   * @returns {void}
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = this.applyLowercase ? input.value.toLowerCase() : input.value;
    this.onChange(this.value);
  }

  /**
   * Receives and displays an error message from form validation.
   * @param {string | null} message - The error message received.
   * @returns {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }

  /**
   * Applies a 'display: none' style to hide the element when necessary.
   * @returns {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the form control associated with this component when necessary.
   * @returns {void}
   */
  private disableElement(): void {
    this.formControl.disable();
  }
}
