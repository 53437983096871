import { createReducer, on } from '@ngrx/store';
import * as LanguagesActions from '~/app/shared/states/languages/languages.actions';
import { initialLanguagesState } from '~/app/shared/states/languages/languages.state';

export const languagesReducer = createReducer(
  initialLanguagesState,

  on(LanguagesActions.loadLanguages, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(LanguagesActions.loadLanguagesSuccess, (state, { languages }) => ({
    ...state,
    languages,
    loading: false,
  })),
  on(LanguagesActions.loadLanguagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(LanguagesActions.languagesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
