import { createAction, props } from '@ngrx/store';
import { Rate } from '@npmicedev/icemodule/lib/enums/rate';
import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { Users } from '@npmicedev/icemodule/lib/entities/Users';
import { Teams } from '@npmicedev/icemodule/lib/entities/Teams';
import { GoalResponse } from '~/app/states/main/stats/types/goal-response.types';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

// Load Actions
export const loadPinnedGoals = createAction(
  '[Goals] Load Pinned Goals',
  props<{ limit: number; page: number }>()
);

export const loadPinnedGoalsSuccess = createAction(
  '[Goals] Load Pinned Goals Success',
  props<{ pinned: PaginationData<GoalResponse> }>()
);

export const loadPinnedGoalsFailure = createAction(
  '[Goals] Load Pinned Goals Failure',
  props<{ error: string }>()
);

export const loadGoals = createAction(
  '[Goals] Load Goals',
  props<{ page: number; limit: number; rate: Rate }>()
);

export const loadGoalsSuccess = createAction(
  '[Goals] Load Goals Success',
  props<{
    goals: PaginationData<GoalResponse>;
    rate: Rate;
  }>()
);

export const loadGoalsFailure = createAction(
  '[Goals] Load Goals Failure',
  props<{ error: string }>()
);

export const loadGoalById = createAction(
  '[Goals] Load Goal By Id',
  props<{ goalUUID: string }>()
);

export const loadGoalByIdSuccess = createAction(
  '[Goals] Load Goal By Id Success',
  props<{ goal: Goals }>()
);

export const loadGoalByIdFailure = createAction(
  '[Goals] Load Goal By Id Failure',
  props<{ error: string }>()
);

export const createGoal = createAction(
  '[Goals] Create Goal',
  props<{ goal: Partial<Goals> }>()
);

export const createGoalSuccess = createAction(
  '[Goals] Create Goal Success',
  props<{ goal: GoalResponse }>()
);

export const createGoalFailure = createAction(
  '[Goals] Create Goal Failure',
  props<{ error: string }>()
);

export const updateGoalById = createAction(
  '[Goals] Update Goal By Id',
  props<{ goal: Partial<Goals> }>()
);

export const updateGoalByIdSuccess = createAction(
  '[Goals] Update Goal By Id Success',
  props<{ goal: GoalResponse }>()
);

export const updateGoalByIdFailure = createAction(
  '[Goals] Update Goal By Id Failure',
  props<{ error: string }>()
);

export const deleteGoalById = createAction(
  '[Goals] Delete Goal By Id',
  props<{ goalUUID: string }>()
);

export const deleteGoalByIdSuccess = createAction(
  '[Goals] Delete Goal By Id Success',
  props<{ goalUUID: string }>()
);

export const deleteGoalByIdFailure = createAction(
  '[Goals] Delete Goal By Id Failure',
  props<{ error: string }>()
);

// Pin Actions
export const pinGoal = createAction(
  '[Goals] Pin Goal',
  props<{ goal: GoalResponse }>()
);

export const pinGoalSuccess = createAction(
  '[Goals] Pin Goal Success',
  props<{ goal: GoalResponse }>()
);

export const pinGoalFailure = createAction(
  '[Goals] Pin Goal Failure',
  props<{ error: string }>()
);

// Unpin Actions
export const unpinGoal = createAction(
  '[Goals] Unpin Goal',
  props<{ goal: GoalResponse }>()
);

export const unpinGoalSuccess = createAction(
  '[Goals] Unpin Goal Success',
  props<{ goal: GoalResponse }>()
);

export const unpinGoalFailure = createAction(
  '[Goals] Unpin Goal Failure',
  props<{ error: string }>()
);

export const loadGoalUsers = createAction(
  '[Goals] Load Goal Users',
  props<{ name?: string }>()
);

export const loadGoalUsersSuccess = createAction(
  '[Goals] Load Goal Users Success',
  props<{ users: Users[] }>()
);

export const loadGoalUsersFailure = createAction(
  '[Goals] Load Goal Users Failure',
  props<{ error: string }>()
);

export const loadGoalTeams = createAction(
  '[Goals] Load Goal Teams',
  props<{ name?: string }>()
);

export const loadGoalTeamsSuccess = createAction(
  '[Goals] Load Goal Teams Success',
  props<{ teams: Teams[] }>()
);

export const loadGoalTeamsFailure = createAction(
  '[Goals] Load Goal Teams Failure',
  props<{ error: string }>()
);

export const goalsUnauthorized = createAction(
  '[Goals] Unauthorized',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * ISSE SERVER
 * ************************************************************************
 **/
export const assignGoalsUserServer = createAction(
  '[Goals] Assign Goals User Server',
  props<{ goals: Goals }>()
);
export const unassignGoalsUserServer = createAction(
  '[Goals] Unassign Goals User Server',
  props<{ goals: Goals }>()
);
