import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ProjectsActions from 'src/app/states/main/sales/states/search-projects/search-projects.actions';
import { loadProjectSuccess } from 'src/app/states/main/sales/states/project/project.actions';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { ProjectService } from '~/app/states/main/sales/services/project.service';
import { AllProjectsState } from '~/app/states/main/sales/states/search-projects/search-projects.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `AllProjectsEffects` handles the side effects related to managing all projects in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading, creating, updating, and deleting projects from the server.
 *
 * Effects included in this class:
 * — `loadAllProjects$`: Fetches all projects from the server based on provided filters.
 * — `loadAllProjectsSuccess$`: Handles successful loading of projects, loading the first project's details.
 * — `createProject$`: Creates a new project on the server.
 * — `createProjectSuccess$`: Handles successful project creation.
 * — `updateProject$`: Updates an existing project on the server.
 * — `updateProjectSuccess$`: Handles successful project updates.
 * — `deleteProject$`: Deletes a project from the server.
 * — `deleteProjectSuccess$`: Handles successful project deletion.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AllProjectsEffects {
  /**
   * Effect to load all projects based on filters.
   */
  loadAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.loadAllProjects),
      checkPermission(ProjectsActions.projectsUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<AllProjectsState>(`${environment.apiUrl}/v1/projects`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(allProjects =>
              ProjectsActions.loadAllProjectsSuccess({ allProjects })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectsActions.loadAllProjectsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful loading of projects.
   */
  loadAllProjectsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActions.loadAllProjectsSuccess),
        tap(({ allProjects }) => {
          if (allProjects.data.length) {
            this.projectService.loadAllProjectData(allProjects.data[0].uuid);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to create a new project.
   */
  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createProject),
      checkPermission(ProjectsActions.projectsUnauthorized),
      mergeMap(({ projectData }) =>
        this.http
          .post<Project>(`${environment.apiUrl}/v1/projects`, projectData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(project => ProjectsActions.createProjectSuccess({ project })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectsActions.createProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful project creation.
   */
  createProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActions.createProjectSuccess),
        tap(({ project }) => {
          this.toastService.successToast(
            'Success',
            'Project created successfully'
          );
          this.projectService.loadAllProjectData(project.uuid);
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing project.
   */
  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.updateProject),
      checkPermission(ProjectsActions.projectsUnauthorized),
      mergeMap(({ uuid, projectData }) =>
        this.http
          .patch<Project>(
            `${environment.apiUrl}/v1/projects/${uuid}`,
            projectData,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(project => ProjectsActions.updateProjectSuccess({ project })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectsActions.updateProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful project updates.
   */
  updateProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.updateProjectSuccess),
      map(({ project }) => {
        this.toastService.successToast(
          'Success',
          'Project updated successfully'
        );
        this.sidebarService.closeSidebar();
        return loadProjectSuccess({ project });
      })
    )
  );

  /**
   * Effect to delete a project.
   */
  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.deleteProject),
      checkPermission(ProjectsActions.projectsUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/projects/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => ProjectsActions.deleteProjectSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProjectsActions.deleteProjectFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful project deletion.
   */
  deleteProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActions.deleteProjectSuccess),
        tap(() => {
          this.toastService.successToast(
            'Success',
            'Project deleted successfully'
          );
          this.projectService.clearSelectedProject();
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `AllProjectsEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {ProjectService} projectService - Injects the ProjectService.
   * @param {CustomToastService} toastService - Injects the CustomToastService.
   * @param {SidenavFormService} sidebarService - Injects the SidenavFormService.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private projectService: ProjectService,
    private toastService: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
