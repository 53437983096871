import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import {
  releaseNoteDate,
  releaseNoteTourSteps,
} from '~/app/shared/const/release-note.const';

/**
 * Service for managing the feature demo tour.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureDemoService {
  /**
   * The current step index for the feature tour.
   * @type {number}
   */
  private currentStepIndex = 0;

  /**
   * Constructs an instance of the FeatureDemoService.
   * @param {ShepherdService} shepherdService - The ShepherdService for managing the feature tour.
   * @param {Router} router - The Angular router for navigating to different routes.
   */
  constructor(
    private shepherdService: ShepherdService,
    private router: Router
  ) {}

  /**
   * Saves the current step index to local storage.
   * @param {number} index - The current step index.
   * @returns {void}
   */
  saveCurrentStep(index: number): void {
    localStorage.setItem('currentStepIndex', index.toString());
  }

  /**
   * Gets the current step index from local storage.
   * @returns {number} The current step index.
   */
  getCurrentStep(): number {
    return parseInt(localStorage.getItem('currentStepIndex') ?? '0', 10);
  }

  /**
   * Starts the feature tour.
   * If the user has already seen the tour, it does nothing.
   * @returns {void}
   */
  startTour(): void {
    if (this.hasSeenTour()) {
      return;
    }
    const currentStepIndex = this.getCurrentStep();
    this.shepherdService.start();
    this.shepherdService.show(currentStepIndex);
    this.saveTourState();
  }

  /**
   * Sets up the tour by adding steps and buttons to the ShepherdService.
   * @returns {void}
   */
  setupTour(): void {
    const steps = releaseNoteTourSteps.map((step, index) => ({
      ...step,
      buttons: step.buttons.map(button => ({
        text: button.text,
        action: (): void => {
          this.saveCurrentStep(index);
          if (button.action === 'next') {
            const nextStep = releaseNoteTourSteps[index + 1];
            if (nextStep && nextStep.route !== step.route && nextStep.route) {
              this.router
                .navigateByUrl(nextStep.route)
                .then(() => {
                  setTimeout(() => {
                    this.shepherdService.next();
                  }, 0);
                })
                .catch(error => {
                  console.error('Navigation error:', error);
                });
            } else {
              this.shepherdService.next();
            }
          } else if (button.action === 'back') {
            this.shepherdService.back();
          } else if (button.action === 'complete') {
            this.saveCurrentStep(0); // Reset step on completion
            this.shepherdService.complete();
          }
        },
      })),
    }));

    this.shepherdService.modal = true;
    this.shepherdService.addSteps(steps);
  }

  /**
   * Saves the tour state to local storage.
   * This is used to determine if the user has seen the tour.
   * @returns {void}
   */
  saveTourState(): void {
    localStorage.setItem('tourSeen', new Date().toISOString());
  }

  /**
   * Checks if the user has seen the tour.
   * @returns {boolean} True if the user has seen the tour, false otherwise.
   */
  hasSeenTour(): boolean {
    return (localStorage.getItem('tourSeen') ?? '') >= releaseNoteDate;
  }

  /**
   * Resets the tour state by removing the tourSeen and currentStepIndex keys from local storage.
   * @returns {void}
   */
  resetTourState(): void {
    localStorage.removeItem('tourSeen');
    localStorage.removeItem('currentStepIndex');
  }

  /**
   * Checks if the tour is currently running.
   * @returns {boolean} True if the tour is running, false otherwise.
   */
  isTourRunning(): boolean {
    return this.shepherdService.isActive;
  }
}
