import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WsConsultingService } from '~/app/states/main/crm/ai-search/services/ws-consulting.service';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import * as AiConsultingActions from '~/app/states/main/crm/ai-search/states/consulting/consulting.actions';
import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';
import { environment } from '~/environments/environment';

/**
 * `AiConsultingEffects` is responsible for managing side effects related to AI consulting in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading and updating AI consulting information from and to the server.
 *
 * Effects included in this class:
 * - `loadRequest$`: Fetches AI consulting requests from the server.
 * - `loadRequestSuccess$`: Handles successful AI consulting requests.
 * - `loadRequestFailure$`: Handles failed AI consulting requests.
 * - `updateAdvancement$`: Updates the progress of an AI consulting request.
 * - `loadCandidates$`: Fetches AI consulting job matching candidates from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AiConsultingEffects {
  /**
   * Effect to load an AI consulting job matching request.
   * Listens for the loadAiConsultingJobMatchingRequest action, checks permissions, makes an API request,
   * and dispatches a success or failure action accordingly.
   */
  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiConsultingActions.loadAiConsultingJobMatchingRequest),
      checkPermission(AiConsultingActions.consultingAISearchUnauthorized),
      mergeMap(({ formData }) =>
        this.http
          .post<WsRequest>(
            `${environment.apiUrl}/v1/ai/profiles/matching/consulting`,
            formData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(request =>
              AiConsultingActions.loadAiConsultingJobMatchingRequestSuccess({
                request,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiConsultingActions.loadAiConsultingJobMatchingRequestFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of loading an AI consulting job matching request.
   * Initializes a WebSocket connection with the provided request.
   */
  loadRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiConsultingActions.loadAiConsultingJobMatchingRequestSuccess),
        tap(({ request }) => {
          this.wsService.initializeConnection(request);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle the failure of loading an AI consulting job matching request.
   * Updates the progress to null.
   */
  loadRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiConsultingActions.loadAiConsultingJobMatchingRequestFailure),
        map(() => AiConsultingActions.updateAdvancement({ progress: null }))
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the advancement of an AI consulting job matching process.
   * Listens for the updateAdvancement action and dispatches a success or failure action accordingly.
   */
  updateAdvancement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiConsultingActions.updateAdvancement),
      map(({ progress }) =>
        AiConsultingActions.updateAdvancementSuccess({ progress })
      ),
      catchError(error =>
        of(AiConsultingActions.updateAdvancementFailure({ error }))
      )
    )
  );

  /**
   * Effect to load AI consulting job matching candidates.
   * Listens for the loadAiConsultingJobMatchingCandidates action, checks permissions, makes an API request,
   * and dispatches a success or failure action accordingly.
   */
  loadCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiConsultingActions.loadAiConsultingJobMatchingCandidates),
      checkPermission(AiConsultingActions.consultingAISearchUnauthorized),
      mergeMap(({ url }) =>
        this.http
          .get<MatchingObject>(
            `${environment.apiUrl}/v1/matchingHistories/${url}`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(matching =>
              AiConsultingActions.loadAiConsultingJobMatchingCandidatesSuccess({
                matching,
              })
            ),
            catchError(error =>
              of(
                AiConsultingActions.loadAiConsultingJobMatchingCandidatesFailure(
                  {
                    error,
                  }
                )
              )
            )
          )
      )
    )
  );

  /**
   * Creates an instance of AiConsultingEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {WsConsultingService} wsService - The WebSocket service to handle WebSocket connections.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    public sidebarService: SidenavFormService,
    private httpErrors: HttpErrorsService,
    private wsService: WsConsultingService
  ) {}
}
