import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultingService } from '~/app/states/main/crm/ai-search/services/consulting.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { BaseSocketService } from '~/app/core/services/base-socket.service';

/**
 * WebSocket consulting service.
 */
@Injectable({
  providedIn: 'root',
})
export class WsConsultingService extends BaseSocketService {
  /**
   * Constructs an instance of the WsConsultingService.
   * @param {ConsultingService} consultingService - The consulting service.
   * @param {HttpErrorsService} httpErrors - The HTTP errors service.
   * @param {Router} router - The router service.
   */
  constructor(
    private consultingService: ConsultingService,
    private httpErrors: HttpErrorsService,
    private router: Router
  ) {
    super();
  }

  /**
   * Sends the WebSocket request.
   * @returns {void}
   */
  onOpen = (): void => {
    this.wsClient?.send(
      JSON.stringify({
        action: this.request?.action,
        content: this.request?.content,
      })
    );
  };

  /**
   * Handles the message event.
   * @param {MessageEvent} message - The message event.
   * @returns {void}
   */
  onMessage = (message: MessageEvent): void => {
    try {
      const data = JSON.parse(message.data);
      if (data.content && data.progress !== undefined) {
        if (data.values) {
          void this.router.navigateByUrl(
            '/crm/ai-consulting/consulting/' + data.values.uuid
          );
          this.consultingService.updateProgress(null);
          this.wsClient?.close();
        } else {
          this.consultingService.updateProgress({
            progress: data.progress,
            content: data.content,
          });
        }
      }
      if (data.error) {
        this.httpErrors.handleError(data.error);
        this.wsClient?.close();
        this.consultingService.updateProgress(null);
      }
    } catch (error) {
      this.httpErrors.handleError(error);
      this.wsClient?.close();
      this.consultingService.updateProgress(null);
    }
  };

  /**
   * Handles the WebSocket onerror event.
   * @param {Event} event - The error event.
   * @returns {void}
   */
  onError = (event: Event): void => {
    this.httpErrors.handleError(event.target);
    this.wsClient?.close();
    this.consultingService.updateProgress(null);
  };
}
