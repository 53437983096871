import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanySectorJobsActions from '~/app/states/main/client-company/profile/states/client-company-sector-jobs/client-company-sector-jobs.actions';
import { ClientCompanySectorJobs } from '~/app/shared/interfaces/client-company/profile/client-company-sector-jobs.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanySectorJobsEffects` handles the side effects related to client company sector jobs in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading, assigning, and unassigning client company sector jobs from the server.
 *
 * Effects included in this class:
 * — `loadClientCompanySectorJobs$`: Fetches client company sector jobs by company UUID from the server.
 * — `assignClientCompanySectorJob$`: Assigns a sector job to a client company.
 * — `assignClientCompanySectorJobSuccess$`: Handles successful sector job assignment.
 * — `unassignClientCompanySectorJob$`: Unassigns a sector job from a client company.
 * — `unassignClientCompanySectorJobSuccess$`: Handles successful sector job unassignment.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanySectorJobsEffects {
  /**
   * Effect to load client company sector jobs by company UUID.
   */
  loadClientCompanySectorJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanySectorJobsActions.loadClientCompanySectorJobs),
      checkPermission(
        ClientCompanySectorJobsActions.clientCompanySectorJobsUnauthorized
      ),
      mergeMap(({ uuid }) =>
        this.http
          .get<
            ClientCompanySectorJobs[]
          >(`${environment.apiUrl}/v1/clientCompanies/sectorJobs/${uuid}`, { withCredentials: true })
          .pipe(
            take(1),
            map(clientCompanySectorJobs =>
              ClientCompanySectorJobsActions.loadClientCompanySectorJobsSuccess(
                { clientCompanySectorJobs }
              )
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanySectorJobsActions.loadClientCompanySectorJobsFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Effect to assign a sector job to a client company.
   */
  assignClientCompanySectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanySectorJobsActions.assignClientCompanySectorJob),
      checkPermission(
        ClientCompanySectorJobsActions.clientCompanySectorJobsUnauthorized
      ),
      mergeMap(({ uuid, clientCompanySectorJob }) =>
        this.http
          .patch<
            ClientCompanySectorJobs[]
          >(`${environment.apiUrl}/v1/clientCompanies/sectorJobs/${uuid}/assignSectorJob`, clientCompanySectorJob, { withCredentials: true })
          .pipe(
            take(1),
            map(clientCompanySectorJobs =>
              ClientCompanySectorJobsActions.assignClientCompanySectorJobSuccess(
                { clientCompanySectorJobs }
              )
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanySectorJobsActions.assignClientCompanySectorJobFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful sector job assignment.
   */
  assignClientCompanySectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClientCompanySectorJobsActions.assignClientCompanySectorJobSuccess
        ),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company sector assigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a sector job from a client company.
   */
  unassignClientCompanySectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanySectorJobsActions.unassignClientCompanySectorJob),
      checkPermission(
        ClientCompanySectorJobsActions.clientCompanySectorJobsUnauthorized
      ),
      mergeMap(({ uuid, clientCompanySectorJob }) =>
        this.http
          .patch<
            ClientCompanySectorJobs[]
          >(`${environment.apiUrl}/v1/clientCompanies/sectorJobs/${uuid}/unassignedSectorJob`, clientCompanySectorJob, { withCredentials: true })
          .pipe(
            take(1),
            map(clientCompanySectorJobs =>
              ClientCompanySectorJobsActions.unassignClientCompanySectorJobSuccess(
                { clientCompanySectorJobs }
              )
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanySectorJobsActions.unassignClientCompanySectorJobFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful sector job unassignment.
   */
  unassignClientCompanySectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClientCompanySectorJobsActions.unassignClientCompanySectorJobSuccess
        ),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company sector jobs unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ClientCompanySectorJobsEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {CustomToastService} toast - Injects the CustomToastService.
   * @param {SidenavFormService} sidebarService - Injects the SidenavFormService.
   * @memberof ClientCompanySectorJobsEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
