import { createSelector } from '@ngrx/store';
import { selectKanbanState } from '~/app/states/main/kanban/kanban.selectors';

export const selectKanbanTagsState = createSelector(
  selectKanbanState,
  state => state.kanbanTags
);

export const selectKanbanTags = createSelector(
  selectKanbanTagsState,
  state => state.kanbanTags
);

export const selectTagsLoadingState = createSelector(
  selectKanbanTagsState,
  state => state.loading
);
