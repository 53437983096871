import { Component } from '@angular/core';

/**
 * Component responsible for rendering different types of toast notifications based on predefined keys.
 * Utilizes PrimeNG's `<p-toast>` element to create multiple, distinct toast containers for various notification
 * types like success, info, warning, and error. Each toast type is styled and behaves according to its
 * intended context, ensuring that notifications are both visually distinct and contextually appropriate.
 *
 * @Component decorator configures the component:
 * @selector 'app-toast' - The CSS selector for the component.
 * @templateUrl './toast.component.html' - Path to the HTML template that includes multiple `<p-toast>` configurations.
 * @styleUrls ['./toast.component.scss'] - Path to the styles specific to the toast presentations.
 */
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {}
