import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// eslint-disable-next-line valid-jsdoc
/**
 * withTimestamp is a utility function that adds a timestamp and a name to each item emitted by the source observable.
 *
 * @param {Observable<T>} obs - The source observable.
 * @param {string} name - The name to attach to each emitted item.
 * @return {Observable<{ data: T; timestamp: number; name: string }>} - An observable emitting objects containing the original data, a timestamp, and the name.
 */
export function withTimestamp<T>(
  obs: Observable<T>,
  name: string
): Observable<{ data: T; timestamp: number; name: string }> {
  return obs.pipe(
    map(data => ({
      data,
      timestamp: Date.now(),
      name,
    }))
  );
}
