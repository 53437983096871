import { createReducer, on } from '@ngrx/store';
import { initialKanbanCardAttachmentsState } from './kanban-card-attachments.state';
import * as KanbanCardAttachmentsActions from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.actions';

export const kanbanCardAttachmentsReducer = createReducer(
  initialKanbanCardAttachmentsState,

  on(KanbanCardAttachmentsActions.loadAllCardAttachments, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardAttachmentsActions.loadAllCardAttachmentsSuccess,
    (state, { kanbanAttachments }) => ({
      ...state,
      attachments: kanbanAttachments,
      loading: false,
    })
  ),
  on(
    KanbanCardAttachmentsActions.loadAllCardAttachmentsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardAttachmentsActions.createKanbanCardAttachment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardAttachmentsActions.createKanbanCardAttachmentSuccess,
    KanbanCardAttachmentsActions.createKanbanCardAttachmentServer,
    (state, { kanbanAttachment }) => ({
      ...state,
      attachments: [...state.attachments, kanbanAttachment],
      loading: false,
    })
  ),
  on(
    KanbanCardAttachmentsActions.createKanbanCardAttachmentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardAttachmentsActions.updateKanbanCardAttachment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardAttachmentsActions.updateKanbanCardAttachmentSuccess,
    KanbanCardAttachmentsActions.updateKanbanCardAttachmentServer,
    (state, { kanbanAttachment }) => ({
      ...state,
      attachments: state.attachments.map(attachment =>
        attachment.uuid === kanbanAttachment.uuid
          ? kanbanAttachment
          : attachment
      ),
      loading: false,
    })
  ),
  on(
    KanbanCardAttachmentsActions.updateKanbanCardAttachmentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardAttachmentsActions.deleteKanbanCardAttachment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardAttachmentsActions.deleteKanbanCardAttachmentSuccess,
    KanbanCardAttachmentsActions.deleteKanbanCardAttachmentServer,
    (state, { uuid }) => ({
      ...state,
      attachments: state.attachments.filter(
        attachment => attachment.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    KanbanCardAttachmentsActions.deleteKanbanCardAttachmentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    KanbanCardAttachmentsActions.kanbanAttachmentsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
