import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, take, tap } from 'rxjs';
import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { Users } from '@npmicedev/icemodule/lib/entities/Users';
import { Teams } from '@npmicedev/icemodule/lib/entities/Teams';
import * as GoalsActions from '~/app/states/main/stats/states/goals/goals.actions';
import { environment } from '~/environments/environment';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { GoalResponse } from '~/app/states/main/stats/types/goal-response.types';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';

/**
 * GoalsEffects manages side effects related to goals within the application.
 * This class responds to actions dispatched from components or services,
 * performs asynchronous HTTP requests to interact with the backend API,
 * and manages state updates based on the results of these requests.
 *
 * The effects contained in this service include loading, updating, pinning,
 * and unpinning goals, as well as loading associated users and teams.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency.
 */
@Injectable()
export class GoalsEffects {
  /**
   * Effect to load pinned goals. It listens for the `loadPinnedGoals` action,
   * checks permissions, and then makes an HTTP GET request to fetch pinned goals.
   * On success, it dispatches a `loadPinnedGoalsSuccess` action with the fetched data.
   * On failure, it dispatches a `loadPinnedGoalsFailure` action with the error.
   */
  loadPinnedGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadPinnedGoals),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ limit, page }) => {
        const params = new HttpParams().set('page', page).set('limit', limit);
        return this.http
          .get<
            PaginationData<GoalResponse>
          >(`${environment.apiUrl}/v1/goalPins`, { withCredentials: true, params })
          .pipe(
            take(1),
            map(pinned => GoalsActions.loadPinnedGoalsSuccess({ pinned })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.loadPinnedGoalsFailure({ error: error.message })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to load goals. It listens for the `loadGoals` action,
   * checks permissions, and retrieves goals based on the specified pagination parameters.
   * On success, it dispatches a `loadGoalsSuccess` action with the fetched goals.
   * On failure, it dispatches a `loadGoalsFailure` action with the error.
   */
  loadGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadGoals),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ page, limit, rate }) => {
        const params = new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('rate', rate);
        return this.http
          .get<
            PaginationData<GoalResponse>
          >(`${environment.apiUrl}/v1/goals`, { withCredentials: true, params })
          .pipe(
            take(1),
            map(goals => GoalsActions.loadGoalsSuccess({ goals, rate })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.loadGoalsFailure({ error: error.message })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to load a goal by its unique identifier. It listens for the `loadGoalById` action,
   * checks permissions, and makes an HTTP GET request to fetch the specified goal.
   * On success, it dispatches a `loadGoalByIdSuccess` action with the fetched goal details.
   * On failure, it dispatches a `loadGoalByIdFailure` action with the error.
   */
  loadGoalById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadGoalById),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goalUUID }) =>
        this.http
          .get<Goals>(`${environment.apiUrl}/v1/goals/${goalUUID}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(goal => GoalsActions.loadGoalByIdSuccess({ goal })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.loadGoalByIdFailure({ error: error.message })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to delete a goal by its unique identifier. It listens for the `deleteGoalById` action,
   * checks permissions, and performs an HTTP DELETE request to remove the specified goal.
   * On success, it dispatches `deleteGoalByIdSuccess` with the goal's UUID.
   * On failure, it dispatches `deleteGoalByIdFailure` with the error.
   */
  deleteGoalById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.deleteGoalById),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goalUUID }) =>
        this.http
          .delete<Goals>(`${environment.apiUrl}/v1/goals/${goalUUID}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => GoalsActions.deleteGoalByIdSuccess({ goalUUID })),
            tap(() => {
              this.sideForm.closeSidebar();
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.deleteGoalByIdFailure({ error: error.message })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a goal. It listens for the `createGoal` action,
   * checks permissions, and performs an HTTP PATCH request with updated goal data.
   * On success, it dispatches `createGoalSuccess` with the updated goal.
   * On failure, it dispatches `createGoalFailure` with the error.
   */
  createGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.createGoal),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goal }) =>
        this.http
          .post<GoalResponse>(
            `${environment.apiUrl}/v1/goals`,
            { ...goal },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            tap(() => {
              this.sideForm.toggleSidebar();
            }),
            map(goal => GoalsActions.createGoalSuccess({ goal })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.createGoalFailure({ error: error.message })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to update an existing goal by its unique identifier. It listens for the `updateGoalById` action,
   * checks permissions, and performs an HTTP PATCH request with updated goal data.
   * On success, it dispatches `updateGoalByIdSuccess` with the updated goal.
   * On failure, it dispatches `updateGoalByIdFailure` with the error.
   */
  updateGoalById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.updateGoalById),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goal }) =>
        this.http
          .patch<GoalResponse>(
            `${environment.apiUrl}/v1/goals/${goal.uuid}`,
            { ...goal },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            tap(() => {
              this.sideForm.toggleSidebar();
            }),
            map(goal => GoalsActions.updateGoalByIdSuccess({ goal })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.updateGoalByIdFailure({ error: error.message })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to pin a specific goal. It listens for the `pinGoal` action,
   * checks permissions, and performs an HTTP PATCH request to pin the specified goal.
   * On success, it dispatches `pinGoalSuccess` with the goal data.
   * On failure, it dispatches `pinGoalFailure` with the error.
   */
  pinGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.pinGoal),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goal }) =>
        this.http
          .patch<GoalResponse>(
            `${environment.apiUrl}/v1/goalPins/${goal.goal.uuid}/assign`,
            {},
            { withCredentials: true }
          )
          .pipe(
            map(() => GoalsActions.pinGoalSuccess({ goal })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GoalsActions.pinGoalFailure({ error: error.message }));
            })
          )
      )
    )
  );

  /**
   * Effect to unpin a specific goal. It listens for the `unpinGoal` action,
   * checks permissions, and performs an HTTP PATCH request to unpin the specified goal.
   * On success, it dispatches `unpinGoalSuccess` with the goal data.
   * On failure, it dispatches `unpinGoalFailure` with the error.
   */
  unpinGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.unpinGoal),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ goal }) =>
        this.http
          .patch<GoalResponse>(
            `${environment.apiUrl}/v1/goalPins/${goal.goal.uuid}/unassign`,
            {},
            { withCredentials: true }
          )
          .pipe(
            map(() => GoalsActions.unpinGoalSuccess({ goal })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.unpinGoalFailure({ error: error.message })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load goal users. It listens for the `loadGoalUsers` action,
   * checks permissions, and performs an HTTP GET request to fetch users related to goals.
   * On success, it dispatches `loadGoalUsersSuccess` with the fetched user list.
   * On failure, it dispatches `loadGoalUsersFailure` with the error.
   */
  loadGoalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadGoalUsers),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ name }) => {
        let params = new HttpParams().set('page', '0').set('limit', '10');
        if (name) params = params.set('name', name);
        return this.http
          .get<
            PaginationData<Users>
          >(`${environment.apiUrl}/v1/users`, { params })
          .pipe(
            map(({ data: users }) =>
              GoalsActions.loadGoalUsersSuccess({ users })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.loadGoalUsersFailure({ error: error.message })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to load goal teams. It listens for the `loadGoalTeams` action,
   * checks permissions, and performs an HTTP GET request to fetch teams related to goals.
   * On success, it dispatches `loadGoalTeamsSuccess` with the fetched team list.
   * On failure, it dispatches `loadGoalTeamsFailure` with the error.
   */
  loadGoalTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadGoalTeams),
      checkPermission(GoalsActions.goalsUnauthorized),
      mergeMap(({ name }) => {
        let params = new HttpParams().set('page', '0').set('limit', '50');
        if (name) params = params.set('name', name);
        return this.http
          .get<
            PaginationData<Teams>
          >(`${environment.apiUrl}/v1/teams`, { params })
          .pipe(
            map(({ data: teams }) =>
              GoalsActions.loadGoalTeamsSuccess({ teams })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                GoalsActions.loadGoalTeamsFailure({ error: error.message })
              );
            })
          );
      })
    )
  );

  /**
   * Constructs the GoalsEffects service with necessary dependencies.
   *
   * @param {Actions} actions$ - Observable stream of actions, provided by NgRx.
   * @param {HttpClient} http - HTTP client for making API requests.
   * @param {CustomToastService} toast - Service for displaying toast notifications.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors.
   * @param {SidenavFormService} sideForm - Service for managing the side form interactions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService,
    private sideForm: SidenavFormService
  ) {}
}
