<div
  class="surface-card p-4 shadow-2 border-round min-w-20rem max-w-30rem bg-cover">
  <app-section-heading
    title="Company Details"
    i18n-title
    subtitle="Please provide the following details about your company"
    i18n-subtitle>
  </app-section-heading>
  <form [formGroup]="companyForm">
    <div class="grid">
      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Company Name'"
        [placeholder]="'Company Name'"
        [formControlName]="'name'"
        [formControl]="name"
        [required]="true">
      </ice-input>

      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Subdomain'"
        [placeholder]="'Subdomain'"
        [formControlName]="'subdomain'"
        [formControl]="subdomain"
        [tooltip]="
          'Subdomain is the unique URL for your company. It will be used to access your CRM. Eg: https://iceberry/subdomain.com'
        "
        [required]="true"
        [applyLowercase]="true">
      </ice-input>

      <div class="col-12">
        <label class="block text-900 font-medium mb-2">
          Company Type <span class="text-red-600">*</span>
        </label>
        <div class="flex align-items-center mb-3">
          <ice-radio
            [isSubmitted]="submitted"
            [options]="radioButtonData"
            [formControlName]="'type'"
            [formControl]="type"
            [class]="'mr-2'"
            [required]="true"></ice-radio>
        </div>
      </div>

      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Email'"
        [placeholder]="'Email'"
        [formControlName]="'email'"
        [formControl]="email">
      </ice-input>

      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Website'"
        [placeholder]="'Eg : http://iceberry.com'"
        [formControlName]="'website'"
        [formControl]="website">
      </ice-input>
    </div>
  </form>
  <ice-button
    class="align-self-end"
    label="Next"
    i18n-label
    [text]="true"
    icon="pi pi-arrow-right"
    i18n-icon
    iconPos="right"
    i18n-iconPos
    (onClick)="onNext()"></ice-button>
</div>
