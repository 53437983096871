import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CrmState } from '~/app/states/main/crm/crm.state';
import * as AiClientActions from '~/app/states/main/crm/ai-search/states/client/client.actions';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Service for managing client-related operations such as loading requests, updating progress,
 * loading candidates, and handling job matching tasks.
 * This service is provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  /**
   * Creates an instance of ClientService.
   *
   * @param {Store<CrmState>} store - The store to manage state.
   */
  constructor(private store: Store<CrmState>) {}

  /**
   * Loads a client job matching request with the provided form data.
   *
   * @param {FormData} formData - The form data containing job matching request information.
   * @returns {void}
   */
  loadRequest(formData: FormData): void {
    this.updateProgress({ progress: 0, content: 'Connecting' });
    this.store.dispatch(
      AiClientActions.loadAiClientJobMatchingRequest({ formData })
    );
  }

  /**
   * Updates the progress of a client job matching request.
   *
   * @param {Progress | null} progress - The progress information to update.
   * @returns {void}
   */
  updateProgress(progress: Progress | null): void {
    this.store.dispatch(AiClientActions.updateAdvancement({ progress }));
  }

  /**
   * Loads job matching candidates based on the provided URL.
   *
   * @param {string} url - The URL to fetch job matching candidates from.
   * @returns {void}
   */
  loadCandidates(url: string): void {
    this.store.dispatch(
      AiClientActions.loadAiClientJobMatchingCandidates({ url })
    );
  }

  /**
   * Resets the list of job matching candidates.
   * @returns {void}
   */
  resetCandidates(): void {
    this.store.dispatch(AiClientActions.resetAiClientJobMatchingCandidates());
  }

  /**
   * Loads the job matching tasks with optional limit and page parameters.
   *
   * @param {number} [limit=10] - The number of tasks items to fetch per page.
   * @param {number} [page=0] - The page number to fetch.
   * @returns {void}
   */
  loadHistory(limit = 10, page = 0): void {
    this.store.dispatch(
      AiClientActions.loadAiConsultingJobMatchingHistory({
        limit: limit,
        page: page,
      })
    );
  }
}
