import { Component, Input } from '@angular/core';

/**
 * IceBackdropComponent is a component that displays a backdrop with optional loading indicator and configurable color.
 *
 * @selector 'app-ice-backdrop'
 * @templateUrl './ice-backdrop.component.html'
 * @styleUrls ['./ice-backdrop.component.scss']
 */
@Component({
  selector: 'app-ice-backdrop',
  templateUrl: './ice-backdrop.component.html',
  styleUrl: './ice-backdrop.component.scss',
})
export class IceBackdropComponent {
  /**
   * Indicates whether the loading indicator should be shown.
   * @type {boolean}
   * @required
   */
  @Input({ required: true }) loading = false;

  /**
   * Specifies the color theme of the backdrop.
   * @type {'light' | 'dark'}
   * @default 'dark'
   */
  @Input() color: 'light' | 'dark' = 'dark';
}
