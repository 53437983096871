/**
 * Extends the Array interface to include additional methods.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare interface Array<T> {
  /**
   * Interpolates new elements into the array at a specified position.
   *
   * This method inserts elements from the provided array into the current array
   * starting at the index defined by the product of limit and page. If the calculated
   * index exceeds the current length of the array, it appends the elements.
   *
   * @param {T[]} array - The array of elements to be added into the current array.
   * @param {number} limit - The number of elements per page.
   * @param {number} page - The current page number, used to calculate the starting index.
   * @return {T[]} A new array containing the original elements along with the interpolated ones.
   */
  interpolate(array: T[], limit: number, page: number): T[];
}

Array.prototype.interpolate = function <T>(
  this: T[],
  array: T[],
  limit: number,
  page: number
): T[] {
  if (limit * page > this.length) return [...this, ...array];

  const copy = [...this];

  for (let i = 0, l = array.length; i < l; i++) {
    copy[limit * page + i] = array[i];
  }
  return copy;
};
