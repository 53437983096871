import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SalesQuickNavState } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.state';
import * as SalesQuickNavActions from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.actions';

/**
 * Service that manages quick navigation for sales profiles, including loading profile UUIDs and navigating up and down profiles.
 *
 * @Injectable
 * @providedIn 'root' — Indicates that this service is provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class SalesQuickNavService {
  /**
   * Constructor for SalesQuickNavService.
   *
   * Initializes the service with necessary dependencies.
   *
   * @param {Store<SalesQuickNavState>} store — The NgRx store for sales quick navigation state management.
   */
  constructor(private store: Store<SalesQuickNavState>) {}

  /**
   * Loads the UUIDs of the profiles for quick navigation.
   *
   * @param {string[]} uuids — The list of profile UUIDs to be loaded.
   * @return {void}
   */
  loadProfilesUUIDs(uuids: string[]): void {
    this.store.dispatch(SalesQuickNavActions.loadSalesUUIDs({ uuids }));
  }

  /**
   * Navigates to the next profile in the quick navigation list.
   *
   * @param {string} currentUUID — The UUID of the current profile.
   * @return {void}
   */
  profileNavUp(currentUUID: string): void {
    this.store.dispatch(SalesQuickNavActions.salesQuickNavUp({ currentUUID }));
  }

  /**
   * Navigates to the previous profile in the quick navigation list.
   *
   * @param {string} currentUUID — The UUID of the current profile.
   * @return {void}
   */
  profileNavDown(currentUUID: string): void {
    this.store.dispatch(
      SalesQuickNavActions.salesQuickNavDown({ currentUUID })
    );
  }
}
