<p-tabView styleClass="tabview-custom m-0" i18n-styleClass>
  <p-tabPanel header="Auto" i18n-header>
    <ng-template pTemplate="Auto">
      <app-ai-create-profile></app-ai-create-profile>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel header="Manual" i18n-header>
    <ng-template pTemplate="manual">
      <app-cu-profile-manual></app-cu-profile-manual>
    </ng-template>
    <p></p>
  </p-tabPanel>
</p-tabView>
