import { createAction, props } from '@ngrx/store';
import {
  CreateSaleInformation,
  UpdateSaleInformation,
} from '~/app/states/main/sales/profile/types/sales-information.types';
import { SalesInformation } from '~/app/shared/interfaces/sales/profile/sales-information.interface';

export const loadSalesInformations = createAction(
  '[Sales Information] Load Sales Informations',
  props<{ saleId: string }>()
);
export const loadSalesInformationsSuccess = createAction(
  '[Sales Information] Load Sales Informations Success',
  props<{ salesInformations: SalesInformation[] }>()
);
export const loadSalesInformationsFailure = createAction(
  '[Sales Information] Load Sales Informations Failure',
  props<{ error: string }>()
);

export const createSalesInformation = createAction(
  '[Sales Information] Create Sales Information',
  props<{ salesInformation: CreateSaleInformation }>()
);
export const createSalesInformationSuccess = createAction(
  '[Sales Information] Create Sales Information Success',
  props<{ salesInformation: SalesInformation }>()
);
export const createSalesInformationFailure = createAction(
  '[Sales Information] Create Sales Information Failure',
  props<{ error: string }>()
);

export const updateSalesInformation = createAction(
  '[Sales Information] Update Sales Information',
  props<{ uuid: string; salesInformation: UpdateSaleInformation }>()
);
export const updateSalesInformationSuccess = createAction(
  '[Sales Information] Update Sales Information Success',
  props<{ salesInformation: SalesInformation }>()
);
export const updateSalesInformationFailure = createAction(
  '[Sales Information] Update Sales Information Failure',
  props<{ error: string }>()
);

export const deleteSalesInformation = createAction(
  '[Sales Information] Delete Sales Information',
  props<{ uuid: string; saleId: string }>()
);
export const deleteSalesInformationSuccess = createAction(
  '[Sales Information] Delete Sales Information Success',
  props<{ uuid: string }>()
);
export const deleteSalesInformationFailure = createAction(
  '[Sales Information] Delete Sales Information Failure',
  props<{ error: string }>()
);

export const salesInformationsUnauthorized = createAction(
  '[Sales Information] Unauthorized',
  props<{ error: string }>()
);
