import { createAction, props } from '@ngrx/store';
import { CreateSale, UpdateSale } from '~/app/main/sales/types/sales.types';
import { Sales } from '~/app/shared/interfaces/sales/sales.interface';

export const loadSale = createAction(
  '[Sales] Load Sale',
  props<{ uuid: string }>()
);
export const loadSaleSuccess = createAction(
  '[Sales] Load Sale Success',
  props<{ sales: Sales }>()
);
export const loadSaleFailure = createAction(
  '[Sales] Load Sale Failure',
  props<{ error: string }>()
);

export const updateSale = createAction(
  '[Sales] Update Sale',
  props<{ uuid: string; saleData: UpdateSale }>()
);
export const updateSaleSuccess = createAction(
  '[Sales] Update Sale Success',
  props<{ sale: Sales }>()
);
export const updateSaleFailure = createAction(
  '[Sales] Update Sale Failure',
  props<{ error: string }>()
);

export const createSale = createAction(
  '[Sales] Create Sale',
  props<{ saleData: CreateSale }>()
);
export const createSaleSuccess = createAction(
  '[Sales] Create Sale Success',
  props<{ sale: Sales }>()
);
export const createSaleFailure = createAction(
  '[Sales] Create Sale Failure',
  props<{ error: string }>()
);

export const salesUnauthorized = createAction(
  '[Sales] Unauthorized',
  props<{ error: string }>()
);
