import { Sales } from '~/app/shared/interfaces/sales/sales.interface';

/**
 * Represents the state of the sales module.
 *
 * @interface SalesState
 */
export interface SalesState {
  /**
   * The current sales record being viewed or edited.
   *
   * @type {Sales | null}
   */
  sales: Sales | null;

  /**
   * Indicates whether the sales data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesState} initialSalesState
 * @description Provides a baseline definition for the sales context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesState: SalesState = {
  sales: null,
  loading: false,
  error: null,
};
