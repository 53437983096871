import { createAction, props } from '@ngrx/store';
import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';

export const setQuickActions = createAction(
  '[Quick Actions] Set Quick Actions',
  props<{ quickActions: QuickActionItem[] }>()
);

export const clearQuickActions = createAction(
  '[Quick Actions] Clear Quick Actions'
);
