import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * Component for picking colors.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'ice-color-picker' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './ice-color-picker.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./ice-color-picker.component.scss'] – Array of paths to the stylesheets used for this component.
 * @providers Array of providers for dependency injection. Registers the component as a value accessor for form controls.
 */
@Component({
  selector: 'ice-color-picker',
  templateUrl: './ice-color-picker.component.html',
  styleUrls: ['./ice-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceColorPickerComponent),
      multi: true,
    },
  ],
})
export class IceColorPickerComponent implements AfterViewInit {
  /**
   * The name of the form control within the form group, used for linking to the Angular forms API.
   * @type {string}
   */
  @Input({ required: true }) formControlName!: string;

  /**
   * The FormControl instance linked to this color picker, used for validation and state management.
   * @type {FormControl}
   */
  @Input({ required: true }) formControl!: FormControl;

  /**
   * The format in which the color value should be output ('hex', 'rgb', or 'hsb').
   * @type {'hex' | 'rgb' | 'hsb'}
   */
  @Input() format: 'hex' | 'rgb' | 'hsb' = 'hex';

  /**
   * Descriptive label associated with the color picker input.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Whether or not the color picker is a required field within a form.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Controls whether the color value should be displayed to the user.
   * @type {boolean}
   */
  @Input() showValue = true;

  /**
   * The rule identifier used to check permissions, determining the component's UI state.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * The behavior to apply when the user does not have the necessary permissions.
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Whether the color picker is disabled.
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * The current value of the color picker.
   * @type {string | undefined}
   */
  value: string | undefined;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructs an instance of IceColorPickerComponent.
   * This constructor injects dependencies needed for the component's functionality and handles initial setup.
   *
   * @param {PermissionsService} permissionService - Service used to check user permissions for accessing or modifying the component.
   * @param {ElementRef} element - Provides direct access to the DOM element associated with this component, allowing direct manipulation.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Lifecycle hook that is called after Angular has fully initialized the component's view.
   * This method checks if the user has the necessary permissions to use the component based on the specified rule.
   * If the user lacks the required permissions, the method applies the specified unauthorized behavior
   * which may either disable the input or hide it entirely, depending on the settings.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.NONE:
                this.hideElement();
                break;
              case PermissionBehaviourEnum.DISABLE:
              default:
                this.disableElement();
            }
          }
        });
  }

  /**
   * Binds a new value to the color picker and updates the internal state.
   * @param {string} value - The new color value in the specified format.
   *
   * @return {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a function to be called when the color picker's value changes.
   * @param {function(string): void} fn - Callback function to register.
   *
   * @return {void}
   */
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the color picker is touched.
   * @param {function(): void} fn - Callback function to register.
   *
   * @return {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Hides the color picker from the UI when not authorized to use it.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the form control associated with the color picker when necessary permissions are lacking.
   * @return {void}
   */
  private disableElement(): void {
    this.formControl.disable();
  }
}
