<div class="section flex flex-auto">
  <app-sidenav
    i18n-aria-label="@@sidebarNavigationAriaLabel"
    aria-label="sidebar Navigation"
    *ngIf="breakpoint$ | async as breakpoint"
    [ngClass]="{
      fixed:
        (sidenavService.isExpanded && breakpoint === 'sm') ||
        (sidenavService.isExpanded && breakpoint === 'md'),
      sticky:
        !sidenavService.isExpanded ||
        breakpoint === 'lg' ||
        breakpoint === 'xl',
    }"
    class="z-5 max-h-full"
    style="margin-bottom: 3rem; margin-top: 1rem"></app-sidenav>
  <div class="content">
    <app-header
      i18n-aria-label="@@applicationHeaderAriaLabel"
      aria-label="Application header">
    </app-header>
    <main
      class="flex flex-column height p-4 pt-0 md:pt-4 relative"
      id="main-container"
      style="background-size: cover">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
