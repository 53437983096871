import { AiProfileCreationRequest } from '~/app/shared/interfaces/crm/ai/ai-profile-creation-request.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Describes the state of AI creation profiles within the application.
 *
 * @interface AiCreationProfileState
 */
export interface AiCreationProfileState {
  /**
   * The current AI profile creation request.
   *
   * @type {AiProfileCreationRequest | null}
   */
  request: AiProfileCreationRequest | null;

  /**
   * The list of candidate statuses.
   *
   * @type {Status[]}
   */
  statuses: Status[];

  /**
   * The list of offices.
   *
   * @type {Office[]}
   */
  offices: Office[];

  /**
   * The list of teams.
   *
   * @type {Team[]}
   */
  teams: Team[];

  /**
   * The current progress of the AI profile creation.
   *
   * @type {Progress | null}
   */
  progress: Progress | null;

  /**
   * Indicates whether the state is in the process of loading.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling AI profile creation actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Represents the initial state for AI creation profiles within the application.
 *
 * @type {AiCreationProfileState}
 */
export const initialAiCreationProfileState: AiCreationProfileState = {
  request: null,
  statuses: [],
  offices: [],
  teams: [],
  progress: null,
  loading: false,
  error: null,
};
