import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Injector,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectReminders } from '~/app/core/state/enum/enums.selectors';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';

/**
 * EnumLabelPipe transforms an enum value into its corresponding label based on the enum type.
 *
 * @pipe 'enumLabel'
 */
@Pipe({
  name: 'enumLabel',
})
export class EnumLabelPipe implements PipeTransform, OnDestroy {
  /**
   * Instance of AsyncPipe used to handle async operations within the pipe.
   * @type {AsyncPipe}
   */
  private asyncPipe: AsyncPipe;

  /**
   * Creates an instance of EnumLabelPipe.
   * @param {Store} store - The NGRX store instance.
   * @param {Injector} injector - The Angular injector instance.
   */
  constructor(
    private store: Store,
    private injector: Injector
  ) {
    this.asyncPipe = new AsyncPipe(this.injector.get(ChangeDetectorRef));
  }

  /**
   * Angular lifecycle hook that is called when the pipe is destroyed.
   * Cleans up the AsyncPipe instance.
   *
   * @return {void}
   */
  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  /**
   * Finds the label for a given value from a list of enums.
   * @param {LabelValueInterface[]} enums - The list of label-value pairs.
   * @param {string} value - The value to find the label for.
   * @returns {string} - The corresponding label or 'enum' if not found.
   */
  findLabel(enums: LabelValueInterface[], value: string): string {
    return (
      enums.find(labelValue => labelValue.value === value)?.label ?? 'enum'
    );
  }

  /**
   * Transforms an enum value into its corresponding label based on the enum type.
   * @param {string} value - The value to transform.
   * @param {'reminder'} type - The type of enum to use for transformation.
   * @returns {string} - The corresponding label or 'Not Found' if not found.
   */
  transform(value: string, type: 'reminder'): string {
    switch (type) {
      case 'reminder':
        return (
          this.asyncPipe.transform(
            this.store.select(selectReminders).pipe(
              take(1),
              map(enums => this.findLabel(enums, value))
            )
          ) ?? 'Not Found'
        );
    }
  }
}
