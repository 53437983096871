import {
  AfterContentInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { VoidFunction } from '~/app/shared/types/function/void-function.type';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { ToggleButtonItems } from '~/app/shared/interfaces/shared/ice-components/toggle-button-items';
import { fadeBg } from '~/app/shared/animations/fade-bg';

/**
 * `IceToggleButtonComponent` is an Angular component that provides a toggle button UI element
 * for selecting between two states (on/off). It integrates with Angular forms and permissions
 * management to control access and visibility based on defined permission rules.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-toggle-button' - The CSS selector that identifies this component.
 * @templateUrl './ice-toggle-button.component.html' - Path to the HTML template for this component.
 * @styleUrl './ice-toggle-button.component.scss' - Path to the styles for this component.
 * @providers NG_VALUE_ACCESSOR - Dependency injection token that allows the component to act as a form control.
 * @animations fadeBg - Animation for background fade effect.
 */
@Component({
  selector: 'ice-toggle-button',
  templateUrl: './ice-toggle-button.component.html',
  styleUrl: './ice-toggle-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceToggleButtonComponent),
      multi: true,
    },
  ],
  animations: [fadeBg],
})
export class IceToggleButtonComponent implements AfterContentInit {
  /**
   * An array of options for the toggle to display.
   *
   * @type {ToggleButtonItems}
   */
  @Input() options!: ToggleButtonItems;

  /**
   * Form control linked to the toggle for integration with Angular forms.
   *
   * @type {FormControl}
   */
  @Input() formControl: FormControl | undefined;

  /**
   * Specifies if the toggle button is a required field.
   *
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Represents the active state of the toggle button.
   * If `true`, the button is in its active state.
   *
   * @type {boolean | undefined}
   */
  @Input() active = true;

  /**
   * Permission rule identifier for checking accessibility to the toggle button.
   *
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Defines the behavior when the user does not have the required permission.
   * Possible values are defined in the `PermissionBehaviourEnum`.
   *
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Stores the current value of the toggle button (on/off state).
   *
   * @type {boolean}
   */
  value!: boolean;

  /**
   * Callback function for when the toggle value changes.
   * Initialized to an empty function, but overridden when `registerOnChange` is called.
   *
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback function for when the toggle button is touched (e.g., on focus).
   * Initialized to an empty function, but overridden when `registerOnTouched` is called.
   *
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructor initializes required services such as the `PermissionsService`
   * and sets up references to the DOM element.
   *
   * @param {PermissionsService} permissionService - Service for handling permission rules.
   * @param {ElementRef} element - The host element reference for this component.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Lifecycle hook that runs after Angular projects the content into the component.
   * It checks for the required permission rule and either disables or hides the toggle button
   * based on the permission and the `unauthorizedBehaviour` setting.
   *
   * @return {void}
   */
  ngAfterContentInit(): void {
    if (this.active) this.value = this.active;
    if (this.rule) {
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.DISABLE:
                this.disableElement();
                break;
              case PermissionBehaviourEnum.NONE:
              default:
                this.hideElement();
            }
          }
        });
    }
  }

  /**
   * Marks the form control as touched when the toggle button gains focus.
   *
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the toggle button.
   *
   * @param {boolean} value - The new value to write to the toggle button (true or false).
   *
   * @return {void}
   */
  writeValue(value: boolean): void {
    this.value = value;
  }

  /**
   * Registers a callback function to be called when the toggle value changes.
   *
   * @param {AnyVoidFunction} fn - The callback function to register.
   *
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function to be called when the toggle button is touched.
   *
   * @param {VoidFunction} fn - The callback function to register.
   *
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Handles the click event on the toggle button, toggling its value and updating the form control.
   *
   * @return {void}
   */
  clickEvent(): void {
    if (this.formControl) {
      this.formControl.setValue(!this.value);
    }
  }

  /**
   * Hides the toggle button element by applying a CSS style to make it invisible.
   *
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the form control associated with the toggle button.
   *
   * @return {void}
   */
  private disableElement(): void {
    this.formControl?.disable();
  }
}
