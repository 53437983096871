import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesSectorJobsActions from '~/app/states/main/sales/profile/states/sales-sectors-jobs/sales-sector-jobs.actions';
import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesSectorJobsEffects` manages the side effects for sales sector job-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales sector jobs, such as loading, assigning, and unassigning sector jobs.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesSectorJobs$`: Loads the sales sector jobs associated with a specific sales record.
 * — `unassignSalesSector$`: Unassigns a sales sector job from a specific sales record.
 * — `unassignSalesSectorSuccess$`: Handles the success of unassigning a sales sector job by displaying a success toast message.
 * — `assignSalesSectorJob$`: Assigns a sales sector job to a specific sales record.
 * — `assignSalesSectorJobSuccess$`: Handles the success of assigning a sales sector job by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesSectorJobsEffects {
  /**
   * Effect to load sales sector jobs associated with a specific sales record.
   * It listens for the `loadSalesSectors` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales sector jobs or a failure action if an error occurs.
   */
  loadSalesSectorJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesSectorJobsActions.loadSalesSectors),
      checkPermission(SalesSectorJobsActions.salesSectorJobsUnauthorized),
      mergeMap(({ salesId }) =>
        this.http
          .get<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/sales/sectorJobs/${salesId}`, { withCredentials: true })
          .pipe(
            take(1),
            map(salesSectors =>
              SalesSectorJobsActions.loadSalesSectorsSuccess({ salesSectors })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesSectorJobsActions.loadSalesSectorsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to unassign a sales sector job from a specific sales record.
   * It listens for the `unassignSalesSector` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful unassignment, it dispatches a success action with the updated sales sector jobs or a failure action if an error occurs.
   */
  unassignSalesSector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesSectorJobsActions.unassignSalesSector),
      checkPermission(SalesSectorJobsActions.salesSectorJobsUnauthorized),
      mergeMap(({ salesId, salesSector }) =>
        this.http
          .patch<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/sales/sectorJobs/${salesId}/unassignedSectorJob`, salesSector, { withCredentials: true })
          .pipe(
            take(1),
            map(salesSectors =>
              SalesSectorJobsActions.unassignSalesSectorSuccess({
                salesSectors,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesSectorJobsActions.unassignSalesSectorFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a sales sector job.
   * It displays a success toast message.
   */
  unassignSalesSectorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesSectorJobsActions.unassignSalesSectorSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales sector unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a sales sector job to a specific sales record.
   * It listens for the `assignSalesSectorJob` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful assignment, it dispatches a success action with the updated sales sector jobs or a failure action if an error occurs.
   */
  assignSalesSectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesSectorJobsActions.assignSalesSectorJob),
      checkPermission(SalesSectorJobsActions.salesSectorJobsUnauthorized),
      mergeMap(({ salesId, salesSector }) =>
        this.http
          .patch<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/sales/sectorJobs/${salesId}/assignSectorJob`, salesSector, { withCredentials: true })
          .pipe(
            take(1),
            map(salesSectors =>
              SalesSectorJobsActions.assignSalesSectorJobSuccess({
                salesSectors,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesSectorJobsActions.assignSalesSectorJobFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a sales sector job.
   * It displays a success toast message.
   */
  assignSalesSectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesSectorJobsActions.assignSalesSectorJobSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales sector assigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesSectorJobsEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService — Injectable service for handling sidebar form operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
