import { createReducer, on } from '@ngrx/store';
import * as ProfileSkillActions from '~/app/states/main/crm/profile/states/profile-skills/profile-skills.actions';
import { initialProfileSkillsState } from '~/app/states/main/crm/profile/states/profile-skills/profile-skills.state';

export const profileSkillsReducer = createReducer(
  initialProfileSkillsState,

  on(ProfileSkillActions.loadProfileSkills, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileSkillActions.loadProfileSkillsSuccess, (state, { skills }) => ({
    ...state,
    skills,
    loading: false,
  })),
  on(ProfileSkillActions.loadProfileSkillsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileSkillActions.assignProfileSkill, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileSkillActions.assignProfileSkillSuccess, (state, { skills }) => ({
    ...state,
    skills,
    loading: false,
  })),
  on(ProfileSkillActions.assignProfileSkillFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileSkillActions.unassignProfileSkill, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileSkillActions.unassignProfileSkillSuccess, (state, { skills }) => ({
    ...state,
    skills,
    loading: false,
  })),
  on(ProfileSkillActions.unassignProfileSkillFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileSkillActions.profileSkillsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
