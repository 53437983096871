import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectToken } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';
import { environment } from '~/environments/environment';

/**
 * Intercepts HTTP requests to add a JWT authentication token to the headers for requests to the API.
 * This is used to ensure that the requests are authenticated when the token is present.
 *
 * @Injectable indicates that this class can be injected, though it doesn't specify 'providedIn'
 * meaning it needs to be explicitly provided in the module or component.
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   * Constructs a new instance of JwtInterceptor.
   * @param {Store<AppState>} store - The NgRx store used to select the current authentication token.
   */
  private currentUrl = '/';

  /**
   * Constructs a new instance of JwtInterceptor.
   * @param {Store<AppState>} store - The NgRx store used to select the current authentication token.
   * @param {Router} router - The Angular router service used to monitor navigation events.
   */
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(event => {
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  /**
   * Method that intercepts all HTTP requests and modifies them if they are directed towards the API URL.
   * It adds the Authorization header with the bearer token if the token exists.
   *
   * @param {HttpRequest<any>} request - The outgoing request object to handle.
   * @param {HttpHandler} next - The next interceptor in the chain, or the final handler that will send the request.
   * @returns {Observable<HttpEvent<any>>} An observable of the event stream for the request.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExemptUrl = /^\/browse-jobs(\b|\/|$)/.exec(this.currentUrl);
    if (isExemptUrl) {
      return next.handle(request);
    }
    return this.store.select(selectToken).pipe(
      take(1), // Take only one value from the observable (the current token)
      switchMap(token => {
        if (token && request.url.startsWith(environment.apiUrl)) {
          // Clone the request and add the bearer token
          const authReq = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
          return next.handle(authReq);
        }
        return next.handle(request);
      })
    );
  }
}
