import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { ISSEEventType } from '@npmicedev/icemodule/lib/enums/ISSEEventType';
import { KanbanEvent } from '~/app/shared/interfaces/kanbanEvent.interface';
import { KanbanColumnsService } from '~/app/states/main/kanban/services/kanban-columns.service';
import { selectKanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.selectors';
import { selectKanban } from '~/app/states/main/kanban/states/kanbans/kanbans.selectors';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import { KanbanCardsService } from '~/app/states/main/kanban/services/kanban-cards.service';
import { KanbansService } from '~/app/states/main/kanban/services/kanbans.service';
import { KanbanCardAttachmentsService } from '~/app/states/main/kanban/services/kanban-card-attachments.service';
import { KanbanCardCommentsService } from '~/app/states/main/kanban/services/kanban-card-comments.service';
import { KanbanCardTagsService } from '~/app/states/main/kanban/services/kanban-card-tags.service';
import { KanbanCardTasksService } from '~/app/states/main/kanban/services//kanban-card-tasks.service';
import { IsseService } from '~/app/shared/services/IsseService';

/**
 * Service responsible for listening to a channel on the SSE Server.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class IsseKanbanService {
  /**
   * The ID of the current kanban.
   * @type {string | null}
   */
  kanbanId: string | null = null;

  /**
   * Constructor for IsseService.
   * @param {Store<KanbanState>} store - Store to manage Kanban state.
   * @param {IsseService} isseService - Service to connect to the SSE Server.
   * @param {KanbansService} kanbanService - Service to manage kanbans.
   * @param {KanbanColumnsService} kanbanColumnsService - Service to manage kanban columns.
   * @param {KanbanCardsService} kanbanCardsService - Service for kanban cards.
   * @param {KanbanCardAttachmentsService} kanbanCardAttachmentsService - Service for kanban attachments.
   * @param {KanbanCardCommentsService} kanbanCardCommentsService - Service for kanban comments.
   * @param {KanbanCardTagsService} kanbanCardTagsService - Service for kanban tags.
   * @param {KanbanCardTasksService} kanbanCardTasksService - Service for kanban tasks.
   */
  constructor(
    private store: Store<KanbanState>,
    private isseService: IsseService,
    private kanbanService: KanbansService,
    private kanbanColumnsService: KanbanColumnsService,
    private kanbanCardsService: KanbanCardsService,
    private kanbanCardAttachmentsService: KanbanCardAttachmentsService,
    private kanbanCardCommentsService: KanbanCardCommentsService,
    private kanbanCardTagsService: KanbanCardTagsService,
    private kanbanCardTasksService: KanbanCardTasksService
  ) {
    this.store.select(selectKanban).subscribe(kanban => {
      if (kanban) {
        this.kanbanId = kanban.uuid;
      }
    });
  }

  /**
   * Creates an EventSourcePolyfill & connect it to the SSE Server.
   * @returns {void}
   */
  listenToChannel(): void {
    const eventSourcePolyfill = this.isseService.eventSource;

    /**
     * ************************************************************************
     * KANBAN USERS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanUserAssigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanService.assignUserServer(res);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanUserUnassigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanService.unassignUserServer(res);
      }
    );

    /**
     * ************************************************************************
     * KANBAN TAGS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanTagCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTagsService.createTagServer(res.kanbanTag);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanTagDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTagsService.deleteTagsServer(res.kanbanTagId);
      }
    );

    /**
     * ************************************************************************
     * KANBAN COLUMNS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanColumnCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanColumnsService.createKanbanColumnServer(res);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanColumnUpdated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanColumnsService.updateKanbanColumnServer(res);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanColumnDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.deleteColumnServer(res.kanbanColumnId);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanColumnReordered,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanColumnsService.reOrderColumnSequenceServer({
          columns: res.columns,
          kanbanId: res.kanbanId,
        });
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.createCardServer(res, res.kanbanColumn.uuid);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardUpdated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.updateCardServer(
          res,
          res.kanbanColumn.uuid,
          res.nbAttachments,
          res.nbComments
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.deleteCardServer(
          res.kanbanCardId,
          res.kanbanColumnId
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardReordered,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.reOrderCardSequenceServer(
          {
            kanbanColumnId: res.kanbanColumnId,
            cards: res.cards,
          },
          []
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardSwitch,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        console.log('switch card', res);

        this.kanbanCardsService.switchKanbanCardColumnServer(res.kanbanCardId, {
          sequence: res.sequence,
          oldKanbanColumnId: res.oldKanbanColumnId,
          newKanbanColumnId: res.newKanbanColumnId,
        });
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS USERS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardUserAssigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.assignUserServer(
          res.kanbanCardId,
          res.kanbanCardUsers,
          res.kanbanColumnId
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardUserUnassigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.unassignUserServer(res.kanbanCardId, {
          userId: res.usersId,
          kanbanColumnId: res.kanbanColumnId,
        });
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS TAGS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTagAssigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.assignTagServer(
          res.kanbanCardId,
          res.kanbanCardTags,
          res.kanbanColumnId
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTagUnassigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardsService.unassignTagServer(res.kanbanCardId, {
          tagId: res.tagsId,
          kanbanColumnId: res.kanbanColumnId,
        });
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS ATTACHMENTS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardAttachmentCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardAttachmentsService.addAttachmentServer(
          res.kanbanAttachment,
          res.kanbanCardId,
          res.kanbanColumnId
        );
      }
    );

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardAttachmentUpdated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardAttachmentsService.upadateAttachmentServer(res);
      }
    );

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardAttachmentDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardAttachmentsService.deleteAttachmentServer(
          res.kanbanAttachmentId,
          res.kanbanCardId,
          res.kanbanColumnId
        );
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS COMMENTS
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardCommentCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardCommentsService.createKanbanCommentServer(
          res.kanbanComment,
          res.kanbanCardId,
          res.kanbanColumnId
        );
      }
    );

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardCommentUpdated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardCommentsService.updateKanbanCommentServer(
          res.kanbanComment
        );
      }
    );

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardCommentDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardCommentsService.deleteKanbanCommentServer(
          res.kanbanCommentId,
          res.kanbanCardId,
          res.kanbanColumnId
        );
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS PROFILES
     * ************************************************************************
     **/
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardProfileAssigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;

        console.log(
          'kanban card profile assigned',
          JSON.parse(kanbanEvent.data).change
        );
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardProfileUnassigned,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;

        console.log(
          'kanban card profile Unassigned',
          JSON.parse(kanbanEvent.data).change
        );
      }
    );

    /**
     * ************************************************************************
     * KANBAN CARDS TASKS
     * ************************************************************************
     **/

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTaskCreated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTasksService.createTaskServer(res.kanbanTask);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTaskUpdated,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTasksService.updateTaskServer(res.kanbanTask);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTaskDeleted,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTasksService.deleteTaskServer(res.kanbanTaskId);
      }
    );

    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTaskChecked,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTasksService.checkTaskServer(res.kanbanTask);
      }
    );
    eventSourcePolyfill.addEventListener(
      ISSEEventType.KanbanCardTaskUnchecked,
      event => {
        const kanbanEvent = event as unknown as KanbanEvent;
        const res = JSON.parse(kanbanEvent.data).change;

        this.kanbanCardTasksService.uncheckTaskServer(res.kanbanTask);
      }
    );
  }

  /**
   * Deletes a column & load columns if needed.
   * @param {string} kanbanColumnId - The uuid of the column to delete.
   * @returns {void}
   */
  deleteColumnServer(kanbanColumnId: string): void {
    this.kanbanColumnsService.deleteKanbanColumnServer(kanbanColumnId);
    this.store
      .select(selectKanbanColumnsState)
      .pipe(take(1))
      .subscribe(states => {
        if (this.kanbanId && states.data.length <= states.limit) {
          this.kanbanColumnsService.loadAllKanbanColumns({
            kanbanId: this.kanbanId,
            limit: states.limit,
            page: states.page + 1,
          });
        }
      });
  }
}
