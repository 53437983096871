<p-sidebar
  [(visible)]="isVisible"
  position="right"
  i18n-position
  [fullScreen]="fullScreen"
  styleClass="sidebar-dimensions min-w-max bg-light-100"
  i18n-styleClass>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-3">
      <p class="ml-3 mt-0 mb-0 text-xl text-black-alpha-90 font-semibold">
        {{ title }}
      </p>
    </div>
  </ng-template>
  <div class="p-3 pb-0 h-full">
    <ng-template appSidebarHost></ng-template>
  </div>
  <ng-template pTemplate="footer"></ng-template>
</p-sidebar>
