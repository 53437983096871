import { Injectable, Type, ViewContainerRef } from '@angular/core';

/**
 * Service to manage the dialog's state, content, and visibility within the application.
 * It provides methods to dynamically load components into the dialog, manage titles,
 * and toggle visibility.
 *
 * @Injectable indicates that this service can be injected into other components and services.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /**
   * Reference to the ViewContainer where modal content will be attached dynamically.
   * @private
   */
  private viewContainerRef: ViewContainerRef | null = null;

  /**
   * Current visibility state of the modal.
   * @private
   */
  private isVisible = false;

  /**
   * Centered of the dialog.
   * @private
   */
  private centered!: boolean;

  /**
   * Returns the current visibility state of the dialog.
   * @returns {boolean} True if the dialog is visible, otherwise false.
   */
  get dialogVisible(): boolean {
    return this.isVisible;
  }

  /**
   * Returns the centered boolean of the dialog.
   * @returns {boolean} The centered variable.
   */
  get isCentered(): boolean {
    return this.centered;
  }

  /**
   * Sets the ViewContainerRef for modal content. This is the anchor point for inserting dynamic components.
   * @param {ViewContainerRef} ref - The ViewContainerRef to attach dynamic components to.
   * @return {void}
   */
  setViewContainerRef(ref: ViewContainerRef): void {
    this.viewContainerRef = ref;
  }

  /**
   * Sets the visibility of the modal and clears the title and subtitle if it is being hidden.
   * @param {boolean} visible - Specifies the visibility state of the modal.
   * @return {void}
   */
  setDialogVisibility(visible: boolean): void {
    this.isVisible = visible;
  }

  /**
   * Sets the centered boolean of the dialog.
   * @param {boolean} centered - The centered varialble to set for the dialog.
   * @return {void}
   */
  setCentered(centered: boolean): void {
    this.centered = centered;
  }

  /**
   * Toggles the visibility of the dialog.
   * @return {void}
   */
  toggleDialog(): void {
    this.isVisible = !this.isVisible;
  }

  /**
   * Dynamically loads a component into the dialog's ViewContainerRef with optional inputs, title, and subtitle.
   * @param {Type<any>} component - The component type to load.
   * @param {Object} [inputs] - Optional inputs to pass to the component.
   * @param {boolean} centered - Optional input to center the dialog
   * @return {void}
   */
  loadComponent(
    component: Type<any>,
    inputs?: { [key: string]: any },
    centered?: boolean
  ): void {
    if (centered) {
      this.setCentered(centered);
    }
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(component);

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          componentRef.instance[key] = inputs[key];
        }
      }
    }
  }
}
