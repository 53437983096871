import { Social } from '~/app/shared/interfaces/shared/social.interface';

/**
 * Represents the state of the experiences module.
 *
 * @interface SalesSocialsState
 */
export interface SalesSocialsState {
  /**
   * The list of social network records.
   *
   * @type {Social[]}
   */
  socials: Social[];

  /**
   * Indicates whether the experiences are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales experiences within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesSocialsState} initialSalesSocialsState
 * @description Provides a baseline definition for the sales experiences context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesSocialsState: SalesSocialsState = {
  socials: [],
  loading: false,
  error: null,
};
