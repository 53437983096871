import { createAction, props } from '@ngrx/store';
import { UsersState } from '~/app/states/main/settings/states/users/users.state';
import {
  InvitationUserInCompany,
  UpdateOtherUserById,
  UpdateUser,
} from '~/app/states/main/settings/types/users.types';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { LoadUsersByFilters } from '~/app/shared/interfaces/settings/access-control/load-users-by-filters.interface';

// Action types for loading access-control users
export const loadUsers = createAction(
  '[Users] Load Users',
  props<{ filters?: LoadUsersByFilters }>()
);
export const loadUsersSuccess = createAction(
  '[Users] Load Users Success',
  props<{ users: UsersState }>()
);
export const loadUsersFailure = createAction(
  '[Users] Load Users Failure',
  props<{ error: string }>()
);

// Action types for inviting a user
export const inviteUser = createAction(
  '[Users] Invite User',
  props<{ inviteData: InvitationUserInCompany }>()
);
export const inviteUserSuccess = createAction('[Users] Invite User Success');
export const inviteUserFailure = createAction(
  '[Users] Invite User Failure',
  props<{ error: string }>()
);

// Action types for updating a user
export const updateUser = createAction(
  '[Users] Update User',
  props<{ uuid: string; userData: UpdateOtherUserById }>()
);
export const updateUserSuccess = createAction(
  '[Users] Update User Success',
  props<{ user: User }>()
);
export const updateUserFailure = createAction(
  '[Users] Update User Failure',
  props<{ error: string }>()
);

// Action types for updating a user
export const updateCurrentUser = createAction(
  '[Users] Update Current User',
  props<{ userData: UpdateUser }>()
);
export const updateCurrentUserSuccess = createAction(
  '[Users] Update Current User Success',
  props<{ user: User }>()
);
export const updateCurrentUserFailure = createAction(
  '[Users] Update Current User Failure',
  props<{ error: string }>()
);

export const assignGroupToUser = createAction(
  '[Users] Assign Group To User',
  props<{ userId: string; groupId: string }>()
);
export const assignGroupToUserSuccess = createAction(
  '[Users] Assign Group To User Success',
  props<{ user: User }>()
);
export const assignGroupToUserFailure = createAction(
  '[Users] Assign Group To User Failure',
  props<{ error: string }>()
);

export const unassignGroupToUser = createAction(
  '[Users] Unassign Group To User',
  props<{ userId: string; groupId: string }>()
);
export const unassignGroupToUserSuccess = createAction(
  '[Users] Unassign Group To User Success',
  props<{ user: User }>()
);
export const unassignGroupToUserFailure = createAction(
  '[Users] Unassign Group To User Failure',
  props<{ error: string }>()
);

export const userUnauthorized = createAction(
  '[Users] Unauthorized',
  props<{ error: string }>()
);

// Action types for activation of a user
export const activateUser = createAction(
  '[Users] Activate User',
  props<{ uuid: string; activate: boolean }>()
);
export const activateUserSuccess = createAction(
  '[Users] Activate User Success',
  props<{ user: User }>()
);
export const activateUserFailure = createAction(
  '[Users] Activate User Failure',
  props<{ error: string }>()
);

// Action types for desactivation of a user
export const deactivateUser = createAction(
  '[Users] Deactivate User',
  props<{ uuid: string; activate: boolean }>()
);
export const deactivateUserSuccess = createAction(
  '[Users] Deactivate User Success',
  props<{ user: User }>()
);
export const deactivateUserFailure = createAction(
  '[Users] Deactivate User Failure',
  props<{ error: string }>()
);
