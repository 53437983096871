import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AllOfficesState } from '~/app/states/main/settings/states/offices/offices.state';
import { WsProfileService } from '~/app/states/main/crm/ai-creation/services/ws-profile.service';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import * as AiCreationProfileActions from '~/app/states/main/crm/ai-creation/states/profile/ai-creation-profile.actions';
import { AiProfileCreationRequest } from '~/app/shared/interfaces/crm/ai/ai-profile-creation-request.interface';
import { environment } from '~/environments/environment';

/**
 * `AiCreationProfileEffects` is responsible for managing side effects related to AI profile creation in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading and updating AI profile creation information from and to the server.
 *
 * Effects included in this class:
 * — `loadRequest$`: Fetches AI profile creation requests from the server.
 * — `loadRequestSuccess$`: Handles successful AI profile creation requests.
 * — `loadRequestFailure$`: Handles failure of AI profile creation requests.
 * — `updateAdvancement$`: Handles updating the progress of AI profile creation.
 * — `loadStatuses$`: Fetches candidate statuses from the server.
 * — `loadOffices$`: Fetches office data from the server.
 * — `loadTeams$`: Fetches team data from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AiCreationProfileEffects {
  /**
   * Effect to load AI profile creation requests. It listens for the loadAiCreationProfileRequest action,
   * checks permissions, and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiCreationProfileActions.loadAiCreationProfileRequest),
      checkPermission(AiCreationProfileActions.aiCreateProfileUnauthorized),
      mergeMap(({ formData }) =>
        this.http
          .post<AiProfileCreationRequest>(
            `${environment.apiUrl}/v1/ai/profiles/generate`,
            formData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(request =>
              AiCreationProfileActions.loadAiCreationProfileRequestSuccess({
                request,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiCreationProfileActions.loadAiCreationProfileRequestFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of loading AI profile creation requests. It listens for the loadAiCreationProfileRequestSuccess action
   * and initializes the WebSocket connection with the received request. This effect does not dispatch any actions.
   */
  loadRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiCreationProfileActions.loadAiCreationProfileRequestSuccess),
        tap(({ request }) => {
          this.wsService.initializeConnection(request);
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle failure of loading AI profile creation requests. It listens for the loadAiCreationProfileRequestFailure action
   * and dispatches the updateAdvancementSuccess action with null progress. This effect does not dispatch any actions.
   */
  loadRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiCreationProfileActions.loadAiCreationProfileRequestFailure),
        map(() =>
          AiCreationProfileActions.updateAdvancementSuccess({ progress: null })
        )
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle the update advancement action. It listens for the updateAdvancement action,
   * and dispatches the updateAdvancementSuccess action with the provided progress.
   */
  updateAdvancement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiCreationProfileActions.updateAdvancement),
      map(({ progress }) =>
        AiCreationProfileActions.updateAdvancementSuccess({ progress })
      ),
      catchError(error =>
        of(AiCreationProfileActions.updateAdvancementFailure({ error }))
      )
    )
  );

  /**
   * Effect to load candidate statuses. It listens for the loadStatuses action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiCreationProfileActions.loadStatuses),
      checkPermission(AiCreationProfileActions.aiCreateProfileUnauthorized),
      mergeMap(() =>
        this.http
          .get<Status[]>(`${environment.apiUrl}/v1/profiles/status`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(statuses =>
              AiCreationProfileActions.loadStatusesSuccess({ statuses })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiCreationProfileActions.loadStatusesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load offices. It listens for the loadOffices action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiCreationProfileActions.loadOffices),
      checkPermission(AiCreationProfileActions.aiCreateProfileUnauthorized),
      mergeMap(() =>
        this.http
          .get<AllOfficesState>(`${environment.apiUrl}/v1/offices`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offices =>
              AiCreationProfileActions.loadOfficesSuccess({
                offices: offices.data,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AiCreationProfileActions.loadOfficesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load teams. It listens for the loadTeams action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiCreationProfileActions.loadTeams),
      checkPermission(AiCreationProfileActions.aiCreateProfileUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData<Team>>(`${environment.apiUrl}/v1/teams`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(teams =>
              AiCreationProfileActions.loadTeamsSuccess({ teams: teams.data })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AiCreationProfileActions.loadTeamsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an instance of AiCreationProfileEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {WsProfileService} wsService - The WebSocket service to handle WebSocket connections.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    public sidebarService: SidenavFormService,
    private httpErrors: HttpErrorsService,
    private wsService: WsProfileService
  ) {}
}
