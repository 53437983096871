import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { allModuleItems } from '~/app/shared/const/all-module-items.const';
import { ActivatedModule } from '~/app/shared/interfaces/module/activated-module.interface';
import { ModuleItemSkeleton } from '~/app/shared/interfaces/module/module-item-skeleton.interface';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';
import { SidenavService } from '~/app/shared/services/sidenav.service';

/**
 * Service for managing module-related operations.
 *
 * @Injectable decorator marks this class as available to be provided and injected as a dependency throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  /**
   * Constructs an instance of the ModuleService.
   *
   * @param {Router} router - Injectable Angular Router for handling navigation and URL manipulation.
   * @param {SidenavService} sidenavService - Service for managing the behavior and state of the side navigation panel.
   */
  constructor(
    private router: Router,
    public sidenavService: SidenavService
  ) {}

  /**
   * Converts an array of activated modules into an array of ModuleItem objects.
   * Each ModuleItem includes properties for name, label, icon, and a command for navigation.
   *
   * @param {ActivatedModule[]} activatedModules - Array of activated modules to be converted.
   * @returns {ModuleItem[]} - Array of ModuleItem objects with navigation commands.
   */
  convertToModuleItem(activatedModules: ActivatedModule[]): ModuleItem[] {
    const modules = activatedModules.map(activatedModule => {
      const module = allModuleItems[activatedModule.name];
      if (module)
        return {
          name: module.name,
          label: module.label,
          icon: module.icon,
          command: (): void => {
            this.sidenavService.closeMobileSidenav();
            void this.router.navigate([module.route]);
          },
        };
      return null;
    });

    const homeSkeleton = allModuleItems['Home'] as ModuleItemSkeleton;

    const homeModule: ModuleItem = {
      name: homeSkeleton.name,
      label: homeSkeleton.label,
      icon: homeSkeleton.icon,
      command: (): void => {
        this.sidenavService.closeMobileSidenav();
        void this.router.navigate([homeSkeleton.route]);
      },
    };

    const jobHubSkeleton = allModuleItems['JobHub'] as ModuleItemSkeleton;

    const jobHubModule: ModuleItem = {
      name: jobHubSkeleton.name,
      label: jobHubSkeleton.label,
      icon: jobHubSkeleton.icon,
      command: (): void => {
        this.sidenavService.closeMobileSidenav();
        void this.router.navigate([jobHubSkeleton.route]);
      },
    };

    const settingsSkeleton = allModuleItems['Settings'] as ModuleItemSkeleton;

    const settingsModule: ModuleItem = {
      name: settingsSkeleton.name,
      label: settingsSkeleton.label,
      icon: settingsSkeleton.icon,
      command: (): void => {
        this.sidenavService.closeMobileSidenav();
        void this.router.navigate([settingsSkeleton.route]);
      },
    };

    const clientCompanySkeleton = allModuleItems[
      'ClientCompany'
    ] as ModuleItemSkeleton;
    const clientCompanyModule: ModuleItem = {
      ...clientCompanySkeleton,
      command: (): void => {
        this.sidenavService.closeMobileSidenav();
        void this.router.navigate([clientCompanySkeleton.route]);
      },
    };

    const shuffleListSkeleton = allModuleItems[
      'ShuffleList'
    ] as ModuleItemSkeleton;
    const shuffleListModule: ModuleItem = {
      ...shuffleListSkeleton,
      command: (): void => {
        this.sidenavService.closeMobileSidenav();
        void this.router.navigate([shuffleListSkeleton.route]);
      },
    };

    return [
      homeModule,
      jobHubModule,
      ...(modules.filter(module => module !== null) as ModuleItem[]),
      clientCompanyModule,
      shuffleListModule,
      settingsModule,
    ];
  }
}
