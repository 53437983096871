import { createAction, props } from '@ngrx/store';
import {
  CreateKanbanTask,
  UpdateKanbanTask,
} from '~/app/states/main/kanban/types/kanban-tasks.types';
import { KanbanTask } from '~/app/shared/interfaces/kanban/kanban-task/kanban-task.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllKanbanCardTasks = createAction(
  '[Kanban Card Tasks] Load All Kanban Card Tasks',
  props<{ kanbanCardId: string }>()
);
export const loadAllKanbanCardTasksSuccess = createAction(
  '[Kanban Card Tasks] Load All Kanban Card Tasks Success',
  props<{
    kanbanTasks: KanbanTask[];
  }>()
);
export const loadAllKanbanCardTasksFailure = createAction(
  '[Kanban Card Tasks] Load All Kanban Card Tasks Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createKanbanCardTask = createAction(
  '[Kanban Card Tasks] Create Kanban Card Task',
  props<{
    kanbanCardId: string;
    kanbanTaskData: CreateKanbanTask;
  }>()
);
export const createKanbanCardTaskSuccess = createAction(
  '[Kanban Card Tasks] Create Kanban Card Task Success',
  props<{
    kanbanTask: KanbanTask;
  }>()
);
export const createKanbanCardTaskFailure = createAction(
  '[Kanban Card Tasks] Create Kanban Card Task Failure',
  props<{ error: string }>()
);

export const updateKanbanCardTask = createAction(
  '[Kanban Card Tasks] Update Kanban Card Task',
  props<{
    uuid: string;
    kanbanTaskData: UpdateKanbanTask;
  }>()
);
export const updateKanbanCardTaskSuccess = createAction(
  '[Kanban Card Tasks] Update Kanban Card Task Success',
  props<{
    kanbanTask: KanbanTask;
  }>()
);
export const updateKanbanCardTaskFailure = createAction(
  '[Kanban Card Tasks] Update Kanban Card Task Failure',
  props<{ error: string }>()
);

export const deleteKanbanCardTask = createAction(
  '[Kanban Card Tasks] Delete Kanban Card Task',
  props<{ uuid: string; kanbanCardId: string }>()
);
export const deleteKanbanCardTaskSuccess = createAction(
  '[Kanban Card Tasks] Delete Kanban Card Task Success',
  props<{ uuid: string }>()
);
export const deleteKanbanCardTaskFailure = createAction(
  '[Kanban Card Tasks] Delete Kanban Card Task Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * UN/CHECKED
 * ************************************************************************
 **/
export const checkKanbanCardTask = createAction(
  '[Kanban Card Tasks] Check Kanban Card Task',
  props<{ uuid: string; kanbanCardId: string }>()
);
export const checkKanbanCardTaskFailure = createAction(
  '[Kanban Card Tasks] Check Kanban Card Task Failure',
  props<{ error: string }>()
);

export const uncheckKanbanCardTask = createAction(
  '[Kanban Card Tasks] Uncheck Kanban Card Task',
  props<{ uuid: string; kanbanCardId: string }>()
);
export const uncheckKanbanCardTaskFailure = createAction(
  '[Kanban Card Tasks] Uncheck Kanban Card Task Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * SSE SERVER
 * ************************************************************************
 **/
export const createKanbanCardTaskServer = createAction(
  '[Kanban Card Tasks] Create Kanban Card Task Server',
  props<{
    kanbanTask: KanbanTask;
  }>()
);
export const updateKanbanCardTaskServer = createAction(
  '[Kanban Card Tasks] Update Kanban Card Task Server',
  props<{
    kanbanTask: KanbanTask;
  }>()
);
export const deleteKanbanCardTaskServer = createAction(
  '[Kanban Card Tasks] Delete Kanban Card Task Server',
  props<{ uuid: string }>()
);
export const checkKanbanCardTaskServer = createAction(
  '[Kanban Card Tasks] Check Kanban Card Task Server',
  props<{ kanbanTask: KanbanTask }>()
);
export const uncheckKanbanCardTaskServer = createAction(
  '[Kanban Card Tasks] Uncheck Kanban Card Task Server',
  props<{ kanbanTask: KanbanTask }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const kanbanCardTasksUnauthorized = createAction(
  '[Kanban Card Tasks] Unauthorized',
  props<{ error: string }>()
);
