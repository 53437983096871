import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

/**
 * Represents the state of the sales industries module.
 *
 * @interface SalesIndustriesState
 */
export interface SalesIndustriesState {
  /**
   * The list of industries associated with the sales records.
   *
   * @type {Industry[]}
   */
  industries: Industry[];

  /**
   * Indicates whether the industries are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales industries within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesIndustriesState} initialSalesIndustriesState
 * @description Provides a baseline definition for the sales industries context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesIndustriesState: SalesIndustriesState = {
  industries: [],
  loading: false,
  error: null,
};
