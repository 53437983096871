import { ProfileReference } from '~/app/shared/interfaces/crm/profile/profile-reference.interface';

/**
 * Represents the state of profile references.
 *
 * @interface ProfileReferenceState
 */
export interface ProfileReferenceState {
  /**
   * An array of profile references.
   *
   * @type {ProfileReference[]}
   */
  profileReferences: ProfileReference[];

  /**
   * Indicates if the profile references are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile references.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile references within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileReferenceState} initialProfileReferencesState
 * @description Provides a baseline definition for the profile references context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileReferencesState: ProfileReferenceState = {
  profileReferences: [],
  loading: false,
  error: null,
};
