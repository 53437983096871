import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Language } from '~/app/shared/interfaces/shared/language.interface';
import * as LanguagesActions from '~/app/shared/states/languages/languages.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { registerAction } from '~/app/shared/operators/register-action';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `LanguagesEffects` manages the side effects for language-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to languages, such as loading available languages.
 * It performs operations that involve checking permissions and making HTTP requests to fetch language data.
 *
 * The effects in this class include:
 * - `loadAllLanguages$`: Fetches all available languages from the API, dispatching success or failure actions based on the result.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class LanguagesEffects {
  /**
   * Effect to load all languages. It listens for the `loadLanguages` action,
   * checks for permissions, and makes an HTTP request to fetch languages.
   * Upon successful fetch, it dispatches a success action with the fetched languages.
   */
  loadAllLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguagesActions.loadLanguages),
      registerAction(),
      checkPermission(LanguagesActions.languagesUnauthorized),
      mergeMap(() =>
        this.http
          .get<Language[]>(`${environment.apiUrl}/v1/profiles/languages/all`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(languages =>
              LanguagesActions.loadLanguagesSuccess({ languages })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LanguagesActions.loadLanguagesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `LanguagesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
