<!--
<ng-container *ngIf="progress | async as currentProgress">
  <div
    class="surface-card m-2 p-2 border-round flex justify-content-center align-items-center h-fit w-30rem">
    <article class="flex gap-3 w-full">
      <landing-company class="pi pi-cloud-upload text-primary-500 text-2xl"></landing-company>
      <div class="flex flex-column gap-3 w-full">
        <p i18n class="m-0 font-semibold text-base text-900">
          Analysing your file
        </p>
        <p class="m-0 text-base text-700">{{ currentProgress.content }}</p>
        <div class="flex flex-column gap-2">
          <p-progressBar
            [value]="currentProgress.progress * 100"
            [showValue]="false"
            [style]="{ height: '6px' }"></p-progressBar>
          <label i18n class="text-right text-xs text-700"
            >{{ (currentProgress.progress * 100).toFixed(2) }}%
            analysed...</label
          >
        </div>
      </div>
    </article>
  </div>
</ng-container>
-->
<ng-container *ngIf="true">
  <div
    class="surface-card m-2 p-2 border-round flex justify-content-center align-items-center h-fit w-30rem">
    <article class="flex gap-3 w-full">
      <i class="pi pi-cloud-upload text-primary-500 text-2xl"></i>
      <div class="flex flex-column gap-3 w-full">
        <p i18n class="m-0 font-semibold text-base text-900">
          Analysing your file
        </p>
        <p class="m-0 text-base text-700">currentProgress.content</p>
        <div class="flex flex-column gap-2">
          <p-progressBar
            [value]="40"
            [showValue]="false"
            [style]="{ height: '6px' }"></p-progressBar>
          <label i18n class="text-right text-xs text-700"
            >40% analysed...</label
          >
        </div>
      </div>
    </article>
  </div>
</ng-container>
