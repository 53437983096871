import { Component, HostListener } from '@angular/core';
import { Testimonial } from '~/app/shared/interfaces/landing/testimonial.interface';

/**
 * The `TestimonialsComponent` is responsible for displaying user testimonials
 * and managing layout adjustments based on screen size.
 *
 * The testimonials are split into multiple columns or rows, and the layout
 * is responsive, adapting to different screen widths.
 */
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent {
  /**
   * An array of testimonials organized into multiple lists (representing columns or rows).
   * Each list contains an array of `Testimonial` objects.
   *
   * @type {Testimonial[][]}
   */
  testimonials: Testimonial[][] = [
    [
      {
        userName: 'Marine P.',
        userQuality: 'Freelancer',
        photo: 'marine-perrin.webp',
        stars: 5,
        text: 'Finding freelance gigs used to be a full-time job in itself. With IceBerry’s AI recommendations, I finally get relevant project offers that match my skills.',
      },
      {
        userName: 'Thomas F.',
        userQuality: 'HR Manager',
        photo: 'thomas-ferdinand.webp',
        stars: 5,
        text: 'Our recruitment time was reduced by 40%. The best candidates are now at our fingertips.',
      },
      {
        userName: 'Juliette M.',
        userQuality: 'Senior Consultant',
        photo: 'juliette-maes.webp',
        stars: 5,
        text: 'The ability to manage both clients and candidates from a single platform has streamlined our operations significantly.',
      },
    ],
    [
      {
        userName: 'Mathias V.',
        userQuality: 'Developer',
        photo: 'mathias-vincent.webp',
        stars: 4,
        text: 'IceBerry delivers project offers that match what I’m looking for.',
      },
      {
        userName: 'Nathalie V.',
        userQuality: 'HR Expert',
        photo: 'nathalie-vought.webp',
        stars: 5,
        text: 'Managing a pool of hundreds of candidates and clients used to be a headache. With IceBerry, everything is centralized and easy to track.',
      },
      {
        userName: 'Isabelle T.',
        userQuality: 'COO',
        photo: 'isabelle-tanin.webp',
        stars: 4,
        text: 'We’ve never been more confident in our hiring decisions.',
      },
    ],
  ];

  /**
   * A boolean that tracks whether the screen width is smaller than 768px.
   * This is used to adjust the layout of the testimonials for small screens.
   *
   * @type {boolean}
   */
  isSmallScreen = false;

  /**
   * Constructor that initializes the component and checks the current screen size.
   * Updates the `isSmallScreen` property based on the screen width.
   */
  constructor() {
    this.checkScreenSize();
  }

  /**
   * This method checks the current screen size and sets the `isSmallScreen` property
   * to `true` if the screen width is less than 768px, and `false` otherwise.
   *
   * @HostListener listens to window resize events and triggers the method
   * whenever the window is resized.
   *
   * @returns {void}
   */
  @HostListener('window:resize', [])
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth < 768;
  }
}
