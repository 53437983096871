import { createAction, props } from '@ngrx/store';
import { HomeSalesRemindersState } from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.state';
import { HomeSalesRemindersFilters } from '~/app/shared/interfaces/sales/home/home-sales-reminders-filters.interface';

export const loadHomeSalesReminders = createAction(
  '[Home Sales Reminders] Load Home Sales Reminders',
  props<{ filters?: HomeSalesRemindersFilters }>()
);
export const loadHomeSalesRemindersSuccess = createAction(
  '[Home Sales Reminders] Load Home Sales Reminders Success',
  props<{
    salesReminders: HomeSalesRemindersState;
    filters?: HomeSalesRemindersFilters;
  }>()
);
export const loadHomeSalesRemindersFailure = createAction(
  '[Home Sales Reminders] Load Home Sales Reminders Failure',
  props<{ error: string }>()
);

export const checkSalesReminder = createAction(
  '[Home Sales Reminders] Check Sales Reminder',
  props<{ saleId: string; uuid: string }>()
);
export const checkSalesReminderSuccess = createAction(
  '[Home Sales Reminders] Check Sales Reminder Success',
  props<{ uuid: string }>()
);
export const checkSalesReminderFailure = createAction(
  '[Home Sales Reminders] Check Sales Reminder Failure',
  props<{ error: string }>()
);

export const uncheckSalesReminder = createAction(
  '[Home Sales Reminders] Uncheck Sales Reminder',
  props<{ saleId: string; uuid: string }>()
);
export const uncheckSalesReminderSuccess = createAction(
  '[Home Sales Reminders] Uncheck Sales Reminder Success',
  props<{ uuid: string }>()
);
export const uncheckSalesReminderFailure = createAction(
  '[Home Sales Reminders] Uncheck Sales Reminder Failure',
  props<{ error: string }>()
);

export const homeSalesRemindersUnauthorized = createAction(
  '[Home Sales Reminders] Unauthorized',
  props<{ error: string }>()
);
