import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileInterviewsActions from '~/app/states/main/crm/profile/states/profile-interviews/profile-interviews.actions';
import { ProfileInterview } from '~/app/shared/interfaces/crm/profile/profile-interview.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';
import { ProfileWeeklyInterview } from '~/app/shared/interfaces/crm/home/interview/profile-weekly-interview.interface';

/**
 * `ProfileInterviewsEffects` manages the side effects for profile interview-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile interviews, such as loading, creating, updating, and deleting profile interviews.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileInterviews$`: Loads profile interviews based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched interviews or a failure action if an error occurs.
 * — `loadWeeklyProfileInterviews$`: Loads profile interviews based on the actual week. It performs an HTTP GET request and dispatches a success action with the fetched interviews or a failure action if an error occurs.
 * — `createProfileInterview$`: Creates a new profile interview. It performs an HTTP POST request and dispatches a success action with the created interview or a failure action if an error occurs.
 * — `createProfileInterviewSuccess$`: Handles the success of creating a new profile interview by displaying a success toast message and closing the sidebar form.
 * — `updateProfileInterview$`: Updates an existing profile interview based on its UUID and profile interview data. It performs an HTTP PATCH request and dispatches a success action with the updated interview or a failure action if an error occurs.
 * — `updateProfileInterviewSuccess$`: Handles the success of updating a profile interview by displaying a success toast message and closing the sidebar form.
 * — `deleteProfileInterview$`: Deletes a profile interview based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileInterviewSuccess$`: Handles the success of deleting a profile interview by displaying a success toast message and closing the sidebar form.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileInterviewsEffects {
  /**
   * Effect to load profile interviews based on a given profile ID.
   * Listens for the `loadProfileInterviews` action, checks permissions, and performs an HTTP GET request to fetch the interviews.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileInterviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileInterviewsActions.loadProfileInterviews),
      checkPermission(ProfileInterviewsActions.profileInterviewsUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<ProfileInterview[]>(
            `${environment.apiUrl}/v1/profiles/interviews`,
            {
              params: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileInterviews =>
              ProfileInterviewsActions.loadProfileInterviewsSuccess({
                profileInterviews,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileInterviewsActions.loadProfileInterviewsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load weekly profile interviews.
   */
  loadWeeklyProfileInterviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileInterviewsActions.loadWeeklyProfileInterviews),
      checkPermission(ProfileInterviewsActions.profileInterviewsUnauthorized),
      mergeMap(() =>
        this.http
          .get<{
            [date: string]: ProfileInterview[];
          }>(`${environment.apiUrl}/v1/profiles/weeklyInterviews`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(response => {
              const weeklyProfileInterviews: ProfileWeeklyInterview = response;
              return ProfileInterviewsActions.loadWeeklyProfileInterviewsSuccess(
                { weeklyProfileInterviews }
              );
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileInterviewsActions.loadWeeklyProfileInterviewsFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new profile interview.
   * Listens for the `createProfileInterview` action, checks permissions, and performs an HTTP POST request to create the interview.
   * Dispatches a success or failure action based on the result.
   */
  createProfileInterview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileInterviewsActions.createProfileInterview),
      checkPermission(ProfileInterviewsActions.profileInterviewsUnauthorized),
      mergeMap(({ profileInterview }) =>
        this.http
          .post<ProfileInterview>(
            `${environment.apiUrl}/v1/profiles/interviews`,
            profileInterview,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileInterview =>
              ProfileInterviewsActions.createProfileInterviewSuccess({
                profileInterview,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileInterviewsActions.createProfileInterviewFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile interview.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createProfileInterviewSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileInterviewsActions.createProfileInterviewSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile interview created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing profile interview based on its UUID and profile interview data.
   * Listens for the `updateProfileInterview` action, checks permissions, and performs an HTTP PATCH request to update the interview.
   * Dispatches a success or failure action based on the result.
   */
  updateProfileInterview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileInterviewsActions.updateProfileInterview),
      checkPermission(ProfileInterviewsActions.profileInterviewsUnauthorized),
      mergeMap(({ uuid, profileInterview }) =>
        this.http
          .patch<ProfileInterview>(
            `${environment.apiUrl}/v1/profiles/interviews/${uuid}`,
            profileInterview,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileInterview =>
              ProfileInterviewsActions.updateProfileInterviewSuccess({
                profileInterview,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileInterviewsActions.updateProfileInterviewFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a profile interview.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateProfileInterviewSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileInterviewsActions.updateProfileInterviewSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile interview updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a profile interview based on its UUID and profile ID.
   * Listens for the `deleteProfileInterview` action, checks permissions, and performs an HTTP DELETE request to delete the interview.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileInterview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileInterviewsActions.deleteProfileInterview),
      checkPermission(ProfileInterviewsActions.profileInterviewsUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/profiles/interviews/${uuid}`,
            {
              body: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              ProfileInterviewsActions.deleteProfileInterviewSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileInterviewsActions.deleteProfileInterviewFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a profile interview.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  deleteProfileInterviewSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileInterviewsActions.deleteProfileInterviewSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile interview deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileInterviewsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
