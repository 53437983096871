import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';

import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { VoidFunction } from '~/app/shared/types/function/void-function.type';

/**
 * `IceRangeInputComponent` is an Angular component that provides a range input slider.
 * It integrates with Angular forms and supports validation and custom options.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-range-input' - The CSS selector that identifies this component.
 * @templateUrl './ice-range-input.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-range-input.component.scss'] - Path to the styles for this component.
 * @providers NG_VALUE_ACCESSOR - Dependency injection token that allows the component to act as a form control.
 */
@Component({
  selector: 'ice-range-input',
  templateUrl: './ice-range-input.component.html',
  styleUrls: ['./ice-range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceRangeInputComponent),
      multi: true,
    },
  ],
})
export class IceRangeInputComponent implements AfterViewInit {
  /**
   * ViewChild directive to access the Slider component instance.
   * @type {ElementRef<HTMLInputElement>}
   */
  @ViewChild('slider') slider: ElementRef<HTMLInputElement> | undefined;

  /**
   * ViewChild directive to access the fromTag component instance.
   * @type {ElementRef<HTMLInputElement>}
   */
  @ViewChild('fromTag') fromTag: ElementRef<HTMLDivElement> | undefined;

  /**
   * ViewChild directive to access the toTag component instance.
   * @type {ElementRef<HTMLInputElement>}
   */
  @ViewChild('toTag') toTag: ElementRef<HTMLDivElement> | undefined;

  /**
   * FormControl linked to the input, used for validation and managing form state.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * The name of the FormControl within a FormGroup, used for accessing and validating the control.
   * @type {string}
   */
  @Input() formControlName!: string;

  /**
   * Minimum value for the range input.
   * @type {number}
   */
  @Input({ required: true }) min = 0;

  /**
   * Maximum value for the range input.
   * @type {Observable<number>}
   */
  @Input({ required: true }) max = of(0);

  /**
   * Label for the range input.
   * @type {string | undefined}
   */
  @Input() label: string | undefined;

  /**
   * Unit for the range input value.
   * @type {string | undefined}
   */
  @Input() unit: string | undefined;

  /**
   * Current value of the input range.
   * @type {number | number[] | null}
   */
  value: number[] = [0, 2000];

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructor initializes the renderer.
   * @param {Renderer2} renderer - The renderer for DOM manipulations.
   */
  constructor(private renderer: Renderer2) {}

  /**
   * AfterViewInit lifecycle hook to handle the filling of the bar.
   * @return {void}
   */
  ngAfterViewInit(): void {
    this.max.subscribe(num => {
      if (num !== 0) {
        const valueMaximum = (this.min + num) / 2;
        const valueMinimum = (this.min + num) / 2;

        const extremumMin = this.formControl.value
          ? Math.min(this.formControl.value[0], this.formControl.value[1])
          : this.min;
        const extremumMax = this.formControl.value
          ? Math.max(this.formControl.value[0], this.formControl.value[1])
          : num;

        const loop = (valueMin: number, valueMax: number): void => {
          setTimeout(() => {
            if (valueMin > extremumMin) valueMin = valueMin - 100;
            else if (valueMin < extremumMin) valueMin = valueMin + 75;

            if (valueMax < extremumMax) valueMax = valueMax + 100;
            else if (valueMax > extremumMax) valueMax = valueMax - 75;

            const eventFrom = {
              target: { id: 'fromSlider', value: valueMin },
            } as unknown as Event;
            const eventTo = {
              target: { id: 'toSlider', value: valueMax },
            } as unknown as Event;

            if (valueMin > extremumMin || valueMax < extremumMax) {
              this.patchValue(eventFrom);
              this.patchValue(eventTo);
              loop(valueMin, valueMax);
            } else {
              this.patchValue({
                target: { id: 'fromSlider', value: extremumMin },
              } as unknown as Event);
              this.patchValue({
                target: { id: 'toSlider', value: extremumMax },
              } as unknown as Event);
              this.value = [extremumMin, extremumMax];
            }
          }, 1);
        };

        loop(valueMinimum, valueMaximum);
      }
    });
  }

  /**
   * Writes a new value to the input.
   * @param {number | number[]} value - The new value (number or array of numbers).
   * @returns {void}
   */
  writeValue(value: number[] | null): void {
    this.value = value ?? [0, 2000];

    this.onSliderChange();
  }

  /**
   * Registers a function to be called when the input value changes.
   * @param {void} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Function to change the value of the formcontrol when the value of one of the slider change
   *
   * @param {Event} event - The event to retrieve the right tag and the value
   * @return {void}
   */
  patchValue(event: Event): void {
    const { value, id } = event.target as HTMLInputElement;

    switch (id) {
      case 'fromSlider':
        this.formControl.patchValue([parseInt(value), this.value[1]]);
        break;
      default:
      case 'toSlider':
        this.formControl.patchValue([this.value[0], parseInt(value)]);
    }
  }

  /**
   * Update the positions of the tags and the color of the background color of the input
   * @return {void}
   */
  onSliderChange(): void {
    this.max.subscribe(num => {
      const distance = num - this.min;
      const from = this.value[0] - this.min;
      const to = this.value[1] - this.min;

      if (this.slider && this.fromTag && this.toTag) {
        this.slider.nativeElement.style['background'] = `linear-gradient(
      to right,
      var(--color-dark-10) 0%,
      var(--color-dark-10) ${(Math.min(from, to) / distance) * 100}%,
      var(--color-dark-100) ${(Math.min(from, to) / distance) * 100}%,
      var(--color-dark-100) ${(Math.max(from, to) / distance) * 100}%, 
      var(--color-dark-10) ${(Math.max(from, to) / distance) * 100}%, 
      var(--color-dark-10) 100%)`;

        this.fromTag.nativeElement.style['left'] =
          `calc(${(from / distance) * 96}% + 9px)`;

        this.toTag.nativeElement.style['right'] =
          `calc(96% - ${(to / distance) * 96}% + 5px)`;
      }
    });
  }
}
