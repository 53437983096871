import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  createShuffleListValidation,
  updateShuffleListByIdValidation,
} from '@npmicedev/icemodule/lib/validations/shuffleLists';
import { AllShuffleListState } from '~/app/main/shuffle-list/states/shuffle-list/shuffle-list.state';
import {
  CreateShuffleList,
  UpdateShuffleList,
} from '~/app/main/shuffle-list/types/shuffle-list.types';
import { AllShuffleListFilters } from '~/app/shared/interfaces/shuffle-list/all-shuffle-list-filters.interface';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import * as ShuffleListActions from '~/app/main/shuffle-list/states/shuffle-list/shuffle-list.actions';

/**
 * Service for managing shuffle list operations such as create, update, delete, assign, and unassign teams.
 *
 * This service handles the creation, updating, and deletion of shuffle lists.
 * It interacts with the store and dispatches actions to manage the state of shuffle lists.
 * Validation is performed before any operation is dispatched to the store.
 *
 * @export
 * @class ShuffleListService
 */
@Injectable({
  providedIn: 'root',
})
export class ShuffleListService {
  /**
   * Creates an instance of ShuffleListService.
   *
   * @param {Store<AllShuffleListState>} store - Injectable store for managing shuffle list state.
   * @param {CustomToastService} toast - Service for displaying toast notifications.
   * @memberof ShuffleListService
   */
  constructor(
    private store: Store<AllShuffleListState>,
    private toast: CustomToastService
  ) {}

  /**
   * Loads all shuffle lists with optional filters.
   *
   * @param {AllShuffleListFilters} [filters] - Optional filters for loading shuffle lists.
   * @returns {void}
   * @memberof ShuffleListService
   */
  loadAllShuffleList(filters?: AllShuffleListFilters): void {
    this.store.dispatch(ShuffleListActions.loadAllShuffleList({ filters }));
  }

  /**
   * Creates a new shuffle list.
   *
   * @param {CreateShuffleList} shuffleListData - The data for the new shuffle list.
   * @returns {void}
   * @memberof ShuffleListService
   */
  createShuffleList(shuffleListData: CreateShuffleList): void {
    const result = createShuffleListValidation.body.safeParse(shuffleListData);

    if (result.success) {
      this.store.dispatch(
        ShuffleListActions.createShuffleList({ shuffleListData })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Updates an existing shuffle list.
   *
   * @param {string} uuid - The UUID of the shuffle list to update.
   * @param {UpdateShuffleList} shuffleListData - The updated data for the shuffle list.
   * @returns {void}
   * @memberof ShuffleListService
   */
  updateShuffleList(uuid: string, shuffleListData: UpdateShuffleList): void {
    const result =
      updateShuffleListByIdValidation.body.safeParse(shuffleListData);

    if (result.success) {
      this.store.dispatch(
        ShuffleListActions.updateShuffleList({ uuid, shuffleListData })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Deletes a shuffle list.
   *
   * @param {string} uuid - The UUID of the shuffle list to delete.
   * @returns {void}
   * @memberof ShuffleListService
   */
  deleteShuffleList(uuid: string): void {
    this.store.dispatch(ShuffleListActions.deleteShuffleList({ uuid }));
  }

  /**
   * Assigns a team to a shuffle list.
   *
   * @param {string} uuid - The UUID of the shuffle list.
   * @param {string} teamId - The ID of the team to assign.
   * @returns {void}
   * @memberof ShuffleListService
   */
  assignTeam(uuid: string, teamId: string): void {
    this.store.dispatch(ShuffleListActions.assignTeam({ uuid, teamId }));
  }

  /**
   * Unassigns a team from a shuffle list.
   *
   * @param {string} uuid - The UUID of the shuffle list.
   * @param {string} teamId - The ID of the team to unassign.
   * @returns {void}
   * @memberof ShuffleListService
   */
  unassignTeam(uuid: string, teamId: string): void {
    this.store.dispatch(ShuffleListActions.unassignTeam({ uuid, teamId }));
  }

  /**
   * Get a shuffle list by its uuid.
   *
   * @param {string} uuid - The UUID of the shuffle list.
   * @returns {void}
   * @memberof ShuffleListService
   */
  getShuffleListById(uuid: string): void {
    this.store.dispatch(ShuffleListActions.getShuffleListById({ uuid }));
  }

  /**
   * Get a random team for a shuffle list.
   *
   * @param {string} uuid - The UUID of the shuffle list.
   * @returns {void}
   * @memberof ShuffleListService
   */
  getRandom(uuid: string): void {
    this.store.dispatch(ShuffleListActions.getRandom({ uuid }));
  }
}
