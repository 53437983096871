<p-toast key="action" i18n-key [preventOpenDuplicates]="false">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column gap-2 flex-grow-1">
      <h1 class="m-0 font-semibold text-base text-900">
        {{ message.summary }}
      </h1>
      <span class="text-base text-700">{{ message.detail }}</span>
      <div class="flex gap-3 align-self-end">
        <ice-button
          label="Let's see"
          i18n-label
          [text]="true"
          (onClick)="message.data.onAction()">
          <!-- Bind click to onAction -->
        </ice-button>
        <ice-button
          label="Not now"
          i18n-label
          [text]="true"
          class="text-500"
          (onClick)="message.data.onCancel()">
          <!-- Bind click to onCancel -->
        </ice-button>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast key="success" i18n-key [preventOpenDuplicates]="false">
  <ng-template let-message pTemplate="message">
    <p class="text-base m-0 color-secondary-100 w-full">{{ message.detail }}</p>
  </ng-template>
</p-toast>

<p-toast key="info" i18n-key [preventOpenDuplicates]="false">
  <ng-template let-message pTemplate="message">
    <p class="text-base m-0 color-fancy-sky w-full">{{ message.detail }}</p>
  </ng-template>
</p-toast>

<p-toast key="warning" i18n-key [preventOpenDuplicates]="false">
  <ng-template let-message pTemplate="message">
    <p class="text-base m-0 color-warning-100 w-full">{{ message.detail }}</p>
  </ng-template>
</p-toast>

<p-toast key="error" i18n-key [preventOpenDuplicates]="false">
  <ng-template let-message pTemplate="message">
    <p class="text-base m-0 color-danger-90 w-full">{{ message.detail }}</p>
  </ng-template>
</p-toast>

<p-toast key="zodError" i18n-key [preventOpenDuplicates]="true">
  <ng-template let-message pTemplate="message">
    <p class="text-base m-0 color-danger-90 w-full">{{ message.detail }}</p>
  </ng-template>
</p-toast>
