<div
  class="flex flex-column justify-content-center surface-section px-4 pt-4 pb-8 md:px-6 lg:px-8 h-screen relative z-1 bg-cover">
  <div
    style="
      background: radial-gradient(
        50% 109137.91% at 50% 50%,
        rgba(233, 30, 99, 0.1) 0%,
        rgba(254, 244, 247, 0) 100%
      );
    "
    class="text-center"></div>
  <h2 i18n class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
    Check Your Email 💌
  </h2>
  <p i18n class="text-500 text-xl mt-0 mb-6 text-center">
    A reset link has been sent if your email is registered with us. Please
    follow the instructions to set up a new password.
  </p>
  <div class="text-center">
    <ice-button
      routerLink="/"
      class="mr-2"
      [text]="true"
      label="Go Back"
      i18n-label
      icon="pi pi-arrow-left"
      i18n-icon></ice-button>
    <ice-button
      routerLink="/login"
      label="Go to login page"
      i18n-label
      icon="pi pi-sign-in"
      i18n-icon></ice-button>
  </div>
</div>
