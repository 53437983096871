import { Component } from '@angular/core';

/**
 * Component for creating and updating profiles.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-cu-profile' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './cu-profile.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./cu-profile.component.scss'] – Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-cu-profile',
  templateUrl: './cu-profile.component.html',
  styleUrls: ['./cu-profile.component.scss'],
})
export class CuProfileComponent {}
