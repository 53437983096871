import { createReducer, on } from '@ngrx/store';
import { initialClientCompanyNotesState } from '~/app/states/main/client-company/profile/states/client-company-notes/client-company-notes.state';
import * as ClientCOmpanyNotesActions from '~/app/states/main/client-company/profile/states/client-company-notes/client-company-notes.actions';

export const clientCompanyNotesReducer = createReducer(
  initialClientCompanyNotesState,

  on(ClientCOmpanyNotesActions.loadClientCompanyNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCOmpanyNotesActions.loadClientCompanyNotesSuccess,
    (state, { clientCompanyNotes }) => ({
      ...state,
      clientCompanyNotes,
      loading: false,
    })
  ),
  on(
    ClientCOmpanyNotesActions.loadClientCompanyNotesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCOmpanyNotesActions.createClientCompanyNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCOmpanyNotesActions.createClientCompanyNotesSuccess,
    (state, { clientCompanyNote }) => ({
      ...state,
      clientCompanyNotes: [clientCompanyNote, ...state.clientCompanyNotes],
      loading: false,
    })
  ),
  on(
    ClientCOmpanyNotesActions.createClientCompanyNotesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCOmpanyNotesActions.updateClientCompanyNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCOmpanyNotesActions.updateClientCompanyNotesSuccess,
    (state, { clientCompanyNote }) => ({
      ...state,
      clientCompanyNotes: state.clientCompanyNotes.map(item =>
        item.uuid === clientCompanyNote.uuid ? clientCompanyNote : item
      ),
      loading: false,
    })
  ),
  on(
    ClientCOmpanyNotesActions.updateClientCompanyNotesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCOmpanyNotesActions.deleteClientCompanyNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCOmpanyNotesActions.deleteClientCompanyNotesSuccess,
    (state, { uuid }) => ({
      ...state,
      clientCompanyNotes: state.clientCompanyNotes.filter(
        item => item.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    ClientCOmpanyNotesActions.deleteClientCompanyNotesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCOmpanyNotesActions.clientCompanyNotesUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
