import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanCardCommentsActions from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.actions';
import {
  CreateKanbanComment,
  UpdateKanbanComment,
} from '~/app/states/main/kanban/types/kanban-comments.types';
import { KanbanComment } from '~/app/shared/interfaces/kanban/kanban-comment.interface';

/**
 * Service responsible for managing Kanban card comments.
 * Provides methods to load, create, update, and delete comments for Kanban cards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanCardCommentsService {
  /**
   * Constructs a new KanbanCardCommentsService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/

  /**
   * Loads all comments for a specified Kanban card and column.
   * Dispatches a loadAllKanbanCardComments action with the card and column IDs.
   *
   * @param {string} kanbanCardId – The ID of the Kanban card.
   * @returns {void}
   */
  loadKanbanCardComments(kanbanCardId: string): void {
    this.store.dispatch(
      KanbanCardCommentsActions.loadAllKanbanCardComments({
        kanbanCardId,
      })
    );
  }

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/

  /**
   * Creates a new comment for a specified Kanban card and column.
   * Dispatches a createKanbanCardComment action with the column ID, comment data, and event emitter.
   *
   * @param {CreateKanbanComment} kanbanCommentData – The data for the new Kanban comment.
   * @param {string} kanbanColumnId - The uuid of the column.
   * @param {void} eventEmitter – The event emitter function to be called after the comment is created.
   * @returns {void}
   */
  createKanbanComment(
    kanbanCommentData: CreateKanbanComment,
    kanbanColumnId: string,
    eventEmitter: () => void
  ): void {
    this.store.dispatch(
      KanbanCardCommentsActions.createKanbanCardComment({
        kanbanCommentData,
        kanbanColumnId,
        eventEmitter,
      })
    );
  }

  /**
   * Updates an existing comment for a specified Kanban card and column.
   * Dispatches an updateKanbanCardComment action with the column ID, comment data, and comment UUID.
   *
   * @param {UpdateKanbanComment} kanbanCommentData – The data for the updated Kanban comment.
   * @param {string} uuid – The UUID of the comment to be updated.
   * @returns {void}
   */
  updateKanbanComment(
    kanbanCommentData: UpdateKanbanComment,
    uuid: string
  ): void {
    this.store.dispatch(
      KanbanCardCommentsActions.updateKanbanCardComment({
        kanbanCommentData,
        uuid,
      })
    );
  }

  /**
   * Deletes a comment from a specified Kanban card and column.
   * Dispatches a deleteKanbanCardComment action with the comment UUID, card UUID, and column UUID.
   *
   * @param {string} uuid – The UUID of the comment to be deleted.
   * @param {string} kanbanCardId – The UUD of the Kanban card.
   * @param {string} kanbanColumnId - The UUID of the column where the card is.
   * @returns {void}
   */
  deleteKanbanComment(
    uuid: string,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardCommentsActions.deleteKanbanCardComment({
        uuid,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Creates a new comment for a specified Kanban card and column.
   * Dispatches a createKanbanCardCommentServer action with the comment, the card UUID and the column UUID.
   *
   * @param {KanbanComment} kanbanComment – The Kanban comment.
   * @param {string} kanbanCardId - The uuid of the card.
   * @param {string} kanbanColumnId - The uuid of the column.
   * @returns {void}
   */
  createKanbanCommentServer(
    kanbanComment: KanbanComment,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardCommentsActions.createKanbanCardCommentServer({
        kanbanComment,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }

  /**
   * Updates an existing comment for a specified Kanban card and column.
   * Dispatches an updateKanbanCardCommentServer action with the .
   *
   * @param {KanbanComment} kanbanComment – The Kanban Comment.
   * @returns {void}
   */
  updateKanbanCommentServer(kanbanComment: KanbanComment): void {
    this.store.dispatch(
      KanbanCardCommentsActions.updateKanbanCardCommentServer({
        kanbanComment,
      })
    );
  }

  /**
   * Deletes a comment from a specified Kanban card and column.
   * Dispatches a deleteKanbanCardComment action with the comment UUID, card UUID, and column UUID.
   *
   * @param {string} uuid – The UUID of the comment to be deleted.
   * @param {string} kanbanCardId – The UUD of the Kanban card.
   * @param {string} kanbanColumnId - The UUID of the column where the card is.
   * @returns {void}
   */
  deleteKanbanCommentServer(
    uuid: string,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardCommentsActions.deleteKanbanCardCommentServer({
        uuid,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }
}
