import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getAllModuleItems } from '~/app/shared/const/all-module-items.const';
import { ActivatedModule } from '~/app/shared/interfaces/module/activated-module.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { ModuleService } from '~/app/shared/services/module.service';
import { QuickActionService } from '~/app/shared/services/quick-action.service';
import * as NavigationActions from '~/app/shared/states/navigation/navigation.action';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { environment } from '~/environments/environment';

/**
 * `NavigationEffects` manages the side effects for navigation-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to navigation, such as loading available modules and changing the current module.
 * It performs operations that involve checking permissions, interacting with Angular's Router for navigation, and potentially making HTTP requests to fetch or update navigation data.
 *
 * The effects in this class include:
 * - `loadAllAvailableModules$`: Fetches all available navigation modules. It simulates an API call from a local function, dispatching a success action with the modules.
 * - `changeCurrentModule$`: Changes the current navigation module based on user action, checking permissions and dispatching a success action with the new module.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class NavigationEffects {
  /**
   * Effect to load all available modules. It listens for the `loadAllAvailableModules` action,
   * checks for permissions, and simulates an API call by fetching module items directly from a local function.
   * Upon successful fetch, it dispatches a success action with the fetched modules.
   */
  loadAllAvailableModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadAllAvailableModules),
      checkPermission(NavigationActions.navigationUnauthorized),
      mergeMap(() => {
        // TODO: Retrieve Modules from API
        return of(
          NavigationActions.loadAllAvailableModulesSuccess({
            availableModules: getAllModuleItems(this.router),
          })
        );
      })
    )
  );

  /**
   * Effect to load all activated modules. It listens for the `loadAllActivatedModules` action,
   * checks for permissions, and simulates an API call by fetching module items directly from a local function.
   * Upon successful fetch, it dispatches a success action with the fetched modules.
   */
  loadAllActivatedModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadAllActivatedModules),
      checkPermission(NavigationActions.navigationUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData<ActivatedModule>>(
            `${environment.apiUrl}/v1/companyModules`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            map(({ data: activatedModules }) =>
              NavigationActions.loadAllActivatedModulesSuccess({
                activatedModules:
                  this.moduleService.convertToModuleItem(activatedModules),
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                NavigationActions.loadAllActivatedModulesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to change the current module based on user action. It listens for the `changeCurrentModule` action,
   * checks for permissions, and upon successful permission check, dispatches a success action with the new module.
   */
  changeCurrentModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.changeCurrentModule),
      checkPermission(NavigationActions.navigationUnauthorized),
      mergeMap(({ module }) =>
        of(
          NavigationActions.changeCurrentModuleSuccess({
            module,
          })
        )
      )
    )
  );

  /**
   * Effect to handle side effects after successfully changing the current module.
   * It listens for the `changeCurrentModuleSuccess` action and sets quick actions based on the new module.
   */
  changeCurrentModuleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.changeCurrentModuleSuccess),
        checkPermission(NavigationActions.navigationUnauthorized),
        tap(({ module }) => {
          this.quickActions.setQuickActions(module);
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `NavigationEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {Router} router Injectable Angular Router for handling navigation and URL manipulation.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {ModuleService} moduleService Injectable service for handling module actions.
   * @param {QuickActionService} quickActions Injectable srevice for handling quick actions.
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private moduleService: ModuleService,
    private quickActions: QuickActionService
  ) {}
}
