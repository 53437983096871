import { createAction, props } from '@ngrx/store';
import {
  CreateNoteCategory,
  UpdateNoteCategoryById,
} from '~/app/states/main/settings/types/notes.types';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';

export const loadNoteCategories = createAction(
  '[Note Categories] Load Note Categories'
);
export const loadNoteCategoriesSuccess = createAction(
  '[Note Categories] Load Note Categories Success',
  props<{ noteCategories: NoteCategory[] }>()
);
export const loadNoteCategoriesFailure = createAction(
  '[Note Categories] Load Note Categories Failure',
  props<{ error: string }>()
);

export const createNoteCategory = createAction(
  '[Note Categories] Create Note Category',
  props<{ noteCategoryData: CreateNoteCategory }>()
);
export const createNoteCategorySuccess = createAction(
  '[Note Categories] Create Note Category Success',
  props<{ noteCategory: NoteCategory }>()
);
export const createNoteCategoryFailure = createAction(
  '[Note Categories] Create Note Category Failure',
  props<{ error: string }>()
);

export const updateNoteCategory = createAction(
  '[Note Categories] Update Note Category',
  props<{ uuid: string; noteCategoryData: UpdateNoteCategoryById }>()
);
export const updateNoteCategorySuccess = createAction(
  '[Note Categories] Update Note Category Success',
  props<{ noteCategory: NoteCategory }>()
);
export const updateNoteCategoryFailure = createAction(
  '[Note Categories] Update Note Category Failure',
  props<{ error: string }>()
);

export const deleteNoteCategory = createAction(
  '[Note Categories] Delete Note Category',
  props<{ uuid: string }>()
);
export const deleteNoteCategorySuccess = createAction(
  '[Note Categories] Delete Note Category Success',
  props<{ uuid: string }>()
);
export const deleteNoteCategoryFailure = createAction(
  '[Note Categories] Delete Note Category Failure',
  props<{ error: string }>()
);

export const noteCategoryUnauthorized = createAction(
  '[Note Categories] Unauthorized',
  props<{ error: string }>()
);
