import { createAction, props } from '@ngrx/store';
import {
  CreateKanbanComment,
  UpdateKanbanComment,
} from '~/app/states/main/kanban/types/kanban-comments.types';
import { KanbanComment } from '~/app/shared/interfaces/kanban/kanban-comment.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllKanbanCardComments = createAction(
  '[Kanban Card Comments] Load All Card Comments',
  props<{ kanbanCardId: string }>()
);
export const loadAllKanbanCardCommentsSuccess = createAction(
  '[Kanban Card Comments] Load All Card Comments Success',
  props<{
    kanbanComments: KanbanComment[];
  }>()
);
export const loadAllKanbanCardCommentsFailure = createAction(
  '[Kanban Card Comments] Load All Card Comments Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createKanbanCardComment = createAction(
  '[Kanban Card Comments] Create Card Comment',
  props<{
    kanbanCommentData: CreateKanbanComment;
    kanbanColumnId: string;
    eventEmitter: () => void;
  }>()
);
export const createKanbanCardCommentSuccess = createAction(
  '[Kanban Card Comments] Create Card Comment Success',
  props<{
    kanbanComment: KanbanComment;
    kanbanCardId: string;
    kanbanColumnId: string;
    eventEmitter: () => void;
  }>()
);
export const createKanbanCardCommentFailure = createAction(
  '[Kanban Card Comments] Create Card Comment Failure',
  props<{ error: string }>()
);

export const updateKanbanCardComment = createAction(
  '[Kanban Card Comments] Update Card Comment',
  props<{
    uuid: string;
    kanbanCommentData: UpdateKanbanComment;
  }>()
);
export const updateKanbanCardCommentSuccess = createAction(
  '[Kanban Card Comments] Update Card Comment Success',
  props<{
    kanbanComment: KanbanComment;
  }>()
);
export const updateKanbanCardCommentFailure = createAction(
  '[Kanban Card Comments] Update Card Comment Failure',
  props<{ error: string }>()
);

export const deleteKanbanCardComment = createAction(
  '[Kanban Card Comments] Delete Card Comment',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardCommentSuccess = createAction(
  '[Kanban Card Comments] Delete Card Comment Success',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardCommentFailure = createAction(
  '[Kanban Card Comments] Delete Card Comment Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * SSE SERVER
 * ************************************************************************
 **/
export const createKanbanCardCommentServer = createAction(
  '[Kanban Card Comments] Create Card Comment Server',
  props<{
    kanbanComment: KanbanComment;
    kanbanCardId: string;
    kanbanColumnId: string;
  }>()
);

export const updateKanbanCardCommentServer = createAction(
  '[Kanban Card Comments] Update Card Comment Server',
  props<{
    kanbanComment: KanbanComment;
  }>()
);

export const deleteKanbanCardCommentServer = createAction(
  '[Kanban Card Comments] Delete Card Comment Server',
  props<{ uuid: string; kanbanCardId: string; kanbanColumnId: string }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const kanbanCommentUnauthorized = createAction(
  '[Kanban Card Comments] Unauthorized',
  props<{ error: string }>()
);
