<p-button
  #iceButton
  *ngIf="iceIf"
  [class]="class"
  [style]="style"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [disabled]="disabled"
  [iconPos]="iconPos"
  [rounded]="rounded"
  [text]="text"
  [routerLink]="routerLink"
  [severity]="severity"
  [plain]="plain"
  [outlined]="outlined"
  [ariaLabel]="ariaLabel"
  (onClick)="onClick.emit($event)"
  [styleClass]="fullWidth ? 'w-full flex justify-content-center md:w-auto' : ''"
  [loading]="loading">
  <ng-content></ng-content>
</p-button>
