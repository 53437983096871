import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

/**
 * Represents the state for sales tags in the CRM system.
 *
 * @interface SalesTagsState
 */
export interface SalesTagsState {
  /**
   * The list of sales tags.
   *
   * @type {Tag[]}
   */
  salesTags: Tag[];

  /**
   * Indicates if the sales tags are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from loading the sales tags.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales tags.
 *
 * @constant {SalesTagsState} initialSalesTagsState
 * @description Provides a baseline definition for the sales tags context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesTagsState: SalesTagsState = {
  salesTags: [],
  loading: false,
  error: null,
};
