import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisableTwoFactorAuthComponent } from '~/app/auth/components/disable-two-factor-auth/disable-two-factor-auth.component';
import { LoginComponent } from '~/app/auth/components/login/login.component';
// import { CompanyStepComponent } from '~/app/auth/components/register/company-step/company-step.component';
// import { OfficeStepComponent } from '~/app/auth/components/register/office-step/office-step.component';
import { RegisterRedirectionComponent } from '~/app/auth/components/register/register-redirection/register-redirection.component';
// import { RegisterStepperComponent } from '~/app/auth/components/register/register-stepper/register-stepper.component';
// import { RootUserStepComponent } from '~/app/auth/components/register/root-user-step/root-user-step.component';
import { RequestNewPasswordComponent } from '~/app/auth/components/request-new-password/request-new-password.component';
import { ResetPasswordComponent } from '~/app/auth/components/reset-password/reset-password.component';
import { SecureLoginComponent } from '~/app/auth/components/secure-login/secure-login.component';
import { UserRegisterComponent } from '~/app/auth/components/user-register/user-register.component';
import { secureLoginGuard } from '~/app/auth/guards/auth.guard';
import { PrimengModule } from '~/app/primeng/primeng.module';
import { loginSuccessGuard } from '~/app/auth/guards/login-success.guard';
import { RedirectComponent } from '~/app/_common-pages/redirect/redirect.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'login-secure',
    component: SecureLoginComponent,
    canActivate: [secureLoginGuard],
  },
  // {
  //   path: 'register',
  //   component: RegisterStepperComponent,
  //   children: [
  //     { path: '', redirectTo: 'company', pathMatch: 'full' },
  //     {
  //       path: 'company',
  //       component: CompanyStepComponent,
  //       data: { animation: 'CompanyPage' },
  //     },
  //     {
  //       path: 'office',
  //       component: OfficeStepComponent,
  //       data: { animation: 'OfficePage' },
  //     },
  //     {
  //       path: 'root-user',
  //       component: RootUserStepComponent,
  //     },
  //   ],
  // },
  { path: 'confirmation/:token', component: RegisterRedirectionComponent },
  { path: 'forgot-password', component: RequestNewPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'forgot-totp', component: DisableTwoFactorAuthComponent },
  { path: 'invitations/:token', component: UserRegisterComponent },
  {
    path: 'login-success',
    canActivate: [loginSuccessGuard],
    component: RedirectComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PrimengModule],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
