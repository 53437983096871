import { createAction, props } from '@ngrx/store';
import { TimelinesState } from '~/app/states/main/sales/states/timelines/timelines.state';
import {
  CreateTimelineTasks,
  DeleteTimelineTasks,
  UpdateTimeline,
  UpdateTimelineTasks,
} from '~/app/states/main/sales/types/timelines.types';
import { TimelineTask } from '~/app/shared/interfaces/sales/timelines/timeline-task.interface';
import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';
import { TimelinesFilters } from '~/app/shared/interfaces/sales/timelines/timelines-filters.interface';

export const loadTimelines = createAction(
  '[Timelines] Load Timelines',
  props<{ filters?: TimelinesFilters }>()
);
export const loadTimelinesSuccess = createAction(
  '[Timelines] Load Timelines Success',
  props<{ timelines: TimelinesState }>()
);
export const loadTimelinesFailure = createAction(
  '[Timelines] Load Timelines Failure',
  props<{ error: string }>()
);

export const loadSelectedTimelines = createAction(
  '[Timelines] Load Selected Timelines',
  props<{ uuid: string }>()
);
export const loadSelectedTimelinesSuccess = createAction(
  '[Timelines] Load Selected Timelines Success',
  props<{ selectedTimeline: Timeline }>()
);
export const loadSelectedTimelinesFailure = createAction(
  '[Timelines] Load Selected Timelines Failure',
  props<{ error: string }>()
);

export const createTimeline = createAction(
  '[Timelines] Create Timelines',
  props<{ name: string; description: string }>()
);
export const createTimelineSuccess = createAction(
  '[Timelines] Create Timelines Success',
  props<{ timeline: Timeline }>()
);
export const createTimelineFailure = createAction(
  '[Timelines] Create Timelines Failure',
  props<{ error: string }>()
);

export const deleteTimeline = createAction(
  '[Timelines] Delete Timelines',
  props<{ uuid: string }>()
);
export const deleteTimelineSuccess = createAction(
  '[Timelines] Delete Timelines Success',
  props<{ uuid: string }>()
);
export const deleteTimelineFailure = createAction(
  '[Timelines] Delete Timelines Failure',
  props<{ error: string }>()
);

export const updateTimeline = createAction(
  '[Timelines] Update Timelines',
  props<{
    uuid: string;
    updateTimeline: UpdateTimeline;
    createTasks: CreateTimelineTasks;
    updateTasks: UpdateTimelineTasks;
    deleteTasks: DeleteTimelineTasks;
  }>()
);
export const updateTimelineSuccess = createAction(
  '[Timelines] Update Timelines Success',
  props<{ timeline: Timeline; tasks: TimelineTask[] }>()
);
export const updateTimelineFailure = createAction(
  '[Timelines] Update Timelines Failure',
  props<{ error: string }>()
);

export const timelinesUnauthorized = createAction(
  '[Timelines] Unauthorized',
  props<{ error: string }>()
);
