import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as UserActions from '~/app/states/main/kanban/states/users/users.actions';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { environment } from '~/environments/environment';

/**
 * `KanbanUsersEffects` manages the side effects for user-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to users,
 * such as loading all users with pagination and optional name filtering.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `getAllUsers$`: Loads all users with pagination and optional name filtering. It performs an HTTP GET request and dispatches a success action with the fetched users or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanUsersEffects {
  /**
   * Effect to load all users with pagination and optional name filtering.
   * Listens for the `loadUsers` action, checks permissions, and performs an HTTP GET request to fetch the users.
   * Dispatches a success or failure action based on the result.
   */
  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsers),
      checkPermission(UserActions.userUnauthorized),
      mergeMap(({ limit, page, name }) => {
        let params = new HttpParams()
          .set('limit', limit.toString())
          .set('page', page.toString());

        if (name) params = params.append('name', name);

        return this.http
          .get<PaginationData<User>>(`${environment.apiUrl}/v1/users`, {
            params,
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(users => UserActions.loadUsersSuccess({ users })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(UserActions.loadUsersFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Constructor for `KanbanUsersEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
