<div
  class="flex flex-column justify-content-center surface-section px-4 pt-4 pb-8 md:px-6 lg:px-8 h-screen relative z-1 bg-cover">
  <div
    style="
      background: radial-gradient(
        50% 109137.91% at 50% 50%,
        rgba(233, 30, 99, 0.1) 0%,
        rgba(254, 244, 247, 0) 100%
      );
    "
    class="text-center">
    <span class="bg-white text-pink-500 font-bold text-2xl inline-block px-3"
      >404</span
    >
  </div>
  <div i18n class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
    Page Not Found
  </div>
  <p i18n class="text-700 text-3xl mt-0 mb-6 text-center">
    Sorry, the page you are looking for does not exist.
  </p>
  <div class="text-center">
    <ice-button
      routerLink="/home"
      label="Go to homepage"
      i18n-label
      icon="pi pi-home"
      i18n-icon></ice-button>
  </div>
</div>
