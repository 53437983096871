import { createAction, props } from '@ngrx/store';
import { ProfileFile } from '~/app/shared/interfaces/crm/profile/profile-file.interface';

export const loadProfileFiles = createAction(
  '[Offer] Load Profile Files',
  props<{ profileId: string }>()
);
export const loadProfileFilesSuccess = createAction(
  '[Offer] Load Profile Files Success',
  props<{ files: ProfileFile[] }>()
);
export const loadProfileFilesFailure = createAction(
  '[Offer] Load Profile Files Failure',
  props<{ error: string }>()
);

export const offerUnauthorized = createAction(
  '[Offer] Unauthorized',
  props<{ error: string }>()
);
