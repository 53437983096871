import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbansActions from '~/app/states/main/kanban/states/kanbans/kanbans.actions';
import { AllKanbansState } from '~/app/states/main/kanban/states/kanbans/kanbans.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { Kanban } from '~/app/shared/interfaces/kanban/kanban.interface';
import { environment } from '~/environments/environment';
import { IsseKanbanService } from '~/app/states/main/kanban/services/isse-kanban.service';

/**
 * `KanbansEffects` manages the side effects for kanban-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanbans,
 * such as loading all kanbans, loading a kanban by ID, creating, updating, deleting kanbans,
 * and assigning/unassigning users to/from kanbans.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllKanbans$`: Loads all kanbans. It performs an HTTP GET request and dispatches a success action with the fetched kanbans or a failure action if an error occurs.
 * — `loadKanbanById$`: Loads a specific kanban by its ID. It performs an HTTP GET request and dispatches a success action with the fetched kanban or a failure action if an error occurs.
 * — `createKanban$`: Creates a new kanban. It performs an HTTP POST request and dispatches a success action with the created kanban or a failure action if an error occurs.
 * — `createKanbanSuccess$`: Handles the success of creating a kanban by displaying a success toast message and closing the sidebar.
 * — `updateKanban$`: Updates an existing kanban. It performs an HTTP PATCH request and dispatches a success action with the updated kanban or a failure action if an error occurs.
 * — `updateKanbanSuccess$`: Handles the success of updating a kanban by displaying a success toast message and closing the sidebar.
 * — `deleteKanban$`: Deletes a kanban. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteKanbanSuccess$`: Handles the success of deleting a kanban by displaying a success toast message and closing the sidebar.
 * — `assignKanbanUser$`: Assigns a user to a kanban. It performs an HTTP PATCH request and dispatches a success action if the assignment is successful or a failure action if an error occurs.
 * — `assignKanbanUserSuccess$`: Handles the success of assigning a user to a kanban by displaying a success toast message.
 * — `unassignKanbanUser$`: Unassigns a user from a kanban. It performs an HTTP PATCH request and dispatches a success action if the unassignment is successful or a failure action if an error occurs.
 * — `unassignKanbanUserSuccess$`: Handles the success of unassigning a user from a kanban by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AllKanbansEffects {
  /**
   * Effect to load all kanbans.
   * Listens for the `loadAllKanbans` action, checks permissions, and performs an HTTP GET request to fetch the kanbans.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.loadAllKanbans),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<AllKanbansState>(`${environment.apiUrl}/v1/kanbans`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanbans => KanbansActions.loadAllKanbansSuccess({ kanbans })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.loadAllKanbansFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load a specific kanban by its ID.
   * Listens for the `loadKanbanById` action, checks permissions, and performs an HTTP GET request to fetch the kanban.
   * Dispatches a success or failure action based on the result.
   */
  loadKanbanById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.loadKanbanById),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Kanban>(`${environment.apiUrl}/v1/kanbans/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanban => KanbansActions.loadKanbanByIdSuccess({ kanban })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.loadKanbanByIdFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban.
   * Listens for the `createKanban` action, checks permissions, and performs an HTTP POST request to create the kanban.
   * Dispatches a success or failure action based on the result.
   */
  createKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.createKanban),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ kanban }) =>
        this.http
          .post<Kanban>(`${environment.apiUrl}/v1/kanbans`, kanban, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanban => KanbansActions.createKanbanSuccess({ kanban })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.createKanbanFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a kanban.
   * Displays a success toast message and closes the sidebar.
   * This effect does not dispatch any further actions.
   */
  createKanbanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbansActions.createKanbanSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Kanban created successfully');
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing kanban.
   * Listens for the `updateKanban` action, checks permissions, and performs an HTTP PATCH request to update the kanban.
   * Dispatches a success or failure action based on the result.
   */
  updateKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.updateKanban),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ uuid, kanban }) =>
        this.http
          .patch<Kanban>(`${environment.apiUrl}/v1/kanbans/${uuid}`, kanban, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanban => KanbansActions.updateKanbanSuccess({ kanban })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.updateKanbanFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a kanban.
   * Displays a success toast message and closes the sidebar.
   * This effect does not dispatch any further actions.
   */
  updateKanbanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbansActions.updateKanbanSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Kanban updated successfully');
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a kanban.
   * Listens for the `deleteKanban` action, checks permissions, and performs an HTTP DELETE request to delete the kanban.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.deleteKanban),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/kanbans/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => KanbansActions.deleteKanbanSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.deleteKanbanFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban.
   * Displays a success toast message and closes the sidebar.
   * This effect does not dispatch any further actions.
   */
  deleteKanbanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbansActions.deleteKanbanSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Kanban deleted successfully');
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a user to a kanban.
   * Listens for the `assignKanbanUser` action, checks permissions, and performs an HTTP PATCH request to assign the user.
   * Dispatches a success or failure action based on the result.
   */
  assignKanbanUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.assignKanbanUser),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ uuid, assignKanbanUser }) =>
        this.http
          .patch<Kanban>(
            `${environment.apiUrl}/v1/kanbans/${uuid}/assign/users`,
            assignKanbanUser,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanban => KanbansActions.assignKanbanUserSuccess({ kanban })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.assignKanbanUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a user to a kanban.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  assignKanbanUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbansActions.assignKanbanUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a user from a kanban.
   * Listens for the `unassignKanbanUser` action, checks permissions, and performs an HTTP PATCH request to unassign the user.
   * Dispatches a success or failure action based on the result.
   */
  unassignKanbanUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.unassignKanbanUser),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ uuid, unassignKanbanUser }) =>
        this.http
          .patch<Kanban>(
            `${environment.apiUrl}/v1/kanbans/${uuid}/unassign/users`,
            unassignKanbanUser,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanban => KanbansActions.unassignKanbanUserSuccess({ kanban })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbansActions.unassignKanbanUserFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a user from a kanban.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  unassignKanbanUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbansActions.unassignKanbanUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to connect to a channel.
   * Listens for the `connectToChannel` action, checks permissions, and performs an HTTP POST request.
   * Dispatches a success or failure action based on the result.
   */
  connectToChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.connectToChannel),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ connectionDetails }) =>
        this.http
          .post<void>(
            `${environment.isseUrl}/v1/auth/connect`,
            connectionDetails,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => {
              this.isseService.listenToChannel();
              return KanbansActions.connectToChannelSuccess();
            }),
            catchError(error => {
              return of(KanbansActions.connectToChannelFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to disconnect from a channel.
   * Listens for the `disconnectFromChannel` action, checks permissions, and performs an HTTP POST.
   * Dispatches a success or failure action based on the result.
   */
  disconnectFromChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbansActions.disconnectFromChannel),
      checkPermission(KanbansActions.kanbansUnauthorized),
      mergeMap(({ connectionDetails }) =>
        this.http
          .post<void>(
            `${environment.isseUrl}/v1/auth/disconnect`,
            connectionDetails,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => KanbansActions.disconnectFromChannelSuccess()),
            catchError(error => {
              return of(KanbansActions.disconnectFromChannelFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `KanbansEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebar Injectable service for managing the sidebar form.
   * @param {IsseKanbanService} isseService ISSE service for managing the listening to the SSE server.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebar: SidenavFormService,
    private isseService: IsseKanbanService
  ) {}
}
