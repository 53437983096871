import { createAction, props } from '@ngrx/store';
import {
  AssignUnassignKanbanCardTag,
  AssignUnassignKanbanCardUser,
  CreateKanbanCard,
  SwitchKanbanCardColumn,
  UpdateKanbanCard,
  UpdateKanbanCardSequences,
} from '~/app/states/main/kanban/types/kanban-cards.types';
import { KanbanCardTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-card-tag.interface';
import { KanbanCardUser } from '~/app/shared/interfaces/kanban/kanban-user/kanban-card-user.interface';
import { KanbanCard } from '~/app/shared/interfaces/kanban/kanban-card.interface';
import { KanbanProfileColumn } from '~/app/shared/interfaces/kanban/kanban-profile-column.interface';
import { KanbanProfile } from '~/app/shared/interfaces/kanban/kanban-profile.interface';
import { KanbanCardProfile } from '~/app/shared/interfaces/kanban/kanban-card-profile.interface';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { AllKanbanCard } from '~/app/shared/interfaces/kanban/all-kanban-card.interface';
import { OldSequence } from '~/app/shared/interfaces/kanban/old-sequence.interface';
import { ConnectionDetails } from '~/app/shared/interfaces/connectionDetails.interface';
import { IsseUser } from '~/app/shared/interfaces/isse-user.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllKanbanCards = createAction(
  '[Kanban Cards] Load All Kanban Cards',
  props<{ kanbanColumnId: string; filters?: AllKanbansFilters }>()
);
export const loadAllKanbanCardsSuccess = createAction(
  '[Kanban Cards] Load All Kanban Cards Success',
  props<{ kanbanCards: AllKanbanCard; kanbanColumnId: string }>()
);
export const loadAllKanbanCardsFailure = createAction(
  '[Kanban Cards] Load All Kanban Cards Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createKanbanCard = createAction(
  '[Kanban Cards] Create Kanban Card',
  props<{ kanbanCardData: CreateKanbanCard }>()
);
export const createKanbanCardSuccess = createAction(
  '[Kanban Cards] Create Kanban Card Success',
  props<{ kanbanCard: KanbanCard; kanbanColumnId: string }>()
);
export const createKanbanCardFailure = createAction(
  '[Kanban Cards] Create Kanban Card Failure',
  props<{ error: string }>()
);

export const updateKanbanCard = createAction(
  '[Kanban Cards] Update Kanban Card',
  props<{
    uuid: string;
    kanbanCardData: UpdateKanbanCard;
    nbAttachments: number;
    nbComments: number;
  }>()
);
export const updateKanbanCardSuccess = createAction(
  '[Kanban Cards] Update Kanban Card Success',
  props<{
    kanbanCard: KanbanCard;
    kanbanColumnId: string;
    nbAttachments: number;
    nbComments: number;
  }>()
);
export const updateKanbanCardFailure = createAction(
  '[Kanban Cards] Update Kanban Card Failure',
  props<{ error: string }>()
);

export const deleteKanbanCard = createAction(
  '[Kanban Cards] Delete Kanban Card',
  props<{ uuid: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardSuccess = createAction(
  '[Kanban Cards] Delete Kanban Card Success',
  props<{ uuid: string; kanbanColumnId: string }>()
);
export const deleteKanbanCardFailure = createAction(
  '[Kanban Cards] Delete Kanban Card Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * REORDER CARDS + SWITCH COLUMN
 * ************************************************************************
 **/
export const updateKanbanCardSequences = createAction(
  '[Kanban Cards] Update Kanban Card Sequences',
  props<{
    kanbanCardSequencesData: UpdateKanbanCardSequences;
    oldCardSequence: OldSequence[];
  }>()
);
export const updateKanbanCardSequencesSuccess = createAction(
  '[Kanban Cards] Update Kanban Card Sequences Success'
);
export const updateKanbanCardSequencesFailure = createAction(
  '[Kanban Cards] Update Kanban Card Sequences Failure',
  props<{
    error: string;
    kanbanColumnId: string;
    oldCardSequence: OldSequence[];
  }>()
);

export const switchKanbanCardColumn = createAction(
  '[Kanban Cards] Switch Kanban Card Column',
  props<{
    uuid: string;
    kanbanCardsData: SwitchKanbanCardColumn;
    kanbanId: string;
    prevIndex: number;
  }>()
);
export const switchKanbanCardColumnSuccess = createAction(
  '[Kanban Cards] Switch Kanban Card Column Success'
);
export const switchKanbanCardColumnFailure = createAction(
  '[Kanban Cards] Switch Kanban Card Column Failure',
  props<{
    error: string;
    uuid: string;
    kanbanCardsData: SwitchKanbanCardColumn;
    prevIndex: number;
  }>()
);

/**
 * ************************************************************************
 * SSE SERVER
 * ************************************************************************
 **/
export const createKanbanCardServer = createAction(
  '[Kanban Cards] Create Kanban Card Server',
  props<{ kanbanCard: KanbanCard; kanbanColumnId: string }>()
);
export const updateKanbanCardServer = createAction(
  '[Kanban Cards] Update Kanban Card Server',
  props<{
    kanbanCard: KanbanCard;
    kanbanColumnId: string;
    nbAttachments: number;
    nbComments: number;
  }>()
);
export const deleteKanbanCardServer = createAction(
  '[Kanban Cards] Delete Kanban Card Server',
  props<{ uuid: string; kanbanColumnId: string }>()
);

export const updateKanbanCardSequencesServer = createAction(
  '[Kanban Cards] Update Kanban Card Sequences Server',
  props<{
    kanbanCardSequencesData: UpdateKanbanCardSequences;
    oldCardSequence: OldSequence[];
  }>()
);
export const switchKanbanCardColumnServer = createAction(
  '[Kanban Cards] Switch Kanban Card Column Server',
  props<{
    uuid: string;
    kanbanCardsData: SwitchKanbanCardColumn;
  }>()
);

export const assignKanbanCardUserServer = createAction(
  '[Kanban Cards] Assign Kanban Card User Server',
  props<{
    uuid: string;
    kanbanCardUsers: KanbanCardUser[];
    kanbanColumnId: string;
  }>()
);
export const unassignKanbanCardUserServer = createAction(
  '[Kanban Cards] Unassign Kanban Card User Server',
  props<{
    uuid: string;
    kanbanCardUserData: AssignUnassignKanbanCardUser;
  }>()
);
export const assignKanbanCardTagServer = createAction(
  '[Kanban Cards] Assign Kanban Card Tag Server',
  props<{
    uuid: string;
    kanbanCardTags: KanbanCardTag[];
    kanbanColumnId: string;
  }>()
);
export const unassignKanbanCardTagServer = createAction(
  '[Kanban Cards] Unassign Kanban Card Tag Server',
  props<{
    uuid: string;
    kanbanCardTagData: AssignUnassignKanbanCardTag;
  }>()
);

export const userStartTyping = createAction(
  '[Kanban Cards] User Start Typing',
  props<{ kanbanCardId: string; users: IsseUser[] }>()
);
export const userStopTyping = createAction(
  '[Kanban Cards] User Stop Typing',
  props<{ kanbanCardId: string; users: IsseUser[] }>()
);

/**
 * ************************************************************************
 * ASSIGN/UNASSIGN TAG + USER + PROFILE
 * ************************************************************************
 **/
export const assignKanbanCardUser = createAction(
  '[Kanban Cards] Assign Kanban Card User',
  props<{ uuid: string; kanbanCardUserData: AssignUnassignKanbanCardUser }>()
);
export const assignKanbanCardUserSuccess = createAction(
  '[Kanban Cards] Assign Kanban Card User Success',
  props<{
    uuid: string;
    kanbanCardUsers: KanbanCardUser[];
    kanbanColumnId: string;
  }>()
);
export const assignKanbanCardUserFailure = createAction(
  '[Kanban Cards] Assign Kanban Card User Failure',
  props<{ error: string }>()
);

export const unassignKanbanCardUser = createAction(
  '[Kanban Cards] Unassign Kanban Card User',
  props<{ uuid: string; kanbanCardUserData: AssignUnassignKanbanCardUser }>()
);
export const unassignKanbanCardUserSuccess = createAction(
  '[Kanban Cards] Unassign Kanban Card User Success',
  props<{
    uuid: string;
    kanbanCardUserData: AssignUnassignKanbanCardUser;
  }>()
);
export const unassignKanbanCardUserFailure = createAction(
  '[Kanban Cards] Unassign Kanban Card User Failure',
  props<{ error: string }>()
);

export const assignKanbanCardTag = createAction(
  '[Kanban Cards] Assign Kanban Card Tag',
  props<{ uuid: string; kanbanCardTagData: AssignUnassignKanbanCardTag }>()
);
export const assignKanbanCardTagSuccess = createAction(
  '[Kanban Cards] Assign Kanban Card Tag Success',
  props<{
    uuid: string;
    kanbanCardTags: KanbanCardTag[];
    kanbanColumnId: string;
  }>()
);
export const assignKanbanCardTagFailure = createAction(
  '[Kanban Cards] Assign Kanban Card Tag Failure',
  props<{ error: string }>()
);

export const unassignKanbanCardTag = createAction(
  '[Kanban Cards] Unassign Kanban Card Tag',
  props<{ uuid: string; kanbanCardTagData: AssignUnassignKanbanCardTag }>()
);
export const unassignKanbanCardTagSuccess = createAction(
  '[Kanban Cards] Unassign Kanban Card Tag Success',
  props<{
    uuid: string;
    kanbanCardTagData: AssignUnassignKanbanCardTag;
  }>()
);
export const unassignKanbanCardTagFailure = createAction(
  '[Kanban Cards] Unassign Kanban Card Tag Failure',
  props<{ error: string }>()
);

export const getKanbanCardProfiles = createAction(
  '[Kanban Cards] Get Kanban Card Profiles',
  props<{ kanbanCardId: string; kanbanId?: string }>()
);
export const getKanbanCardProfilesSuccess = createAction(
  '[Kanban Cards] Get Kanban Card Profiles Success',
  props<{ profiles: KanbanProfile[] }>()
);
export const getKanbanCardProfilesFailure = createAction(
  '[Kanban Cards] Get Kanban Card Profiles Failure',
  props<{ error: string }>()
);

export const assignKanbanCardProfile = createAction(
  '[Kanban Cards] Assign Kanban Card Profile',
  props<{ kanbanCardId: string; kanbanProfileColumn: KanbanProfileColumn }>()
);
export const assignKanbanCardProfileSuccess = createAction(
  '[Kanban Cards] Assign Kanban Card Profile Success',
  props<{ kanbanCardProfileData: KanbanCardProfile[] }>()
);
export const assignKanbanCardProfileFailure = createAction(
  '[Kanban Cards] Assign Kanban Card Profile Failure',
  props<{ error: string }>()
);

export const unassignKanbanCardProfile = createAction(
  '[Kanban Cards] Unassign Kanban Card Profile',
  props<{ kanbanCardId: string; kanbanProfileColumn: KanbanProfileColumn }>()
);
export const unassignKanbanCardProfileSuccess = createAction(
  '[Kanban Cards] Unassign Kanban Card Profile Success',
  props<{ profileId: string }>()
);
export const unassignKanbanCardProfileFailure = createAction(
  '[Kanban Cards] Unassign Kanban Card Profile Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * SSE SERVER CONNECTION
 * ************************************************************************
 **/

export const connectToChannel = createAction(
  '[Kanban Cards] Connect To Channel',
  props<{ connectionDetails: ConnectionDetails[]; token: string }>()
);
export const connectToChannelSuccess = createAction(
  '[Kanban Cards] Connect To Channel Success'
);
export const connectToChannelFailure = createAction(
  '[Kanban Cards] Connect To Channel Failure',
  props<{ error: string }>()
);

export const disconnectFromChannel = createAction(
  '[Kanban Cards] Disconnect From Channel',
  props<{ connectionDetails: ConnectionDetails[] }>()
);
export const disconnectFromChannelSuccess = createAction(
  '[Kanban Cards] Disconnect From Channel Success'
);
export const disconnectFromChannelFailure = createAction(
  '[Kanban Cards] Disconnect From Channel Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const kanbanCardUnauthorized = createAction(
  '[Kanban Cards] Unauthorized',
  props<{ error: string }>()
);
