import { createReducer, on } from '@ngrx/store';
import { initialHomeTeamState } from '~/app/states/main/crm/home/states/home-teams/home-teams.state';
import * as HomeTeamsActions from '~/app/states/main/crm/home/states/home-teams/home-teams.actions';

export const homeTeamsReducer = createReducer(
  initialHomeTeamState,

  on(HomeTeamsActions.loadHomeTeamsAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HomeTeamsActions.loadHomeTeamsActionSuccess, (state, { teams }) => ({
    ...state,
    teams,
    loading: false,
  })),
  on(HomeTeamsActions.loadHomeTeamsActionFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(HomeTeamsActions.homeTeamUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
