<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="flex flex-column px-4 pb-4 gap-4">
  <ng-container *ngIf="title || subtitle || badge || titleText">
    <div class="flex align-items-center justify-content-between">
      <div class="flex flex-column gap-2">
        <div
          class="flex align-items-center gap-2"
          *ngIf="title || badge || titleText">
          <i
            *ngIf="iconClass"
            [class]="iconClass + ' text-2xl mr-3 text-primary'"
            i18n-aria-label="@@iconAriaLabel"
            aria-label="Icon">
          </i>
          <h1
            *ngIf="title"
            class="m-0 text-5xl font-medium line-height-3 color-dark-100"
            i18n-aria-label="@@titleAriaLabel"
            aria-label="Title">
            {{ title }}
          </h1>
          <span
            *ngIf="titleText"
            class="text-base font-normal ml-3 text-200 color-dark-70"
            i18n-aria-label="@@titleTextAriaLabel"
            aria-label="Title text">
            {{ titleText }}
          </span>
          <!-- Render the text next to the title -->
          <p-badge
            *ngIf="badge"
            [value]="badge"
            severity="warning"
            i18n-severity
            i18n-aria-label="@@badgeAriaLabel"
            aria-label="Badge">
          </p-badge>
        </div>
        <p
          *ngIf="subtitle"
          class="mb-0 mt-0 text-base text-500"
          i18n-aria-label="@@subtitleAriaLabel"
          aria-label="Subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div class="mt-3 md:mt-0">
        <ng-container *ngFor="let button of buttonsConfig">
          <ice-button
            *ngIf="button.onClick"
            [rule]="button.buttonRule"
            [unauthorizedBehaviour]="button.unauthorizedBehaviour"
            [label]="button.label"
            [icon]="button.icon"
            [ngClass]="button.styleClass"
            (onClick)="button.onClick()"></ice-button>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="navTabsConfig.length > 0">
    <ul
      class="flex align-items-center overflow-x-auto gap-3 m-0 px-2 list-none">
      <li *ngFor="let tab of navTabsConfig; let first = first">
        <a
          class="flex align-items-center cursor-pointer py-3 px-4 hover:border-300 transition-duration-150 transition-all no-underline"
          [routerLink]="'./' + tab.route"
          [ngClass]="{ 'border-bottom-2 border-500': tab.isActive }">
          <i [class]="'text-primary-500 mr-3 pi ' + tab.icon"></i>
          <span class="text-500 font-medium hidden md:block">
            {{ tab.label }}
          </span>
        </a>
      </li>
    </ul>
  </ng-container>
</div>
