import { createAction, props } from '@ngrx/store';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { TimelineTaskSales } from '~/app/shared/interfaces/sales/timelines/timeline-task-sale.interface';

export const loadSalesProjects = createAction(
  '[Sales Projects] Load Sales Projects',
  props<{ saleId: string }>()
);
export const loadSalesProjectsSuccess = createAction(
  '[Sales Projects] Load Sales Projects Success',
  props<{ salesProjects: Project[] }>()
);
export const loadSalesProjectsFailure = createAction(
  '[Sales Projects] Load Sales Projects Failure',
  props<{ error: string }>()
);

export const updateAssignedTaskStatus = createAction(
  '[Sales Projects] Update Assigned Task Status',
  props<{ timelineTaskId: string; status: boolean }>()
);
export const updateAssignedTaskStatusSuccess = createAction(
  '[Sales Projects] Update Assigned Task Status Success',
  props<{ task: TimelineTaskSales }>()
);
export const updateAssignedTaskStatusFailure = createAction(
  '[Sales Projects] Update Assigned Task Status Failure',
  props<{ error: string }>()
);

export const salesProjectsUnauthorized = createAction(
  '[Sales Projects] Unauthorized',
  props<{ error: string }>()
);
