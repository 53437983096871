import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

/**
 * Represents the state of offers.
 *
 * @interface OffersState
 */
export interface OffersState {
  /**
   * The current offer.
   *
   * @type {Offer | null}
   */
  offer: Offer | null;

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for offers.
 *
 * @type {OffersState}
 */
export const initialOffersState: OffersState = {
  offer: null,
  loading: false,
  error: null,
};
