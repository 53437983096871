import { User } from '~/app/shared/interfaces/auth/user.interface';

/**
 * Represents the state of home users, including loading status and error information.
 *
 * @interface HomeUsersState
 */
export interface HomeUsersState {
  /**
   * An array of users.
   *
   * @type {Team[]}
   * @memberof HomeUsersState
   */
  users: User[];

  /**
   * Indicates if the users are currently being loaded.
   *
   * @type {boolean}
   * @memberof HomeUsersState
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the users.
   *
   * @type {string | null}
   * @memberof HomeUsersState
   */
  error: string | null;
}

/**
 * The initial state of home teams within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {HomeUsersState} initialHomeUserState
 * @description Provides a baseline definition for the home users context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialHomeUserState: HomeUsersState = {
  users: [],
  loading: false,
  error: null,
};
