import { createAction, props } from '@ngrx/store';
import { UpdateSalesReminder } from '~/app/states/main/sales/profile/types/sales-reminders.types';
import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';

export const loadSalesReminders = createAction(
  '[Sales Reminders] Load Sales Reminders',
  props<{ saleId: string }>()
);
export const loadSalesRemindersSuccess = createAction(
  '[Sales Reminders] Load Sales Reminders Success',
  props<{ salesReminders: Reminder[] }>()
);
export const loadSalesRemindersFailure = createAction(
  '[Sales Reminders] Load Sales Reminders Failure',
  props<{ error: string }>()
);

export const createSalesReminder = createAction(
  '[Sales Reminders] Create Sales Reminder',
  props<{ salesReminderData: FormData }>()
);
export const createSalesReminderSuccess = createAction(
  '[Sales Reminders] Create Sales Reminder Success',
  props<{ salesReminder: Reminder }>()
);
export const createSalesReminderFailure = createAction(
  '[Sales Reminders] Create Sales Reminder Failure',
  props<{ error: string }>()
);

export const updateSalesReminder = createAction(
  '[Sales Reminders] Update Sales Reminder',
  props<{ uuid: string; salesReminderData: UpdateSalesReminder }>()
);
export const updateSalesReminderSuccess = createAction(
  '[Sales Reminders] Update Sales Reminder Success',
  props<{ salesReminder: Reminder }>()
);
export const updateSalesReminderFailure = createAction(
  '[Sales Reminders] Update Sales Reminder Failure',
  props<{ error: string }>()
);

export const deleteSalesReminder = createAction(
  '[Sales Reminders] Delete Sales Reminder',
  props<{ uuid: string; saleId: string }>()
);
export const deleteSalesReminderSuccess = createAction(
  '[Sales Reminders] Delete Sales Reminder Success',
  props<{ uuid: string }>()
);
export const deleteSalesReminderFailure = createAction(
  '[Sales Reminders] Delete Sales Reminder Failure',
  props<{ error: string }>()
);

export const checkSalesReminder = createAction(
  '[Sales Reminders] Check Sales Reminder',
  props<{ uuid: string; saleId: string }>()
);
export const checkSalesReminderSuccess = createAction(
  '[Sales Reminders] Check Sales Reminder Success',
  props<{ uuid: string }>()
);
export const checkSalesReminderFailure = createAction(
  '[Sales Reminders] Check Sales Reminder Failure',
  props<{ error: string }>()
);

export const uncheckSalesReminder = createAction(
  '[Sales Reminders] Uncheck Sales Reminder',
  props<{ uuid: string; saleId: string }>()
);
export const uncheckSalesReminderSuccess = createAction(
  '[Sales Reminders] Uncheck Sales Reminder Success',
  props<{ uuid: string }>()
);
export const uncheckSalesReminderFailure = createAction(
  '[Sales Reminders] Uncheck Sales Reminder Failure',
  props<{ error: string }>()
);

export const salesRemindersUnauthorized = createAction(
  '[Sales Reminders] Unauthorized',
  props<{ error: string }>()
);
