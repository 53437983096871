import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AllSkillsActions from '~/app/shared/states/skills/skills.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { registerAction } from '~/app/shared/operators/register-action';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { AllSkillsState } from '~/app/shared/states/skills/skills.state';
import { environment } from '~/environments/environment';

/**
 * `AllSkillsEffects` manages the side effects for skill-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to skills, such as loading available skills.
 * It performs operations that involve checking permissions and making HTTP requests to fetch skill data.
 *
 * The effects in this class include:
 * - `loadAllSkills$`: Fetches all available skills from the API, dispatching success or failure actions based on the result.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AllSkillsEffects {
  /**
   * Effect to load all skills. It listens for the `loadAllSkills` action,
   * checks for permissions, and makes an HTTP request to fetch skills.
   * Upon successful fetch, it dispatches a success action with the fetched skills.
   *
   * @memberof AllSkillsEffects
   */
  loadAllSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AllSkillsActions.loadAllSkills),
      registerAction(),
      checkPermission(AllSkillsActions.loadAllSkillsUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<AllSkillsState>(`${environment.apiUrl}/v1/skills`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(skills => AllSkillsActions.loadAllSkillsSuccess({ skills })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AllSkillsActions.loadAllSkillsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `AllSkillsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
