import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as GroupRuleActions from '~/app/states/main/settings/states/access/access.actions';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Group } from '~/app/shared/interfaces/settings/access-control/group/group.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `GroupRulesEffects` orchestrates the handling of asynchronous operations related to group rules in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to group rules, such as fetching and
 * updating data from and to the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadGroupRules$`: Fetches group rules from the server.
 * — `assignRuleInGroup$`: Assigns a rule to a group.
 * — `unassignRuleInGroup$`: Unassigns a rule from a group.
 * — `loadGroupRuleById$`: Fetches a group rule by its ID from the server.
 * — `createGroupRule$`: Creates a new group rule.
 * — `createGroupRuleSuccess$`: Handles the success of creating a new group rule.
 * — `updateGroupRule$`: Updates an existing group rule.
 * — `updateGroupRuleSuccess$`: Handles the success of updating a group rule.
 * — `deleteGroupRule$`: Deletes a group rule.
 * — `deleteGroupRuleSuccess$`: Handles the success of deleting a group rule.
 * — `assignUserToGroup$`: Assigns a user to a group.
 * — `assignUserToGroupSuccess$`: Handles the success of assigning a user to a group.
 * — `unassignUserFromGroup$`: Unassigns a user from a group.
 * — `unassignUserFromGroupSuccess$`: Handles the success of unassigning a user from a group.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AccessEffects {
  /**
   * Effect to load group rules. It listens for the loadGroupRules action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadGroupRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.loadGroupRules),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(() => {
        return this.http
          .get<PaginationData<Group>>(`${environment.apiUrl}/v1/rules`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(groups => GroupRuleActions.loadGroupRulesSuccess({ groups })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.loadGroupRulesFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to assign a rule to a group. It listens for the `assignRuleInGroup` action, checks for permissions,
   * and makes an HTTP PATCH request to assign a rule to a group. Upon success or failure, it dispatches the
   * corresponding success or failure actions.
   */
  assignRuleInGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.assignRuleInGroup),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ rulesRuleGroupData }) => {
        return this.http
          .patch<Group>(
            `${environment.apiUrl}/v1/rules/assign/rules`,
            rulesRuleGroupData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => {
              this.toast.successToast('Success', 'Rule assigned successfully');
              return GroupRuleActions.assignRuleInGroupSuccess();
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.assignRuleInGroupFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to unassign a rule from a group. It listens for the `unassignRuleInGroup` action, checks for permissions,
   * and makes an HTTP PATCH request to unassign a rule from a group. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  unassignRuleInGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.unassignRuleInGroup),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ rulesRuleGroupData }) => {
        return this.http
          .patch<Group>(
            `${environment.apiUrl}/v1/rules/unassigned/rules`,
            rulesRuleGroupData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => {
              this.toast.successToast(
                'Success',
                'Rule unassigned successfully'
              );
              return GroupRuleActions.unassignRuleInGroupSuccess();
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.unassignRuleInGroupFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to load a group rule by its ID. It listens for the `loadGroupRuleById` action, checks for permissions,
   * and makes an HTTP GET request to fetch the rule by its ID. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadGroupRuleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.loadGroupRuleById),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ uuid }) => {
        return this.http
          .get<any>(`${environment.apiUrl}/v1/rules/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(rule => GroupRuleActions.loadGroupRuleByIdSuccess({ rule })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.loadGroupRuleByIdFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to create a new group rule. It listens for the `createGroupRule` action, checks for permissions,
   * and makes an HTTP POST request to create a new group rule. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  createGroupRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.createGroupRule),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ ruleGroupData }) => {
        return this.http
          .post<Group>(`${environment.apiUrl}/v1/rules`, ruleGroupData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(group => GroupRuleActions.createGroupRuleSuccess({ group })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.createGroupRuleFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle success of creating a new group rule. It listens for the `createGroupRuleSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  createGroupRuleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupRuleActions.createGroupRuleSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Group Rule created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing group rule. It listens for the `updateGroupRule` action, checks for permissions,
   * and makes an HTTP PATCH request to update the group rule. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  updateGroupRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.updateGroupRule),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ uuid, ruleGroupData }) => {
        return this.http
          .patch<Group>(
            `${environment.apiUrl}/v1/rules/${uuid}`,
            ruleGroupData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(groupData =>
              GroupRuleActions.updateGroupRuleSuccess({ groupData })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.updateGroupRuleFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle success of updating a group rule. It listens for the `updateGroupRuleSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  updateGroupRuleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupRuleActions.updateGroupRuleSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Group updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a group rule. It listens for the `deleteGroupRule` action, checks for permissions,
   * and makes an HTTP DELETE request to delete the group rule. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  deleteGroupRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.deleteGroupRule),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/rules/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              GroupRuleActions.deleteGroupRuleSuccess({ groupId: uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.deleteGroupRuleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of deleting a group rule. It listens for the `deleteGroupRuleSuccess` action,
   * shows a success toast notification, and closes the sidebar. This effect does not dispatch any actions.
   */
  deleteGroupRuleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupRuleActions.deleteGroupRuleSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Group Rule deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a user to a group. It listens for the `assignUserInGroup` action, checks for permissions,
   * and makes an HTTP PATCH request to assign a user to a group. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  assignUserToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.assignUserInGroup),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ userRuleGroupData }) => {
        return this.http
          .patch<User>(
            `${environment.apiUrl}/v1/rules/assign/users`,
            userRuleGroupData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(user => GroupRuleActions.assignUserInGroupSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.assignUserInGroupFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle success of assigning a user to a group. It listens for the `assignUserInGroupSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  assignUserToGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupRuleActions.assignUserInGroupSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a user from a group. It listens for the `unassignUserInGroup` action, checks for permissions,
   * and makes an HTTP PATCH request to unassign a user from a group. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  unassignUserFromGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupRuleActions.unassignUserInGroup),
      checkPermission(GroupRuleActions.groupRulesUnauthorized),
      mergeMap(({ userRuleGroupData }) => {
        return this.http
          .patch<User>(
            `${environment.apiUrl}/v1/rules/unassigned/users`,
            userRuleGroupData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(user => GroupRuleActions.unassignUserInGroupSuccess({ user })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(GroupRuleActions.unassignUserInGroupFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle success of unassigning a user from a group. It listens for the `unassignUserInGroupSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  unassignUserFromGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupRuleActions.unassignUserInGroupSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for GroupRulesEffects.
   *
   * @param {Actions} actions$ Injected actions observable that allows the effect to react to actions dispatched to the store.
   * @param {HttpClient} http Injected HttpClient for making API calls.
   * @param {CustomToastService} toast Injected service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injected service for managing the sidebar form.
   * @param {HttpErrorsService} httpErrors Injected service for handling HTTP error responses.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService,
    private httpErrors: HttpErrorsService
  ) {}
}
