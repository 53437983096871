<!--TODO: Add rules-->

<form [formGroup]="kanbanForm" (ngSubmit)="onSubmit()">
  <ice-input
    [label]="'Title'"
    [placeholder]="'Name'"
    [required]="true"
    [formControlName]="'name'"
    [formControl]="name"></ice-input>
  <ice-input
    [label]="'Description'"
    [placeholder]="'Description'"
    [formControlName]="'description'"
    [formControl]="description"></ice-input>

  <p-divider></p-divider>

  <div class="flex flex-column gap-2 pt-3 pb-2 mb-2">
    <div>
      <label class="color-dark-80 text-sm">File upload</label>
    </div>

    <div
      class="flex align-items-center justify-content-center flex-column border-dashed border-200 border-2 border-round p-5">
      <ice-file-upload
        name="file"
        mode="basic"
        i18n-mode
        chooseLabel=""
        chooseIcon="pi pi-cloud-upload"
        i18n-chooseLabel
        (onSelect)="onUpload($event)">
      </ice-file-upload>
    </div>
  </div>

  <div class="flex flex-row-reverse justify-content-between gap-2">
    <ice-button
      [label]="initialData ? 'Update' : 'Create'"
      [disabled]="!kanbanForm.valid"
      type="submit"
      [loading]="(isKanbanLoading$ | async) ?? false"></ice-button>
    <ice-button
      *ngIf="initialData"
      [text]="true"
      label="Delete"
      i18n-label
      severity="danger"
      i18n-severity
      (onClick)="onDelete()"></ice-button>
  </div>
</form>
