import { createAction, props } from '@ngrx/store';
import {
  CreateProfileInterviews,
  UpdateProfileInterviews,
} from '~/app/states/main/crm/profile/types/profile-interviews.types';
import { ProfileInterview } from '~/app/shared/interfaces/crm/profile/profile-interview.interface';
import { ProfileWeeklyInterview } from '~/app/shared/interfaces/crm/home/interview/profile-weekly-interview.interface';

export const loadProfileInterviews = createAction(
  '[Profile Interviews] Load Profile Interviews',
  props<{ profileId: string }>()
);
export const loadProfileInterviewsSuccess = createAction(
  '[Profile Interviews] Load Profile Interviews Success',
  props<{ profileInterviews: ProfileInterview[] }>()
);
export const loadProfileInterviewsFailure = createAction(
  '[Profile Interviews] Load Profile Interviews Failure',
  props<{ error: string }>()
);

export const loadWeeklyProfileInterviews = createAction(
  '[Profile Interviews] Load Weekly Profile Interviews'
);
export const loadWeeklyProfileInterviewsSuccess = createAction(
  '[Profile Interviews] Load Weekly Profile Interviews Success',
  props<{ weeklyProfileInterviews: ProfileWeeklyInterview }>()
);
export const loadWeeklyProfileInterviewsFailure = createAction(
  '[Profile Interviews] Load Weekly Profile Interviews Failure',
  props<{ error: string }>()
);

export const createProfileInterview = createAction(
  '[Profile Interviews] Create Profile Interview',
  props<{ profileInterview: CreateProfileInterviews }>()
);
export const createProfileInterviewSuccess = createAction(
  '[Profile Interviews] Create Profile Interview Success',
  props<{ profileInterview: ProfileInterview }>()
);
export const createProfileInterviewFailure = createAction(
  '[Profile Interviews] Create Profile Interview Failure',
  props<{ error: string }>()
);

export const updateProfileInterview = createAction(
  '[Profile Interviews] Update Profile Interview',
  props<{ uuid: string; profileInterview: UpdateProfileInterviews }>()
);
export const updateProfileInterviewSuccess = createAction(
  '[Profile Interviews] Update Profile Interview Success',
  props<{ profileInterview: ProfileInterview }>()
);
export const updateProfileInterviewFailure = createAction(
  '[Profile Interviews] Update Profile Interview Failure',
  props<{ error: string }>()
);

export const deleteProfileInterview = createAction(
  '[Profile Interviews] Delete Profile Interview',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileInterviewSuccess = createAction(
  '[Profile Interviews] Delete Profile Interview Success',
  props<{ uuid: string }>()
);
export const deleteProfileInterviewFailure = createAction(
  '[Profile Interviews] Delete Profile Interview Failure',
  props<{ error: string }>()
);

export const profileInterviewsUnauthorized = createAction(
  '[Profile Interviews] Unauthorized',
  props<{ error: string }>()
);
