import { Pipe, PipeTransform } from '@angular/core';

/**
 * Adds a duration to a date.
 * @Pipe decorator defines the name of the pipe.
 */
@Pipe({
  name: 'addDuration',
})
export class AddDurationPipe implements PipeTransform {
  /**
   * Adds a duration to a date.
   * @param {Date | string} startDate - The start date.
   * @param {number} duration - The duration to add.
   * @param {string} durationUnit - The unit of the duration.
   * @returns {Date} - The new date.
   */
  transform(
    startDate: Date | string,
    duration: number,
    durationUnit: string
  ): Date {
    const date = new Date(startDate);

    switch (durationUnit) {
      case 'year':
        date.setFullYear(date.getFullYear() + duration);
        break;
      case 'month':
        date.setMonth(date.getMonth() + duration);
        break;
      case 'days':
        date.setDate(date.getDate() + duration);
        break;
    }

    return date;
  }
}
