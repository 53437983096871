/* eslint-disable max-lines */

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
  HostListener,
  QueryList,
  ViewChildren,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { IceCompleteEvent } from '~/app/shared/interfaces/shared/pipe/ice-complete-event.interface';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { fadeIn } from '~/app/shared/animations/fade-in';
import { VoidFunction } from '~/app/shared/types/function/void-function.type';
import { IceAutocompleteValue } from '~/app/shared/interfaces/shared/ice-components/ice-autocomplete-value.interface';

/**
 * `NewIceAutocompleteComponent` is an Angular component that provides an autocomplete input field.
 * It allows users to select from a list of suggestions while typing.
 *
 * The component supports single and multiple selections and can be integrated with Angular forms.
 *
 * @selector 'new-ice-autocomplete'
 * @templateUrl './new-ice-autocomplete.component.html'
 * @styleUrl './new-ice-autocomplete.component.scss'
 */
@Component({
  selector: 'new-ice-autocomplete',
  templateUrl: './new-ice-autocomplete.component.html',
  styleUrls: ['./new-ice-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewIceAutocompleteComponent),
      multi: true,
    },
  ],
  animations: [fadeIn],
})
export class NewIceAutocompleteComponent implements OnDestroy, OnInit {
  /**
   * References to DOM elements in the component.
   *
   * @type {ElementRef<HTMLInputElement> | undefined}
   * @description Reference to the input element for user input.
   */
  @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;

  /**
   * Reference to the container element that wraps the input and suggestions.
   *
   * @type {ElementRef<HTMLDivElement> | undefined}
   * @description Reference to the container element for layout purposes.
   */
  @ViewChild('container') container: ElementRef<HTMLDivElement> | undefined;

  /**
   * Reference to the suggestions list container that displays autocomplete suggestions.
   *
   * @type {ElementRef<HTMLUListElement> | undefined}
   * @description Reference to the suggestions list for displaying potential matches.
   */
  @ViewChild('suggestions') suggestions:
    | ElementRef<HTMLUListElement>
    | undefined;

  /**
   * Reference to the suggestions list element that displays autocomplete suggestions.
   *
   * @type {ElementRef<HTMLUListElement> | undefined}
   * @description Reference to the suggestions list for displaying potential matches.
   */
  @ViewChildren('suggestionItem') suggestionsList!: QueryList<
    ElementRef<HTMLLIElement>
  >;

  /**
   * Label for the autocomplete input field.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Placeholder text for the autocomplete input.
   * @type {string}
   */
  @Input() placeholder!: string;

  /**
   * Specifies if the autocomplete is a required field.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Specifies if multiple selections are allowed.
   * @type {boolean}
   */
  @Input() multiple = false;

  /**
   * Observable that provides suggestions for the autocomplete.
   * @type {Observable<(unknown & LabelValueInterface)[]>}
   */
  @Input({ required: true }) suggestions$!: Observable<
    (unknown & LabelValueInterface)[]
  >;

  /**
   * Template to be used for the autocomplete suggestions.
   * @type {IceAutocompleteTemplateEnum}
   */
  @Input() iceAutocompleteTemplate: IceAutocompleteTemplateEnum =
    IceAutocompleteTemplateEnum.DEFAULT;

  /**
   * Form control associated with the autocomplete input.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * Boolean triggering the inline style of the autocomplete
   * @type {boolean}
   */
  @Input() inline = false;

  /**
   * Event emitted when a suggestion is selected.
   * @type {EventEmitter<IceCompleteEvent>}
   */
  @Output() completeMethod: EventEmitter<IceCompleteEvent> =
    new EventEmitter<IceCompleteEvent>();

  /**
   * An observable containing all the suggestions filtered by the query in the input
   *
   * @type {Observable<(unknown & LabelValueInterface)[]>}
   */
  filteredSuggestions$!: Observable<(unknown & LabelValueInterface)[]>;

  /**
   * Boolean to track if the option list's backdrop is open
   *
   * @type {boolean}
   */
  isOpen = false;

  /**
   * Boolean to track if the option list's backdrop is open
   * but a little delayed to trigger the closing animation
   *
   * @type {boolean}
   */
  isOpenAnim = false;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange.
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched.
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * A BehaviorSubject that holds the current query string for the autocomplete.
   * It allows subscribers to react to changes in the query.
   *
   * @type {BehaviorSubject<string>}
   */
  querySubject = new BehaviorSubject<string>('');

  /**
   * An observable that emits the current value of the query subject.
   * Subscribers can use this observable to get updates on the query changes.
   *
   * @type {Observable<string>}
   */
  query$ = this.querySubject.asObservable();

  /**
   * Current value of the autocomplete.
   * @type {LabelValueInterface[] | LabelValueInterface | null}
   */
  value!: LabelValueInterface[] | LabelValueInterface | null;

  /**
   * Array to hold suggestions for the autocomplete.
   * The suggestions can be of unknown type combined with LabelValueInterface.
   *
   * @type {(unknown & LabelValueInterface)[]}
   */
  suggestionsValue: (unknown & LabelValueInterface)[] = [];

  /**
   * Subscription object to manage multiple subscriptions.
   * This helps in cleaning up subscriptions when the component is destroyed.
   *
   * @type {Subscription}
   */
  subscription: Subscription = new Subscription();

  /**
   * A protected property to reference the `IceAutocompleteTemplateEnum`.
   * This enumeration holds the template configuration options available for the autocomplete component.
   * It is used internally within the component to configure the rendering and behavior of the autocomplete suggestions.
   * Being `readonly` ensures that the value cannot be modified after initialization.
   *
   * @type {IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Index of the currently focused suggestion in the autocomplete dropdown.
   * This is used to manage keyboard navigation within the suggestions.
   *
   * @type {number | null}
   */
  private currentFocusIndex: number | null = null;

  /**
   * Creates an instance of NewIceAutocompleteComponent.
   *
   * @param {Renderer2} renderer - The renderer to manipulate DOM elements.
   */
  constructor(private renderer: Renderer2) {
    /**
     * This events get called by all clicks on the page.
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked.
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition above must close the menu.
       */
      if (
        this.suggestions &&
        !this.isAncestor(
          e.target as HTMLElement,
          this.suggestions.nativeElement
        ) &&
        e.target !== this.input?.nativeElement
      ) {
        this.setIsOpen(false);
      }
    });
  }

  /**
   * Handles keyboard events for navigating and selecting suggestions.
   *
   * @param {KeyboardEvent} event - The keyboard event triggered by user actions.
   * @returns {void}
   */
  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.isOpen) {
      if (event.key === 'ArrowDown' || event.key === 'Tab') {
        event.preventDefault();
        this.focusNextSuggestion();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusPreviousSuggestion();
      } else if (event.key === 'Enter') {
        event.preventDefault();
        this.selectFocusedSuggestion();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        this.setIsOpen(false);
      }
    }
  }

  /**
   * OnInit lifecycle hook to initialize the component.
   * It sets up the filtered suggestions observable based on user input.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.filteredSuggestions$ = this.querySubject.pipe(
      switchMap(query =>
        this.suggestions$.pipe(
          map(suggestions =>
            suggestions.filter(suggestion =>
              suggestion.label.toLowerCase().includes(query.toLowerCase())
            )
          )
        )
      )
    );

    this.subscription.add(
      this.suggestions$.subscribe(suggestions => {
        this.suggestionsValue = suggestions;
      })
    );
  }

  /**
   * OnDestroy lifecycle hook to clean up the component.
   * It unsubscribes from any subscriptions to prevent memory leaks.
   *
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks if a given element is an ancestor of another element.
   *
   * @param {HTMLElement} descendent - The potential descendant element.
   * @param {HTMLElement} ancestor - The potential ancestor element.
   * @returns {boolean} - True if descendent is an ancestor of ancestor, otherwise false.
   */
  isAncestor(descendent: HTMLElement, ancestor: HTMLElement): boolean {
    return (
      descendent.parentElement === ancestor ||
      descendent.parentElement?.parentElement === ancestor ||
      descendent.parentElement?.parentElement?.parentElement === ancestor ||
      descendent.parentElement?.parentElement?.parentElement?.parentElement ===
        ancestor ||
      descendent.parentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement === ancestor
    );
  }

  /**
   * Sets the open state of the autocomplete suggestions.
   *
   * @param {boolean} value - The value to set for the open state.
   * @returns {void}
   */
  setIsOpen(value: boolean): void {
    if (!value) {
      this.isOpenAnim = false;
      setTimeout(() => {
        this.isOpen = false;
      }, 150);
      return;
    }
    if (this.suggestions && this.input) {
      this.suggestions.nativeElement.style.marginTop =
        this.input.nativeElement.offsetHeight +
        this.input.nativeElement.offsetTop +
        5 +
        'px';

      this.suggestions.nativeElement.style.width =
        this.input.nativeElement.offsetWidth + 'px';
    }

    this.isOpen = true;
    this.isOpenAnim = true;
  }

  /**
   * Handles the focus event on the input field.
   * @returns {void}
   */
  onFocusInput(): void {
    this.onFocus();
  }

  /**
   * Triggers the touch event callback when the autocomplete element gains focus.
   * @return {void}
   */
  onFocus(): void {
    this.setIsOpen(true);
  }

  /**
   * Triggers the touch event callback when the autocomplete element loses focus.
   * @return {void}
   */
  onBlur(): void {
    this.setIsOpen(false);
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   *
   * @param {IceAutocompleteValue} value - The new value (string).
   * @returns {void}
   */
  writeValue(value: IceAutocompleteValue): void {
    this.value = value;
  }

  /**
   * Registers a function to be called when the input value changes.
   *
   * @param {AnyVoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   *
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Handles the selection of a suggestion.
   *
   * @param {LabelValueInterface} suggestion - The selected suggestion.
   * @return {void}
   */
  selectSuggestion(suggestion: unknown & LabelValueInterface): void {
    if (this.multiple) {
      const currentValues = this.formControl.value ?? [];
      const index = currentValues.findIndex(
        (item: unknown & LabelValueInterface) => item.value === suggestion.value
      );

      let updatedValues;
      if (index > -1) {
        updatedValues = currentValues.filter(
          (item: unknown & LabelValueInterface) =>
            item.value !== suggestion.value
        );
      } else {
        updatedValues = [...currentValues, suggestion];
      }

      this.formControl.setValue(updatedValues);

      return;
    }

    const currentValue = this.formControl.value;

    if (currentValue && currentValue.value === suggestion.value) {
      this.formControl.setValue(null);
    } else {
      this.formControl.setValue(suggestion);
    }
    this.setIsOpen(false);
  }

  /**
   * Handles the completion event when the input value changes.
   *
   * @param {Event} event - The input event.
   * @returns {void}
   */
  onComplete(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.querySubject.next(inputValue);
    this.setIsOpen(true);
    this.completeMethod.emit({
      event,
      query: inputValue,
    });
    this.currentFocusIndex = 0;
  }

  /**
   * Focuses on the next suggestion in the suggestions list.
   *
   * This method increments the current focus index to move to the next suggestion. If the end of the list is reached,
   * it wraps around to the beginning. It also ensures that the suggestion list is not empty before attempting to focus.
   *
   * @returns {void}
   */
  focusNextSuggestion(): void {
    const suggestions = this.suggestionsList.toArray();

    if (!suggestions.length) return;

    this.currentFocusIndex =
      (this.currentFocusIndex === null ? -1 : this.currentFocusIndex + 1) %
      suggestions.length;
    this.suggestions?.nativeElement
      .querySelectorAll('li')
      [this.currentFocusIndex].focus();
  }

  /**
   * Focuses on the previous suggestion in the suggestions list.
   *
   * This method decrements the current focus index to move to the previous suggestion. If the beginning of the list is reached,
   * it wraps around to the end. It also ensures that the suggestion list is not empty before attempting to focus.
   *
   * @returns {void}
   */
  focusPreviousSuggestion(): void {
    const suggestions = this.suggestionsList.toArray();
    if (!suggestions.length) return;

    this.currentFocusIndex =
      (this.currentFocusIndex === null
        ? 0
        : this.currentFocusIndex - 1 + suggestions.length) % suggestions.length;
    this.suggestions?.nativeElement
      .querySelectorAll('li')
      [this.currentFocusIndex].focus();
  }

  /**
   * Selects the currently focused suggestion from the suggestions list.
   *
   * This method checks if the current focus index is within the bounds of the suggestions array. If valid, it retrieves the
   * suggestion at the current focus index and calls the method to select that suggestion.
   *
   * @returns {void}
   */
  selectFocusedSuggestion(): void {
    if ((this.currentFocusIndex ?? 0) < this.suggestionsValue.length) {
      if ((this.currentFocusIndex ?? 0) <= 0) this.currentFocusIndex = 0;
      const suggestion = this.suggestionsValue[this.currentFocusIndex ?? 0];
      this.selectSuggestion(suggestion);
    }
  }
}
