import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AuthState } from '~/app/auth/auth.state';
import { LoginService } from '~/app/auth/services/login.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { LoginFormComponent } from '~/app/shared/components/login-form/login-form.component';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Represents the header component of the landing page.
 * This component displays the header section of the landing page, including the navigation menu and login/logout functionality.
 * This component is a template.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'landing-header' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './header.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./header.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'landing-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /**
   * Form group for search filters.
   */
  navigationForm!: FormGroup;

  /**
   * Observable that holds the authentication state.
   * This observable is used to determine whether the user is logged in or not.
   */
  isLoggedIn$: Observable<AuthState>;

  /**
   * Boolean flag indicating if the dark theme is currently active.
   * @type {boolean}
   */
  isDarkTheme = false;

  /**
   * Constructs an instance of the HeaderComponent with dependencies injected via the constructor.
   *
   * @param {Store<AppState>} store A Redux store that provides access to the application state.
   * @param {LoginService} loginService A service responsible for handling user login/logout operations.
   * @param {FormBuilder} formBuilder Form builder for constructing form groups.
   * @param {ThemeService} theme Theme service.
   * @param {DialogService} dialog Dialog service.
   */
  constructor(
    private store: Store<AppState>,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private theme: ThemeService,
    private dialog: DialogService
  ) {
    this.isLoggedIn$ = this.store.select(selectAuthState);
    this.isDarkTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Gets the form control for profile type.
   *
   * @returns {FormControl} Form control for profile type.
   */
  public get type(): FormControl {
    return this.navigationForm.get('type') as FormControl;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes the search form structure.
   *
   * @returns {void} Does not return any value.
   */
  ngOnInit(): void {
    this.initForms();
  }

  /**
   * Initializes the forms for search and pagination.
   *
   * @returns {void}
   * @memberof ConsultantsComponent
   */
  initForms(): void {
    this.navigationForm = this.formBuilder.group({
      type: ['dream-job'],
    });
  }

  /**
   * Logs out the current user.
   * This method is triggered when the user clicks on the logout button in the header.
   *
   * @returns {void}
   *
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Opens the login modal.
   *
   * @returns {void}
   */
  clickLogin(): void {
    this.dialog.loadComponent(LoginFormComponent, {}, true);
    this.dialog.toggleDialog();
  }
}
