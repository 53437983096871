import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import * as SearchSaleActions from '~/app/states/main/sales/components/search-sales/states/search-sales.actions';
import { AllSalesState } from '~/app/states/main/sales/components/search-sales/states/search-sales.state';
import { SalesQuickNavService } from '~/app/states/main/sales/services/sales-quick-nav.service';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SearchSaleEffects` manages the side effects for sales search-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales search, such as loading sales based on filters.
 * It performs operations that involve checking permissions, making HTTP requests to fetch sales data, and handling HTTP errors.
 *
 * The effects in this class include:
 * - `loadSalesByFilter$`: Loads sales based on provided filters. It listens for the `loadSalesByFilter` action,
 *   checks for permissions, makes an HTTP request to fetch sales, and dispatches a success or failure action based on the result.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SearchSaleEffects {
  /**
   * Effect to load all sales. It listens for the `loadAllSales` action,
   * checks for permissions, and makes an HTTP request to fetch sales data. Upon successful fetch,
   * it dispatches a success action with the fetched sales. If there is an error, it dispatches a failure action.
   */
  loadAllSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchSaleActions.loadAllSales),
      checkPermission(SearchSaleActions.searchSalesUnauthorized),
      mergeMap(() => {
        return this.http
          .post<AllSalesState>(`${environment.apiUrl}/v1/sales/search`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sales => {
              this.salesQuickNavService.loadProfilesUUIDs(
                sales.data.map(({ uuid }) => uuid)
              );
              return SearchSaleActions.loadAllSalesSuccess({ sales });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SearchSaleActions.loadAllSalesFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to load sales based on filters. It listens for the `loadSalesByFilter` action,
   * checks for permissions, and makes an HTTP request to fetch sales data. Upon successful fetch,
   * it dispatches a success action with the fetched sales. If there is an error, it dispatches a failure action.
   */
  loadSalesByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchSaleActions.loadSalesByFilter),
      checkPermission(SearchSaleActions.searchSalesUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .post<AllSalesState>(
            `${environment.apiUrl}/v1/sales/search`,
            filters,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(sales => {
              this.salesQuickNavService.loadProfilesUUIDs(
                sales.data.map(({ uuid }) => uuid)
              );
              return SearchSaleActions.loadSalesByFilterSuccess({ sales });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SearchSaleActions.loadSalesByFilterFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load sales tags. It listens for the `loadSearchSalesTags` action,
   * checks for permissions, and makes an HTTP GET request to fetch the sales tags.
   * Upon successful fetch, it dispatches a success action with the fetched sales tags.
   */
  loadSearchSalesTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchSaleActions.loadSearchSalesTags),
      checkPermission(SearchSaleActions.searchSalesUnauthorized),
      mergeMap(() =>
        this.http
          .get<Tag[]>(`${environment.apiUrl}/v1/sales/tags`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesTags =>
              SearchSaleActions.loadSearchSalesTagsSuccess({
                salesTags,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SearchSaleActions.loadSearchSalesTagsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to export all sales by filter criteria. It listens for the `exportAllSales` action, checks permissions,
   * and fetches data from the server based on provided filter criteria. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  exportAllSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchSaleActions.exportAllSales),
      mergeMap(({ filters }) => {
        const options = {
          headers: {
            accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob' as 'json', // 'json' here is a trick to avoid TypeScript error
          withCredentials: true,
        };

        return this.http
          .post<Blob>(`${environment.apiUrl}/v1/sales/export`, filters, options)
          .pipe(
            take(1),
            map((exportedData: Blob) => {
              // Create a download link and trigger the download
              const downloadLink = document.createElement('a');
              const url = window.URL.createObjectURL(exportedData);
              downloadLink.href = url;
              downloadLink.download = 'export-sales.xlsx';
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(url);
              return SearchSaleActions.exportAllSalesSuccess();
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SearchSaleActions.exportAllSalesFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Constructor for `SearchSaleEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {SidenavFormService} sidebarService Injected service for managing the sidebar form.
   * @param {SalesQuickNavService} salesQuickNavService The service to handle quick navigation between sales.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    public sidebarService: SidenavFormService,
    private salesQuickNavService: SalesQuickNavService
  ) {}
}
