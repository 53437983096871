import { createAction, props } from '@ngrx/store';
import { User } from '~/app/shared/interfaces/auth/user.interface';

export const loadHomeUsersAction = createAction(
  '[Home Users] Load Home Users',
  props<{ name?: string }>()
);
export const loadHomeUsersActionSuccess = createAction(
  '[Home Users] Load Home Users Success',
  props<{ users: User[] }>()
);
export const loadHomeUsersActionFailure = createAction(
  '[Home Users] Load Home Users Failure',
  props<{ error: string }>()
);

export const homeUsersUnauthorized = createAction(
  '[Home Users] Unauthorized',
  props<{ error: string }>()
);
