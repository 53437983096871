import { KanbanTask } from '~/app/shared/interfaces/kanban/kanban-task/kanban-task.interface';

/**
 * Represents the state of Kanban Tasks.
 *
 * @interface KanbanTasksState
 */
export interface KanbanTasksState {
  /**
   * All the Tasks related to the Kanban Column
   *
   * @type {KanbanTask[]}
   */
  tasks: KanbanTask[];

  /**
   * Indicates if the Kanban Tasks are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban Tasks.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of Kanban Tasks.
 *
 * @type {KanbanTasksState}
 */
export const initialKanbanTasksState: KanbanTasksState = {
  tasks: [],
  loading: false,
  error: null,
};
