import { createSelector } from '@ngrx/store';
import { selectSharedState } from '~/app/shared/shared.selector';

export const selectDataState = createSelector(
  selectSharedState,
  state => state.data
);

export const selectCandidateStatuses = createSelector(
  selectDataState,
  state => state.candidateStatuses
);

export const selectCandidateTags = createSelector(
  selectDataState,
  state => state.candidateTags
);

export const selectSalesStatuses = createSelector(
  selectDataState,
  state => state.salesStatuses
);

export const selectNoteCategories = createSelector(
  selectDataState,
  state => state.noteCategories
);

export const selectCategoriesWithSectors = createSelector(
  selectDataState,
  state => state.categoriesWithSectors
);

export const selectOffices = createSelector(
  selectDataState,
  state => state.offices
);

export const selectIndustries = createSelector(
  selectDataState,
  state => state.industries
);

export const selectCompanies = createSelector(
  selectDataState,
  state => state.companies
);

export const selectCompanyReferences = createSelector(
  selectDataState,
  state => state.companyReferences
);

export const selectTeams = createSelector(
  selectDataState,
  state => state.teams
);

export const selectUsers = createSelector(
  selectDataState,
  state => state.users
);

export const selectTimelines = createSelector(
  selectDataState,
  state => state.timelines
);
