<div
  class="flex align-items-center border-gray-200 flex justify-content-between p-4">
  <div class="flex align-items-center gap-3-5">
    <a class="flex mobile-display cursor-pointer">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="30" />
    </a>
    <p-breadcrumb
      styleClass="border-0 ml-5"
      class="max-w-full desktop-display"
      [model]="(urlSegments$ | async)!">
      <ng-template pTemplate="item" let-item let-i="index" let-last="last">
        <ng-container *ngIf="item.route; else elseBlock">
          <a [routerLink]="item.route" class="p-menuitem-link">
            <p
              [ngClass]="{ 'last-breadcrumb-item': last }"
              class="text-2xl color-dark-100 font-semibold">
              {{ item.label }}
            </p>
          </a>
        </ng-container>
        <ng-template #elseBlock>
          <a
            [href]="item.url"
            class="no-underline"
            [ngClass]="{ 'last-breadcrumb-item': last }">
            <span class="color-dark-100">{{ item.label | lineClip: 20 }}</span>
          </a>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="separator"> / </ng-template>
    </p-breadcrumb>
  </div>

  <div>
    <ng-container *ngIf="(user$ | async)?.user as user; else notConnected">
      <a
        i18n-aria-label="@@userMenuAriaLabel"
        aria-label="User menu"
        class="cursor-pointer"
        (click)="menu.toggle($event)">
        <ice-avatar [avatarContent]="user"></ice-avatar>
      </a>
    </ng-container>
    <ng-template #notConnected>
      <a
        i18n-aria-label="@@userMenuAriaLabel"
        aria-label="User menu"
        class="cursor-pointer"
        (click)="menu.toggle($event)">
        <p-avatar size="large" shape="circle" icon="pi pi-user"></p-avatar>
      </a>
    </ng-template>
    <p-menu
      #menu
      [model]="items"
      [popup]="true"
      appendTo="body"
      i18n-appendTo
      i18n-aria-label="@@userOptionsAriaLabel"
      aria-label="User options">
      <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="!item.customContent">
          <a
            (click)="item.action ? item.action('') : null"
            class="p-menuitem-link flex justify-content-between align-items-center p-3">
            <div>
              <span [class]="item.icon"></span>
              <span> {{ item.label }}</span>
            </div>
            <div>
              <span *ngIf="item.shortcut" [class]="item.shortcutClass">{{
                item.shortcut
              }}</span>
              <p-badge
                *ngIf="item.badge"
                [value]="item.badge"
                [severity]="item.badgeSeverity"></p-badge>
            </div>
          </a>
        </ng-container>
        <ng-container *ngIf="item.customContent">
          <!-- Layout for the theme switcher -->
          <div
            class="flex align-items-center gap-3 p-3"
            (click)="$event.stopPropagation()">
            <i
              class="pi pi-sun"
              *ngIf="!isLightTheme"
              style="margin-bottom: 5px"></i>
            <i
              class="pi pi-moon"
              *ngIf="isLightTheme"
              style="margin-bottom: 5px"></i>
            <p-inputSwitch
              [(ngModel)]="isLightTheme"
              (onChange)="onThemeToggle()"></p-inputSwitch>
          </div>
        </ng-container>
      </ng-template>
    </p-menu>
  </div>
</div>
