import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AllSearchClientCompanyFilters } from '~/app/shared/interfaces/client-company/all-search-client-company-filters.interface';
import * as ClientCompanyActions from '~/app/states/main/crm/profile/states/client-companies/profile-client-companies.actions';
import { ProfileState } from '~/app/states/main/profile/profile.state';

/**
 * Service for managing client companies.
 * This service provides methods to load, create, update, and delete client companies,
 * utilizing the NgRx store for state management and validation for data integrity.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileClientCompanyService {
  /**
   * Constructs an instance of the class.
   *
   * @param {Store<ProfileState>} store - Injectable store for managing CRM state.
   */
  constructor(private store: Store<ProfileState>) {}

  /**
   * Loads all client companies with the given filters.
   *
   * @param {AllSearchClientCompanyFilters} filters - The filters to apply when loading client companies.
   * @returns {void}
   */
  loadAllClientCompanies(filters: AllSearchClientCompanyFilters): void {
    this.store.dispatch(
      ClientCompanyActions.loadAllClientCompanies({ filters })
    );
  }
}
