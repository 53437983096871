import { createReducer, on } from '@ngrx/store';
import * as ProfileSectorJobsActions from '~/app/states/main/crm/profile/states/profile-sectors/profile-sectors.actions';
import { initialProfileSectorsState } from '~/app/states/main/crm/profile/states/profile-sectors/profile-sectors.state';

export const profileSectorJobReducer = createReducer(
  initialProfileSectorsState,

  on(ProfileSectorJobsActions.loadProfileSectors, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(
    ProfileSectorJobsActions.loadProfileSectorsSuccess,
    (state, { profileSectors }) => ({
      ...state,
      profileSectorJobs: profileSectors,
      loading: false,
    })
  ),

  on(
    ProfileSectorJobsActions.loadProfileSectorsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileSectorJobsActions.unassignProfileSector, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileSectorJobsActions.unassignProfileSectorSuccess,
    (state, { profileSectors }) => ({
      ...state,
      profileSectorJobs: profileSectors,
      loading: false,
    })
  ),
  on(
    ProfileSectorJobsActions.unassignProfileSectorFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileSectorJobsActions.assignProfileSectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileSectorJobsActions.assignProfileSectorJobSuccess,
    (state, { profileSectors }) => ({
      ...state,
      profileSectorJobs: profileSectors,
      loading: false,
    })
  ),
  on(
    ProfileSectorJobsActions.assignProfileSectorJobFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileSectorJobsActions.profileSectorsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
