import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';

/**
 * Represents the state for candidates in a Kanban board within the CRM system.
 *
 * @interface KanbanCandidateState
 */
export interface KanbanCandidateState {
  /**
   * The list of candidates displayed in the Kanban board.
   *
   * @type {Candidate[]}
   */
  candidates: Candidate[];

  /**
   * Indicates if the candidates are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from loading the candidates.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for candidates in the Kanban board.
 *
 * @constant {KanbanCandidateState} initialKanbanCandidateState
 * @description Provides a baseline definition for the Kanban candidates context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialKanbanCandidateState: KanbanCandidateState = {
  candidates: [],
  loading: false,
  error: null,
};
