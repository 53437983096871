import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { VoidFunction } from '~/app/shared/types/function/void-function.type';

/**
 * A custom radio button component that integrates with Angular forms and supports permission-based UI adjustments.
 * It allows the rendering of a radio button group with options passed as inputs. The component also handles
 * custom behavior for authorization purposes, such as disabling the control or hiding it based on user permissions.
 *
 * @Component decorator defines the Angular component metadata.
 * @selector 'ice-radio' - The selector used to use this component in HTML templates.
 * @templateUrl './ice-radio.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./ice-radio.component.scss'] - The styles for this component.
 * @providers NG_VALUE_ACCESSOR - Provides a token that allows the component to register as a ControlValueAccessor,
 * enabling it to interact with Angular forms.
 */
@Component({
  selector: 'ice-radio',
  templateUrl: './ice-radio.component.html',
  styleUrls: ['./ice-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceRadioComponent),
      multi: true,
    },
  ],
})
export class IceRadioComponent implements AfterViewInit {
  /**
   * Options for the radio button, each having a value and label.
   * @type {Array<{value: string, label: string}>}
   */
  @Input({ required: true }) options!: {
    value: string;
    label: string;
  }[];

  /**
   * The FormControl that will be bound to the input element of the radio button.
   * @type {FormControl}
   */
  @Input({ required: true }) formControl!: FormControl;

  /**
   * The name of the form control within the form group, used for error message handling.
   * @type {string}
   */
  @Input() formControlName!: string;

  /**
   * Indicates whether the radio button is required.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Current error message to display (if any).
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Flag to indicate if the form has been submitted; used for error state handling.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * Rule identifier for permission checks.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Defines the behavior when the user does not have the required permission.
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Reference to the native radio button element.
   * @type {ElementRef}
   */
  @ViewChild('iceRadioButton') element!: ElementRef;

  /**
   * The internal value of the radio button.
   * @type {string}
   */
  public value!: string;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructor for a component that requires access to `PermissionsService`.
   * This service is used to check and enforce user permissions within the component.
   *
   * @param {PermissionsService} permissionService - The service responsible for checking and validating permissions
   * based on user roles and rules. This service is injected into the component to allow for permission checks
   * throughout the component's lifecycle and operations.
   */
  constructor(private permissionService: PermissionsService) {}

  /**
   * AfterViewInit lifecycle hook to handle permission-based UI adjustments.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.DISABLE:
                this.disableElement();
                break;
              case PermissionBehaviourEnum.NONE:
              default:
                this.hideElement();
            }
          }
        });
  }

  /**
   * Registers a function to be called when the input value changes.
   * @param {AnyVoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Triggers the touch event callback when the radio button gains focus.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Triggers the touch event callback when the radio button loses focus.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Method to be called when the radio button is selected, updating the internal value.
   * @param {Event} event - The DOM event triggered when the radio button is selected.
   * @return {void}
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(input.value);
  }

  /**
   * Receives and handles an error message from form validation.
   * @param {string | null} message - The error message to be displayed.
   * @return {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }

  /**
   * Hides the radio button element by applying a CSS style.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the form control associated with the radio button.
   * @return {void}
   */
  private disableElement(): void {
    this.formControl.disable();
  }
}
