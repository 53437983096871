import { ProfileFile } from '~/app/shared/interfaces/crm/profile/profile-file.interface';

/**
 * Represents the state for profile files.
 *
 * @interface ProfileFileState
 */
export interface ProfileFileState {
  /**
   * The list of profile files, or null if not loaded.
   *
   * @type {ProfileFile[] | null}
   */
  files: ProfileFile[] | null;

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for profile files within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileFileState} initialProfileFileState
 * @description Provides a baseline definition for the profile files context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileFileState: ProfileFileState = {
  files: null,
  loading: false,
  error: null,
};
