import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoginService } from '~/app/auth/services/login.service';
import * as LoginActions from '~/app/auth/state/login/login.actions';
import * as AuthActions from '~/app/auth/auth.actions';
import { EnumService } from '~/app/core/services/enum.service';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { SecureUser } from '~/app/shared/interfaces/auth/secure-user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { registerAction } from '~/app/shared/operators/register-action';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `LoginEffects` orchestrates the side effects related to user authentication within the application.
 * Leveraging Angular's NgRx effects library, it listens for actions related to the login process,
 * performing tasks such as issuing login and logout requests to the server, handling the authentication flow,
 * including conditions where two-factor authentication is required, and managing redirections after successful
 * or failed authentication attempts.
 *
 * Effects included in this class:
 * - Processing user login requests and responding to the outcome.
 * - Handling two-factor authentication setup and verification.
 * - Executing logout procedures and cleaning up user session data.
 * - Refreshing authentication tokens to maintain user sessions.
 * - Navigating the user to appropriate routes based on authentication actions and results.
 *
 * @Injectable Decorator that marks the class as one that participates in the dependency injection system.
 */
@Injectable()
export class LoginEffects {
  /**
   * Effect to handle user login. It triggers on the login action, checks user permissions,
   * and attempts to log the user in via the API. Depending on the response, it either triggers a two-factor
   * authentication action or a successful login action.
   */
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.login),
      registerAction(),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ loginData }) =>
        this.http
          .post<
            AuthUser | SecureUser
          >(`${environment.apiUrl}/v1/auth/login`, loginData, { withCredentials: true })
          .pipe(
            take(1),
            map(userData => {
              return userData.hasOwnProperty('secureLogin')
                ? LoginActions.loginTwoFactorAuthentication({
                    secureLoginToken: userData.token,
                  })
                : LoginActions.loginSuccess({ userData: userData as AuthUser });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.loginFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect the user to the two-factor authentication page upon successful login
   * that requires secondary verification.
   */
  loginTwoFactorAuthenticationRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.loginTwoFactorAuthentication),
        tap(() => this.router.navigate(['/login-secure']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle secure login after initial login requires two-factor authentication.
   * This effect handles the submission of two-factor authentication data to the API.
   */
  secureLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.secureLogin),
      registerAction(),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ loginSecureData }) =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/auth/login-secure`,
            loginSecureData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => LoginActions.loginSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.loginFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect the user to the main CRM page upon successful login.
   */
  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.loginSuccess),
        tap(() => {
          this.enumService.loadCecrlTypes();
          this.enumService.loadOfferStatusTypes();
          this.enumService.loadOfferUnitTypes();
          this.enumService.loadOfferVisibilityTypes();
          this.enumService.loadProfileExperienceTypes();
          this.enumService.loadProfileFileTypes();
          this.enumService.loadProfileTypes();
          this.enumService.loadProfileTimeUnits();
          this.enumService.loadProposalStatusTypes();
          this.enumService.loadReminderTypes();
          this.enumService.loadSaleInformationTypes();
          this.enumService.loadSocialTypes();
          this.enumService.loadGenderTypes();
          this.enumService.loadSkillExperiencesTypes();
          void this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle user logout. It sends a logout request to the API and handles
   * the response to clear user session data.
   */
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.logout),
      registerAction(),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(() =>
        this.http
          .post<void>(
            `${environment.apiUrl}/v1/auth/logout`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => LoginActions.logoutSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.logoutFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect the user to the login page after successful logout.
   */
  logoutRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.logoutSuccess),
        tap(() => {
          this.loginService.stopRefreshTokenTimer();
          void this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle token refresh requests. It sends a request to refresh the authentication token
   * and dispatches success or failure actions based on the response.
   */
  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      registerAction(),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(() =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/auth/refreshToken`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => AuthActions.refreshTokenSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AuthActions.refreshTokenFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructs the `LoginEffects` instance.
   *
   * @param {LoginService} loginService - The service used for managing login operations and handling token refresh logic.
   * @param {Actions} actions$ - An observable of actions. It serves as the source of dispatched actions for the effects.
   * @param {HttpClient} http - The HTTP client service for making API calls to the backend.
   * @param {Router} router - The Angular Router service used for navigating between routes.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors and providing user feedback.
   * @param {EnumService} enumService - Service for ahandling enums.
   */
  constructor(
    private loginService: LoginService,
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private httpErrors: HttpErrorsService,
    private enumService: EnumService
  ) {}
}
