import { Proposal } from '~/app/shared/interfaces/jobhub/proposal/proposal.interface';

/**
 * Represents the state of proposal tasks.
 *
 * @interface ProposalHistoryState
 */
export interface ProposalHistoryState {
  /**
   * The limit of proposals per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * Indicates if there are previous pages.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Indicates if there are next pages.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * The total count of proposals.
   *
   * @type {number}
   */
  count: number;

  /**
   * The list of proposals.
   *
   * @type {Proposal[]}
   */
  data: Proposal[];

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for proposal tasks.
 *
 * @type {ProposalHistoryState}
 */
export const initialProposalHistoryState: ProposalHistoryState = {
  limit: 10,
  page: 0,
  hasPrevious: false,
  hasNext: false,
  count: 0,
  data: [],
  loading: false,
  error: null,
};
