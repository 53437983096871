import { Component } from '@angular/core';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * `RegisterSuccessComponent` is a presentation component designed to display a success message post-registration.
 * It informs the user that the registration process is complete and instructs them to check their email to activate their account.
 * This component is simple and stateless, utilizing HTML and SCSS for layout and styling.
 *
 * @Component decorator defines the Angular metadata for the component.
 * @selector 'app-register-success' - The selector used to embed this component into other components or templates.
 * @templateUrl './register-success.component.html' - Contains the layout and elements displaying the success message:
 *   "Thank you for registering. Please check your email to activate your account."
 * @styleUrls ['./register-success.component.scss'] - Defines specific styling for the registration success page, including fonts, colors, and layout.
 *
 * @example
 * Example of usage:
 * This component is typically used in the user registration flow, displayed upon successful completion of the registration process.
 */
@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent {
  /**
   * Constructor for the RegisterSuccessComponent.
   * @param {ThemeService} theme - Service responsible for handling theme changes within the app.
   * The service is made public to access its properties and methods from the component's template,
   * allowing for dynamic styling based on the current theme.
   */
  constructor(public theme: ThemeService) {}
}
