import { createAction, props } from '@ngrx/store';
import { AllSearchClientCompaniesState } from '~/app/main/client-company/states/search-client-company/search-client-company.state';
import { AllSearchClientCompanyFilters } from '~/app/shared/interfaces/client-company/all-search-client-company-filters.interface';
import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';

export const loadAllClientCompanies = createAction(
  '[Client Company] Load All Client Companies',
  props<{ filters: AllSearchClientCompanyFilters }>()
);
export const loadAllClientCompaniesSuccess = createAction(
  '[Client Company] Load All Client Companies Success',
  props<{ allClientCompanies: AllSearchClientCompaniesState }>()
);
export const loadAllClientCompaniesFailure = createAction(
  '[Client Company] Load All Client Companies Failure',
  props<{ error: string }>()
);

export const createClientCompany = createAction(
  '[Client Company] Create Client Company',
  props<{ clientCompanyData: FormData }>()
);
export const createClientCompanySuccess = createAction(
  '[Client Company] Create Client Company Success',
  props<{ uuid: string; clientCompany: ClientCompany }>()
);
export const createClientCompanyFailure = createAction(
  '[Client Company] Create Client Company Failure',
  props<{ error: string }>()
);

export const clientCompanyUnauthorized = createAction(
  '[Client Company] Unauthorized',
  props<{ error: string }>()
);
