import { createSelector } from '@ngrx/store';
import { selectProfileState } from '~/app/states/main/crm/profile/profile.selector';

export const profileState = createSelector(
  selectProfileState,
  state => state.profile
);

export const selectProfile = createSelector(
  profileState,
  state => state.profile
);

export const selectProfileTags = createSelector(
  selectProfile,
  state => state?.tags ?? []
);

export const selectProfileLoadingState = createSelector(
  profileState,
  state => state.loading
);
