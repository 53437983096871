<section class="container px-3-5 flex flex-column mx-auto">
  <div class="storytelling-one flex flex-column text-center mx-auto">
    <h4 class="display-h4 m-0 color-dark-100">
      <span class="color-dark-70">Revolutionizing</span> Job Search &
      Recruitment <span class="color-dark-70">for Everyone</span>
    </h4>
    <h6 class="display-h6 color-dark-50">
      Finding the right job or talent shouldn’t be this hard.
      <span class="gradiant">IceBerry</span> uses AI to simplify and optimize
      job searching, recruitment, and consulting.
    </h6>
  </div>
  <div
    class="storytelling-two flex flex-column lg:flex-row justify-content-between align-items-center">
    <div class="scale-glow p-2 lg:p-8">
      <img
        alt="mockup iceberry"
        src="assets/landing-pages/mockups/mockup-jobhub.png"
        width="100%" />
    </div>
    <h4 class="display-h4 line-height-4 color-dark-70 text-center lg:text-left">
      We connect you with the right talent <br />
      <span class="line-height-4">the first time,</span> <br />
      <span class="gradiant"> every time.</span>
    </h4>
  </div>
</section>

<section class="progress-container mt-15rem">
  <img
    class="md:hidden image-custom"
    src="assets/landing-pages/ai.png"
    width="300" />
  <img
    class="hidden md:block image-custom"
    src="assets/landing-pages/ai.png"
    width="500" />
  <h3
    class="hidden md:block display-h3 text-center color-dark-100 text-on-image">
    The perfect match is out there <br /><span class="gradiant"
      >don’t waste time</span
    >
    on the wrong ones.
  </h3>
  <h5
    class="md:hidden display-h5 text-center color-dark-100 small-text-on-image">
    The perfect match is out there <br /><span class="gradiant"
      >don’t waste time</span
    >
    on the wrong ones.
  </h5>
  <div>
    <h5 class="hidden lg:block progress-message display-h5 color-dark-100">
      {{ message }}
    </h5>
    <p class="lg:hidden text-lg mb-3 color-dark-100">{{ message }}</p>
    <div class="progress-bar-wrapper">
      <div class="progress-bar" [style.width.%]="progress"></div>
    </div>
    <div class="flex justify-content-end align-items-center">
      <p class="text-base lg:text-2xl color-dark-100">Ai is working</p>
      <i
        class="hidden lg:block ml-7-75 pi pi-spin pi-spinner-dotted"
        style="font-size: 2rem; color: var(--color-dark-100)"></i>
      <i
        class="lg:hidden ml-3-5 pi pi-spin pi-spinner-dotted"
        style="font-size: 1rem; color: var(--color-dark-100)"></i>
    </div>
  </div>
</section>

<!-- Section affichée une fois que la progress bar est complétée -->
<section *ngIf="isCompleted" class="completed-container">
  <h2 class="hidden lg:block display-h2 m-0 color-dark-100" @fadeInOut>
    35 jobs found
  </h2>
  <h3 class="lg:hidden display-h3 m-0 color-dark-100" @fadeInOut>
    35 jobs found
  </h3>
  <p class="text-base lg:text-2xl color-dark-70" @fadeInOut>
    based on the given CV
  </p>
  <div class="p-1 border-round-2xl bg-gradiant mt-7-75 custom-big-button">
    <button
      @fadeInOut
      class="sticky top-0 border-round-2xl h-full w-full flex align-items-center justify-content-between px-7"
      href="#">
      <h2
        @fadeInOut
        class="hidden lg:block display-h2 m-0 color-light-100-forever">
        Get Started
      </h2>
      <h5 @fadeInOut class="lg:hidden display-h5 m-0 color-light-100-forever">
        Get Started
      </h5>
      <i
        @fadeInOut
        class="hidden lg:block pi pi-arrow-right"
        style="font-size: 3.5rem"></i>
      <i
        @fadeInOut
        class="lg:hidden pi pi-arrow-right"
        style="font-size: 2rem"></i>
    </button>
  </div>
</section>
<!-- Section affichée une fois que la progress bar est complétée -->
<div *ngIf="!isCompleted" class="completed-container"></div>
