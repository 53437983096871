import { Component, OnInit } from '@angular/core';

/**
 * Represents the landing page component of the application.
 * This component serves as the main entry point for visitors accessing the landing page of the application.
 * This component is a template.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-landing' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './landing.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./landing.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  /**
   * Boolean flag that indicates whether the current user is a company.
   */
  isCompany = false;

  /**
   * String that represents the name of the company.
   */
  companyName = '';

  /**
   * Initializes the component.
   * This lifecycle hook is called after Angular has initialized all data-bound properties of a directive.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    if (
      window.location.hostname.split('.')[0] === 'localhost' ||
      window.location.hostname.split('.')[0] === 'ice-berry'
    ) {
      this.isCompany = true;
      this.companyName = window.location.hostname.split('.')[0];
    }
  }
}
