<div
  class="surface-card p-4 shadow-2 border-round min-w-20rem max-w-30rem bg-cover">
  <app-section-heading
    title="Office Information"
    i18n-title
    subtitle="Please enter your office information"
    i18n-subtitle></app-section-heading>

  <form [formGroup]="officeForm">
    <!-- Office Name Field -->
    <div class="grid">
      <ice-input
        class="col-12 sm:col-8"
        [label]="'Office Name'"
        [placeholder]="'Office Name'"
        [formControlName]="'name'"
        [formControl]="name"
        [required]="true">
      </ice-input>
      <ice-dropdown
        [isSubmitted]="submitted"
        class="col-12 sm:col-4"
        [options]="countryCodes"
        [label]="'Country'"
        [placeholder]="'Country'"
        [formControlName]="'country'"
        [formControl]="country"
        [filter]="true">
      </ice-dropdown>
      <ice-input
        class="col-12"
        [isSubmitted]="submitted"
        [label]="'Street Address'"
        [placeholder]="'Street Address'"
        [formControlName]="'streetAddress'"
        [formControl]="streetAddress">
      </ice-input>

      <ice-input
        [isSubmitted]="submitted"
        class="col-12 xs:col-4"
        [label]="'Postal Code'"
        [placeholder]="'Postal Code'"
        [formControlName]="'postalCode'"
        [formControl]="postalCode">
      </ice-input>
      <ice-input
        [isSubmitted]="submitted"
        class="col-12 xs:col-8"
        [label]="'City'"
        [placeholder]="'City'"
        [formControlName]="'city'"
        [formControl]="city">
      </ice-input>
    </div>
  </form>
  <div class="flex justify-content-between mt-4">
    <ice-button
      label="Previous"
      i18n-label
      [text]="true"
      icon="pi pi-arrow-left"
      i18n-icon
      routerLink="/register/company"
      iconPos="left"
      i18n-iconPos></ice-button>
    <ice-button
      label="Next"
      i18n-label
      [text]="true"
      icon="pi pi-arrow-right"
      i18n-icon
      iconPos="right"
      i18n-iconPos
      (onClick)="onNext()"></ice-button>
  </div>
</div>
