import { createReducer, on } from '@ngrx/store';
import * as SalesLanguagesActions from '~/app/states/main/sales/profile/states/sales-languages/sales-languages.actions';
import { initialSalesLanguagesState } from '~/app/states/main/sales/profile/states/sales-languages/sales-languages.state';

export const salesLanguagesReducer = createReducer(
  initialSalesLanguagesState,

  on(SalesLanguagesActions.loadSalesLanguages, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesLanguagesActions.loadSalesLanguagesSuccess,
    (state, { salesLanguages }) => ({
      ...state,
      salesLanguages,
      loading: false,
    })
  ),
  on(SalesLanguagesActions.loadSalesLanguagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesLanguagesActions.createSalesLanguage, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesLanguagesActions.createSalesLanguageSuccess,
    (state, { salesLanguage }) => ({
      ...state,
      salesLanguages: [...state.salesLanguages, salesLanguage],
      loading: false,
    })
  ),
  on(SalesLanguagesActions.createSalesLanguageFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesLanguagesActions.deleteSalesLanguage, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesLanguagesActions.deleteSalesLanguageSuccess, (state, { uuid }) => ({
    ...state,
    salesLanguages: state.salesLanguages.filter(
      language => language.uuid !== uuid
    ),
    loading: false,
  })),
  on(SalesLanguagesActions.deleteSalesLanguageFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesLanguagesActions.salesLanguagesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
