import { HomeSalesReminder } from '~/app/shared/interfaces/sales/home/home-sales-reminders.interface';

/**
 * Represents the state of home sales reminders, including pagination details, loading status, and error information.
 *
 * @interface HomeSalesRemindersState
 */
export interface HomeSalesRemindersState {
  /**
   * The total number of reminders.
   *
   * @type {number}
   * @memberof HomeSalesRemindersState
   */
  count: number;

  /**
   * The number of reminders to display per page.
   *
   * @type {number}
   * @memberof HomeSalesRemindersState
   */
  limit: number;

  /**
   * The current page number.
   *
   * @type {number}
   * @memberof HomeSalesRemindersState
   */
  page: number;

  /**
   * Indicates if there is a next page available.
   *
   * @type {boolean}
   * @memberof HomeSalesRemindersState
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page available.
   *
   * @type {boolean}
   * @memberof HomeSalesRemindersState
   */
  hasPrevious: boolean;

  /**
   * An array of home sales reminders.
   *
   * @type {HomeSalesReminder[]}
   * @memberof HomeSalesRemindersState
   */
  data: HomeSalesReminder[];

  /**
   * Indicates if the reminders are currently being loaded.
   *
   * @type {boolean}
   * @memberof HomeSalesRemindersState
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the reminders.
   *
   * @type {string | null}
   * @memberof HomeSalesRemindersState
   */
  error: string | null;

  /**
   * The current date, which the user choose.
   * @type {string}
   */
  currentDate: string;
}

/**
 * The initial state of home sales reminders within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {HomeSalesRemindersState} initialHomeSalesRemindersState
 * @description Provides a baseline definition for the home sales reminders context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialHomeSalesRemindersState: HomeSalesRemindersState = {
  count: 0,
  limit: 10,
  page: 0,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
  currentDate: '',
};
