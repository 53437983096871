import { Component, ViewChild, OnInit } from '@angular/core';
import { SidebarHeaderDirective } from '~/app/shared/directives/sidebar-header.directive';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';

/**
 * SidebarHeaderComponent is responsible for rendering the header of the sidebar.
 * It manages dynamic content loading into the sidebar and handles responsive behavior based on screen size.
 *
 * @selector 'app-sidebar-header'
 * @templateUrl './sidebar-header.component.html'
 * @styleUrls ['./sidebar-header.component.scss']
 */
@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent implements OnInit {
  /**
   * Accesses the SidebarHeaderDirective to manage dynamic content loading into the sidebar.
   * @type {SidebarHeaderDirective}
   */
  @ViewChild(SidebarHeaderDirective, { static: true })
  sidebarHeader!: SidebarHeaderDirective;

  /**
   * Constructs the SidebarHeaderComponent with necessary dependencies.
   *
   * @param {SidenavFormService} sidebarService - Service used to manage the state and content of the sidebar including titles, subtitles, and visibility.
   */
  constructor(public sidebarService: SidenavFormService) {}

  /**
   * Fetches the title from the sidebar service, dynamically reflecting changes.
   * @returns {string} The current title of the sidebar.
   */
  public get title(): string {
    return this.sidebarService.title;
  }

  /**
   * OnInit lifecycle hook to set up the sidebar's content and initiate responsiveness handling.
   * @returns {void}
   */
  ngOnInit(): void {
    this.sidebarService.setViewHeaderContainerRef(
      this.sidebarHeader.viewContainerRef
    );
    this.sidebarService.applyHeader();
  }
}
