import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to calculate the time difference between the current time and a given date.
 */
@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  /**
   * Transforms the given date string into a human-readable time difference.
   * @param {string} createdAt - The date string to transform.
   * @returns {{text: string, isNew: boolean}} - The transformed time difference text.
   */
  transform(createdAt: string): { text: string; isNew: boolean } {
    const createdAtDate = new Date(createdAt);
    const diffMs = Math.abs(createdAtDate.getTime() - new Date().getTime());

    // Check for 'new' status first
    const diffDays = this.calculateDifference(diffMs, 24 * 60 * 60 * 1000);
    if (diffDays < 15) {
      return { text: `${diffDays} day${diffDays > 1 ? 's' : ''}`, isNew: true };
    }

    return (
      this.getTimeDifferenceText(diffMs, [
        { unit: 'year', ms: 365 * 24 * 60 * 60 * 1000 },
        { unit: 'month', ms: 30 * 24 * 60 * 60 * 1000 },
        { unit: 'day', ms: 24 * 60 * 60 * 1000 },
        { unit: 'hour', ms: 60 * 60 * 1000 },
        { unit: 'min', ms: 60 * 1000 },
      ]) ?? { text: 'less than a minute', isNew: false }
    );
  }

  /**
   * Calculates the difference between two dates in a given unit.
   * @param {number} diffMs - The difference in milliseconds.
   * @param {number} unitMs - The unit in milliseconds to calculate the difference.
   * @returns {number} - The difference between the two dates in the given unit.
   */
  private calculateDifference(diffMs: number, unitMs: number): number {
    return Math.floor(diffMs / unitMs);
  }

  /**
   * Gets the time difference text for the given units.
   * @param {number} diffMs - The difference in milliseconds.
   * @param {{ unit: string, ms: number }[]} units - The units to calculate the difference.
   * @returns {{ text: string, isNew: boolean } | null} - The time difference text or null if not found.
   */
  private getTimeDifferenceText(
    diffMs: number,
    units: { unit: string; ms: number }[]
  ): { text: string; isNew: boolean } | null {
    for (const { unit, ms } of units) {
      const diff = this.calculateDifference(diffMs, ms);
      if (diff > 0) {
        return {
          text: `${diff} ${unit}${diff > 1 ? 's' : ''}`,
          isNew: false,
        };
      }
    }
    return null;
  }
}
