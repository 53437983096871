/**
 * Enum for defining days of the week.
 *
 * This enum is used to represent the days of the week in various contexts, such as scheduling, calendars, or time-related operations. Each day is represented by a numerical value starting from Monday.
 *
 * @enum {number}
 * @property {number} MONDAY - Represents Monday, typically considered the first day of the work or school week in many cultures.
 * @property {number} TUESDAY - Represents Tuesday, the second day of the traditional work or school week.
 * @property {number} WEDNESDAY - Represents Wednesday, often referred to as the midpoint or "hump day" of the work week.
 * @property {number} THURSDAY - Represents Thursday, the fourth day of the traditional work week.
 * @property {number} FRIDAY - Represents Friday, the last day of the work week in many cultures, often associated with the beginning of the weekend.
 * @property {number} SATURDAY - Represents Saturday, commonly considered the first day of the weekend, associated with leisure and rest.
 * @property {number} SUNDAY - Represents Sunday, typically considered a day of rest or religious observance in many cultures, often marking the end of the weekend.
 */
export enum DaysOfWeekEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}
