import { createReducer, on } from '@ngrx/store';
import * as SalesIndustryActions from '~/app/states/main/sales/profile/states/sales-industries/sales-industries.actions';
import { initialSalesIndustriesState } from '~/app/states/main/sales/profile/states/sales-industries/sales-industries.state';

export const salesIndustryReducer = createReducer(
  initialSalesIndustriesState,

  on(SalesIndustryActions.loadSalesIndustries, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesIndustryActions.loadSalesIndustriesSuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(SalesIndustryActions.loadSalesIndustriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesIndustryActions.assignSalesIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesIndustryActions.assignSalesIndustrySuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(SalesIndustryActions.assignSalesIndustryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesIndustryActions.unassignSalesIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesIndustryActions.unassignSalesIndustrySuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(SalesIndustryActions.unassignSalesIndustryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesIndustryActions.salesIndustriesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
