import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AiCreationProfileActions from '~/app/main/crm/ai-creation/states/profile/ai-creation-profile.actions';
import { CrmState } from '~/app/main/crm/crm.state';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Service for managing profile operations such as loading requests and updating progress.
 * This service is provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /**
   * Creates an instance of ProfileService.
   * @param {Store<CrmState>} store - The store to manage state.
   */
  constructor(private store: Store<CrmState>) {}

  /**
   * Loads a profile creation request with the provided status ID, office ID, team ID, and resume file.
   *
   * @param {string} statusId - The ID of the status.
   * @param {string} officeId - The ID of the office.
   * @param {string} teamId - The ID of the team.
   * @param {File} resume - The resume file to upload.
   * @returns {void}
   */
  loadRequest(
    statusId: string,
    officeId: string,
    teamId: string,
    resume: File
  ): void {
    const formData = new FormData();

    formData.append('statusId', statusId);
    formData.append('officeId', officeId);
    formData.append('teamId', teamId);
    formData.append('resume', resume);

    this.updateProgress({ progress: 0, content: 'Connecting' });
    this.store.dispatch(
      AiCreationProfileActions.loadAiCreationProfileRequest({ formData })
    );
  }

  /**
   * Updates the progress of a profile creation request.
   *
   * @param {Progress | null} progress - The progress information to update.
   * @returns {void}
   */
  updateProgress(progress: Progress | null): void {
    this.store.dispatch(
      AiCreationProfileActions.updateAdvancement({ progress })
    );
  }

  /**
   * Loads suggestions for statuses, offices, and teams.
   *
   * @returns {void}
   */
  loadSuggestions(): void {
    this.store.dispatch(AiCreationProfileActions.loadStatuses());
    this.store.dispatch(AiCreationProfileActions.loadOffices());
    this.store.dispatch(AiCreationProfileActions.loadTeams());
  }
}
