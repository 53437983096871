<section class="container flex testimonials mx-auto px-3-5">
  <div class="flex flex-column gap-3-5 mx-auto">
    <div class="flex flex-column mx-auto">
      <h4 class="display-h4 m-0">Public Cheers for Us!</h4>
      <p class="text-base">Find out how our users are spreading the word!</p>
    </div>
    <div class="flex gap-3-5">
      <div
        *ngFor="let list of isSmallScreen ? [testimonials[0]] : testimonials"
        class="flex flex-column gap-3-5">
        <div *ngFor="let testimonial of list" class="flex gap-3-5">
          <div
            class="flex flex-column gap-3-5 p-4 border-round-2-5xl border-1 border-dark-10 max-w-30rem">
            <div class="flex align-items-center justify-content-between">
              <div class="flex align-items-center gap-3-5">
                <img
                  src="{{
                    'assets/landing-pages/imaginary-users/' + testimonial.photo
                  }}"
                  alt="user avatar"
                  width="45"
                  height="45"
                  class="radius" />
                <div class="flex flex-column">
                  <p class="text-bold-base m-0">{{ testimonial.userName }}</p>
                  <p class="text-sm m-0">{{ testimonial.userQuality }}</p>
                </div>
              </div>
              <div class="flex gap-2">
                <i
                  *ngFor="let star of [].constructor(testimonial.stars)"
                  class="pi pi-star-fill"
                  style="color: var(--color-fancy-pink)"></i>
              </div>
            </div>
            <p class="text-base m-0 color-dark-70">{{ testimonial.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
