import { createSelector } from '@ngrx/store';
import { selectSalesProfileState } from '~/app/states/main/sales/profile/sales-profile.selector';

export const selectSalesQuickNav = createSelector(
  selectSalesProfileState,
  state => state.quickNav
);

export const selectSalesUUIDs = createSelector(
  selectSalesQuickNav,
  state => state.uuids
);
