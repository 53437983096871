import { Component, Input } from '@angular/core';

/**
 * InfoCardComponent is a reusable component that displays an informational card with a title and subtitle.
 *
 * @selector 'app-info-card'
 * @templateUrl './info-card.component.html'
 * @styleUrls ['./info-card.component.scss']
 */
@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss',
})
export class InfoCardComponent {
  /**
   * The title of the info card.
   * @type {string}
   */
  @Input() title = 'IceBerry';

  /**
   * The subtitle of the info card.
   * @type {string}
   */
  @Input() subtitle = 'What a CRM !';

  /**
   * The severity of the card it determines the color of the card
   * @type {'info' | 'warning'}
   */
  @Input() severity: 'info' | 'warning' = 'info';
}
