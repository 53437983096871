import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * Animation trigger for fading in an element.
 *
 * @const {AnimationTriggerMetadata} fadeInOutBounce
 * @transition Transition from false to true.
 * @style Initial style with opacity 0.
 * @animate Animates the element to opacity 1 over 300ms with ease-in timing.
 */
export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateY(20px)',
    })
  ),
  transition(':enter', [
    animate(
      '600ms ease-out',
      style({
        opacity: 1,
        transform: 'translateY(0)',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '600ms ease-in',
      style({
        opacity: 0,
        transform: 'translateY(20px)',
      })
    ),
  ]),
]);
