<div
  style="
    background: radial-gradient(
      69.84% 69.84% at 50% 100%,
      rgba(21, 101, 192, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    margin-top: 15em;
  ">
  <div
    class="flex flex-column m-auto gap-7-75 px-3-5 lg:px-8 mt-8"
    style="max-width: 90rem">
    <div
      class="flex flex-column gap-7-75 lg:flex-row justify-content-between w-full">
      <div class="flex flex-column gap-3-5 m-auto lg:m-0">
        <h4 class="display-h4 m-0">Get notified when we launch</h4>
        <p class="text-base m-0">
          Stay up to date with the latest news, announcements, and articles.
        </p>
      </div>
      <form
        [formGroup]="newsLetterForm"
        class="flex align-items-center gap-3-5 m-auto lg:m-0">
        <div class="px-2 border-1 border-dark-10 border-round-2xl">
          <input
            class="w-20rem h-3rem bg-transparent"
            type="email"
            formControlName="email"
            [formControl]="email"
            placeholder="Enter your email"
            (keydown.enter)="onSubmit()"
            i18n-placeholder />
        </div>
        <ice-button
          [ariaLabel]="'submit'"
          type="submit"
          [disabled]="!newsLetterForm.valid"
          (click)="onSubmit()"
          [icon]="'pi pi-send'"></ice-button>
      </form>
    </div>
    <footer
      class="flex flex-column lg:flex-row gap-3-5 lg:gap-7-75 justify-content-between w-full custom-margin">
      <div class="flex flex-column max-w-30rem m-auto lg:my-auto lg:mx-0">
        <h4 class="flex gap-3-5 align-items-center">
          <img
            [ngSrc]="
              'assets/icons/ice-berry-logo' +
              (isDarkTheme ? '-dark' : '') +
              '.svg'
            "
            alt="Image"
            height="30"
            width="30"
            i18n-alt />
          IceBerry
        </h4>
        <p class="text-base">
          Streamlines job seeking, hiring processes, candidate tracking, client
          acquisition and more.
        </p>
      </div>

      <div class="flex gap-0 lg:gap-3-5 m-auto lg:m-0">
        <div class="flex flex-column gap-3-5 w-10rem">
          <p class="text-bold-base">Product</p>
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Entreprise</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Tutorials</a
          >
          <div class="flex gap-2 align-items-center color-dark-70">
            <a class="text-base m-0 no-underline color-dark-70" href="#"
              >Releases</a
            >
            <ice-tag class="hidden md:flex" text="new"></ice-tag>
          </div>
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Features</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Pricing</a
          >
        </div>
        <div class="flex flex-column gap-3-5 w-10rem">
          <p class="text-bold-base">Company</p>
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >About us</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#">News</a>
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Events</a
          >
        </div>

        <div class="flex flex-column gap-3-5 w-10rem">
          <p class="text-bold-base">Legal</p>
          <a class="text-base m-0 no-underline color-dark-70" href="#">Terms</a>
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Privacy</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Cookies</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Licenses</a
          >
          <a class="text-base m-0 no-underline color-dark-70" href="#"
            >Contact</a
          >
        </div>
      </div>
    </footer>
    <div class="flex flex-column mb-7-75">
      <p-divider></p-divider>
      <div class="flex justify-content-between align-items-center">
        <p class="text-sm md:text-base m-0">
          © 2024 Company All rights reserved
        </p>
        <ul class="flex gap-3-5 list-none">
          <li>
            <a
              aria-label="linkedin"
              href="https://www.linkedin.com/company/84535890/admin/dashboard/"
              class="pi pi-linkedin text-2xl no-underline color-dark-100"></a>
          </li>
          <li>
            <a
              aria-label="email"
              href="mailto: recruitment@ama-ec.com"
              class="pi pi-envelope text-2xl no-underline color-dark-100"></a>
          </li>
          <li>
            <a
              aria-label="github"
              href="https://github.com/IceDevHub/IceDeed-front"
              class="pi pi-github text-2xl no-underline color-dark-100"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
