import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/core/state/app.state';
import { quickActionItems } from '~/app/shared/const/quick-action-items.const';
import * as QuickActionActions from '~/app/shared/states/quick-actions/quick-actions.actions';

/**
 * Service to manage quick actions in the application's header. This service is responsible for
 * initializing and handling quick actions that can trigger various functionalities across the application,
 * such as opening popups or side menus.
 *
 * @Injectable marks this class as a service that can be injected, and specifies that it should be
 * provided in the root of the application, making it a singleton and available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class QuickActionService {
  /**
   * Constructs a QuickActionService with dependencies injected.
   *
   * @param {Store<AppState>} store - The NgRx store used for managing the state of the application.
   */
  constructor(private store: Store<AppState>) {}

  /**
   * Sets the quick actions for the specified module.
   *
   * This method dispatches an action to update the quick actions in the store
   * based on the provided module name. It retrieves the quick actions from
   * the `quickActionItems` configuration object.
   *
   * @param {string} module - The name of the module for which to set quick actions.
   * @returns {void}
   * @memberof QuickActionService
   */
  setQuickActions(module: string): void {
    this.store.dispatch(
      QuickActionActions.setQuickActions({
        quickActions: quickActionItems[module],
      })
    );
  }
}
