import { createReducer, on } from '@ngrx/store';
import * as UserActions from '~/app/states/main/kanban/states/users/users.actions';
import { initialKanbanUsersState } from '~/app/states/main/kanban/states/users/users.state';

export const kanbanUsersReducer = createReducer(
  initialKanbanUsersState,

  on(UserActions.loadUsers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.loadUsersSuccess, (state, { users }) => ({
    ...state,
    users: users,
    loading: false,
  })),
  on(UserActions.loadUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  }))
);
