/* eslint-disable max-lines */

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { isNumeric } from '@npmicedev/icemodule/lib/utils/regex/number';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown';
import { Observable, skipWhile } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from '~/app/core/state/app.state';
import {
  selectOfferDocumentTypes,
  selectOfferUnits,
  selectOfferVisibilities,
  selectSkillExperiences,
} from '~/app/core/state/enum/enums.selectors';
import { OfferService } from '~/app/main/jobhub/services/offer.service';
import { DataService } from '~/app/shared/services/data.service';
import { DialogService } from '~/app/shared/services/dialog.service';
import { SkillsService } from '~/app/shared/services/skills.service';
import { UtilsService } from '~/app/shared/services/utils.service';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { selectAllSkills } from '~/app/shared/states/skills/skills.selectors';
import { AllSkillsState } from '~/app/shared/states/skills/skills.state';
import { OffersState } from '~/app/main/jobhub/states/offers/offers.state';
import { selectOffersLoadingState } from '~/app/main/jobhub/states/offers/offers.selectors';

/**
 * Component for creating, updating, and deleting offers.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-cud-offer' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './cud-offer.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./cud-offer.component.scss'] – Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-cud-offer',
  templateUrl: './cud-offer.component.html',
  styleUrls: ['./cud-offer.component.scss'],
})
export class CudOfferComponent implements OnInit {
  /**
   * ViewChild for the skill autocomplete component.
   *
   * @type {AutoComplete}
   */
  @ViewChild('skillAutoComplete') skillAutoComplete!: AutoComplete;

  /**
   * ViewChild for the skill level dropdown component.
   *
   * @type {Dropdown}
   */
  @ViewChild('skillLevelDropdown') skillLevelDropdown!: Dropdown;

  /**
   * ViewChild for the document autocomplete component.
   *
   * @type {AutoComplete}
   */
  @ViewChild('documentAutocomplete') documentAutocomplete!: AutoComplete;

  /**
   * Initial offer data, if available.
   *
   * @type {any | null}
   */
  @Input() initialOffer: any | null = null; // TODO – change any to type

  /**
   * Form group for managing the offer.
   *
   * @type {FormGroup}
   */
  offerForm!: FormGroup;

  /**
   * Observable for offer unit types.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  offerUnitTypes$: Observable<LabelValueInterface[]>;

  /**
   * Observable for offer visibility types.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  offerVisibilityTypes$: Observable<LabelValueInterface[]>;

  /**
   * Observable for group reference IDs.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  groupReferenceId$: Observable<LabelValueInterface[]>;

  /**
   * Observable of offer document types.
   *
   * @type {LabelValueInterface[]}
   */
  offerDocumentTypes$: Observable<LabelValueInterface[]>;

  /**
   * Observable for skills.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  skills$: Observable<LabelValueInterface[]>;

  /**
   * Observable for skill experiences.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  skillExperiences$: Observable<LabelValueInterface[]>;

  /**
   * Observable for the loading state of the cud offer.
   *
   * @type {Observable<boolean>}
   */
  isLoading$: Observable<boolean>;

  /**
   * Constructor for the component.
   *
   * @param {FormBuilder} formBuilder – FormBuilder service for creating forms.
   * @param {Store<AppState>} store – Store service for managing app state.
   * @param {OfferService} offer – Service for managing offers.
   * @param {SkillsService} skillsService - Service for managing all skills.
   * @param {Store} skillsStore – Store service for managing skills state.
   * @param {DataService} data – Service for managing data.
   * @param {UtilsService} utils – Service for utility functions.
   * @param {DatePipe} datePipe – Service for formatting dates.
   * @param {Store<OffersState>} offersStore - Store for managing the offers state.
   */
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private offer: OfferService,
    private skillsService: SkillsService,
    private skillsStore: Store<AllSkillsState>,
    private data: DataService,
    private utils: UtilsService,
    private datePipe: DatePipe,
    private offersStore: Store<OffersState>,
    private dialog: DialogService
  ) {
    this.offerUnitTypes$ = this.store.select(selectOfferUnits);
    this.offerVisibilityTypes$ = this.store.select(selectOfferVisibilities);
    this.skillExperiences$ = this.store.select(selectSkillExperiences);
    this.offerDocumentTypes$ = this.store.select(selectOfferDocumentTypes);
    this.isLoading$ = this.offersStore.select(selectOffersLoadingState);

    this.groupReferenceId$ = this.data.companyReferences$.pipe(
      skipWhile(items => !items),
      map(groups =>
        groups.map(group => ({
          label: group.name,
          value: group.uuid,
        }))
      )
    );

    this.skills$ = this.skillsStore.select(selectAllSkills).pipe(
      skipWhile(items => !items),
      map(skills =>
        skills.map(skill => ({
          label: skill.name,
          value: skill.uuid,
        }))
      )
    );
  }

  /**
   * Getter for the title form control.
   *
   * @returns {FormControl} – The FormControl for the title field.
   */
  public get title(): FormControl {
    return this.offerForm.get('title') as FormControl;
  }

  /**
   * Getter for the rate form control.
   *
   * @returns {FormControl} – The FormControl for the rate field.
   */
  public get rate(): FormControl {
    return this.offerForm.get('rate') as FormControl;
  }

  /**
   * Getter for the rate value.
   *
   * @returns {string} – The value of the rate field.
   */
  public get rateValue(): string {
    return this.rate.value;
  }

  /**
   * Getter for the rate unit form control.
   *
   * @returns {FormControl} – The FormControl for the rate unit field.
   */
  public get rateUnit(): FormControl {
    return this.offerForm.get('rateUnit') as FormControl;
  }

  /**
   * Getter for the rate unit value.
   *
   * @returns {string} – The value of the rate unit field.
   */
  public get rateUnitValue(): string {
    return this.rateUnit.value?.value ?? this.rateUnit.value;
  }

  /**
   * Getter for the start date form control.
   *
   * @returns {FormControl} – The FormControl for the start date field.
   */
  public get startDate(): FormControl {
    return this.offerForm.get('startDate') as FormControl;
  }

  /**
   * Getter for the start date value.
   *
   * @returns {string} – The value of the start date field.
   */
  public get startDateValue(): string {
    return this.startDate.value;
  }

  /**
   * Getter for the street address form control.
   *
   * @returns {FormControl} – The FormControl for the street address field.
   */
  public get streetAddress(): FormControl {
    return this.offerForm.get('streetAddress') as FormControl;
  }

  /**
   * Getter for the city form control.
   *
   * @returns {FormControl} – The FormControl for the city field.
   */
  public get city(): FormControl {
    return this.offerForm.get('city') as FormControl;
  }

  /**
   * Getter for the postal code form control.
   *
   * @returns {FormControl} – The FormControl for the postal code field.
   */
  public get postalCode(): FormControl {
    return this.offerForm.get('postalCode') as FormControl;
  }

  /**
   * Getter for the country form control.
   *
   * @returns {FormControl} – The FormControl for the country field.
   */
  public get country(): FormControl {
    return this.offerForm.get('country') as FormControl;
  }

  /**
   * Getter for the visibility form control.
   *
   * @returns {FormControl} – The FormControl for the visibility field.
   */
  public get visibility(): FormControl {
    return this.offerForm.get('visibility') as FormControl;
  }

  /**
   * Getter for the visibility value.
   *
   * @returns {string} – The value of the visibility field.
   */
  public get visibilityValue(): string {
    return this.visibility.value?.value ?? this.visibility.value;
  }

  /**
   * Getter for the group reference ID form control.
   *
   * @returns {FormControl} – The FormControl for the group reference ID field.
   */
  public get groupReferenceId(): FormControl {
    return this.offerForm.get('groupReferenceId') as FormControl;
  }

  /**
   * Getter for the group reference ID value.
   *
   * @returns {string} – The value of the group reference ID field.
   */
  public get groupReferenceIdValue(): string {
    return this.groupReferenceId.value?.value ?? this.groupReferenceId.value;
  }

  /**
   * Getter for the documents form array.
   *
   * @returns {FormArray} – The FormArray for the documents.
   */
  public get documents(): FormArray {
    return this.offerForm.get('documents') as FormArray;
  }

  /**
   * Getter for the skills form array.
   *
   * @returns {FormArray} – The FormArray for the skills.
   */
  public get skills(): FormArray {
    return this.offerForm.get('skills') as FormArray;
  }

  /**
   * Getter for the skill form control.
   *
   * @returns {FormControl} – The FormControl for the skill field.
   */
  public get skill(): FormControl {
    return this.offerForm.get('skill') as FormControl;
  }

  /**
   * Getter for the skill experience form control.
   *
   * @returns {FormControl} – The FormControl for the skill experience field.
   */
  public get skillExperience(): FormControl {
    return this.offerForm.get('skillExperience') as FormControl;
  }

  /**
   * Getter for the file type form control.
   *
   * @returns {FormControl} – The FormControl for the file type field.
   */
  public get fileType(): FormControl {
    return this.offerForm.get('fileType') as FormControl;
  }

  /**
   * Gets the form control for a specific skill by index.
   *
   * @param {number} skillArrayIndex – The index of the skill in the array.
   * @returns {FormControl} – The FormControl for the specified skill.
   */
  public getSkill(skillArrayIndex: number): FormControl<{
    skillId: LabelValueInterface;
    experienceMinimum: string;
  }> {
    return this.skills.at(skillArrayIndex) as FormControl<{
      skillId: LabelValueInterface;
      experienceMinimum: string;
    }>;
  }

  /**
   * Gets the form control for a specific document by index.
   *
   * @param {number} documentArrayIndex – The index of the document in the array.
   * @returns {FormControl<LabelValueInterface>} – The FormControl for the specified document.
   */
  public getDocument(
    documentArrayIndex: number
  ): FormControl<LabelValueInterface> {
    return this.documents.at(
      documentArrayIndex
    ) as FormControl<LabelValueInterface>;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.handleVisibilityChanges();
  }

  /**
   * Initializes the form.
   *
   * @returns {void}
   */
  initForm(): void {
    this.offerForm = this.formBuilder.group({
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(64),
        ],
      ],
      rate: ['', [Validators.required, Validators.pattern(isNumeric)]],
      rateUnit: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      streetAddress: ['', Validators.maxLength(255)],
      city: ['', Validators.maxLength(100)],
      postalCode: ['', Validators.maxLength(20)],
      country: ['', Validators.maxLength(100)],
      proposalLimit: [3, [Validators.required, Validators.min(1)]],
      minimumProfiles: [1, [Validators.required, Validators.min(1)]],
      description: ['', Validators.maxLength(2048)],
      visibility: [null, [Validators.required]],
      groupReferenceId: [''],
      documents: this.formBuilder.array([]),
      skills: this.formBuilder.array([]),
      skill: [],
      skillExperience: [],
      fileType: [],
    });

    if (this.initialOffer) {
      this.offerForm.patchValue({
        ...this.initialOffer,
        groupReferenceId: this.initialOffer.groupReference,
      });
    }
    if (this.offerForm.get('visibility')?.value === 'public') {
      this.offerForm.get('groupReferenceId')?.clearValidators();
      this.offerForm.get('groupReferenceId')?.updateValueAndValidity();
    }

    this.handleVisibilityChanges();
  }

  /**
   * Handles visibility changes for the reference group input.
   *
   * @returns {void}
   */
  handleVisibilityChanges(): void {
    const visibilityControl = this.offerForm.get('visibility');
    const groupReferenceControl = this.offerForm.get('groupReferenceId');

    if (visibilityControl && groupReferenceControl) {
      visibilityControl.valueChanges.subscribe(value => {
        if (value === 'public') {
          groupReferenceControl.clearValidators(); // Supprime les validateurs
        } else {
          groupReferenceControl.setValidators([Validators.required]); // Ajoute le validateur requis
        }
        groupReferenceControl.updateValueAndValidity(); // Met à jour la validité
      });
    }
  }

  /**
   * Handles form submission.
   *
   * @returns {void}
   */
  onSubmit(): void {
    const offerData = {
      ...this.offerForm.value,
      rate: this.rateValue.toString(),
      rateUnit: this.rateUnitValue,
      startDate: this.datePipe.transform(this.startDateValue, 'yyyy-MM-dd'),
      groupReferenceId: this.groupReferenceIdValue,
      visibility: this.visibilityValue,
    };

    if (offerData.skills) {
      offerData.skills = offerData.skills.map(
        (skill: {
          skillId: LabelValueInterface;
          experienceMinimum: string;
        }) => {
          return {
            skillId: skill.skillId.value,
            experienceMinimum: skill.experienceMinimum,
          };
        }
      );
    }

    if (offerData.documents) {
      offerData.documents = this.documents.value.map((document: string) => ({
        type: document,
        required: true,
      }));
    }

    if (this.initialOffer) {
      this.offer.updateOffer(
        this.utils.filterValues(this.utils.extractValue(offerData)),
        this.initialOffer.uuid
      );
    } else {
      this.offer.createOffer(this.utils.filterValues(offerData));
    }
  }

  /**
   * Handles the selection of a skill.
   *
   * This function is triggered when a skill is selected from a list or dropdown.
   * It sets the selected skill value to the corresponding form control.
   *
   * @param {LabelValueInterface} skill - The selected skill object containing the label and value.
   * @returns {void}
   */
  onSelectSkill(skill: LabelValueInterface): void {
    this.skill.setValue(skill);
  }

  /**
   * Adds a skill to the skills form array.
   *
   * @returns {void}
   */
  addSkill(): void {
    this.skills.push(
      this.formBuilder.group({
        skillId: this.skill.value,
        experienceMinimum: this.skillExperience.value,
      })
    );

    this.skill.reset();
    this.skillExperience.reset();
  }

  /**
   * Removes a skill from the skills form array.
   *
   * @param {number} index – The index of the skill to remove.
   * @returns {void}
   */
  removeSkill(index: number): void {
    this.skills.removeAt(index);
  }

  /**
   * Checks if a document is already in the documents array.
   *
   * @param {string} documentValue - The value of the document to check.
   * @returns {boolean} - True if the document is in the array, otherwise false.
   */
  isDocumentChecked(documentValue: string): boolean {
    return this.documents.controls.some(
      control => control.value === documentValue
    );
  }

  /**
   * Handles the toggle event of a document checkbox.
   *
   * @param {boolean} isChecked - The new state of the checkbox.
   * @param {LabelValueInterface} document - The document associated with the checkbox.
   * @returns {void}
   */
  onDocumentToggle(isChecked: boolean, document: LabelValueInterface): void {
    if (isChecked) {
      // Add the document to the FormArray
      this.documents.push(this.formBuilder.control(document.value));
    } else {
      // Remove the document from the FormArray
      const index = this.documents.controls.findIndex(
        control => control.value === document.value
      );
      if (index !== -1) {
        this.documents.removeAt(index);
      }
    }
  }

  /**
   * Searches for skills based on the autocomplete event query.
   *
   * @param {AutoCompleteCompleteEvent} event The autocomplete complete event.
   * @returns {void}
   */
  searchSkills(event: AutoCompleteCompleteEvent): void {
    this.skillsService.loadAllSkills({ limit: 20, name: event.query });
  }
}
