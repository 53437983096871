<p-sidebar
  [(visible)]="isMobileSidenavOpen"
  styleClass="w-18rem pt-2"
  position="left"
  [modal]="true">
  <ng-template pTemplate="header">
    <a [routerLink]="'/home'" (click)="closeMobileSidenav()">
      <img
        src="isLightTheme ? 'assets/icons/ice-berry-logo-dark.svg' : 'assets/icons/ice-berry-logo-dark.svg'"
        alt="Image"
        i18n-alt
        height="30" />
    </a>
  </ng-template>

  <ng-template pTemplate="content">
    <div class="flex flex-column gap-4-8 mt-7-75">
      <div *ngFor="let module of activatedModules$ | async">
        <a
          tabindex="0"
          (mouseleave)="onHoverModuleLeave()"
          (click)="module.command()"
          class="mobile-sidenav-module flex align-items-center justify-content-between transition-colors transition-duration-150"
          [ngClass]="{
            'current-module': (currentModule$ | async)?.name === module.name,
          }">
          <div class="flex lg:gap-4-8 align-items-center">
            <img
              [ngSrc]="
                'assets/icons/modules/' +
                ((currentModule$ | async)?.name === module.name ||
                moduleHovered === module.name
                  ? 'light/'
                  : 'dark/') +
                module.icon +
                '.svg'
              "
              width="20"
              height="20"
              alt="Module icon" />
            <span class="text-sm text-center sidenav-content-title">
              {{ module.label.toUpperCase() }}
            </span>
          </div>
          <i
            class="pi pi-chevron-down ml-auto"
            tabindex="0"
            (click)="toggleNavItems(module); $event.stopPropagation()"
            [ngClass]="{
              'chevron-rotated': expendedMobileModule === module.name,
              'chevron-default': expendedMobileModule !== module.name,
            }"></i>
        </a>

        <div
          *ngIf="expendedMobileModule === module.name"
          class="flex flex-column gap-3-5 mt-3-5 ml-4-8"
          [ngClass]="{
            visible: expendedMobileModule === module.name,
            hidden: expendedMobileModule !== module.name,
          }">
          <a
            *ngFor="let item of navItems"
            class="no-underline mobile-sidenav-module flex gap-4-8 align-items-center"
            (click)="closeMobileSidenav()"
            [routerLink]="item.path">
            <img
              [ngSrc]="'assets/icons/modules/dark/' + item.icon + '.svg'"
              width="15"
              height="15"
              alt="Module icon" />
            <span class="text-sm text-center sidenav-content-title">
              {{ item.label.toUpperCase() }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex flex-column">
      <a
        (mouseenter)="moduleHovered = 'settings'"
        (mouseleave)="moduleHovered = null"
        [routerLink]="'/settings'"
        class="flex flex-row justify-content-center mobile-settings-container no-underline transition-colors transition-duration-150"
        [ngClass]="{
          'current-module': (currentModule$ | async)?.name === 'settings',
        }">
        <img
          [ngSrc]="
            'assets/icons/modules/' +
            ((currentModule$ | async)?.name === 'settings' ||
            moduleHovered === 'settings'
              ? 'light/'
              : 'dark/') +
            'setting.svg'
          "
          width="20"
          height="20"
          alt="Setting icon" />
      </a>
      <div
        class="cursor-pointer flex justify-content-center"
        (click)="releaseNote()"
        (mouseenter)="moduleHovered = 'version'"
        (mouseleave)="moduleHovered = null">
        <p
          *ngIf="moduleHovered !== 'version'; else otherVersion"
          class="w-full h-full border-round-xl color-dark-100 text-center">
          Version <br />
          {{ version }}
        </p>
        <ng-template #otherVersion>
          <p
            class="w-full h-full border-round-xl color-dark-3 bg-dark-100 text-center">
            Version <br />
            {{ version }}
          </p>
        </ng-template>
      </div>
    </div>
  </ng-template>
</p-sidebar>

<div class="mobile-display-none h-full">
  <div class="media-sidenav flex flex-row h-full ml-2">
    <div
      [ngClass]="
        navItems.length && isShow
          ? 'sidenav-container-extend-border'
          : 'sidenav-container-border'
      "
      class="sidenav-container bg-dark-3 border-1 border-dark-10 flex flex-column justify-content-between transition-all transition-delay-150 transition-ease-in-out">
      <div class="flex flex-column gap-5 lg:gap-6 align-items-center">
        <a [routerLink]="'/home'">
          <img
            class="sidenav-content align-self-center"
            [ngSrc]="
              (isLightTheme$ | async)
                ? 'assets/icons/ice-berry-logo-dark.svg'
                : 'assets/icons/ice-berry-logo.svg'
            "
            alt="IceBerry logo"
            width="44"
            height="36" />
        </a>

        <div class="flex flex-column gap-4 lgh:gap-6 sidenav-module-container">
          <div
            (mouseenter)="onHoverModuleEnter(module)"
            (mouseleave)="onHoverModuleLeave()"
            (click)="module.command()"
            *ngFor="let module of activatedModules$ | async"
            class="sidenav-module transition-colors transition-duration-150"
            [ngClass]="{
              'current-module': (currentModule$ | async)?.name === module.name,
            }">
            <div class="flex flex-column gap-2 align-items-center max-w-5rem">
              <img
                [ngSrc]="
                  'assets/icons/modules/' +
                  ((currentModule$ | async)?.name === module.name ||
                  moduleHovered === module.name ||
                  (isLightTheme$ | async)
                    ? 'light/'
                    : 'dark/') +
                  module.icon +
                  '.svg'
                "
                width="20"
                height="20"
                alt="Module icon" />
              <span class="text-sm text-center sidenav-content-title">
                {{ module.label.toUpperCase() }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-column">
        <a
          (mouseenter)="moduleHovered = 'settings'"
          (mouseleave)="moduleHovered = null"
          [routerLink]="'/settings'"
          class="flex flex-row justify-content-center settings-container no-underline transition-colors transition-duration-150"
          [ngClass]="{
            'current-module': (currentModule$ | async)?.name === 'settings',
          }">
          <img
            [ngSrc]="
              'assets/icons/modules/' +
              ((currentModule$ | async)?.name === 'settings' ||
              moduleHovered === 'settings' ||
              (isLightTheme$ | async)
                ? 'light/'
                : 'dark/') +
              'setting.svg'
            "
            width="20"
            height="20"
            alt="Setting icon" />
        </a>
        <div
          class="mt-3 cursor-pointer flex justify-content-center mt-1"
          (click)="releaseNote()">
          <div>
            <p class="w-full border-round-xl color-dark-50 text-center">
              Version <br />
              {{ version }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="transition-all transition-duration-150 transition-ease-in-out"
      [ngClass]="{
        'opacity-0 pointer-events-none': !navItems.length || !isShow,
        'opacity-1': navItems.length && isShow,
      }"
      (mouseenter)="onHoverExtendedEnter()"
      (mouseleave)="onHoverExtendedLeave()">
      <div
        class="absolute h-full w-20rem flex flex-column gap-5 lgh:gap-6 sidenav-extend-container">
        <app-sidenav-item [items]="navItems"></app-sidenav-item>
      </div>
    </div>
  </div>
</div>
