import { Team } from '~/app/shared/interfaces/crm/team/team.interface';

/**
 * Represents the state of home teams including the teams array, loading status, and error information.
 *
 * @interface HomeTeamsState
 */
export interface HomeTeamsState {
  /**
   * The array of teams.
   *
   * @type {Team[]}
   */
  teams: Team[];

  /**
   * Indicates whether the teams are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message if an error occurred during the loading of teams.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Represents the initial state of home teams including the teams array, loading status, and error information.
 *
 * @type {HomeTeamsState}
 */
export const initialHomeTeamState: HomeTeamsState = {
  teams: [],
  loading: false,
  error: null,
};
