import { User } from '~/app/shared/interfaces/auth/user.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

/**
 * Represents the state of Kanban users.
 *
 * @interface KanbanUsersState
 */
export interface KanbanUsersState {
  /**
   * The paginated data of Kanban users.
   *
   * @type {PaginationData<User> | null}
   */
  users: PaginationData<User> | null;

  /**
   * Indicates if the Kanban users are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban users.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of Kanban users.
 *
 * @type {KanbanUsersState}
 */
export const initialKanbanUsersState: KanbanUsersState = {
  users: null,
  loading: false,
  error: null,
};
