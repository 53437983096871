import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

/**
 * Directive that automatically converts text entered into an input field to lowercase.
 * This is particularly useful for ensuring that user inputs conform to data formats that are case-insensitive,
 * such as email addresses or usernames.
 *
 * The directive uses Angular's Renderer2 for DOM manipulations, which provides a layer of abstraction that ensures
 * automatic DOM updates are performed in a way that is optimized for Angular's change detection mechanisms.
 *
 * @Directive decorator specifies the CSS selector `[appLowercase]` that targets input elements where this behavior should be applied.
 */
@Directive({
  selector: '[appLowercase]',
})
export class LowercaseDirective {
  /**
   * Constructs an instance of the LowercaseDirective with injected dependencies.
   *
   * @param {ElementRef} el - A wrapper around the native element where the directive is applied, providing direct access to the DOM element.
   * @param {Renderer2} renderer - Angular's rendering abstraction used to modify the element in a way that's compatible with server-side rendering and optimized for Angular's change detection.
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  /**
   * Host listener for the 'input' event on the element. Whenever the user inputs data, the entered text is converted to lowercase.
   * This listener ensures that any text input by the user is immediately transformed, maintaining the lowercase format consistently.
   *
   * @param {Event} event - The DOM event triggered when text is input into the field.
   *
   * @return {void}
   */
  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement; // Access the native input element
    const inputValue = inputElement.value.toLowerCase(); // Convert the input value to lowercase
    this.renderer.setProperty(inputElement, 'value', inputValue); // Update the input element with the new value
  }
}
