import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { CountryCodes } from '~/app/shared/interfaces/country-codes.interface';
import { iso31661Codes } from '~/app/shared/const/country-code.const';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * Component to provide an input field specifically for phone numbers with an associated dropdown
 * for selecting country codes. This component is integrated with Angular's forms system and supports
 * dynamic validation and error handling.
 *
 * @Component decorator provides Angular metadata for component declaration.
 * @selector 'ice-phone-iso' - CSS selector for embedding this component.
 * @templateUrl './ice-phone-iso.component.html' - HTML template for the component.
 * @styleUrls ['./ice-phone-iso.component.scss'] - Styles for the component.
 * @providers NG_VALUE_ACCESSOR - Allows the component to interact with Angular's form API as a form control.
 */
@Component({
  selector: 'ice-phone-iso',
  templateUrl: './ice-phone-iso.component.html',
  styleUrls: ['./ice-phone-iso.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IcePhoneIsoComponent),
      multi: true,
    },
  ],
})
export class IcePhoneIsoComponent {
  /**
   * The label for the phone input field.
   * @type {string}
   */
  @Input({ required: true }) label!: string;

  /**
   * Placeholder text for the phone input field.
   * @type {string}
   */
  @Input({ required: true }) placeholder!: string;

  /**
   * FormControl bound to the input element, used for managing form state and validation.
   * @type {FormControl}
   */
  @Input({ required: true }) formControl!: FormControl;

  /**
   * Name of the FormControl within the FormGroup for error handling and messages.
   * @type {string}
   */
  @Input({ required: true }) formControlName!: string;

  /**
   * Specifies if the input is required.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Holds any current validation errors to display.
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Indicates if the form has been submitted, used to control error visibility.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * List of country codes to be used for the phone number input.
   * @type {CountryCodes[]}
   */
  countryCodes: CountryCodes[] = iso31661Codes;

  /**
   * Current value of the input.
   * @type {string}
   */
  value!: string;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Called when the input field is focused, marking the field as touched.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Called when the input field loses focus, marking the field as touched.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a callback for value changes.
   * @param {void} fn - The callback function (value: any) => void.
   * @returns {void}
   */
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback for touch events.
   * @param {void} fn - The callback function () => void.
   * @returns {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Handles input events, updating the value and triggering change detection.
   * @param {Event} event - The input event (Event).
   * @returns {void}
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(input.value);
  }

  /**
   * Handles the receipt of an error message from the form validation process.
   * @param {string | null} message - The error message received from the validator.
   * @return {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }
}
