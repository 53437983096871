import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { registerUserValidation } from '@npmicedev/icemodule/lib/validations/users';
import { ZodObject } from 'zod';
import {
  selectCompanyFormData,
  selectOfficeFormData,
} from '~/app/auth/state/register/register.selectors';
import { AppState } from '~/app/core/state/app.state';
import { ToastService } from '~/app/shared/services/toast.service';
import { UtilsService } from '~/app/shared/services/utils.service';
import { iso31661Codes } from '~/app/shared/const/country-code.const';
import * as RegisterActions from '~/app/auth/state/register/register.actions';

/**
 * Component responsible for handling the office registration step in a multi-step registration process.
 * It provides a form for entering office details such as name, address, and country, and validates this information using Zod validation schema.
 *
 * @Component
 * @selector 'app-office-step' - The CSS selector that identifies this component in a template.
 * @templateUrl './office-step.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./office-step.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-office-step',
  templateUrl: './office-step.component.html',
  styleUrls: ['./office-step.component.scss'],
})
export class OfficeStepComponent implements OnInit {
  /**
   * FormGroup representing the form for office details.
   * @type {FormGroup}
   */
  officeForm!: FormGroup;

  /**
   * Flag to track whether the form has been submitted.
   * @type {boolean}
   */
  submitted = false;

  /**
   * List of country names extracted from ISO 3166-1 codes for the country dropdown.
   * @type {string[]}
   */
  countryCodes: string[] = iso31661Codes.map(countryCode => countryCode.name);

  /**
   * Full list of country codes and details, used internally.
   * @type {typeof iso31661Codes}
   */
  protected readonly iso31661Codes = iso31661Codes;

  /**
   * Constructs the OfficeStepComponent with necessary services.
   *
   * @param {FormBuilder} formBuilder FormBuilder to create form group instances.
   * @param {Router} router Router for navigation between registration steps.
   * @param {Store<AppState>} store Store for accessing and dispatching state.
   * @param {UtilsService} utils Utility service for filtering form values before validation.
   * @param {ToastService} toast Service to show toast notifications for success or error messages.
   */
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private utils: UtilsService,
    private toast: ToastService
  ) {}

  /**
   * Accessor for the 'name' form control
   * @type {FormControl}
   */
  get name(): FormControl {
    return this.officeForm.get('name') as FormControl;
  }

  /**
   * Accessor for the 'streetAddress' form control
   * @type {FormControl}
   */
  get streetAddress(): FormControl {
    return this.officeForm.get('streetAddress') as FormControl;
  }

  /**
   * Accessor for the 'city' form control
   * @type {FormControl}
   */
  get city(): FormControl {
    return this.officeForm.get('city') as FormControl;
  }

  /**
   * Accessor for the 'postalCode' form control
   * @type {FormControl}
   */
  get postalCode(): FormControl {
    return this.officeForm.get('postalCode') as FormControl;
  }

  /**
   * Accessor for the 'country' form control
   * @type {FormControl}
   */
  get country(): FormControl {
    return this.officeForm.get('country') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the form and subscribes to necessary state selectors.
   * @return {void}
   */
  ngOnInit(): void {
    this.store.select(selectCompanyFormData).subscribe(res => {
      if (res === null) {
        void this.router.navigate(['register/company']);
        this.toast.showWarning(
          'Form not completed',
          'Please fill the first form before'
        );
      }
    });
    this.initOfficeForm();
  }

  /**
   * Initializes the office form with validators.
   * @return {void}
   */
  initOfficeForm(): void {
    this.officeForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
        ],
      ],
      streetAddress: [null, [Validators.maxLength(255)]],
      city: [null, [Validators.maxLength(100)]],
      postalCode: [null, [Validators.maxLength(20)]],
      country: [null, [Validators.maxLength(100)]],
    });

    this.store.select(selectOfficeFormData).subscribe(officeData => {
      if (officeData) {
        this.officeForm.patchValue(officeData);
      }
    });
  }

  /**
   * Returns an error message based on the form control's validation status.
   *
   * @param {string} controlName Name of the form control to validate.
   * @returns {string | null} Error message or null if no errors.
   */
  getErrorMessage(controlName: string): string | null {
    const control = this.officeForm.get(controlName);
    if (control && control.errors) {
      if (control.errors['required']) {
        return 'This field is required.';
      }
      if (control.errors['minlength']) {
        // Adjust the message based on the control's validation requirements
        const requiredLength = control.errors['minlength'].requiredLength;
        return `Must be at least ${requiredLength} characters long.`;
      }
      if (control.errors['maxlength']) {
        const requiredLength = control.errors['maxlength'].requiredLength;
        return `Cannot be more than ${requiredLength} characters long.`;
      }
      if (control.errors['email']) {
        return 'Please enter a valid email address.';
      }
      // Add other error types as needed
    }

    return null;
  }

  /**
   * Handler for the 'Next' button in the form. Validates the form data against a Zod schema,
   * dispatches the updated office data to the store, and navigates to the next step or shows an error.
   * @return {void}
   */
  onNext(): void {
    const officeSchema = (registerUserValidation.body as ZodObject<any>).shape
      .office;

    const result = officeSchema.safeParse(
      this.utils.filterValues(this.officeForm.value)
    );

    if (result?.success) {
      this.store.dispatch(
        RegisterActions.updateOfficeForm({
          officeData: this.officeForm.value,
        })
      );
      void this.router.navigate(['/register/root-user']);
      return;
    } else {
      this.toast.showError(
        'Error',
        result?.error.errors[0]?.message ?? 'Validation failed'
      );
    }
    this.submitted = true;
  }
}
