import { createReducer, on } from '@ngrx/store';
import * as CandidateStatusActions from '~/app/states/main/settings/states/status/candidate-status/candidate-status.actions';
import { initialCandidateStatusState } from '~/app/states/main/settings/states/status/candidate-status/candidate-status.state';

export const candidateStatusReducer = createReducer(
  initialCandidateStatusState,

  on(CandidateStatusActions.loadCandidateStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateStatusActions.loadCandidateStatusesSuccess,
    (state, { candidateStatuses }) => ({
      ...state,
      candidateStatuses,
      loading: false,
    })
  ),
  on(
    CandidateStatusActions.loadCandidateStatusesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(CandidateStatusActions.createCandidateStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateStatusActions.createCandidateStatusSuccess,
    (state, { status }) => ({
      ...state,
      loading: false,
      candidateStatuses: [...state.candidateStatuses, status],
    })
  ),
  on(
    CandidateStatusActions.createCandidateStatusFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  on(CandidateStatusActions.updateCandidateStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CandidateStatusActions.updateCandidateStatusSuccess,
    (state, { status }) => ({
      ...state,
      loading: false,
      candidateStatuses: state.candidateStatuses.map(item =>
        item.uuid === status.uuid ? { ...item, ...status } : item
      ),
    })
  ),
  on(
    CandidateStatusActions.updateCandidateStatusFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  on(CandidateStatusActions.deleteCandidateStatus, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(
    CandidateStatusActions.deleteCandidateStatusSuccess,
    (state, { uuid }) => ({
      ...state,
      candidateStatuses: state.candidateStatuses.filter(s => s.uuid !== uuid),
      loading: false,
    })
  ),
  on(
    CandidateStatusActions.deleteCandidateStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    CandidateStatusActions.candidateStatusUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
