import { createReducer, on } from '@ngrx/store';
import * as SalesStatusActions from '~/app/states/main/settings/states/status/sales-status/sales-status.actions';
import { initialSalesStatusState } from '~/app/states/main/settings/states/status/sales-status/sales-status.state';

export const salesStatusReducer = createReducer(
  initialSalesStatusState,

  on(SalesStatusActions.loadSalesStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesStatusActions.loadSalesStatusesSuccess,
    (state, { salesStatuses }) => ({
      ...state,
      salesStatuses,
      loading: false,
    })
  ),
  on(SalesStatusActions.loadSalesStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesStatusActions.createSalesStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesStatusActions.createSalesStatusSuccess, (state, { salesStatus }) => ({
    ...state,
    salesStatuses: [...state.salesStatuses, salesStatus],
    loading: false,
  })),
  on(SalesStatusActions.createSalesStatusFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Update Sales Statuses
  on(SalesStatusActions.updateSalesStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesStatusActions.updateSalesStatusSuccess, (state, { salesStatus }) => ({
    ...state,
    salesStatuses: state.salesStatuses.map(item =>
      item.uuid === salesStatus.uuid ? { ...item, ...salesStatus } : item
    ),
    loading: false,
  })),
  on(SalesStatusActions.updateSalesStatusFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Delete Sales Statuses
  on(SalesStatusActions.deleteSalesStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesStatusActions.deleteSalesStatusSuccess, (state, { uuid }) => ({
    ...state,
    salesStatuses: state.salesStatuses.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(SalesStatusActions.deleteSalesStatusFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesStatusActions.salesStatusUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
