<div class="bg-dark-3 w-full p-3 border-round">
  <form [formGroup]="kanbanForm" (ngSubmit)="onSubmit()">
    <h3 i18n class="mt-2">Tag</h3>
    <div class="flex align-items-center gap-2 mb-3">
      <ice-color-picker
        [formControl]="color"
        formControlName="color"
        format="hex"
        i18n-format></ice-color-picker>
      <ice-input
        [formControl]="name"
        formControlName="name"
        placeholder="Add a tag"
        i18n-placeholder></ice-input>
    </div>

    <div class="flex flex-row-reverse justify-content-between gap-2 mb-3">
      <ice-button
        class="flex flex-row-reverse mb-2"
        icon="pi pi-plus"
        i18n-icon
        [disabled]="kanbanForm.invalid"
        [label]="toUpdate ? 'Update' : 'Create'"
        i18n-label
        type="submit"
        [loading]="(isTagLoading$ | async) ?? false"></ice-button>
      <ice-button
        *ngIf="toUpdate"
        [text]="true"
        label="Cancel"
        i18n-label
        (onClick)="onCancel()"></ice-button>
    </div>

    <ng-container *ngIf="kanbanTags$ | async as kanbanTags">
      <div class="chip-list cursor-pointer">
        <div
          *ngFor="let tag of kanbanTags"
          class="flex align-items-center text-sm block py-1 px-2 border-round font-medium"
          [ngStyle]="{
            'background-color':
              !toUpdate || (toUpdate && tag.uuid === uuidUpdate)
                ? (tag.color | smartBackgroundColor: 10)
                : 'var(--color-dark-10)',
            color: tag.color | smartTextColor: 15,
          }">
          <span (click)="onUpdate(tag)"> {{ tag.name }}</span>
          <i
            class="pi pi-times-circle cursor-pointer p-2"
            (click)="onDelete(tag.uuid)"></i>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<div class="bg-dark-3 p-3 mt-4 border-round">
  <h3 i18n>User</h3>
  <ice-autocomplete
    class="w-full flex-1 bg-light-100 mb-2"
    id="assignees"
    [suggestions]="(users$ | async) ?? []"
    (completeMethod)="searchUsersSuggestions($event)"
    [formControl]="user"
    (onSelect)="assignUser($event)"
    [dropdown]="false"
    [iceAutocompleteTemplate]="templateEnum.USER"
    placeholder="Choose assignees"
    i18n-placeholder>
  </ice-autocomplete>
  <div class="chip-list2">
    <p-chip
      class="bg-light-100 border-dark-70 flex gap-2 px-2 py-2"
      [style]="{ backgroundColor: 'var(--color-light-100)' }"
      *ngFor="let user of kanbanUsers$ | async">
      <div
        class="flex flex-row justify-content-between align-items-center w-full">
        <p-avatar
          [shape]="'circle'"
          [size]="'large'"
          [style]="{ backgroundColor: user.userId.color }"
          [label]="
            user.userId.firstname.toUpperCase().charAt(0) +
            user.userId.lastname.toUpperCase().charAt(0)
          "></p-avatar>
        <span>
          {{ user.userId.firstname + ' ' + user.userId.lastname }}
        </span>
        <i
          class="pi pi-times-circle cursor-pointer"
          (click)="unassignUser(user)"></i>
      </div>
    </p-chip>
  </div>
</div>
