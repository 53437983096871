<div
  class="flex align-items-center border-gray-200 flex justify-content-between py-4">
  <a class="flex mobile-display cursor-pointer" (click)="openMobileSidenav()">
    <img
      src="assets/icons/ice-berry-logo.svg"
      alt="Image"
      i18n-alt
      height="30"
      class="ml-3" />
  </a>
  <div class="flex justify-content-start align-items-center">
    <p-breadcrumb
      styleClass="border-0 ml-5"
      class="max-w-full desktop-display"
      [model]="(urlSegments$ | async)!">
      <ng-template pTemplate="item" let-item let-i="index" let-last="last">
        <ng-container *ngIf="item.route; else elseBlock">
          <a [routerLink]="item.route" class="p-menuitem-link">
            <p
              [ngClass]="{ 'last-breadcrumb-item': last }"
              class="text-2xl color-dark-100 font-semibold">
              {{ item.label }}
            </p>
          </a>
        </ng-container>
        <ng-template #elseBlock>
          <a
            [href]="item.url"
            class="no-underline"
            [ngClass]="{ 'last-breadcrumb-item': last }">
            <span class="color-dark-100">{{ item.label | lineClip: 20 }}</span>
          </a>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="separator"> / </ng-template>
    </p-breadcrumb>

    <div *ngIf="kanbanName$ | async as Name">
      <span style="color: #334155; font-weight: 700; white-space: pre"
        >/&emsp;{{ Name }}</span
      >
    </div>
  </div>

  <div class="flex align-items-center gap-3">
    <div
      *ngFor="let quickAction of headerActions$ | async; let i = index"
      class="desktop-display">
      <ice-button
        (onClick)="triggerQuickAction(quickAction)"
        [pTooltip]="quickAction.tooltip"
        [tooltipPosition]="'bottom'"
        [icon]="quickAction.icon">
      </ice-button>
    </div>

    <ng-container *ngIf="notifications$ | async as notifications">
      <div class="notification-container">
        <ice-button
          (click)="toggleDropdown($event)"
          icon="pi pi-bell"
          [text]="true"
          class="notification-button">
          <span
            class="notification-badge"
            *ngIf="unreadCount(notifications) > 0"
            [style.background-color]="'var(--color-fancy-pink)'">
            {{ unreadCount(notifications) }}
          </span>
        </ice-button>

        <div
          #notificationDropdown
          class="notification-dropdown"
          *ngIf="isDropdownOpen"
          [class.is-open]="isDropdownOpen">
          <div class="dropdown-header px-3-5 border-bottom-1 color-dark-10">
            <p class="text-bold-lg color-dark-100">Notification</p>

            <p-button
              icon="pi pi-ellipsis-v"
              text="true"
              class="menu-button"
              (click)="toggleMenu($event)">
            </p-button>

            <div *ngIf="isMenuOpen" class="mini-menu flex flex-column">
              <p-button
                text="true"
                label="Mark all as read"
                (click)="markAllAsRead()">
              </p-button>
              <p-button
                text="true"
                label="Delete all"
                (click)="deleteAllNotifications()">
              </p-button>
            </div>
          </div>

          <div class="notification-list-container">
            <ul
              *ngIf="notifications.length > 0; else emptyState"
              class="notification-list">
              <li
                *ngFor="let notification of notifications"
                [class.unread]="!notification.isRead"
                (click)="markAsRead(notification)"
                class="notification-item">
                <div class="notification-avatar">
                  <img
                    *ngIf="notification.type === 'kanbanCardAssigned'"
                    src="assets/icons/modules/dark/board.svg"
                    alt="Kanban" />
                  <img
                    *ngIf="notification.type === 'shuffleListAssigned'"
                    src="assets/icons/modules/dark/rand.svg"
                    alt="ShuffleList" />
                  <img
                    *ngIf="notification.type === 'goalFullFill'"
                    src="assets/icons/modules/dark/goals.svg"
                    alt="Goal" />
                </div>
                <div class="notification-content">
                  <div class="notification-header">
                    <div class="user-info">
                      <span class="text-bold-sm color-dark-100 line-clamp-1">
                        {{ notification.title }}
                      </span>
                    </div>
                    <div class="notification-meta">
                      <span class="text-xs color-dark-70 m-0">
                        {{ notification.createdAt | relativeTime }}
                      </span>
                      <span
                        class="unread-indicator"
                        *ngIf="!notification.isRead"></span>
                    </div>
                  </div>
                  <div class="notification-message-container">
                    <p
                      class="text-sm m-0 color-dark-70"
                      [innerHTML]="notification.message"></p>
                    <p-button
                      icon="pi pi-times"
                      text="true"
                      (click)="
                        deleteNotification(notification.uuid);
                        $event.stopPropagation()
                      "></p-button>
                  </div>
                </div>
              </li>
            </ul>
            <ng-template #emptyState>
              <div class="empty-state">
                <div class="empty-icon">
                  <i class="pi pi-bell-slash"></i>
                </div>
                <p class="empty-notification-message">
                  You have no notifications
                </p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>

    <ul
      i18n-aria-label="userProfileAndSettingsAriaLabel"
      aria-label="User profile and settings"
      class="list-none p-0 m-0 flex align-items-center flex-row border-none surface-border shadow-none static">
      <li class="surface-border mx-4">
        <ng-container *ngIf="(user$ | async)?.user as user">
          <a
            i18n-aria-label="@@userMenuAriaLabel"
            aria-label="User menu"
            class="cursor-pointer"
            (click)="toggleProfileDialog($event)">
            <ice-avatar [size]="'medium'" [avatarContent]="user"></ice-avatar>
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
<app-sidebar></app-sidebar>
