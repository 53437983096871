import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';

/**
 * Represents the state of profile sectors.
 *
 * @interface ProfileSectorsState
 */
export interface ProfileSectorsState {
  /**
   * An array of profile sector jobs or null if not loaded.
   *
   * @type {SectorJobs[] | null}
   * @memberof ProfileSectorsState
   */
  profileSectorJobs: SectorJobs[] | null;

  /**
   * Indicates if the profile sector jobs are currently being loaded.
   *
   * @type {boolean}
   * @memberof ProfileSectorsState
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile sector jobs.
   *
   * @type {string | null}
   * @memberof ProfileSectorsState
   */
  error: string | null;
}

/**
 * The initial state of profile sectors within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileSectorsState} initialProfileSectorsState
 * @description Provides a baseline definition for the profile sectors context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileSectorsState: ProfileSectorsState = {
  profileSectorJobs: [],
  loading: false,
  error: null,
};
