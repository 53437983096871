import { createReducer, on } from '@ngrx/store';
import * as ClientCompanyActions from '~/app/states/main/client-company/states/search-client-company/search-client-company.actions';
import { initialAllSearchClientCompaniesState } from '~/app/states/main/client-company/states/search-client-company/search-client-company.state';

export const allSearchClientCompanyReducer = createReducer(
  initialAllSearchClientCompaniesState,

  on(ClientCompanyActions.loadAllClientCompanies, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyActions.loadAllClientCompaniesSuccess,
    (state, { allClientCompanies }) => ({
      ...state,
      ...allClientCompanies,
      loading: false,
    })
  ),
  on(
    ClientCompanyActions.loadAllClientCompaniesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanyActions.createClientCompany, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyActions.createClientCompanySuccess,
    (state, { clientCompany }) => ({
      ...state,
      data: [...state.data, clientCompany],
      loading: false,
    })
  ),
  on(ClientCompanyActions.createClientCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ClientCompanyActions.clientCompanyUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
