import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanCardCommentsActions from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.actions';
import { KanbanComment } from '~/app/shared/interfaces/kanban/kanban-comment.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `KanbanCardCommentsEffects` manages the side effects for kanban card comment-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban card comments,
 * such as loading all kanban card comments, creating, updating, and deleting kanban card comments.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllKanbanCardComments$`: Loads all kanban card comments. It performs an HTTP GET request and dispatches a success action with the fetched comments or a failure action if an error occurs.
 * — `createKanbanCardComment$`: Creates a new kanban card comment. It performs an HTTP POST request and dispatches a success action with the created comment or a failure action if an error occurs.
 * — `updateKanbanCardComment$`: Updates an existing kanban card comment. It performs an HTTP PATCH request and dispatches a success action with the updated comment or a failure action if an error occurs.
 * — `deleteKanbanCardComment$`: Deletes a kanban card comment. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `createKanbanCardCommentSuccess$`: Handles the success of creating a kanban card comment by displaying a success toast message.
 * — `updateKanbanCardCommentSuccess$`: Handles the success of updating a kanban card comment by displaying a success toast message.
 * — `deleteKanbanCardCommentSuccess$`: Handles the success of deleting a kanban card comment by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanCardCommentsEffects {
  /**
   * Effect to load all kanban card comments.
   * Listens for the `loadAllKanbanCardComments` action, checks permissions, and performs an HTTP GET request to fetch the comments.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbanCardComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardCommentsActions.loadAllKanbanCardComments),
      checkPermission(KanbanCardCommentsActions.kanbanCommentUnauthorized),
      mergeMap(({ kanbanCardId }) =>
        this.http
          .get<KanbanComment[]>(
            `${environment.apiUrl}/v1/kanbans/cards/comments`,
            {
              params: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(kanbanComments =>
              KanbanCardCommentsActions.loadAllKanbanCardCommentsSuccess({
                kanbanComments,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardCommentsActions.loadAllKanbanCardCommentsFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban card comment.
   * Listens for the `createKanbanCardComment` action, checks permissions, and performs an HTTP POST request to create the comment.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanCardComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardCommentsActions.createKanbanCardComment),
      checkPermission(KanbanCardCommentsActions.kanbanCommentUnauthorized),
      mergeMap(({ kanbanCommentData, kanbanColumnId, eventEmitter }) =>
        this.http
          .post<KanbanComment>(
            `${environment.apiUrl}/v1/kanbans/cards/comments`,
            kanbanCommentData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanComment =>
              KanbanCardCommentsActions.createKanbanCardCommentSuccess({
                kanbanComment,
                kanbanCardId: kanbanCommentData.kanbanCardId,
                kanbanColumnId,
                eventEmitter,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardCommentsActions.createKanbanCardCommentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a kanban card comment.
   * Displays a success toast message and emits an event.
   * This effect does not dispatch any further actions.
   */
  createKanbanCardCommentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardCommentsActions.createKanbanCardCommentSuccess),
        tap(({ eventEmitter }) => {
          eventEmitter();
          this.toast.successToast('Success', 'Comment created successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing kanban card comment.
   * Listens for the `updateKanbanCardComment` action, checks permissions, and performs an HTTP PATCH request to update the comment.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanCardComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardCommentsActions.updateKanbanCardComment),
      checkPermission(KanbanCardCommentsActions.kanbanCommentUnauthorized),
      mergeMap(({ uuid, kanbanCommentData }) =>
        this.http
          .patch<KanbanComment>(
            `${environment.apiUrl}/v1/kanbans/cards/comments/${uuid}`,
            kanbanCommentData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanComment =>
              KanbanCardCommentsActions.updateKanbanCardCommentSuccess({
                kanbanComment,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardCommentsActions.updateKanbanCardCommentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a kanban card comment.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  updateKanbanCardCommentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardCommentsActions.updateKanbanCardCommentSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Comment updated successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a kanban card comment.
   * Listens for the `deleteKanbanCardComment` action, checks permissions, and performs an HTTP DELETE request to delete the comment.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanCardComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardCommentsActions.deleteKanbanCardComment),
      checkPermission(KanbanCardCommentsActions.kanbanCommentUnauthorized),
      mergeMap(({ uuid, kanbanCardId, kanbanColumnId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/kanbans/cards/comments/${uuid}`,
            {
              body: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardCommentsActions.deleteKanbanCardCommentSuccess({
                uuid,
                kanbanCardId,
                kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardCommentsActions.deleteKanbanCardCommentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban card comment.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteKanbanCardCommentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardCommentsActions.deleteKanbanCardCommentSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Comment deleted successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `KanbanCardCommentsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
