import { ProfileFile } from '~/app/shared/interfaces/crm/profile/profile-file.interface';

/**
 * Represents the state of profile files.
 *
 * @interface ProfileFilesState
 */
export interface ProfileFilesState {
  /**
   * An array of profile files.
   *
   * @type {ProfileFile[]}
   */
  profileFiles: ProfileFile[];

  /**
   * Indicates if the profile files are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile files.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile files within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileFilesState} initialProfileFilesState
 * @description Provides a baseline definition for the profile files context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileFilesState: ProfileFilesState = {
  profileFiles: [],
  loading: false,
  error: null,
};
