import { createAction, props } from '@ngrx/store';
import { ProfileNoteBody } from '~/app/shared/interfaces/crm/profile/profile-note-body.interface';
import { ProfileNote } from '~/app/shared/interfaces/crm/profile/profile-note.interface';

export const loadProfileNotes = createAction(
  '[Profile Notes] Load Profile Notes',
  props<{ profileId: string }>()
);
export const loadProfileNotesSuccess = createAction(
  '[Profile Notes] Load Profile Notes Success',
  props<{ profileNotes: ProfileNote[] }>()
);
export const loadProfileNotesFailure = createAction(
  '[Profile Notes] Load Profile Notes Failure',
  props<{ error: string }>()
);

export const createProfileNote = createAction(
  '[Profile Notes] Create Profile Note',
  props<{ profileNoteData: FormData }>()
);
export const createProfileNoteSuccess = createAction(
  '[Profile Notes] Create Profile Note Success',
  props<{ profileNote: ProfileNote }>()
);
export const createProfileNoteFailure = createAction(
  '[Profile Notes] Create Profile Note Failure',
  props<{ error: string }>()
);

export const updateProfileNote = createAction(
  '[Profile Notes] Update Profile Note',
  props<{ uuid: string; body: ProfileNoteBody }>()
);
export const updateProfileNoteSuccess = createAction(
  '[Profile Notes] Update Profile Note Success',
  props<{ profileNote: ProfileNote }>()
);
export const updateProfileNoteFailure = createAction(
  '[Profile Notes] Update Profile Note Failure',
  props<{ error: string }>()
);

export const deleteProfileNote = createAction(
  '[Profile Notes] Delete Profile Note',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileNoteSuccess = createAction(
  '[Profile Notes] Delete Profile Note Success',
  props<{ uuid: string }>()
);
export const deleteProfileNoteFailure = createAction(
  '[Profile Notes] Delete Profile Note Failure',
  props<{ error: string }>()
);

export const profileNotesUnauthorized = createAction(
  '[Profile Notes] Unauthorized',
  props<{ error: string }>()
);
