import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as SalesProjectsActions from '~/app/states/main/sales/profile/states/sales-projects/sales-projects.actions';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';
import { TimelineTaskSales } from '~/app/shared/interfaces/sales/timelines/timeline-task-sale.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * Effects for managing sales projects related actions.
 *
 * @export
 * @class SalesProjectsEffects
 */
@Injectable()
export class SalesProjectsEffects {
  /**
   * Effect to load sales projects.
   *
   * Listens for the `loadSalesProjects` action, checks permissions, makes an HTTP GET request to fetch
   * sales projects, and dispatches either a success or failure action based on the result.
   *
   * @memberof SalesProjectsEffects
   */
  loadSalesProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProjectsActions.loadSalesProjects),
      checkPermission(SalesProjectsActions.salesProjectsUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<Project[]>(`${environment.apiUrl}/v1/sales/${saleId}/projects`, {
            params: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesProjects =>
              SalesProjectsActions.loadSalesProjectsSuccess({ salesProjects })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesProjectsActions.loadSalesProjectsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to update the status of an assigned task.
   *
   * Listens for the `updateAssignedTaskStatus` action, checks permissions, makes an HTTP PATCH request
   * to update the task status, and dispatches either a success or failure action based on the result.
   *
   * @memberof SalesProjectsEffects
   */
  updateAssignedTaskStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProjectsActions.updateAssignedTaskStatus),
      checkPermission(SalesProjectsActions.salesProjectsUnauthorized),
      mergeMap(({ timelineTaskId, status }) =>
        this.http
          .patch<TimelineTaskSales>(
            `${environment.apiUrl}/v1/timelines/tasks/${timelineTaskId}/status`,
            { check: status },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(task =>
              SalesProjectsActions.updateAssignedTaskStatusSuccess({ task })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesProjectsActions.updateAssignedTaskStatusFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a sale task status.
   * It displays a success toast message.
   */
  updateAssignedTaskStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesProjectsActions.updateAssignedTaskStatusSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Task updated successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of SalesProjectsEffects.
   *
   * @param {Actions} actions$ - Stream of dispatched actions.
   * @param {HttpClient} http - HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @memberof SalesProjectsEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
