<form
  [formGroup]="salesForm"
  class="flex flex-column gap-2"
  (ngSubmit)="onSubmit()"
  [appComponentPermission]="iceRule.ReadSale">
  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'First Name'"
    [placeholder]="'FirstName'"
    [required]="true"
    [formControlName]="'firstname'"
    [formControl]="firstname">
  </ice-input>
  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'Last Name'"
    [placeholder]="'LastName'"
    [required]="true"
    [formControlName]="'lastname'"
    [formControl]="lastname"></ice-input>

  <ice-autocomplete
    class="w-full"
    [label]="'Status'"
    [formControl]="statusId"
    [formControlName]="'statusId'"
    [multiple]="false"
    [required]="true"
    placeholder="Select a status"
    i18n-placeholder
    [dropdown]="true"
    [optionLabel]="'label'"
    [iceAutocompleteTemplate]="templateEnum.STATUS"
    [suggestions]="(statuses$ | async)!">
    <ng-template let-status pTemplate="item">
      <div class="flex align-items-center gap-2">
        <div
          class="border-circle w-1rem h-1rem"
          [ngStyle]="{ 'background-color': status.color }"></div>
        <div>{{ status.name }}</div>
      </div>
    </ng-template>
    <ng-template let-status pTemplate="selectedItem">
      <div
        *ngIf="status; else noSelection"
        class="flex align-items-center gap-2">
        <div
          class="border-circle w-1rem h-1rem"
          [ngStyle]="{ 'background-color': status.color }"></div>
        <div>{{ status.name }}</div>
      </div>
      <ng-template #noSelection> </ng-template>
    </ng-template>
  </ice-autocomplete>

  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'Title'"
    [placeholder]="'Title'"
    [formControlName]="'title'"
    [formControl]="title"></ice-input>
  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'Street Address'"
    [placeholder]="'Street Address'"
    [formControlName]="'streetAddress'"
    [formControl]="streetAddress"></ice-input>
  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'City'"
    [placeholder]="'City'"
    [formControlName]="'city'"
    [formControl]="city"></ice-input>
  <ice-input
    [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
    [label]="'Postal Code'"
    [placeholder]="'Postal Code'"
    [formControlName]="'postalCode'"
    [formControl]="postalCode"></ice-input>

  <ice-autocomplete
    class="w-full"
    [label]="'Client Company'"
    [formControl]="clientCompanyId"
    [formControlName]="'clientCompanyId'"
    [multiple]="false"
    [required]="true"
    placeholder="Select a Company"
    i18n-placeholder
    [dropdown]="true"
    [optionLabel]="'label'"
    (completeMethod)="searchClientCompanies($event)"
    [suggestions]="(clientCompanies$ | async)!"></ice-autocomplete>

  <div class="sticky bottom-0">
    <p-divider></p-divider>
    <ng-container>
      <ice-button
        [rule]="initialData ? iceRule.UpdateSale : iceRule.CreateSale"
        class="flex justify-content-end"
        [disabled]="!salesForm.valid"
        [label]="initialData ? 'Update' : 'Create'"
        type="submit"
        [loading]="(isLoading$ | async) ?? false">
      </ice-button>
    </ng-container>
  </div>
</form>
