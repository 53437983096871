import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AlertComponent } from '~/app/shared/components/alert/alert.component';
import { Alert } from '~/app/shared/interfaces/alert.interface';
import { AlertEnum } from '~/app/shared/enums/alert.enum';

/**
 * Service to manage alert dialogs using PrimeNG's DynamicDialog.
 * @Injectable - Decorator that marks a class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /**
   * Reference to the dialog opened by the service.
   * @type {DynamicDialogRef | undefined}
   */
  ref: DynamicDialogRef | undefined;

  /**
   * Creates an instance of AlertService.
   * @param {DialogService} dialogService - Service for dynamic dialogs management.
   */
  constructor(private dialogService: DialogService) {}

  /**
   * Displays an alert dialog with specified header and alert configuration.
   * @param {string} header - The header title of the alert dialog.
   * @param {Alert} alert - The configuration and content for the alert.
   *
   * @return {void}
   */
  showAlert(header: string, alert: Alert): void {
    this.ref = this.dialogService.open(AlertComponent, {
      contentStyle: { padding: 0 },
      header: header,
      data: {
        ...alert,
        buttonActionMessage: alert.buttonActionMessage ?? 'Delete',
        type: alert.type ?? AlertEnum.DANGER,
      },
    });
  }
}
