import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanCardTasksActions from '~/app/states/main/kanban/states/kanban-card-tasks/kanban-card-tasks.actions';
import { KanbanTask } from '~/app/shared/interfaces/kanban/kanban-task/kanban-task.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `KanbanCardTasksEffects` manages the side effects for kanban card task-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban card tasks,
 * such as loading all kanban card tasks, creating, updating, deleting, checking, and unchecking kanban card tasks.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllKanbanCardTasks$`: Loads all kanban card tasks. It performs an HTTP GET request and dispatches a success action with the fetched tasks or a failure action if an error occurs.
 * — `createKanbanCardTask$`: Creates a new kanban card task. It performs an HTTP POST request and dispatches a success action with the created task or a failure action if an error occurs.
 * — `updateKanbanCardTask$`: Updates an existing kanban card task. It performs an HTTP PATCH request and dispatches a success action with the updated task or a failure action if an error occurs.
 * — `deleteKanbanCardTask$`: Deletes a kanban card task. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `checkKanbanCardTask$`: Checks a kanban card task. It performs an HTTP PATCH request and dispatches a success action with the updated task or a failure action if an error occurs.
 * — `uncheckKanbanCardTask$`: Unchecks a kanban card task. It performs an HTTP PATCH request and dispatches a success action with the updated task or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanCardTasksEffects {
  /**
   * Effect to load all kanban card tasks.
   * Listens for the `loadAllKanbanCardTasks` action, checks permissions, and performs an HTTP GET request to fetch the tasks.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbanCardTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.loadAllKanbanCardTasks),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ kanbanCardId }) =>
        this.http
          .get<KanbanTask[]>(`${environment.apiUrl}/v1/kanbans/cards/tasks`, {
            params: { kanbanCardId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanbanTasks =>
              KanbanCardTasksActions.loadAllKanbanCardTasksSuccess({
                kanbanTasks,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.loadAllKanbanCardTasksFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban card task.
   * Listens for the `createKanbanCardTask` action, checks permissions, and performs an HTTP POST request to create the task.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanCardTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.createKanbanCardTask),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ kanbanCardId, kanbanTaskData }) =>
        this.http
          .post<KanbanTask>(
            `${environment.apiUrl}/v1/kanbans/cards/tasks`,
            kanbanTaskData,
            {
              params: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(kanbanTask =>
              KanbanCardTasksActions.createKanbanCardTaskSuccess({
                kanbanTask,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.createKanbanCardTaskFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to update an existing kanban card task.
   * Listens for the `updateKanbanCardTask` action, checks permissions, and performs an HTTP PATCH request to update the task.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanCardTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.updateKanbanCardTask),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ uuid, kanbanTaskData }) =>
        this.http
          .patch<KanbanTask>(
            `${environment.apiUrl}/v1/kanbans/cards/tasks/${uuid}`,
            kanbanTaskData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanTask =>
              KanbanCardTasksActions.updateKanbanCardTaskSuccess({
                kanbanTask,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.updateKanbanCardTaskFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to delete a kanban card task.
   * Listens for the `deleteKanbanCardTask` action, checks permissions, and performs an HTTP DELETE request to delete the task.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanCardTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.deleteKanbanCardTask),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ uuid, kanbanCardId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/kanbans/cards/tasks/${uuid}`,
            {
              body: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardTasksActions.deleteKanbanCardTaskSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.deleteKanbanCardTaskFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to check a kanban card task.
   * Listens for the `checkKanbanCardTask` action, checks permissions, and performs an HTTP PATCH request to check the task.
   * Dispatches a success or failure action based on the result.
   */
  checkKanbanCardTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.checkKanbanCardTask),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ uuid, kanbanCardId }) =>
        this.http
          .patch<KanbanTask>(
            `${environment.apiUrl}/v1/kanbans/cards/tasks/${uuid}/check`,
            { kanbanCardId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanTask =>
              KanbanCardTasksActions.updateKanbanCardTaskSuccess({
                kanbanTask,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.checkKanbanCardTaskFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to uncheck a kanban card task.
   * Listens for the `uncheckKanbanCardTask` action, checks permissions, and performs an HTTP PATCH request to uncheck the task.
   * Dispatches a success or failure action based on the result.
   */
  uncheckKanbanCardTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardTasksActions.uncheckKanbanCardTask),
      checkPermission(KanbanCardTasksActions.kanbanCardTasksUnauthorized),
      mergeMap(({ uuid, kanbanCardId }) =>
        this.http
          .patch<KanbanTask>(
            `${environment.apiUrl}/v1/kanbans/cards/tasks/${uuid}/uncheck`,
            { kanbanCardId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanTask =>
              KanbanCardTasksActions.updateKanbanCardTaskSuccess({
                kanbanTask,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardTasksActions.uncheckKanbanCardTaskFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `KanbanCardTasksEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
