import { createReducer, on } from '@ngrx/store';
import * as SalesActions from '~/app/states/main/sales/profile/states/sales/sales.actions';
import { initialSalesState } from '~/app/states/main/sales/profile/states/sales/sales.state';

export const saleReducer = createReducer(
  initialSalesState,

  on(SalesActions.loadSale, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesActions.loadSaleSuccess, (state, { sales }) => ({
    ...state,
    sales,
    loading: false,
  })),
  on(SalesActions.loadSaleFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(SalesActions.createSale, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesActions.createSaleSuccess, (state, { sale }) => ({
    ...state,
    sale,
    loading: false,
  })),
  on(SalesActions.createSaleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesActions.updateSale, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesActions.updateSaleSuccess, (state, { sale }) => ({
    ...state,
    sales: sale,
    loading: false,
  })),
  on(SalesActions.updateSaleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesActions.salesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
