import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Rate } from '@npmicedev/icemodule/lib/enums/rate';
import { FormGroup } from '@angular/forms';
import { GoalScope } from '@npmicedev/icemodule/lib/enums/goalScope';
import {
  createGoalValidation,
  updateGoalByIdValidation,
} from '@npmicedev/icemodule/lib/validations/goals';
import { GoalType } from '@npmicedev/icemodule/lib/enums/goalType';
import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { GoalsStates } from '~/app/states/main/stats/states/goals/goals.states';
import * as GoalsActions from '~/app/states/main/stats/states/goals/goals.actions';
import { GoalResponse } from '~/app/states/main/stats/types/goal-response.types';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * `GoalsService` provides methods to interact with the NgRx store for managing goals,
 * including loading goals, pinning, and unpinning them.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  /**
   * Constructor for `GoalsService`.
   *
   * @param {Store<GoalsStates>} store - The NgRx store for state management related to goals.
   * @param {CustomToastService} toast - The Service for handling the toasts.
   */
  constructor(
    private store: Store<GoalsStates>,
    private toast: CustomToastService
  ) {}

  /**
   * Dispatches an action to load pinned goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadPinnedGoals(limit: number, page: number): void {
    this.store.dispatch(GoalsActions.loadPinnedGoals({ limit, page }));
  }

  /**
   * Dispatches an action to load daily goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadDailyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Daily })
    );
  }

  /**
   * Dispatches an action to load weekly goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadWeeklyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Weekly })
    );
  }

  /**
   * Dispatches an action to load monthly goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadMonthlyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Monthly })
    );
  }

  /**
   * Dispatches an action to load the selected goal.
   *
   * @param {string} goalUUID - The uuid of the goal to load.
   *
   * @return {void}
   */
  loadGoalById(goalUUID: string): void {
    this.store.dispatch(GoalsActions.loadGoalById({ goalUUID }));
  }

  /**
   * Dispatches an action to delete the selected goal.
   *
   * @param {string} goalUUID - The uuid of the goal to load.
   *
   * @return {void}
   */
  deleteGoalById(goalUUID: string): void {
    this.store.dispatch(GoalsActions.deleteGoalById({ goalUUID }));
  }

  /**
   * Dispatches an action to create a goal.
   *
   * @param {FormGroup} formGroup - The formGroup containing all the information to create the goal.*
   * @return {void}
   */
  createGoal(formGroup: FormGroup): void {
    const getControlValue = (controlName: string): any => {
      const control = formGroup.get(controlName);
      const value = control ? control.value : null;
      return value && typeof value === 'object' ? value.value : value;
    };

    let goal: any = {
      target: getControlValue('target'),
      goalType: getControlValue('goalType'),
      goal: parseInt(getControlValue('goal'), 10),
      name: getControlValue('name'),
      rate: getControlValue('rate'),
      scope: getControlValue('scope'),
      userId: getControlValue('userId'),
      teamId: getControlValue('teamId'),
      hasNotification: getControlValue('hasNotification'),
    };

    if (goal.hasNotification === true) {
      goal.hasNotification = 'false';
    } else if (goal.hasNotification === false) {
      goal.hasNotification = null;
    }

    switch (formGroup.get('scope')?.value.value) {
      case GoalScope.User:
        delete goal.teamId;
        break;
      case GoalScope.Team:
        delete goal.userId;
        break;
      case GoalScope.Company:
        delete goal.userId;
        delete goal.teamId;
        break;
    }

    if (goal.goalType === GoalType.CHANGE_STATUS_BETWEEN) {
      goal = {
        ...goal,
        oldStatus: getControlValue('oldStatus'),
        newStatus: getControlValue('newStatus'),
      };
    }

    if (goal.goalType === GoalType.CHANGE_TYPE) {
      goal = {
        ...goal,
        oldType: getControlValue('oldType'),
        newType: getControlValue('newType'),
      };
    }

    if (goal.goalType === GoalType.DOCUMENT_UPLOADED) {
      goal = {
        ...goal,
        fileType: getControlValue('fileType'),
      };
    }

    const result = createGoalValidation.body.safeParse(goal);

    if (result.success) this.store.dispatch(GoalsActions.createGoal({ goal }));
    else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Dispatches an action to update the selected goal.
   *
   * @param {FormGroup} formGroup - The formGroup containing all the information to update the goal.
   * @param {string} uuid - The uuid of the goal to load.
   *
   * @return {void}
   */
  updateGoalById(formGroup: FormGroup, uuid: string): void {
    const getControlValue = (controlName: string): any => {
      const control = formGroup.get(controlName);
      const value = control ? control.value : null;
      return value && typeof value === 'object' ? value.value : value;
    };

    let goal: any = {
      uuid,
      name: getControlValue('name'),
      target: getControlValue('target'),
      goalType: getControlValue('goalType'),
      goal: parseInt(getControlValue('goal'), 10),
      rate: getControlValue('rate'),
      scope: getControlValue('scope'),
      userId: getControlValue('userId'),
      teamId: getControlValue('teamId'),
      hasNotification: getControlValue('hasNotification'),
    };

    switch (formGroup.get('scope')?.value.value) {
      case GoalScope.User:
        delete goal.teamId;
        break;
      case GoalScope.Team:
        delete goal.userId;
        break;
      case GoalScope.Company:
        delete goal.userId;
        delete goal.teamId;
        break;
    }

    if (goal.goalType === GoalType?.CHANGE_STATUS_BETWEEN) {
      goal = {
        ...goal,
        oldStatus: getControlValue('oldStatus'),
        newStatus: getControlValue('newStatus'),
      };
    }

    if (goal.goalType === GoalType?.CHANGE_TYPE) {
      goal = {
        ...goal,
        oldType: getControlValue('oldType'),
        newType: getControlValue('newType'),
      };
    }

    if (goal.goalType === GoalType?.DOCUMENT_UPLOADED) {
      goal = {
        ...goal,
        fileType: getControlValue('fileType'),
      };
    }

    const result = updateGoalByIdValidation.body.safeParse(goal);

    if (result.success)
      this.store.dispatch(GoalsActions.updateGoalById({ goal }));
    else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Dispatches an action to pin a goal.
   *
   * @param {GoalResponse} goal - The unique identifier of the goal to pin.
   *
   * @return {void}
   */
  pinGoal(goal: GoalResponse): void {
    this.store.dispatch(GoalsActions.pinGoal({ goal }));
  }

  /**
   * Dispatches an action to unpin a goal.
   *
   * @param {GoalResponse} goal - The unique identifier of the goal to unpin.
   *
   * @return {void}
   */
  unpinGoal(goal: GoalResponse): void {
    this.store.dispatch(GoalsActions.unpinGoal({ goal }));
  }

  /**
   * Dispatches an action to load the users.
   *
   * @param {string} name - The string which but be in the name of the users.
   *
   * @return {void}
   */
  loadGoalUsers(name?: string): void {
    this.store.dispatch(GoalsActions.loadGoalUsers({ name }));
  }

  /**
   * Dispatches an action to load the teams.
   *
   * @param {string} name - The string which but be in the name of the teams.
   *
   * @return {void}
   */
  loadGoalTeams(name?: string): void {
    this.store.dispatch(GoalsActions.loadGoalTeams({ name }));
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Assigns the given goals to the user by dispatching the action
   * `GoalsActions.assignGoalsUserServer` with the provided goals.
   *
   * This method triggers the Redux action that assigns a set of goals to the current user.
   * It uses the `goals` parameter to pass the relevant goals to the action.
   *
   * @param {Goals} goals - The goals to be assigned to the user.
   * @returns {void} - No return value. The method only dispatches an action.
   */
  assignUserServer(goals: Goals): void {
    this.store.dispatch(GoalsActions.assignGoalsUserServer({ goals }));
  }

  /**
   * Unassigns the given goals from the user by dispatching the action
   * `GoalsActions.unassignGoalsUserServer` with the provided goals.
   *
   * This method triggers the Redux action that unassigns a set of goals from the current user.
   * It uses the `goals` parameter to pass the relevant goals to the action for unassignment.
   *
   * @param {Goals} goals - The goals to be unassigned from the user.
   * @returns {void} - No return value. The method only dispatches an action.
   */
  unassignUserServer(goals: Goals): void {
    this.store.dispatch(GoalsActions.unassignGoalsUserServer({ goals }));
  }
}
