import { createReducer, on } from '@ngrx/store';
import * as ProfileInterviewsActions from '~/app/states/main/crm/profile/states/profile-interviews/profile-interviews.actions';
import { initialProfileInterviewsState } from '~/app/states/main/crm/profile/states/profile-interviews/profile-interviews.state';

export const profileInterviewsReducer = createReducer(
  initialProfileInterviewsState,

  on(ProfileInterviewsActions.loadProfileInterviews, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileInterviewsActions.loadProfileInterviewsSuccess,
    (state, { profileInterviews }) => ({
      ...state,
      profileInterviews,
      loading: false,
    })
  ),
  on(
    ProfileInterviewsActions.loadProfileInterviewsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileInterviewsActions.loadWeeklyProfileInterviews, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileInterviewsActions.loadWeeklyProfileInterviewsSuccess,
    (state, { weeklyProfileInterviews }) => ({
      ...state,
      weeklyProfileInterviews,
      loading: false,
    })
  ),
  on(
    ProfileInterviewsActions.loadWeeklyProfileInterviewsFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  on(ProfileInterviewsActions.createProfileInterview, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileInterviewsActions.createProfileInterviewSuccess,
    (state, { profileInterview }) => ({
      ...state,
      loading: false,
      profileInterviews: [...state.profileInterviews, profileInterview],
    })
  ),
  on(
    ProfileInterviewsActions.createProfileInterviewFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileInterviewsActions.updateProfileInterview, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileInterviewsActions.updateProfileInterviewSuccess,
    (state, { profileInterview }) => ({
      ...state,
      loading: false,
      profileInterviews: state.profileInterviews.map(item =>
        item.uuid === profileInterview.uuid ? profileInterview : item
      ),
    })
  ),
  on(
    ProfileInterviewsActions.updateProfileInterviewFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileInterviewsActions.deleteProfileInterview, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileInterviewsActions.deleteProfileInterviewSuccess,
    (state, { uuid }) => ({
      ...state,
      profileInterviews: state.profileInterviews.filter(
        item => item.uuid !== uuid
      ),
    })
  ),
  on(
    ProfileInterviewsActions.deleteProfileInterviewFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileInterviewsActions.profileInterviewsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
