import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as OfficesAction from '~/app/states/main/settings/states/offices/offices.actions';
import { AllOfficesState } from '~/app/states/main/settings/states/offices/offices.state';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `OfficesEffects` manages side effects related to office data in the application using Angular's NgRx Effects.
 * It handles asynchronous operations such as loading, creating, updating, and deleting office information.
 *
 * Effects included in this class:
 * - `loadOffices$`: Fetches office data from the server.
 * - `createOffice$`: Creates a new office on the server.
 * - `updateOffice$`: Updates an existing office on the server.
 * - `deleteOffice$`: Deletes an office from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class OfficesEffects {
  /**
   * Creates an observable effect to load office data. Listens for the `loadOffices` action,
   * checks permissions, and fetches data from the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  loadOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfficesAction.loadOffices),
      checkPermission(OfficesAction.officesUnauthorized),
      mergeMap(({ limit, page }) =>
        this.http
          .get<AllOfficesState>(`${environment.apiUrl}/v1/offices`, {
            params: { limit, page },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offices => OfficesAction.loadOfficesSuccess({ offices })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OfficesAction.loadOfficesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to create a new office. Listens for the `createOffice` action,
   * checks permissions, and creates a new office on the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  createOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfficesAction.createOffice),
      checkPermission(OfficesAction.officesUnauthorized),
      mergeMap(({ officeData }) =>
        this.http
          .post<Office>(`${environment.apiUrl}/v1/offices`, officeData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(office => OfficesAction.createOfficeSuccess({ office })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OfficesAction.createOfficeFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to handle successful office creation.
   * Listens for the `createOfficeSuccess` action and displays a success toast.
   */
  createOfficeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfficesAction.createOfficeSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Office created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an observable effect to update an existing office. Listens for the `updateOffice` action,
   * checks permissions, and updates an office on the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  updateOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfficesAction.updateOffice),
      checkPermission(OfficesAction.officesUnauthorized),
      mergeMap(({ uuid, officeData }) =>
        this.http
          .patch<Office>(
            `${environment.apiUrl}/v1/offices/${uuid}`,
            officeData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(office => OfficesAction.updateOfficeSuccess({ office })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OfficesAction.updateOfficeFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to handle successful office updates.
   * Listens for the `updateOfficeSuccess` action and displays a success toast.
   */
  updateOfficeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfficesAction.updateOfficeSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Office updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an observable effect to delete an office. Listens for the `deleteOffice` action,
   * checks permissions, and deletes an office from the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  deleteOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfficesAction.deleteOffice),
      checkPermission(OfficesAction.officesUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/offices/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => OfficesAction.deleteOfficeSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OfficesAction.deleteOfficeFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to handle successful office deletion.
   * Listens for the `deleteOfficeSuccess` action and displays a success toast.
   */
  deleteOfficeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfficesAction.deleteOfficeSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Office deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for OfficesEffects.
   * @param {Actions} actions$ Injected actions observable that allows the effect to react to actions dispatched to the store.
   * @param {HttpClient} http Injected HttpClient for making API calls.
   * @param {HttpErrorsService} httpErrors Injected service for handling HTTP error responses.
   * @param {CustomToastService} toast Injected service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injected service for managing sidebar state.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
