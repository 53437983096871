import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { LoginService } from '~/app/auth/services/login.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { HeaderItemGroup } from '~/app/shared/interfaces/navigation/header-item-group.interface';
import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';
import { SidenavService } from '~/app/shared/services/sidenav.service';
import { selectQuickActions } from '~/app/shared/states/quick-actions/quick-actions.selectors';

/**
 * The PublicHeaderComponent is responsible for rendering the header of the application when the user is not authenticated.
 * It provides navigation links and actions for the user to interact with the application.
 */
@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrl: './public-header.component.scss',
})
export class PublicHeaderComponent implements OnInit {
  /**
   * A boolean flag to determine the current theme setting.
   *
   * When set to true, the light theme is applied.
   * When set to false, the dark theme is applied.
   * @type {boolean}
   */
  public isLightTheme = true;

  /**
   * Items to be displayed in the header. Each item can have sub-items and associated actions or navigation links.
   * @type {HeaderItemGroup[]}
   */
  items: HeaderItemGroup[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'My profile',
          escape: false,
          icon: 'pi pi-user',
          iconClass: 'text-xl',
          action: (): void => {
            void this.router.navigateByUrl(`/profile`);
          },
        },
        {
          label: 'Settings',
          escape: false,
          icon: 'pi pi-cog',
          iconClass: 'text-xl',
        },
        {
          label: 'Theme',
          customContent: true,
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          action: (): void => {
            this.logout();
          },
        },
      ],
    },
  ];

  /**
   * User currently logged-in.
   * @type {Observable<AuthUser | null>}
   */
  user$: Observable<AuthUser | null>;

  /**
   * Observable stream of QuickActionItem objects representing the quick actions available in the header.
   *
   * @type {Observable<QuickActionItem[]>}
   */
  headerActions$!: Observable<QuickActionItem[]>;

  /**
   * Observable stream of MenuItem objects representing the current URL path segments.
   * @type {Observable<MenuItem[]>}
   */
  public urlSegments$!: Observable<MenuItem[]>;

  /**
   * Constructor for HeaderComponent, setting up and initializing various services and state observables necessary for its operation.
   * This component serves as the main header for the application, providing navigation and quick actions.
   *
   * @param {LoginService} loginService - Service handling user authentication and logout procedures.
   * @param {ThemeService} theme - Service for managing the application's theme settings.
   * @param {Store<AppState>} store - Global state store of the application, used here to select and manage application-wide settings like quick actions.
   * @param {Router} router - Angular's Router for subscribing to and handling navigation changes.
   * @param {SidenavService} sidenavService - Service for managing the sidebar's state, content, and visibility within the application.
   */
  constructor(
    private loginService: LoginService,
    private store: Store<AppState>,
    private router: Router,
    private sidenavService: SidenavService,
    private theme: ThemeService
  ) {
    this.headerActions$ = this.store.select(selectQuickActions);
    this.user$ = this.store.select(selectCurrentUser);
    this.isLightTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Initializes the component, subscribing to navigation events to update the URL path segments displayed in the header.
   *
   * @return {void}
   */
  ngOnInit(): void {
    const urlSegments = this.router.url.split('/');
    if (urlSegments.length > 0) {
      this.sidenavService.changeCurrentModule(urlSegments[1]);
    }
    this.urlSegments$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(new NavigationEnd(0, this.router.url, this.router.url)),
      map(event => this.createMenuItems(event.urlAfterRedirects))
    );
  }

  /**
   * Logs the user out of the application using the LoginService.
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Toggles the application's theme between light and dark.
   * @return {void}
   */
  onThemeToggle(): void {
    const currentTheme = localStorage.getItem('app-theme') ?? 'light';
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    document.body.setAttribute('app-theme', newTheme);
    localStorage.setItem('app-theme', newTheme);
    this.isLightTheme = newTheme === 'light';
    this.theme.initTheme();
  }

  /**
   * Creates a list of MenuItem objects representing the current URL path segments.
   * @param {string} url - The current URL path.
   * @return {MenuItem[]} - The MenuItem objects representing the URL path segments.
   */
  private createMenuItems(url: string): MenuItem[] {
    const segments = url.split('/').filter(segment => segment);
    const menuItems: MenuItem[] = [];
    let cumulativePath = '';

    segments.forEach(segment => {
      cumulativePath += `/${segment}`;
      if (segment === 'home') {
        return;
      }
      if (!(menuItems.length !== 0 && menuItems[0].label === 'Kanban')) {
        menuItems.push({
          label: segment.charAt(0).toUpperCase() + segment.slice(1),
          routerLink: cumulativePath,
        });
      }
    });
    return menuItems;
  }
}
