import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import iceRule from '@npmicedev/icemodule/lib/utils/rules';
import { FileSelectEvent } from 'primeng/fileupload';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { KanbansService } from '~/app/main/kanban/services/kanbans.service';
import { AlertService } from '~/app/shared/services/alert.service';
import { Kanban } from '~/app/shared/interfaces/kanban/kanban.interface';
import { AllKanbansState } from '~/app/main/kanban/states/kanbans/kanbans.state';
import { selectKanbanLoading } from '~/app/main/kanban/states/kanbans/kanbans.selectors';

/**
 * CudKanbanComponent is responsible for creating, updating, and deleting a kanban.
 * It provides a form for user input and handles form submission and file upload events.
 *
 * @Component
 * @selector 'app-cud-kanban' — CSS selector that identifies this component in a template.
 * @templateUrl './cud-kanban.component.html' — Path to the HTML template for this component.
 * @styleUrls ['./cud-kanban.component.scss'] — Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-cud-kanban',
  templateUrl: './cud-kanban.component.html',
  styleUrls: ['./cud-kanban.component.scss'],
})
export class CudKanbanComponent implements OnInit {
  /**
   * Initial data for the kanban, used to prefill the form in case of editing.
   * @type {Kanban | null}
   */
  @Input() initialData: Kanban | null = null;

  /**
   * FormGroup that encapsulates the kanban form controls and validators.
   * @type {FormGroup}
   */
  kanbanForm!: FormGroup;

  /**
   * Observable for the loading state of the kanban.
   *
   * @type {Observable<boolean>}
   */
  isKanbanLoading$: Observable<boolean>;

  /**
   * A utility rule imported from IceModule.
   */
  protected readonly iceRule = iceRule;

  /**
   * Constructor for CudKanbanComponent.
   *
   * Initializes necessary services for form building, kanban operations, and alert handling.
   *
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {KanbansService} kanbansService — Service for managing kanban operations.
   * @param {AlertService} alertService — Service for displaying alerts.
   * @param {Store<AllKanbansState>} kanbanStore - Store for managing all the kanban state.
   */
  constructor(
    private formBuilder: FormBuilder,
    private kanbansService: KanbansService,
    private alertService: AlertService,
    private kanbanStore: Store<AllKanbansState>
  ) {
    this.isKanbanLoading$ = this.kanbanStore.select(selectKanbanLoading);
  }

  /**
   * Getter for accessing the 'name' FormControl within the kanban form.
   * @type {FormControl}
   */
  public get name(): FormControl {
    return this.kanbanForm.get('name') as FormControl;
  }

  /**
   * Getter for accessing the 'description' FormControl within the kanban form.
   * @type {FormControl}
   */
  public get description(): FormControl {
    return this.kanbanForm.get('description') as FormControl;
  }

  /**
   * Getter for accessing the 'backgroundUrl' FormControl within the kanban form.
   * @type {FormControl}
   */
  public get file(): FormControl {
    return this.kanbanForm.get('backgroundUrl') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the form.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the kanban form with default values and validators.
   * Sets up the form controls with required validators and specific constraints for each field.
   * If initial data is provided, it patches the form with this data.
   * @return {void}
   */
  initForm(): void {
    this.kanbanForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
        ],
      ],
      description: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      backgroundUrl: [],
    });

    if (this.initialData) {
      this.kanbanForm.patchValue(this.initialData);
    }
  }

  /**
   * Handles file upload event and patches the form with the selected file.
   * @param {FileSelectEvent} event — The file select event containing the uploaded files.
   * @return {void}
   */
  onUpload(event: FileSelectEvent): void {
    this.kanbanForm.patchValue({
      backgroundUrl: event.files[0],
    });
  }

  /**
   * Handles form submission for creating or updating a kanban.
   * If initial data is provided, it updates the kanban; otherwise, it creates a new kanban.
   * @return {void}
   */
  onSubmit(): void {
    if (this.initialData && this.initialData.uuid) {
      this.kanbansService.updateKanbanById(
        this.initialData.uuid,
        this.kanbanForm
      );
    } else {
      this.kanbansService.createKanban(this.kanbanForm);
    }
  }

  /**
   * Handles the deletion of a kanban and shows a confirmation alert.
   *
   * @return {void}
   */
  onDelete(): void {
    this.alertService.showAlert('Delete Confirmation', {
      title: 'Are you sure you want to delete this kanban?',
      message: 'This action cannot be undone',
      action: () => {
        this.kanbansService.deleteKanbanById(this.initialData?.uuid ?? '');
      },
    });
  }
}
