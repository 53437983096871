import { createAction, props } from '@ngrx/store';
import {
  CreateKanbanTag,
  UpdateKanbanTag,
} from '~/app/states/main/kanban/types/kanban-tags.types';
import { KanbanTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-tag.interface';
import { KanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.state';

export const loadAllKanbanTags = createAction(
  '[Kanban Tags] Load All Kanban Tags',
  props<{ kanbanId: string }>()
);
export const loadAllKanbanTagsSuccess = createAction(
  '[Kanban Tags] Load All Kanban Tags Success',
  props<{ kanbanTags: KanbanTag[] }>()
);
export const loadAllKanbanTagsFailure = createAction(
  '[Kanban Tags] Load All Kanban Tags Failure',
  props<{ error: string }>()
);

export const createKanbanTag = createAction(
  '[Kanban Tags] Create Kanban Tag',
  props<{ kanbanCardId?: string; kanbanTagData: CreateKanbanTag }>()
);
export const createKanbanTagSuccess = createAction(
  '[Kanban Tags] Create Kanban Tag Success',
  props<{ kanbanTag: KanbanTag }>()
);
export const createKanbanTagFailure = createAction(
  '[Kanban Tags] Create Kanban Tag Failure',
  props<{ error: string }>()
);

export const updateKanbanTag = createAction(
  '[Kanban Tags] Update Kanban Tag',
  props<{ uuid: string; kanbanTagData: UpdateKanbanTag }>()
);
export const updateKanbanTagSuccess = createAction(
  '[Kanban Tags] Update Kanban Tag Success',
  props<{ uuid: string; kanbanTag: KanbanTag }>()
);
export const updateKanbanTagFailure = createAction(
  '[Kanban Tags] Update Kanban Tag Failure',
  props<{ error: string }>()
);

export const deleteKanbanTag = createAction(
  '[Kanban Tags] Delete Kanban Tag',
  props<{ uuid: string; kanbanId: string }>()
);
export const deleteKanbanTagSuccess = createAction(
  '[Kanban Tags] Delete Kanban Tag Success',
  props<{ uuid: string; kanbanColumns: KanbanColumnsState }>()
);
export const deleteKanbanTagFailure = createAction(
  '[Kanban Tags] Delete Kanban Tag Failure',
  props<{ error: string }>()
);

export const createKanbanTagServer = createAction(
  '[Kanban Tags] Create Kanban Tag Server',
  props<{ kanbanTag: KanbanTag }>()
);
export const deleteKanbanTagServer = createAction(
  '[Kanban Tags] Delete Kanban Tag Server',
  props<{ uuid: string }>()
);

export const kanbanTagsUnauthorized = createAction(
  '[Kanban Tags] Unauthorized',
  props<{ error: string }>()
);
