import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Social } from '~/app/shared/interfaces/shared/social.interface';
import * as SocialNetworksActions from '~/app/states/main/sales/profile/states/sales-socials/sales-socials.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesSocialEffects` manages the side effects for sales social network-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales social networks, such as loading, creating, updating, and deleting social networks.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSocialNetworks$`: Loads the social networks associated with a specific sales record.
 * — `createSocialNetwork$`: Creates a new social network record for a specific sales record.
 * — `createSocialNetworkSuccess$`: Handles the success of creating a social network by displaying a success toast message and closing the sidebar.
 * — `updateSocialNetwork$`: Updates an existing social network record for a specific sales record.
 * — `updateSocialNetworkSuccess$`: Handles the success of updating a social network by displaying a success toast message and closing the sidebar.
 * — `deleteSocialNetwork$`: Deletes a social network record associated with a specific sales record.
 * — `deleteSocialNetworkSuccess$`: Handles the success of deleting a social network by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesSocialEffects {
  /**
   * Effect to load social networks associated with a specific sales record.
   * It listens for the `loadSocialNetworks` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded social networks or a failure action if an error occurs.
   */
  loadSocialNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.loadSocialNetworks),
      checkPermission(SocialNetworksActions.salesSocialNetworksUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<Social[]>(`${environment.apiUrl}/v1/sales/socialNetworks`, {
            params: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(socials =>
              SocialNetworksActions.loadSocialNetworksSuccess({
                socials,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.loadSocialNetworksFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new social network record for a specific sales record.
   * It listens for the `createSocialNetwork` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created social network or a failure action if an error occurs.
   */
  createSocialNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.createSocialNetwork),
      checkPermission(SocialNetworksActions.salesSocialNetworksUnauthorized),
      mergeMap(({ saleSocialNetwork }) =>
        this.http
          .post<Social>(
            `${environment.apiUrl}/v1/sales/socialNetworks`,
            saleSocialNetwork,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(social =>
              SocialNetworksActions.createSocialNetworkSuccess({
                social,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.createSocialNetworkFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a social network.
   * It displays a success toast message and closes the sidebar.
   */
  createSocialNetworkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SocialNetworksActions.createSocialNetworkSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Social added successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a social network record associated with a specific sales record.
   * It listens for the `deleteSocialNetwork` action, checks for permissions, and performs an HTTP DELETE request.
   * Upon successful deletion, it dispatches a success action or a failure action if an error occurs.
   */
  deleteSocialNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.deleteSocialNetwork),
      checkPermission(SocialNetworksActions.salesSocialNetworksUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/sales/socialNetworks/${uuid}`,
            {
              body: { saleId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              SocialNetworksActions.deleteSocialNetworkSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.deleteSocialNetworkFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a social network.
   * It displays a success toast message.
   */
  deleteSocialNetworkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SocialNetworksActions.deleteSocialNetworkSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Social deleted successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesSocialEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService — Injectable service for handling sidebar form operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
