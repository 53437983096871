import { TypedAction } from '@ngrx/store/src/models';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { CompanyFormData } from '~/app/shared/interfaces/auth/register/company-form-data.interface';
import { OfficeFormData } from '~/app/shared/interfaces/auth/register/office-form-data.interface';
import { RootUserFormData } from '~/app/shared/interfaces/auth/register/root-user-form-data.interface';

/**
 * Represents the authentication state of the application, encapsulating various aspects of user authentication and session management.
 *
 * @interface AuthState
 */
export interface AuthState {
  /**
   * Token used to maintain secure sessions across HTTP requests. `null` indicates no active session.
   *
   * @type {string | null}
   */
  secureLoginToken: string | null;

  /**
   * Information about the authenticated user. `null` if no user is authenticated.
   *
   * @type {AuthUser | null}
   */
  userData: AuthUser | null;

  /**
   * Holds data for company registration forms temporarily during the registration process. `null` when not in use.
   *
   * @type {CompanyFormData | null}
   */
  companyFormData: CompanyFormData | null;

  /**
   * Holds data for office setup or modification forms temporarily. Used during the setup or editing of an office. `null` when not in use.
   *
   * @type {OfficeFormData | null}
   */
  officeFormData: OfficeFormData | null;

  /**
   * Contains data for setting up the initial root user account during system setup. `null` when no setup is underway.
   *
   * @type {RootUserFormData | null}
   */
  rootUserFormData: RootUserFormData | null;

  /**
   * The last executed request in order to resend it if it's needed
   * @type {TypedAction<any> | null}
   */
  lastRequest: TypedAction<any> | null;

  /**
   * Contains error messages related to authentication processes, such as failed login attempts or session expirations. `null` if there are no errors.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * Indicates if an authentication-related process is ongoing and if the UI should display loading indicators.
   *
   * @type {boolean}
   */
  loading: boolean;
}

/**
 * The initial state of the authentication module.
 * This object provides default values for all properties in the AuthState interface.
 *
 * @type {AuthState}
 */
export const initialAuthState: AuthState = {
  secureLoginToken: '',
  userData: null,
  companyFormData: null,
  officeFormData: null,
  rootUserFormData: null,
  lastRequest: null,
  loading: false,
  error: null,
};
