import { createSelector } from '@ngrx/store';
import { selectAuthState, selectCurrentUser } from '~/app/auth/auth.selectors';

import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';

export const selectSecureLoginToken = createSelector(
  selectAuthState,
  state => state.secureLoginToken
);

export const selectToken = createSelector(
  selectCurrentUser,
  (user: AuthUser | null) => user?.token
);

export const selectLastRequest = createSelector(
  selectAuthState,
  state => state.lastRequest
);
