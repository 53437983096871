import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import * as SalesQuickNavActions from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.actions';
import { selectSalesUUIDs } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.selectors';
import { SalesQuickNavState } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.state';

/**
 * `SalesQuickNavEffects` manages the side effects for sales quick navigation actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to navigating between sales profiles.
 * It performs operations such as navigating to the next or previous sales profile based on the current profile's UUID.
 *
 * The effects in this class include:
 * — `salesNavUp$`: Navigates to the next sales profile when the `salesQuickNavUp` action is dispatched.
 * — `salesNavDown$`: Navigates to the previous sales profile when the `salesQuickNavDown` action is dispatched.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesQuickNavEffects {
  /**
   * Effect to navigate to the next sales profile.
   * It listens for the `salesQuickNavUp` action, retrieves the list of sales UUIDs from the store, and navigates to the next profile based on the current profile's UUID.
   */
  salesNavUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesQuickNavActions.salesQuickNavUp),
        tap(({ currentUUID }) => {
          this.store
            .select(selectSalesUUIDs)
            .pipe(take(1))
            .subscribe(uuids => {
              const index = uuids.findIndex(uuid => uuid === currentUUID);
              if (index + 1 < uuids.length) {
                void this.router.navigate(
                  ['/sales/profile/' + uuids[index + 1]],
                  {
                    queryParams: { fromPrevProfile: true },
                  }
                );
              }
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to navigate to the previous sales profile.
   * It listens for the `salesQuickNavDown` action, retrieves the list of sales UUIDs from the store, and navigates to the previous profile based on the current profile's UUID.
   */
  salesNavDown$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesQuickNavActions.salesQuickNavDown),
        tap(({ currentUUID }) => {
          this.store
            .select(selectSalesUUIDs)
            .pipe(take(1))
            .subscribe(uuids => {
              const index = uuids.findIndex(uuid => uuid === currentUUID);
              if (index - 1 >= 0) {
                void this.router.navigate(
                  ['/sales/profile/' + uuids[index - 1]],
                  {
                    queryParams: { fromNextProfile: true },
                  }
                );
              }
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesQuickNavEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {Store<SalesQuickNavState>} store — Injectable store for accessing the sales quick navigation state.
   * @param {Router} router — Injectable Router for navigating between routes.
   */
  constructor(
    private actions$: Actions,
    private store: Store<SalesQuickNavState>,
    private router: Router
  ) {}
}
