import { createReducer, on } from '@ngrx/store';
import * as HomeProfileRemindersActions from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.actions';
import { initialHomeProfileRemindersState } from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.state';

export const homeProfileRemindersReducer = createReducer(
  initialHomeProfileRemindersState,

  on(HomeProfileRemindersActions.loadHomeProfileReminders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    HomeProfileRemindersActions.loadHomeProfileRemindersSuccess,
    (state, { profileReminders, filters }) => ({
      ...state,
      ...profileReminders,
      currentDate: filters ? (filters.date ? filters.date : '') : '',
      loading: false,
    })
  ),
  on(
    HomeProfileRemindersActions.loadHomeProfileRemindersFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  on(HomeProfileRemindersActions.checkProfileReminder, state => ({
    ...state,
    loading: true,
  })),
  on(
    HomeProfileRemindersActions.checkProfileReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      data: state.data.map(reminder =>
        reminder.uuid === uuid
          ? { ...reminder, doneAt: new Date().toISOString() }
          : reminder
      ),
      loading: false,
    })
  ),
  on(
    HomeProfileRemindersActions.checkProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  on(HomeProfileRemindersActions.uncheckProfileReminder, state => ({
    ...state,
    loading: true,
  })),
  on(
    HomeProfileRemindersActions.uncheckProfileReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      data: state.data.map(reminder =>
        reminder.uuid === uuid ? { ...reminder, doneAt: null } : reminder
      ),
      loading: false,
    })
  ),
  on(
    HomeProfileRemindersActions.uncheckProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  on(
    HomeProfileRemindersActions.homeProfileRemindersUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
