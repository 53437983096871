/**
 * Contains the release note bug fixes as an object.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteBugFix = [
  'No more random logouts! We’ve squashed that bug, so you can browse in peace (finally!).',
  'You can now swiftly jump between sales profiles using the arrow keys—no more lost clicks!',
  'Navigate between project-associated sales profiles with ease—because we know you’ve got better things to do than click endlessly.',
];

/**
 * Contains the release note upgrades as an object.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteUpgrade = [
  'Autocompletes in the candidate search are now smarter. (Yes, we made the search engine a little less clueless!)',
  'The candidate documents UI got a makeover—less clutter, more clarity!',
];

/**
 * Contains the release note features as an object.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteFeature = [
  'The first steps of our shiny new landing page are live.',
  'You can now link sales profiles directly to a project from the search page.',
  'All buttons now come with loading indicators. You’ll know when things are happening (or when the server is just taking its sweet time).',
  'Candidate profiles now show birthday dates.',
  'You can now edit notes directly on candidate and sales profiles.',
  'The candidate search filters now include a salary range slider.',
];
