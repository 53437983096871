import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * `fadeBg` is an Angular animation trigger that applies a background color transition when toggling between "on" and "off" states.
 * It smoothly animates the width and background color of the element over 300ms with an `ease-in-out` timing function.
 *
 * @constant {AnimationTriggerMetadata}
 *
 * @property {state} on - Represents the "on" state of the element, where custom styles can be applied if needed.
 *
 * @property {state} off - Represents the "off" state of the element, where custom styles can be applied if needed.
 *
 * @property {transition} 'on <=> off' - Defines a bidirectional transition between the "on" and "off" states.
 * During the transition, the element's width and background color animate over `300ms` with `ease-in-out`.
 */
export const fadeBg: AnimationTriggerMetadata = trigger('fadeBg', [
  state('void', style({})),
  state('on', style({})),
  state('off', style({})),
  transition('on => off', [
    style({
      opacity: 0,
      backgroundColor: 'var(--color-light-100)',
    }),
    animate(
      '300ms ease-in-out',
      style({
        opacity: 1,
        backgroundColor: 'var(--color-dark-100)',
      })
    ),
  ]),
  transition('off => on', [
    style({
      opacity: 0,
      backgroundColor: 'var(--color-dark-100)',
    }),
    animate(
      '300ms ease-in-out',
      style({
        opacity: 1,
        backgroundColor: 'var(--color-light-100)',
      })
    ),
  ]),
]);
