import { createReducer, on } from '@ngrx/store';
import * as DataActions from '~/app/shared/states/data/data.actions';
import { initialDataState } from '~/app/shared/states/data/data.state';

export const dataReducer = createReducer(
  initialDataState,

  on(DataActions.loadCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadCandidatesSuccess, (state, { candidates }) => ({
    ...state,
    candidates,
    loading: false,
  })),
  on(DataActions.loadCandidatesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCandidateStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    DataActions.loadCandidateStatusesSuccess,
    (state, { candidateStatuses }) => ({
      ...state,
      candidateStatuses,
      loading: false,
    })
  ),
  on(DataActions.loadCandidateStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCandidateTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadCandidateTagsSuccess, (state, { candidateTags }) => ({
    ...state,
    candidateTags,
    loading: false,
  })),
  on(DataActions.loadCandidateTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadSalesStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadSalesStatusesSuccess, (state, { salesStatuses }) => ({
    ...state,
    salesStatuses,
    loading: false,
  })),
  on(DataActions.loadSalesStatusesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadNoteCategories, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadNoteCategoriesSuccess, (state, { noteCategories }) => ({
    ...state,
    noteCategories,
    loading: false,
  })),
  on(DataActions.loadNoteCategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCategoriesWithSectors, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    DataActions.loadCategoriesWithSectorsSuccess,
    (state, { categoriesWithSectors }) => ({
      ...state,
      categoriesWithSectors,
      loading: false,
    })
  ),
  on(DataActions.loadCategoriesWithSectorsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadOffices, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadOfficesSuccess, (state, { offices }) => ({
    ...state,
    offices,
    loading: false,
  })),
  on(DataActions.loadOfficesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadIndustries, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadIndustriesSuccess, (state, { industries }) => ({
    ...state,
    industries,
    loading: false,
  })),
  on(DataActions.loadIndustriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCompanies, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies,
    loading: false,
  })),
  on(DataActions.loadCompaniesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCompanyNameFromSubdomain, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(
    DataActions.loadCompanyNameFromSubdomainSuccess,
    (state, { companyName }) => ({
      ...state,
      currentCompanyName: companyName,
      loading: false,
    })
  ),

  on(DataActions.loadCompanyNameFromSubdomainFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadCompanyReferences, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    DataActions.loadCompanyReferencesSuccess,
    (state, { companyReferences }) => ({
      ...state,
      companyReferences,
      loading: false,
    })
  ),
  on(DataActions.loadCompanyReferencesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadTeams, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadTeamsSuccess, (state, { teams }) => ({
    ...state,
    teams,
    loading: false,
  })),
  on(DataActions.loadTeamsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadUsers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    loading: false,
  })),
  on(DataActions.loadUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.loadTimelines, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DataActions.loadTimelinesSuccess, (state, { timelines }) => ({
    ...state,
    timelines,
    loading: false,
  })),
  on(DataActions.loadTimelinesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DataActions.dataActionsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
