import { SalesNote } from '~/app/shared/interfaces/sales/profile/sales-note.interface';

/**
 * Represents the state of the sales notes module.
 *
 * @interface SalesNotesState
 */
export interface SalesNotesState {
  /**
   * The list of sales note records.
   *
   * @type {SalesNote[]}
   */
  salesNotes: SalesNote[];

  /**
   * Indicates whether the sales notes are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales notes within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesNotesState} initialSalesNotesState
 * @description Provides a baseline definition for the sales notes context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesNotesState: SalesNotesState = {
  salesNotes: [],
  loading: false,
  error: null,
};
