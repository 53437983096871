<div
  class="flex flex-column justify-content-center surface-section px-4 pt-4 pb-8 md:px-6 lg:px-8 h-screen relative z-1 bg-cover">
  <div class="surface-0 p-4 shadow-2 border-round">
    <div i18n class="mt-3 mb-5 font-bold text-6xl text-900 text-center">
      Registration Complete 🎉
    </div>

    <p i18n class="text-700 text-3xl mt-0 mb-6 text-center">
      Thank you for registering. Please check your email to activate your
      account.
    </p>

    <div class="text-center">
      <ice-button
        class="mr-2"
        routerLink="/"
        [text]="true"
        label="Go Home"
        i18n-label
        icon="pi pi-home"
        i18n-icon></ice-button>
      <ice-button
        routerLink="/login"
        label="Log In"
        i18n-label
        icon="pi pi-sign-in"
        i18n-icon></ice-button>
    </div>
  </div>
</div>
