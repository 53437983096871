import { Injectable, Type, ViewContainerRef } from '@angular/core';

/**
 * Service to manage the sidebar's state, content, and visibility within the application.
 * It provides methods to dynamically load components into the sidebar, manage titles,
 * and toggle visibility.
 *
 * @Injectable indicates that this service can be injected into other components and services.
 */
@Injectable({
  providedIn: 'root',
})
export class SidenavFormService {
  /**
   * Reference to the ViewContainer where sidebar content will be attached dynamically.
   * @private
   */
  private viewContainerRef: ViewContainerRef | null = null;

  /**
   * Current visibility state of the sidebar.
   * @private
   */
  private visibility = false;

  /**
   * Title of the sidebar.
   * @private
   */
  private sidebarTitle = '';

  /**
   * Subtitle of the sidebar.
   * @private
   */
  private sidebarSubtitle = '';

  /**
   * Returns the current visibility state of the sidebar.
   * @returns {boolean} True if the sidebar is visible, otherwise false.
   */
  get sidebarVisible(): boolean {
    return this.visibility;
  }

  /**
   * Returns the current title of the sidebar.
   * @returns {string} The title of the sidebar.
   */
  get title(): string {
    return this.sidebarTitle;
  }

  /**
   * Returns the current subtitle of the sidebar.
   * @returns {string} The subtitle of the sidebar.
   */
  get subtitle(): string {
    return this.sidebarSubtitle;
  }

  /**
   * Sets the ViewContainerRef for sidebar content. This is the anchor point for inserting dynamic components.
   * @param {ViewContainerRef} ref - The ViewContainerRef to attach dynamic components to.
   * @return {void}
   */
  setViewContainerRef(ref: ViewContainerRef): void {
    this.viewContainerRef = ref;
  }

  /**
   * Sets the visibility of the sidebar and clears the title and subtitle if it is being hidden.
   * @param {boolean} visible - Specifies the visibility state of the sidebar.
   * @return {void}
   */
  setSidebarVisibility(visible: boolean): void {
    this.visibility = visible;

    if (!visible) {
      this.sidebarTitle = '';
      this.sidebarSubtitle = '';
    }
  }

  /**
   * Sets the title of the sidebar.
   * @param {string} title - The title to set for the sidebar.
   * @return {void}
   */
  setTitle(title: string): void {
    this.sidebarTitle = title;
  }

  /**
   * Sets the subtitle of the sidebar.
   * @param {string} subtitle - The subtitle to set for the sidebar.
   * @return {void}
   */
  setSubtitle(subtitle: string): void {
    this.sidebarSubtitle = subtitle;
  }

  /**
   * Toggles the visibility of the sidebar.
   * @return {void}
   */
  toggleSidebar(): void {
    this.visibility = !this.visibility;
  }

  /**
   * Closes the sidebar and resets its title and subtitle.
   * @return {void}
   */
  closeSidebar(): void {
    this.visibility = false;
    this.sidebarTitle = '';
    this.sidebarSubtitle = '';
  }

  /**
   * Dynamically loads a component into the sidebar's ViewContainerRef with optional inputs, title, and subtitle.
   * @param {Type<any>} component - The component type to load.
   * @param {Object} [inputs] - Optional inputs to pass to the component.
   * @param {string} [title] - Optional title for the sidebar when the component is loaded.
   * @param {string} [subtitle] - Optional subtitle for the sidebar when the component is loaded.
   * @return {void}
   */
  loadComponent(
    component: Type<any>,
    inputs?: { [key: string]: any },
    title?: string,
    subtitle?: string
  ): void {
    if (title) {
      this.setTitle(title);
    }
    if (subtitle) {
      this.setSubtitle(subtitle);
    }

    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(component);

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          componentRef.instance[key] = inputs[key];
        }
      }
    }
  }
}
