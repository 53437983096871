import { Pipe, PipeTransform } from '@angular/core';

/**
 * Interface defining the structure of route mappings.
 * Each property key is a module name in lowercase and the value is the corresponding route path as a string.
 */
interface RouteMapping {
  [key: string]: string;
}

/**
 * A custom Angular pipe that converts module names to corresponding route paths using a predefined mapping.
 * This pipe is useful for dynamically determining routes based on module names provided, for instance, in navigational components.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'moduleToRoute', enabling its use within Angular templates.
 */
@Pipe({
  name: 'moduleToRoute',
})
export class ModuleToRoutePipe implements PipeTransform {
  /* eslint-disable prettier/prettier */
  /**
   * Private object to hold the route mappings.
   * These mappings are predefined paths associated with specific module names.
   */
  private routes: RouteMapping = {
    jobhub: 'jobhub/offers', // Route for the JobHub module
    crm: 'crm', // Route for the CRM module
    kanban: 'kanban', // Route for the Kanban module
    sales: 'sales', // Route for the Sales module
    stats: 'stats', // Route for the Statistics module
  };
  /* eslint-enable prettier/prettier */

  /**
   * Transforms a module name string into a corresponding route string based on predefined mappings.
   * If the module name does not exist in the mapping, it defaults to '/home'.
   *
   * @param {string} moduleName - The name of the module to transform into a route.
   * @returns {string} - The route path corresponding to the given module name, or '/home' if the module name is not found.
   */
  transform(moduleName: string): string {
    const normalizedModuleName = moduleName.toLowerCase(); // Normalize the module name to ensure case-insensitivity
    return this.routes[normalizedModuleName] || '/home'; // Return the mapped route or default to '/home'
  }
}
