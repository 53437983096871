import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';

/**
 * Represents the state of quick actions.
 *
 * @interface QuickActionState
 */
export interface QuickActionState {
  /**
   * The list of quick action configurations.
   *
   * @type {QuickActionItem[]}
   */
  quickActions: QuickActionItem[];
}

/**
 * The initial state for quick actions.
 *
 * @type {QuickActionState}
 * @const
 */
export const initialQuickActionState: QuickActionState = {
  quickActions: [],
};
