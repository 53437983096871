import { createAction, props } from '@ngrx/store';
import { ProposalHistoryState } from '~/app/states/main/jobhub/states/proposal-history/proposal-history.state';

export const loadProposalHistory = createAction('[History] Load proposals');
export const loadProposalHistorySuccess = createAction(
  '[History] Load proposals Success',
  props<{ proposalHistory: ProposalHistoryState }>()
);
export const loadProposalHistoryFailure = createAction(
  '[History] Load proposals Failure',
  props<{ error: string }>()
);

export const proposalHistoryUnauthorized = createAction(
  '[History] Unauthorized',
  props<{ error: string }>()
);
