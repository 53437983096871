import { createReducer, on } from '@ngrx/store';
import * as OfficesActions from '~/app/states/main/settings/states/offices/offices.actions';
import { initialAllOfficesState } from '~/app/states/main/settings/states/offices/offices.state';

export const officesReducer = createReducer(
  initialAllOfficesState,

  on(OfficesActions.loadOffices, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfficesActions.loadOfficesSuccess, (state, { offices }) => ({
    ...state,
    ...offices,
    loading: false,
  })),
  on(OfficesActions.loadOfficesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfficesActions.createOffice, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfficesActions.createOfficeSuccess, (state, { office }) => ({
    ...state,
    data: [...state.data, office],
    loading: false,
  })),
  on(OfficesActions.createOfficeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfficesActions.updateOffice, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfficesActions.updateOfficeSuccess, (state, { office }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === office.uuid ? office : item)),
    loading: false,
  })),
  on(OfficesActions.updateOfficeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfficesActions.deleteOffice, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfficesActions.deleteOfficeSuccess, (state, { uuid }) => ({
    ...state,
    data: state.data.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(OfficesActions.deleteOfficeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfficesActions.officesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
