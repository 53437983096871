import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { JobMatchingHistory } from '~/app/shared/interfaces/jobhub/job-matching/job-matching-history.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Describes the state of AI client operations within the application, including the current request, matching object,
 * search term, tasks, progress, loading status, and error information.
 *
 * @interface AiClientState
 */
export interface AiClientState {
  /**
   * The current WebSocket request for AI client operations.
   *
   * @type {WsRequest | null}
   */
  request: WsRequest | null;

  /**
   * The matching object containing job matching details.
   *
   * @type {MatchingObject | null}
   */
  matching: MatchingObject | null;

  /**
   * The current search term used for job matching.
   *
   * @type {string | null}
   */
  search: string | null;

  /**
   * The job matching tasks.
   *
   * @type {JobMatchingHistory | null}
   */
  history: JobMatchingHistory | null;

  /**
   * The progress information for the current job matching process.
   *
   * @type {Progress | null}
   */
  progress: Progress | null;

  /**
   * Indicates whether the state is currently loading.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the AI client reducer, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {AiClientState}
 * @description Provides a baseline definition for the AI client context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialClientReducerState: AiClientState = {
  request: null,
  matching: null,
  search: null,
  history: null,
  progress: null,
  loading: false,
  error: null,
};
