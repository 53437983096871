import { Component } from '@angular/core';

/**
 * Component dedicated to displaying examples of typography used throughout the application.
 * It provides a visual reference for text styles including headings, subtitles, body text, captions, and overlines.
 * This component is essential for maintaining consistent typography standards across the application.
 *
 * @Component decorator defines Angular component metadata:
 * @selector 'app-typography' - The CSS selector for the component.
 * @templateUrl './typography.component.html' - The path to the HTML template that demonstrates typography styles.
 * @styleUrls ['./typography.component.scss'] - The path to the styles that apply to the typography elements.
 */
@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss'],
})
export class TypographyComponent {}
