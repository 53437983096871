import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanyActions from '~/app/states/main/client-company/profile/states/client-company/client-company.actions';
import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanyEffects` handles the side effects related to client company profiles in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading, creating, updating, and deleting client company profiles from the server.
 *
 * Effects included in this class:
 * — `loadClientCompanyById$`: Fetches a client company profile by ID from the server.
 * — `updateClientCompany$`: Updates an existing client company on the server.
 * — `updateClientCompanySuccess$`: Handles successful client company updates.
 * — `deleteClientCompany$`: Deletes a client company from the server.
 * — `deleteClientCompanySuccess$`: Handles successful client company deletion.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanyEffects {
  /**
   * Effect to load a client company by ID.
   */
  loadClientCompanyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyActions.loadClientCompanyById),
      checkPermission(ClientCompanyActions.clientCompanyUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<ClientCompany>(
            `${environment.apiUrl}/v1/clientCompanies/${uuid}`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompany =>
              ClientCompanyActions.loadClientCompanyByIdSuccess({
                clientCompany,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyActions.loadClientCompanyByIdFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to update an existing client company.
   */
  updateClientCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyActions.updateClientCompany),
      checkPermission(ClientCompanyActions.clientCompanyUnauthorized),
      mergeMap(({ uuid, clientCompanyData }) =>
        this.http
          .patch<ClientCompany>(
            `${environment.apiUrl}/v1/clientCompanies/${uuid}`,
            clientCompanyData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompany =>
              ClientCompanyActions.updateClientCompanySuccess({ clientCompany })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyActions.updateClientCompanyFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful client company updates.
   */
  updateClientCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyActions.updateClientCompanySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company updated successfully'
          );
          this.sidenavFormService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a client company.
   */
  deleteClientCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyActions.deleteClientCompany),
      checkPermission(ClientCompanyActions.clientCompanyUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/clientCompanies/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => ClientCompanyActions.deleteClientCompanySuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyActions.deleteClientCompanyFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful client company deletion.
   */
  deleteClientCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyActions.deleteClientCompanySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company deleted successfully'
          );
          this.sidenavFormService.closeSidebar();

          void this.router.navigate(['client-company/search-client-company']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ClientCompanyEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {Router} router - Injects the Router service.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {CustomToastService} toast - Injects the CustomToastService.
   * @param {SidenavFormService} sidenavFormService - Injects the SidenavFormService.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidenavFormService: SidenavFormService
  ) {}
}
