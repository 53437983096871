import { createAction, props } from '@ngrx/store';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';

export const loadHomeTeamsAction = createAction('[Home Teams] Load Home Teams');
export const loadHomeTeamsActionSuccess = createAction(
  '[Home Teams] Load Home Teams Success',
  props<{ teams: Team[] }>()
);
export const loadHomeTeamsActionFailure = createAction(
  '[Home Teams] Load Home Teams Failure',
  props<{ error: string }>()
);

export const homeTeamUnauthorized = createAction(
  '[Home Teams] Unauthorized',
  props<{ error: string }>()
);
