import { createAction, props } from '@ngrx/store';
import { ClientCompanyNote } from '~/app/shared/interfaces/client-company/profile/client-company-note.interface';

export const loadClientCompanyNotes = createAction(
  '[Client Company Notes] Load Client Company Notes',
  props<{ clientCompanyId: string }>()
);
export const loadClientCompanyNotesSuccess = createAction(
  '[Client Company Notes] Load Client Company Notes Success',
  props<{ clientCompanyNotes: ClientCompanyNote[] }>()
);
export const loadClientCompanyNotesFailure = createAction(
  '[Client Company Notes] Load Client Company Notes Failure',
  props<{ error: string }>()
);

export const createClientCompanyNotes = createAction(
  '[Client Company Notes] Create Client Company Notes',
  props<{ clientCompanyNoteData: FormData }>()
);
export const createClientCompanyNotesSuccess = createAction(
  '[Client Company Notes] Create Client Company Notes Success',
  props<{ clientCompanyNote: ClientCompanyNote }>()
);
export const createClientCompanyNotesFailure = createAction(
  '[Client Company Notes] Create Client Company Notes Failure',
  props<{ error: string }>()
);

export const updateClientCompanyNotes = createAction(
  '[Client Company Notes] Update Client Company Notes',
  props<{ uuid: string; clientCompanyNoteData: FormData }>()
);
export const updateClientCompanyNotesSuccess = createAction(
  '[Client Company Notes] Update Client Company Notes Success',
  props<{ clientCompanyNote: ClientCompanyNote }>()
);
export const updateClientCompanyNotesFailure = createAction(
  '[Client Company Notes] Update Client Company Notes Failure',
  props<{ error: string }>()
);

export const deleteClientCompanyNotes = createAction(
  '[Client Company Notes] Delete Client Company Notes',
  props<{ uuid: string; clientCompanyId: string }>()
);
export const deleteClientCompanyNotesSuccess = createAction(
  '[Client Company Notes] Delete Client Company Notes Success',
  props<{ uuid: string }>()
);
export const deleteClientCompanyNotesFailure = createAction(
  '[Client Company Notes] Delete Client Company Notes Failure',
  props<{ error: string }>()
);

export const clientCompanyNotesUnauthorized = createAction(
  '[Client Company Notes] Unauthorized',
  props<{ error: string }>()
);
