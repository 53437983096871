import { SidenavItems } from '~/app/shared/interfaces/navigation/sidenav/sidenav-items.interface';

/**
 * Represents the navigation items for the sidebar of the application, organized by top-level keys corresponding to different
 * application modules like CRM, JobHub, and Kanban. Each key maps to an array of `SidenavItem` objects that define
 * the navigation structure for that module, including paths, labels, icons, and nested items for sub-navigation.
 *
 * @type {SidenavItems}
 */
export const sidenavItems: SidenavItems = {
  crm: [
    {
      path: 'crm',
      icon: 'home',
      label: 'Dashboard',
    },
    {
      path: 'crm/search-candidates',
      icon: 'search-people',
      label: 'Search',
    },
    {
      path: 'crm/ai-search',
      icon: 'ai',
      label: 'AI Search',
      items: [
        {
          path: 'crm/ai-search/consulting',
          icon: 'ai-client',
          label: 'Consulting',
        },
        {
          path: 'crm/ai-search/client',
          icon: 'ai-client',
          label: 'Client',
        },
        {
          path: 'crm/ai-search/client/history',
          icon: 'history',
          label: 'History',
        },
      ],
    },
  ],

  sales: [
    {
      path: 'sales',
      icon: 'home',
      label: 'Dashboard',
    },
    {
      path: 'sales/search-sales',
      icon: 'search-people',
      label: 'Search',
    },
    {
      path: 'sales/projects',
      icon: 'project',
      label: 'Projects',
    },
    {
      path: 'sales/timelines',
      icon: 'timeline',
      label: 'Timelines',
    },
  ],

  jobhub: [
    {
      path: 'jobhub/my-offers',
      icon: 'my-offers', // Icon representing user's offers
      label: 'My offers',
    },
    {
      path: 'jobhub/offers',
      icon: 'jobs', // Icon representing list of offers
      label: 'Offers',
    },
    {
      path: 'jobhub/my-history',
      icon: 'history', // Icon representing past proposals
      label: 'My Proposal History',
    },
  ],

  kanban: [
    {
      path: 'kanban',
      icon: 'board',
      label: 'Board',
    },
  ],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'client-company': [
    {
      path: 'client-company',
      icon: 'home',
      label: 'Dashboard',
    },
    {
      path: 'client-company/search-client-company',
      icon: 'search',
      label: 'Search',
    },
  ],

  // eslint-disable-next-line @typescript-eslint/naming-convention
  'shuffle-list': [
    {
      path: 'shuffle-list',
      icon: 'rand',
      label: 'Shuffle List',
    },
  ],

  stats: [
    {
      path: 'stats/goals',
      icon: 'goals', // Icon representing goals
      label: 'Goals',
    },
  ],
};
