import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { selectAuthLoading } from '~/app/auth/auth.selectors';
import { LoginService } from '~/app/auth/services/login.service';
import { selectSecureLoginToken } from '~/app/auth/auth.selectors';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';

/**
 * Component responsible for handling secure login process, particularly using TOTP (Time-based One-Time Password).
 * It includes form handling for entering the TOTP and submitting it for verification.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-secure-login' - The CSS selector that identifies this component in a template.
 * @templateUrl './secure-login.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./secure-login.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-secure-login',
  templateUrl: './secure-login.component.html',
  styleUrls: ['./secure-login.component.scss'],
})
export class SecureLoginComponent implements OnInit {
  /**
   * FormGroup to manage the secure login form
   * @type {FormGroup}
   */
  secureLoginForm!: FormGroup;

  /**
   * Observable to track authentication loading state
   * @type {Observable<boolean>}
   */
  isLoading$!: Observable<boolean>;

  /**
   * Stores the secure login token required for submitting with TOTP
   * @type {string}
   */
  secureLoginToken = '';

  /**
   * Constructs the SecureLoginComponent with the necessary dependencies.
   *
   * @param {Store<AppState>} store - NgRx store for managing state, used to select the secure login token and auth loading state.
   * @param {LoginService} loginService - Service to handle the logic for secure login.
   * @param {FormBuilder} formBuilder - Service to create FormGroup based on provided configuration.
   * @param {ThemeService} theme - Service to manage theming across the application.
   */
  constructor(
    private store: Store<AppState>,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    public theme: ThemeService
  ) {
    this.store
      .select(selectSecureLoginToken)
      .pipe(take(1))
      .subscribe(token => {
        this.secureLoginToken = token ?? '';
      });
  }

  /**
   * Accessor for the 'totp' form control
   * @type {FormControl}
   */
  get totp(): FormControl {
    return this.secureLoginForm.get('totp') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the form and sets up the isLoading$ observable.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.isLoading$ = this.store.select(selectAuthLoading);
  }

  /**
   * Handles form submission, collects the form data, and submits it for secure login.
   * @return {void}
   */
  onSubmit(): void {
    const secureLoginData = {
      token: this.secureLoginToken,
      totp: this.secureLoginForm.value.totp,
    };
    this.loginService.secureLogin(secureLoginData);
  }

  /**
   * Initializes the secure login form with necessary form controls and validators, particularly for TOTP.
   * @return {void}
   */
  private initForm(): void {
    this.secureLoginForm = this.formBuilder.group({
      totp: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.minLength(6),
          Validators.maxLength(6),
        ],
      ],
    });
  }
}
