import { createReducer, on } from '@ngrx/store';
import * as AllSkillsActions from '~/app/shared/states/skills/skills.actions';
import { initialAllSkillsState } from '~/app/shared/states/skills/skills.state';

export const allSkillsReducer = createReducer(
  initialAllSkillsState,

  on(AllSkillsActions.loadAllSkills, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AllSkillsActions.loadAllSkillsSuccess, (state, { skills }) => {
    return {
      ...state,
      ...skills,
      loading: false,
    };
  }),
  on(AllSkillsActions.loadAllSkillsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AllSkillsActions.loadAllSkillsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
