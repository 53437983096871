import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Alert } from '~/app/shared/interfaces/alert.interface';
import { AlertEnum } from '~/app/shared/enums/alert.enum';

/**
 * Represents a component for displaying alert messages in a modal dialog. This component uses PrimeNG's DynamicDialog
 * to render an alert message dynamically.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-alert' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './alert.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./alert.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  /**
   * The alert object containing the details to be displayed by the component. This is typically passed in
   * through the data property of the DynamicDialog configuration.
   */
  alert: Alert;

  /**
   * Constructs an instance of the AlertComponent with dependencies injected via the constructor.
   *
   * @param {DynamicDialogRef} ref A reference to the DynamicDialog instance that created this component. This reference
   * is used to manipulate the dialog, specifically to close it when the operation completes.
   * @param {DynamicDialogConfig}config Provides configuration for the DynamicDialog, including any data passed to this component.
   */
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.alert = this.config.data;
  }

  /**
   * Returns the appropriate icon class based on the type of alert.
   * @returns {string} The icon class.
   */
  public get icon(): string {
    switch (this.alert.type) {
      case AlertEnum.INFO:
        return 'text-blue-600 pi pi-directions';
      case AlertEnum.WARNING:
        return 'text-orange-500 pi pi-exclamation-triangle';
      default:
        return 'text-red-600 pi pi-exclamation-triangle';
    }
  }

  /**
   * Returns the background color class based on the type of alert.
   * @returns {string} The background color class.
   */
  public get backgroundColor(): string {
    switch (this.alert.type) {
      case AlertEnum.INFO:
        return 'bg-blue-100';
      case AlertEnum.WARNING:
        return 'bg-orange-100';
      default:
        return 'bg-red-100';
    }
  }

  /**
   * Closes the current dialog window. This method is typically bound to a button click in the dialog
   * to allow the user to dismiss the alert.
   * @return {void}
   */
  close(): void {
    this.ref.close();
  }
}
