import { ProfileNote } from '~/app/shared/interfaces/crm/profile/profile-note.interface';

/**
 * Represents the state of profile notes.
 *
 * @interface ProfileNotesState
 */
export interface ProfileNotesState {
  /**
   * An array of profile notes.
   *
   * @type {ProfileNote[]}
   */
  profileNotes: ProfileNote[];

  /**
   * Indicates if the profile notes are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile notes.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile notes within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileNotesState} initialProfileNotesState
 * @description Provides a baseline definition for the profile notes context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileNotesState: ProfileNotesState = {
  profileNotes: [],
  loading: false,
  error: null,
};
