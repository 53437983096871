import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import * as TeamActions from '~/app/states/main/settings/states/teams/teams.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';
import { AllTeamsState } from '~/app/states/main/settings/states/teams/teams.state';

/**
 * `TeamEffects` orchestrates the handling of asynchronous operations related to team management in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to teams, such as fetching, creating,
 * updating, and deleting teams, as well as assigning and unassigning members to teams.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadTeams$`: Fetches teams from the server.
 * — `loadTeamsByFilters$`: Fetches teams based on specified filters.
 * — `createTeam$`: Creates a new team on the server.
 * — `updateTeam$`: Updates an existing team on the server.
 * — `deleteTeam$`: Deletes a team from the server.
 * — `assignMember$`: Assigns a member to a team.
 * — `unassignMember$`: Unassigns a member from a team.
 * — `changeTeamMemberStatus$`: Changes the status of a team member.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AllTeamsEffects {
  /**
   * Effect to load teams. It listens for the `loadTeams` action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.loadAllTeams),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<AllTeamsState>(`${environment.apiUrl}/v1/teams`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(teams => TeamActions.loadAllTeamsSuccess({ teams })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.loadAllTeamsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new team. It listens for the `createTeam` action, checks permissions,
   * and sends a request to create a new team on the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  createTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.createTeam),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ teamData }) =>
        this.http
          .post<Team>(`${environment.apiUrl}/v1/teams`, teamData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(team => TeamActions.createTeamSuccess({ team })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.createTeamFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of creating a new team. It listens for the `createTeamSuccess` action,
   * shows a success toast notification, closes the sidebar, and reloads the list of teams.
   */
  createTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.createTeamSuccess),
      map(() => {
        this.toast.successToast('Success', 'Team created successfully');
        this.sidebarService.closeSidebar();
        return TeamActions.loadAllTeams({ filters: { limit: 10, page: 0 } });
      })
    )
  );

  /**
   * Effect to update an existing team. It listens for the `updateTeam` action, checks permissions,
   * and sends a request to update the team on the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.updateTeam),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ teamUuid, teamData }) =>
        this.http
          .patch<Team>(`${environment.apiUrl}/v1/teams/${teamUuid}`, teamData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(team => TeamActions.updateTeamSuccess({ team })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.updateTeamFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of updating a team. It listens for the `updateTeamSuccess` action,
   * shows a success toast notification, and closes the sidebar.
   */
  updateTeamSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamActions.updateTeamSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Team updated successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a team. It listens for the `deleteTeam` action, checks permissions,
   * and sends a request to delete the team from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  deleteTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.deleteTeam),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/teams/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => TeamActions.deleteTeamSuccess({ teamId: uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.deleteTeamFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of deleting a team. It listens for the `deleteTeamSuccess` action,
   * shows a success toast notification, closes the sidebar, and reloads the list of teams.
   */
  deleteTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.deleteTeamSuccess),
      map(() => {
        this.toast.successToast('Success', 'Team deleted successfully');
        this.sidebarService.closeSidebar();
        return TeamActions.loadAllTeams({ filters: { limit: 10, page: 0 } });
      })
    )
  );

  /**
   * Effect to assign a member to a team. It listens for the `assignMember` action, checks permissions,
   * and sends a request to assign the member to the team on the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  assignMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.assignMember),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ teamUuid, userUuid }) =>
        this.http
          .patch<Team>(
            `${environment.apiUrl}/v1/teams/assign/${teamUuid}/user/${userUuid}`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(team => TeamActions.assignMemberSuccess({ team })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.assignMemberFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of assigning a member to a team. It listens for the `assignMemberSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  assignMemberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamActions.assignMemberSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Member assigned to team successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a member from a team. It listens for the `unassignMember` action, checks permissions,
   * and sends a request to unassign the member from the team on the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  unassignMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.unassignMember),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ teamUuid, userUuid }) =>
        this.http
          .patch<Team>(
            `${environment.apiUrl}/v1/teams/unassigned/${teamUuid}/user/${userUuid}`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(team => TeamActions.unassignMemberSuccess({ team })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.unassignMemberFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of unassigning a member from a team. It listens for the `unassignMemberSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  unassignMemberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamActions.unassignMemberSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Member unassigned from team successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to change the status of a team member. It listens for the `changeMemberStatus` action, checks permissions,
   * and sends a request to change the status of the team member on the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  changeTeamMemberStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.changeMemberStatus),
      checkPermission(TeamActions.teamsUnauthorized),
      mergeMap(({ teamUuid, userUuid, memberStatusData }) =>
        this.http
          .patch<Team>(
            `${environment.apiUrl}/v1/teams/${teamUuid}/changeStatus/${userUuid}`,
            memberStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(team => TeamActions.changeMemberStatusSuccess({ team })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TeamActions.changeMemberStatusFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle success of changing the status of a team member. It listens for the `changeMemberStatusSuccess` action
   * and shows a success toast notification. This effect does not dispatch any actions.
   */
  changeTeamMemberStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamActions.changeMemberStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Member status changed successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for TeamEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {CustomToastService} toast - The service to handle toast notifications.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
