import { createAction, props } from '@ngrx/store';
import {
  AssignClientCompanySectorJobs,
  UnassignedClientCompanySectorJobs,
} from '~/app/states/main/client-company/profile/types/client-company-sector-jobs.types';
import { ClientCompanySectorJobs } from '~/app/shared/interfaces/client-company/profile/client-company-sector-jobs.interface';

export const loadClientCompanySectorJobs = createAction(
  '[Client Company Sector Jobs] Load Client Company Sector Jobs',
  props<{ uuid: string }>()
);
export const loadClientCompanySectorJobsSuccess = createAction(
  '[Client Company Sector Jobs] Load Client Company Sector Jobs Success',
  props<{ clientCompanySectorJobs: ClientCompanySectorJobs[] }>()
);
export const loadClientCompanySectorJobsFailure = createAction(
  '[Client Company Sector Jobs] Load Client Company Sector Jobs Failure',
  props<{ error: string }>()
);

export const assignClientCompanySectorJob = createAction(
  '[Client Company Sector Jobs] Assign Client Company Sector Job',
  props<{
    uuid: string;
    clientCompanySectorJob: AssignClientCompanySectorJobs;
  }>()
);
export const assignClientCompanySectorJobSuccess = createAction(
  '[Client Company Sector Jobs] Assign Client Company Sector Job Success',
  props<{ clientCompanySectorJobs: ClientCompanySectorJobs[] }>()
);
export const assignClientCompanySectorJobFailure = createAction(
  '[Client Company Sector Jobs] Assign Client Company Sector Job Failure',
  props<{ error: string }>()
);

export const unassignClientCompanySectorJob = createAction(
  '[Client Company Sector Jobs] Unassign Client Company Sector Job',
  props<{
    uuid: string;
    clientCompanySectorJob: UnassignedClientCompanySectorJobs;
  }>()
);
export const unassignClientCompanySectorJobSuccess = createAction(
  '[Client Company Sector Jobs] Unassign Client Company Sector Job Success',
  props<{ clientCompanySectorJobs: ClientCompanySectorJobs[] }>()
);
export const unassignClientCompanySectorJobFailure = createAction(
  '[Client Company Sector Jobs] Unassign Client Company Sector Job Failure',
  props<{ error: string }>()
);

export const clientCompanySectorJobsUnauthorized = createAction(
  '[Client Company Sector Jobs] Unauthorized',
  props<{ error: string }>()
);
