import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProfileQuickNavService } from '~/app/states/main/crm/profile/services/profile-quick-nav.service';
import * as SearchCandidateActions from '~/app/states/main/crm/search-candidate/state/search-candidate.actions';
import { AllCandidatesState } from '~/app/states/main/crm/search-candidate/state/search-candidate.state';
import { FreelanceSalaryMax } from '~/app/shared/interfaces/crm/search-candidate/freelance-salary-max.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `SearchCandidateEffects` handles the side effects for searching candidates in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading all candidates and filtering candidates based on search criteria.
 *
 * The effects in this class include:
 * - `loadAllCandidates$`: Fetches all candidates from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SearchCandidateEffects {
  /**
   * Effect to load candidates by filter criteria. It listens for the `loadCandidatesByFilter` action, checks permissions,
   * and fetches data from the server based on provided filter criteria. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadAllCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchCandidateActions.loadAllCandidates),
      checkPermission(SearchCandidateActions.searchCandidateUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .post<AllCandidatesState>(
            `${environment.apiUrl}/v1/profiles/search`,
            filters,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(candidates => {
              this.profileQuickNavService.loadProfilesUUIDs(
                candidates.data.map(({ uuid }) => uuid)
              );
              return SearchCandidateActions.loadAllCandidatesSuccess({
                candidates,
              });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SearchCandidateActions.loadAllCandidatesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to get the salary max. It listens for the `getSalaryMax` action, checks permissions,
   * and fetches data from the server based on provided filter criteria. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  getSalaryMax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchCandidateActions.getSalaryMax),
      checkPermission(SearchCandidateActions.searchCandidateUnauthorized),
      mergeMap(() =>
        this.http
          .get<FreelanceSalaryMax>(
            `${environment.apiUrl}/v1/profiles/salaryMax`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(salaryMax =>
              SearchCandidateActions.getSalaryMaxSuccess({ salaryMax })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SearchCandidateActions.getSalaryMaxFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to export all candidates by filter criteria. It listens for the `exportAllCandidates` action, checks permissions,
   * and fetches data from the server based on provided filter criteria. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  exportAllCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchCandidateActions.exportAllCandidates),
      mergeMap(({ filters }) => {
        const options = {
          headers: {
            accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob' as 'json', // 'json' here is a trick to avoid TypeScript error
          withCredentials: true,
        };

        return this.http
          .post<Blob>(
            `${environment.apiUrl}/v1/profiles/export`,
            filters,
            options
          )
          .pipe(
            take(1),
            map((exportedData: Blob) => {
              // Create a download link and trigger the download
              const downloadLink = document.createElement('a');
              const url = window.URL.createObjectURL(exportedData);
              downloadLink.href = url;
              downloadLink.download = 'export-candidates.xlsx';
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(url);
              return SearchCandidateActions.exportAllCandidatesSuccess();
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SearchCandidateActions.exportAllCandidatesFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Creates an instance of SearchCandidateEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {ProfileQuickNavService} profileQuickNavService - The service to handle quick navigation between profiles.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private profileQuickNavService: ProfileQuickNavService
  ) {}
}
