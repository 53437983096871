import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';

/**
 * Describes the state of navigation within the application, including the current module, available modules,
 * navigation items, and status indicators for loading and errors.
 *
 * @interface NavigationState
 */
export interface NavigationState {
  /**
   * Currently active module in the navigation, or null if no module is active.
   *
   * @type {ModuleItem | null}
   */
  currentModule: ModuleItem | null;

  /**
   * Array of available modules that the user can navigate to.
   *
   * @type {ModuleItem[]}
   */
  availableModules: ModuleItem[];

  /**
   * Array of activated modules that the user can navigate to.
   *
   * @type {ModuleItem[]}
   */
  activatedModules: ModuleItem[];

  /**
   * Indicates whether the navigation state is in the process of loading (e.g., fetching data).
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling navigation actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the navigation within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {NavigationState} initialNavigationState
 * @description Provides a baseline definition for the navigation context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialNavigationState: NavigationState = {
  currentModule: null,
  availableModules: [],
  activatedModules: [],
  loading: false,
  error: null,
};
