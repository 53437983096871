import { Component } from '@angular/core';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * A component designed to display a forbidden access message.
 * This component can be used in areas of the application where access control is enforced,
 * and users attempt to reach resources they do not have permissions to access.
 *
 * @Component decorator defines the Angular metadata for the component.
 * @selector 'app-forbidden' - The selector used to insert this component into templates.
 * @templateUrl './forbidden.component.html' - Points to the HTML template that describes the part of the view for this component.
 * @styleUrls ['./forbidden.component.scss'] - Contains specific styles for this component.
 *
 * @example
 * Example of usage:
 * Typically, this component is used in route handling to show a 403 page when a user navigates to a forbidden route:
 * { path: 'forbidden', component: ForbiddenComponent }
 */
@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  /**
   * Constructor that integrates ThemeService, which might be used to adjust the theme
   * dynamically based on the application's requirements or user preferences.
   *
   * @param {ThemeService} theme - Service responsible for handling theme changes within the app.
   * This service is made public to access its properties and methods from the component's template,
   * allowing for dynamic styling based on the current theme.
   */
  constructor(public theme: ThemeService) {}
}
