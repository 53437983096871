import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileSkillActions from '~/app/states/main/crm/profile/states/profile-skills/profile-skills.actions';

import { Skill } from '~/app/shared/interfaces/shared/skill.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileSkillsEffects` manages the side effects for profile skill-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile skills, such as loading, creating, assigning, and unassigning skills.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileSkills$`: Loads the skills assigned to a profile based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched skills or a failure action if an error occurs.
 * — `assignProfileSkill$`: Assigns a skill to a profile. It performs an HTTP PATCH request and dispatches a success action with the updated skills or a failure action if an error occurs.
 * — `assignProfileSkillSuccess$`: Handles the success of assigning a profile skill by displaying a success toast message.
 * — `unassignProfileSkill$`: Unassigns a skill from a profile. It performs an HTTP PATCH request and dispatches a success action with the updated skills or a failure action if an error occurs.
 * — `unassignProfileSkillSuccess$`: Handles the success of unassigning a profile skill by displaying a success toast message.
 * — `createSkill$`: Creates a new skill and assigns it to a profile. It performs an HTTP POST request to create the skill, followed by an HTTP PATCH request to assign the skill to the profile. It dispatches a success action if both operations succeed, or a failure action if either operation fails.
 * — `createSkillSuccess$`: Handles the success of creating a new skill by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileSkillsEffects {
  /**
   * Effect to load the skills assigned to a profile based on a given profile ID.
   * Listens for the `loadProfileSkills` action, checks permissions, and performs an HTTP GET request to fetch the skills.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSkillActions.loadProfileSkills),
      checkPermission(ProfileSkillActions.profileSkillsUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<
            Skill[]
          >(`${environment.apiUrl}/v1/profiles/skills/${profileId}`, { withCredentials: true })
          .pipe(
            take(1),
            map(skills =>
              ProfileSkillActions.loadProfileSkillsSuccess({ skills })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSkillActions.loadProfileSkillsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to assign a skill to a profile.
   * Listens for the `assignProfileSkill` action, checks permissions, and performs an HTTP PATCH request to assign the skill.
   * Dispatches a success or failure action based on the result.
   */
  assignProfileSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSkillActions.assignProfileSkill),
      checkPermission(ProfileSkillActions.profileSkillsUnauthorized),
      mergeMap(({ uuid, skillData }) =>
        this.http
          .patch<
            Skill[]
          >(`${environment.apiUrl}/v1/profiles/skills/${uuid}/assignSkill`, skillData, { withCredentials: true })
          .pipe(
            take(1),
            map(skills =>
              ProfileSkillActions.assignProfileSkillSuccess({ skills })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSkillActions.assignProfileSkillFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a profile skill.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  assignProfileSkillSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSkillActions.assignProfileSkillSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile skill assigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a skill from a profile.
   * Listens for the `unassignProfileSkill` action, checks permissions, and performs an HTTP PATCH request to unassign the skill.
   * Dispatches a success or failure action based on the result.
   */
  unassignProfileSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSkillActions.unassignProfileSkill),
      checkPermission(ProfileSkillActions.profileSkillsUnauthorized),
      mergeMap(({ profileId, skillId }) =>
        this.http
          .patch<
            Skill[]
          >(`${environment.apiUrl}/v1/profiles/skills/${profileId}/unassignedSkill`, { skillId }, { withCredentials: true })
          .pipe(
            take(1),
            map(skills =>
              ProfileSkillActions.unassignProfileSkillSuccess({ skills })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSkillActions.unassignProfileSkillFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a profile skill.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  unassignProfileSkillSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSkillActions.unassignProfileSkillSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile skill unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileSkillsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService
  ) {}
}
