import { createReducer, on } from '@ngrx/store';
import { initialBreakpointState } from '~/app/core/state/breakpoint/breakpoint.state';
import * as BreakpointAction from '~/app/core/state/breakpoint/breakpoint.action';

export const breakpointReducer = createReducer(
  initialBreakpointState,
  on(BreakpointAction.changeCurrentBreakpoint, (state, { breakpoint }) => ({
    currentBreakpoint: breakpoint,
  }))
);
