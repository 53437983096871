import { createReducer, on } from '@ngrx/store';
import * as AssignActions from '~/app/states/main/jobhub/states/assign-offer/apply-offer.action';
import { initialApplyOfferState } from '~/app/states/main/jobhub/states/assign-offer/apply-offer.state';

export const applyOfferReducer = createReducer(
  initialApplyOfferState,

  on(AssignActions.loadOfferById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AssignActions.loadOfferByIdSuccess, (state, { offer }) => ({
    ...state,
    offer,
    loading: false,
  })),
  on(AssignActions.loadOfferByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AssignActions.loadAppliedProfiles, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AssignActions.loadAppliedProfilesSuccess, (state, { proposals }) => ({
    ...state,
    candidates: proposals,
    loading: false,
  })),
  on(AssignActions.loadAppliedProfilesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AssignActions.autoApplyOffer, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AssignActions.autoApplyOfferSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(AssignActions.autoApplyOfferFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AssignActions.manualApplyOffer, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AssignActions.manualApplyOfferSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(AssignActions.manualApplyOfferFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AssignActions.loadAllCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AssignActions.loadAllCandidatesSuccess, (state, { allCandidates }) => ({
    ...state,
    allCandidates,
    loading: false,
  })),
  on(AssignActions.loadAllCandidatesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AssignActions.applyOfferUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
