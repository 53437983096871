/**
 * Generates a random pastel color in hexadecimal format.
 * This function is designed to create aesthetically pleasing, soft colors by using a higher base lightness
 * combined with a random component for each of the red, green, and blue channels of the color.
 *
 * @returns {string} A string representing the color in hexadecimal format, prefixed with '#'.
 */
export function randomColor(): string {
  /**
   * Generates a hex color component for a pastel color.
   * It creates a component by combining a base lightness with a random value, ensuring the result is a light color.
   *
   * @returns {string} A two-digit hexadecimal string representing one of the RGB components of the color.
   */
  const randomPastelPart = (): string => {
    const baseLight = 127;
    const colorPart = Math.floor(Math.random() * 128 + baseLight).toString(16);
    return colorPart.length === 1 ? `0${colorPart}` : colorPart;
  };

  const red = randomPastelPart();
  const green = randomPastelPart();
  const blue = randomPastelPart();

  return `#${red}${green}${blue}`;
}
