import { createSelector } from '@ngrx/store';
import { selectSharedState } from '~/app/shared/shared.selector';

export const selectNavigationState = createSelector(
  selectSharedState,
  state => state.navigation
);

export const selectCurrentModule = createSelector(
  selectNavigationState,
  state => state.currentModule
);

export const selectActivatedModules = createSelector(
  selectNavigationState,
  state => state.activatedModules
);
