import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemeService } from '~/app/core/services/theme.service';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Represents a component for displaying features information.
 * This component serves as a container for presenting various features of the application.
 * It does not contain any specific logic for displaying dynamic content.
 * This component is a template.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-features' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './features.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./features.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  /**
   * Form group for the newsletter form.
   * @type {FormGroup}
   */
  newsLetterForm!: FormGroup;

  /**
   * Boolean flag indicating if the dark theme is currently active.
   * @type {boolean}
   */
  isDarkTheme = false;

  /**
   * Constructs an instance of the HeroComponent with the theme service injected via the constructor.
   *
   * @param {CustomToastService} toast - Custom toasts service.
   * @param {FormBuilder} formBuilder – FormBuilder service for creating forms.
   * @param {ThemeService} theme - Theme service
   */
  constructor(
    private formBuilder: FormBuilder,
    private toast: CustomToastService,
    private theme: ThemeService
  ) {
    this.isDarkTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Getter for accessing the 'email' FormControl within the newsletter form.
   * @type {FormControl} FormControl for the email input.
   */
  get email(): FormControl {
    return this.newsLetterForm.get('email') as FormControl;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes the search form structure.
   *
   * @returns {void} Does not return any value.
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the forms for search and pagination.
   *
   * @returns {void}
   * @memberof ConsultantsComponent
   */
  initForm(): void {
    this.newsLetterForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  /**
   * Register the email for the newsletter.
   *
   * @returns {void}
   */
  onSubmit(): void {
    this.toast.contrastToast('Subscribed to our newsletter');
  }
}
