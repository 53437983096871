import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  forgotPasswordResetUserValidation,
  forgotPasswordUserValidation,
} from '@npmicedev/icemodule/lib/validations/users';
import * as ResetPasswordActions from '~/app/auth/state/reset-password/reset-password.actions';
import {
  ForgotPasswordResetUser,
  ForgotPasswordUser,
} from '~/app/auth/types/reset-password.types';
import { AppState } from '~/app/core/state/app.state';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service to manage password reset functionality. It handles actions such as sending reset password requests,
 * verifying reset tokens, and updating passwords post-verification.
 *
 * @Injectable marks this class as a service that can be injected with Angular's dependency injection,
 * making it available throughout the application as a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  /**
   * Creates an instance of the ResetPasswordService with injected dependencies for state management and user notification.
   *
   * @param {Store<AppState>} store - The application-wide NgRx store for managing state.
   * @param {CustomToastService} toast - Service to display custom toast messages for errors and information.
   */
  constructor(
    private store: Store<AppState>,
    private toast: CustomToastService
  ) {}

  /**
   * Initiates a password reset request. Validates the provided data using forgotPasswordUserValidation before dispatching.
   * If the validation fails, it shows an error toast with the validation errors.
   *
   * @param {ForgotPasswordUser} forgotPasswordData - Data needed for the password reset request.
   * @return {void}
   */
  resetPassword(forgotPasswordData: ForgotPasswordUser): void {
    const result =
      forgotPasswordUserValidation.body.safeParse(forgotPasswordData);

    if (result.success) {
      this.store.dispatch(
        ResetPasswordActions.resetPassword({ forgotPasswordData })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Verifies the reset password token by dispatching a check action with the token.
   *
   * @param {string} token - The token to verify for password reset.
   * @return {void}
   */
  checkResetPassword(token: string): void {
    this.store.dispatch(ResetPasswordActions.checkResetPassword({ token }));
  }

  /**
   * Completes the password reset process by updating the password. Validates the new password data
   * using forgotPasswordResetUserValidation before dispatching.
   * If the validation fails, it displays an error toast.
   *
   * @param {string} token - Token verifying the user's permission to reset the password.
   * @param {ForgotPasswordResetUser} patchPasswordData - New password data to be set.
   * @return {void}
   */
  patchPassword(
    token: string,
    patchPasswordData: ForgotPasswordResetUser
  ): void {
    const result =
      forgotPasswordResetUserValidation.body.safeParse(patchPasswordData);

    if (result.success) {
      this.store.dispatch(
        ResetPasswordActions.patchPassword({ token, patchPasswordData })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
