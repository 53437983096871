import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Describes the state of AI consulting within the application, including the current request, matching object,
 * search term, progress, loading status, and error information.
 *
 * @interface AiConsultingState
 */
export interface AiConsultingState {
  /**
   * The current WebSocket request for AI consulting.
   *
   * @type {WsRequest | null}
   */
  request: WsRequest | null;

  /**
   * The matching object containing job matching details.
   *
   * @type {MatchingObject | null}
   */
  matching: MatchingObject | null;

  /**
   * The current search term used for job matching.
   *
   * @type {string | null}
   */
  search: string | null;

  /**
   * The progress information for the current job matching process.
   *
   * @type {Progress | null}
   */
  progress: Progress | null;

  /**
   * Indicates whether the state is currently loading.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the AI consulting reducer, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {AiConsultingState}
 * @description Provides a baseline definition for the AI consulting context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialConsultingReducerState: AiConsultingState = {
  request: null,
  matching: null,
  search: null,
  progress: null,
  loading: false,
  error: null,
};
