import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  createProfileValidation,
  updateProfileByIdValidation,
} from '@npmicedev/icemodule/lib/validations/profiles/profiles';
import { ProfileState } from '~/app/main/crm/profile/profile-layout/state/profile.state';
import * as ProfileActions from '~/app/main/crm/profile/profile-layout/state/profile.actions';
import {
  CreateProfile,
  UpdateProfileById,
} from '~/app/main/crm/profile/types/profile.types';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * ProfileService is responsible for managing profiles.
 * It provides methods to load, create, update and delete profiles.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /**
   * Constructs an instance of the class.
   *
   * @param {Store<ProfileState>} store - Injectable store for managing profile state.
   * @param {CustomToastService} toast - Injectable custom toast service for displaying notifications.
   */
  constructor(
    private store: Store<ProfileState>,
    private toast: CustomToastService
  ) {}

  /**
   * Loads a profile by its UUID.
   *
   * @param {string} uuid - The UUID of the profile to load.
   * @return {void}
   */
  loadProfile(uuid: string): void {
    this.store.dispatch(ProfileActions.loadProfile({ uuid }));
  }

  /**
   * Creates a new profile using the given profile data.
   *
   * @param {CreateProfile} profileData - The profile data to create a profile.
   * @return {void}
   */
  createProfile(profileData: CreateProfile): void {
    const result = createProfileValidation.body.safeParse(profileData);

    if (result.success) {
      this.store.dispatch(ProfileActions.createProfile({ profileData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Updates an existing profile with the given profile data.
   *
   * @param {string} uuid - The UUID of the profile to update.
   * @param {UpdateProfileById} profileData - The profile data to update a profile.
   * @return {void}
   */
  updateProfile(uuid: string, profileData: UpdateProfileById): void {
    const result = updateProfileByIdValidation.body.safeParse(profileData);

    if (result.success) {
      this.store.dispatch(
        ProfileActions.updateProfile({
          uuid,
          profileData,
        })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Assign an existing profile tag to a profile.
   *
   * @param {string} uuid - The UUID of the profile to update.
   * @param {string} tagId - The UUID of the tag to assign.
   * @return {void}
   */
  assignProfileTag(uuid: string, tagId: string): void {
    this.store.dispatch(ProfileActions.assignProfileTag({ uuid, tagId }));
  }

  /**
   * Unassign an existing profile tag.
   *
   * @param {string} uuid - The UUID of the profile to update.
   * @param {string} tagId - The UUID of the tag to unassign.
   * @return {void}
   */
  unassignProfileTag(uuid: string, tagId: string): void {
    this.store.dispatch(ProfileActions.unassignProfileTag({ uuid, tagId }));
  }
}
