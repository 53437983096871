<div #iceInput class="pb-3 relative w-full flex flex-column gap-2">
  <div class="flex justify-content-between">
    <label [for]="formControlName" class="color-dark-80 text-sm mx-0">
      {{ label }} <span *ngIf="required" class="text-red-600">*</span>
    </label>
    <app-ice-info-tooltip
      *ngIf="tooltip"
      [text]="tooltip"></app-ice-info-tooltip>
  </div>

  <textarea
    *ngIf="textArea; else elseArea"
    [value]="value"
    (input)="onInput($event)"
    [id]="formControlName"
    type="text"
    [placeholder]="placeholder ? placeholder : ''"
    [formControl]="formControl"
    [appFormError]="formControlName"
    (formError)="onErrorMessageReceived($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [isSubmitted]="isSubmitted"
    pInputTextarea
    [rows]="rows"
    [cols]="cols"
    class="w-full"></textarea>
  <ng-template #elseArea>
    <span
      class="w-full"
      [ngClass]="{
        'p-input-icon-left': startIcon,
        'p-input-icon-right': endIcon,
      }">
      <i *ngIf="startIcon" [class]="startIcon"></i>
      <input
        [appNumber]="number"
        class="w-full"
        [ngClass]="{
          'cursor-stop': disabled,
        }"
        [value]="value"
        (input)="onInput($event)"
        [id]="formControlName"
        [placeholder]="placeholder ? placeholder : ''"
        [formControl]="formControl"
        [appFormError]="formControlName"
        (formError)="onErrorMessageReceived($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [disabled]="disabled"
        [isSubmitted]="isSubmitted"
        pInputText />
      <i
        *ngIf="endIcon"
        [class]="endIcon"
        [ngClass]="{
          'cursor-pointer': endIconButton && !endIconButtonDisable,
        }"
        (click)="
          endIconButton && !endIconButtonDisable ? endIconAction.emit() : null
        "></i>
    </span>
  </ng-template>

  <div *ngIf="error" class="text-xs text-red-600 absolute bottom-0">
    {{ error }}
  </div>
</div>
