import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileLanguagesActions from '~/app/states/main/crm/profile/states/profile-languages/profile-languages.actions';
import { ProfileLanguage } from '~/app/shared/interfaces/crm/profile/profile-language.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileLanguagesEffects` manages the side effects for profile language-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile languages, such as loading, creating, and deleting profile languages.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileLanguages$`: Loads profile languages based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched languages or a failure action if an error occurs.
 * — `createProfileLanguage$`: Creates a new profile language. It performs an HTTP POST request and dispatches a success action with the created language or a failure action if an error occurs.
 * — `createProfileLanguageSuccess$`: Handles the success of creating a new profile language by displaying a success toast message.
 * — `deleteProfileLanguage$`: Deletes a profile language based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileLanguageSuccess$`: Handles the success of deleting a profile language by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileLanguagesEffects {
  /**
   * Effect to load profile languages based on a given profile ID.
   * Listens for the `loadProfileLanguages` action, checks permissions, and performs an HTTP GET request to fetch the languages.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLanguagesActions.loadProfileLanguages),
      checkPermission(ProfileLanguagesActions.profileLanguagesUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<ProfileLanguage[]>(
            `${environment.apiUrl}/v1/profiles/languages`,
            {
              params: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileLanguages =>
              ProfileLanguagesActions.loadProfileLanguagesSuccess({
                profileLanguages,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileLanguagesActions.loadProfileLanguagesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new profile language.
   * Listens for the `createProfileLanguage` action, checks permissions, and performs an HTTP POST request to create the language.
   * Dispatches a success or failure action based on the result.
   */
  createProfileLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLanguagesActions.createProfileLanguage),
      checkPermission(ProfileLanguagesActions.profileLanguagesUnauthorized),
      mergeMap(({ profileLanguage }) =>
        this.http
          .post<ProfileLanguage>(
            `${environment.apiUrl}/v1/profiles/languages`,
            profileLanguage,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileLanguage =>
              ProfileLanguagesActions.createProfileLanguageSuccess({
                profileLanguage,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileLanguagesActions.createProfileLanguageFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile language.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  createProfileLanguageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileLanguagesActions.createProfileLanguageSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile language created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a profile language based on its UUID and profile ID.
   * Listens for the `deleteProfileLanguage` action, checks permissions, and performs an HTTP DELETE request to delete the language.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLanguagesActions.deleteProfileLanguage),
      checkPermission(ProfileLanguagesActions.profileLanguagesUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/languages/${uuid}`, {
            body: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              ProfileLanguagesActions.deleteProfileLanguageSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileLanguagesActions.deleteProfileLanguageFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a profile language.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteProfileLanguageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileLanguagesActions.deleteProfileLanguageSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile language deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileLanguagesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
