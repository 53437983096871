import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';

/**
 * A custom Angular pipe that adjusts the color of text to ensure it remains readable against various backgrounds.
 * It uses the 'tinycolor2' library to assess the lightness of the provided color and then intelligently darkens or lightens
 * the color based on its initial value. This pipe is especially useful for dynamic text coloring in UI components
 * where background colors are variable and text visibility is crucial.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'smartTextColor', enabling its use within Angular templates
 * to dynamically adjust text colors for better readability.
 */
@Pipe({
  name: 'smartTextColor',
})
export class SmartTextColorPipe implements PipeTransform {
  /**
   * Transforms a color value by either darkening or lightening it based on its lightness to ensure text visibility.
   * The transformation increases contrast with potential background colors, particularly useful in dynamic UI themes.
   *
   * @param {string} value - The color value in any format accepted by 'tinycolor2' (e.g., HEX, RGB, HSL).
   * @param {number} [amount=10] - The base amount to adjust the color by, which is scaled up if the color is dark.
   * @returns {string} - The adjusted color as a string in the best format detected by 'tinycolor2'.
   */
  transform(value: string, amount = 70): string {
    const color = tinycolor(value);

    if (!color.isValid() || color.getAlpha() === 0) {
      return '#000000';
    }

    const lightness = color.getLuminance();
    const threshold = 0.7;

    if (lightness > threshold) {
      return color.darken(amount).toString() + ' !important';
    } else {
      return color.lighten(amount * 2).toString() + ' !important';
    }
  }
}
