import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AllSkillsFilters } from '~/app/shared/interfaces/data/all-skills-filters.interface';
import * as AllSkillsActions from '~/app/shared/states/skills/skills.actions';
import { UtilsService } from '~/app/shared/services/utils.service';
import { AllSkillsState } from '~/app/shared/states/skills/skills.state';

/**
 * `SkillsService` manages operations related to loading all skills,
 * applying filters, and interacting with the NgRx store to dispatch actions.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  /**
   * Constructs an instance of the `SkillsService`.
   *
   * @param {Store<AllSkillsState>} store - Injectable store for managing all skills state.
   * @param {UtilsService} utils - Utility service for various helper methods.
   */
  constructor(
    private store: Store<AllSkillsState>,
    private utils: UtilsService
  ) {}

  /**
   * Loads all skills with optional filters.
   *
   * @param {AllSkillsFilters} [filters] - Optional filters to apply when loading skills.
   * @returns {void}
   * @memberof SkillsService
   */
  loadAllSkills(filters?: AllSkillsFilters): void {
    this.store.dispatch(
      AllSkillsActions.loadAllSkills({
        filters: this.utils.filterValues(filters),
      })
    );
  }
}
