import { createReducer, on } from '@ngrx/store';
import { initialKanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.state';
import * as KanbanColumnsActions from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.actions';

export const kanbanColumnsReducer = createReducer(
  initialKanbanColumnsState,

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/
  on(KanbanColumnsActions.loadAllKanbanColumns, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanColumnsActions.loadAllKanbanColumnsSuccess,
    (state, { kanbanColumns, reloadAll }) => ({
      ...state,
      data: reloadAll
        ? kanbanColumns.data
        : [...state.data, ...kanbanColumns.data].filter(
            (column, index, columns) =>
              columns.findIndex(c => c.uuid === column.uuid) === index
          ),
      page: kanbanColumns.page,
      limit: kanbanColumns.limit,
      count: kanbanColumns.count,
      hasNext: kanbanColumns.hasNext,
      hasPrevious: kanbanColumns.hasPrevious,
      loading: false,
    })
  ),
  on(KanbanColumnsActions.loadAllKanbanColumnsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/
  on(KanbanColumnsActions.createKanbanColumn, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanColumnsActions.createKanbanColumnSuccess,
    KanbanColumnsActions.createKanbanColumnServer,
    (state, { column }) => ({
      ...state,
      data:
        state.hasNext || (state.count / state.limit) % 2 === 0
          ? state.data
          : [...state.data, column],
      count: state.count + 1,
      loading: false,
      hasNext: state.hasNext || (state.count / state.limit) % 2 === 0,
    })
  ),
  on(KanbanColumnsActions.createKanbanColumnFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanColumnsActions.updateKanbanColumn, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanColumnsActions.updateKanbanColumnSuccess,
    KanbanColumnsActions.updateKanbanColumnServer,
    (state, { kanbanColumn }) => ({
      ...state,
      data: state.data.map(item =>
        item.uuid === kanbanColumn.uuid ? kanbanColumn : item
      ),
      loading: false,
    })
  ),
  on(KanbanColumnsActions.updateKanbanColumnFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbanColumnsActions.deleteKanbanColumn, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanColumnsActions.deleteKanbanColumnSuccess,
    KanbanColumnsActions.deleteKanbanColumnServer,
    (state, { uuid }) => ({
      ...state,
      count: state.count - 1,
      data: state.data.filter(column => column.uuid !== uuid),
      loading: false,
    })
  ),
  on(KanbanColumnsActions.deleteKanbanColumnFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * REORDER COLUMNS
   * ************************************************************************
   **/
  on(
    KanbanColumnsActions.updateKanbanColumnSequences,
    (state, { columnSequencesData }) => ({
      ...state,
      data: state.data
        .map(column => {
          const kcolumn = columnSequencesData.columns.find(
            kColumn => kColumn.uuid === column.uuid
          );
          return {
            ...column,
            sequence: kcolumn ? kcolumn.sequence : column.sequence,
          };
        })
        .sort((a, b) => a.sequence - b.sequence),
      loading: true,
      error: null,
    })
  ),
  on(KanbanColumnsActions.updateKanbanColumnSequencesSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(
    KanbanColumnsActions.updateKanbanColumnSequencesFailure,
    (state, { error, oldSequence }) => ({
      ...state,
      data: state.data
        .map(column => {
          const col = oldSequence.find(
            columnold => columnold.uuid === column.uuid
          );
          return {
            ...column,
            sequence: col ? col.sequence : column.sequence,
          };
        })
        .sort((a, b) => a.sequence - b.sequence),
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/
  on(
    KanbanColumnsActions.updateKanbanColumnSequencesServer,
    (state, { columnSequencesData }) => ({
      ...state,
      data: state.data
        .map(column => {
          const kcolumn = columnSequencesData.columns.find(
            kColumn => kColumn.uuid === column.uuid
          );
          return {
            ...column,
            sequence: kcolumn ? kcolumn.sequence : column.sequence,
          };
        })
        .sort((a, b) => a.sequence - b.sequence),
    })
  ),
  /**
   * ************************************************************************
   * UNAUTHORIZED
   * ************************************************************************
   **/
  on(KanbanColumnsActions.kanbanColumnsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
