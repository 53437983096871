import { createSelector } from '@ngrx/store';
import { selectCrmState } from '~/app/states/main/crm/crm.selector';

export const selectAiCreationProfile = createSelector(
  selectCrmState,
  state => state.aiCreationProfile
);

export const selectProgress = createSelector(
  selectAiCreationProfile,
  state => state.progress
);

export const selectStatuses = createSelector(
  selectAiCreationProfile,
  state => state.statuses
);

export const selectOffices = createSelector(
  selectAiCreationProfile,
  state => state.offices
);

export const selectTeams = createSelector(
  selectAiCreationProfile,
  state => state.teams
);
