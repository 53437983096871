import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { environment } from '~/environments/environment';
import { selectServerToken } from '~/app/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class IsseService {
  private eventSourcePolyfill: EventSourcePolyfill | undefined;

  constructor(private store: Store) {}

  get eventSource(): EventSourcePolyfill {
    if (!this.eventSourcePolyfill) {
      this.store
        .select(selectServerToken)
        .pipe(
          take(1),
          tap(token => {
            this.eventSourcePolyfill = new EventSourcePolyfill(
              `${environment.isseUrl}/v1/messageEvent/listen`,
              {
                headers: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
              }
            );
          })
        )
        .subscribe();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.eventSourcePolyfill!;
  }
}
