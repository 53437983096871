import { createReducer, on } from '@ngrx/store';
import * as ProfileNotesActions from '~/app/states/main/crm/profile/states/profile-notes/profile-notes.actions';
import { initialProfileNotesState } from '~/app/states/main/crm/profile/states/profile-notes/profile-notes.state';

export const profileNotesReducer = createReducer(
  initialProfileNotesState,

  on(ProfileNotesActions.loadProfileNotes, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileNotesActions.loadProfileNotesSuccess,
    (state, { profileNotes }) => ({
      ...state,
      profileNotes,
      loading: false,
    })
  ),
  on(ProfileNotesActions.loadProfileNotesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileNotesActions.createProfileNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileNotesActions.createProfileNoteSuccess,
    (state, { profileNote }) => ({
      ...state,
      loading: false,
      profileNotes: [profileNote, ...state.profileNotes],
    })
  ),
  on(ProfileNotesActions.createProfileNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileNotesActions.updateProfileNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileNotesActions.updateProfileNoteSuccess,
    (state, { profileNote }) => ({
      ...state,
      profileNotes: state.profileNotes.map(note =>
        note.uuid === profileNote.uuid ? profileNote : note
      ),
      loading: false,
    })
  ),
  on(ProfileNotesActions.updateProfileNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileNotesActions.deleteProfileNote, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileNotesActions.deleteProfileNoteSuccess, (state, { uuid }) => ({
    ...state,
    profileNotes: state.profileNotes.filter(nc => nc.uuid !== uuid),
    loading: false,
  })),
  on(ProfileNotesActions.deleteProfileNoteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileNotesActions.profileNotesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
