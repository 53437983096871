<form class="h-full" [formGroup]="goalForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-column gap-2 mb-4">
    <label class="color-dark-80 text-sm mx-0">
      Target <span class="text-red-600">*</span>
    </label>
    <ice-select
      [formControl]="target"
      optionLabel="label"
      optionValue="value"
      [suggestions]="(targets$ | async) ?? []"></ice-select>
  </div>

  <ng-container *ngIf="target.value">
    <ice-autocomplete
      label="Goal Type"
      placeholder="Ex: Sales note"
      [required]="true"
      [formControl]="goalType"
      [dropdown]="false"
      optionLabel="label"
      optionValue="value"
      [suggestions]="(goalsTypes$ | async) ?? []"></ice-autocomplete>

    <div>
      <label class="color-dark-80 text-sm mx-0">
        Goal Rate <span class="text-red-600">*</span>
      </label>
      <div class="flex align-items-center gap-3">
        <ice-input
          placeholder="Goal"
          [formControl]="goal"
          [number]="true"></ice-input>
        <b>/</b>
        <ice-autocomplete
          placeholder="Ex: Day"
          [dropdown]="false"
          [formControl]="rate"
          optionLabel="label"
          optionValue="value"
          [suggestions]="(rates$ | async) ?? []"></ice-autocomplete>
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <label class="color-dark-80 text-sm mx-0">
        Scope <span class="text-red-600">*</span>
      </label>
      <ice-select
        [formControl]="scope"
        optionLabel="label"
        optionValue="value"
        [suggestions]="scopes"></ice-select>

      <ng-container [ngSwitch]="scope.value">
        <ng-container *ngSwitchCase="goalScope.Team">
          <ice-autocomplete
            [label]="'Team'"
            [required]="true"
            placeholder="Assign team"
            [formControl]="teamId"
            [iceAutocompleteTemplate]="templateEnum.DEFAULT"
            optionLabel="label"
            optionValue="value"
            [dropdown]="false"
            [suggestions]="(teams$ | async) ?? []"></ice-autocomplete>
        </ng-container>
        <ng-container *ngSwitchCase="goalScope.User">
          <ice-autocomplete
            [suggestions]="(users$ | async) ?? []"
            (completeMethod)="onSearch($event)"
            [formControl]="userId"
            [dropdown]="false"
            [iceAutocompleteTemplate]="templateEnum.USER"
            placeholder="Choose assignees"
            i18n-placeholder>
          </ice-autocomplete>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div class="sticky top-100">
    <p-divider></p-divider>

    <div
      class="flex flex-row-reverse justify-content-between align-items-center">
      <ice-button
        [rule]="iceRule.UpdateGoals"
        type="submit"
        class="flex justify-content-end"
        [disabled]="
          goalForm.invalid ||
          (scope.value === goalScopeEnum.Team && teamId.value === null) ||
          (scope.value === goalScopeEnum.User && userId.value === null)
        "
        [label]="goalUUID ? 'Update' : 'Create'"
        [loading]="(isLoading$ | async) ?? false">
      </ice-button>
      <ice-button
        [rule]="iceRule.DeleteGoals"
        *ngIf="goalUUID"
        (onClick)="deleteGoal()"
        label="Delete goal"
        i18n-label
        [text]="true"
        severity="danger"
        i18n-severity></ice-button>
    </div>
  </div>
</form>
