import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { AppState } from '~/app/core/state/app.state';
import { selectCurrentBreakpoint } from '~/app/core/state/breakpoint/breakpoint.selectors';
import { ThemeService } from '~/app/core/services/theme.service';
import { SidenavService } from '~/app/shared/services/sidenav.service';

/**
 * LayoutComponent is responsible for managing the overall layout of the application,
 * particularly the responsiveness and theming of side navigation based on the viewport size.
 *
 * @Component decorator defines this class as an Angular component.
 * @selector 'app-layout' - The CSS selector that represents this component in the template.
 * @templateUrl './layout.component.html' - The path to the HTML template associated with this component.
 * @styleUrls ['./layout.component.scss'] - The styles for this component.
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  /**
   * Observable of the current breakpoint.
   * @type {Observable<string>}
   */
  breakpoint$: Observable<string>;

  /**
   * Constructor for LayoutComponent that sets up reactive layout management based on viewport breakpoints.
   * This constructor initializes necessary observables for detecting screen size changes and adjusts the layout
   * dynamically by subscribing to breakpoint changes and updating the sidenav styles accordingly.
   *
   * @param {SidenavService} sidenavService - Service to manage the behavior and properties of the sidenav.
   * @param {ThemeService} theme - Service for managing application themes.
   * @param {Store<AppState>} store - NgRx store to access application-wide state, particularly for responsive breakpoints.
   */
  constructor(
    protected sidenavService: SidenavService,
    public theme: ThemeService,
    private store: Store<AppState>
  ) {
    this.breakpoint$ = this.store.select(selectCurrentBreakpoint);
    this.breakpoint$
      .pipe(
        tap(breakpointName => {
          switch (breakpointName) {
            case 'sm':
              this.setSidenavStyles('closable', '0px');
              break;
            case 'md':
              this.setSidenavStyles('closable', '0px');
              break;
            case 'lg':
              this.setSidenavStyles('collapsable', '70px');
              break;
            case 'xl':
              this.setSidenavStyles('collapsable', '70px');
              break;
            default:
              break;
          }
        })
      )
      .subscribe();
  }

  /**
   * Updates a CSS custom property on the document element.
   * @param {string} property - The CSS custom property to update.
   * @param {string} value - The value to set for the property.
   * @returns {void}
   */
  private updateCssCustomProperty(property: string, value: string): void {
    const root = document.querySelector(':root') as HTMLElement;
    root.style.setProperty(property, value);
  }

  /**
   * Sets the sidenav styles based on the current breakpoint.
   * @param {'closable' | 'collapsable'} sidenavMode - The mode to set for the sidenav.
   * @param {string} collapsedWidth - The width of the sidenav when collapsed.
   * @returns {void}
   */
  private setSidenavStyles(
    sidenavMode: 'closable' | 'collapsable',
    collapsedWidth: string
  ): void {
    this.updateCssCustomProperty('--sidenav-collapsed-width', collapsedWidth);
  }
}
