import { createReducer, on } from '@ngrx/store';
import * as OfferActions from '~/app/states/main/jobhub/states/company-offer/company-offers.actions';
import { initialAllCompanyOffersState } from '~/app/states/main/jobhub/states/company-offer/company-offers.state';

export const allCompanyOffersReducer = createReducer(
  initialAllCompanyOffersState,

  on(OfferActions.loadAllCompanyOffers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfferActions.loadAllCompanyOffersSuccess, (state, { companyOffers }) => ({
    ...state,
    ...companyOffers,
    loading: false,
  })),
  on(OfferActions.loadAllCompanyOffersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfferActions.companyOfferUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
