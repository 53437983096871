import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';

export const refreshToken = createAction('[Auth] Refresh Token Interceptor');
export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Interceptor Success',
  props<{ userData: AuthUser }>()
);
export const refreshTokenFailure = createAction(
  '[Auth] Refresh Token Interceptor Failure',
  props<{ error: string }>()
);

export const registerAction = createAction(
  '[Auth] Register Action',
  props<{ action: TypedAction<any> }>()
);

export const authUnauthorized = createAction(
  '[Auth] Unauthorized',
  props<{ error: string }>()
);
