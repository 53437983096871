import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanyNotesActions from '~/app/states/main/client-company/profile/states/client-company-notes/client-company-notes.actions';
import { ClientCompanyNote } from '~/app/shared/interfaces/client-company/profile/client-company-note.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanyNotesEffects` handles the side effects related to client company notes in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading, creating, updating, and deleting client company notes from the server.
 *
 * Effects included in this class:
 * — `loadClientCompanyInterviews$`: Fetches client company notes by company ID from the server.
 * — `createClientCompanyNotes$`: Creates a new client company note on the server.
 * — `createClientCompanyNotesSuccess$`: Handles successful client company note creation.
 * — `updateClientCompanyNotes$`: Updates an existing client company note on the server.
 * — `updateClientCompanyNotesSuccess$`: Handles successful client company note updates.
 * — `deleteClientCompanyNotes$`: Deletes a client company note from the server.
 * — `deleteClientCompanyNotesSuccess$`: Handles successful client company note deletion.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanyNotesEffects {
  /**
   * Effect to load client company notes by company ID.
   */
  loadClientCompanyInterviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyNotesActions.loadClientCompanyNotes),
      checkPermission(ClientCompanyNotesActions.clientCompanyNotesUnauthorized),
      mergeMap(({ clientCompanyId }) =>
        this.http
          .get<
            ClientCompanyNote[]
          >(`${environment.apiUrl}/v1/clientCompanies/notes`, { params: { clientCompanyId }, withCredentials: true })
          .pipe(
            take(1),
            map(clientCompanyNotes =>
              ClientCompanyNotesActions.loadClientCompanyNotesSuccess({
                clientCompanyNotes,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyNotesActions.loadClientCompanyNotesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new client company note.
   */
  createClientCompanyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyNotesActions.createClientCompanyNotes),
      checkPermission(ClientCompanyNotesActions.clientCompanyNotesUnauthorized),
      mergeMap(({ clientCompanyNoteData }) =>
        this.http
          .post<ClientCompanyNote>(
            `${environment.apiUrl}/v1/clientCompanies/notes`,
            clientCompanyNoteData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompanyNote =>
              ClientCompanyNotesActions.createClientCompanyNotesSuccess({
                clientCompanyNote,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyNotesActions.createClientCompanyNotesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful client company note creation.
   */
  createClientCompanyNotesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyNotesActions.createClientCompanyNotesSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company note created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing client company note.
   */
  updateClientCompanyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyNotesActions.updateClientCompanyNotes),
      checkPermission(ClientCompanyNotesActions.clientCompanyNotesUnauthorized),
      mergeMap(({ uuid, clientCompanyNoteData }) =>
        this.http
          .patch<ClientCompanyNote>(
            `${environment.apiUrl}/v1/clientCompanies/notes/${uuid}`,
            clientCompanyNoteData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompanyNote =>
              ClientCompanyNotesActions.updateClientCompanyNotesSuccess({
                clientCompanyNote,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyNotesActions.updateClientCompanyNotesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful client company note updates.
   */
  updateClientCompanyNotesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyNotesActions.updateClientCompanyNotesSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company note updated successfully'
          );
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a client company note.
   */
  deleteClientCompanyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyNotesActions.deleteClientCompanyNotes),
      checkPermission(ClientCompanyNotesActions.clientCompanyNotesUnauthorized),
      mergeMap(({ uuid, clientCompanyId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/clientCompanies/notes/${uuid}`,
            {
              body: { clientCompanyId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              ClientCompanyNotesActions.deleteClientCompanyNotesSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyNotesActions.deleteClientCompanyNotesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful client company note deletion.
   */
  deleteClientCompanyNotesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientCompanyNotesActions.deleteClientCompanyNotesSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Client company note deleted successfully'
          );
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ClientCompanyNotesEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {CustomToastService} toast - Injects the CustomToastService.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {SidenavFormService} sidebar - Injects the SidenavFormService.
   * @memberof ClientCompanyNotesEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService,
    private sidebar: SidenavFormService
  ) {}
}
