import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { Distribution } from '~/app/shared/interfaces/crm/profile/distribution';

/**
 * Represents the state containing all candidates with pagination details and loading/error states.
 *
 * @interface AllCandidatesState
 */
export interface AllCandidatesState {
  /**
   * The total number of candidates.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The limit of candidates per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there are more pages available.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there are previous pages available.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * The list of candidates.
   *
   * @type {Candidate[]}
   */
  data: Candidate[];

  /**
   * The loading state for fetching all candidates.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message for fetching all candidates.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * The salary max.
   *
   * @type {number}
   */
  salaryMax: number;

  /**
   * The distribution of candidates based on salary.
   *
   * @type {Distribution[]}
   */
  distribution: Distribution[];
}

/**
 * The initial state of all candidates within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {AllCandidatesState} initialAllCandidatesState
 * @description Provides a baseline definition for the candidates context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialAllCandidatesState: AllCandidatesState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
  salaryMax: 0,
  distribution: [],
};
