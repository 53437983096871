<section class="custom-height flex p-4 gap-3-5">
  <div
    *ngIf="companyName$ | async as companyName"
    class="flex flex-column justify-content-between border-round w-full p-4">
    <div class="flex align-items-end gap-3-5">
      <img
        class="mb-2"
        src="/assets/ama-logo.png"
        alt="Company Logo"
        height="30" />
    </div>
    <div class="m-auto w-full" style="max-width: 25rem">
      <ng-container
        *ngIf="!(isLoggedIn$ | async)?.userData?.token; else loggedIn"
        class="flex flex-column gap-3-5">
        <div>
          <h4 i18n class="display-h4 color-dark-100 mx-0 mb-0 mt-4-8 md:m-0">
            Welcome back!
          </h4>
          <p class="text-base color-dark-70">Please enter your details.</p>
        </div>
        <form [formGroup]="adminLoginForm" (ngSubmit)="onSubmit()">
          <div>
            <ice-input
              [attr.data-cy]="'email'"
              [label]="'Email'"
              [formControlName]="'email'"
              [formControl]="email">
            </ice-input>
            <ice-password
              [attr.data-cy]="'password'"
              [label]="'Password'"
              [formControlName]="'password'"
              [formControl]="password">
            </ice-password>

            <div class="flex align-items-center justify-content-between mb-6">
              <a
                i18n
                routerLink="/forgot-password"
                class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                >Forgot password?</a
              >
            </div>

            <div class="w-full md:flex md:justify-content-between">
              <div class="h-1rem w-2rem"></div>
              <ice-button
                [attr.data-cy]="'login-button'"
                [fullWidth]="true"
                label="Go In"
                i18n-label
                i18n-icon
                type="submit"
                [disabled]="!adminLoginForm.valid"
                [loading]="(isLoading$ | async) ?? false" />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-template #loggedIn>
        <div
          *ngIf="isLoggedIn$ | async as user"
          class="flex flex-column gap-3-5">
          <div>
            <h4 i18n class="display-h4 color-dark-100 mx-0 mb-0 mt-4-8 md:m-0">
              Hi {{ user.userData?.user?.firstname }}! 👋
            </h4>
            <p class="text-base color-dark-70">You are already logged in.</p>
          </div>
          <div class="flex flex-column w-full text-center gap-3-5">
            <ice-button
              [attr.data-cy]="'IceBerry'"
              label="IceBerry"
              [style]="{ width: '100%' }"
              i18n-label
              [routerLink]="'/home'" />
            <ice-button
              [attr.data-cy]="'switch-account'"
              label="Switch Account"
              [text]="true"
              i18n-label
              (onClick)="logout()"
              [icon]="'pi pi-arrow-right-arrow-left'"
              severity="info" />
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="isSamlConfigured$ | async">
        <p-divider align="center">
          <b class="block">Or</b>
        </p-divider>
        <ice-button
          [attr.data-cy]="'login-button'"
          label="Azure Connect"
          i18n-label
          i18n-icon
          [style]="{ width: '100%' }"
          icon="pi pi-microsoft"
          iconPos="right"
          [loading]="(isLoading$ | async) ?? false"
          (onClick)="samlLogin()"
          class="azure-gradient" />
      </ng-container>
    </div>
    <div
      i18n
      class="flex justify-content-center gap-1 mb-custom cursor-pointer"
      (click)="redirectToLandingPage()">
      <img
        src="/assets/icons/ice-berry-logo.svg"
        alt="Iceberry Logo"
        height="20" />
      <span class="color-dark-80 text-xs align-content-center">ICEBERRY</span>
    </div>
  </div>
  <div
    class="hidden lg:flex flex-column justify-content-center w-full bg-gradiant"
    style="border-radius: 2rem">
    <div class="flex flex-column gap-4-8 max-w-30rem w-full m-auto">
      <div
        class="flex justify-content-between align-items-center p-3 border-round bg-dark-80 custom-blur border-dark-60 border-1 cursor-pointer custom-hover">
        <div class="flex align-items-center gap-4-8">
          <i class="pi pi-clock color-light-100" style="font-size: 1.3rem"></i>
          <div>
            <p class="color-light-100 text-base m-0">
              View our product roadmap
            </p>
            <p class="text-sm color-light-70 m-0">
              Browse and vote on what's next
            </p>
          </div>
        </div>

        <i
          class="pi pi-angle-right color-light-100"
          style="font-size: 1.3rem"></i>
      </div>
      <div
        class="flex justify-content-between align-items-center p-3 border-round bg-dark-80 custom-blur border-dark-60 border-1 cursor-pointer custom-hover">
        <div class="flex align-items-center gap-4-8">
          <i
            class="pi pi-question-circle color-light-100"
            style="font-size: 1.3rem"></i>
          <div>
            <p class="color-light-100 text-base m-0">Need help?</p>
            <p class="text-sm color-light-70 m-0">Contact our support team</p>
          </div>
        </div>

        <i
          class="pi pi-angle-right color-light-100"
          style="font-size: 1.3rem"></i>
      </div>
      <div
        class="flex justify-content-between align-items-center p-3 border-round bg-dark-80 custom-blur border-dark-60 border-1 cursor-pointer custom-hover">
        <div class="flex align-items-center gap-4-8">
          <i
            class="pi pi-info-circle color-light-100"
            style="font-size: 1.3rem"></i>
          <div>
            <p class="color-light-100 text-base m-0">
              Learn more about IceBerry
            </p>
            <p class="text-sm color-light-70 m-0">Visit our website</p>
          </div>
        </div>
        <i
          class="pi pi-angle-right color-light-100"
          style="font-size: 1.3rem"></i>
      </div>
    </div>
  </div>
</section>
