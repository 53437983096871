import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as HomeSalesRemindersActions from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.actions';
import { HomeSalesRemindersState } from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `HomeSalesRemindersEffects` orchestrates the handling of asynchronous operations related to home sales reminders in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to home sales reminders, such as fetching,
 * checking, and unchecking reminders from the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadHomeSalesReminders$`: Fetches home sales reminders from the server.
 * — `checkSalesReminder$`: Marks a sales reminder as checked.
 * — `uncheckSalesReminder$`: Marks a sales reminder as unchecked.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class HomeSalesRemindersEffects {
  /**
   * Effect to load home sales reminders.
   */
  loadHomeSalesReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeSalesRemindersActions.loadHomeSalesReminders),
      checkPermission(HomeSalesRemindersActions.homeSalesRemindersUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<HomeSalesRemindersState>(
            `${environment.apiUrl}/v1/sales/allReminders`,
            { params: { ...filters } }
          )
          .pipe(
            take(1),
            map(salesReminders =>
              HomeSalesRemindersActions.loadHomeSalesRemindersSuccess({
                salesReminders,
                filters,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeSalesRemindersActions.loadHomeSalesRemindersFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to check a sales reminder.
   */
  checkSalesReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeSalesRemindersActions.checkSalesReminder),
      checkPermission(HomeSalesRemindersActions.homeSalesRemindersUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .patch(
            `${environment.apiUrl}/v1/sales/reminders/check/${uuid}`,
            { saleId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              HomeSalesRemindersActions.checkSalesReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeSalesRemindersActions.checkSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful sales reminder check.
   */
  checkSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomeSalesRemindersActions.checkSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder checked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to uncheck a sales reminder.
   */
  uncheckSalesReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeSalesRemindersActions.uncheckSalesReminder),
      checkPermission(HomeSalesRemindersActions.homeSalesRemindersUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .patch(
            `${environment.apiUrl}/v1/sales/reminders/uncheck/${uuid}`,
            { saleId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              HomeSalesRemindersActions.uncheckSalesReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeSalesRemindersActions.uncheckSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful sales reminder uncheck.
   */
  uncheckSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomeSalesRemindersActions.uncheckSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder unchecked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for HomeSalesRemindersEffects.
   *
   * @param {Actions} actions$ — The stream of actions observed by this effect.
   * @param {HttpClient} http — The HTTP client used for making API calls.
   * @param {HttpErrorsService} httpErrors — Service for handling HTTP errors.
   * @param {CustomToastService} toast — Service for displaying toast messages.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
