import { createReducer, on } from '@ngrx/store';
import * as ProfileLanguagesActions from '~/app/states/main/crm/profile/states/profile-languages/profile-languages.actions';
import { initialProfileLanguagesState } from '~/app/states/main/crm/profile/states/profile-languages/profile-languages.state';

export const profileLanguagesReducer = createReducer(
  initialProfileLanguagesState,

  on(ProfileLanguagesActions.loadProfileLanguages, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileLanguagesActions.loadProfileLanguagesSuccess,
    (state, { profileLanguages }) => ({
      ...state,
      profileLanguages,
      loading: false,
    })
  ),
  on(
    ProfileLanguagesActions.loadProfileLanguagesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileLanguagesActions.createProfileLanguage, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileLanguagesActions.createProfileLanguageSuccess,
    (state, { profileLanguage }) => ({
      ...state,
      loading: false,
      profileLanguages: [...state.profileLanguages, profileLanguage],
    })
  ),
  on(
    ProfileLanguagesActions.createProfileLanguageFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileLanguagesActions.deleteProfileLanguage, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileLanguagesActions.deleteProfileLanguageSuccess,
    (state, { uuid }) => ({
      ...state,
      loading: false,
      profileLanguages: state.profileLanguages.filter(
        language => language.uuid !== uuid
      ),
    })
  ),
  on(
    ProfileLanguagesActions.deleteProfileLanguageFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileLanguagesActions.profileLanguagesUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
