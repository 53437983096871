import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectAuthLoading,
  selectCurrentUser,
} from '~/app/auth/auth.selectors';
import { RegisterService } from '~/app/auth/services/register.service';
import { selectRegisterError } from '~/app/auth/state/register/register.selectors';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { RegisterFormComponent } from '~/app/shared/components/register-form/register-form.component';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Component for handling the final step of user registration, which includes
 * redirection after successful registration and token verification. It displays
 * user registration status and errors if any occur during the registration process.
 *
 * @Component
 * @selector 'app-register-redirection' - The CSS selector that identifies this component in a template.
 * @templateUrl './register-redirection.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./register-redirection.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-register-redirection',
  templateUrl: './register-redirection.component.html',
  styleUrls: ['./register-redirection.component.scss'],
})
export class RegisterRedirectionComponent implements OnInit {
  /**
   * Countdown timer value to redirect user, typically after successful registration.
   * @type {number}
   */
  countdown = 5;

  /**
   * Observable of the currently authenticated user.
   * @type {Observable<AuthUser | null>}
   */
  user$: Observable<AuthUser | null>;

  /**
   * Observable of any registration errors encountered during the process.
   * @type {Observable<string | null>}
   */
  error$: Observable<string | null>;

  /**
   * Observable indicating if there is an ongoing authentication process.
   * @type {Observable<boolean>}
   */
  loading$: Observable<boolean>;

  /**
   * Constructs the RegisterRedirectionComponent with necessary dependencies.
   *
   * @param {Store<AppState>} store NgRx store for accessing the application state related to authentication.
   * @param {ActivatedRoute} route ActivatedRoute to access route parameters, particularly for token validation.
   * @param {RegisterService} register RegisterService which handles registration logic and token validation.
   * @param {ThemeService} theme ThemeService to manage theming across the application.
   * @param {Router} router Router for navigating to different routes within the application.
   * @param {DialogService} dialog DialogService for displaying dialog components.
   */
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private register: RegisterService,
    public theme: ThemeService,
    private router: Router,
    private dialog: DialogService
  ) {
    this.user$ = this.store.select(selectCurrentUser);
    this.error$ = this.store.select(selectRegisterError);
    this.loading$ = this.store.select(selectAuthLoading);
  }

  /**
   * OnInit lifecycle hook to perform initial data fetching operations such as token validation.
   * The token is retrieved from route parameters and validated via the RegisterService.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.register.checkToken(this.route.snapshot.params['token']);
    this.user$.subscribe(user => {
      if (user) {
        this.countdownRedirect();
      }
    });
  }

  /**
   * Method to navigate to the login page after the countdown timer expires.
   *
   * @return {void}
   */
  navigateThenOpenRegister(): void {
    this.router
      .navigate(['/'])
      .then(() => {
        this.dialog.loadComponent(RegisterFormComponent, {
          centered: 'centered',
          showBackdrop: true,
          closable: true,
        });
        this.dialog.toggleDialog();
      })
      .catch(error => {
        console.error('Navigation error:', error);
      });
  }

  /**
   * Method to redirect the user to the login page after a countdown timer expires.
   *
   * @return {void}
   */
  countdownRedirect(): void {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval);
        this.router
          .navigate(['/jobhub'])
          .then()
          .catch(error => {
            console.error('Navigation error:', error);
          });
      }
    }, 1000);
  }
}
