import { Sales } from '@npmicedev/icemodule/lib/entities/Sales';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Project } from '~/app/shared/interfaces/sales/project/project.interface';

/**
 * Interface representing the state of projects.
 *
 * @interface ProjectState
 */
export interface ProjectState {
  /**
   * The current project.
   * @type {Project | null}
   */
  project: Project | null;

  /**
   * List of users associated with the current project.
   * @type {User[]}
   */
  users: User[];

  /**
   * List of sales associated with the current project.
   * @type {Sales[]}
   */
  sales: Sales[];

  /**
   * Loading state of the project.
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message related to project operations.
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for the project.
 *
 * @type {ProjectState}
 */
export const initialProjectState: ProjectState = {
  project: null,
  users: [],
  sales: [],
  loading: false,
  error: null,
};
