import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { DialogService } from '~/app/shared/services/dialog.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';
import * as OffersActions from '~/app/states/main/jobhub/states/offers/offers.actions';

/**
 * `OffersEffects` manages the side effects for offer-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to offers,
 * such as creating, updating, and deleting offers. It performs operations that involve checking permissions,
 * interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `createOffer$`: Creates a new offer. It performs an HTTP POST request and dispatches a success action if successful or a failure action if an error occurs.
 * — `createOfferSuccess$`: Handles the success of creating a new offer by displaying a success toast message and closing the sidebar form.
 * — `updateOffer$`: Updates an existing offer. It performs an HTTP PATCH request and dispatches a success action if successful or a failure action if an error occurs.
 * — `updateOfferSuccess$`: Handles the success of updating an offer by displaying a success toast message and closing the sidebar form.
 * — `deleteOffer$`: Deletes an offer. It performs an HTTP DELETE request and dispatches a success action if successful or a failure action if an error occurs.
 * — `deleteOfferSuccess$`: Handles the success of deleting an offer by displaying a success toast message and closing the sidebar form.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class OffersEffects {
  /**
   * Effect to create a new offer.
   * Listens for the `createOffer` action, checks permissions, and performs an HTTP POST request to create the offer.
   * Dispatches a success or failure action based on the result.
   */
  createOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersActions.createOffer),
      checkPermission(OffersActions.offersUnauthorized),
      mergeMap(({ offerData }) =>
        this.http
          .post<Offer>(`${environment.apiUrl}/v1/offers`, offerData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offer => OffersActions.createOfferSuccess({ offer })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OffersActions.createOfferFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new offer.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OffersActions.createOfferSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Offer created successfully');
          this.dialog.toggleDialog();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing offer.
   * Listens for the `updateOffer` action, checks permissions, and performs an HTTP PATCH request to update the offer.
   * Dispatches a success or failure action based on the result.
   */
  updateOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersActions.updateOffer),
      checkPermission(OffersActions.offersUnauthorized),
      mergeMap(({ uuid, offerData }) =>
        this.http
          .patch<Offer>(`${environment.apiUrl}/v1/offers/${uuid}`, offerData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offer => OffersActions.updateOfferSuccess({ offer })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OffersActions.updateOfferFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating an offer.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OffersActions.updateOfferSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Offer updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `OffersEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   * @param {DialogService} dialog Injectable service for handling dialog actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService,
    private dialog: DialogService
  ) {}
}
