import { createAction, props } from '@ngrx/store';
import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

export const loadAiConsultingJobMatchingRequest = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Request',
  props<{ formData: FormData }>()
);
export const loadAiConsultingJobMatchingRequestSuccess = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Request Success',
  props<{ request: WsRequest }>()
);
export const loadAiConsultingJobMatchingRequestFailure = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Request Failure',
  props<{ error: string }>()
);

export const updateAdvancement = createAction(
  '[Consulting AI Search] Update Advancement',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementSuccess = createAction(
  '[Consulting AI Search] Update Advancement Success',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementFailure = createAction(
  '[Consulting AI Search] Update Advancement Failure',
  props<{ error: string }>()
);

export const loadAiConsultingJobMatchingCandidates = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Candidates',
  props<{ url: string }>()
);
export const loadAiConsultingJobMatchingCandidatesSuccess = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Candidates Success',
  props<{ matching: MatchingObject }>()
);
export const loadAiConsultingJobMatchingCandidatesFailure = createAction(
  '[Consulting AI Search] Load Ai Consulting Job Matching Candidates Failure',
  props<{ error: string }>()
);

export const resetAiConsultingJobMatchingCandidates = createAction(
  '[Consulting AI Search] Reset Ai Consulting Job Matching Candidates'
);

export const consultingAISearchUnauthorized = createAction(
  '[Consulting AI Search] Unauthorized',
  props<{ error: string }>()
);
