import { Component, Input } from '@angular/core';

/**
 * Component to display a text with an associated tooltip. This component encapsulates the logic and styling
 * required to display hoverable tooltips that provide additional information for the text shown.
 *
 * @Component decorator defines Angular component metadata:
 * @selector 'ice-text-tooltip' - the component's CSS selector.
 * @templateUrl './ice-text-tooltip.component.html' - the path to the component's HTML template.
 * @styleUrls ['./ice-text-tooltip.component.scss'] - the path to the styles for this component.
 */
@Component({
  selector: 'ice-text-tooltip',
  templateUrl: './ice-text-tooltip.component.html',
  styleUrls: ['./ice-text-tooltip.component.scss'],
})
export class IceTextTooltipComponent {
  /**
   * The main text to be displayed by the component. This is typically the text over which the user will hover
   * to see the tooltip. It defaults to an empty string if not provided.
   *
   * @example
   * <ice-text-tooltip [text]="'Example Text'"></ice-text-tooltip>
   *
   * @type {string}
   */
  @Input() public text = '';

  /**
   * The tooltip text that provides additional information about the main text. This text is displayed
   * in a tooltip format when a user hovers over the main text. It defaults to an empty string if not provided.
   *
   * @example
   * <ice-text-tooltip [tip]="'This is a tooltip for the example text.'"></ice-text-tooltip>
   *
   * @type {string}
   */
  @Input() public tip = '';
}
