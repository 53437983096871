import { createReducer, on } from '@ngrx/store';
import { initialClientCompanySectorJobsState } from '~/app/states/main/client-company/profile/states/client-company-sector-jobs/client-company-sector-jobs.state';
import * as ClientCompanySectorJobsActions from '~/app/states/main/client-company/profile/states/client-company-sector-jobs/client-company-sector-jobs.actions';

export const clientCompanySectorJobsReducer = createReducer(
  initialClientCompanySectorJobsState,

  on(ClientCompanySectorJobsActions.loadClientCompanySectorJobs, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(
    ClientCompanySectorJobsActions.loadClientCompanySectorJobsSuccess,
    (state, { clientCompanySectorJobs }) => ({
      ...state,
      clientCompanySectorJobs,
      loading: false,
    })
  ),

  on(
    ClientCompanySectorJobsActions.loadClientCompanySectorJobsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanySectorJobsActions.assignClientCompanySectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanySectorJobsActions.assignClientCompanySectorJobSuccess,
    (state, { clientCompanySectorJobs }) => ({
      ...state,
      clientCompanySectorJobs,
      loading: false,
    })
  ),
  on(
    ClientCompanySectorJobsActions.assignClientCompanySectorJobFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanySectorJobsActions.unassignClientCompanySectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanySectorJobsActions.unassignClientCompanySectorJobSuccess,
    (state, { clientCompanySectorJobs }) => ({
      ...state,
      clientCompanySectorJobs,
      loading: false,
    })
  ),
  on(
    ClientCompanySectorJobsActions.unassignClientCompanySectorJobFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCompanySectorJobsActions.clientCompanySectorJobsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
