import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanySalesActions from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.actions';
import { ClientCompanySalesState } from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanySalesEffects` handles the side effects related to client company sales in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading client company sales from the server.
 *
 * Effects included in this class:
 * — `loadClientCompanySales$`: Fetches client company sales by company UUID from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanySalesEffects {
  /**
   * Effect to load client company sales by company UUID.
   */
  loadClientCompanySales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanySalesActions.loadClientCompanySales),
      checkPermission(ClientCompanySalesActions.clientCompanySalesUnauthorized),
      mergeMap(({ uuid, filters }) =>
        this.http
          .get<ClientCompanySalesState>(
            `${environment.apiUrl}/v1/clientCompanies/${uuid}/sales`,
            { params: { ...filters }, withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompanySales =>
              ClientCompanySalesActions.loadClientCompanySalesSuccess({
                clientCompanySales,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanySalesActions.loadClientCompanySalesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `ClientCompanySalesEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @memberof ClientCompanySalesEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
