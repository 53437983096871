import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthActions from '~/app/auth/auth.actions';
import {
  selectAuthState,
  selectIsSamlConfigured,
} from '~/app/auth/auth.selectors';
import { AuthState } from '~/app/auth/auth.state';
import { LoginService } from '~/app/auth/services/login.service';
import { LoginUser } from '~/app/auth/types/login.types';
import { AppState } from '~/app/core/state/app.state';
import { LandingCompanyService } from '~/app/auth/services/landing-company.service';
import {
  selectCurrentCompanyName,
  selectDataLoading,
} from '~/app/shared/states/data/data.selectors';
import { environment } from '~/environments/environment';

/**
 * LandingCompanyComponent handles the landing page for a specific company, providing a form for inputting email and password
 * for admin login, and uses the LoginService to authenticate admins based on these credentials.
 *
 * @Component
 * @selector 'app-landing-company' - CSS selector that identifies this component in a template.
 * @templateUrl './landing-company.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./landing-company.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-login-company',
  templateUrl: './login-company.component.html',
  styleUrl: './login-company.component.scss',
})
export class LoginCompanyComponent implements OnInit {
  /**
   * Observable that emits the current company name.
   * @type {Observable<{name: string} | null>}
   */
  companyName$ = new Observable<{ name: string } | null>();

  /**
   * The form group for the admin login form.
   * @type {FormGroup}
   */
  adminLoginForm!: FormGroup;

  /**
   * Observable that emits the loading state of the authentication process.
   * @type {Observable<boolean>}
   */
  isLoading$!: Observable<boolean>;

  /**
   * Observable that emits whether SAML is configured for the current company.
   * @type {Observable<boolean>}
   */
  isSamlConfigured$: Observable<boolean>;

  /**
   * Observable that holds the authentication state.
   * This observable is used to determine whether the user is logged in or not.
   *
   * @type {Observable<AuthState>}
   */
  isLoggedIn$: Observable<AuthState>;

  /**
   * Constructor that injects the LandingCompanyService, Store, FormBuilder, and LoginService into the LandingCompanyComponent.
   * @param {LandingCompanyService} landingCompanyService - The LandingCompanyService that provides functionality for the landing company page.
   * @param {Store<AppState>} store - The Store service that provides access to the application state.
   * @param {FormBuilder} formBuilder - The FormBuilder service that provides functionality for creating form groups and controls.
   * @param {LoginService} loginService - The LoginService that provides functionality for user authentication
   */
  constructor(
    private landingCompanyService: LandingCompanyService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    const companySubdomain = window.location.hostname.split('.')[0];
    this.landingCompanyService.getCompanyNameFromSubdomain(companySubdomain);
    this.companyName$ = this.store.select(selectCurrentCompanyName);
    this.isSamlConfigured$ = this.store.select(selectIsSamlConfigured);
    this.store.dispatch(AuthActions.isSamlConfigured({ companySubdomain }));
    this.isLoggedIn$ = this.store.select(selectAuthState);
  }

  /**
   * Getter for accessing the 'email' FormControl within the login form.
   * @type {FormControl} FormControl for the email input.
   */
  get email(): FormControl {
    return this.adminLoginForm.get('email') as FormControl;
  }

  /**
   * Getter for accessing the 'password' FormControl within the login form.
   * @type {FormControl} FormControl for the password input.
   */
  get password(): FormControl {
    return this.adminLoginForm.get('password') as FormControl;
  }

  /**
   * Initializes the component by subscribing to the company name observable and initializing the form.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.isLoading$ = this.store.select(selectDataLoading);
  }

  /**
   * Initializes the login form with default values and validators.
   * Sets up the form controls with required validators and specific constraints for each field.
   * @return {void}
   */
  onSubmit(): void {
    const loginData: LoginUser = {
      subdomain:
        window.location.hostname.split('.')[0] === 'localhost' ||
        window.location.hostname.split('.')[0] === 'ice-berry'
          ? this.adminLoginForm.value.email
          : window.location.hostname.split('.')[0],
      email: this.adminLoginForm.value.email,
      password: this.adminLoginForm.value.password,
    };

    this.loginService.login(loginData);
  }

  /**
   * Initialize the form group for the admin login form.
   *
   * @returns {void}
   */
  initForm(): void {
    this.adminLoginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
      ],
    });
  }

  /**
   * Handles the SAML login process by redirecting the user to the SAML login page.
   *
   * @return {void}
   */
  samlLogin(): void {
    this.loginService.samlLogin();
  }

  /**
   * Logs out the current user.
   *
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Redirects the user to the registration form.
   * @return {void}
   */
  redirectToLandingPage(): void {
    if (environment.production) window.open(`https://ice-berry.com`, '_self');
    else window.open(`https://localhost:4200`, '_self');
  }
}
