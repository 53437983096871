import { Pipe, PipeTransform } from '@angular/core';
import { KanbanTask } from '~/app/shared/interfaces/kanban/kanban-task/kanban-task.interface';

/**
 * A custom Angular pipe that checks if a given KanbanTask is marked as done. The task is considered done if its `doneAt`
 * property is not null. This pipe is useful for filtering tasks or displaying task status within Kanban board interfaces
 * or similar project management tools.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'isTaskDone', enabling its use within Angular templates
 * to dynamically check and display the completion status of tasks.
 */
@Pipe({
  name: 'isTaskDone',
})
export class IsTaskDonePipe implements PipeTransform {
  /**
   * Transforms a KanbanTask object by checking the status of the `doneAt` property.
   * Returns true if the task has a non-null `doneAt` date, indicating that the task has been completed.
   *
   * @param {KanbanTask} task - The KanbanTask object to check.
   * @returns {boolean} - True if the task is done (i.e., `doneAt` is not null), false otherwise.
   */
  transform(task: KanbanTask): boolean {
    return task.doneAt !== null;
  }
}
