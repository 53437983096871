import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/core/state/app.state';
import * as DataActions from '~/app/shared/states/data/data.actions';

/**
 * Service that provides functionality for the landing company page.
 *
 * @Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class LandingCompanyService {
  /**
   * Constructor that injects the Store service into the LandingCompanyService.
   * @param {Store<AppState>} store - The Store service that provides access to the application state.
   */
  constructor(private store: Store<AppState>) {}

  /**
   * Get the company name from the subdomain.
   * @param {string} subdomain - The hostname of the current URL.
   * @returns {void} - The company name from the subdomain.
   */
  getCompanyNameFromSubdomain(subdomain: string): void {
    this.store.dispatch(
      DataActions.loadCompanyNameFromSubdomain({ subdomain })
    );
  }
}
