import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileTagsActions from '~/app/states/main/crm/profile/states/profile-tags/profile-tags.actions';

import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileTagsEffects` manages the side effects for profile tag-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile tags, such as loading profile tags.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileTags$`: Loads all profile tags. It performs an HTTP GET request and dispatches a success action with the fetched tags or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileTagsEffects {
  /**
   * Effect to load all profile tags.
   * Listens for the `loadProfileTags` action, checks permissions, and performs an HTTP GET request to fetch the tags.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileTagsActions.loadProfileTags),
      checkPermission(ProfileTagsActions.profileTagsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Tag[]>(`${environment.apiUrl}/v1/profiles/tags`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(tags => ProfileTagsActions.loadProfileTagsSuccess({ tags })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileTagsActions.loadProfileTagsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `ProfileTagsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
