import { createAction, props } from '@ngrx/store';
import {
  CreateStatus,
  UpdateStatus,
} from '~/app/states/main/settings/types/status.types';
import { SequenceUuidInterface } from '~/app/shared/interfaces/generic/sequence-uuid.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';

export const loadCandidateStatuses = createAction(
  '[Candidate Status] Load Candidate Statuses'
);
export const loadCandidateStatusesSuccess = createAction(
  '[Candidate Status] Load Candidate Statuses Success',
  props<{ candidateStatuses: Status[] }>()
);
export const loadCandidateStatusesFailure = createAction(
  '[Candidate Status] Load Candidate Statuses Failure',
  props<{ error: string }>()
);

export const createCandidateStatus = createAction(
  '[Candidate Status] Create Candidate Status',
  props<{ candidateStatusData: CreateStatus }>()
);
export const createCandidateStatusSuccess = createAction(
  '[Candidate Status] Create Candidate Status Success',
  props<{ status: Status }>()
);
export const createCandidateStatusFailure = createAction(
  '[Candidate Status] Create Candidate Status Failure',
  props<{ error: string }>()
);

export const updateCandidateStatus = createAction(
  '[Candidate Status] Update Candidate Status',
  props<{ uuid: string; candidateStatusData: UpdateStatus }>()
);
export const updateCandidateStatusSuccess = createAction(
  '[Candidate Status] Update Candidate Status Success',
  props<{ status: Status }>()
);
export const updateCandidateStatusFailure = createAction(
  '[Candidate Status] Update Candidate Status Failure',
  props<{ error: string }>()
);

export const deleteCandidateStatus = createAction(
  '[Candidate Status] Delete Candidate Status',
  props<{ uuid: string }>()
);
export const deleteCandidateStatusSuccess = createAction(
  '[Candidate Status] Delete Candidate Status Success',
  props<{ uuid: string }>()
);
export const deleteCandidateStatusFailure = createAction(
  '[Candidate Status] Delete Candidate Status Failure',
  props<{ error: string }>()
);

export const updateProfileStatusSequence = createAction(
  '[Candidate Status] Update Profile Status Sequence',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateProfileStatusSequenceSuccess = createAction(
  '[Candidate Status] Update Profile Status Sequence Success',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateProfileStatusSequenceFailure = createAction(
  '[Candidate Status] Update Profile Status Sequence Failure',
  props<{ error: string }>()
);

export const candidateStatusUnauthorized = createAction(
  '[Candidate Status] Unauthorized',
  props<{ error: string }>()
);
