import { ProfileLanguage } from '~/app/shared/interfaces/crm/profile/profile-language.interface';

/**
 * Represents the state of profile languages.
 *
 * @interface ProfileLanguageState
 */
export interface ProfileLanguageState {
  /**
   * An array of profile languages.
   *
   * @type {ProfileLanguage[]}
   */
  profileLanguages: ProfileLanguage[];

  /**
   * Indicates if the profile languages are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile languages.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile languages.
 *
 * @type {ProfileLanguageState}
 */
export const initialProfileLanguagesState: ProfileLanguageState = {
  profileLanguages: [],
  loading: false,
  error: null,
};
