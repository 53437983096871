import { createAction, props } from '@ngrx/store';
import { AllTeamsState } from '~/app/main/shuffle-list/states/teams/teams-shuffle-list.states';
import { AllTeamsFilters } from '~/app/shared/interfaces/crm/team/all-teams-filters.interface';

export const loadAllTeamsShuffleList = createAction(
  '[Shuffle List] Load All Teams ShuffleList',
  props<{ filters?: AllTeamsFilters }>()
);
export const loadAllTeamsShuffleListSuccess = createAction(
  '[Shuffle List] Load All Teams ShuffleList Success',
  props<{ teams: AllTeamsState }>()
);
export const loadAllTeamsShuffleListFailure = createAction(
  '[Shuffle List] Load All Teams ShuffleList Failure',
  props<{ error: string }>()
);

export const teamsShuffleListUnauthorized = createAction(
  '[Shuffle List] Unauthorized ShuffleList',
  props<{ error: string }>()
);
