import { createReducer, on } from '@ngrx/store';
import * as SalesInformationActions from '~/app/states/main/sales/profile/states/sales-informations/sales-informations.actions';
import { initialSalesInformationState } from '~/app/states/main/sales/profile/states/sales-informations/sales-informations.state';

export const salesInformationReducer = createReducer(
  initialSalesInformationState,

  on(SalesInformationActions.loadSalesInformations, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesInformationActions.loadSalesInformationsSuccess,
    (state, { salesInformations }) => ({
      ...state,
      salesInformations,
      loading: false,
    })
  ),
  on(
    SalesInformationActions.loadSalesInformationsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesInformationActions.createSalesInformation, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesInformationActions.createSalesInformationSuccess,
    (state, { salesInformation }) => ({
      ...state,
      salesInformations: [...state.salesInformations, salesInformation],
      loading: false,
    })
  ),
  on(
    SalesInformationActions.createSalesInformationFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesInformationActions.updateSalesInformation, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesInformationActions.updateSalesInformationSuccess,
    (state, { salesInformation }) => ({
      ...state,
      salesInformations: state.salesInformations.map(info =>
        info.uuid === salesInformation.uuid ? salesInformation : info
      ),
      loading: false,
    })
  ),
  on(
    SalesInformationActions.updateSalesInformationFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesInformationActions.deleteSalesInformation, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesInformationActions.deleteSalesInformationSuccess,
    (state, { uuid }) => ({
      ...state,
      salesInformations: state.salesInformations.filter(
        info => info.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    SalesInformationActions.deleteSalesInformationFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    SalesInformationActions.salesInformationsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
