import { Status } from '~/app/shared/interfaces/shared/status.interface';

/**
 * Represents the state of candidate statuses.
 *
 * @interface CandidateStatusState
 */
export interface CandidateStatusState {
  /**
   * The list of candidate statuses.
   *
   * @type {Status[]}
   */
  candidateStatuses: Status[];

  /**
   * Indicates whether candidate statuses are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, related to loading candidate statuses.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for candidate statuses.
 *
 * @constant {CandidateStatusState} initialCandidateStatusState
 * @description Provides a baseline definition for the candidate statuses context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialCandidateStatusState: CandidateStatusState = {
  candidateStatuses: [],
  loading: false,
  error: null,
};
