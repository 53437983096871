import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SearchOfferProposalActions from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.actions';
import { AllSearchOfferProposalsState } from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `SearchOfferProposalEffects` manages the side effects for search offer proposal-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to search offer proposals,
 * such as loading all search offer proposals and loading search offer proposals by filter. It performs operations that involve
 * checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllSearchOfferProposals$`: Loads all search offer proposals. It performs an HTTP GET request and dispatches a success action with the fetched proposals or a failure action if an error occurs.
 * — `loadAllSearchOfferProposalsByFilter$`: Loads search offer proposals based on the provided filter. It performs an HTTP GET request and dispatches a success action with the fetched proposals or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SearchOfferProposalEffects {
  /**
   * Effect to load all search offer proposals.
   * Listens for the `loadAllSearchOfferProposals` action, checks permissions, and performs an HTTP GET request to fetch the proposals.
   * Dispatches a success or failure action based on the result.
   */
  loadAllSearchOfferProposals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchOfferProposalActions.loadAllSearchOfferProposals),
      checkPermission(
        SearchOfferProposalActions.searchOfferProposalsUnauthorized
      ),
      mergeMap(({ filters }) =>
        this.http
          .get<AllSearchOfferProposalsState>(
            `${environment.apiUrl}/v1/offers/search`,
            {
              params: { ...filters },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(searchOfferProposals =>
              SearchOfferProposalActions.loadAllSearchOfferProposalsSuccess({
                searchOfferProposals,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SearchOfferProposalActions.loadAllSearchOfferProposalsFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load all PUBLIC search offer proposals.
   * Listens for the `loadAllSearchOfferProposalsPublic` action, checks permissions, and performs an HTTP GET request to fetch the proposals.
   * Dispatches a success or failure action based on the result.
   */
  loadAllSearchOfferProposalsPublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchOfferProposalActions.loadAllSearchOfferProposalsPublic),

      mergeMap(({ filters }) =>
        this.http
          .get<AllSearchOfferProposalsState>(
            `${environment.apiUrl}/v1/offers/search/public`,
            {
              params: { ...filters },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(searchOfferProposals => {
              return SearchOfferProposalActions.loadAllSearchOfferProposalsPublicSuccess(
                {
                  searchOfferProposals,
                }
              );
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SearchOfferProposalActions.loadAllSearchOfferProposalsPublicFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `SearchOfferProposalEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
