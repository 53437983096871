import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanEvent } from '~/app/shared/interfaces/kanbanEvent.interface';
import * as KanbanCardsActions from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.actions';
import { IsseService } from '~/app/shared/services/IsseService';

/**
 * Service responsible for listening to real-time events related to Kanban cards.
 * It uses EventSourcePolyfill to establish a connection to the server and dispatches actions
 * to the store based on the events received.
 *
 * @Injectable decorator marks the class as available to be provided and injected as a dependency.
 */
@Injectable({
  providedIn: 'root',
})
export class IsseKanbanCardService {
  /**
   * Creates an instance of IsseKanbanCardService.
   *
   * @param {Store} store - The NgRx store used for dispatching actions.
   * @param {IsseService} isseService - Service to connect to the SSE Server.
   */
  constructor(
    private store: Store,
    private isseService: IsseService
  ) {}

  /**
   * Listens to the specified channel for real-time events.
   *
   * @returns {void}
   */
  listenToChannel(): void {
    const eventSourcePolyfill = this.isseService.eventSource;

    /**
     * Event listener for when a user connects.
     * Dispatches an action to update the store with the typing status of users on a Kanban card.
     *
     * @param {Event} event - The event object containing the user connection data.
     */
    eventSourcePolyfill.addEventListener('UserConnected', event => {
      const incomingEvent = event as unknown as KanbanEvent;
      const res = JSON.parse(incomingEvent.data).change;

      console.log(res);

      switch (res.topic) {
        case 'kanbanCard':
          this.store.dispatch(
            KanbanCardsActions.userStartTyping({
              kanbanCardId: res.channel,
              users: res.users,
            })
          );
          break;
        default:
      }
    });

    /**
     * Event listener for when a user disconnects.
     * Dispatches an action to update the store with the typing status of users on a Kanban card.
     *
     * @param {Event} event - The event object containing the user disconnection data.
     */
    eventSourcePolyfill.addEventListener('UserDisconnected', event => {
      const incomingEvent = event as unknown as KanbanEvent;
      const res = JSON.parse(incomingEvent.data).change;

      switch (res.topic) {
        case 'kanbanCard':
          this.store.dispatch(
            KanbanCardsActions.userStopTyping({
              kanbanCardId: res.channel,
              users: res.users,
            })
          );
          break;
        default:
      }
    });
  }
}
