import { createReducer, on } from '@ngrx/store';
import * as SalesSocialNetworksActions from '~/app/states/main/sales/profile/states/sales-socials/sales-socials.actions';
import { initialSalesSocialsState } from '~/app/states/main/sales/profile/states/sales-socials/sales-socials.state';

export const socialReducer = createReducer(
  initialSalesSocialsState,

  on(SalesSocialNetworksActions.loadSocialNetworks, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSocialNetworksActions.loadSocialNetworksSuccess,
    (state, { socials }) => ({
      ...state,
      socials,
      loading: false,
    })
  ),
  on(
    SalesSocialNetworksActions.loadSocialNetworksFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesSocialNetworksActions.createSocialNetwork, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSocialNetworksActions.createSocialNetworkSuccess,
    (state, { social }) => ({
      ...state,
      socials: [...state.socials, social],
      loading: false,
    })
  ),
  on(
    SalesSocialNetworksActions.createSocialNetworkFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesSocialNetworksActions.deleteSocialNetwork, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSocialNetworksActions.deleteSocialNetworkSuccess,
    (state, { uuid }) => ({
      ...state,
      socials: state.socials.filter(item => item.uuid !== uuid),
      loading: false,
    })
  ),
  on(
    SalesSocialNetworksActions.deleteSocialNetworkFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    SalesSocialNetworksActions.salesSocialNetworksUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
