import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as CompanyActions from '~/app/states/main/settings/states/company/company.actions';
import { Company } from '~/app/shared/interfaces/settings/company.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `CompanyEffects` is responsible for managing side effects related to company data in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading and updating company information from and to the server.
 *
 * Effects included in this class:
 * - `loadCompany$`: Fetches company data from the server.
 * - `updateCompany$`: Updates company data on the server.
 * - `updateCompanySuccess$`: Handles successful company updates.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class CompanyEffects {
  /**
   * Creates an observable effect to load company data. Listens for the `loadCompany` action,
   * checks permissions, and fetches data from the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompany),
      checkPermission(CompanyActions.companyUnauthorized),
      mergeMap(() =>
        this.http
          .get<Company>(`${environment.apiUrl}/v1/companies`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(company => CompanyActions.loadCompanySuccess({ company })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(CompanyActions.loadCompanyFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to update company data. Listens for the `updateCompany` action,
   * checks permissions, and updates data on the server. Upon success or failure,
   * it dispatches the corresponding success or failure actions.
   */
  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateCompany),
      checkPermission(CompanyActions.companyUnauthorized),
      mergeMap(({ companyData }) =>
        this.http
          .patch<Company>(`${environment.apiUrl}/v1/companies`, companyData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(company => CompanyActions.updateCompanySuccess({ company })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(CompanyActions.updateCompanyFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an observable effect to handle successful company updates.
   * Listens for the `updateCompanySuccess` action and displays a success toast.
   */
  updateCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyActions.updateCompanySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Company info updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for CompanyEffects.
   * @param {Actions} actions$ Injected actions observable that allows the effect to react to actions dispatched to the store.
   * @param {HttpClient} http Injected HttpClient for making API calls.
   * @param {HttpErrorsService} httpErrors Injected service for handling HTTP error responses.
   * @param {CustomToastService} toast Injected service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
