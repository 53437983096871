<ng-container>
  <div
    *ngIf="checked; else unchecked"
    class="w-1-5rem h-1-5rem border-round-md border-1 flex justify-content-center align-items-center"
    [ngClass]="{
      'cursor-pointer border-dark-100 bg-dark-100': !disabled,
      'cursor-stop border-dark-50 bg-dark-50': disabled,
    }"
    (click)="onClick.emit($event)">
    <img
      alt="checkbox checked"
      ngSrc="assets/icons/misc/checkbox-checked.svg"
      height="23"
      width="23" />
  </div>
  <ng-template #unchecked>
    <div
      class="w-1-5rem h-1-5rem border-round-md border-1"
      [ngClass]="{
        'cursor-pointer border-dark-100': !disabled,
        'cursor-stop border-dark-50': disabled,
      }"
      (click)="onClick.emit($event)"></div>
  </ng-template>
</ng-container>
