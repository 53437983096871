import { KanbanColumn } from '~/app/shared/interfaces/kanban/kanban-column.interface';

/**
 * Represents the state of Kanban columns.
 *
 * @interface KanbanColumnsState
 */
export interface KanbanColumnsState {
  /**
   * An array of Kanban columns.
   *
   * @type {KanbanColumn[]}
   */
  data: KanbanColumn[];

  /**
   * Indicates if the Kanban columns are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban columns.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The maximum number of Kanban boards per page.
   *
   * @type {number}
   */
  limit: number;
  /**
   * Indicates if there is a next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;
  /**
   * The total number of Kanban boards.
   *
   * @type {number}
   */
  count: number;
}

/**
 * The initial state of Kanban columns.
 *
 * @type {KanbanColumnsState}
 */
export const initialKanbanColumnsState: KanbanColumnsState = {
  data: [],
  loading: false,
  error: null,
  page: 0,
  limit: 7,
  hasNext: false,
  hasPrevious: false,
  count: 0,
};
