import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesIndustryActions from '~/app/states/main/sales/profile/states/sales-industries/sales-industries.actions';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `SalesIndustryEffects` manages the side effects for sales industry-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales industries, such as loading, assigning, and unassigning sales industries.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesIndustries$`: Loads the sales industries associated with a specific sales record. It performs an HTTP GET request and dispatches a success action with the loaded industries or a failure action if an error occurs.
 * — `assignSalesIndustry$`: Assigns an industry to a sales record. It performs an HTTP PATCH request and dispatches a success action with the updated industries or a failure action if an error occurs.
 * — `assignSalesIndustrySuccess$`: Handles the success of assigning an industry to a sales record by displaying a success toast message.
 * — `unassignSalesIndustry$`: Unassigns an industry from a sales record. It performs an HTTP PATCH request and dispatches a success action with the updated industries or a failure action if an error occurs.
 * — `unassignSalesIndustrySuccess$`: Handles the success of unassigning an industry from a sales record by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesIndustryEffects {
  /**
   * Effect to load sales industries associated with a specific sales record.
   * It listens for the `loadSalesIndustries` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded industries or a failure action if an error occurs.
   */
  loadSalesIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesIndustryActions.loadSalesIndustries),
      checkPermission(SalesIndustryActions.salesIndustriesUnauthorized),
      mergeMap(({ salesId }) =>
        this.http
          .get<
            Industry[]
          >(`${environment.apiUrl}/v1/sales/industries/${salesId}`, { withCredentials: true })
          .pipe(
            take(1),
            map(industries =>
              SalesIndustryActions.loadSalesIndustriesSuccess({
                industries,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesIndustryActions.loadSalesIndustriesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to assign an industry to a sales record.
   * It listens for the `assignSalesIndustry` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful assignment, it dispatches a success action with the updated industries or a failure action if an error occurs.
   */
  assignSalesIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesIndustryActions.assignSalesIndustry),
      checkPermission(SalesIndustryActions.salesIndustriesUnauthorized),
      mergeMap(({ salesId, industryId }) =>
        this.http
          .patch<
            Industry[]
          >(`${environment.apiUrl}/v1/sales/industries/${salesId}/assignIndustry`, { industryId }, { withCredentials: true })
          .pipe(
            take(1),
            map(industries =>
              SalesIndustryActions.assignSalesIndustrySuccess({ industries })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesIndustryActions.assignSalesIndustryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning an industry to a sales record.
   * It displays a success toast message.
   */
  assignSalesIndustrySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesIndustryActions.assignSalesIndustrySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales industry assigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign an industry from a sales record.
   * It listens for the `unassignSalesIndustry` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful unassignment, it dispatches a success action with the updated industries or a failure action if an error occurs.
   */
  unassignSalesIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesIndustryActions.unassignSalesIndustry),
      checkPermission(SalesIndustryActions.salesIndustriesUnauthorized),
      mergeMap(({ salesId, industryId }) =>
        this.http
          .patch<
            Industry[]
          >(`${environment.apiUrl}/v1/sales/industries/${salesId}/unassignedIndustry`, { industryId }, { withCredentials: true })
          .pipe(
            take(1),
            map(industries =>
              SalesIndustryActions.unassignSalesIndustrySuccess({ industries })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesIndustryActions.unassignSalesIndustryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning an industry from a sales record.
   * It displays a success toast message.
   */
  unassignSalesIndustrySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesIndustryActions.unassignSalesIndustrySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales industry unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesIndustryEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService
  ) {}
}
