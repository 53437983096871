import { createSelector } from '@ngrx/store';
import { selectClientCompanyState } from '~/app/main/client-company/client-company.selector';

export const selectAllClientCompaniesState = createSelector(
  selectClientCompanyState,
  state => state.allSearchClientCompanies
);

export const selectClientCompanies = createSelector(
  selectAllClientCompaniesState,
  state => state.data
);

export const selectLoadingState = createSelector(
  selectAllClientCompaniesState,
  state => state.loading
);
