import { Injectable } from '@angular/core';
import { ZodError } from 'zod';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { CustomError } from '~/app/shared/types/custom-error.type';

/**
 * Service for handling HTTP errors across the application. This service interprets errors
 * and displays appropriate notifications to the user via a toast service.
 *
 * @Injectable marks this service as available to be provided and injected as a singleton
 * within the root injector.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpErrorsService {
  /**
   * Constructs the HttpErrorsService with dependency injection.
   * @param {CustomToastService} toast - Service used for displaying toast notifications to the user.
   */
  constructor(private toast: CustomToastService) {}

  /**
   * Handles various types of HTTP errors and displays appropriate messages using the toast service.
   * The method differentiates between Zod validation errors, string messages, standard Error objects,
   * and other types of unknown errors, providing a specific toast for each.
   *
   * @param {CustomError} error - The error to handle, which can be one of several types:
   * - a string indicating a simple error message,
   * - a ZodError for detailed schema validation issues,
   * - an Error object for standard JavaScript errors,
   * - or any other object that might contain error details.
   * @return {void}
   */
  handleError(error: CustomError): void {
    if (error?.errorValidation instanceof ZodError) {
      this.toast.zodErrorToast(error.errorValidation);
    } else if (error?.error?.error) {
      this.toast.errorToast('Error', error.error.error);
    } else if (error?.statusText) {
      this.toast.errorToast('Error', error.statusText);
    } else {
      this.toast.errorToast('Unknown Error', 'An unexpected error occurred');
    }
  }
}
