import { createAction, props } from '@ngrx/store';
import { Notifications } from '@npmicedev/icemodule/lib/entities/Notifications';
import { ConnectionDetails } from '~/app/shared/interfaces/connectionDetails.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllNotifications = createAction(
  '[Notification] Load All Notifications'
);

export const loadAllNotificationsSuccess = createAction(
  '[Notification] Load All Notifications Success',
  props<{ notifications: Notifications[] }>()
);

export const loadAllNotificationsFailure = createAction(
  '[Notification] Load All Notifications Failure',
  props<{ error: string }>()
);

export const markNotificationAsRead = createAction(
  '[Notification] Mark Notification As Read',
  props<{ uuid: string }>()
);

export const markNotificationAsReadSuccess = createAction(
  '[Notification] Mark Notification As Read Success',
  props<{ uuid: string }>()
);

export const markNotificationAsReadFailure = createAction(
  '[Notification] Mark Notification As Read Failure',
  props<{ error: string }>()
);

export const markAllNotificationsAsRead = createAction(
  '[Notifications] Mark All As Read'
);

export const markAllNotificationsAsReadSuccess = createAction(
  '[Notifications] Mark All As Read Success'
);

export const markAllNotificationsAsReadFailure = createAction(
  '[Notifications] Mark All As Read Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createNotification = createAction(
  '[Notifications] Create Notification',
  props<{ notification: Notifications }>()
);

export const deleteNotification = createAction(
  '[Notification] Delete Notification',
  props<{ uuid: string }>()
);

export const deleteNotificationSuccess = createAction(
  '[Notification] Delete Notification Success',
  props<{ uuid: string }>()
);

export const deleteNotificationFailure = createAction(
  '[Notification] Delete Notification Failure',
  props<{ error: string }>()
);

export const deleteAllNotifications = createAction(
  '[Notifications] Delete All'
);

export const deleteAllNotificationsSuccess = createAction(
  '[Notifications] Delete All Success'
);

export const deleteAllNotificationsFailure = createAction(
  '[Notifications] Delete All Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * SSE SERVER CONNECTION
 * ************************************************************************
 **/

export const connectToChannel = createAction(
  '[Notifications] Connect To Channel',
  props<{ connectionDetails: ConnectionDetails[]; token: string }>()
);
export const connectToChannelSuccess = createAction(
  '[Notifications] Connect To Channel Success'
);
export const connectToChannelFailure = createAction(
  '[Notifications] Connect To Channel Failure',
  props<{ error: string }>()
);

export const disconnectFromChannel = createAction(
  '[Notifications] Disconnect From Channel',
  props<{ connectionDetails: ConnectionDetails[] }>()
);
export const disconnectFromChannelSuccess = createAction(
  '[Notifications] Disconnect From Channel Success'
);
export const disconnectFromChannelFailure = createAction(
  '[Notifications] Disconnect From Channel Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const notificationsUnauthorized = createAction(
  '[Notifications] Unauthorized',
  props<{ error: string }>()
);
