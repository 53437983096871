/**
 * Represents the state for quick navigation of profiles.
 *
 * @interface ProfileQuickNavState
 */
export interface ProfileQuickNavState {
  /**
   * An array of profile UUIDs for quick navigation.
   *
   * @type {string[]}
   */
  uuids: string[];
}

/**
 * The initial state for quick navigation of profiles.
 *
 * @type {ProfileQuickNavState}
 */
export const initialProfileQuickNavState: ProfileQuickNavState = {
  uuids: [],
};
