import { createAction, props } from '@ngrx/store';
import { AiProfileCreationRequest } from '~/app/shared/interfaces/crm/ai/ai-profile-creation-request.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

export const loadAiCreationProfileRequest = createAction(
  '[AI Creation Profile] Load AI Creation Profile Request',
  props<{ formData: FormData }>()
);

export const loadAiCreationProfileRequestSuccess = createAction(
  '[AI Creation Profile] Load AI Creation Profile Request Success',
  props<{ request: AiProfileCreationRequest }>()
);

export const loadAiCreationProfileRequestFailure = createAction(
  '[AI Creation Profile] Load AI Creation Profile Request Failure',
  props<{ error: string }>()
);

export const updateAdvancement = createAction(
  '[AI Creation Profile] Update Advancement',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementSuccess = createAction(
  '[AI Creation Profile] Update Advancement Success',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementFailure = createAction(
  '[AI Creation Profile] Update Advancement Failure',
  props<{ error: string }>()
);

export const loadStatuses = createAction('[AI Creation Profile] Load Statuses');
export const loadStatusesSuccess = createAction(
  '[AI Creation Profile] Load Statuses Success',
  props<{ statuses: Status[] }>()
);
export const loadStatusesFailure = createAction(
  '[AI Creation Profile] Load Statuses Failure',
  props<{ error: string }>()
);

export const loadOffices = createAction('[AI Creation Profile] Load Offices');
export const loadOfficesSuccess = createAction(
  '[AI Creation Profile] Load Offices Success',
  props<{ offices: Office[] }>()
);
export const loadOfficesFailure = createAction(
  '[AI Creation Profile] Load Offices Failure',
  props<{ error: string }>()
);

export const loadTeams = createAction('[AI Creation Profile] Load Teams');
export const loadTeamsSuccess = createAction(
  '[AI Creation Profile] Load Teams Success',
  props<{ teams: Team[] }>()
);
export const loadTeamsFailure = createAction(
  '[AI Creation Profile] Load Teams Failure',
  props<{ error: string }>()
);

export const aiCreateProfileUnauthorized = createAction(
  '[AI Creation Profile] Unauthorized',
  props<{ error: string }>()
);
