import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SectorCategoryActions from '~/app/states/main/states/sectors/sectors.actions';
import { SectorCategory } from '~/app/shared/interfaces/shared/sector/sector-category.interface';
import { SectorJob } from '~/app/shared/interfaces/shared/sector/sector-job.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SectorsEffects` manages the side effects related to sector categories in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting sector categories and jobs from the server.
 *
 * The effects in this class include:
 * — `loadSectorCategories$`: Fetches sector categories and all sector jobs from the server.
 * — `loadSectorCategoryById$`: Fetches a sector category by its ID from the server.
 * — `createSectorCategory$`: Creates a new sector category on the server.
 * — `createSectorCategorySuccess$`: Handles successful sector category creation.
 * — `updateSectorCategory$`: Updates an existing sector category on the server.
 * — `updateSectorCategorySuccess$`: Handles successful sector category updates.
 * — `deleteSectorCategory$`: Deletes a sector category from the server.
 * — `deleteSectorCategorySuccess$`: Handles successful sector category deletion.
 * — `assignSectorJob$`: Assigns a sector job to a category on the server.
 * — `assignSectorJobSuccess$`: Handles successful sector job assignment.
 * — `unassignSectorJob$`: Unassigns a sector job from a category on the server.
 * — `unassignSectorJobSuccess$`: Handles successful sector job unassignment.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */

@Injectable()
export class SectorsEffects {
  /**
   * Effect to load all sector categories and jobs. It listens for the `loadSectorCategories` action,
   * checks for permissions, and fetches sector categories and all sector jobs from the API.
   * Upon successful fetch, it dispatches a success action with the fetched data.
   */
  loadSectorCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.loadSectorCategories),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(() =>
        forkJoin({
          sectorCategories: this.http.get<SectorCategory[]>(
            `${environment.apiUrl}/v1/sectorJobs`
          ),
          allSectorJobs: this.http.get<SectorJob[]>(
            `${environment.apiUrl}/v1/sectorJobs/all`
          ),
        }).pipe(
          take(1),
          map(({ sectorCategories, allSectorJobs }) =>
            SectorCategoryActions.loadSectorCategoriesSuccess({
              sectorCategories,
              allSectorJobs,
            })
          ),
          catchError(error => {
            this.httpErrors.handleError(error);
            return of(
              SectorCategoryActions.loadSectorCategoriesFailure({ error })
            );
          })
        )
      )
    )
  );

  /**
   * Effect to load a sector category by its ID. It listens for the `loadSectorCategoryById` action,
   * checks for permissions, and fetches the sector category data from the API.
   * Upon successful fetch, it dispatches a success action with the fetched data.
   */
  loadSectorCategoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.loadSectorCategoryById),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<SectorCategory>(`${environment.apiUrl}/v1/sectorJobs/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sectorCategory =>
              SectorCategoryActions.loadSectorCategoryByIdSuccess({
                sectorCategory,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SectorCategoryActions.loadSectorCategoryByIdFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sector category. It listens for the `createSectorCategory` action,
   * checks for permissions, and posts the new sector category data to the API.
   * Upon successful creation, it dispatches a success action with the created data.
   */
  createSectorCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.createSectorCategory),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ sectorCategoryData }) =>
        this.http
          .post<SectorCategory>(
            `${environment.apiUrl}/v1/sectorJobs`,
            sectorCategoryData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(sectorCategory =>
              SectorCategoryActions.createSectorCategorySuccess({
                sectorCategory,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SectorCategoryActions.createSectorCategoryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful creation of a sector category. It displays a success toast
   * and closes the sidebar.
   */
  createSectorCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SectorCategoryActions.createSectorCategorySuccess),
        tap(() => {
          this.toast.successToast('Success', 'Category created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing sector category. It listens for the `updateSectorCategory` action,
   * checks for permissions, and patches the updated sector category data to the API.
   * Upon successful update, it dispatches a success action with the updated data.
   */
  updateSectorCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.updateSectorCategory),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ uuid, sectorCategoryData }) =>
        this.http
          .patch<SectorCategory>(
            `${environment.apiUrl}/v1/sectorJobs/${uuid}`,
            sectorCategoryData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(sectorCategory =>
              SectorCategoryActions.updateSectorCategorySuccess({
                sectorCategory,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SectorCategoryActions.updateSectorCategoryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful update of a sector category. It displays a success toast
   * and closes the sidebar.
   */
  updateSectorCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SectorCategoryActions.updateSectorCategorySuccess),
        tap(() => {
          this.toast.successToast('Success', 'Category updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sector category. It listens for the `deleteSectorCategory` action,
   * checks for permissions, and deletes the sector category by its ID from the API.
   * Upon successful deletion, it dispatches a success action.
   */
  deleteSectorCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.deleteSectorCategory),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/sectorJobs/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              SectorCategoryActions.deleteSectorCategorySuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SectorCategoryActions.deleteSectorCategoryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful deletion of a sector category. It displays a success toast
   * and closes the sidebar.
   */
  deleteSectorCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SectorCategoryActions.deleteSectorCategorySuccess),
        tap(() => {
          this.toast.successToast('Success', 'Category deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a sector job to a category. It listens for the `assignSectorJob` action,
   * checks for permissions, and patches the sector job assignment data to the API.
   * Upon successful assignment, it dispatches a success action.
   */
  assignSectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.assignSectorJob),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ sectorJobData }) =>
        this.http
          .patch<SectorCategory>(
            `${environment.apiUrl}/v1/sectorJobs/assign`,
            sectorJobData
          )
          .pipe(
            take(1),
            map(sectorCategory =>
              SectorCategoryActions.assignSectorJobSuccess({
                sectorCategory,
              })
            ),
            catchError(error =>
              of(SectorCategoryActions.assignSectorJobFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * Effect to handle successful assignment of a sector job to a category. It displays a success toast.
   */
  assignSectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SectorCategoryActions.assignSectorJobSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sector assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a sector job from a category. It listens for the `unassignSectorJob` action,
   * checks for permissions, and patches the sector job unassignment data to the API.
   * Upon successful unassignment, it dispatches a success action.
   */
  unassignSectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectorCategoryActions.unassignSectorJob),
      checkPermission(SectorCategoryActions.sectorJobUnauthorized),
      mergeMap(({ sectorJobData }) =>
        this.http
          .patch<SectorCategory>(
            `${environment.apiUrl}/v1/sectorJobs/unassigned`,
            sectorJobData
          )
          .pipe(
            take(1),
            map(sectorCategory =>
              SectorCategoryActions.unassignSectorJobSuccess({
                sectorCategory,
              })
            ),
            catchError(error =>
              of(SectorCategoryActions.unassignSectorJobFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * Effect to handle successful unassignment of a sector job from a category. It displays a success toast.
   */
  unassignSectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SectorCategoryActions.unassignSectorJobSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sector unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SectorsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying custom toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for managing the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
