import { User } from '~/app/shared/interfaces/auth/user.interface';

/**
 * Describes the state of users within the application, including pagination details, user data,
 * and status indicators for loading and errors.
 *
 * @interface UsersState
 */
export interface UsersState {
  /**
   * The total number of users.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page of users being displayed.
   *
   * @type {number}
   */
  page: number;

  /**
   * The maximum number of users per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates whether there are more users available in the next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates whether there are users available in the previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * An array of User objects representing the users.
   *
   * @type {User[]}
   */
  data: User[];

  /**
   * Indicates whether user data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, occurred during user data retrieval.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the users within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {UsersState}
 * @description Provides a baseline definition for the users context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialUsersState: UsersState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
};
