import { createAction, props } from '@ngrx/store';
import { ClientCompanySalesState } from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.state';
import { ClientCompanySalesFilters } from '~/app/shared/interfaces/client-company/profile/client-company-sales-filters.interface';

export const loadClientCompanySales = createAction(
  '[Client Company Sales] Load Client Company Sales',
  props<{ uuid: string; filters?: ClientCompanySalesFilters }>()
);
export const loadClientCompanySalesSuccess = createAction(
  '[Client Company Sales] Load Client Company Sales Success',
  props<{ clientCompanySales: ClientCompanySalesState }>()
);
export const loadClientCompanySalesFailure = createAction(
  '[Client Company Sales] Load Client Company Sales Failure',
  props<{ error: string }>()
);

export const clientCompanySalesUnauthorized = createAction(
  '[Client Company Sales] Unauthorized',
  props<{ error: string }>()
);
