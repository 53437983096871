import { createAction, props } from '@ngrx/store';
import { CreateSalesLanguages } from '~/app/states/main/sales/profile/types/sales-languages.types';
import { SalesLanguage } from '~/app/shared/interfaces/sales/profile/sales-languages.interface';

export const loadSalesLanguages = createAction(
  '[Sales Languages] Load Sales Languages',
  props<{ saleId: string }>()
);
export const loadSalesLanguagesSuccess = createAction(
  '[Sales Languages] Load Sales Languages Success',
  props<{ salesLanguages: SalesLanguage[] }>()
);
export const loadSalesLanguagesFailure = createAction(
  '[Sales Languages] Load Sales Languages Failure',
  props<{ error: string }>()
);

export const createSalesLanguage = createAction(
  '[Sales Languages] Create Sales Language',
  props<{ salesLanguage: CreateSalesLanguages }>()
);
export const createSalesLanguageSuccess = createAction(
  '[Sales Languages] Create Sales Language Success',
  props<{ salesLanguage: SalesLanguage }>()
);
export const createSalesLanguageFailure = createAction(
  '[Sales Languages] Create Sales Language Failure',
  props<{ error: string }>()
);

export const deleteSalesLanguage = createAction(
  '[Sales Languages] Delete Sales Language',
  props<{ uuid: string; saleId: string }>()
);
export const deleteSalesLanguageSuccess = createAction(
  '[Sales Languages] Delete Sales Language Success',
  props<{ uuid: string }>()
);
export const deleteSalesLanguageFailure = createAction(
  '[Sales Languages] Delete Sales Language Failure',
  props<{ error: string }>()
);

export const salesLanguagesUnauthorized = createAction(
  '[Sales Languages] Unauthorized',
  props<{ error: string }>()
);
