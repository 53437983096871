import { createReducer, on } from '@ngrx/store';
import * as RulesActions from '~/app/states/main/settings/states/rules/rules.actions';
import { initialRulesState } from '~/app/states/main/settings/states/rules/rules.state';

export const rulesReducer = createReducer(
  initialRulesState,

  on(RulesActions.loadRules, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RulesActions.loadRulesSuccess, (state, { allRules }) => ({
    ...state,
    allRules,
    loading: false,
  })),
  on(RulesActions.loadRulesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(RulesActions.loadRulesByGroup, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RulesActions.loadRulesByGroupSuccess, (state, { groupRules }) => ({
    ...state,
    currentGroupRules: groupRules,
    loading: false,
  })),
  on(RulesActions.loadRulesByGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
