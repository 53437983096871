import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileFileActions from '~/app/states/main/crm/profile/states/profile-files/profile-files.actions';
import { ProfileFile } from '~/app/shared/interfaces/crm/profile/profile-file.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileFilesEffects` manages the side effects for profile file-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile files, such as loading, creating, updating, and deleting profile files.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileFiles$`: Loads profile files based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched files or a failure action if an error occurs.
 * — `createProfileFile$`: Creates a new profile file. It performs an HTTP POST request and dispatches a success action with the created file or a failure action if an error occurs.
 * — `createProfileFileSuccess$`: Handles the success of creating a new profile file by displaying a success toast message and closing the sidebar form.
 * — `updateProfileFile$`: Updates an existing profile file based on its UUID and profile file data. It performs an HTTP PATCH request and dispatches a success action with the updated file or a failure action if an error occurs.
 * — `updateProfileFileSuccess$`: Handles the success of updating a profile file by displaying a success toast message and closing the sidebar form.
 * — `deleteProfileFile$`: Deletes a profile file based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileFileSuccess$`: Handles the success of deleting a profile file by displaying a success toast message and closing the sidebar form.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileFilesEffects {
  /**
   * Effect to load profile files based on a given profile ID.
   * Listens for the `loadProfileFiles` action, checks permissions, and performs an HTTP GET request to fetch the files.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileFileActions.loadProfileFiles),
      checkPermission(ProfileFileActions.profileFilesUnauthorized),
      mergeMap(({ profileId }) => {
        return this.http
          .get<ProfileFile[]>(`${environment.apiUrl}/v1/profiles/files`, {
            params: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(profileFiles =>
              ProfileFileActions.loadProfileFilesSuccess({
                profileFiles,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileFileActions.loadProfileFilesFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to create a new profile file.
   * Listens for the `createProfileFile` action, checks permissions, and performs an HTTP POST request to create the file.
   * Dispatches a success or failure action based on the result.
   */
  createProfileFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileFileActions.createProfileFile),
      checkPermission(ProfileFileActions.profileFilesUnauthorized),
      mergeMap(({ profileFileData }) =>
        this.http
          .post<ProfileFile>(
            `${environment.apiUrl}/v1/profiles/files`,
            profileFileData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileFile =>
              ProfileFileActions.createProfileFileSuccess({
                profileFile,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileFileActions.createProfileFileFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile file.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createProfileFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileFileActions.createProfileFileSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile file created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing profile file based on its UUID and profile file data.
   * Listens for the `updateProfileFile` action, checks permissions, and performs an HTTP PATCH request to update the file.
   * Dispatches a success or failure action based on the result.
   */
  updateProfileFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileFileActions.updateProfileFile),
      checkPermission(ProfileFileActions.profileFilesUnauthorized),
      mergeMap(({ uuid, profileFileData }) => {
        return this.http
          .patch<ProfileFile>(
            `${environment.apiUrl}/v1/profiles/files/${uuid}`,
            profileFileData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileFile =>
              ProfileFileActions.updateProfileFileSuccess({
                profileFile,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileFileActions.updateProfileFileFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle the success of updating a profile file.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateProfileFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileFileActions.updateProfileFileSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile file updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a profile file based on its UUID and profile ID.
   * Listens for the `deleteProfileFile` action, checks permissions, and performs an HTTP DELETE request to delete the file.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileFileActions.deleteProfileFile),
      checkPermission(ProfileFileActions.profileFilesUnauthorized),
      mergeMap(({ uuid, profileId }) => {
        return this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/files/${uuid}`, {
            body: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => ProfileFileActions.deleteProfileFileSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileFileActions.deleteProfileFileFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle the success of deleting a profile file.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  deleteProfileFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileFileActions.deleteProfileFileSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile file deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileFilesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
