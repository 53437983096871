<div class="relative w-full flex flex-column gap-2" *ngIf="iceIf">
  <label class="font-bold text-sm mx-0">
    {{ label }} <span *ngIf="required" class="text-red-600">*</span>
  </label>

  <p-fileUpload
    #fileUpload
    #elementRef
    [id]="id"
    [class]="class"
    [mode]="mode"
    [name]="name"
    [url]="url"
    [multiple]="multiple"
    [files]="files"
    [accept]="accept"
    [customUpload]="customUpload"
    [chooseLabel]="chooseLabel"
    [chooseIcon]="chooseIcon"
    [uploadLabel]="uploadLabel"
    [cancelLabel]="cancelLabel"
    [fileLimit]="fileLimit"
    [disabled]="disabled"
    (onSelect)="onSelectFile($event)"
    (uploadHandler)="uploadHandler.emit($event)"
    (onUpload)="onUpload.emit($event)"
    (onSend)="onSend.emit($event)"
    (onRemove)="onRemove.emit($event)"
    [maxFileSize]="maxFileSize">
    <ng-content></ng-content>
  </p-fileUpload>
</div>
