import { createReducer, on } from '@ngrx/store';
import { initialGroupsState } from '~/app/states/main/settings/states/access/access.state';
import * as GroupActions from '~/app/states/main/settings/states/access/access.actions';
import * as AccessActions from '~/app/states/main/settings/states/access/access.actions';

export const accessReducer = createReducer(
  initialGroupsState,

  // Loading all group rules
  on(GroupActions.loadGroupRules, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GroupActions.loadGroupRulesSuccess, (state, { groups }) => ({
    ...state,
    ...groups,
    loading: false,
  })),
  on(GroupActions.loadGroupRulesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Loading a group rule by ID
  on(GroupActions.loadGroupRuleById, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(GroupActions.loadGroupRuleByIdSuccess, (state, { rule }) => ({
    ...state,
    currentRules: rule.rules || null,
    currentUsers: rule.users || null,
    loading: false,
  })),

  on(GroupActions.loadGroupRuleByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Creating a group rule
  on(GroupActions.createGroupRule, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GroupActions.createGroupRuleSuccess, (state, { group }) => ({
    ...state,
    data: [group, ...state.data],
    loading: false,
  })),
  on(GroupActions.createGroupRuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Updating a group rule
  on(GroupActions.updateGroupRule, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GroupActions.updateGroupRuleSuccess, (state, { groupData }) => ({
    ...state,
    data: state.data.map(item =>
      item.uuid === groupData.uuid ? groupData : item
    ),
    loading: false,
  })),
  on(GroupActions.updateGroupRuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Deleting a group rule
  on(GroupActions.deleteGroupRule, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GroupActions.deleteGroupRuleSuccess, (state, { groupId }) => ({
    ...state,
    data: state.data.filter(item => item.uuid !== groupId),
    loading: false,
  })),
  on(GroupActions.deleteGroupRuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Assign rule in group
  on(AccessActions.assignRuleInGroup, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AccessActions.assignRuleInGroupSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(AccessActions.assignRuleInGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  // Unassign rule in group
  on(AccessActions.unassignRuleInGroup, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AccessActions.unassignRuleInGroupSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(AccessActions.unassignRuleInGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AccessActions.assignRuleInGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(GroupActions.groupRulesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
