import { ProfileInterview } from '~/app/shared/interfaces/crm/profile/profile-interview.interface';
import { ProfileWeeklyInterview } from '~/app/shared/interfaces/crm/home/interview/profile-weekly-interview.interface';

/**
 * Represents the state of profile interviews.
 *
 * @interface ProfileInterviewsState
 */
export interface ProfileInterviewsState {
  /**
   * An array of profile interviews.
   *
   * @type {ProfileInterview[]}
   */
  profileInterviews: ProfileInterview[];

  /**
   * The array of profile interviews for this week.
   *
   * @type {ProfileInterview[]}
   */
  weeklyProfileInterviews: ProfileWeeklyInterview;

  /**
   * Indicates if the profile interviews are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile interviews.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile interviews within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileInterviewsState} initialProfileInterviewsState
 * @description Provides a baseline definition for the profile interviews context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileInterviewsState: ProfileInterviewsState = {
  profileInterviews: [],
  weeklyProfileInterviews: {},
  loading: false,
  error: null,
};
