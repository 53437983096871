import { createReducer, on } from '@ngrx/store';
import { initialKanbanCardCommentssState } from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.state';
import * as KanbanCardCommentsActions from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.actions';

export const kanbanCardCommentsReducer = createReducer(
  initialKanbanCardCommentssState,

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/
  on(KanbanCardCommentsActions.loadAllKanbanCardComments, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardCommentsActions.loadAllKanbanCardCommentsSuccess,
    (state, { kanbanComments }) => ({
      ...state,
      comments: kanbanComments,
      loading: false,
    })
  ),
  on(
    KanbanCardCommentsActions.loadAllKanbanCardCommentsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/
  on(KanbanCardCommentsActions.createKanbanCardComment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardCommentsActions.createKanbanCardCommentSuccess,
    KanbanCardCommentsActions.createKanbanCardCommentServer,
    (state, { kanbanComment }) => ({
      ...state,
      comments: [...state.comments, kanbanComment],
      loading: false,
    })
  ),
  on(
    KanbanCardCommentsActions.createKanbanCardCommentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardCommentsActions.updateKanbanCardComment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardCommentsActions.updateKanbanCardCommentSuccess,
    KanbanCardCommentsActions.updateKanbanCardCommentServer,
    (state, { kanbanComment }) => ({
      ...state,
      comments: state.comments.map(comment =>
        comment.uuid === kanbanComment.uuid ? kanbanComment : comment
      ),
      loading: false,
    })
  ),
  on(
    KanbanCardCommentsActions.updateKanbanCardCommentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(KanbanCardCommentsActions.deleteKanbanCardComment, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbanCardCommentsActions.deleteKanbanCardCommentSuccess,
    KanbanCardCommentsActions.deleteKanbanCardCommentServer,
    (state, { uuid }) => ({
      ...state,
      comments: state.comments.filter(comment => comment.uuid !== uuid),
      loading: false,
    })
  ),
  on(
    KanbanCardCommentsActions.deleteKanbanCardCommentFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  /**
   * ************************************************************************
   * UNAUTHORIZED
   * ************************************************************************
   **/
  on(
    KanbanCardCommentsActions.kanbanCommentUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
