import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { take } from 'rxjs';
import { IceUnauthorizedPlaceholderComponent } from '~/app/shared/components/ui-elements/ice-unauthorized-placeholder/ice-unauthorized-placeholder.component';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';

/**
 * Directive to control the visibility and behavior of components based on permission rules.
 * It checks if the user has the required permissions to view a component and reacts accordingly
 * by either showing a warning, hiding the component, or displaying the component as normal.
 *
 * @Directive decorator defines the selector `[appComponentPermission]` to apply this behavior as an attribute directive.
 */
@Directive({
  selector: '[appComponentPermission]',
})
export class ComponentPermissionDirective implements AfterViewInit {
  /**
   * The permission rule identifier used to check against the permissions service.
   */
  @Input() appComponentPermission!: string;

  /**
   * Defines the behavior when the user does not have the required permission.
   * The default behavior is to display a warning message.
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.WARNING;

  /**
   * Constructs a new instance of ComponentPermissionDirective.
   * Injects the necessary services and elements required for its operation.
   *
   * @param {ElementRef} element - A wrapper around the native element this directive is attached to. It provides direct access to the DOM element.
   * @param {PermissionsService} permissionService - Service used to check if a user has the required permission for a particular rule.
   * @param {ViewContainerRef} viewContainerRef - The Angular View Container Reference that allows for dynamic component loading.
   */
  constructor(
    private element: ElementRef,
    private permissionService: PermissionsService,
    private viewContainerRef: ViewContainerRef
  ) {}

  /**
   * Lifecycle hook that checks permissions after the view initializes.
   * Depending on the result and the defined behavior, it may hide the element or show a warning component.
   * @return {void}
   */
  ngAfterViewInit(): void {
    this.permissionService
      .hasPermissionForRule(this.appComponentPermission)
      .pipe(take(1))
      .subscribe(hasPermission => {
        if (!hasPermission) {
          switch (this.unauthorizedBehaviour) {
            case PermissionBehaviourEnum.WARNING:
              this.showWarningComponent();
              break;
            case PermissionBehaviourEnum.NONE:
            default:
              this.hideElement();
          }
        }
      });
  }

  /**
   * Displays a placeholder component with a warning message when the user lacks the necessary permission.
   * @return {void}
   */
  private showWarningComponent(): void {
    const elementHeight = this.element.nativeElement.offsetHeight;

    this.viewContainerRef
      .createComponent(IceUnauthorizedPlaceholderComponent)
      .setInput('height', elementHeight);

    this.hideElement();
  }

  /**
   * Hides the element to which this directive is applied.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }
}
