import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';
import { SalesInformation } from '~/app/shared/interfaces/sales/profile/sales-information.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

/**
 * Represents a sale.
 *
 * @interface Sale
 */
export interface Sale {
  /**
   * The unique identifier of the sale.
   *
   * @type {string}
   */
  uuid: string;

  /**
   * The first name of the salesperson.
   *
   * @type {string}
   */
  firstname: string;

  /**
   * The last name of the salesperson.
   *
   * @type {string}
   */
  lastname: string;

  /**
   * The title of the salesperson.
   *
   * @type {string}
   */
  title: string;

  /**
   * The street address of the salesperson.
   *
   * @type {string}
   */
  streetAddress: string;

  /**
   * The city where the salesperson is located.
   *
   * @type {string}
   */
  city: string;

  /**
   * The postal code of the salesperson's location.
   *
   * @type {string}
   */
  postalCode: string;

  /**
   * The country where the salesperson is located.
   *
   * @type {string}
   */
  country: string;

  /**
   * The URL to the salesperson's photo.
   *
   * @type {string}
   */
  photoUrl: string;

  /**
   * The creation date of the sale record.
   *
   * @type {string}
   */
  createdAt: string;

  /**
   * The last update date of the sale record.
   *
   * @type {string}
   */
  updatedAt: string;

  /**
   * The deletion date of the sale record, if applicable.
   *
   * @type {null}
   */
  deletedAt: null;

  /**
   * The client company associated with the sale.
   *
   * @type {ClientCompany}
   */
  clientCompany: ClientCompany;

  /**
   * The user who created the sale.
   *
   * @type {SalesInformation[]}
   */
  saleInformations: SalesInformation[];

  /**
   * The status of the sale.
   *
   * @type {Status}
   */
  status: Status;
}

/**
 * Represents the state for searching sales.
 *
 * @interface SearchSalesState
 */
export interface SearchSalesState {
  /**
   * The list of sales.
   *
   * @type {Sale[]}
   */
  sales: Sale[];

  /**
   * Indicates if the sales are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Represents the state for all sales.
 *
 * @interface AllSalesState
 */
export interface AllSalesState {
  /**
   * The total count of sales.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The number of sales per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there is a next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * The list of sales data.
   *
   * @type {Sale[]}
   */
  data: Sale[];

  /**
   * The list of sales tags.
   *
   * @type {Tag[]}
   */
  salesTags: Tag[];

  /**
   * Indicates if the sales are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for all sales.
 *
 * @type {AllSalesState}
 */
export const initialAllSalesState: AllSalesState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  salesTags: [],
  loading: false,
  error: null,
};

/**
 * Represents the filters for loading sales.
 *
 * @interface LoadSalesByFilters
 */
export interface LoadSalesByFilters {
  /**
   * The name of the salesperson.
   *
   * @type {string}
   */
  name?: string;

  /**
   * The gender of the salesperson.
   *
   * @type {'male' | 'female' | 'other'}
   */
  gender?: 'male' | 'female' | 'other';

  /**
   * The title of the salesperson.
   *
   * @type {string | null}
   */
  title?: string | null;

  /**
   * The street address of the salesperson.
   *
   * @type {string | null}
   */
  streetAddress?: string | null;

  /**
   * The city where the salesperson is located.
   *
   * @type {string | null}
   */
  city?: string | null;

  /**
   * The postal code of the salesperson's location.
   *
   * @type {string | null}
   */
  postalCode?: string | null;

  /**
   * The country where the salesperson is located.
   *
   * @type {string | null}
   */
  country?: string | null;

  /**
   * The email address of the salesperson.
   *
   * @type {string}
   */
  email?: string;

  /**
   * The client companies associated with the sale.
   *
   * @type {string | string[]}
   */
  clientCompanies?: string | string[];

  /**
   * The languages spoken by the salesperson.
   *
   * @type {string | string[]}
   */
  languages?: string | string[];

  /**
   * The condition for matching languages.
   *
   * @type {'or' | 'and'}
   */
  languageCondition?: 'or' | 'and';

  /**
   * The sector jobs associated with the sale.
   *
   * @type {string | string[]}
   */
  sectorJobs?: string | string[];

  /**
   * The condition for matching sector jobs.
   *
   * @type {'or' | 'and'}
   */
  sectorJobsCondition?: 'or' | 'and';

  /**
   * The industries associated with the sale.
   *
   * @type {string | string[]}
   */
  industries?: string | string[];

  /**
   * The condition for matching industries.
   *
   * @type {'or' | 'and'}
   */
  industryCondition?: 'or' | 'and';

  /**
   * The statuses associated with the sale.
   *
   * @type {string | string[]}
   */
  statuses?: string | string[];

  /**
   * The tags associated with the sale.
   *
   * @type {string | string[]}
   */
  tags?: string | string[];

  /**
   * The condition for matching tags.
   *
   * @type {'or' | 'and'}
   */
  tagCondition?: 'or' | 'and';

  /**
   * The user who created the sale record.
   *
   * @type {string | string[]}
   */
  createdBy?: string | string[];

  /**
   * The limit for the number of sales per page.
   *
   * @type {string}
   */
  limit?: number;

  /**
   * The current page number.
   *
   * @type {string}
   */
  page?: string;
}
