import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectSalesUUIDs } from '~/app/main/sales/states/sales-quick-nav/sales-quick-nav.selectors';

/**
 * A custom Angular pipe that determines if navigation to the next or previous sale in a quick navigation list
 * should be disabled based on the current sale's UUID.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'isSalesQuickNavDisable', enabling its use within Angular templates
 * to dynamically check if sales navigation should be disabled.
 */
@Pipe({
  name: 'isSalesQuickNavDisable',
})
export class IsSalesQuickNavDisablePipe implements PipeTransform {
  /**
   * Observable emitting an array of sales UUIDs.
   * @type {Observable<string[]>}
   */
  uuids$: Observable<string[]>;

  /**
   * Constructs an instance of the IsSalesQuickNavDisablePipe.
   *
   * @param {Store} store - Injectable store for managing application state.
   */
  constructor(private store: Store) {
    this.uuids$ = this.store.select(selectSalesUUIDs);
  }

  /**
   * Transforms the current sale UUID and navigation direction into a boolean indicating if navigation should be disabled.
   *
   * @param {string} currentUUID - The UUID of the current sale.
   * @param {'next' | 'previous'} direction - The direction of navigation, either 'next' or 'previous'.
   * @returns {Observable<boolean>} - An observable emitting a boolean indicating if the navigation should be disabled.
   */
  transform(
    currentUUID: string,
    direction: 'next' | 'previous'
  ): Observable<boolean> {
    return this.uuids$.pipe(
      map(uuids => {
        const index = uuids.findIndex(uuid => uuid === currentUUID);
        if (index === -1) return true;
        switch (direction) {
          case 'next':
            return !(index + 1 < uuids.length);
          case 'previous':
            return !(index - 1 >= 0);
          default:
            return true;
        }
      })
    );
  }
}
