import { createReducer, on } from '@ngrx/store';
import { initialProfileFileState } from '~/app/states/main/jobhub/states/profile-file/profile-file.state';
import * as ProfileFileActions from '~/app/states/main/jobhub/states/profile-file/profile-file.actions';

export const profileFileReducer = createReducer(
  initialProfileFileState,

  on(ProfileFileActions.loadProfileFiles, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileFileActions.loadProfileFilesSuccess, (state, { files }) => ({
    ...state,
    files,
    loading: false,
  })),
  on(ProfileFileActions.loadProfileFilesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileFileActions.offerUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
