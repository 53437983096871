import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';

/**
 * Interface representing the state of timelines in the application.
 */
export interface TimelinesState {
  /**
   * The total number of timelines.
   * @type {number}
   * @memberof TimelinesState
   */
  count: number;

  /**
   * The current page number.
   * @type {number}
   * @memberof TimelinesState
   */
  page: number;

  /**
   * The number of timelines to display per page.
   * @type {number}
   * @memberof TimelinesState
   */
  limit: number;

  /**
   * Indicates if there is a next page available.
   * @type {boolean}
   * @memberof TimelinesState
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page available.
   * @type {boolean}
   * @memberof TimelinesState
   */
  hasPrevious: boolean;

  /**
   * An array of timelines.
   * @type {Timeline[]}
   * @memberof TimelinesState
   */
  data: Timeline[];

  /**
   * Currently selected timeline.
   * @type {Timeline | null}
   * @memberof TimelinesState
   */
  selectedTimeline: Timeline | null;

  /**
   * Indicates if the timelines are currently loading.
   * @type {boolean}
   * @memberof TimelinesState
   */
  loading: boolean;

  /**
   * Error message, if any.
   * @type {string | null}
   * @memberof TimelinesState
   */
  error: string | null;
}

/**
 * Initial state for timelines.
 * @type {TimelinesState}
 */
export const initialTimelinesState: TimelinesState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  selectedTimeline: null,
  loading: false,
  error: null,
};
