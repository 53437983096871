import { createSelector } from '@ngrx/store';
import { selectKanbanState } from '~/app/states/main/kanban/kanban.selectors';
import { randomColor } from '~/app/shared/functions/random-color';

export const selectKanbansState = createSelector(
  selectKanbanState,
  state => state.allKanbans
);

export const selectKanbans = createSelector(
  selectKanbansState,
  state => state.data
);

export const selectKanban = createSelector(
  selectKanbansState,
  state => state.kanban
);

export const selectKanbanLoading = createSelector(
  selectKanbansState,
  state => state.loading
);

export const selectKanbanUsers = createSelector(selectKanban, state =>
  (state?.kanbanUsers ?? []).map(({ kanbanId, userId }) => ({
    kanbanId,
    userId: { ...userId, color: randomColor() },
  }))
);

export const selectGeneratedToken = createSelector(
  selectKanbansState,
  state => state.token
);
export const selectKanbanConnected = createSelector(
  selectKanbansState,
  state => state.connected
);
