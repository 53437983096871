import { createReducer, on } from '@ngrx/store';
import { initialProfileQuickNavState } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.state';
import * as ProfileQuickNavActions from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.actions';

export const profileQuickNavReducer = createReducer(
  initialProfileQuickNavState,

  on(ProfileQuickNavActions.loadProfilesUUIDs, (state, { uuids }) => ({
    ...state,
    uuids,
  }))
);
