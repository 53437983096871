import { KanbanTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-tag.interface';

/**
 * Represents the state of Kanban tags.
 *
 * @interface KanbanTagsState
 */
export interface KanbanTagsState {
  /**
   * An array of Kanban tags.
   *
   * @type {KanbanTag[]}
   */
  kanbanTags: KanbanTag[];

  /**
   * Indicates if the Kanban tags are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban tags.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of Kanban tags.
 *
 * @type {KanbanTagsState}
 */
export const initialKanbanTagsState: KanbanTagsState = {
  kanbanTags: [],
  loading: false,
  error: null,
};
