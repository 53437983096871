import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, skipWhile, tap } from 'rxjs';
import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { map } from 'rxjs/operators';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { GoalScope } from '@npmicedev/icemodule/lib/enums/goalScope';
import IceRule from '@npmicedev/icemodule/lib/utils/rules';
import { Target } from '@npmicedev/icemodule/lib/enums/target';
import { GoalType } from '@npmicedev/icemodule/lib/enums/goalType';
import { GoalsStates } from '~/app/main/stats/states/goals/goals.states';
import { GoalsService } from '~/app/main/stats/services/goals.service';
import {
  selectGoalTeams,
  selectGoalUsers,
  selectIsLoading,
  selectSelectedGoal,
} from '~/app/main/stats/states/goals/goals.selectors';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import {
  selectGoalTypes,
  selectRates,
  selectTargets,
} from '~/app/core/state/enum/enums.selectors';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { AuthCompany } from '~/app/shared/interfaces/auth/auth-company.interface';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';

/**
 * CudGoalComponent manages the creation, updating, and deletion of goals.
 * It provides a form for users to input goal details and leverages an NgRx store for state management.
 *
 * @Component
 * @selector 'app-cud-goal' — CSS selector that identifies this component in a template.
 * @templateUrl './cud-goal.component.html' — Path to the HTML template for this component.
 * @styleUrl './cud-goal.component.scss' — Path to the stylesheet for this component.
 */
@Component({
  selector: 'app-cud-goal',
  templateUrl: './cud-goal.component.html',
  styleUrls: ['./cud-goal.component.scss'],
})
export class CudGoalComponent implements OnInit {
  /**
   * Universal Unique Identifier for the goal.
   * @type {string | undefined}
   */
  @Input() goalUUID: string | undefined;

  /**
   * Observable that emits the selected goal details.
   * @type {Observable<Goals | null>}
   */
  goal$: Observable<Goals | null>;

  /**
   * Observable that emits a boolean indicating whether data is currently being loaded.
   * @type {Observable<boolean>}
   */
  isLoading$: Observable<boolean>;

  /**
   * Observable that emits the available goal types.
   * @type {Observable<LabelValueInterface[]>}
   */
  goalsTypes$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available targets.
   * @type {Observable<LabelValueInterface[]>}
   */
  targets$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available rates.
   * @type {Observable<LabelValueInterface[]>}
   */
  rates$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available teams for goal assignment.
   * @type {Observable<LabelValueInterface[]>}
   */
  teams$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available users for goal assignment.
   * @type {Observable<LabelValueInterface[]>}
   */
  users$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the company information of the current user.
   * @type {Observable<AuthCompany | undefined>}
   */
  company$: Observable<AuthCompany | undefined>;

  /**
   * FormGroup that encapsulates the form controls for goal creation and management.
   * @type {FormGroup}
   */
  goalForm!: FormGroup;

  /**
   * Array of scopes available for goal setting.
   * @type {{ label: string, value: GoalScope }[]}
   */
  scopes = [
    { label: 'Company', value: GoalScope.Company },
    { label: 'Team', value: GoalScope.Team },
    { label: 'User', value: GoalScope.User },
  ];

  /**
   * Array to hold filtered scope options.
   * @type {any[]}
   */
  filteredScope: any[] = [];

  /**
   * Reference to the GoalScope enum.
   * @type {typeof GoalScope}
   */
  protected readonly goalScope = GoalScope;

  /**
   * Reference to the IceAutocompleteTemplateEnum enum.
   * @type {typeof IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Reference to the IceRule utility.
   * @type {typeof IceRule}
   */
  protected readonly iceRule = IceRule;

  /**
   * Reference to the GoalScope Enum
   * @type {typeof GoalScope}
   *  */
  protected readonly goalScopeEnum = GoalScope;

  /**
   * Constructor for CudGoalComponent.
   *
   * Initializes necessary services for state management and goal handling.
   *
   * @param {Store<GoalsStates>} store — Provides access to the goals state in the application.
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {GoalsService} goalService — Service to manage goal-related actions.
   */
  constructor(
    private store: Store<GoalsStates>,
    private formBuilder: FormBuilder,
    private goalService: GoalsService
  ) {
    this.goal$ = this.store.select(selectSelectedGoal);
    this.targets$ = this.store.select(selectTargets);
    this.rates$ = this.store.select(selectRates);
    this.isLoading$ = this.store.select(selectIsLoading);

    this.searchGoalTypes();

    this.users$ = this.store.select(selectGoalUsers).pipe(
      skipWhile(items => !items),
      map(users => {
        return users.map(user => {
          return {
            ...user,
            label: user.firstname + ' ' + user.lastname,
            value: user.uuid,
          };
        });
      })
    );

    this.teams$ = this.store.select(selectGoalTeams).pipe(
      skipWhile(items => !items),
      map(teams => teams.map(team => ({ label: team.name, value: team.uuid })))
    );

    this.company$ = this.store
      .select(selectCurrentUser)
      .pipe(map(user => user?.company));
  }

  /**
   * Getter for the 'target' FormControl.
   * @type {FormControl}
   */
  get target(): FormControl {
    return this.goalForm.get('target') as FormControl;
  }

  /**
   * Getter for the 'goalType' FormControl.
   * @type {FormControl}
   */
  get goalType(): FormControl {
    return this.goalForm.get('goalType') as FormControl;
  }

  /**
   * Getter for the 'goal' FormControl.
   * @type {FormControl}
   */
  get goal(): FormControl {
    return this.goalForm.get('goal') as FormControl;
  }

  /**
   * Getter for the 'rate' FormControl.
   * @type {FormControl}
   */
  get rate(): FormControl {
    return this.goalForm.get('rate') as FormControl;
  }

  /**
   * Getter for the 'scope' FormControl.
   * @type {FormControl}
   */
  get scope(): FormControl {
    return this.goalForm.get('scope') as FormControl;
  }

  /**
   * Getter for the 'teamId' FormControl.
   * @type {FormControl}
   */
  get teamId(): FormControl {
    return this.goalForm.get('teamId') as FormControl;
  }

  /**
   * Getter for the 'userId' FormControl.
   * @type {FormControl}
   */
  get userId(): FormControl {
    return this.goalForm.get('userId') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the component.
   * @return {void}
   */
  ngOnInit(): void {
    this.goalService.loadGoalUsers();
    this.goalService.loadGoalTeams();

    if (this.goalUUID) {
      this.goalService.loadGoalById(this.goalUUID);
    }

    this.initForm();
  }

  /**
   * Initializes the goal form with default values and sets up observables to patch values from the selected goal.
   * @return {void}
   */
  initForm(): void {
    this.goalForm = this.formBuilder.group({
      target: [null, Validators.required],
      goalType: [null, Validators.required],
      goal: [null, Validators.required],
      rate: [null, Validators.required],
      scope: [null, Validators.required],
      teamId: [null],
      userId: [null],
      hasNotification: [false],
    });

    this.goal$
      .pipe(
        tap(goal => {
          if (goal && this.goalUUID) {
            this.goalForm.patchValue({
              target: goal.target,
              goalType: goal.goalType,
              goal: goal.goal,
              rate: goal.rate,
              scope: goal.scope,
              hasNotification: [false],
            });

            if (goal.scope === GoalScope.Team && goal.team?.uuid) {
              this.goalForm.patchValue({
                teamId: goal.team.name,
              });
            }

            if (goal.scope === GoalScope.User && goal.user?.uuid) {
              this.goalForm.patchValue({
                userId: goal.user.firstname + ' ' + goal.user.lastname,
              });
            }
          }
        })
      )
      .subscribe();

    this.target.valueChanges.subscribe(() => {
      this.searchGoalTypes();
      this.goalForm.patchValue({ goalType: [null] });
    });
  }

  /**
   * Update the list of available goals types
   *
   * @return {void}
   */
  searchGoalTypes(): void {
    this.goalsTypes$ = this.store.select(selectGoalTypes).pipe(
      map(types => {
        if (this.target.value) {
          return types.filter(type =>
            this.target.value === Target.Profile
              ? [
                  GoalType.PROFILE_CREATED,
                  GoalType.DOCUMENT_UPLOADED,
                  GoalType.CHANGE_STATUS,
                  GoalType.COMMENT_CREATED,
                ].includes(type.value as GoalType)
              : [
                  GoalType.CHANGE_STATUS,
                  GoalType.COMMENT_CREATED,
                  GoalType.SALE_CREATED,
                ].includes(type.value as GoalType)
          );
        } else {
          return [];
        }
      })
    );
  }

  /**
   * Handles the search event when using the autocomplete feature for users.
   * @param {AutoCompleteCompleteEvent} event — The autocomplete event containing the query to search for.
   * @return {void}
   */
  onSearch(event: AutoCompleteCompleteEvent): void {
    this.goalService.loadGoalUsers(event.query);
  }

  /**
   * Deletes the specified goal by its UUID.
   * @return {void}
   */
  deleteGoal(): void {
    if (this.goalUUID) this.goalService.deleteGoalById(this.goalUUID);
  }

  /**
   * Submits the goal form to update an existing goal.
   * @return {void}
   */
  onSubmit(): void {
    if (this.goalUUID) {
      this.goalService.updateGoalById(this.goalForm, this.goalUUID);
    } else {
      this.goalService.createGoal(this.goalForm);
    }
  }
}
