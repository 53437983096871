import { SearchOffer } from '~/app/shared/interfaces/jobhub/offer/search-offer.interface';

/**
 * Represents the state for all search offer proposals.
 *
 * @interface AllSearchOfferProposalsState
 */
export interface AllSearchOfferProposalsState {
  /**
   * The total number of offers.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The maximum number of offers per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there is a next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * The array of search offers.
   *
   * @type {SearchOffer[]}
   */
  data: SearchOffer[];

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for all search offer proposals.
 *
 * @type {AllSearchOfferProposalsState}
 */
export const initialAllSearchOfferProposalsState: AllSearchOfferProposalsState =
  {
    count: 0,
    page: 0,
    limit: 10,
    hasNext: false,
    hasPrevious: false,
    data: [],
    loading: false,
    error: null,
  };
