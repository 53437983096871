import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, take } from 'rxjs';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import * as ClientCompanyActions from '~/app/states/main/crm/profile/states/client-companies/profile-client-companies.actions';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { environment } from '~/environments/environment';
import { ProfileClientCompaniesState } from '~/app/states/main/crm/profile/states/client-companies/profile-client-companies.states';

/**
 * `ProfileClientCompaniesEffects` is responsible for managing side effects related to client companies in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading client companies from the server.
 *
 * Effects included in this class:
 * — `loadAllClientCompanies$`: Fetches all client companies based on provided filters.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileClientCompagniesEffects {
  /**
   * Effect to load all client companies from the server.
   * @returns An observable of the action to dispatch on success or failure.
   */
  loadAllClientCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyActions.loadAllClientCompanies),
      checkPermission(ClientCompanyActions.clientCompanyUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<ProfileClientCompaniesState>(
            `${environment.apiUrl}/v1/clientCompanies`,
            { params: { ...filters }, withCredentials: true }
          )
          .pipe(
            take(1),
            map(allClientCompanies =>
              ClientCompanyActions.loadAllClientCompaniesSuccess({
                allClientCompanies,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyActions.loadAllClientCompaniesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Creates an instance of ProfileClientCompagniesEffects.
   *
   * @param {Actions} actions$ - The injected NgRx Actions service.
   * @param {Router} router - The injected Router service for navigation.
   * @param {HttpClient} http - The injected HttpClient service for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - The injected service for handling HTTP errors.
   * @param {CustomToastService} toast - The injected service for displaying custom toast messages.
   * @param {SidenavFormService} sidenavFormService - The injected service for managing sidenav forms.
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidenavFormService: SidenavFormService
  ) {}
}
