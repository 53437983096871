import { Component, Input, OnInit } from '@angular/core';

/**
 * `IceAvatarComponent` is an Angular component that displays an avatar image or, if the image URL is not provided,
 * generates a placeholder with the initials from a given content or a default text.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-avatar' - The CSS selector that identifies this component in a template.
 * @templateUrl './ice-avatar.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-avatar.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'ice-avatar',
  templateUrl: './ice-avatar.component.html',
  styleUrls: ['./ice-avatar.component.scss'],
})
export class IceAvatarComponent<
  T extends { firstname: string; lastname: string },
> implements OnInit
{
  /**
   * The URL of the avatar image.
   * If provided, the image will be displayed.
   * @type {string | undefined}
   */
  @Input() avatarUrl: string | undefined;

  /**
   * The content to generate the avatar text if the avatar URL is not provided.
   * It can be an object with `firstname` and `lastname` properties, or a string.
   * @type {T | string | undefined}
   */
  @Input() avatarContent: T | string | undefined;

  /**
   * The size of the avatar, which controls its dimensions.
   * Defaults to 'large'.
   * @type {'small' | 'large' | 'xlarge'}
   */
  @Input() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium';

  /**
   * The text to display in the avatar if the avatar URL is not provided.
   * This is generated based on `avatarContent` or set to '??' if no content is provided.
   * @type {string | undefined}
   */
  avatarText: string | undefined;

  /**
   * Returns the CSS class to apply for the size of the avatar.
   * @returns {string} The CSS class for the avatar size.
   */
  get avatarSizeClass(): string {
    return `avatar-${this.size}`;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes `avatarText` based on `avatarContent` if `avatarUrl` is not provided.
   * @return {void}
   */
  ngOnInit(): void {
    if (!this.avatarUrl) {
      if (this.avatarContent) {
        this.avatarText =
          typeof this.avatarContent === 'string'
            ? this.avatarContent
            : this.avatarContent.firstname.toUpperCase().charAt(0) +
              this.avatarContent.lastname.toUpperCase().charAt(0);
      } else {
        this.avatarText = '??';
      }
    }
  }
}
