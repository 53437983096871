import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ShuffleListState } from '~/app/main/shuffle-list/shuffle-list.state';
import { selectAllTeams } from '~/app/main/shuffle-list/states/teams/teams-shuffle-list.selectors';
import {
  selectShuffleList,
  selectShuffleListLoadingState,
} from '~/app/main/shuffle-list/states/shuffle-list/shuffle-list.selectors';
import { TeamShuffleListService } from '~/app/main/shuffle-list/services/team-shuffle-list.service';
import { ShuffleListService } from '~/app/main/shuffle-list/services/shuffle-list.service';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { ShuffleList } from '~/app/shared/interfaces/shuffle-list/shuffle-list.interface';
import { AlertService } from '~/app/shared/services/alert.service';
import { DaysOfWeekEnum } from '~/app/shared/enums/daysOfWeek.enum';

/**
 * Component for managing shuffle lists.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-cud-shuffle-list' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './cud-shuffle-list.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./cud-shuffle-list.component.scss'] – Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-cud-shuffle-list',
  templateUrl: './cud-shuffle-list.component.html',
  styleUrls: ['./cud-shuffle-list.component.scss'],
})
export class CudShuffleListComponent implements OnInit {
  /**
   * Initial data for the shuffle list, used to prefill the form in case of editing.
   *
   * @type {ShuffleList | null}
   */
  @Input() initialData: ShuffleList | null = null;

  /**
   * FormGroup for creation of shuffle list.
   *
   * @type {FormGroup}
   */
  shuffleForm!: FormGroup;

  /**
   * The list of teams of the current shuffle list.
   *
   * @type {Observable<ShuffleList | null>}
   */
  shuffleTeams$: Observable<ShuffleList | null> = new Observable<ShuffleList>();

  /**
   * Observable stream of teams state.
   *
   * @type {Observable<LabelValueInterface[]>}
   */
  teams$: Observable<LabelValueInterface[]>;

  /**
   * List of strings representing days of week.
   *
   * @type {string[]}
   */
  daysOfWeekEnum = Object.values(DaysOfWeekEnum);

  /**
   * Observable for the loading state of the cud shuffle list.
   *
   * @type {Observable<boolean>}
   */
  isShuffleListLoading$: Observable<boolean>;

  /**
   * Constructs an instance of ShuffleListComponent.
   *
   * @param {ShuffleListService} shuffleListService - Service for managing shuffle lists.
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {AlertService} alertService — Service for displaying alerts.
   * @param {TeamShuffleListService} teamShuffleList - Service for handling team settings operations.
   * @param {Store<ShuffleListState>} store - The NgRx store for state management.
   * @memberof ShuffleListComponent
   */
  constructor(
    private shuffleListService: ShuffleListService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private teamShuffleList: TeamShuffleListService,
    private store: Store<ShuffleListState>
  ) {
    this.teamShuffleList.loadTeams();
    this.teams$ = this.store
      .select(selectAllTeams)
      .pipe(
        map(teamsState =>
          teamsState.map(team => ({ label: team.name, value: team.uuid }))
        )
      );
    this.isShuffleListLoading$ = this.store.select(
      selectShuffleListLoadingState
    );
  }

  /**
   * Getter for accessing the 'name' FormControl within the shuffle form.
   * @type {FormControl}
   */
  public get name(): FormControl {
    return this.shuffleForm.get('name') as FormControl;
  }

  /**
   * Getter for accessing the 'description' FormControl within the shuffle form.
   * @type {FormControl}
   */
  public get description(): FormControl {
    return this.shuffleForm.get('description') as FormControl;
  }

  /**
   * Getter for accessing the 'daysOfWeek' FormControl within the shuffle form.
   * @type {FormControl}
   */
  public get daysOfWeek(): FormControl {
    return this.shuffleForm.get('daysOfWeek') as FormControl;
  }

  /**
   * Getter for accessing the 'teams' FormControl within the shuffle form.
   * @type {FormControl}
   */
  public get teams(): FormControl {
    return this.shuffleForm.get('teams') as FormControl;
  }

  /**
   * Checks if the form is dirty.
   * @returns {boolean} True if the form is dirty, false otherwise.
   */
  public get isFormDirty(): boolean {
    return this.shuffleForm.dirty;
  }

  /**
   * OnInit lifecycle hook that initializes the form and the current shuffle list if update.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.teams.reset();
    if (this.initialData) {
      this.shuffleListService.getShuffleListById(this.initialData.uuid);
      this.shuffleTeams$ = this.store.select(selectShuffleList);
    }
  }

  /**
   * Initializes the search form.
   *
   * @return {void}
   */
  initForm(): void {
    this.shuffleForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
        ],
      ],
      description: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1024),
        ],
      ],
      daysOfWeek: [[], [Validators.required, Validators.minLength(1)]],
      teams: [null],
    });

    if (this.initialData) {
      this.shuffleForm.patchValue(this.initialData);
    }
  }

  /**
   * Handles form submission for creating a sufflelist.
   *
   * @return {void}
   */
  onSubmit(): void {
    if (this.initialData && this.initialData.uuid) {
      this.shuffleListService.updateShuffleList(this.initialData.uuid, {
        name: this.shuffleForm.value.name,
        description: this.shuffleForm.value.description,
        daysOfWeek: this.shuffleForm.value.daysOfWeek,
      });
    } else {
      this.shuffleListService.createShuffleList({
        name: this.shuffleForm.value.name,
        description: this.shuffleForm.value.description,
        daysOfWeek: this.shuffleForm.value.daysOfWeek,
      });
    }
  }

  /**
   * Handles the deletion of a shuffleList and shows a confirmation alert.
   *
   * @return {void}
   */
  onDelete(): void {
    this.alertService.showAlert('Delete Confirmation', {
      title: 'Are you sure you want to delete this shufflelist?',
      message: 'This action cannot be undone',
      action: () => {
        this.shuffleListService.deleteShuffleList(this.initialData?.uuid ?? '');
      },
    });
  }

  /**
   * Assigns a team to the Shuffle list.
   *
   * @param {AutoCompleteSelectEvent} event - The autocomplete select event.
   * @returns {void}
   */
  onAssignTeam(event: AutoCompleteSelectEvent): void {
    if (this.initialData && this.initialData.uuid) {
      this.shuffleListService.assignTeam(
        this.initialData.uuid,
        event.value.value
      );
    }
    this.teams.reset();
  }

  /**
   * Unassigns a team from the Shuffle list.
   *
   * @param {string} teamId - The UUID of the team to unassign.
   * @returns {void}
   */
  onUnassignTeam(teamId: string): void {
    if (this.initialData && this.initialData.uuid) {
      this.shuffleListService.unassignTeam(this.initialData.uuid, teamId);
    }
  }
}
