import { Skill } from '~/app/shared/interfaces/shared/skill.interface';

/**
 * Represents the state of all skills.
 *
 * @interface AllSkillsState
 */
export interface AllSkillsState {
  /**
   * The maximum number of skills to return.
   *
   * @type {number}
   * @memberof AllSkillsState
   */
  limit: number;

  /**
   * The current page number.
   *
   * @type {number}
   * @memberof AllSkillsState
   */
  page: number;

  /**
   * Indicates if there are previous pages.
   *
   * @type {boolean}
   * @memberof AllSkillsState
   */
  hasPrevious: boolean;

  /**
   * Indicates if there are next pages.
   *
   * @type {boolean}
   * @memberof AllSkillsState
   */
  hasNext: boolean;

  /**
   * The total number of skills found.
   *
   * @type {number}
   * @memberof AllSkillsState
   */
  count: number;

  /**
   * An array of all skills.
   *
   * @type {Skill[]}
   * @memberof AllSkillsState
   */
  data: Skill[];

  /**
   * Indicates if the skills are currently being loaded.
   *
   * @type {boolean}
   * @memberof AllSkillsState
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the skills.
   *
   * @type {string | null}
   * @memberof AllSkillsState
   */
  error: string | null;
}

/**
 * The initial state of all skills within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {AllSkillsState} initialAllSkillsState
 * @description Provides a baseline definition for the all skills context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialAllSkillsState: AllSkillsState = {
  limit: 0,
  page: 0,
  hasPrevious: false,
  hasNext: false,
  count: 0,
  data: [],
  loading: false,
  error: null,
};
