import { createAction, props } from '@ngrx/store';
import { AllRules } from '~/app/shared/interfaces/settings/access-control/rule/all-rules.interface';
import { GroupRule } from '~/app/shared/interfaces/settings/access-control/group/group-rule.interface';

// Action types for loading all rules
export const loadRules = createAction('[Rules] Load Rules');
export const loadRulesSuccess = createAction(
  '[Rules] Load Rules Success',
  props<{ allRules: AllRules }>()
);
export const loadRulesFailure = createAction(
  '[Rules] Load Rules Failure',
  props<{ error: string }>()
);

// Action types for loading a rule by ID
export const loadRulesByGroup = createAction(
  '[Rules] Load Rules By Group',
  props<{ groupId: string }>()
);
export const loadRulesByGroupSuccess = createAction(
  '[Rules] Load Rules By Group Success',
  props<{ groupRules: GroupRule }>()
);
export const loadRulesByGroupFailure = createAction(
  '[Rules] Load Rules By Group Failure',
  props<{ error: string }>()
);

export const rulesUnauthorized = createAction(
  '[Rules] Unauthorized',
  props<{ error: string }>()
);
