import { createReducer, on } from '@ngrx/store';
import * as OfferProposalActions from '~/app/states/main/jobhub/states/offer-proposal/offer-proposal.actions';
import { initialOfferProposalState } from '~/app/states/main/jobhub/states/offer-proposal/offer-proposal.state';

export const offerProposalReducer = createReducer(
  initialOfferProposalState,

  on(OfferProposalActions.loadOfferProposal, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OfferProposalActions.loadOfferProposalSuccess, (state, { offer }) => ({
    ...state,
    offer,
    loading: false,
  })),
  on(OfferProposalActions.loadOfferProposalFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OfferProposalActions.changeOfferProposalStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    OfferProposalActions.changeOfferProposalStatusSuccess,
    (state, { offer }) => ({
      ...state,
      offer,
      loading: false,
    })
  ),
  on(
    OfferProposalActions.changeOfferProposalStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(OfferProposalActions.offerProposalUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
