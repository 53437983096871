import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileNotesActions from '~/app/states/main/crm/profile/states/profile-notes/profile-notes.actions';
import { ProfileNote } from '~/app/shared/interfaces/crm/profile/profile-note.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileNotesEffects` manages the side effects for profile note-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile notes, such as loading, creating, updating, and deleting profile notes.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileNotes$`: Loads profile notes based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched notes or a failure action if an error occurs.
 * — `createProfileNote$`: Creates a new profile note. It performs an HTTP POST request and dispatches a success action with the created note or a failure action if an error occurs.
 * — `createProfileNoteSuccess$`: Handles the success of creating a new profile note by displaying a success toast message.
 * — `updateProfileNote$`: Updates an existing profile note. It performs an HTTP PATCH request and dispatches a success action with the updated note or a failure action if an error occurs.
 * — `updateProfileNoteSuccess$`: Handles the success of updating a profile note by displaying a success toast message.
 * — `deleteProfileNote$`: Deletes a profile note based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileNoteSuccess$`: Handles the success of deleting a profile note by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileNotesEffects {
  /**
   * Effect to load profile notes based on a given profile ID.
   * Listens for the `loadProfileNotes` action, checks permissions, and performs an HTTP GET request to fetch the notes.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNotesActions.loadProfileNotes),
      checkPermission(ProfileNotesActions.profileNotesUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<ProfileNote[]>(`${environment.apiUrl}/v1/profiles/notes`, {
            params: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(profileNotes =>
              ProfileNotesActions.loadProfileNotesSuccess({
                profileNotes,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileNotesActions.loadProfileNotesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new profile note.
   * Listens for the `createProfileNote` action, checks permissions, and performs an HTTP POST request to create the note.
   * Dispatches a success or failure action based on the result.
   */
  createProfileNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNotesActions.createProfileNote),
      checkPermission(ProfileNotesActions.profileNotesUnauthorized),
      mergeMap(({ profileNoteData }) =>
        this.http
          .post<ProfileNote>(
            `${environment.apiUrl}/v1/profiles/notes`,
            profileNoteData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileNote =>
              ProfileNotesActions.createProfileNoteSuccess({ profileNote })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileNotesActions.createProfileNoteFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile note.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  createProfileNoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileNotesActions.createProfileNoteSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile note created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a sales note.
   * It listens for the `updateSalesNote` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales note or a failure action if an error occurs.
   */
  updateSalesNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNotesActions.updateProfileNote),
      checkPermission(ProfileNotesActions.profileNotesUnauthorized),
      mergeMap(({ uuid, body }) =>
        this.http
          .patch<ProfileNote>(
            `${environment.apiUrl}/v1/profiles/notes/${uuid}`,
            body,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileNote =>
              ProfileNotesActions.updateProfileNoteSuccess({ profileNote })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileNotesActions.updateProfileNoteFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to delete a profile note based on its UUID and profile ID.
   * Listens for the `deleteProfileNote` action, checks permissions, and performs an HTTP DELETE request to delete the note.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNotesActions.deleteProfileNote),
      checkPermission(ProfileNotesActions.profileNotesUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/notes/${uuid}`, {
            body: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => ProfileNotesActions.deleteProfileNoteSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileNotesActions.deleteProfileNoteFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a profile note.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteProfileNoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileNotesActions.deleteProfileNoteSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile note deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileNotesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
