import { createReducer, on } from '@ngrx/store';
import { initialNoteCategoriesState } from '~/app/states/main/settings/states/notes/notes.state';
import * as NotesActions from '~/app/states/main/settings/states/notes/notes.actions';

export const noteCategoriesReducer = createReducer(
  initialNoteCategoriesState,

  on(NotesActions.loadNoteCategories, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NotesActions.loadNoteCategoriesSuccess, (state, { noteCategories }) => ({
    ...state,
    noteCategories,
    loading: false,
  })),
  on(NotesActions.loadNoteCategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotesActions.createNoteCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NotesActions.createNoteCategorySuccess, (state, { noteCategory }) => ({
    ...state,
    noteCategories: [...state.noteCategories, noteCategory],
    loading: false,
  })),
  on(NotesActions.createNoteCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotesActions.updateNoteCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NotesActions.updateNoteCategorySuccess, (state, { noteCategory }) => ({
    ...state,
    noteCategories: state.noteCategories.map(item =>
      item.uuid === noteCategory.uuid ? noteCategory : item
    ),
    loading: false,
  })),
  on(NotesActions.updateNoteCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotesActions.deleteNoteCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NotesActions.deleteNoteCategorySuccess, (state, { uuid }) => ({
    ...state,
    noteCategories: state.noteCategories.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(NotesActions.deleteNoteCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotesActions.noteCategoryUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
