import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';
import { environment } from '~/environments/environment';

/**
 * Intercepts HTTP requests to add a JWT authentication token to the headers for requests to the API.
 * This is used to ensure that the requests are authenticated when the token is present.
 *
 * @Injectable indicates that this class can be injected, though it doesn't specify 'providedIn'
 * meaning it needs to be explicitly provided in the module or component.
 */
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  /**
   * Tracks the current URL to determine if the request should bypass authentication checks.
   * Used primarily for exempting specific routes, such as public pages.
   * @private
   */
  private currentUrl = '/';

  /**
   * Constructs a new instance of JwtInterceptor.
   * @param {Store<AppState>} store - The NgRx store used to select the current authentication token.
   * @param {Router} router - The Angular router service used to monitor navigation events.
   */
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(event => {
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  /**
   * Method that intercepts all HTTP requests and modifies them if they are directed towards the API URL.
   * It adds the Authorization header with the bearer token if the token exists.
   *
   * @param {HttpRequest<any>} request - The outgoing request object to handle.
   * @param {HttpHandler} next - The next interceptor in the chain, or the final handler that will send the request.
   * @returns {Observable<HttpEvent<any>>} An observable of the event stream for the request.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Utilise `currentUrl` pour vérifier si c'est une URL exemptée
    const isExemptUrl = /^\/browse-jobs(\b|\/|$)/.exec(this.currentUrl);
    if (isExemptUrl) {
      // Ignore l'authentification pour les URLs exemptées
      return next.handle(request);
    }

    // Si l'URL n'est pas exemptée, continue avec l'authentification
    return this.store.select(selectAuthState).pipe(
      take(1),
      switchMap(authState => {
        return next.handle(request).pipe(
          catchError(err => {
            if (
              [401, 403].includes(err.status) &&
              authState.userData !== null
            ) {
              if (err.url !== environment.apiUrl + '/v1/auth/refreshToken') {
                window.location.reload();
              } else {
                void this.router.navigate(['/login']);
              }
            }

            const error =
              (err && err.error && err.error.message) || err.statusText;
            return throwError(() => error);
          })
        );
      })
    );
  }
}
