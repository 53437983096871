import { KanbanAttachment } from '~/app/shared/interfaces/kanban/kanban-attachment.interface';

/**
 * Represents the state of Kanban cards.
 *
 * @interface KanbanCardAttachmentsState
 */
export interface KanbanCardAttachmentsState {
  /**
   * The attachments associated with the Kanban card.
   *
   * @type {KanbanAttachment[]}
   */
  attachments: KanbanAttachment[];

  /**
   * Indicates if the Kanban cards are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban cards.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of Kanban cards.
 *
 * @type {KanbanCardsState}
 */
export const initialKanbanCardAttachmentsState: KanbanCardAttachmentsState = {
  attachments: [],
  loading: false,
  error: null,
};
