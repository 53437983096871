import { Injectable } from '@angular/core';

/**
 * Service to manage local storage operations within the application.
 * Provides methods to save and retrieve data from the browser's local storage.
 * This service ensures the data is stored and retrieved in a JSON serialized format,
 * allowing complex data types to be easily stored and retrieved.
 *
 * @Injectable indicates that this service can be injected across the application
 * and it will be provided in the root injector, making it a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Saves a value to localStorage under the specified key after serializing it to a JSON string.
   * This method can handle complex data types by utilizing JSON.stringify to serialize them.
   *
   * @param {string} key - The key under which the value will be stored.
   * @param {T} value - The value to store, which can be any type. The value will be serialized to a string.
   * @typeparam T - The type of the data being stored. This generic parameter helps ensure type safety.
   * @return {void}
   */
  saveItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves a value from localStorage by the specified key and deserializes it from a JSON string back into its original format.
   * If the key does not exist, or if the stored value is null, this method returns null.
   *
   * @param {string} key - The key under which the value is stored.
   * @returns {T | null} - Returns the deserialized value if it exists, or null if it does not exist.
   * The return type is automatically inferred based on where the method is called (type safety).
   * @typeparam T - The expected type of the data to retrieve. This generic parameter helps in deserializing the data into its correct format.
   */
  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
}
