import { Component } from '@angular/core';

/**
 * LoginComponent handles user login functionality, providing a form for inputting subdomain, email, and password,
 * and uses the LoginService to authenticate users based on these credentials.
 *
 * @Component
 * @selector 'app-login' - CSS selector that identifies this component in a template.
 * @templateUrl './login.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./login.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {}
