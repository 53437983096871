import { Pipe, PipeTransform } from '@angular/core';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { KanbanUser } from '~/app/shared/interfaces/kanban/kanban-user/kanban-user.interface';

/**
 * A custom Angular pipe that converts an array of KanbanUser objects into an array of User objects.
 * This pipe is useful for adapting Kanban-specific user data into a more general User model format,
 * facilitating the use of user data across different parts of an application where only basic user details are required.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'kanbanUsersToUsers', enabling its use within Angular templates
 * to dynamically convert arrays of KanbanUser objects into User arrays.
 */
@Pipe({
  name: 'kanbanUsersToUsers',
})
export class KanbanUsersToUsersPipe implements PipeTransform {
  /**
   * Transforms an array of KanbanUser objects into an array of User objects.
   * This transformation extracts the `userId` property from each KanbanUser object and uses it to create new User objects.
   * Note: This example assumes that User objects can be correctly represented just by their `userId`. Adjustments may be necessary
   * if the User model requires more data.
   *
   * @param {KanbanUser[]} kanbanUsers - The array of KanbanUser objects to be transformed.
   * @returns {User[]} - An array of User objects derived from the KanbanUser input array.
   */
  transform(kanbanUsers: KanbanUser[]): User[] {
    return kanbanUsers.map(user => user.userId);
  }
}
