/* eslint-disable max-lines */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanCardsActions from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.actions';
import { KanbanCardTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-card-tag.interface';
import { KanbanCardUser } from '~/app/shared/interfaces/kanban/kanban-user/kanban-card-user.interface';
import { KanbanCard } from '~/app/shared/interfaces/kanban/kanban-card.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { KanbanProfile } from '~/app/shared/interfaces/kanban/kanban-profile.interface';
import { KanbanCardProfile } from '~/app/shared/interfaces/kanban/kanban-card-profile.interface';
import { environment } from '~/environments/environment';
import { AllKanbanCard } from '~/app/shared/interfaces/kanban/all-kanban-card.interface';
import { IsseKanbanCardService } from '~/app/states/main/kanban/services/isse-kanban-card.service';

/**
 * `KanbanCardsEffects` manages the side effects for kanban card-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban cards,
 * such as loading, creating, updating, deleting cards, and managing card users and tags.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests,
 * and displaying notifications.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanCardsEffects {
  /**
   * Effect to load all kanban cards for a given column ID.
   * Listens for the `loadAllKanbanCards` action, checks permissions, and performs an HTTP GET request to fetch the cards.
   * Dispatches a success or failure action based on the result.
   */
  loadAllKanbanCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.loadAllKanbanCards),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanColumnId, filters }) =>
        this.http
          .get<AllKanbanCard>(`${environment.apiUrl}/v1/kanbans/cards`, {
            params: { kanbanColumnId, ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(kanbanCards => {
              return KanbanCardsActions.loadAllKanbanCardsSuccess({
                kanbanCards,
                kanbanColumnId,
              });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.loadAllKanbanCardsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban card.
   * Listens for the `createKanbanCard` action, checks permissions, and performs an HTTP POST request to create the card.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.createKanbanCard),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanCardData }) =>
        this.http
          .post<KanbanCard>(
            `${environment.apiUrl}/v1/kanbans/cards`,
            kanbanCardData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanCard =>
              KanbanCardsActions.createKanbanCardSuccess({
                kanbanCard,
                kanbanColumnId: kanbanCardData.kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanCardsActions.createKanbanCardFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new kanban card.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createKanbanCardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.createKanbanCardSuccess),
        tap(() => {
          this.sidebar.closeSidebar();
          this.toast.successToast(
            'Success',
            'Kanban card created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing kanban card.
   * Listens for the `updateKanbanCard` action, checks permissions, and performs an HTTP PATCH request to update the card.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.updateKanbanCard),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardData, nbAttachments, nbComments }) =>
        this.http
          .patch<KanbanCard>(
            `${environment.apiUrl}/v1/kanbans/cards/${uuid}`,
            kanbanCardData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanCard =>
              KanbanCardsActions.updateKanbanCardSuccess({
                kanbanCard,
                kanbanColumnId: kanbanCardData.kanbanColumnId,
                nbAttachments,
                nbComments,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanCardsActions.updateKanbanCardFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a kanban card.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateKanbanCardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.updateKanbanCardSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Kanban card updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete an existing kanban card.
   * Listens for the `deleteKanbanCard` action, checks permissions, and performs an HTTP DELETE request to delete the card.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.deleteKanbanCard),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanColumnId }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/kanbans/cards/${uuid}`, {
            body: { kanbanColumnId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              KanbanCardsActions.deleteKanbanCardSuccess({
                uuid,
                kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(KanbanCardsActions.deleteKanbanCardFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban card.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  deleteKanbanCardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.deleteKanbanCardSuccess),
        tap(() => {
          this.sidebar.closeSidebar();
          this.toast.successToast(
            'Success',
            'Kanban card deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the sequences of kanban cards.
   * Listens for the `updateKanbanCardSequences` action, checks permissions, and performs an HTTP PATCH request to update the card sequences.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanCardSequences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.updateKanbanCardSequences),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanCardSequencesData, oldCardSequence }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/cards/sequences`,
            kanbanCardSequencesData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => KanbanCardsActions.updateKanbanCardSequencesSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.updateKanbanCardSequencesFailure({
                  error,
                  kanbanColumnId: kanbanCardSequencesData.kanbanColumnId,
                  oldCardSequence,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to switch a kanban card between columns.
   * Listens for the `switchKanbanCardColumn` action, checks permissions, and performs an HTTP PATCH request to switch the card's column.
   * Dispatches a success or failure action based on the result.
   */
  switchKanbanCardColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.switchKanbanCardColumn),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardsData, prevIndex }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/cards/${uuid}/switch/columns`,
            kanbanCardsData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => KanbanCardsActions.switchKanbanCardColumnSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.switchKanbanCardColumnFailure({
                  error,
                  uuid,
                  kanbanCardsData: {
                    sequence: kanbanCardsData.sequence,
                    newKanbanColumnId: kanbanCardsData.oldKanbanColumnId,
                    oldKanbanColumnId: kanbanCardsData.newKanbanColumnId,
                  },
                  prevIndex,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of switching a kanban card between columns.
   * Closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  switchKanbanCardColumnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.switchKanbanCardColumnSuccess),
        tap(() => {
          this.sidebar.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a user to a kanban card.
   * Listens for the `assignKanbanCardUser` action, checks permissions, and performs an HTTP PATCH request to assign the user.
   * Dispatches a success or failure action based on the result.
   */
  assignKanbanCardUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.assignKanbanCardUser),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardUserData }) =>
        this.http
          .patch<
            KanbanCardUser[]
          >(`${environment.apiUrl}/v1/kanbans/cards/${uuid}/users/assign`, kanbanCardUserData, { withCredentials: true })
          .pipe(
            take(1),
            map(kanbanCardUsers =>
              KanbanCardsActions.assignKanbanCardUserSuccess({
                uuid,
                kanbanCardUsers,
                kanbanColumnId: kanbanCardUserData.kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.assignKanbanCardUserFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a user to a kanban card.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  assignKanbanCardUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.assignKanbanCardUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a user from a kanban card.
   * Listens for the `unassignKanbanCardUser` action, checks permissions, and performs an HTTP PATCH request to unassign the user.
   * Dispatches a success or failure action based on the result.
   */
  unassignKanbanCardUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.unassignKanbanCardUser),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardUserData }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/cards/${uuid}/users/unassigned`,
            kanbanCardUserData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardsActions.unassignKanbanCardUserSuccess({
                uuid,
                kanbanCardUserData,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.unassignKanbanCardUserFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a user from a kanban card.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  unassignKanbanCardUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.unassignKanbanCardUserSuccess),
        tap(() => {
          this.toast.successToast('Success', 'User unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a tag to a kanban card.
   * Listens for the `assignKanbanCardTag` action, checks permissions, and performs an HTTP PATCH request to assign the tag.
   * Dispatches a success or failure action based on the result.
   */
  assignKanbanCardTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.assignKanbanCardTag),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardTagData }) =>
        this.http
          .patch<
            KanbanCardTag[]
          >(`${environment.apiUrl}/v1/kanbans/cards/${uuid}/kanbantag/assign`, kanbanCardTagData, { withCredentials: true })
          .pipe(
            take(1),
            map(kanbanCardTags =>
              KanbanCardsActions.assignKanbanCardTagSuccess({
                uuid,
                kanbanCardTags,
                kanbanColumnId: kanbanCardTagData.kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.assignKanbanCardTagFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a tag to a kanban card.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  assignKanbanCardTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.assignKanbanCardTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a tag from a kanban card.
   * Listens for the `unassignKanbanCardTag` action, checks permissions, and performs an HTTP PATCH request to unassign the tag.
   * Dispatches a success or failure action based on the result.
   */
  unassignKanbanCardTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.unassignKanbanCardTag),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ uuid, kanbanCardTagData }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/cards/${uuid}/kanbantag/unassigned`,
            kanbanCardTagData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardsActions.unassignKanbanCardTagSuccess({
                uuid,
                kanbanCardTagData,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.unassignKanbanCardTagFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a tag from a kanban card.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  unassignKanbanCardTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardsActions.unassignKanbanCardTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a profile to a kanban card.
   * Listens for the `assignKanbanCardProfile` action, checks permissions, and performs an HTTP PATCH request to assign the profile.
   * Dispatches a success or failure action based on the result.
   */
  getKanbanCardProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.getKanbanCardProfiles),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanCardId, kanbanId }) => {
        let queryParams = new HttpParams();
        if (kanbanId) {
          queryParams = queryParams.set('kanbanId', kanbanId);
        }
        return this.http
          .get<
            KanbanProfile[]
          >(`${environment.apiUrl}/v1/kanbans/cards/${kanbanCardId}/profile`, { withCredentials: true, params: queryParams })
          .pipe(
            take(1),
            map(profiles =>
              KanbanCardsActions.getKanbanCardProfilesSuccess({ profiles })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.getKanbanCardProfilesFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to assign a profile to a kanban card.
   * Listens for the `assignKanbanCardProfile` action, checks permissions, and performs an HTTP PATCH request to assign the profile.
   * Dispatches a success or failure action based on the result.
   */
  assignKanbanCardProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.assignKanbanCardProfile),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanCardId, kanbanProfileColumn }) =>
        this.http
          .patch<
            KanbanCardProfile[]
          >(`${environment.apiUrl}/v1/kanbans/cards/${kanbanCardId}/profile/assign`, kanbanProfileColumn, { withCredentials: true })
          .pipe(
            take(1),
            map(kanbanCardProfileData =>
              KanbanCardsActions.assignKanbanCardProfileSuccess({
                kanbanCardProfileData,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.assignKanbanCardProfileFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to unassign a profile from a kanban card.
   * Listens for the `unassignKanbanCardProfile` action, checks permissions, and performs an HTTP PATCH request to unassign the profile.
   * Dispatches a success or failure action based on the result.
   */
  unassignKanbanCardProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.unassignKanbanCardProfile),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ kanbanCardId, kanbanProfileColumn }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/kanbans/cards/${kanbanCardId}/profile/unassigned`,
            kanbanProfileColumn,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardsActions.unassignKanbanCardProfileSuccess({
                profileId: kanbanProfileColumn.profileId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardsActions.unassignKanbanCardProfileFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to connect to a channel.
   * Listens for the `connectToChannel` action, checks permissions, and performs an HTTP POST request.
   * Dispatches a success or failure action based on the result.
   */
  connectToChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.connectToChannel),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ connectionDetails }) =>
        this.http
          .post<void>(
            `${environment.isseUrl}/v1/auth/connect`,
            connectionDetails,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => {
              this.isseService.listenToChannel();
              return KanbanCardsActions.connectToChannelSuccess();
            }),
            catchError(error => {
              return of(KanbanCardsActions.connectToChannelFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to disconnect from a channel.
   * Listens for the `disconnectFromChannel` action, checks permissions, and performs an HTTP POST.
   * Dispatches a success or failure action based on the result.
   */
  disconnectFromChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardsActions.disconnectFromChannel),
      checkPermission(KanbanCardsActions.kanbanCardUnauthorized),
      mergeMap(({ connectionDetails }) =>
        this.http
          .post<void>(
            `${environment.isseUrl}/v1/auth/disconnect`,
            connectionDetails,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => KanbanCardsActions.disconnectFromChannelSuccess()),
            catchError(error => {
              return of(
                KanbanCardsActions.disconnectFromChannelFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `KanbanCardsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebar Injectable service for handling sidebar form actions.
   * @param {IsseKanbanService} isseService Injectable service for handling ISSE operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebar: SidenavFormService,
    private isseService: IsseKanbanCardService
  ) {}
}
