import { AllSearchClientCompaniesState } from '~/app/states/main/client-company/states/search-client-company/search-client-company.state';

/**
 * Represents the state of the profile client companies.
 * This type extends the `AllSearchClientCompaniesState` to include all properties related to client companies.
 *
 * @type {ProfileClientCompaniesState}
 */
export type ProfileClientCompaniesState = AllSearchClientCompaniesState;

/**
 * The initial state for the profile client companies.
 * It defines the default values for the state properties including pagination and loading status.
 *
 * @constant {ProfileClientCompaniesState}
 * @default
 */
export const initialProfileClientCompaniesState: ProfileClientCompaniesState = {
  count: 0, // Total number of client companies available.
  page: 0, // Current page number for pagination.
  limit: 10, // Number of client companies to display per page.
  hasNext: false, // Indicates if there is a next page available.
  hasPrevious: false, // Indicates if there is a previous page available.
  data: [], // Array to hold the client company data.
  loading: false, // Indicates if the data is currently being loaded.
  error: null, // Holds any error that may occur during data fetching.
};
