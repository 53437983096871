<div
  (click)="clickEvent()"
  [@fadeBg]="value ? 'on' : 'off'"
  [ngClass]="{
    'border-1 hover:bg-dark-3': value,
    'bg-dark-90 color-light-100': !value,
  }"
  class="flex h-3rem align-items-center px-3-5 gap-2-5 border-round cursor-pointer">
  <i
    [class]="
      'text-2xl pi ' + (value ? options.true.icon : options.false.icon)
    "></i>
  <p>{{ value ? options.true.label : options.false.label }}</p>
</div>
