import { createAction, props } from '@ngrx/store';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';

export const loadAllAvailableModules = createAction(
  '[Navigation] Load All Available Modules'
);
export const loadAllAvailableModulesSuccess = createAction(
  '[Navigation] Load All Available Modules Success',
  props<{ availableModules: ModuleItem[] }>()
);
export const loadAllAvailableModulesFailure = createAction(
  '[Navigation] Load All Available Modules Failure',
  props<{ error: string }>()
);

export const loadAllActivatedModules = createAction(
  '[Navigation] Load All Activated Modules'
);
export const loadAllActivatedModulesSuccess = createAction(
  '[Navigation] Load All Activated Modules Success',
  props<{ activatedModules: ModuleItem[] }>()
);
export const loadAllActivatedModulesFailure = createAction(
  '[Navigation] Load All Activated Modules Failure',
  props<{ error: string }>()
);

export const loadCurrentModule = createAction(
  '[Navigation] Load Current Modules'
);
export const loadCurrentModuleSuccess = createAction(
  '[Navigation] Load Current Modules Success',
  props<{ currentModule: ModuleItem }>()
);
export const loadCurrentModuleFailure = createAction(
  '[Navigation] Load Current Modules Failure',
  props<{ error: string }>()
);

export const changeCurrentModule = createAction(
  '[Navigation] Change Current Modules',
  props<{ module: string }>()
);
export const changeCurrentModuleSuccess = createAction(
  '[Navigation] Change Current Modules Success',
  props<{ module: string }>()
);
export const changeCurrentModuleFailure = createAction(
  '[Navigation] Change Current Modules Failure',
  props<{ error: string }>()
);

export const navigationUnauthorized = createAction(
  '[Navigaion] Unauthorized',
  props<{ error: string }>()
);
