import { createAction, props } from '@ngrx/store';
import { AllOfficesState } from '~/app/states/main/settings/states/offices/offices.state';
import {
  CreateOffice,
  UpdateOfficeById,
} from '~/app/states/main/settings/types/office.types';
import { Office } from '~/app/shared/interfaces/settings/office.interface';

export const loadOffices = createAction(
  '[Offices] Load Offices',
  props<{ limit: number; page: number }>()
);
export const loadOfficesSuccess = createAction(
  '[Offices] Load Offices Success',
  props<{ offices: AllOfficesState }>()
);
export const loadOfficesFailure = createAction(
  '[Offices] Load Offices Failure',
  props<{ error: string }>()
);

export const createOffice = createAction(
  '[Offices] Create Office',
  props<{ officeData: CreateOffice }>()
);
export const createOfficeSuccess = createAction(
  '[Offices] Create Office Success',
  props<{ office: Office }>()
);
export const createOfficeFailure = createAction(
  '[Offices] Create Office Failure',
  props<{ error: string }>()
);

export const updateOffice = createAction(
  '[Offices] Update Office',
  props<{ uuid: string; officeData: UpdateOfficeById }>()
);
export const updateOfficeSuccess = createAction(
  '[Offices] Update Office Success',
  props<{ office: Office }>()
);
export const updateOfficeFailure = createAction(
  '[Offices] Update Office Failure',
  props<{ error: string }>()
);

export const deleteOffice = createAction(
  '[Offices] Delete Office',
  props<{ uuid: string }>()
);
export const deleteOfficeSuccess = createAction(
  '[Offices] Delete Office Success',
  props<{ uuid: string }>()
);
export const deleteOfficeFailure = createAction(
  '[Offices] Delete Office Failure',
  props<{ error: string }>()
);

export const officesUnauthorized = createAction(
  '[Offices] Unauthorized',
  props<{ error: string }>()
);
