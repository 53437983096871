import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Notifications } from '@npmicedev/icemodule/lib/entities/Notifications';
import * as NotificationsActions from '~/app/states/main/states/notification/notification.actions';
import { NotificationState } from '~/app/states/main/states/notification/notification.state';
import { ConnectionDetails } from '~/app/shared/interfaces/connectionDetails.interface';

/**
 * NotificationService
 *
 * This service provides a centralized way to interact with the NgRx store
 * for managing notification-related state in the application.
 * It dispatches actions to load notifications, create them, mark them as read,
 * and handle SSE channel connections.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * The constructor injects:
   * - The NgRx store to interact with notification state.
   * - The HttpClient, which is available for potential HTTP calls (not used in this file).
   */
  constructor(
    private store: Store<NotificationState>,
    private http: HttpClient
  ) {}

  /**
   * Dispatches an action to load all notifications.
   * @returns {void}
   */
  loadAllNotifications(): void {
    this.store.dispatch(NotificationsActions.loadAllNotifications());
  }

  /**
   * Dispatches an action to create a new notification.
   * @param {Notifications} notification - The notification object to create.
   * @returns {void}
   */
  createNotification(notification: Notifications): void {
    this.store.dispatch(
      NotificationsActions.createNotification({ notification })
    );
  }

  /**
   * Dispatches an action to mark a notification as read.
   * @param {string} uuid - The UUID of the notification to mark as read.
   * @returns {void}
   */
  markAsRead(uuid: string): void {
    this.store.dispatch(NotificationsActions.markNotificationAsRead({ uuid }));
  }

  /**
   * Dispatches an action to mark all notifications as read.
   * @returns {void}
   */
  markAllAsRead(): void {
    this.store.dispatch(NotificationsActions.markAllNotificationsAsRead());
  }

  /**
   * Dispatches an action to delete a notification.
   * @param {string} uuid - The UUID of the notification to delete.
   * @returns {void}
   */
  deleteNotification(uuid: string): void {
    this.store.dispatch(NotificationsActions.deleteNotification({ uuid }));
  }

  /**
   * Dispatches an action to delete all notifications for the authenticated user.
   * @returns {void}
   */
  deleteAllNotifications(): void {
    this.store.dispatch(NotificationsActions.deleteAllNotifications());
  }

  /**
   * Connects to an SSE channel with the given details and token.
   * @param {ConnectionDetails[]} connectionDetails - List of connection details for the SSE channels.
   * @param {string} token - The authentication token for the SSE connection.
   * @returns {void}
   */
  connectToChannel(
    connectionDetails: ConnectionDetails[],
    token: string
  ): void {
    this.store.dispatch(
      NotificationsActions.connectToChannel({ connectionDetails, token })
    );
  }

  /**
   * Disconnects from the SSE channels provided.
   * @param {ConnectionDetails[]} connectionDetails - List of connection details for the SSE channels.
   * @returns {void}
   */
  disconnectFromChannel(connectionDetails: ConnectionDetails[]): void {
    this.store.dispatch(
      NotificationsActions.disconnectFromChannel({ connectionDetails })
    );
  }
}
