import { createReducer, on } from '@ngrx/store';
import { initialClientCompanyConsultantsState } from '~/app/states/main/client-company/profile/states/client-company-consultants/client-company-consultants.state';
import * as ClientCompanyConsultantsActions from '~/app/states/main/client-company/profile/states/client-company-consultants/client-company-consultants.actions';

export const clientCompanyConsultantsReducer = createReducer(
  initialClientCompanyConsultantsState,

  on(ClientCompanyConsultantsActions.loadClientCompanyConsultants, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyConsultantsActions.loadClientCompanyConsultantsSuccess,
    (state, { clientCompanyConsultants }) => ({
      ...state,
      ...clientCompanyConsultants,
      loading: false,
    })
  ),
  on(
    ClientCompanyConsultantsActions.loadClientCompanyConsultantsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCompanyConsultantsActions.clientCompanyConsultantsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
