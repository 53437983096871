import { ClientCompanyInterview } from '~/app/shared/interfaces/client-company/profile/client-company-interview.interface';

/**
 * Interface representing the state of client company interviews.
 *
 * @interface ClientCompanyInterviewsState
 */
export interface ClientCompanyInterviewsState {
  /**
   * The total count of client company interviews.
   *
   * @type {number}
   * @memberof ClientCompanyInterviewsState
   */
  count: number;

  /**
   * The current page of client company interviews.
   *
   * @type {number}
   * @memberof ClientCompanyInterviewsState
   */
  page: number;

  /**
   * The limit of client company interviews per page.
   *
   * @type {number}
   * @memberof ClientCompanyInterviewsState
   */
  limit: number;

  /**
   * Whether there is a next page of client company interviews.
   *
   * @type {boolean}
   * @memberof ClientCompanyInterviewsState
   */
  hasNext: boolean;

  /**
   * Whether there is a previous page of client company interviews.
   *
   * @type {boolean}
   * @memberof ClientCompanyInterviewsState
   */
  hasPrevious: boolean;

  /**
   * The list of client company interviews.
   *
   * @type {ClientCompanyInterview[]}
   * @memberof ClientCompanyInterviewsState
   */
  data: ClientCompanyInterview[];

  /**
   * Whether the client company interviews are currently being loaded.
   *
   * @type {boolean}
   * @memberof ClientCompanyInterviewsState
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   * @memberof ClientCompanyInterviewsState
   */
  error: string | null;
}

/**
 * The initial state for client company interviews.
 *
 * @type {ClientCompanyInterviewsState}
 */
export const initialClientCompanyInterviewsState: ClientCompanyInterviewsState =
  {
    count: 0,
    page: 0,
    limit: 10,
    hasNext: false,
    hasPrevious: false,
    data: [],
    loading: false,
    error: null,
  };
