import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { disableTwoFactorAuthenticationByRecovery } from '@npmicedev/icemodule/lib/validations/users';
import * as TwoFactorAuthAction from '~/app/auth/state/two-factor-auth/two-factor-auth.actions';
import { DisableTwoFactorAuthByRecovery } from '~/app/auth/types/two-factor-auth.types';
import { AppState } from '~/app/core/state/app.state';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service dedicated to managing two-factor authentication (2FA) processes within the application.
 * It provides functionality to disable 2FA using a recovery mechanism.
 *
 * @Injectable marks this class as a service that can be injected with Angular's dependency injection,
 * making it available throughout the application as a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthService {
  /**
   * Constructs a new instance of TwoFactorAuthService, injecting dependencies needed for its operations.
   * This service is responsible for 2FA related actions like disabling 2FA through recovery codes.
   *
   * @param {Store<AppState>} store - The application-wide NgRx store for managing state.
   * @param {CustomToastService} toast - Service to display custom toast messages for errors and information.
   */
  constructor(
    private store: Store<AppState>,
    private toast: CustomToastService
  ) {}

  /**
   * Disables two-factor authentication for a user using their recovery code. This function validates the recovery data
   * using `disableTwoFactorAuthenticationByRecovery` validator before dispatching the disable action.
   * If the validation fails, it shows an error toast with the validation errors.
   *
   * @param {DisableTwoFactorAuthByRecovery} disableData - The data containing the user's recovery code needed to disable 2FA.
   * @return {void}
   */
  disableTwoFactorAuthenticationByRecovery(
    disableData: DisableTwoFactorAuthByRecovery
  ): void {
    const result =
      disableTwoFactorAuthenticationByRecovery.body.safeParse(disableData);

    if (result.success) {
      this.store.dispatch(
        TwoFactorAuthAction.disableTwoFactorAuthenticationByRecovery({
          disableData,
        })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
