<section class="container flex flex-column m-auto gap-3-5 px-3-5 xl:px-0">
  <p class="text-bold-base m-0">Latest jobs</p>
  <div class="flex gap-3-5 m-auto scrollable-section">
    <a
      *ngFor="let job of jobListings"
      href="#"
      class="flex flex-column justify-content-between p-4 border-round-2-5xl border-1 border-dark-10 cursor-pointer size h-26rem bg-light-100 no-underline">
      <div class="flex align-items-center justify-content-between">
        <img
          [src]="job.companyLogo"
          [alt]="job.companyName"
          height="35"
          width="35" />
        <ice-tag text="new" [color]="'var(--color-dark-10)'"></ice-tag>
      </div>
      <div class="flex flex-column gap-3-5">
        <p class="text-bold-base color-dark-70 m-0">{{ job.companyName }}</p>
        <h5 class="display-h5 color-dark-100 m-0">{{ job.jobTitle }}</h5>
        <p class="text-base color-dark-70 line-clamp-2 m-0">
          {{ job.description }}
        </p>
        <div class="flex gap-3-5">
          <ice-tag *ngFor="let tag of job.tags" [text]="tag"></ice-tag>
        </div>
      </div>
      <div class="flex flex-column border-top-1 border-dark-10 padding-custom">
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column">
            <p class="text-bold-sm m-0">{{ job.salary }}</p>
            <p class="text-base m-0">{{ job.location }}</p>
          </div>
          <ice-button label="Apply" [outlined]="true"></ice-button>
        </div>
      </div>
    </a>
  </div>
</section>
