import { Company } from '~/app/shared/interfaces/settings/company.interface';

/**
 * Represents the state of company-related data within the application.
 *
 * @interface CompanyState
 */
export interface CompanyState {
  /**
   * The company object or null if no company data is available.
   *
   * @type {Company | null}
   */
  company: Company | null;

  /**
   * Indicates whether company data is being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling company-related actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Provides the initial state for the company module.
 *
 * @type {CompanyState}
 */
export const initialCompanyState: CompanyState = {
  company: null,
  loading: false,
  error: null,
};
