import { inject } from '@angular/core';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { take } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { CheckPermissionReturn } from '~/app/shared/types/check-permission-return.type';

/**
 * Creates an RxJS operator to filter actions based on permissions.
 * It checks if the current user has permission to execute the action provided. If not,
 * it dispatches a failure action and displays an error toast.
 * This function is typically used in NgRx effects to ensure that the actions being processed are permitted.
 *
 * @param {ActionCreator} failureAction - A factory function that creates an action to be dispatched when the permission check fails.
 * This action typically carries an error message indicating the lack of authorization.
 * @returns {CheckPermissionReturn<T>} An RxJS OperatorFunction that filters the actions based on the permission check.
 * If the user has the required permission, it passes the action through; otherwise, it suppresses the action and triggers side effects (failure action and toast).
 *
 * @template T - The type of the action being checked. This must extend from TypedAction.
 */
export function checkPermission<T>(
  failureAction: ActionCreator<
    string,
    (props: { error: string }) => { error: string } & TypedAction<string>
  >
): CheckPermissionReturn<T> {
  const permissionService = inject(PermissionsService);
  const store = inject(Store);
  const toastService = inject(CustomToastService);
  // TODO: Rework like the registerAction operator

  let hasPermission = false;
  return filter(action => {
    permissionService
      .hasPermissionForAction(action.type)
      .pipe(take(1))
      .subscribe(permision => (hasPermission = permision));

    if (!hasPermission) {
      store.dispatch(failureAction({ error: 'Unauthorized' }));
      toastService.errorToast('Error', 'Unauthorized');
    }
    return hasPermission;
  });
}
