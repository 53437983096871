import { createSelector } from '@ngrx/store';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AuthState } from '~/app/auth/auth.state';

export const selectRegisterError = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectCompanyFormData = createSelector(
  selectAuthState,
  (state: AuthState) => state.companyFormData
);

// Selector for office form data
export const selectOfficeFormData = createSelector(
  selectAuthState,
  (state: AuthState) => state.officeFormData
);

// Selector for root user form data
export const selectRootUserFormData = createSelector(
  selectAuthState,
  (state: AuthState) => state.rootUserFormData
);
