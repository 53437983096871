import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/main/kanban/kanban.state';
import * as KanbansActions from '~/app/main/kanban/states/kanbans/kanbans.actions';
import {
  AssignKanbanUser,
  UnassignKanbanUser,
} from '~/app/main/kanban/types/kanbans.types';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { UtilsService } from '~/app/shared/services/utils.service';

/**
 * Service responsible for managing Kanban boards and their associated actions.
 * Provides methods to load, create, update, delete, assign users, and unassign users from Kanban boards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbansService {
  /**
   * Constructs a new KanbansService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   * @param {UtilsService} utils - Service for managing data format.
   */
  constructor(
    private store: Store<KanbanState>,
    private utils: UtilsService
  ) {}

  /**
   * Loads all Kanban boards.
   * Dispatches a loadAllKanbans action.
   *
   * @param {AllKanbansFilters} filters - Optionnal filters for all kanbans.
   * @returns {void}
   */
  loadAllKanbans(filters?: AllKanbansFilters): void {
    this.store.dispatch(
      KanbansActions.loadAllKanbans({
        filters: this.utils.filterValues(filters),
      })
    );
  }

  /**
   * Loads a Kanban board by its ID.
   * Dispatches a loadKanbanById action with the Kanban board ID.
   *
   * @param {string} uuid – The ID of the Kanban board.
   * @returns {void}
   */
  loadKanbanById(uuid: string): void {
    this.store.dispatch(KanbansActions.loadKanbanById({ uuid }));
  }

  /**
   * Creates a new Kanban board.
   * Dispatches a createKanban action with the Kanban board data.
   *
   * @param {FormGroup} kanbanControl – The form group containing the Kanban board data.
   * @returns {void}
   */
  createKanban(kanbanControl: FormGroup): void {
    const kanban = new FormData();

    kanban.append('name', kanbanControl.get('name')?.value);
    kanban.append('description', kanbanControl.get('description')?.value);
    kanban.append('backgroundUrl', kanbanControl.get('backgroundUrl')?.value);

    this.store.dispatch(KanbansActions.createKanban({ kanban }));
  }

  /**
   * Updates a Kanban board by its ID.
   * Dispatches an updateKanban action with the Kanban board ID and updated data.
   *
   * @param {string} uuid – The ID of the Kanban board.
   * @param {FormGroup} kanbanControl – The form group containing the updated Kanban board data.
   * @returns {void}
   */
  updateKanbanById(uuid: string, kanbanControl: FormGroup): void {
    const kanban = new FormData();

    kanban.append('name', kanbanControl.get('name')?.value);
    kanban.append('description', kanbanControl.get('description')?.value);
    kanban.append('backgroundUrl', kanbanControl.get('backgroundUrl')?.value);

    this.store.dispatch(
      KanbansActions.updateKanban({
        uuid,
        kanban,
      })
    );
  }

  /**
   * Deletes a Kanban board by its ID.
   * Dispatches a deleteKanban action with the Kanban board ID.
   *
   * @param {string} uuid – The ID of the Kanban board to delete.
   * @returns {void}
   */
  deleteKanbanById(uuid: string): void {
    this.store.dispatch(KanbansActions.deleteKanban({ uuid }));
  }

  /**
   * Assigns a user to a Kanban board.
   * Dispatches an assignKanbanUser action with the Kanban board ID and user data.
   *
   * @param {string} uuid – The ID of the Kanban board.
   * @param {AssignKanbanUser} assignKanbanUser – The data of the user to assign.
   * @returns {void}
   */
  assignUser(uuid: string, assignKanbanUser: AssignKanbanUser): void {
    this.store.dispatch(
      KanbansActions.assignKanbanUser({ uuid, assignKanbanUser })
    );
  }

  /**
   * Unassigns a user from a Kanban board.
   * Dispatches an unassignKanbanUser action with the Kanban board ID and user data.
   *
   * @param {string} uuid – The ID of the Kanban board.
   * @param {UnassignKanbanUser} unassignKanbanUser – The data of the user to unassign.
   * @returns {void}
   */
  unassignUser(uuid: string, unassignKanbanUser: UnassignKanbanUser): void {
    this.store.dispatch(
      KanbansActions.unassignKanbanUser({ uuid, unassignKanbanUser })
    );
  }

  /**
   * Resets the current Kanban state.
   * Dispatches a resetKanban action.
   *
   * @returns {void}
   */
  resetKanban(): void {
    this.store.dispatch(KanbansActions.resetKanban());
  }
}
