import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesInformationActions from '~/app/states/main/sales/profile/states/sales-informations/sales-informations.actions';
import { SalesInformation } from '~/app/shared/interfaces/sales/profile/sales-information.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesInformationEffects` manages the side effects for sales information-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales information, such as loading, creating, and updating sales information records.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesInformations$`: Loads the sales information associated with a specific sales record. It performs an HTTP GET request and dispatches a success action with the loaded sales information or a failure action if an error occurs.
 * — `createSalesInformation$`: Creates a new sales information record. It performs an HTTP POST request and dispatches a success action with the created sales information or a failure action if an error occurs.
 * — `createSalesInformationSuccess$`: Handles the success of creating a new sales information record by displaying a success toast message and closing the sidebar.
 * — `updateSalesInformation$`: Updates an existing sales information record. It performs an HTTP PATCH request and dispatches a success action with the updated sales information or a failure action if an error occurs.
 * — `updateSalesInformationSuccess$`: Handles the success of updating a sales information record by displaying a success toast message and closing the sidebar.
 * — `deleteSalesInformation$`: Deletes a sales information record. It performs an HTTP DELETE request and dispatches a success action or a failure action if an error occurs.
 * — `deleteSalesInformationSuccess$`: Handles the success of deleting a sales information record by displaying a success toast message and closing the sidebar.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesInformationEffects {
  /**
   * Effect to load sales information associated with a specific sales record.
   * It listens for the `loadSalesInformations` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales information or a failure action if an error occurs.
   */
  loadSalesInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesInformationActions.loadSalesInformations),
      checkPermission(SalesInformationActions.salesInformationsUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<SalesInformation[]>(
            `${environment.apiUrl}/v1/sales/information`,
            {
              params: { saleId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(salesInformations =>
              SalesInformationActions.loadSalesInformationsSuccess({
                salesInformations,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesInformationActions.loadSalesInformationsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales information record.
   * It listens for the `createSalesInformation` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales information or a failure action if an error occurs.
   */
  createSalesInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesInformationActions.createSalesInformation),
      checkPermission(SalesInformationActions.salesInformationsUnauthorized),
      mergeMap(({ salesInformation }) =>
        this.http
          .post<SalesInformation>(
            `${environment.apiUrl}/v1/sales/information`,
            salesInformation,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesInformation =>
              SalesInformationActions.createSalesInformationSuccess({
                salesInformation,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesInformationActions.createSalesInformationFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new sales information record.
   * It displays a success toast message and closes the sidebar.
   */
  createSalesInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesInformationActions.createSalesInformationSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales information created');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing sales information record.
   * It listens for the `updateSalesInformation` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful update, it dispatches a success action with the updated sales information or a failure action if an error occurs.
   */
  updateSalesInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesInformationActions.updateSalesInformation),
      checkPermission(SalesInformationActions.salesInformationsUnauthorized),
      mergeMap(({ uuid, salesInformation }) =>
        this.http
          .patch<SalesInformation>(
            `${environment.apiUrl}/v1/sales/information/${uuid}`,
            salesInformation,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesInformation =>
              SalesInformationActions.updateSalesInformationSuccess({
                salesInformation,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesInformationActions.updateSalesInformationFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a sales information record.
   * It displays a success toast message and closes the sidebar.
   */
  updateSalesInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesInformationActions.updateSalesInformationSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales information updated');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sales information record.
   * It listens for the `deleteSalesInformation` action, checks for permissions, and performs an HTTP DELETE request.
   * Upon successful deletion, it dispatches a success action or a failure action if an error occurs.
   */
  deleteSalesInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesInformationActions.deleteSalesInformation),
      checkPermission(SalesInformationActions.salesInformationsUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .delete<SalesInformation>(
            `${environment.apiUrl}/v1/sales/information/${uuid}`,
            {
              body: { saleId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              SalesInformationActions.deleteSalesInformationSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesInformationActions.deleteSalesInformationFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a sales information record.
   * It displays a success toast message and closes the sidebar.
   */
  deleteSalesInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesInformationActions.deleteSalesInformationSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales information deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesInformationEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService — Injectable service for managing the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
