import { createReducer, on } from '@ngrx/store';
import { initialProfileClientCompaniesState } from '~/app/states/main/crm/profile/states/client-companies/profile-client-companies.states';
import * as ClientCompanyActions from '~/app/states/main/crm/profile/states/client-companies/profile-client-companies.actions';

export const profileClientCompagniesReducer = createReducer(
  initialProfileClientCompaniesState,

  on(ClientCompanyActions.loadAllClientCompanies, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyActions.loadAllClientCompaniesSuccess,
    (state, { allClientCompanies }) => ({
      ...state,
      ...allClientCompanies,
      loading: false,
    })
  ),
  on(
    ClientCompanyActions.loadAllClientCompaniesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanyActions.clientCompanyUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
