import { Component, Input } from '@angular/core';
import { SidenavItem } from '~/app/shared/interfaces/navigation/sidenav/sidenav-item.interface';

/**
 * Component that renders a navigational item or a set of items for a sidebar. These items can adapt
 * dynamically to the state of the application and user interactions, supporting responsive and
 * interactive design.
 *
 * @Component decorator provides metadata for the component:
 * @selector 'app-sidenav-item' - CSS selector for using this component within HTML templates.
 * @templateUrl './sidenav-item.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./sidenav-item.component.scss'] - Path to the styles specific to this component.
 */
@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
})
export class SidenavItemComponent {
  /**
   * Array of sidenav items to be displayed.
   * @type {SidenavItem[]}
   */
  @Input() items!: SidenavItem[];
}
