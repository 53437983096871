import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as IndustryActions from '~/app/states/main/settings/states/industries/industries.actions';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `IndustriesEffects` is responsible for managing side effects related to industry data in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, assigning, and unassigning industries from and to the server.
 *
 * Effects included in this class:
 * — `loadIndustries$`: Fetches all industries and the selected industries from the server.
 * — `assignIndustry$`: Assigns an industry to a category on the server.
 * — `assignIndustrySuccess$`: Handles successful industry assignments.
 * — `unassignIndustry$`: Unassigns an industry from a category on the server.
 * — `unassignIndustrySuccess$`: Handles successful industry unassignments.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class IndustriesEffects {
  /**
   * Effect to load all industries and selected industries from the server.
   * @returns An observable of the action to dispatch on success or failure.
   */
  loadIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndustryActions.loadIndustries),
      checkPermission(IndustryActions.industryUnauthorized),
      mergeMap(() =>
        forkJoin({
          availableIndustries: this.http.get<Industry[]>(
            `${environment.apiUrl}/v1/industries/all`
          ),
          selectedIndustries: this.http.get<Industry[]>(
            `${environment.apiUrl}/v1/industries/`
          ),
        }).pipe(
          take(1),
          map(({ availableIndustries, selectedIndustries }) =>
            IndustryActions.loadIndustriesSuccess({
              availableIndustries,
              selectedIndustries,
            })
          ),
          catchError(() =>
            of(
              IndustryActions.loadIndustriesFailure({
                error: 'Failed to load industries',
              })
            )
          )
        )
      )
    )
  );

  /**
   * Effect to assign an industry to a category on the server.
   * @returns An observable of the action to dispatch on success or failure.
   */
  assignIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndustryActions.assignIndustry),
      checkPermission(IndustryActions.industryUnauthorized),
      mergeMap(({ industryData }) => {
        return this.http
          .patch<
            Industry[]
          >(`${environment.apiUrl}/v1/industries/assign`, industryData)
          .pipe(
            take(1),
            map(industries =>
              IndustryActions.assignIndustrySuccess({ industries })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(IndustryActions.assignIndustryFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect to handle successful industry assignments.
   * @returns An observable that triggers a success toast message.
   */
  assignIndustrySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndustryActions.assignIndustrySuccess),
        tap(() => {
          this.toast.successToast('Success', 'Industry assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign an industry from a category on the server.
   * @returns An observable of the action to dispatch on success or failure.
   */
  unassignIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndustryActions.unassignIndustry),
      checkPermission(IndustryActions.industryUnauthorized),
      mergeMap(({ industryData }) =>
        this.http
          .patch<
            Industry[]
          >(`${environment.apiUrl}/v1/industries/unassigned`, industryData)
          .pipe(
            take(1),
            map(industries =>
              IndustryActions.unassignIndustrySuccess({ industries })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(IndustryActions.unassignIndustryFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful industry unassignments.
   * @returns An observable that triggers a success toast message.
   */
  unassignIndustrySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndustryActions.unassignIndustrySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Industry unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of IndustriesEffects.
   *
   * @param {Actions} actions$ - The injected NgRx Actions service.
   * @param {HttpClient} http - The injected HttpClient service.
   * @param {CustomToastService} toast - The injected CustomToastService.
   * @param {HttpErrorsService} httpErrors - The injected HttpErrorsService.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toast: CustomToastService,
    private httpErrors: HttpErrorsService
  ) {}
}
