import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { primeFlexBreakpoints } from '~/app/shared/const/breakpoints.const';
import { SidebarHostDirective } from '~/app/shared/directives/sidebar-host.directive';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';

/**
 * Component responsible for rendering a sidebar that dynamically adjusts based on the viewport's size and can host various forms or additional content.
 * It uses Angular CDK's BreakpointObserver to apply responsiveness, ensuring that the sidebar's behavior and appearance are optimal across different devices.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-sidebar' - The CSS selector that identifies this component in a template.
 * @templateUrl './sidebar.component.html' - The path to the HTML template for the component.
 * @styleUrls ['./sidebar.component.scss'] - The path to the styles for this component.
 * @encapsulation ViewEncapsulation.None - Disables Angular's style encapsulation to allow styles to be applied globally.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  /**
   * Accesses the SidebarHostDirective to manage dynamic content loading into the sidebar.
   */
  @ViewChild(SidebarHostDirective, { static: true })
  sidebarHost!: SidebarHostDirective;

  /**
   * Controls whether the sidebar is rendered in full screen based on viewport size.
   */
  fullScreen = false;

  /**
   * Subscription to manage the cleanup of the breakpoint observation to prevent memory leaks.
   */
  private breakpointSubscription: Subscription | undefined;

  /**
   * Constructs the SidebarComponent with necessary dependencies.
   *
   * @param {SidenavFormService} sidebarService - Service used to manage the state and content of the sidebar including titles, subtitles, and visibility.
   * @param {BreakpointObserver} breakpointObserver - Angular CDK service used to monitor changes in screen size and apply responsive behavior based on breakpoints.
   */
  constructor(
    public sidebarService: SidenavFormService,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * Fetches the title from the sidebar service, dynamically reflecting changes.
   */
  public get title(): string {
    return this.sidebarService.title;
  }

  /**
   * Fetches the subtitle from the sidebar service, dynamically reflecting changes.
   */
  public get subtitle(): string {
    return this.sidebarService.subtitle;
  }

  /**
   * Controls the visibility of the sidebar, using a getter to retrieve the state and a setter to modify it,
   * encapsulating the visibility logic within the sidebar service.
   */
  public get isVisible(): boolean {
    return this.sidebarService.sidebarVisible;
  }

  /**
   * Setter for the visibility of the sidebar. It delegates the control of the sidebar's visibility
   * to the SidenavFormService, allowing the sidebar to be shown or hidden as dictated by user interactions
   * or other application logic. This abstraction ensures that the sidebar's visibility can be managed
   * centrally from the service, supporting a cleaner and more maintainable architecture.
   *
   * @param {boolean} value - A boolean value indicating whether the sidebar should be visible (true) or hidden (false).
   */
  public set isVisible(value: boolean) {
    this.sidebarService.setSidebarVisibility(value);
  }

  /**
   * OnInit lifecycle hook to set up the sidebar's content and initiate responsiveness handling.
   * @return {void}
   */
  ngOnInit(): void {
    this.sidebarService.setViewContainerRef(this.sidebarHost.viewContainerRef);
    this.observeBreakpoints();
  }

  /**
   * Observes the screen size using predefined breakpoints and adjusts the sidebar's fullscreen mode accordingly.
   * This method ensures the sidebar is responsive and suitable for the current view state.
   * @return {void}
   */
  observeBreakpoints(): void {
    this.breakpointSubscription = this.breakpointObserver
      .observe([
        primeFlexBreakpoints.sm,
        primeFlexBreakpoints.md,
        primeFlexBreakpoints.lg,
        primeFlexBreakpoints.xl,
      ])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          const breakpoint = Object.entries(primeFlexBreakpoints).find(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
            ([key, value]) => result.breakpoints[value]
          );

          if (breakpoint) {
            const [breakpointName] = breakpoint;
            switch (breakpointName) {
              case 'sm':
                this.fullScreen = true;
                break;

              case 'md':
                this.fullScreen = true;
                break;

              default:
                this.fullScreen = false;
                break;
            }
          }
        }
      });
  }
}
