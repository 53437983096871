import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import * as HomeUsersActions from '~/app/states/main/sales/home/states/home-users/home-users.actions';
import { environment } from '~/environments/environment';
import { User } from '~/app/shared/interfaces/auth/user.interface';

/**
 * `HomeUsersEffects` orchestrates the handling of asynchronous operations related to home Users in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to home Users, such as fetching data from the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadHomeUser$`: Fetches home Users from the server.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class HomeUsersEffects {
  /**
   * Effect to load home Users.
   */
  loadHomeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeUsersActions.loadHomeUsersAction),
      checkPermission(HomeUsersActions.homeUsersUnauthorized),
      mergeMap(filters =>
        this.http
          .get<PaginationData<User>>(`${environment.apiUrl}/v1/users`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(({ data }) =>
              HomeUsersActions.loadHomeUsersActionSuccess({ users: data })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(HomeUsersActions.loadHomeUsersActionFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for HomeUsersEffects.
   *
   * @param {Actions} actions$ — The stream of actions observed by this effect.
   * @param {HttpClient} http — The HTTP client used for making API calls.
   * @param {HttpErrorsService} httpErrors — Service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
