<div class="w-full relative">
  <ul class="list-none m-0 p-0 flex w-full">
    <li
      class="flex-1 flex justify-content-center align-items-center cursor-pointer"
      (click)="selectTab(tab.value)"
      [ngClass]="{
        'color-light-100': tab.value === selectedTab,
        'color-dark-70': tab.value !== selectedTab,
      }"
      *ngFor="let tab of tabs">
      {{ tab.label }}
    </li>
  </ul>
  <div
    class="tab-indicator"
    [ngStyle]="{
      'width.px': tabWidth,
      'left.px': tabLeft,
      'height.px': tabHeight,
    }"></div>

  <ng-container *ngIf="currentTemplate">
    <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
  </ng-container>
</div>
