import { createReducer, on } from '@ngrx/store';
import { initialUsersState } from '~/app/states/main/settings/states/users/users.state';
import * as UserActions from '~/app/states/main/settings/states/users/users.actions';

export const usersReducer = createReducer(
  initialUsersState,

  on(UserActions.loadUsers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.loadUsersSuccess, (state, { users }) => ({
    ...state,
    ...users,
    loading: false,
  })),
  on(UserActions.loadUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(UserActions.updateUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === user.uuid ? user : item)),
    loading: false,
  })),
  on(UserActions.updateUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(UserActions.inviteUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.inviteUserSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(UserActions.inviteUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(UserActions.assignGroupToUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.assignGroupToUserSuccess, (state, { user }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === user.uuid ? user : item)),
    loading: false,
  })),
  on(UserActions.assignGroupToUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UserActions.unassignGroupToUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.unassignGroupToUserSuccess, (state, { user }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === user.uuid ? user : item)),
    loading: false,
  })),
  on(UserActions.unassignGroupToUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UserActions.userUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(UserActions.activateUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.activateUserSuccess, (state, { user }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === user.uuid ? user : item)),
    loading: false,
  })),
  on(UserActions.activateUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(UserActions.deactivateUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.deactivateUserSuccess, (state, { user }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === user.uuid ? user : item)),
    loading: false,
  })),
  on(UserActions.deactivateUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
