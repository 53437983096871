import { Component } from '@angular/core';
import { Location } from '@angular/common';

/**
 * `UnauthorizedComponent` is designed to display a message when a user attempts to access a restricted area without proper authorization.
 * It provides a user interface to inform the user of the unauthorized attempt and includes a method to navigate back to the previous page.
 *
 * @selector 'app-unauthorized' - This selector is used to embed this component into other components or templates.
 * @templateUrl './unauthorized.component.html' - Contains the HTML template for this component, including the layout and elements for displaying the unauthorized access message.
 * @styleUrls ['./unauthorized.component.scss'] - Defines the SCSS styling for this component, specifying the visual design for the unauthorized message such as font, color, and layout.
 *
 * @example
 * Example of usage:
 * The component can be placed in routes where unauthorized access is detected:
 * { path: 'unauthorized', component: UnauthorizedComponent }
 */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  /**
   * Constructs the UnauthorizedComponent with dependency injection for Angular's Location service.
   * @param {Location} location - The Location service injected into the component for navigation.
   */
  constructor(private location: Location) {}

  /**
   * Navigates back to the previous page.
   * @return {void}
   */
  goBack(): void {
    this.location.back();
  }
}
