import { createAction, props } from '@ngrx/store';
import {
  CreateOffer,
  UpdateOffer,
} from '~/app/states/main/jobhub/types/offers.types';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

export const createOffer = createAction(
  '[Offers] Create Offer',
  props<{ offerData: CreateOffer }>()
);
export const createOfferSuccess = createAction(
  '[Offers] Create Offer Success',
  props<{ offer: Offer }>()
);
export const createOfferFailure = createAction(
  '[Offers] Create Offer Failure',
  props<{ error: string }>()
);

export const updateOffer = createAction(
  '[Offers] Update Offer',
  props<{ uuid: string; offerData: UpdateOffer }>()
);
export const updateOfferSuccess = createAction(
  '[Offers] Update Offer Success',
  props<{ offer: Offer }>()
);
export const updateOfferFailure = createAction(
  '[Offers] Update Offer Failure',
  props<{ error: string }>()
);

export const offersUnauthorized = createAction(
  '[Offers] Unauthorized',
  props<{ error: string }>()
);
