import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';

/**
 * Represents the state of profile reminders.
 *
 * @interface ProfileRemindersState
 */
export interface ProfileRemindersState {
  /**
   * An array of profile reminders.
   *
   * @type {Reminder[]}
   */
  profileReminders: Reminder[];

  /**
   * Indicates if the profile reminders are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile reminders.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile reminders within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileRemindersState} initialProfileRemindersState
 * @description Provides a baseline definition for the profile reminders context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileRemindersState: ProfileRemindersState = {
  profileReminders: [],
  loading: false,
  error: null,
};
