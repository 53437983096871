import { createReducer, on } from '@ngrx/store';
import { initialAllKanbansState } from '~/app/states/main/kanban/states/kanbans/kanbans.state';
import * as KanbansActions from '~/app/states/main/kanban/states/kanbans/kanbans.actions';

export const allKanbansReducer = createReducer(
  initialAllKanbansState,

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/
  on(KanbansActions.loadAllKanbans, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.loadAllKanbansSuccess, (state, { kanbans }) => ({
    ...state,
    ...kanbans,
    loading: false,
  })),
  on(KanbansActions.loadAllKanbansFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbansActions.loadKanbanById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.loadKanbanByIdSuccess, (state, { kanban }) => ({
    ...state,
    kanban,
    loading: false,
  })),
  on(KanbansActions.loadKanbanByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/
  on(KanbansActions.createKanban, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.createKanbanSuccess, (state, { kanban }) => ({
    ...state,
    data: [...state.data, kanban],
    loading: false,
  })),
  on(KanbansActions.createKanbanFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbansActions.updateKanban, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.updateKanbanSuccess, (state, { kanban }) => ({
    ...state,
    data: state.data.map(item => (item.uuid === kanban.uuid ? kanban : item)),
    loading: false,
  })),
  on(KanbansActions.updateKanbanFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbansActions.deleteKanban, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.deleteKanbanSuccess, (state, { uuid }) => ({
    ...state,
    data: state.data.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(KanbansActions.deleteKanbanFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * ASSIGN/UNASSIGN USER
   * ************************************************************************
   **/
  on(KanbansActions.assignKanbanUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbansActions.assignKanbanUserSuccess,
    KanbansActions.assignKanbanUserServer,
    (state, { kanban }) => ({
      ...state,
      kanban,
      loading: false,
    })
  ),
  on(KanbansActions.assignKanbanUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(KanbansActions.unassignKanbanUser, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    KanbansActions.unassignKanbanUserSuccess,
    KanbansActions.unassignKanbanUserServer,
    (state, { kanban }) => ({
      ...state,
      kanban,
      loading: false,
    })
  ),
  on(KanbansActions.unassignKanbanUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * ************************************************************************
   * SSE SERVER CONNECTION
   * ************************************************************************
   **/

  on(KanbansActions.connectToChannel, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.connectToChannelSuccess, state => ({
    ...state,
    connected: true,
    loading: false,
  })),
  on(KanbansActions.connectToChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    connected: false,
    error: error,
  })),
  on(KanbansActions.disconnectFromChannel, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(KanbansActions.disconnectFromChannelSuccess, state => ({
    ...state,
    connected: false,
    token: '',
    loading: false,
  })),
  on(KanbansActions.disconnectFromChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  /**
   * ************************************************************************
   * RESET KANBAN
   * ************************************************************************
   **/
  on(KanbansActions.resetKanban, state => ({
    ...state,
    kanban: null,
  })),

  /**
   * ************************************************************************
   * UNAUTHORIZED
   * ************************************************************************
   **/
  on(KanbansActions.kanbansUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
