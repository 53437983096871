import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { Teams } from '@npmicedev/icemodule/lib/entities/Teams';
import { Users } from '@npmicedev/icemodule/lib/entities/Users';
import { GoalResponse } from '~/app/states/main/stats/types/goal-response.types';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

/**
 * GoalsStates interface represents the state structure for goals in the application.
 * It encapsulates various pieces of information related to goals, including loading states,
 * lists of users and teams, and selected goal details.
 */
export interface GoalsStates {
  /**
   * The state of pinned goals, represented as paginated goal responses.
   * @type {PaginationData<GoalResponse> | null}
   */
  pinned: PaginationData<GoalResponse> | null;

  /**
   * The state of daily goals, represented as paginated goal responses.
   * @type {PaginationData<GoalResponse> | null}
   */
  daily: PaginationData<GoalResponse> | null;

  /**
   * The state of weekly goals, represented as paginated goal responses.
   * @type {PaginationData<GoalResponse> | null}
   */
  weekly: PaginationData<GoalResponse> | null;

  /**
   * The state of monthly goals, represented as paginated goal responses.
   * @type {PaginationData<GoalResponse> | null}
   */
  monthly: PaginationData<GoalResponse> | null;

  /**
   * The currently selected goal, represented by the Goals entity.
   * @type {Goals | null}
   */
  selectedGoal: Goals | null;

  /**
   * List of users associated with the goals.
   * @type {Users[]}
   */
  users: Users[];

  /**
   * List of teams associated with the goals.
   * @type {Teams[]}
   */
  teams: Teams[];

  /**
   * Indicates whether data is currently being loaded.
   * @type {boolean}
   */
  isLoading: boolean;

  /**
   * Error message, if any, related to goals operations.
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for goals, providing default values for all properties.
 * @type {GoalsStates}
 */
export const initialGoalsStates: GoalsStates = {
  pinned: null,
  daily: null,
  weekly: null,
  monthly: null,
  selectedGoal: null,
  users: [],
  teams: [],
  isLoading: false,
  error: null,
};
