import { createReducer, on } from '@ngrx/store';
import { initialSalesTagsState } from '~/app/states/main/settings/states/tags/sales-tags-state/sales-tags.state';
import * as SalesTagActions from '~/app/states/main/settings/states/tags/sales-tags-state/sales-tags.actions';

export const salesTagsReducer = createReducer(
  initialSalesTagsState,

  on(SalesTagActions.loadSalesTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesTagActions.loadSalesTagsSuccess, (state, { salesTags }) => ({
    ...state,
    salesTags,
    loading: false,
  })),
  on(SalesTagActions.loadSalesTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesTagActions.createSalesTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesTagActions.createSalesTagSuccess, (state, { salesTag }) => ({
    ...state,
    salesTags: [...state.salesTags, salesTag],
    loading: false,
  })),
  on(SalesTagActions.createSalesTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesTagActions.updateSalesTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesTagActions.updateSalesTagSuccess, (state, { salesTag }) => ({
    ...state,
    salesTags: state.salesTags.map(tag =>
      tag.uuid === salesTag.uuid ? { ...tag, ...salesTag } : tag
    ),
    loading: false,
  })),
  on(SalesTagActions.updateSalesTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesTagActions.deleteSalesTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesTagActions.deleteSalesTagSuccess, (state, { uuid }) => ({
    ...state,
    salesTags: state.salesTags.filter(tag => tag.uuid !== uuid),
    loading: false,
  })),
  on(SalesTagActions.deleteSalesTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesTagActions.salesTagUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
