import { createAction, props } from '@ngrx/store';
import {
  AssignProfileSector,
  UnassignedProfileSector,
} from '~/app/states/main/crm/profile/types/profile-sectors.types';
import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';

export const loadProfileSectors = createAction(
  '[Profile Sector Jobs] Load Profile Sectors',
  props<{ profileId: string }>()
);
export const loadProfileSectorsSuccess = createAction(
  '[Profile Sector Jobs] Load Profile Sectors Success',
  props<{ profileSectors: SectorJobs[] }>()
);
export const loadProfileSectorsFailure = createAction(
  '[Profile Sector Jobs] Load Profile Sectors Failure',
  props<{ error: string }>()
);

export const unassignProfileSector = createAction(
  '[Profile Sector Jobs] Unassign Company Sector Job',
  props<{ profileId: string; profileSector: UnassignedProfileSector }>()
);
export const unassignProfileSectorSuccess = createAction(
  '[Profile Sector Jobs] Unassign Company Sector Job Success',
  props<{ profileSectors: SectorJobs[] }>()
);
export const unassignProfileSectorFailure = createAction(
  '[Profile Sector Jobs] Unassign Company Sector Job Failure',
  props<{ error: string }>()
);

export const assignProfileSectorJob = createAction(
  '[Profile Sector Jobs] Assign Profile Sector Job',
  props<{ profileId: string; profileSector: AssignProfileSector }>()
);
export const assignProfileSectorJobSuccess = createAction(
  '[Profile Sector Jobs] Assign Profile Sector Job Success',
  props<{ profileSectors: SectorJobs[] }>()
);
export const assignProfileSectorJobFailure = createAction(
  '[Profile Sector Jobs] Assign Profile Sector Job Failure',
  props<{ error: string }>()
);

export const profileSectorsUnauthorized = createAction(
  '[Profile Sector Jobs] Unauthorized',
  props<{ error: string }>()
);
