import { createAction, props } from '@ngrx/store';
import { CreateProfileSocialNetwork } from '~/app/states/main/crm/profile/types/profile-socials.types';
import { SocialNetwork } from '~/app/shared/interfaces/shared/social-network.interface';

export const loadSocialNetworks = createAction(
  '[Profile Social Networks] Load Social Networks',
  props<{ profileId: string }>()
);
export const loadSocialNetworksSuccess = createAction(
  '[Profile Social Networks] Load Social Networks Success',
  props<{ socialNetworks: SocialNetwork[] }>()
);
export const loadSocialNetworksFailure = createAction(
  '[Profile Social Networks] Load Social Networks Failure',
  props<{ error: string }>()
);

export const createSocialNetwork = createAction(
  '[Profile Social Networks] Create Social Network',
  props<{ profileSocialNetwork: CreateProfileSocialNetwork }>()
);
export const createSocialNetworkSuccess = createAction(
  '[Profile Social Networks] Create Social Network Success',
  props<{ socialNetwork: SocialNetwork }>()
);
export const createSocialNetworkFailure = createAction(
  '[Profile Social Networks] Create Social Network Failure',
  props<{ error: string }>()
);

export const updateSocialNetwork = createAction(
  '[Profile Social Networks] Update Social Network',
  props<{ socialNetwork: SocialNetwork }>()
);
export const updateSocialNetworkSuccess = createAction(
  '[Profile Social Networks] Update Social Network Success',
  props<{ socialNetwork: SocialNetwork }>()
);
export const updateSocialNetworkFailure = createAction(
  '[Profile Social Networks] Update Social Network Failure',
  props<{ error: string }>()
);

export const deleteSocialNetwork = createAction(
  '[Profile Social Networks] Delete Social Network',
  props<{ profileId: string; uuid: string }>()
);
export const deleteSocialNetworkSuccess = createAction(
  '[Profile Social Networks] Delete Social Network Success',
  props<{ uuid: string }>()
);
export const deleteSocialNetworkFailure = createAction(
  '[Profile Social Networks] Delete Social Network Failure',
  props<{ error: string }>()
);

export const profileSocialNetworksUnauthorized = createAction(
  '[Profile Social Networks] Unauthorized',
  props<{ error: string }>()
);
