/* eslint-disable max-lines */
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  Observable,
  skipWhile,
  startWith,
  take,
  tap,
} from 'rxjs';
import { Goals } from '@npmicedev/icemodule/lib/entities/Goals';
import { map } from 'rxjs/operators';
import { GoalScope } from '@npmicedev/icemodule/lib/enums/goalScope';
import IceRule from '@npmicedev/icemodule/lib/utils/rules';
import { Target } from '@npmicedev/icemodule/lib/enums/target';
import { GoalType } from '@npmicedev/icemodule/lib/enums/goalType';
import { GoalsStates } from '~/app/states/main/stats/states/goals/goals.states';
import { GoalsService } from '~/app/states/main/stats/services/goals.service';
import {
  selectGoalTeams,
  selectGoalUsers,
  selectIsLoading,
  selectSelectedGoal,
} from '~/app/states/main/stats/states/goals/goals.selectors';
import { AlertService } from '~/app/shared/services/alert.service';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import {
  selectGoalTypes,
  selectProfileFiles,
  selectProfileTypes,
  selectRates,
  selectTargets,
} from '~/app/core/state/enum/enums.selectors';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { AuthCompany } from '~/app/shared/interfaces/auth/auth-company.interface';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { DataService } from '~/app/shared/services/data.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { IceCompleteEvent } from '~/app/shared/interfaces/shared/pipe/ice-complete-event.interface';

/**
 * CudGoalComponent manages the creation, updating, and deletion of goals.
 * It provides a form for users to input goal details and leverages an NgRx store for state management.
 *
 * @Component
 * @selector 'app-cud-goal' — CSS selector that identifies this component in a template.
 * @templateUrl './cud-goal.component.html' — Path to the HTML template for this component.
 * @styleUrl './cud-goal.component.scss' — Path to the stylesheet for this component.
 */
@Component({
  selector: 'app-cud-goal',
  templateUrl: './cud-goal.component.html',
  styleUrls: ['./cud-goal.component.scss'],
})
export class CudGoalComponent implements OnInit {
  /**
   * Universal Unique Identifier for the goal.
   * @type {string | undefined}
   */
  @Input() goalUUID: string | undefined;

  /**
   * Observable that emits the selected goal detais.
   * @type {Observable<Goals | null>}
   */
  goal$: Observable<Goals | null>;

  /**
   * Observable that emits a boolean indicating whether data is currently being loaded.
   * @type {Observable<boolean>}
   */
  isLoading$: Observable<boolean>;

  /**
   * Observable that emits the available goal types.
   * @type {Observable<LabelValueInterface[]>}
   */
  goalsTypes$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available targets.
   * @type {Observable<LabelValueInterface[]>}
   */
  targets$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available rates.
   * @type {Observable<LabelValueInterface[]>}
   */
  rates$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available teams for goal assignment.
   * @type {Observable<LabelValueInterface[]>}
   */
  teams$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available users for goal assignment.
   * @type {Observable<LabelValueInterface[]>}
   */
  users$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the company information of the current user.
   * @type {Observable<AuthCompany | undefined>}
   */
  company$: Observable<AuthCompany | undefined>;

  /**
   * Observable that emits the statuses of profiles.
   * @type {Observable<LabelValueInterface[]>}
   */
  profilesStatuses$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the new profiles statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  newProfilesStatuses$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the old profiles statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  oldProfilesStatuses$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the statuses of sales.
   * @type {Observable<LabelValueInterface[]>}
   */
  salesStatuses$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the new sales statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  newSalesStatuses$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the old sales statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  oldSalesStatuses$!: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available document types.
   * @type {Observable<LabelValueInterface[]>}
   */
  documentTypes$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits the available profile types.
   * @type {Observable<LabelValueInterface[]>}
   */
  profileTypes$: Observable<LabelValueInterface[]>;

  /**
   * FormGroup that encapsulates the form controls for goal creation and management.
   * @type {FormGroup}
   */
  goalForm!: FormGroup;

  /**
   * Observable that emits a boolean indicating if the light theme is active.
   * @type {Observable<boolean>}
   */
  isLightTheme$: Observable<boolean>;

  /**
   * Array of scopes available for goal setting.
   * @type {{ label: string, value: GoalScope }[]}
   */
  scopes = [
    { label: 'Company', value: GoalScope.Company },
    { label: 'Team', value: GoalScope.Team },
    { label: 'User', value: GoalScope.User },
  ];

  /**
   * Array to hold filtered scope options.
   * @type {any[]}
   */
  filteredScope: any[] = [];

  /**
   * Reference to the GoalScope enum.
   * @type {typeof GoalScope}
   */
  protected readonly goalScope = GoalScope;

  /**
   * Reference to the IceAutocompleteTemplateEnum enum.
   * @type {typeof IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Reference to the IceRule utility.
   * @type {typeof IceRule}
   */
  protected readonly iceRule = IceRule;

  /**
   * Reference to the GoalScope Enum
   * @type {typeof GoalScope}
   *  */
  protected readonly goalScopeEnum = GoalScope;

  /**
   * Reference to the GoalType Enum
   * @type {typeof GoalType}
   */
  protected readonly goalTypeEnum = GoalType;

  /**
   * Reference to the IceAutocompleteTemplateEnum
   * @type {typeof IceAutocompleteTemplateEnum}
   */
  protected readonly iceAutocompleteTemplateEnum = IceAutocompleteTemplateEnum;

  /**
   * Constructor for CudGoalComponent.
   *
   * Initializes necessary services for state management and goal handling.
   *
   * @param {Store<GoalsStates>} store — Provides access to the goals state in the application.
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {GoalsService} goalService — Service to manage goal-related actions.
   * @param {ThemeService} theme - Service to get information about the current theme
   * @param {DataService} data - Service to handle some data it need to BE DOWN
   */
  constructor(
    private store: Store<GoalsStates>,
    private formBuilder: FormBuilder,
    private goalService: GoalsService,
    private theme: ThemeService,
    private alertService: AlertService,
    private data: DataService
  ) {
    this.isLightTheme$ = this.theme.isDarkThemeSubject.asObservable();
    this.goal$ = this.store.select(selectSelectedGoal);
    this.targets$ = this.store.select(selectTargets);
    this.rates$ = this.store.select(selectRates);
    this.isLoading$ = this.store.select(selectIsLoading);
    this.documentTypes$ = this.store.select(selectProfileFiles);
    this.profileTypes$ = this.store.select(selectProfileTypes);

    this.profilesStatuses$ = this.data.candidateStatuses$.pipe(
      map(statuses =>
        statuses.map(status => ({
          label: status.name,
          value: status.uuid,
          color: status.color,
        }))
      )
    );

    this.salesStatuses$ = this.data.salesStatuses$.pipe(
      map(statuses =>
        statuses.map(status => ({
          label: status.name,
          value: status.uuid,
          color: status.color,
        }))
      )
    );

    this.users$ = this.store.select(selectGoalUsers).pipe(
      skipWhile(items => !items),
      map(users => {
        return users.map(user => {
          return {
            ...user,
            label: user.firstname + ' ' + user.lastname,
            value: user.uuid,
          };
        });
      })
    );

    this.teams$ = this.store.select(selectGoalTeams).pipe(
      skipWhile(items => !items),
      map(teams => teams.map(team => ({ label: team.name, value: team.uuid })))
    );

    this.company$ = this.store
      .select(selectCurrentUser)
      .pipe(map(user => user?.company));
  }

  /**
   * Getter for the 'name' FormControl.
   * @type {FormControl}
   */
  get name(): FormControl {
    return this.goalForm.get('name') as FormControl;
  }

  /**
   * Getter for the 'target' FormControl.
   * @type {FormControl}
   */
  get target(): FormControl {
    return this.goalForm.get('target') as FormControl;
  }

  /**
   * Getter for the 'goalType' FormControl.
   * @type {FormControl}
   */
  get goalType(): FormControl {
    return this.goalForm.get('goalType') as FormControl;
  }

  /**
   * Getter for the 'goal' FormControl.
   * @type {FormControl}
   */
  get goal(): FormControl {
    return this.goalForm.get('goal') as FormControl;
  }

  /**
   * Getter for the 'rate' FormControl.
   * @type {FormControl}
   */
  get rate(): FormControl {
    return this.goalForm.get('rate') as FormControl;
  }

  /**
   * Getter for the 'scope' FormControl.
   * @type {FormControl}
   */
  get scope(): FormControl {
    return this.goalForm.get('scope') as FormControl;
  }

  /**
   * Getter for the 'teamId' FormControl.
   * @type {FormControl}
   */
  get teamId(): FormControl {
    return this.goalForm.get('teamId') as FormControl;
  }

  /**
   * Getter for the 'userId' FormControl.
   * @type {FormControl}
   */
  get userId(): FormControl {
    return this.goalForm.get('userId') as FormControl;
  }

  /**
   * Getter for the 'newStatus' FormControl.
   * @type {FormControl}
   */
  get newStatus(): FormControl {
    return this.goalForm.get('newStatus') as FormControl;
  }

  /**
   * Getter for the 'oldStatus' FormControl.
   * @type {FormControl}
   */
  get oldStatus(): FormControl {
    return this.goalForm.get('oldStatus') as FormControl;
  }

  /**
   * Getter for the 'newType' FormControl.
   * @type {FormControl}
   */
  get newType(): FormControl {
    return this.goalForm.get('newType') as FormControl;
  }

  /**
   * Getter for the 'oldType' FormControl.
   * @type {FormControl}
   */
  get oldType(): FormControl {
    return this.goalForm.get('oldType') as FormControl;
  }

  /**
   * Getter for the 'fileType' FormControl.
   * @type {FormControl}
   */
  get fileType(): FormControl {
    return this.goalForm.get('fileType') as FormControl;
  }

  /**
   * Getter for the 'hasNotification' FormControl.
   * @type {FormControl}
   */
  get hasNotification(): FormControl {
    return this.goalForm.get('hasNotification') as FormControl;
  }

  get isFormValid(): boolean {
    if (this.goalForm.invalid) {
      return false;
    }

    if (this.scope.value?.value === this.goalScope.Team && !this.teamId.value) {
      return false;
    }

    if (this.scope.value?.value === this.goalScope.User && !this.userId.value) {
      return false;
    }

    if (this.goalType.value) {
      const goalTypeValue = this.goalType.value.value;

      if (goalTypeValue === GoalType.CHANGE_STATUS_BETWEEN) {
        if (!this.newStatus.value || !this.oldStatus.value) {
          return false;
        }
      }

      if (goalTypeValue === GoalType.CHANGE_TYPE) {
        if (!this.newType.value || !this.oldType.value) {
          return false;
        }
      }

      if (goalTypeValue === GoalType.DOCUMENT_UPLOADED) {
        if (!this.fileType.value) {
          return false;
        }
      }
    }

    if (!this.goal.value) {
      return false;
    }

    return this.rate.value;
  }

  /**
   * OnInit lifecycle hook that initializes the component.
   * @return {void}
   */
  ngOnInit(): void {
    this.goalService.loadGoalUsers();
    this.goalService.loadGoalTeams();

    if (this.goalUUID) {
      this.goalService.loadGoalById(this.goalUUID);
    }

    this.initForm();
  }

  /**
   * Initializes the goal form with default values and sets up observables to patch values from the selected goal.
   * @return {void}
   */
  initForm(): void {
    this.goalForm = this.formBuilder.group({
      name: [null, Validators.required],
      target: [null, Validators.required],
      goalType: [null, Validators.required],
      goal: [null, Validators.required],
      rate: [null, Validators.required],
      scope: [null, Validators.required],
      teamId: [null],
      userId: [null],
      newStatus: [null],
      oldStatus: [null],
      newType: [null],
      oldType: [null],
      fileType: [null],
      hasNotification: [null],
    });

    this.goal$
      .pipe(
        tap(goal => {
          if (goal && this.goalUUID) {
            this.goalForm.patchValue({
              name: goal.name,
              target: { value: goal.target },
              goalType: { value: goal.goalType },
              goal: goal.goal,
              rate: null,
              scope: { value: goal.scope },
              teamId: null,
              userId: null,
              newStatus: null,
              oldStatus: null,
              newType: null,
              oldType: null,
              fileType: null,
              hasNotification: goal.hasNotification,
            });

            this.rates$
              .pipe(
                take(1),
                tap(rates => {
                  this.goalForm.patchValue({
                    rate: rates.find(rate => rate.value === goal.rate),
                  });
                })
              )
              .subscribe();

            this.documentTypes$
              .pipe(
                take(1),
                tap(docs => {
                  this.goalForm.patchValue({
                    fileType: docs.find(doc => doc.value === goal.fileType),
                  });
                })
              )
              .subscribe();

            if (goal.scope === GoalScope.Team && goal.team?.uuid) {
              this.goalForm.patchValue({
                teamId: { label: goal.team.name, value: goal.team.uuid },
              });
            }

            if (goal.scope === GoalScope.User && goal.user?.uuid) {
              this.goalForm.patchValue({
                userId: {
                  label: goal.user.firstname + ' ' + goal.user.lastname,
                  value: goal.user.uuid,
                },
              });
            }

            switch (goal.goalType) {
              case GoalType.CHANGE_STATUS_BETWEEN:
                this.goalForm.patchValue({
                  newStatus: goal.newStatus.map(status => status.uuid),
                  oldStatus: goal.oldStatus.map(status => status.uuid),
                });
                break;
              case GoalType.CHANGE_TYPE:
                this.goalForm.patchValue({
                  newType: { value: goal.newType },
                  oldType: { value: goal.oldType },
                });
                break;
              default:
            }
          }
        })
      )
      .subscribe();

    this.target.valueChanges.subscribe(value => {
      this.goalForm.patchValue({
        goalType: null,
        oldStatus: null,
        newStatus: null,
      });
      this.searchGoalTypes();
      value.value === 'profile'
        ? this.setProfilesStatuses()
        : this.setSalesStatuses();
    });

    this.searchGoalTypes();

    this.profileTypes$
      .pipe(
        take(1),
        tap(types => {
          this.goalForm.patchValue({
            oldType: types[0],
            newType: types[1],
          });
        })
      )
      .subscribe();
  }

  /**
   * Update the list of available goals types
   *
   * @return {void}
   */
  searchGoalTypes(): void {
    this.goalsTypes$ = this.store.select(selectGoalTypes).pipe(
      map(types => {
        if (this.target.value) {
          return types.filter(type =>
            this.target.value.value === Target.Profile
              ? [
                  GoalType.PROFILE_CREATED,
                  GoalType.DOCUMENT_UPLOADED,
                  GoalType.CHANGE_STATUS_BETWEEN,
                  GoalType.CHANGE_TYPE,
                  GoalType.COMMENT_CREATED,
                ].includes(type.value as GoalType)
              : [
                  //GoalType.CHANGE_STATUS_BETWEEN,
                  GoalType.COMMENT_CREATED,
                  GoalType.SALE_CREATED,
                ].includes(type.value as GoalType)
          );
        } else {
          return [];
        }
      })
    );
  }

  /**
   * Sets the statuses for profiles based on the selected values.
   * @returns {void}
   */
  setProfilesStatuses(): void {
    this.newProfilesStatuses$ = combineLatest([
      this.profilesStatuses$,
      this.oldStatus.valueChanges.pipe(startWith({ value: null })),
    ]).pipe(
      map(([profilesStatuses, oldStatusValue]) => {
        return profilesStatuses.filter(
          status => status.value !== (oldStatusValue?.value ?? undefined)
        );
      })
    );

    this.oldProfilesStatuses$ = combineLatest([
      this.profilesStatuses$,
      this.newStatus.valueChanges.pipe(startWith({ value: null })),
    ]).pipe(
      map(([profilesStatuses, oldStatusValue]) => {
        return profilesStatuses.filter(
          status => status.value !== (oldStatusValue?.value ?? undefined)
        );
      })
    );
  }

  /**
   * Sets the statuses for sales based on the selected values.
   * @returns {void}
   */
  setSalesStatuses(): void {
    this.newSalesStatuses$ = combineLatest([
      this.salesStatuses$,
      this.oldStatus.valueChanges.pipe(startWith({ value: null })),
    ]).pipe(
      map(([salesStatuses, oldStatusValue]) => {
        return salesStatuses.filter(
          status => status.value !== (oldStatusValue?.value ?? undefined)
        );
      })
    );

    this.oldSalesStatuses$ = combineLatest([
      this.salesStatuses$,
      this.newStatus.valueChanges.pipe(startWith({ value: null })),
    ]).pipe(
      map(([salesStatuses, oldStatusValue]) => {
        return salesStatuses.filter(
          status => status.value !== (oldStatusValue?.value ?? undefined)
        );
      })
    );
  }

  /**
   * Swaps the values of new and old statuses.
   * @returns {void}
   */
  swapStatuses(): void {
    const tmp = this.oldStatus.value;
    this.oldStatus.patchValue(this.newStatus.value);
    this.newStatus.patchValue(tmp);

    this.target.value.value === 'profile'
      ? this.setProfilesStatuses()
      : this.setSalesStatuses();
  }

  /**
   * Swaps the values of new and old types.
   * @returns {void}
   */
  swapType(): void {
    const tmp = this.oldType.value;
    this.oldType.patchValue(this.newType.value);
    this.newType.patchValue(tmp);
  }

  /**
   * Deletes the specified goal by its UUID.
   * @return {void}
   */
  deleteGoal(): void {
    this.alertService.showAlert('Delete Confirmation', {
      title: 'Are you sure you want to delete this goal?',
      message: 'This action cannot be undone',
      action: () => {
        if (this.goalUUID) this.goalService.deleteGoalById(this.goalUUID);
      },
    });
  }

  /**
   * Submits the goal form to update an existing goal.
   * @return {void}
   */
  onSubmit(): void {
    if (this.goalUUID) {
      const newGoalForm = this.formBuilder.group({
        ...this.goalForm.value,
        hasNotification:
          this.goalForm.get('hasNotification')?.value === true
            ? 'true'
            : 'false',
      });
      this.goalService.updateGoalById(newGoalForm, this.goalUUID);
    } else {
      this.goalService.createGoal(this.goalForm);
    }
  }

  /**
   * Methode to search goals user
   *
   * @param {IceCompleteEvent} event - event to retrieve the query for searching users
   * @return {void}
   */
  searchUsers(event: IceCompleteEvent): void {
    this.goalService.loadGoalUsers(event.query);
  }

  /**
   * Methode to search goals teams
   *
   * @param {IceCompleteEvent} event - event to retrieve the query for searching teams
   * @return {void}
   */
  searchTeams(event: IceCompleteEvent): void {
    this.goalService.loadGoalTeams(event.query);
  }
}
