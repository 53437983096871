import { createReducer, on } from '@ngrx/store';
import { initialTimelinesState } from '~/app/states/main/sales/states/timelines/timelines.state';
import * as TimelinesActions from '~/app/states/main/sales/states/timelines/timelines.actions';
import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';

export const timelinesReducer = createReducer(
  initialTimelinesState,

  on(TimelinesActions.loadTimelines, state => ({
    ...state,
    loading: true,
  })),
  on(TimelinesActions.loadTimelinesSuccess, (state, { timelines }) => ({
    ...state,
    ...timelines,
    loading: false,
  })),
  on(TimelinesActions.loadTimelinesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(TimelinesActions.loadSelectedTimelines, state => ({
    ...state,
    loading: true,
  })),
  on(
    TimelinesActions.loadSelectedTimelinesSuccess,
    (state, { selectedTimeline }) => ({
      ...state,
      selectedTimeline,
      loading: false,
    })
  ),
  on(TimelinesActions.loadSelectedTimelinesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(TimelinesActions.createTimeline, state => ({
    ...state,
    loading: true,
  })),
  on(TimelinesActions.createTimelineSuccess, (state, { timeline }) => ({
    ...state,
    selectedTimeline: timeline,
    data: [timeline, ...state.data],
    loading: false,
  })),
  on(TimelinesActions.createTimelineFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(TimelinesActions.deleteTimeline, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TimelinesActions.deleteTimelineSuccess, (state, { uuid }) => ({
    ...state,
    selectedTimeline: null,
    data: state.data.filter(timeline => timeline.uuid !== uuid),
    loading: false,
  })),
  on(TimelinesActions.deleteTimelineFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(TimelinesActions.updateTimeline, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    TimelinesActions.updateTimelineSuccess,
    (state, { timeline: { uuid, name, description }, tasks }) => ({
      ...state,
      selectedTimeline: {
        ...(state.selectedTimeline as Timeline),
        name,
        description,
        tasks,
      },
      data: state.data.map(timeline =>
        timeline.uuid === uuid
          ? {
              ...timeline,
              name,
              description,
              tasks,
              tasksCount: tasks.length,
            }
          : timeline
      ),
      loading: false,
    })
  ),
  on(TimelinesActions.updateTimelineFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
