import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { kanbanCandidatesUnauthorized } from '~/app/states/main/kanban/states/candidates/kanban-candidate.actions';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import * as KanbanCandidateActions from '~/app/states/main/kanban/states/candidates/kanban-candidate.actions';
import { environment } from '~/environments/environment';

/**
 * `KanbanCandidateEffects` manages the side effects for kanban candidate-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban candidates,
 * such as loading kanban candidates. It performs operations that involve checking permissions, interacting with
 * services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadCandidates$`: Loads kanban candidates. It performs an HTTP POST request and dispatches a success action
 *   with the fetched candidates or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use
 * throughout the application.
 */
@Injectable()
export class KanbanCandidateEffects {
  /**
   * Effect to load kanban candidates.
   * Listens for the `loadKanbanCandidates` action, checks permissions, and performs an HTTP POST request to fetch
   * the candidates. Dispatches a success or failure action based on the result.
   */
  loadCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCandidateActions.loadKanbanCandidates),
      checkPermission(kanbanCandidatesUnauthorized),
      mergeMap(({ name }) => {
        let request = {};
        if (name) {
          request = { name };
        }
        return this.http
          .post<
            PaginationData<Candidate>
          >(`${environment.apiUrl}/v1/profiles/search`, request, { withCredentials: true })
          .pipe(
            take(1),
            map(({ data: candidates }) =>
              KanbanCandidateActions.loadKanbanCandidatesSuccess({ candidates })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCandidateActions.loadKanbanCandidatesFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Constructor for `KanbanCandidateEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
