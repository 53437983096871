import { createAction, props } from '@ngrx/store';
import { ClientCompanyConsultantsState } from '~/app/states/main/client-company/profile/states/client-company-consultants/client-company-consultants.state';
import { ClientCompanyConsultantsFilters } from '~/app/shared/interfaces/client-company/profile/client-company-consultants-filters.interface';

export const loadClientCompanyConsultants = createAction(
  '[Client Company Consultants] Load Client Company Consultants',
  props<{ uuid: string; filters?: ClientCompanyConsultantsFilters }>()
);
export const loadClientCompanyConsultantsSuccess = createAction(
  '[Client Company Consultants] Load Client Company Consultants Success',
  props<{ clientCompanyConsultants: ClientCompanyConsultantsState }>()
);
export const loadClientCompanyConsultantsFailure = createAction(
  '[Client Company Consultants] Load Client Company Consultants Failure',
  props<{ error: string }>()
);

export const clientCompanyConsultantsUnauthorized = createAction(
  '[Client Company Consultants] Unauthorized',
  props<{ error: string }>()
);
