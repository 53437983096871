import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AssignActions from '~/app/states/main/jobhub/states/assign-offer/apply-offer.action';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { ProfileApplied } from '~/app/shared/interfaces/jobhub/assign-offer/profile-applied.interface';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { environment } from '~/environments/environment';

/**
 * `ApplyOfferEffects` manages the side effects for offer application-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to applying profiles to offers, such as loading profiles, auto-applying and manually applying profiles to offers.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadAppliedProfiles$`: Loads profiles applied to a specific offer. It performs an HTTP GET request and dispatches a success action with the fetched profiles or a failure action if an error occurs.
 * — `loadOfferById$`: Loads a specific offer based on its UUID. It performs an HTTP GET request and dispatches a success action with the fetched offer or a failure action if an error occurs.
 * — `autoApplyOffer$`: Automatically applies profiles to an offer. It performs an HTTP POST request and dispatches a success action if successful or a failure action if an error occurs.
 * — `autoApplyOfferSuccess$`: Handles the success of automatically applying profiles by displaying a success toast message and closing the sidebar form.
 * — `manualApplyOffer$`: Manually applies profiles to an offer. It performs an HTTP POST request and dispatches a success action if successful or a failure action if an error occurs.
 * — `manualApplyOfferSuccess$`: Handles the success of manually applying profiles by displaying a success toast message and closing the sidebar form.
 * — `loadAllCandidates$`: Loads all candidates, optionally filtered by name. It performs an HTTP POST request and dispatches a success action with the fetched candidates or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ApplyOfferEffects {
  /**
   * Effect to load profiles applied to a specific offer.
   * Listens for the `loadAppliedProfiles` action, checks permissions, and performs an HTTP GET request to fetch the profiles.
   * Dispatches a success or failure action based on the result.
   */
  loadAppliedProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignActions.loadAppliedProfiles),
      checkPermission(AssignActions.applyOfferUnauthorized),
      mergeMap(({ offerId }) =>
        this.http
          .get<
            ProfileApplied[]
          >(`${environment.apiUrl}/v1/offers/${offerId}/proposals`, { withCredentials: true })
          .pipe(
            take(1),
            map(proposals =>
              AssignActions.loadAppliedProfilesSuccess({ proposals })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AssignActions.loadAppliedProfilesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load a specific offer based on its UUID.
   * Listens for the `loadOfferById` action, checks permissions, and performs an HTTP GET request to fetch the offer.
   * Dispatches a success or failure action based on the result.
   */
  loadOfferById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignActions.loadOfferById),
      checkPermission(AssignActions.applyOfferUnauthorized),
      mergeMap(({ offerId }) =>
        this.http
          .get<Offer>(`${environment.apiUrl}/v1/offers/search/${offerId}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offer => AssignActions.loadOfferByIdSuccess({ offer })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AssignActions.loadOfferByIdFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to automatically apply profiles to an offer.
   * Listens for the `autoApplyOffer` action, checks permissions, and performs an HTTP POST request to apply the profiles.
   * Dispatches a success or failure action based on the result.
   */
  autoApplyOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignActions.autoApplyOffer),
      checkPermission(AssignActions.applyOfferUnauthorized),
      mergeMap(({ offerId, payload }) =>
        this.http
          .post(
            `${environment.apiUrl}/v1/offers/applyProfiles/${offerId}`,
            payload,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => AssignActions.autoApplyOfferSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AssignActions.autoApplyOfferFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of automatically applying profiles.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  autoApplyOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssignActions.autoApplyOfferSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Profiles applied successfully');
          this.sidenavService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to manually apply profiles to an offer.
   * Listens for the `manualApplyOffer` action, checks permissions, and performs an HTTP POST request to apply the profiles manually.
   * Dispatches a success or failure action based on the result.
   */
  manualApplyOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignActions.manualApplyOffer),
      checkPermission(AssignActions.applyOfferUnauthorized),
      mergeMap(({ offerId, manualApplyData }) =>
        this.http
          .post(
            `${environment.apiUrl}/v1/offers/applyManual/${offerId}`,
            manualApplyData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => AssignActions.manualApplyOfferSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AssignActions.manualApplyOfferFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of manually applying profiles.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  manualApplyOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssignActions.manualApplyOfferSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Profile applied successfully');
          this.sidenavService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to load all candidates, optionally filtered by name.
   * Listens for the `loadAllCandidates` action, checks permissions, and performs an HTTP POST request to fetch the candidates.
   * Dispatches a success or failure action based on the result.
   */
  loadAllCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignActions.loadAllCandidates),
      checkPermission(AssignActions.applyOfferUnauthorized),
      mergeMap(({ filters }) => {
        return this.http
          .post<
            PaginationData<Candidate>
          >(`${environment.apiUrl}/v1/profiles/search`, filters, { withCredentials: true })
          .pipe(
            take(1),
            map(allCandidates =>
              AssignActions.loadAllCandidatesSuccess({
                allCandidates: allCandidates.data,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AssignActions.loadAllCandidatesFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Constructor for `ApplyOfferEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidenavService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidenavService: SidenavFormService
  ) {}
}
