import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';

/**
 * A custom Angular pipe that intelligently adjusts the background color based on its current brightness.
 * Utilizing the 'tinycolor2' library, the pipe either lightens or darkens the provided color to optimize visual comfort
 * and aesthetic consistency across different UI components. This pipe is particularly useful in dynamic themes where
 * background colors may need adjustment for optimum text readability or visual harmony.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'smartBackgroundColor', enabling its use within Angular templates
 * to dynamically adjust background colors based on their brightness.
 */
@Pipe({
  name: 'smartBackgroundColor',
})
export class SmartBackgroundColorPipe implements PipeTransform {
  /**
   * Transforms a background color by lightening or darkening it depending on its initial brightness.
   * This method enhances the adaptability of background colors in response to their environment or context,
   * ensuring that UI elements remain both visually appealing and functionally practical.
   *
   * @param {string} value - The color value in any format accepted by 'tinycolor2' (e.g., HEX, RGB, HSL).
   * @param {number} [amount=10] - The amount to adjust the color by, enhancing the lightness or darkness as needed.
   * @returns {string} - The adjusted color as a string in the format detected by 'tinycolor2'.
   */
  transform(value: string, amount = 10): string {
    const color = tinycolor(value);
    if (color.isLight()) {
      return color.lighten(amount).toString();
    } else return color.darken(amount).toString();
  }
}
