import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';

/**
 * Route guard for job seekers that checks for the presence of a job seeker token.
 * If a job seeker token exists, it allows route activation. If no token is found, it redirects to the home page.
 *
 * @returns {Observable<boolean|UrlTree>} - Returns true if a job seeker token exists, otherwise it returns a UrlTree for redirection to the home page.
 */
export const jobSeekerGuard: CanActivateFn = (): Observable<
  boolean | UrlTree
> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectAuthState).pipe(
    map(authState => {
      if (authState.userData?.company?.type === 'freelance') {
        return true;
      } else {
        return router.createUrlTree(['']);
      }
    })
  );
};
