import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserActions from '~/app/states/main/kanban/states/users/users.actions';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';

/**
 * Service responsible for managing Kanban users and their associated actions.
 * Provides methods to load users with pagination and optional name filtering.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanUsersService {
  /**
   * Constructs a new KanbanUsersService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * Loads users with pagination and optional name filtering.
   *
   * Dispatches a loadUsers action with the specified limit, page, and optional name.
   *
   * @param {number} limit – The maximum number of users to load per page.
   * @param {number} page – The current
   * page number to load.
   * @param {string} [name] – Optional name filter to search for specific users.
   * @returns {void}
   */
  loadUsers(limit: number, page: number, name?: string): void {
    this.store.dispatch(UserActions.loadUsers({ limit, page, name }));
  }
}
