import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

/**
 * Service to display toast notifications throughout the application. This service utilizes the PrimeNG MessageService
 * to show customizable messages in response to various application events such as successes, warnings, and errors.
 *
 * @Injectable marks the class as a service that can be injected, with the service being provided at the root level,
 * ensuring a single instance is used throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * Constructor that initializes the ToastService with PrimeNG's MessageService.
   *
   * @param {MessageService} messageService - The PrimeNG service used to manage and display toast messages across the application.
   */
  constructor(private messageService: MessageService) {}

  /**
   * Displays a success toast message.
   * @param {string} summary - A brief summary of the success message.
   * @param {string} detail - Detailed information about the success message.
   * @returns {void}
   */
  showSuccess(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  /**
   * Displays an informational toast message.
   * @param {string} summary - A brief summary of the informational message.
   * @param {string} detail - Detailed information about the informational message.
   * @returns {void}
   */
  showInfo(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'info',
      summary: summary,
      detail: detail,
    });
  }

  /**
   * Displays a warning toast message.
   * @param {string} summary - A brief summary of the warning message.
   * @param {string} detail - Detailed information about the warning message.
   * @returns {void}
   */
  showWarning(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'warn',
      summary: summary,
      detail: detail,
    });
  }

  /**
   * Displays an error toast message.
   * @param {string} summary - A brief summary of the error message.
   * @param {string} detail - Detailed information about the error message.
   * @returns {void}
   */
  showError(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
      sticky: true,
    });
  }

  /**
   * Clears all displayed toast messages.
   * @returns {void}
   */
  clear(): void {
    this.messageService.clear();
  }
}
