import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SocialNetworksActions from '~/app/states/main/crm/profile/states/profile-social-networks/profile-social-networks.actions';
import { SocialNetwork } from '~/app/shared/interfaces/shared/social-network.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileSocialNetworksEffects` manages the side effects for profile social network-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to social networks, such as loading, creating, updating, and deleting social networks.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadSocialNetworks$`: Loads social networks associated with a profile based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched social networks or a failure action if an error occurs.
 * — `createSocialNetwork$`: Creates a new social network for a profile. It performs an HTTP POST request and dispatches a success action with the created social network or a failure action if an error occurs.
 * — `createSocialNetworkSuccess$`: Handles the success of creating a new social network by displaying a success toast message and closing the sidebar form.
 * — `updateSocialNetwork$`: Updates an existing social network. It performs an HTTP PATCH request and dispatches a success action with the updated social network or a failure action if an error occurs.
 * — `updateSocialNetworkSuccess$`: Handles the success of updating a social network by displaying a success toast message and closing the sidebar form.
 * — `deleteSocialNetwork$`: Deletes a social network based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteSocialNetworkSuccess$`: Handles the success of deleting a social network by displaying a success toast message and closing the sidebar form.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileSocialNetworksEffects {
  /**
   * Effect to load social networks associated with a profile based on a given profile ID.
   * Listens for the `loadSocialNetworks` action, checks permissions, and performs an HTTP GET request to fetch the social networks.
   * Dispatches a success or failure action based on the result.
   */
  loadSocialNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.loadSocialNetworks),
      checkPermission(SocialNetworksActions.profileSocialNetworksUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<SocialNetwork[]>(
            `${environment.apiUrl}/v1/profiles/socialNetworks`,
            {
              params: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(socialNetworks =>
              SocialNetworksActions.loadSocialNetworksSuccess({
                socialNetworks,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.loadSocialNetworksFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new social network for a profile.
   * Listens for the `createSocialNetwork` action, checks permissions, and performs an HTTP POST request to create the social network.
   * Dispatches a success or failure action based on the result.
   */
  createSocialNetwork = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.createSocialNetwork),
      checkPermission(SocialNetworksActions.profileSocialNetworksUnauthorized),
      mergeMap(({ profileSocialNetwork }) =>
        this.http
          .post<SocialNetwork>(
            `${environment.apiUrl}/v1/profiles/socialNetworks`,
            profileSocialNetwork,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(socialNetwork =>
              SocialNetworksActions.createSocialNetworkSuccess({
                socialNetwork,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.createSocialNetworkFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new social network.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createSocialNetworkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SocialNetworksActions.createSocialNetworkSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Social network added successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing social network.
   * Listens for the `updateSocialNetwork` action, checks permissions, and performs an HTTP PATCH request to update the social network.
   * Dispatches a success or failure action based on the result.
   */
  updateSocialNetwork = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.updateSocialNetwork),
      checkPermission(SocialNetworksActions.profileSocialNetworksUnauthorized),
      mergeMap(({ socialNetwork }) =>
        this.http
          .patch<SocialNetwork>(
            `${environment.apiUrl}/v1/profiles/socialNetwork/${socialNetwork.uuid}`,
            socialNetwork,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(socialNetwork =>
              SocialNetworksActions.updateSocialNetworkSuccess({
                socialNetwork,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.updateSocialNetworkFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a social network.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateSocialNetworkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SocialNetworksActions.updateSocialNetworkSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Social network updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a social network based on its UUID and profile ID.
   * Listens for the `deleteSocialNetwork` action, checks permissions, and performs an HTTP DELETE request to delete the social network.
   * Dispatches a success or failure action based on the result.
   */
  deleteSocialNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialNetworksActions.deleteSocialNetwork),
      checkPermission(SocialNetworksActions.profileSocialNetworksUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/profiles/socialNetworks/${uuid}`,
            {
              body: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              SocialNetworksActions.deleteSocialNetworkSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SocialNetworksActions.deleteSocialNetworkFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a social network.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  deleteSocialNetworkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SocialNetworksActions.deleteSocialNetworkSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Social network deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileSocialNetworksEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
