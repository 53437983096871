/**
 * Type definition for the state of screen breakpoints.
 * This type is used to manage and track the current screen size category within the application.
 *
 * @interface BreakpointState
 */
export interface BreakpointState {
  /**
   * The current breakpoint, typically representing a screen size category.
   *
   * @type {string}
   */
  currentBreakpoint: string;
}

/**
 * Initial state for the breakpoint management in the application.
 * Sets the default value of the current breakpoint to 'xl', typically representing an extra-large screen size.
 *
 * @type {BreakpointState}
 */
export const initialBreakpointState: BreakpointState = {
  currentBreakpoint: 'xl',
};
