import { createReducer, on } from '@ngrx/store';
import * as ProfileRemindersActions from '~/app/states/main/crm/profile/states/profile-reminders/profile-reminders.actions';
import { initialProfileRemindersState } from '~/app/states/main/crm/profile/states/profile-reminders/profile-reminders.state';

export const profileRemindersReducer = createReducer(
  initialProfileRemindersState,

  on(ProfileRemindersActions.loadProfileReminders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.loadProfileRemindersSuccess,
    (state, { profileReminders }) => ({
      ...state,
      profileReminders,
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.loadProfileRemindersFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileRemindersActions.createProfileReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.createProfileReminderSuccess,
    (state, { profileReminder }) => ({
      ...state,
      profileReminders: [...state.profileReminders, profileReminder],
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.createProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileRemindersActions.updateProfileReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.updateProfileReminderSuccess,
    (state, { profileReminder }) => ({
      ...state,
      profileReminders: state.profileReminders.map(item =>
        item.uuid === profileReminder.uuid ? profileReminder : item
      ),
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.updateProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileRemindersActions.deleteProfileReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.deleteProfileReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      profileReminders: state.profileReminders.filter(
        item => item.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.deleteProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileRemindersActions.checkProfileReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.checkProfileReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      profileReminders: state.profileReminders.map(item =>
        item.uuid === uuid
          ? { ...item, doneAt: new Date().toISOString() }
          : item
      ),
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.checkProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileRemindersActions.uncheckProfileReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileRemindersActions.uncheckProfileReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      profileReminders: state.profileReminders.map(item =>
        item.uuid === uuid ? { ...item, doneAt: null } : item
      ),
      loading: false,
    })
  ),
  on(
    ProfileRemindersActions.uncheckProfileReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileRemindersActions.profileRemindersUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
