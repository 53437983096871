/**
 * Represents the state for quick navigation of sales profiles.
 *
 * @interface SalesQuickNavState
 */
export interface SalesQuickNavState {
  /**
   * The list of UUIDs for the sales profiles used in quick navigation.
   *
   * @type {string[]}
   */
  uuids: string[];
}

/**
 * The initial state of the sales quick navigation module.
 *
 * @type {SalesQuickNavState}
 */
export const initialSalesQuickNavState: SalesQuickNavState = {
  uuids: [],
};
