import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesActions from '~/app/states/main/sales/profile/states/sales/sales.actions';
import { Sales } from '~/app/shared/interfaces/sales/sales.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesEffects` manages the side effects for sales-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales, such as loading, creating, and updating sales records.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSale$`: Loads a sales record by UUID. It performs an HTTP GET request and dispatches a success action with the loaded sales record or a failure action if an error occurs.
 * — `createSale$`: Creates a new sales record. It performs an HTTP POST request and dispatches a success action with the created sales record or a failure action if an error occurs.
 * — `createSaleSuccess$`: Handles the success of creating a new sales record by displaying a success toast message and closing the sidebar.
 * — `updateSale$`: Updates an existing sales record. It performs an HTTP PATCH request and dispatches a success action with the updated sales record or a failure action if an error occurs.
 * — `updateSaleSuccess$`: Handles the success of updating a sales record by displaying a success toast message and closing the sidebar.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesEffects {
  /**
   * Effect to load a sales record by UUID.
   * It listens for the `loadSale` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales record or a failure action if an error occurs.
   */
  loadSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.loadSale),
      checkPermission(SalesActions.salesUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Sales>(`${environment.apiUrl}/v1/sales/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sales => SalesActions.loadSaleSuccess({ sales })),
            catchError(error => {
              this.httpErrors.handleError(error);
              void this.router.navigateByUrl('/not-found');
              return of(SalesActions.loadSaleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales record.
   * It listens for the `createSale` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales record or a failure action if an error occurs.
   */
  createSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.createSale),
      checkPermission(SalesActions.salesUnauthorized),
      mergeMap(({ saleData }) =>
        this.http
          .post<Sales>(`${environment.apiUrl}/v1/sales`, saleData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sale => SalesActions.createSaleSuccess({ sale })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesActions.createSaleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new sales record.
   * It displays a success toast message and closes the sidebar.
   */
  createSaleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesActions.createSaleSuccess),
        tap(({ sale }) => {
          this.toast.successToast('Success', 'Sale created successfully');
          this.sidebarService.closeSidebar();
          void this.router.navigate([`/sales/profile/${sale.uuid}`]);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing sales record.
   * It listens for the `updateSale` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful update, it dispatches a success action with the updated sales record or a failure action if an error occurs.
   */
  updateSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.updateSale),
      checkPermission(SalesActions.salesUnauthorized),
      mergeMap(({ uuid, saleData }) =>
        this.http
          .patch<Sales>(`${environment.apiUrl}/v1/sales/${uuid}`, saleData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(sale => SalesActions.updateSaleSuccess({ sale })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesActions.updateSaleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a sales record.
   * It displays a success toast message and closes the sidebar.
   */
  updateSaleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesActions.updateSaleSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sale updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {Router} router — Injectable Router for navigating between pages.
   * @param {SidenavFormService} sidebarService — Injectable service for managing the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private router: Router,
    public sidebarService: SidenavFormService
  ) {}
}
