/* eslint-disable @typescript-eslint/no-empty-function */

/**
 * An empty function that performs no operations and returns nothing.
 * This function can be used in places where a no-operation (noop) function is necessary,
 * for example, as a default callback or when a function argument is optional.
 *
 * The primary use of this function is to provide a safe, do-nothing placeholder
 * to prevent errors in cases where function input is expected but not essential for operation.
 * @return {void}
 */
export function emptyFunction(): void {}
