import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import IceRule from '@npmicedev/icemodule/lib/utils/rules';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Observable, skipWhile, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchClientCompanyService } from '~/app/main/client-company/services/search-client-company.service';
import { selectClientCompanies } from '~/app/main/client-company/states/search-client-company/search-client-company.selectors';
import { AllSearchClientCompaniesState } from '~/app/main/client-company/states/search-client-company/search-client-company.state';
import { SalesService } from '~/app/main/sales/profile/services/sales.service';
import { DataService } from '~/app/shared/services/data.service';
import { UtilsService } from '~/app/shared/services/utils.service';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { SalesState } from '~/app/main/sales/profile/states/sales/sales.state';
import { selectCreateLoadingState } from '~/app/main/sales/profile/states/sales/sales.selector';

/**
 * CuSalesComponent manages the creation and updating of sales records.
 *
 * @Component
 * @selector 'app-u-sales' — CSS selector that identifies this component in a template.
 * @templateUrl './cu-sales.component.html' — Path to the HTML template for this component.
 * @styleUrls ['./cu-sales.component.scss'] — Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-u-sales',
  templateUrl: './cu-sales.component.html',
  styleUrls: ['./cu-sales.component.scss'],
})
export class CuSalesComponent implements OnInit {
  /**
   * Initial data for the sales form, used for patching existing sales information.
   * @type {any | null}
   */
  @Input() initialData: any | null = null;

  /**
   * FormGroup that encapsulates the sales form controls and validators.
   * @type {FormGroup}
   */
  salesForm!: FormGroup;

  /**
   * Observable that emits a list of sales statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  statuses$: Observable<LabelValueInterface[]>;

  /**
   * Observable that emits a list of client companies.
   * @type {Observable<LabelValueInterface[]>}
   */
  clientCompanies$: Observable<LabelValueInterface[]>;

  /**
   * Observable for the loading state of candidates.
   */
  isLoading$: Observable<boolean>;

  /**
   * IceRule for form validation and rules.
   * @type {IceRule}
   * @readonly
   */
  protected readonly iceRule = IceRule;

  /**
   * IceAutocompleteTemplateEnum for autocomplete template options.
   * @type {IceAutocompleteTemplateEnum}
   * @readonly
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Constructor for CuSalesComponent.
   *
   * Initializes necessary services for form building, data retrieval, and state management.
   *
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {DataService} data — Provides data services.
   * @param {SalesService} sales — Handles sales logic.
   * @param {UtilsService} utils — Provides utility functions.
   * @param {SearchClientCompanyService} clientCompaniesService — Handles client companies logic.
   * @param {Store<AllSearchClientCompaniesState>} clientCompaniesStore - Handle client companies store.
   * @param {Store<SalesState>} salesStore — Provides access to the sales state.
   */
  constructor(
    private formBuilder: FormBuilder,
    private data: DataService,
    private sales: SalesService,
    private utils: UtilsService,
    private clientCompaniesService: SearchClientCompanyService,
    private clientCompaniesStore: Store<AllSearchClientCompaniesState>,
    private salesStore: Store<SalesState>
  ) {
    this.clientCompanies$ = this.clientCompaniesStore
      .select(selectClientCompanies)
      .pipe(
        skipWhile(items => !items),
        map(clientCompanies =>
          clientCompanies.map(clientCompany => ({
            label: clientCompany.name,
            value: clientCompany.uuid,
          }))
        )
      );

    this.statuses$ = this.data.salesStatuses$.pipe(
      skipWhile(items => !items),
      take(2),
      map(statuses =>
        statuses.map(status => ({
          label: status.name,
          value: status.uuid,
          color: status.color,
        }))
      )
    );
    this.isLoading$ = this.salesStore.select(selectCreateLoadingState);
  }

  /**
   * Getter for accessing the 'firstname' FormControl within the sales form.
   * @type {FormControl}
   */
  public get firstname(): FormControl {
    return this.salesForm.get('firstname') as FormControl;
  }

  /**
   * Getter for accessing the 'lastname' FormControl within the sales form.
   * @type {FormControl}
   */
  public get lastname(): FormControl {
    return this.salesForm.get('lastname') as FormControl;
  }

  /**
   * Getter for accessing the 'statusId' FormControl within the sales form.
   * @type {FormControl}
   */
  public get statusId(): FormControl {
    return this.salesForm.get('statusId') as FormControl;
  }

  /**
   * Getter for accessing the value of the 'statusId' FormControl within the sales form.
   * @type {string | null}
   */
  public get statusIdValue(): string | null {
    return this.statusId.value?.value ?? this.statusId.value;
  }

  /**
   * Getter for accessing the 'title' FormControl within the sales form.
   * @type {FormControl}
   */
  public get title(): FormControl {
    return this.salesForm.get('title') as FormControl;
  }

  /**
   * Getter for accessing the 'streetAddress' FormControl within the sales form.
   * @type {FormControl}
   */
  public get streetAddress(): FormControl {
    return this.salesForm.get('streetAddress') as FormControl;
  }

  /**
   * Getter for accessing the 'city' FormControl within the sales form.
   * @type {FormControl}
   */
  public get city(): FormControl {
    return this.salesForm.get('city') as FormControl;
  }

  /**
   * Getter for accessing the 'postalCode' FormControl within the sales form.
   * @type {FormControl}
   */
  public get postalCode(): FormControl {
    return this.salesForm.get('postalCode') as FormControl;
  }

  /**
   * Getter for accessing the 'clientCompanyId' FormControl within the sales form.
   * @type {FormControl}
   */
  public get clientCompanyId(): FormControl {
    return this.salesForm.get('clientCompanyId') as FormControl;
  }

  /**
   * Getter for accessing the value of the 'clientCompanyId' FormControl within the sales form.
   * @type {string | null}
   */
  public get clientCompanyIdValue(): string | null {
    return this.clientCompanyId.value?.value ?? this.clientCompanyId.value;
  }

  /**
   * OnInit lifecycle hook that initializes the form.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the sales form with default values and validators.
   * Sets up the form controls with required validators.
   * If initial data is provided, patches the form with existing values.
   *
   * @return {void}
   */
  initForm(): void {
    this.salesForm = this.formBuilder.group({
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      statusId: [null, Validators.required],
      title: [null],
      streetAddress: [null],
      city: [null],
      postalCode: [null],
      clientCompanyId: [null, Validators.required],
    });

    if (this.initialData) {
      this.salesForm.patchValue({
        ...this.initialData,
        clientCompanyId: {
          label: this.initialData.clientCompany.name,
          value: this.initialData.clientCompany.uuid,
        },
        statusId: {
          label: this.initialData.status.name,
          value: this.initialData.status.uuid,
        },
      });
    }
  }

  /**
   * Submits the sales form data.
   * Prepares the data for submission and creates or updates the sales record.
   *
   * @return {void}
   */
  onSubmit(): void {
    const submissionData = {
      ...this.salesForm.value,
      statusId: this.statusIdValue,
      clientCompanyId: this.clientCompanyIdValue,
    };

    if (this.initialData) {
      this.sales.updateSale(
        this.initialData.uuid,
        this.utils.replaceEmptyByNull(submissionData)
      );
    } else {
      this.sales.createSale(this.utils.filterValues(submissionData));
    }
  }

  /**
   * Searches for client companies based on the provided query in the autocomplete component.
   *
   * @param {AutoCompleteCompleteEvent} event — The autocomplete complete event containing the query.
   * @return {void}
   */
  searchClientCompanies(event: AutoCompleteCompleteEvent): void {
    this.clientCompaniesService.loadAllClientCompanies({
      limit: 20,
      ...(event.query && { name: event.query }),
    });
  }
}
