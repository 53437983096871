import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Directive that marks an element as a host container for dynamic components.
 * This directive is used to dynamically load and embed components within the designated container, such as a sidebar in a UI.
 * It exposes the `ViewContainerRef` of the element it is applied to, which allows Angular's dynamic component loader to
 * inject components into the DOM at the marked element's location.
 *
 * Usage of this directive involves adding it as an attribute to a host element in the template, where dynamic components will be loaded.
 *
 * @example
 * <div appSidebarHost></div>
 *
 * @Directive decorator describes the CSS selector that identifies this directive within a template.
 */
@Directive({
  selector: '[appSidebarHost]',
})
export class SidebarHostDirective {
  /**
   * Constructor that initializes the directive with a reference to the view container.
   * This view container will be used for embedding dynamic components.
   *
   * @param {ViewContainerRef} viewContainerRef - The reference to the container view that will host the dynamic components.
   * This allows the directive to directly manipulate the host container, inserting or managing content dynamically.
   */
  constructor(public viewContainerRef: ViewContainerRef) {}
}
