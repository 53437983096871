import { Directive, ViewContainerRef } from '@angular/core';

/**
 * SidebarHeaderDirective is a custom directive that provides a reference to the
 * ViewContainerRef for the sidebar header component. This allows for dynamic
 * component rendering within the sidebar header.
 *
 * @directive
 * @selector '[appSidebarHeader]'
 */
@Directive({
  selector: '[appSidebarHeader]',
})
export class SidebarHeaderDirective {
  /**
   * Creates an instance of SidebarHeaderDirective.
   *
   * @param {ViewContainerRef} viewContainerRef - The reference to the view container
   * where the sidebar header components can be dynamically inserted.
   */
  constructor(public viewContainerRef: ViewContainerRef) {}
}
