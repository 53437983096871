import { Office } from '~/app/shared/interfaces/settings/office.interface';

/**
 * Represents the state of all offices within the application.
 *
 * @interface AllOfficesState
 */
export interface AllOfficesState {
  /**
   * The total count of offices.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The limit of offices per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates whether there is a next page of offices.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates whether there is a previous page of offices.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Array of office objects representing the offices.
   *
   * @type {Office[]}
   */
  data: Office[];

  /**
   * Indicates whether office data is being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling office-related actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Provides the initial state for the all offices module.
 *
 * @type {AllOfficesState}
 */
export const initialAllOfficesState: AllOfficesState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
};
