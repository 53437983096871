import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ClientCompanyInterviewsActions from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.actions';
import { ClientCompanyInterviewsState } from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ClientCompanyInterviewsEffects` handles the side effects related to client company interviews in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading client company interviews from the server.
 *
 * Effects included in this class:
 * — `loadClientCompanyInterviews$`: Fetches client company interviews by company UUID from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ClientCompanyInterviewsEffects {
  /**
   * Effect to load client company interviews by company UUID.
   */
  loadClientCompanyInterviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientCompanyInterviewsActions.loadClientCompanyInterviews),
      checkPermission(
        ClientCompanyInterviewsActions.clientCompanyInterviewsUnauthorized
      ),
      mergeMap(({ uuid, filters }) =>
        this.http
          .get<ClientCompanyInterviewsState>(
            `${environment.apiUrl}/v1/clientCompanies/${uuid}/interviews`,
            { params: { ...filters }, withCredentials: true }
          )
          .pipe(
            take(1),
            map(clientCompanyInterviews =>
              ClientCompanyInterviewsActions.loadClientCompanyInterviewsSuccess(
                {
                  clientCompanyInterviews,
                }
              )
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ClientCompanyInterviewsActions.loadClientCompanyInterviewsFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `ClientCompanyInterviewsEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @memberof ClientCompanyInterviewsEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
