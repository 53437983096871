import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesRemindersActions from '~/app/states/main/sales/profile/states/sales-reminders/sales-reminders.actions';
import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesRemindersEffects` manages the side effects for sales reminders-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales reminders, such as loading, creating, updating, deleting, and checking reminders.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesReminders$`: Loads the sales reminders associated with a specific sales record.
 * — `createSalesReminder$`: Creates a new sales reminder record.
 * — `createSalesReminderSuccess$`: Handles the success of creating a new sales reminder record by displaying a success toast message.
 * — `updateSalesReminder$`: Updates an existing sales reminder record.
 * — `updateSalesReminderSuccess$`: Handles the success of updating a sales reminder record by displaying a success toast message.
 * — `deleteSalesReminder$`: Deletes a sales reminder record.
 * — `deleteSalesReminderSuccess$`: Handles the success of deleting a sales reminder record by displaying a success toast message.
 * — `checkSalesReminders$`: Marks a sales reminder as checked.
 * — `checkSalesReminderSuccess$`: Handles the success of marking a sales reminder as checked by displaying a success toast message.
 * — `uncheckSalesReminders$`: Marks a sales reminder as unchecked.
 * — `uncheckSalesReminderSuccess$`: Handles the success of marking a sales reminder as unchecked by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesRemindersEffects {
  /**
   * Effect to load sales reminders associated with a specific sales record.
   * It listens for the `loadSalesReminders` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales reminders or a failure action if an error occurs.
   */
  loadSalesReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.loadSalesReminders),
      checkPermission(SalesRemindersActions.salesRemindersUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<Reminder[]>(`${environment.apiUrl}/v1/sales/reminders`, {
            params: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesReminders =>
              SalesRemindersActions.loadSalesRemindersSuccess({
                salesReminders,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.loadSalesRemindersFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales reminder record.
   * It listens for the `createSalesReminder` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales reminder or a failure action if an error occurs.
   */
  createSalesReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.createSalesReminder),
      checkPermission(SalesRemindersActions.salesRemindersUnauthorized),
      mergeMap(({ salesReminderData }) =>
        this.http
          .post<Reminder>(
            `${environment.apiUrl}/v1/sales/reminders`,
            salesReminderData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesReminder =>
              SalesRemindersActions.createSalesReminderSuccess({
                salesReminder,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.createSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new sales reminder record.
   * It displays a success toast message and closes the sidebar.
   */
  createSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesRemindersActions.createSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing sales reminder record.
   * It listens for the `updateSalesReminder` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful updating, it dispatches a success action with the updated sales reminder or a failure action if an error occurs.
   */
  updateSalesReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.updateSalesReminder),
      checkPermission(SalesRemindersActions.updateSalesReminderFailure),
      mergeMap(({ uuid, salesReminderData }) =>
        this.http
          .patch<Reminder>(
            `${environment.apiUrl}/v1/sales/reminders/${uuid}`,
            salesReminderData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesReminder =>
              SalesRemindersActions.updateSalesReminderSuccess({
                salesReminder,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.updateSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a sales reminder record.
   * It displays a success toast message and closes the sidebar.
   */
  updateSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesRemindersActions.updateSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sales reminder record.
   * It listens for the `deleteSalesReminder` action, checks for permissions, and performs an HTTP DELETE request.
   * Upon successful deletion, it dispatches a success action or a failure action if an error occurs.
   */
  deleteSalesReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.deleteSalesReminder),
      checkPermission(SalesRemindersActions.salesRemindersUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .delete<Reminder>(
            `${environment.apiUrl}/v1/sales/reminders/${uuid}`,
            {
              body: { saleId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              SalesRemindersActions.deleteSalesReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.deleteSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a sales reminder record.
   * It displays a success toast message and closes the sidebar.
   */
  deleteSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesRemindersActions.deleteSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to mark a sales reminder as checked.
   * It listens for the `checkSalesReminder` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful checking, it dispatches a success action or a failure action if an error occurs.
   */
  checkSalesReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.checkSalesReminder),
      checkPermission(SalesRemindersActions.salesRemindersUnauthorized),
      mergeMap(({ uuid, saleId }) => {
        return this.http
          .patch<void>(
            `${environment.apiUrl}/v1/sales/reminders/check/${uuid}`,
            { saleId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              SalesRemindersActions.checkSalesReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.checkSalesReminderFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to handle the success of marking a sales reminder as checked.
   * It displays a success toast message.
   */
  checkSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesRemindersActions.checkSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder checked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to mark a sales reminder as unchecked.
   * It listens for the `uncheckSalesReminder` action, checks for permissions, and performs an HTTP PATCH request.
   * Upon successful unchecking, it dispatches a success action or a failure action if an error occurs.
   */
  uncheckSalesReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesRemindersActions.uncheckSalesReminder),
      checkPermission(SalesRemindersActions.salesRemindersUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/sales/reminders/uncheck/${uuid}`,
            { saleId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              SalesRemindersActions.uncheckSalesReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesRemindersActions.uncheckSalesReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of marking a sales reminder as unchecked.
   * It displays a success toast message.
   */
  uncheckSalesReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesRemindersActions.uncheckSalesReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales reminder unchecked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesRemindersEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService — Injectable service for handling sidebar form operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
