<form class="h-full px-2" [formGroup]="goalForm" (ngSubmit)="onSubmit()">
  <!--
    <div class="flex flex-column gap-2 mb-4">
      <label class="color-dark-80 text-sm mx-0">
        Target <span class="text-red-600">*</span>
      </label>
      <ice-select
        [formControl]="target"
        optionLabel="label"
        optionValue="value"
        [suggestions]="(targets$ | async) ?? []"></ice-select>
    </div>

    <ng-container *ngIf="target.value">
      <ice-autocomplete
        label="Goal Type"
        placeholder="Ex: Sales note"
        [required]="true"
        [formControl]="goalType"
        [dropdown]="false"
        optionLabel="label"
        optionValue="value"
        [suggestions]="(goalsTypes$ | async) ?? []"></ice-autocomplete>

      <div>
        <label class="color-dark-80 text-sm mx-0">
          Goal Rate <span class="text-red-600">*</span>
        </label>
        <div class="flex align-items-center gap-3">
          <ice-input
            placeholder="Goal"
            [formControl]="goal"
            [number]="true"></ice-input>
          <b>/</b>
          <ice-autocomplete
            placeholder="Ex: Day"
            [dropdown]="false"
            [formControl]="rate"
            optionLabel="label"
            optionValue="value"
            [suggestions]="(rates$ | async) ?? []"></ice-autocomplete>
        </div>
      </div>

      <div class="flex flex-column gap-2">
        <label class="color-dark-80 text-sm mx-0">
          Scope <span class="text-red-600">*</span>
        </label>
        <ice-select
          [formControl]="scope"
          optionLabel="label"
          optionValue="value"
          [suggestions]="scopes"></ice-select>

        <ng-container [ngSwitch]="scope.value">
          <ng-container *ngSwitchCase="goalScope.Team">
            <ice-autocomplete
              [label]="'Team'"
              [required]="true"
              placeholder="Assign team"
              [formControl]="teamId"
              [iceAutocompleteTemplate]="templateEnum.DEFAULT"
              optionLabel="label"
              optionValue="value"
              [dropdown]="false"
              [suggestions]="(teams$ | async) ?? []"></ice-autocomplete>
          </ng-container>
          <ng-container *ngSwitchCase="goalScope.User">
            <ice-autocomplete
              [suggestions]="(users$ | async) ?? []"
              (completeMethod)="onSearch($event)"
              [formControl]="userId"
              [dropdown]="false"
              [iceAutocompleteTemplate]="templateEnum.USER"
              placeholder="Choose assignees"
              i18n-placeholder>
            </ice-autocomplete>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div class="sticky top-100">
      <p-divider></p-divider>

      <div
        class="flex flex-row-reverse justify-content-between align-items-center">
        <ice-button
          [rule]="iceRule.UpdateGoals"
          type="submit"
          class="flex justify-content-end"
          [disabled]="
            goalForm.invalid ||
            (scope.value === goalScopeEnum.Team && teamId.value === null) ||
            (scope.value === goalScopeEnum.User && userId.value === null)
          "
          [label]="goalUUID ? 'Update' : 'Create'"
          [loading]="(isLoading$ | async) ?? false">
        </ice-button>
        <ice-button
          [rule]="iceRule.DeleteGoals"
          *ngIf="goalUUID"
          (onClick)="deleteGoal()"
          label="Delete goal"
          i18n-label
          [text]="true"
          severity="danger"
          i18n-severity></ice-button>
      </div>
    </div>
    -->
  <div>
    <span>What's the name of your goal?</span>
    <ice-input
      class="w-full"
      [formControl]="name"
      placeholder="Ex: Create 5 profiles per day"
      [required]="true">
    </ice-input>
  </div>

  <div>
    <span>Which target ?</span>
    <ul class="mt-3 p-0">
      <li
        class="list-none my-2 flex gap-2"
        *ngFor="let moduleTarget of targets$ | async"
        [ngClass]="{
          unselected:
            target.value?.value && target.value?.value !== moduleTarget.value,
        }">
        <p-radioButton
          formControlName="target"
          [formControl]="target"
          [inputId]="moduleTarget.value"
          [value]="moduleTarget"></p-radioButton>
        <p
          [ngClass]="{
            selected:
              target.value?.value && target.value?.value === moduleTarget.value,
          }">
          {{ moduleTarget.label }}
        </p>
      </li>
    </ul>
  </div>

  <div class="mt-6" *ngIf="target.value">
    <span>Who ?</span>
    <ul class="mt-3 p-0">
      <li
        class="list-none my-2 flex gap-2"
        [ngClass]="{
          unselected:
            scope.value?.value && scope.value?.value !== scopes[0].value,
        }">
        <p-radioButton
          formControlName="scope"
          [formControl]="scope"
          [inputId]="scopes[0].value"
          [value]="scopes[0]"></p-radioButton>
        <p
          [ngClass]="{
            selected:
              scope.value?.value && scope.value?.value === scopes[0].value,
          }">
          The whole company
        </p>
      </li>
      <li
        class="list-none my-2 flex"
        [ngClass]="{
          unselected:
            scope.value?.value && scope.value?.value !== scopes[1].value,
        }">
        <p-radioButton
          formControlName="scope"
          [formControl]="scope"
          [inputId]="scopes[1].value"
          [value]="scopes[1]"></p-radioButton>
        <p
          [ngClass]="{
            selected:
              scope.value?.value && scope.value?.value === scopes[1].value,
          }">
          <new-ice-autocomplete
            placeholder="This team"
            [inline]="true"
            [formControl]="teamId"
            [iceAutocompleteTemplate]="templateEnum.DEFAULT"
            [suggestions$]="teams$"
            (completeMethod)="searchTeams($event)"></new-ice-autocomplete>
        </p>
      </li>
      <li
        class="list-none my-2 flex"
        [ngClass]="{
          unselected:
            scope.value?.value && scope.value?.value !== scopes[2].value,
        }">
        <p-radioButton
          formControlName="scope"
          [formControl]="scope"
          [inputId]="scopes[2].value"
          [value]="scopes[2]"></p-radioButton>
        <p
          [ngClass]="{
            selected:
              scope.value?.value && scope.value?.value === scopes[2].value,
          }">
          <new-ice-autocomplete
            placeholder="This user"
            [inline]="true"
            [formControl]="userId"
            [iceAutocompleteTemplate]="templateEnum.DEFAULT"
            [suggestions$]="users$"
            (completeMethod)="searchUsers($event)"></new-ice-autocomplete>
        </p>
      </li>
    </ul>
  </div>

  <div class="mt-6" *ngIf="target.value && scope.value">
    <span>What ?</span>
    <ul class="mt-3 p-0">
      <li
        class="list-none my-2 flex gap-2"
        *ngFor="let choice of goalsTypes$ | async"
        [ngClass]="{
          unselected:
            goalType.value?.value && goalType.value?.value !== choice.value,
        }">
        <p-radioButton
          formControlName="goalType"
          [formControl]="goalType"
          [inputId]="choice.value"
          [value]="choice"></p-radioButton>
        <p
          [ngClass]="{
            selected:
              goalType.value?.value && goalType.value?.value === choice.value,
          }">
          {{ choice.label }}
        </p>
      </li>
    </ul>
  </div>

  <div
    class="mt-6"
    *ngIf="
      target.value &&
      scope.value &&
      goalType.value &&
      (goalType.value.value === goalTypeEnum.CHANGE_STATUS_BETWEEN ||
        goalType.value.value === goalTypeEnum.CHANGE_TYPE ||
        goalType.value.value === goalTypeEnum.DOCUMENT_UPLOADED)
    ">
    <span>How ?</span>
    <ng-container [ngSwitch]="goalType.value.value">
      <ng-container *ngSwitchCase="goalTypeEnum.CHANGE_STATUS_BETWEEN">
        <ng-container *ngIf="target.value.value === 'profile'">
          <div class="flex align-items-center">
            <span>Status</span>
            <ice-button
              [text]="true"
              (onClick)="swapStatuses()"
              icon="pi pi-arrow-right-arrow-left"></ice-button>
          </div>
          <div class="flex align-items-center gap-4">
            <new-ice-autocomplete
              placeholder="Old Status"
              [formControl]="oldStatus"
              [iceAutocompleteTemplate]="iceAutocompleteTemplateEnum.STATUS"
              [suggestions$]="oldProfilesStatuses$"></new-ice-autocomplete>
            <i class="pi pi-arrow-right pb-2"></i>
            <new-ice-autocomplete
              placeholder="New Status"
              [formControl]="newStatus"
              [iceAutocompleteTemplate]="iceAutocompleteTemplateEnum.STATUS"
              [suggestions$]="newProfilesStatuses$"></new-ice-autocomplete>
          </div>
        </ng-container>
        <ng-container *ngIf="target.value.value === 'sale'">
          <div class="flex align-items-center">
            <span class="my-4">Status</span>
            <ice-button
              [text]="true"
              (onClick)="swapStatuses()"
              icon="pi pi-arrow-right-arrow-left"></ice-button>
          </div>
          <div class="flex align-items-center gap-4">
            <new-ice-autocomplete
              placeholder="Old Status"
              [formControl]="oldStatus"
              [iceAutocompleteTemplate]="iceAutocompleteTemplateEnum.STATUS"
              [suggestions$]="oldSalesStatuses$"></new-ice-autocomplete>
            <i class="pi pi-arrow-right pb-2"></i>
            <new-ice-autocomplete
              placeholder="New Status"
              [formControl]="newStatus"
              [iceAutocompleteTemplate]="iceAutocompleteTemplateEnum.STATUS"
              [suggestions$]="newSalesStatuses$"></new-ice-autocomplete>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="goalTypeEnum.CHANGE_TYPE">
        <div class="flex align-items-center">
          <span class="my-4">Type</span>
          <ice-button
            [text]="true"
            (onClick)="swapType()"
            icon="pi pi-arrow-right-arrow-left"></ice-button>
        </div>
        <div class="flex align-items-center gap-4">
          <img
            width="85"
            height="85"
            [ngSrc]="
              'assets/icons/modules/' +
              ((isLightTheme$ | async) ? 'light/' : 'dark/') +
              oldType.value.value +
              '.svg'
            " />
          <i class="pi pi-arrow-right pb-2"></i>
          <img
            width="85"
            height="85"
            [ngSrc]="
              'assets/icons/modules/' +
              ((isLightTheme$ | async) ? 'light/' : 'dark/') +
              newType.value.value +
              '.svg'
            " />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="goalTypeEnum.DOCUMENT_UPLOADED">
        <div>
          <span>Document</span>
          <new-ice-autocomplete
            placeholder="Document Type"
            [formControl]="fileType"
            [suggestions$]="documentTypes$"></new-ice-autocomplete>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="mt-6" *ngIf="target.value && scope.value && goalType.value">
    <span>How Often ?</span>
    <div class="flex justify-content-start align-items-center mt-2">
      <ice-input
        class="max-w-3rem"
        [formControl]="goal"
        [inline]="true"
        placeholder="2"></ice-input>
      <span>times</span>
      <new-ice-autocomplete
        class="max-w-10rem"
        placeholder="Week"
        [formControl]="rate"
        [suggestions$]="rates$"
        [inline]="true"></new-ice-autocomplete>
    </div>
  </div>

  <div
    class="mt-6 flex flex-col gap-2"
    *ngIf="target.value && scope.value && goalType.value && rate.value">
    <div class="flex align-items-center">
      <label for="hasNotification" class="color-dark-80 text-sm">
        Do you want notification ?
      </label>
      <p-inputSwitch
        id="hasNotification"
        [formControl]="hasNotification"
        class="ml-2">
      </p-inputSwitch>
    </div>
  </div>

  <div class="sticky bottom-0 top-100">
    <p-divider></p-divider>
    <div class="flex flex-row-reverse justify-content-between">
      <ice-button
        type="submit"
        [rule]="goalUUID ? iceRule.UpdateProfileTag : iceRule.CreateProfileTag"
        [label]="goalUUID ? 'Update' : 'Create'"
        [disabled]="!isFormValid"
        [loading]="(isLoading$ | async) ?? false">
      </ice-button>
      <ice-button
        *ngIf="goalUUID"
        [rule]="iceRule.DeleteProfileTag"
        (onClick)="deleteGoal()"
        type="button"
        label="Delete"
        i18n-label
        [text]="true"
        severity="danger"
        i18n-severity>
      </ice-button>
    </div>
  </div>

  <div class="sticky bottom-0 top-100">
    <p-divider></p-divider>
    <div class="flex flex-row-reverse justify-content-between">
      <ice-button
        type="submit"
        [rule]="goalUUID ? iceRule.UpdateProfileTag : iceRule.CreateProfileTag"
        [label]="goalUUID ? 'Update' : 'Create'"
        [disabled]="!isFormValid"
        [loading]="(isLoading$ | async) ?? false"></ice-button>
      <ice-button
        *ngIf="goalUUID"
        [rule]="iceRule.DeleteProfileTag"
        (onClick)="deleteGoal()"
        type="button"
        label="Delete"
        i18n-label
        [text]="true"
        severity="danger"
        i18n-severity></ice-button>
    </div>
  </div>
</form>
