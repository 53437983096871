<h1 i18n>Typography Documentation</h1>

<section class="surface-card">
  <h1 i18n>Title level 1</h1>
  <h2 i18n>Title level 2</h2>
  <h3 i18n>Title level 3</h3>
  <h4 i18n>Title level 4</h4>

  <div i18n class="ice-subtitle-1">This is a subtitle level 1</div>
  <div i18n class="ice-subtitle-2">This is a subtitle level 2</div>
  <div i18n class="ice-body-1">This is body text level 1</div>
  <div i18n class="ice-body-2">This is body text level 2</div>
  <div i18n class="ice-caption">This is a caption</div>

  <p i18n class="ice-body-1">
    Body text is the main content of your page. It should be easy to read and
    scannable.
  </p>

  <p i18n class="ice-body-2">
    You can use different font sizes and styles to make your body text more
    interesting. For example, you could use a larger font size for headings or a
    bold font for important information.
  </p>
  <p i18n class="ice-overline">
    Overline text is used to highlight important information.
  </p>
</section>
