<form [formGroup]="offerForm" (ngSubmit)="onSubmit()">
  <ice-input
    [label]="'Title'"
    [placeholder]="'Title'"
    [formControlName]="'title'"
    [formControl]="title"
    [required]="true"></ice-input>

  <div>
    <span class="block text-900 font-semibold text-sm mb-1">
      Start Date <span class="text-red-600">*</span>
    </span>
    <p-calendar
      dateFormat="yy-mm-dd"
      [placeholder]="'2024-07-12'"
      i18n-dateFormat
      [formControlName]="'startDate'"
      [formControl]="startDate"
      appendTo="body"
      i18n-appendTo></p-calendar>
  </div>

  <div class="my-6">
    <p-divider class="my-6"></p-divider>
  </div>

  <div class="flex flex-column sm:flex-row gap-2">
    <ice-autocomplete
      [required]="true"
      [optionLabel]="'label'"
      [optionValue]="'value'"
      placeholder="Offer Visibility"
      i18n-placeholder
      label="Visibility"
      i18n-label
      [suggestions]="(offerVisibilityTypes$ | async) ?? []"
      [formControlName]="'visibility'"
      [dropdown]="false"
      [formControl]="visibility">
      ></ice-autocomplete
    >
    <ice-autocomplete
      *ngIf="visibilityValue !== 'public'"
      [required]="true"
      label="Reference"
      i18n-label
      [suggestions]="(groupReferenceId$ | async) ?? []"
      [placeholder]="'Group Reference ID'"
      [formControlName]="'groupReferenceId'"
      optionLabel="label"
      optionValue="value"
      i18n-optionLabel
      [dropdown]="false"
      [formControl]="groupReferenceId">
    </ice-autocomplete>
  </div>

  <div class="flex flex-column sm:flex-row gap-2 mt-4">
    <div class="text-900 font-medium mb-2">
      <span class="block text-900 font-semibold text-sm mb-1">
        Rate <span class="text-red-600">*</span>
      </span>
      <p-inputNumber
        formControlName="rate"
        mode="currency"
        i18n-mode
        currency="EUR"
        i18n-currency
        [required]="true"
        [showButtons]="true"
        [min]="0"
        [step]="50"
        [formControl]="rate"
        inputId="rate"
        i18n-inputId>
      </p-inputNumber>
    </div>
    <ice-autocomplete
      label="Rate Unit"
      [required]="true"
      [suggestions]="(offerUnitTypes$ | async) ?? []"
      [placeholder]="'Rate Unit'"
      [formControlName]="'rateUnit'"
      [optionValue]="'value'"
      [optionLabel]="'label'"
      [dropdown]="false"
      [formControl]="rateUnit"></ice-autocomplete>
  </div>

  <h4 i18n>Team</h4>
  <div class="flex flex-column sm:flex-row justify-content-center gap-4 mt-4">
    <div class="flex flex-column gap-2">
      <div class="block text-800 font-medium">
        <app-ice-info-tooltip
          appendTo="body"
          i18n-appendTo
          text="Voici plus d'informations sur ce sujet."
          i18n-text></app-ice-info-tooltip>
        Proposal Limit
        <span class="text-red-600">*</span>
      </div>
      <div class="border-round border-1 surface-border inline-flex mb-5">
        <p-inputNumber
          [showButtons]="true"
          buttonLayout="horizontal"
          i18n-buttonLayout
          formControlName="proposalLimit"
          [min]="0"
          inputStyleClass="w-3rem text-center border-transparent"
          i18n-inputStyleClass
          decrementButtonClass="p-button-text p-button-danger"
          i18n-decrementButtonClass
          incrementButtonClass="p-button-text p-button-success"
          i18n-incrementButtonClass
          incrementButtonIcon="pi pi-user-plus"
          i18n-incrementButtonIcon
          decrementButtonIcon="pi pi-user-minus"
          i18n-decrementButtonIcon></p-inputNumber>
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <div class="block text-800 font-medium">
        <app-ice-info-tooltip
          appendTo="body"
          i18n-appendTo
          text="Voici plus d'informations sur ce sujet."
          i18n-text></app-ice-info-tooltip>
        Minimum Profiles
        <span class="text-red-600">*</span>
      </div>
      <div class="border-round border-1 surface-border inline-flex mb-5">
        <p-inputNumber
          [showButtons]="true"
          formControlName="minimumProfiles"
          buttonLayout="horizontal"
          i18n-buttonLayout
          [min]="0"
          inputStyleClass="w-3rem text-center border-transparent"
          i18n-inputStyleClass
          decrementButtonClass="p-button-text p-button-danger"
          i18n-decrementButtonClass
          incrementButtonClass="p-button-text p-button-success"
          i18n-incrementButtonClass
          incrementButtonIcon="pi pi-user-plus"
          i18n-incrementButtonIcon
          decrementButtonIcon="pi pi-user-minus"
          i18n-decrementButtonIcon></p-inputNumber>
      </div>
    </div>
  </div>

  <p-divider></p-divider>
  <div>
    <h4 i18n>Skills</h4>
    <div
      class="flex align-items-center bg-blue-100 text-bluegray-500 text-sm px-3-5 gap-3-5 border-round">
      <i class="pi pi-arrow-down"></i>
      <span i18n><p>Defines some required skills.</p></span>
    </div>

    <div class="flex flex-column md:flex-row align-items-center gap-2 mt-1">
      <ice-autocomplete
        [dropdown]="false"
        [optionLabel]="'label'"
        [formControl]="skill"
        formControlName="skill"
        placeholder="Choose a skill"
        i18n-placeholder
        (onSelect)="onSelectSkill($event.value)"
        (completeMethod)="searchSkills($event)"
        [suggestions]="(skills$ | async) ?? []"></ice-autocomplete>

      <ice-autocomplete
        [suggestions]="(skillExperiences$ | async) ?? []"
        [formControl]="skillExperience"
        formControlName="skillExperience"
        [placeholder]="'Experience'"
        [dropdown]="false"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel></ice-autocomplete>

      <ice-button
        class="mb-2"
        type="button"
        label="Add"
        i18n-label
        [disabled]="!skill.value || !skillExperience.value"
        (onClick)="addSkill()"></ice-button>
    </div>
    <div
      *ngIf="skills.controls.length"
      class="border-2 border-dashed border-round surface-border surface-section flex align-items-center justify-content-center mt-2"
      style="min-height: 6rem">
      <p i18n class="text-400 font-semibold">No skills added yet.</p>
    </div>
  </div>
  <div formArrayName="skills" class="mt-4">
    <div *ngFor="let skillGroup of skills.controls; let i = index">
      <p-divider></p-divider>
      <div
        class="flex gap-1 justify-content-between align-items-center ml-2"
        [formGroupName]="i">
        <div class="text-900 font-medium flex flex-column">
          <span>{{ getSkill(i).value.skillId.label }}</span>
          <span i18n>
            Expertise:
            {{
              getSkill(i).value.experienceMinimum
                | capitalizeWithSpace
                | titlecase
            }}
          </span>
        </div>
        <ice-button
          type="button"
          icon="pi pi-times"
          i18n-icon
          [rounded]="true"
          [text]="true"
          severity="danger"
          i18n-severity
          (onClick)="removeSkill(i)"></ice-button>
      </div>
    </div>
  </div>
  <p-divider></p-divider>

  <div>
    <h4 i18n>Required Files</h4>
    <div
      class="flex align-items-center bg-blue-100 text-bluegray-500 text-sm px-3-5 gap-3-5 border-round">
      <i class="pi pi-arrow-down"></i>
      <span i18n><p>Defines some required files.</p></span>
    </div>
    <div class="flex gap-2 align-items-center mt-1">
      <ice-autocomplete
        [dropdown]="false"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [formControl]="fileType"
        formControlName="fileType"
        placeholder="Choose a document"
        i18n-placeholder
        [suggestions]="(offerDocumentTypes$ | async) ?? []"></ice-autocomplete>

      <ice-button
        class="mb-2"
        [label]="'Add'"
        [disabled]="!fileType.value"
        (onClick)="addDocument()"></ice-button>
    </div>
    <div
      *ngIf="documents.controls.length < 1"
      class="border-2 border-dashed border-round surface-border surface-section flex align-items-center justify-content-center mt-2"
      style="min-height: 6rem">
      <p i18n class="text-400 font-semibold">No Documents added yet.</p>
    </div>
    <div formArrayName="documents" class="mt-4">
      <div *ngFor="let documentGroup of documents.controls; let i = index">
        <p-divider></p-divider>
        <div
          class="flex gap-2 justify-content-between align-items-center ml-2"
          [formGroupName]="i">
          <div>
            <span i18n class="text-900 font-medium">
              Document:
              {{ getDocument(i).value.value | capitalizeWithSpace | titlecase }}
            </span>
          </div>
          <ice-button
            type="button"
            icon="pi pi-times"
            i18n-icon
            [rounded]="true"
            [text]="true"
            severity="danger"
            i18n-severity
            (onClick)="removeDocument(i)"></ice-button>
        </div>
      </div>
    </div>
  </div>

  <p-divider></p-divider>
  <p-editor
    [style]="{ height: '320px', maxWidth: '753px' }"
    formControlName="description">
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <select
          class="ql-header"
          title="Heading"
          i18n-title
          aria-label="Heading"
          i18n-aria-label></select>
        <button
          type="button"
          class="ql-bold"
          aria-label="Bold"
          i18n-aria-label></button>
        <button
          type="button"
          class="ql-italic"
          aria-label="Italic"
          i18n-aria-label></button>
        <button
          type="button"
          class="ql-underline"
          aria-label="Underline"
          i18n-aria-label></button>
        <button
          type="button"
          class="ql-list"
          value="bullet"
          aria-label="Unordered List"
          i18n-aria-label></button>
        <button
          type="button"
          class="ql-link"
          aria-label="Insert Link"
          i18n-aria-label></button>
        <select
          class="ql-align"
          title="Align"
          i18n-title
          aria-label="Align"
          i18n-aria-label></select>
      </span>
    </ng-template>
  </p-editor>

  <div class="mt-7">
    <h4 i18n>Adress</h4>

    <div class="flex flex-column sm:flex-row gap-2">
      <ice-input
        [label]="'Street Address'"
        [placeholder]="'Enter street address'"
        [formControlName]="'streetAddress'"
        [formControl]="streetAddress"></ice-input>
      <ice-input
        [label]="'City'"
        [placeholder]="'Enter city'"
        [formControlName]="'city'"
        [formControl]="city"></ice-input>
    </div>
    <div class="flex flex-column sm:flex-row gap-2">
      <ice-input
        [label]="'Postal Code'"
        [placeholder]="'Enter postal code'"
        [formControlName]="'postalCode'"
        [formControl]="postalCode"></ice-input>
      <ice-input
        [label]="'Country'"
        [placeholder]="'Enter country'"
        [formControlName]="'country'"
        [formControl]="country"></ice-input>
    </div>
  </div>

  <!-- Submit and Delete Buttons -->
  <div class="sticky bottom-0 bg-light-100">
    <p-divider></p-divider>
    <ice-button
      type="submit"
      label="Create"
      i18n-label
      [disabled]="offerForm.invalid"
      class="mr-2 flex justify-content-end"
      [loading]="(isLoading$ | async) ?? false"></ice-button>
  </div>
</form>
