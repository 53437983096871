import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Notifications } from '@npmicedev/icemodule/lib/entities/Notifications';
import * as NotificationActions from '~/app/states/main/states/notification/notification.actions';
import { environment } from '~/environments/environment';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { IsseNotificationService } from '~/app/states/main/services/isse-notification.service';

/**
 * `NotificationEffects` manages the side effects for notification-related actions.
 * This class uses NgRx Effects to handle asynchronous operations related to notifications,
 * such as loading notifications, creating, updating, and deleting notifications.
 */
@Injectable()
export class NotificationEffects {
  /**
   * Effect to load all notifications.
   * Listens for the `loadAllNotifications` action, makes an HTTP GET request to fetch notifications.
   * Dispatches a success or failure action based on the result.
   */
  loadAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadAllNotifications),
      mergeMap(() => {
        return this.http
          .get<Notifications[]>(`${environment.apiUrl}/v1/notification/`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(notifications => {
              return NotificationActions.loadAllNotificationsSuccess({
                notifications,
              });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                NotificationActions.loadAllNotificationsFailure({
                  error: error.message || 'Unknown error',
                })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to mark a notification as read.
   * Listens for the `markNotificationAsRead` action, makes an HTTP PATCH request to update the notification's read status.
   * Dispatches a success or failure action based on the result of the HTTP request.
   *
   * - On success, dispatches `markNotificationAsReadSuccess` with the notification UUID.
   * - On failure, dispatches `markNotificationAsReadFailure` with the error message.
   */
  markNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.markNotificationAsRead),
      mergeMap(({ uuid }) =>
        this.http
          .patch<void>(`${environment.apiUrl}/v1/notification/${uuid}/read`, {})
          .pipe(
            map(() =>
              NotificationActions.markNotificationAsReadSuccess({ uuid })
            ),
            catchError(error =>
              of(
                NotificationActions.markNotificationAsReadFailure({
                  error: error.message || 'Unknown error',
                })
              )
            )
          )
      )
    )
  );

  /**
   * Effect to mark all notifications as read.
   *
   * - Listens for the `markAllNotificationsAsRead` action.
   * - Sends an HTTP PATCH request to mark all notifications as read.
   * - If the request succeeds, dispatches `markAllNotificationsAsReadSuccess`.
   * - If the request fails, dispatches `markAllNotificationsAsReadFailure` with the error message.
   */
  markAllNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.markAllNotificationsAsRead),
      mergeMap(() =>
        this.http
          .patch<void>(
            `${environment.apiUrl}/v1/notification/mark-all-read`,
            {}
          )
          .pipe(
            map(() => NotificationActions.markAllNotificationsAsReadSuccess()),
            catchError(error =>
              of(
                NotificationActions.markAllNotificationsAsReadFailure({
                  error: error.message || 'Unknown error',
                })
              )
            )
          )
      )
    )
  );

  /**
   * Effect to delete a notification.
   * Listens for the `deleteNotification` action, makes an HTTP DELETE request to remove the notification.
   * Dispatches a success or failure action based on the result.
   */
  deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.deleteNotification),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/notification/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            map(() => NotificationActions.deleteNotificationSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                NotificationActions.deleteNotificationFailure({
                  error: error.message || 'Unknown error',
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to delete all notifications.
   * Listens for the `deleteAllNotifications` action, makes an HTTP DELETE request to remove all notifications.
   * Dispatches a success or failure action based on the result.
   */
  deleteAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.deleteAllNotifications),
      mergeMap(() =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/notification/delete-all`, {
            withCredentials: true,
          })
          .pipe(
            map(() => NotificationActions.deleteAllNotificationsSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                NotificationActions.deleteAllNotificationsFailure({
                  error: error.message || 'Unknown error',
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to connect to a channel.
   * Listens for the `connectToChannel` action, checks permissions, and performs an HTTP POST request.
   * Dispatches a success or failure action based on the result.
   */
  connectToChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.connectToChannel),
      checkPermission(NotificationActions.notificationsUnauthorized),
      mergeMap(({ connectionDetails }) =>
        this.http
          .post<void>(
            `${environment.isseUrl}/v1/auth/connect`,
            connectionDetails,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => {
              this.isseService.listenToChannel();
              return NotificationActions.connectToChannelSuccess();
            }),
            catchError(error => {
              return of(NotificationActions.connectToChannelFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for NotificationEffects.
   * Injects the necessary services to handle the effects and dispatch actions.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - The service used to display toast notifications.
   * @param {IsseNotificationService} isseService - Instance for managing SSE notifications and channels.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private isseService: IsseNotificationService
  ) {}
}
