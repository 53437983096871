import { createReducer, on } from '@ngrx/store';
import * as ProfileReferencesActions from '~/app/states/main/crm/profile/states/profile-references/profile-references.actions';
import { initialProfileReferencesState } from '~/app/states/main/crm/profile/states/profile-references/profile-references.state';

export const profileReferencesReducer = createReducer(
  initialProfileReferencesState,

  on(ProfileReferencesActions.loadProfileReferences, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileReferencesActions.loadProfileReferencesSuccess,
    (state, { profileReferences }) => ({
      ...state,
      profileReferences,
      loading: false,
    })
  ),
  on(
    ProfileReferencesActions.loadProfileReferencesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileReferencesActions.createProfileReference, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileReferencesActions.createProfileReferenceSuccess,
    (state, { profileReference }) => ({
      ...state,
      profileReferences: [...state.profileReferences, profileReference],
      loading: false,
    })
  ),
  on(
    ProfileReferencesActions.createProfileReferenceFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileReferencesActions.updateProfileReference, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileReferencesActions.updateProfileReferenceSuccess,
    (state, { profileReference }) => ({
      ...state,
      profileReferences: state.profileReferences.map(item =>
        item.uuid === profileReference.uuid ? profileReference : item
      ),
      loading: false,
    })
  ),
  on(
    ProfileReferencesActions.updateProfileReferenceFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileReferencesActions.deleteProfileReference, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileReferencesActions.deleteProfileReferenceSuccess,
    (state, { uuid }) => ({
      ...state,
      profileReferences: state.profileReferences.filter(
        item => item.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    ProfileReferencesActions.deleteProfileReferenceFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileReferencesActions.profileReferenceUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
