import { Team } from '~/app/shared/interfaces/crm/team/team.interface';

/**
 * Represents the state of team settings in the application.
 *
 * @interface AllTeamsState
 */
export interface AllTeamsState {
  /**
   * The maximum number of teams per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * Indicates whether there are previous pages available.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Indicates whether there are next pages available.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * The total number of teams.
   *
   * @type {number}
   */
  count: number;

  /**
   * The list of teams for the current page.
   *
   * @type {Team[]}
   */
  data: Team[];

  /**
   * The currently selected team or null if no team is selected.
   *
   * @type {Team | null}
   */
  selectedTeam: Team | null;

  /**
   * Indicates whether the team settings are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message if an error occurred, otherwise null.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for all teams, including limit, page, pagination indicators,
 * count, data, selected team, loading status, and error information.
 *
 * @constant
 * @type {AllTeamsState}
 */
export const initialAllTeamsState: AllTeamsState = {
  limit: 10,
  page: 0,
  hasPrevious: false,
  hasNext: false,
  count: 0,
  data: [],
  selectedTeam: null,
  loading: false,
  error: null,
};
