import { createReducer, on } from '@ngrx/store';
import * as SocialNetworksActions from '~/app/states/main/crm/profile/states/profile-social-networks/profile-social-networks.actions';
import { initialSocialNetworksState } from '~/app/states/main/crm/profile/states/profile-social-networks/profile-social-networks.state';

export const socialNetworkReducer = createReducer(
  initialSocialNetworksState,

  on(SocialNetworksActions.loadSocialNetworks, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SocialNetworksActions.loadSocialNetworksSuccess,
    (state, { socialNetworks }) => ({
      ...state,
      socialNetworks,
      loading: false,
    })
  ),
  on(SocialNetworksActions.loadSocialNetworksFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SocialNetworksActions.createSocialNetwork, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SocialNetworksActions.createSocialNetworkSuccess,
    (state, { socialNetwork }) => ({
      ...state,
      socialNetworks: [...state.socialNetworks, socialNetwork],
      loading: false,
    })
  ),
  on(SocialNetworksActions.createSocialNetworkFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SocialNetworksActions.updateSocialNetwork, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SocialNetworksActions.updateSocialNetworkSuccess,
    (state, { socialNetwork }) => ({
      ...state,
      socialNetworks: state.socialNetworks.map(item =>
        item.uuid === socialNetwork.uuid ? { ...item, ...socialNetwork } : item
      ),
      loading: false,
    })
  ),
  on(SocialNetworksActions.updateSocialNetworkFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SocialNetworksActions.deleteSocialNetwork, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SocialNetworksActions.deleteSocialNetworkSuccess, (state, { uuid }) => ({
    ...state,
    socialNetworks: state.socialNetworks.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(SocialNetworksActions.deleteSocialNetworkFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    SocialNetworksActions.profileSocialNetworksUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
