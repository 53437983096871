<div class="flex align-items-center gap-3">
  <div *ngFor="let option of options" class="gap-1">
    <p-radioButton
      #iceRadioButton
      [id]="option.value"
      [value]="option.value"
      [formControl]="formControl"
      [appFormError]="formControlName"
      (formError)="onErrorMessageReceived($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [isSubmitted]="isSubmitted"></p-radioButton>
    <label [for]="option.value" class="ml-2">{{ option.label }}</label>
  </div>
</div>
<div *ngIf="error" class="text-xs text-red-600 absolute">
  {{ error }}
</div>
