import { Language } from '~/app/shared/interfaces/shared/language.interface';

/**
 * Represents the state of languages in the application.
 *
 * @interface LanguagesState
 */
export interface LanguagesState {
  /**
   * The list of languages.
   *
   * @type {Language[]}
   */
  languages: Language[];

  /**
   * Indicates if the languages are being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Initial state for the languages.
 *
 * @type {LanguagesState}
 */
export const initialLanguagesState: LanguagesState = {
  languages: [],
  loading: false,
  error: null,
};
