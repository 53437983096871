/**
 * Enum for defining template types for an IceAutocomplete component.
 * This enum helps in specifying the visual and functional layout of the autocomplete component based on the context it is used within.
 *
 * @enum {number}
 * @property {number} DEFAULT - The default template, used when no specific UI customization is needed just to display the label.
 * @property {number} STATUS - A template designed for displaying status-like options.
 * @property {number} SOCIAL - A template ideal for social media context or elements, potentially displaying social media handles or platforms.
 * @property {number} TAG - A template used for tagging purposes, suitable for tag selection or display.
 * @property {number} USER - A template designed specifically for user-related information, such as selecting users from a list.
 */
export enum IceAutocompleteTemplateEnum {
  DEFAULT,
  STATUS,
  SOCIAL,
  TAG,
  USER,
}
