<div class="flex flex-column gap-3-5">
  <landing-header></landing-header>
  <section class="flex flex-column custom-margin">
    <div class="image-container relative">
      <img
        ngSrc="assets/ice-background.webp"
        priority
        fill
        alt="IceBerry wallpaper"
        [@heroImage]="true"
        class="border-round-2-5xl" />
      <h4
        class="md:hidden centered color-light-100-forever display-h4 m-0"
        [@heroText]
        style="max-width: 62rem; width: 100%">
        <span class="color-dark-20">Find your</span> dream job today!
      </h4>
      <h2
        class="hidden md:block centered color-light-100-forever display-h2 m-0"
        [@heroText]
        style="max-width: 62rem; width: 100%">
        Elevate your career
        <span [ngClass]="isDarkTheme ? 'color-light-20' : 'color-dark-20'"
          >and find your</span
        >
        dream job today!
      </h2>
    </div>
    <div class="px-4">
      <div
        class="mx-auto margin-custom p-2 md:p-3 bg-light-100 border-round-2-5xl translate-y">
        <form
          [formGroup]="searchForm"
          class="flex align-items-center px-3-5 border-1 border-dark-10 border-round-2xl">
          <input
            class="w-full h-3rem bg-light-100"
            style="color: var(--color-dark-100)"
            placeholder="Your dream job"
            type="text"
            formControlName="title"
            (keydown.enter)="onSubmit()"
            i18n-placeholder />
          <div class="flex align-items-center px-3-5 py-3">
            <div class="vertical-divider"><br /></div>
          </div>
          <input
            class="w-full h-3rem bg-light-100"
            placeholder="City"
            style="color: var(--color-dark-100)"
            type="text"
            i18n-placeholder
            (keydown.enter)="onSubmit()"
            formControlName="city" /><br />
          <ice-button
            [icon]="'pi pi-search'"
            label="Search"
            i18n-label
            i18n-icon
            (onClick)="onSubmit()"></ice-button>
        </form>
        <div
          class="flex flex-column gap-0 md:flex-row gap-3-5 mt-2 justify-content-center align-items-center">
          <p class="text-bold-sm md:text-bold-base m-0 pl-3 hidden md:flex">
            Suggested:
          </p>
          <div class="scrollable">
            <p-tabView
              [(activeIndex)]="defaultTabIndex"
              [scrollable]="true"
              (onChange)="onTabChange($event)">
              <p-tabPanel
                *ngFor="let tab of scrollableTabs"
                [header]="tab.title"></p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
