import { createSelector, MemoizedSelector } from '@ngrx/store';
import { selectShuffleListState } from '~/app/states/main/shuffle-list/shuffle-list.selector';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';

export const selectAllTeamsState = createSelector(
  selectShuffleListState,
  state => state.allTeams
);

export const selectAllTeams = createSelector(
  selectAllTeamsState,
  state => state.data
);

export const selectTeamLoading = createSelector(
  selectAllTeamsState,
  state => state.loading
);

export const selectSelectedTeam = (
  uuid: string
): MemoizedSelector<
  object,
  Team | undefined,
  (s1: Team[]) => Team | undefined
> =>
  createSelector(selectAllTeams, teams =>
    teams.find(team => team.uuid === uuid)
  );
