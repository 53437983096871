import {
  AfterContentChecked,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { IceTab } from '~/app/shared/interfaces/shared/ice-components/ice-tab.interface';

/**
 * IceTabsComponent provides a tabbed interface for displaying content based on selected tabs.
 * It manages the current active tab and updates the tab indicator accordingly.
 *
 * @Component
 * @selector 'ice-tabs' — CSS selector that identifies this component in a template.
 * @templateUrl './ice-tabs.component.html' — Path to the HTML template for this component.
 * @styleUrl './ice-tabs.component.scss' — Path to the stylesheet for this component.
 * @animations [] — No animations are used in this component.
 */
@Component({
  selector: 'ice-tabs',
  templateUrl: './ice-tabs.component.html',
  styleUrls: ['./ice-tabs.component.scss'],
  animations: [],
})
export class IceTabsComponent implements OnInit, AfterContentChecked {
  /**
   * An array of tabs to be displayed in the tabbed interface.
   * @type {IceTab[]}
   */
  @Input({ required: true }) tabs!: IceTab[];

  /**
   * A collection of templates provided for each tab content.
   * @type {QueryList<TemplateRef<any>>}
   */
  @ContentChildren(TemplateRef) templates!: QueryList<TemplateRef<any>>;

  /**
   * The currently selected tab's value.
   * @type {string}
   */
  selectedTab!: string;

  /**
   * The width of the currently selected tab indicator.
   * @type {number}
   */
  tabWidth = 0;

  /**
   * The left position of the current tab indicator.
   * @type {number}
   */
  tabLeft = 0;

  /**
   * The height of the currently selected tab indicator.
   * @type {number}
   */
  tabHeight = 0;

  /**
   * The current template being displayed for the selected tab.
   * @type {TemplateRef<any>}
   */
  currentTemplate!: TemplateRef<any>;

  /**
   * Constructor for IceTabsComponent.
   *
   * @param {ElementRef} el — Provides a reference to the host DOM element.
   * @param {Renderer2} renderer — Used to manipulate the DOM elements.
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  /**
   * Host listener for window resize events to update the tab indicator.
   * @return {void}
   */
  @HostListener('window:resize')
  onResize(): void {
    this.updateTabIndicator();
  }

  /**
   * OnInit lifecycle hook that initializes the component.
   * Sets the selected tab to the first tab if available.
   * @return {void}
   * @throws {Error} If no tabs are provided.
   */
  ngOnInit(): void {
    if (this.tabs.length > 0) {
      this.selectedTab = this.tabs[0].value;
    } else {
      throw Error('No tabs selected.');
    }
  }

  /**
   * AfterContentChecked lifecycle hook that updates the tab indicator
   * and current template based on the selected tab.
   * @return {void}
   */
  ngAfterContentChecked(): void {
    this.updateTabIndicator();
    this.updateCurrentTemplate();
  }

  /**
   * Selects the tab with the specified value as the active tab.
   * Updates the tab indicator and the currently displayed template.
   * @param {string} tabValue — The value of the tab to select.
   * @return {void}
   */
  selectTab(tabValue: string): void {
    this.selectedTab = tabValue;
    this.updateTabIndicator();
    this.updateCurrentTemplate();
  }

  /**
   * Updates the tab indicator's width, left position, and height
   * based on the currently selected tab.
   * @return {void}
   */
  updateTabIndicator(): void {
    const tabElements = this.el.nativeElement.querySelectorAll('li');
    const selectedTabIndex = this.tabs.findIndex(
      tab => tab.value === this.selectedTab
    );

    if (tabElements[selectedTabIndex]) {
      this.tabWidth = tabElements[selectedTabIndex].offsetWidth;
      this.tabLeft = tabElements[selectedTabIndex].offsetLeft;
      this.tabHeight = tabElements[selectedTabIndex].offsetHeight;
    }
  }

  /**
   * Updates the current template to match the selected tab.
   * @return {void}
   */
  private updateCurrentTemplate(): void {
    // Match the selected tab with the corresponding template
    const index = this.tabs.findIndex(tab => tab.value === this.selectedTab);
    this.currentTemplate = this.templates.toArray()[index];
  }
}
