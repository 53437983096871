import { createReducer, on } from '@ngrx/store';
import * as HomeSalesRemindersActions from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.actions';
import { initialHomeSalesRemindersState } from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.state';

export const homeSalesRemindersReducer = createReducer(
  initialHomeSalesRemindersState,

  on(HomeSalesRemindersActions.loadHomeSalesReminders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    HomeSalesRemindersActions.loadHomeSalesRemindersSuccess,
    (state, { salesReminders, filters }) => ({
      ...state,
      ...salesReminders,
      currentDate: filters ? (filters.date ? filters.date : '') : '',
      loading: false,
    })
  ),
  on(
    HomeSalesRemindersActions.loadHomeSalesRemindersFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  on(HomeSalesRemindersActions.checkSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    HomeSalesRemindersActions.checkSalesReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      data: state.data.map(reminder =>
        reminder.uuid === uuid
          ? { ...reminder, doneAt: new Date().toISOString() }
          : reminder
      ),
      loading: false,
    })
  ),
  on(
    HomeSalesRemindersActions.checkSalesReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(HomeSalesRemindersActions.uncheckSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    HomeSalesRemindersActions.uncheckSalesReminderSuccess,
    (state, { uuid }) => ({
      ...state,
      data: state.data.map(reminder =>
        reminder.uuid === uuid ? { ...reminder, doneAt: null } : reminder
      ),
      loading: false,
    })
  ),
  on(
    HomeSalesRemindersActions.uncheckSalesReminderFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    HomeSalesRemindersActions.homeSalesRemindersUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
