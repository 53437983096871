import { createReducer, on } from '@ngrx/store';
import { initialClientReducerState } from '~/app/states/main/crm/ai-search/states/client/client.state';
import * as AiClientActions from '~/app/states/main/crm/ai-search/states/client/client.actions';

export const aiClientReducer = createReducer(
  initialClientReducerState,

  on(AiClientActions.loadAiClientJobMatchingRequest, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiClientActions.loadAiClientJobMatchingRequestSuccess,
    (state, { request }) => ({
      ...state,
      request,
      loading: false,
    })
  ),
  on(
    AiClientActions.loadAiClientJobMatchingRequestFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiClientActions.updateAdvancement, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AiClientActions.updateAdvancementSuccess, (state, { progress }) => ({
    ...state,
    progress,
    loading: false,
  })),
  on(AiClientActions.updateAdvancementFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AiClientActions.loadAiClientJobMatchingCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiClientActions.loadAiClientJobMatchingCandidatesSuccess,
    (state, { matching }) => ({
      ...state,
      matching,
      loading: false,
    })
  ),
  on(
    AiClientActions.loadAiClientJobMatchingCandidatesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiClientActions.resetAiClientJobMatchingCandidates, state => ({
    ...state,
    matching: null,
    loading: false,
  })),

  on(AiClientActions.loadAiConsultingJobMatchingHistory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiClientActions.loadAiConsultingJobMatchingHistorySuccess,
    (state, { history }) => ({
      ...state,
      history,
      loading: false,
    })
  ),
  on(
    AiClientActions.loadAiConsultingJobMatchingHistoryFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiClientActions.clientAISearchUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
