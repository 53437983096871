import { createAction, props } from '@ngrx/store';
import { AutoApplyProfilesToOffers } from '~/app/states/main/jobhub/types/offer-proposal.types';
import { AllCandidatesFilters } from '~/app/shared/interfaces/crm/search-candidate/all-candidates-filters.interface';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { ProfileApplied } from '~/app/shared/interfaces/jobhub/assign-offer/profile-applied.interface';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

export const loadOfferById = createAction(
  '[Search Offers] Load Offer By Id',
  props<{ offerId: string }>()
);
export const loadOfferByIdSuccess = createAction(
  '[Search Offers] Load Offer By Id Success',
  props<{ offer: Offer }>()
);
export const loadOfferByIdFailure = createAction(
  '[Search Offers] Load Offer By Id Failure',
  props<{ error: string }>()
);

export const loadAppliedProfiles = createAction(
  '[Search Offers] Load Applied Profiles',
  props<{ offerId: string }>()
);
export const loadAppliedProfilesSuccess = createAction(
  '[Search Offers] Load Applied Profiles Success',
  props<{ proposals: ProfileApplied[] }>()
);
export const loadAppliedProfilesFailure = createAction(
  '[Search Offers] Load Applied Profiles Failure',
  props<{ error: string }>()
);

export const autoApplyOffer = createAction(
  '[Search Offers] Auto Apply Offer',
  props<{ payload: AutoApplyProfilesToOffers; offerId: string }>()
);
export const autoApplyOfferSuccess = createAction(
  '[Search Offers] Auto Apply Offer Success'
);
export const autoApplyOfferFailure = createAction(
  '[Search Offers] Auto Apply Offer Failure',
  props<{ error: string }>()
);

export const manualApplyOffer = createAction(
  '[Search Offers] Manual Apply Offer',
  props<{ offerId: string; manualApplyData: FormData }>()
);
export const manualApplyOfferSuccess = createAction(
  '[Search Offers] Manual Apply Offer Success'
);
export const manualApplyOfferFailure = createAction(
  '[Search Offers] Manual Apply Offer Failure',
  props<{ error: string }>()
);

export const loadAllCandidates = createAction(
  '[Search Offers] Load All Candidates',
  props<{ filters?: AllCandidatesFilters }>()
);
export const loadAllCandidatesSuccess = createAction(
  '[Search Offers] Load All Candidates Success',
  props<{ allCandidates: Candidate[] }>()
);
export const loadAllCandidatesFailure = createAction(
  '[Search Offers] Load All Candidates Failure',
  props<{ error: string }>()
);

export const applyOfferUnauthorized = createAction(
  '[Search Offers] Unauthorized',
  props<{ error: string }>()
);
