import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
import { initialProjectState } from '~/app/states/main/sales/states/project/project.state';

export const projectReducer = createReducer(
  initialProjectState,

  on(ProjectActions.loadProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.loadProjectSuccess, (state, { project }) => ({
    ...state,
    project,
    loading: false,
  })),
  on(ProjectActions.loadProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.loadProjectUsers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.loadProjectUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    loading: false,
  })),
  on(ProjectActions.loadProjectUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.loadProjectSales, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.loadProjectSalesSuccess, (state, { sales }) => ({
    ...state,
    sales,
    loading: false,
  })),
  on(ProjectActions.loadProjectSalesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.assignUserToProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.assignUserToProjectSuccess, (state, { users }) => ({
    ...state,
    users,
    loading: false,
  })),
  on(ProjectActions.assignUserToProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.unassignUserFromProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.unassignUserFromProjectSuccess, (state, { users }) => ({
    ...state,
    users,
    loading: false,
  })),
  on(ProjectActions.unassignUserFromProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.assignSaleToProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.assignSaleToProjectSuccess, (state, { sales }) => ({
    ...state,
    sales,
    loading: false,
  })),
  on(ProjectActions.assignSaleToProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.unassignSaleFromProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.unassignSaleFromProjectSuccess, (state, { sales }) => ({
    ...state,
    sales,
    loading: false,
  })),
  on(ProjectActions.unassignSaleFromProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.assignSalesToProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectActions.assignSalesToProjectSuccess, (state, { sales }) => ({
    ...state,
    sales,
    loading: false,
  })),
  on(ProjectActions.assignSalesToProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectActions.clearSelectedProject, state => ({
    ...state,
    project: null,
  })),

  on(ProjectActions.projectUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
