import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from '~/app/core/services/theme.service';
import { heroImage } from '~/app/shared/animations/hero-image';
import { heroText } from '~/app/shared/animations/hero-text';
import { FilterService } from '~/app/shared/services/filter.service';
import { UtilsService } from '~/app/shared/services/utils.service';

/**
 * Represents a tab change event.
 */
interface TabChangeEvent {
  /** The index of the selected tab. */
  index: number;
}

/**
 * Represents the hero component of the application.
 * This component is responsible for displaying the hero section of the application, which often includes
 * introductory content and visuals.
 * This component is a template.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-hero' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './hero.component.html' - Path to the HTML template associated with this component.
 * @styleUrls './hero.component.scss' - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  animations: [heroImage, heroText],
})
export class HeroComponent implements OnInit {
  /**
   * Form group for the search form.
   * @type {FormGroup}
   */
  searchForm!: FormGroup;

  /**
   * Compressed URL filters.
   * @type {string}
   */
  compressedURLFilters!: string;

  /**
   * An array of scrollable tabs used for navigating job categories.
   * Each tab is represented as an object with a `title` property.
   */
  scrollableTabs = [
    { title: 'Development & Testing' },
    { title: 'Project Manager' },
    { title: 'UI/UX Designer' },
    { title: 'Digital & Marketing' },
    { title: 'HR & Recruitment' },
    { title: '.' },
  ];

  /**
   * The default index of the currently active tab.
   * The index is set to the last element of the `scrollableTabs` array.
   */
  defaultTabIndex: number = this.scrollableTabs.length - 1;

  /**
   * Boolean flag indicating if the dark theme is currently active.
   * @type {boolean}
   */
  isDarkTheme = false;

  /**
   * Constructs an instance of the HeroComponent with the theme service injected via the constructor.
   *
   * @param {ThemeService} theme A service for managing the application's theme.
   * @param {FormBuilder} formBuilder – FormBuilder service for creating forms.
   * @param {UtilsService} utils – Service for utility functions.
   * @param {FilterService} filterService – Service for managing filters.
   * @param {Router} router – Service for navigating between routes.
   */
  constructor(
    public theme: ThemeService,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private filterService: FilterService,
    private router: Router
  ) {
    this.isDarkTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes the search form structure.
   *
   * @returns {void} Does not return any value.
   */
  ngOnInit(): void {
    this.initForms();
  }

  /**
   * Initializes the forms for search and pagination.
   *
   * @returns {void}
   * @memberof ConsultantsComponent
   */
  initForms(): void {
    this.searchForm = this.formBuilder.group({
      title: [''],
      city: [''],
    });
  }

  /**
   * Submits the search form and updates the URL with the compressed filter data.
   *
   * @returns {void}
   */
  onSubmit(): void {
    const compressedData = this.filterService.compressJSON({
      ...this.utils.extractValue(this.searchForm.value),
    });
    void this.router.navigate(['/jobhub/offers/filters:' + compressedData]);
  }

  /**
   * Updates the 'title' field of the form with the selected tab's title and submits the form.
   * @param {string} tabTitle - The title of the selected tab.
   * @returns {void} Does not return any value.
   */
  onTabClick(tabTitle: string | undefined): void {
    if (tabTitle === '') {
      return;
    }
    this.searchForm.patchValue({ title: tabTitle });
    this.onSubmit();
  }

  /**
   * Handles tab change events and triggers the appropriate actions.
   * @param {TabChangeEvent} event - The event object containing the index of the selected tab.
   * @returns {void} Does not return any value.
   */
  onTabChange(event: TabChangeEvent): void {
    const selectedIndex = event.index;
    const selectedTabTitle = this.scrollableTabs[selectedIndex].title;
    this.onTabClick(selectedTabTitle);
  }
}
