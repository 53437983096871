import { User } from '~/app/shared/interfaces/auth/user.interface';
import { AllGroupRules } from '~/app/shared/interfaces/settings/access-control/group/all-group-rules.interface';
import { GroupRule } from '~/app/shared/interfaces/settings/access-control/group/group-rule.interface';
import { Group } from '~/app/shared/interfaces/settings/access-control/group/group.interface';

/**
 * Describes the state of groups within the application, including the groups, current rules, current users,
 * all rules, and status indicators for loading and errors.
 *
 * @interface AccessState
 */
export interface AccessState {
  /**
   * The total count of groups.
   *
   * @type {number}
   * @memberof AccessState
   */
  count: number;

  /**
   * The current page number of the group list.
   *
   * @type {number}
   * @memberof AccessState
   */
  page: number;

  /**
   * The number of groups displayed per page.
   *
   * @type {number}
   * @memberof AccessState
   */
  limit: number;

  /**
   * Indicates whether there is a next page of groups.
   *
   * @type {boolean}
   * @memberof AccessState
   */
  hasNext: boolean;

  /**
   * Indicates whether there is a previous page of groups.
   *
   * @type {boolean}
   * @memberof AccessState
   */
  hasPrevious: boolean;

  /**
   * The list of group data.
   *
   * @type {Group[]}
   * @memberof AccessState
   */
  data: Group[];

  /**
   * The current rules for the groups or null if no current rules are available.
   *
   * @type {GroupRule[] | null}
   * @memberof AccessState
   */
  currentRules: GroupRule[] | null;

  /**
   * The current users for the groups or null if no current users are available.
   *
   * @type {User[] | null}
   * @memberof AccessState
   */
  currentUsers: User[] | null;

  /**
   * All rules categorized by a specific category or null if no rules are available.
   *
   * @type {AllGroupRules | null}
   * @memberof AccessState
   */
  allRules: AllGroupRules | null;

  /**
   * Indicates whether group data is being loaded.
   *
   * @type {boolean}
   * @memberof AccessState
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling group-related actions.
   *
   * @type {string | null}
   * @memberof AccessState
   */
  error: string | null;
}

/**
 * The initial state of the groups within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {AccessState}
 * @description Provides a baseline definition for the groups context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialGroupsState: AccessState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  currentRules: null,
  currentUsers: null,
  allRules: null,
  loading: false,
  error: null,
};
