import { createAction, props } from '@ngrx/store';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';

export const loadKanbanCandidates = createAction(
  '[Kanban Candidates] Load Kanban Candidates',
  props<{ name?: string }>()
);

export const loadKanbanCandidatesSuccess = createAction(
  '[Kanban Candidates] Load Kanban Candidates Success',
  props<{ candidates: Candidate[] }>()
);

export const loadKanbanCandidatesFailure = createAction(
  '[Kanban Candidates] Load Kanban Candidates Failure',
  props<{ error: string }>()
);

export const kanbanCandidatesUnauthorized = createAction(
  '[Kanban Candidates] Unauthorized',
  props<{ error: string }>()
);
