import { createReducer, on } from '@ngrx/store';
import { initialClientCompanyStatusState } from '~/app/states/main/settings/states/status/client-company-status/client-company-status.state';
import * as ClientCompanyStatusActions from '~/app/states/main/settings/states/status/client-company-status/client-company-status.actions';

export const clientCompanyStatusReducer = createReducer(
  initialClientCompanyStatusState,

  on(ClientCompanyStatusActions.loadClientCompanyStatuses, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyStatusActions.loadClientCompanyStatusesSuccess,
    (state, { clientCompanyStatuses }) => ({
      ...state,
      clientCompanyStatuses,
      loading: false,
    })
  ),
  on(
    ClientCompanyStatusActions.loadClientCompanyStatusesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanyStatusActions.createClientCompanyStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyStatusActions.createClientCompanyStatusSuccess,
    (state, { clientCompanyStatus }) => ({
      ...state,
      clientCompanyStatuses: [
        ...state.clientCompanyStatuses,
        clientCompanyStatus,
      ],
      loading: false,
    })
  ),
  on(
    ClientCompanyStatusActions.createClientCompanyStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanyStatusActions.updateClientCompanyStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyStatusActions.updateClientCompanyStatusSuccess,
    (state, { status }) => ({
      ...state,
      clientCompanyStatuses: state.clientCompanyStatuses.map(item =>
        item.uuid === status.uuid ? status : item
      ),
      loading: false,
    })
  ),
  on(
    ClientCompanyStatusActions.updateClientCompanyStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ClientCompanyStatusActions.deleteClientCompanyStatus, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(
    ClientCompanyStatusActions.deleteClientCompanyStatusSuccess,
    (state, { uuid }) => ({
      ...state,
      clientCompanyStatuses: state.clientCompanyStatuses.filter(
        item => item.uuid !== uuid
      ),
      loading: false,
    })
  ),
  on(
    ClientCompanyStatusActions.deleteClientCompanyStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCompanyStatusActions.clientCompanyStatusUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
