<section
  class="min-h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="absolute left-0 top-0 ml-3 mt-3">
    <a
      routerLink="/"
      class="p-button p-button-text p-component no-underline flex gap-3">
      <span class="p-button-icon pi pi-arrow-left"></span>
      <span i18n class="p-button-label">Home</span>
    </a>
  </div>

  <div class="surface-card p-4 shadow-2 border-round bg-cover custom-width">
    <app-login-form></app-login-form>
  </div>
</section>
