import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationActions from '~/app/states/main/states/notification/notification.actions';
import {
  NotificationState,
  initialNotificationState,
} from '~/app/states/main/states/notification/notification.state';

export const notificationReducer = createReducer(
  initialNotificationState,

  // ************************************************************************
  // LOAD ALL NOTIFICATIONS
  // ************************************************************************
  on(NotificationActions.loadAllNotifications, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    NotificationActions.loadAllNotificationsSuccess,
    (state, { notifications }) => ({
      ...state,
      loading: false,
      allNotifications: notifications,
    })
  ),
  on(NotificationActions.loadAllNotificationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(NotificationActions.markNotificationAsReadSuccess, (state, { uuid }) => ({
    ...state,
    allNotifications: state.allNotifications.map(notification =>
      notification.uuid === uuid
        ? { ...notification, isRead: true }
        : notification
    ),
  })),
  on(NotificationActions.markNotificationAsReadFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(NotificationActions.markAllNotificationsAsRead, state => ({
    ...state,
    loading: true,
  })),

  on(NotificationActions.markAllNotificationsAsReadSuccess, state => ({
    ...state,
    allNotifications: state.allNotifications.map(n => ({ ...n, isRead: true })),
    loading: false,
  })),

  on(
    NotificationActions.markAllNotificationsAsReadFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  // ************************************************************************
  // CUD
  // ************************************************************************
  on(NotificationActions.createNotification, (state, { notification }) => ({
    ...state,
    allNotifications: [notification, ...state.allNotifications],
    loading: true,
  })),

  on(NotificationActions.deleteNotification, state => ({
    ...state,
    loading: true,
  })),

  on(NotificationActions.deleteNotificationSuccess, (state, { uuid }) => ({
    ...state,
    loading: false,
    allNotifications: state.allNotifications.filter(n => n.uuid !== uuid),
  })),

  on(NotificationActions.deleteNotificationFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotificationActions.deleteAllNotifications, state => ({
    ...state,
    error: null,
    loading: true,
  })),

  on(NotificationActions.deleteAllNotificationsSuccess, state => ({
    ...state,
    allNotifications: [],
    loading: false,
    error: null,
  })),

  on(NotificationActions.deleteAllNotificationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // ************************************************************************
  // SSE SERVER CONNECTION
  // ************************************************************************
  on(
    NotificationActions.connectToChannel,
    (state, { connectionDetails, token }) => ({
      ...state,
      loading: true,
      connectionDetails,
      token,
    })
  ),
  on(NotificationActions.connectToChannelSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(NotificationActions.connectToChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NotificationActions.disconnectFromChannel, state => ({
    ...state,
    loading: true,
  })),
  on(NotificationActions.disconnectFromChannelSuccess, state => ({
    ...state,
    connectionDetails: [],
    token: null,
    loading: false,
  })),
  on(NotificationActions.disconnectFromChannelFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // ************************************************************************
  // UNAUTHORIZED
  // ************************************************************************
  on(NotificationActions.notificationsUnauthorized, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(
  state: NotificationState | undefined,
  action: Action
): NotificationState {
  return notificationReducer(state, action);
}
