import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastService } from '~/app/shared/services/toast.service';
import { ButtonConfig } from '~/app/shared/interfaces/navigation/button-config.interface';
import { NavTabConfig } from '~/app/shared/interfaces/navigation/nav-tab-config.interface';

/**
 * Component for displaying section headings with optional elements such as icons, titles, subtitles,
 * badges, and action buttons. It also supports tabbed navigation for section-related content,
 * updating active states based on the router's current URL.
 *
 * @Component provides Angular component metadata:
 * @selector 'app-section-heading' - The CSS selector for this component.
 * @templateUrl './section-heading.component.html' - Path to the component's HTML template.
 * @styleUrls ['./section-heading.component.scss'] - Path to the component's styles.
 */
@Component({
  selector: 'app-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss'],
})
export class SectionHeadingComponent {
  /**
   * Optional CSS class for the icon.
   * @type {string}
   */
  @Input() iconClass?: string;

  /**
   * Optional title text.
   * @type {string}
   */
  @Input() title?: string;

  /**
   * Optional subtitle text.
   * @type {string}
   */
  @Input() subtitle?: string;

  /**
   * Optional badge text.
   * @type {string}
   */
  @Input() badge?: string;

  /**
   * Optional main text for the title.
   * @type {string}
   */
  @Input() titleText?: string;

  /**
   * Configuration for action buttons displayed in the section header.
   * @type {ButtonConfig[]}
   */
  @Input() buttonsConfig: ButtonConfig[] = [];

  /**
   * Configuration for navigational tabs associated with the section, including their active states.
   * @type {NavTabConfig[]}
   */
  @Input() navTabsConfig: NavTabConfig[] = [];

  /**
   * Constructs the component with necessary dependencies and initializes navigation event handling.
   *
   * This constructor subscribes to the router's events to listen for changes in navigation. It specifically reacts to
   * NavigationEnd events to update the active state of navigation tabs, ensuring that the correct tab is highlighted
   * as active based on the current route. This mechanism is integral for components that need to visually reflect
   * navigation changes to the user.
   *
   * @param {ToastService} toast - The service used for displaying toast notifications within the application.
   * This service facilitates feedback operations such as success, error, info, and warning messages.
   *
   * @param {Router} router - The Angular Router service used to subscribe to navigation events. This service is
   * critical for responding to routing changes, which is especially useful in SPA (Single Page Applications) where
   * components may need to update based on the route context.
   */
  constructor(
    private toast: ToastService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Displays a success toast.
   * @returns {void}
   */
  showSuccess(): void {
    this.toast.showSuccess('success', 'details details and details');
  }

  /**
   * Displays an informational toast.
   * @returns {void}
   */
  showInfo(): void {
    this.toast.showInfo('info', 'details details and details');
  }

  /**
   * Displays a warning toast.
   * @returns {void}
   */
  showWarning(): void {
    this.toast.showWarning('warning', 'details details and details');
  }

  /**
   * Displays an error toast.
   * @returns {void}
   */
  showError(): void {
    this.toast.showError('error', 'details details and details');
  }

  /**
   * Method to update the active state of navigation tabs based on the current URL. Ensures that
   * the tab corresponding to the current route is highlighted as active.
   * @param {string} activeUrl - The URL after redirects, used to determine which tab should be active.
   *
   * @return {void}
   */
  updateActiveTab(activeUrl: string): void {
    const lastSegment = activeUrl.split('/').pop();
    this.navTabsConfig.forEach(tab => {
      tab.isActive =
        tab.route && lastSegment ? tab.route.endsWith(lastSegment) : false;
    });
  }
}
