import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { take } from 'rxjs';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';

/**
 * Represents a customizable checkbox component that integrates seamlessly with Angular's forms and permission systems.
 * It provides features such as a binary mode, native styling, and custom event handling.
 *
 * @Component decorator configures component metadata.
 * @selector 'ice-checkbox' - The selector used in HTML to instantiate this component.
 * @templateUrl './ice-checkbox.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./ice-checkbox.component.scss'] - Path to the styles applied to this component.
 * @providers NG_VALUE_ACCESSOR - Configures this component as a ControlValueAccessor for Angular forms.
 */
@Component({
  selector: 'ice-checkbox',
  templateUrl: './ice-checkbox.component.html',
  styleUrls: ['./ice-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceCheckboxComponent),
      multi: true,
    },
  ],
})
export class IceCheckboxComponent implements AfterViewInit {
  /**
   * Controls the checked state of the checkbox.
   * @type {boolean}
   */
  @Input() checked = false;

  /**
   * Optional rule for permissions check to determine component behavior.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Determines behavior when user lacks permission specified by `rule`.
   * @type {PermissionBehaviourEnum | undefined}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum | undefined =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Controls whether the checkbox is disabled.
   * @type {boolean | undefined}
   */
  @Input() disabled: boolean | undefined;

  /**
   * Specifies whether to use native checkbox behavior.
   * @type {boolean}
   */
  @Input() native = false;

  /**
   * Specifies whether to use a custom styling.
   * @type {boolean}
   */
  @Input() custom = false;

  /**
   * If set to true, the checkbox behaves as a binary input with only checked and unchecked states.
   * @type {boolean}
   */
  @Input() binary = false;

  /**
   * Event emitted when the checkbox value changes.
   * @type {EventEmitter<CheckboxChangeEvent>}
   */
  @Output() onChange = new EventEmitter<CheckboxChangeEvent>();

  /**
   * Event emitted when the checkbox is clicked.
   * @type {EventEmitter<MouseEvent>}
   */
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * Initializes the component with necessary dependencies.
   *
   * @param {PermissionsService} permissionService - The service used to check permissions for the checkbox.
   * @param {ElementRef} element - Provides access to the checkbox DOM element.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Called after Angular fully initializes the component's view. It checks the component's
   * permission rule to determine whether to disable or hide the checkbox based on the
   * user's permissions.
   *
   * If the user lacks the specified permission:
   * - The method removes all 'click' listeners from the native element to prevent actions.
   * - Depending on the `unauthorizedBehaviour` setting, it either hides the checkbox or disables it.
   *   - If `PermissionBehaviourEnum.NONE` is specified, the checkbox is hidden.
   *   - If `PermissionBehaviourEnum.DISABLE` is specified, or by default, the checkbox is disabled.
   *
   * This method ensures that the component adheres to specified security rules, enhancing UI security and user experience.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            this.element.nativeElement.removeAllListeners('click');
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.NONE:
                this.hideElement();
                break;
              case PermissionBehaviourEnum.DISABLE:
              default:
                this.disableElement();
            }
          }
        });
  }

  /**
   * Writes a new value to the input.
   * @returns {void}
   */
  writeValue(): void {
    /* no-op */
  }

  /**
   * Registers a function to be called when the input value changes.
   * @return {void}
   */
  registerOnChange(): void {
    /* no-op */
  }

  /**
   * Registers a function to be called when the input is touched.
   * @return {void}
   */
  registerOnTouched(): void {
    /* no-op */
  }

  /**
   * Hides the checkbox by applying a CSS style.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the checkbox and prevents any interactions.
   * @return {void}
   */
  private disableElement(): void {
    this.disabled = true;
  }
}
