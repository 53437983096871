import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, skipWhile, take, tap } from 'rxjs';
import { AppState } from '~/app/core/state/app.state';
import { selectCurrentBreakpoint } from '~/app/core/state/breakpoint/breakpoint.selectors';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';
import { selectActivatedModules } from '~/app/shared/states/navigation/navigation.selector';
import { NavigationState } from '~/app/shared/states/navigation/navigation.state';
import * as NavigationActions from '~/app/shared/states/navigation/navigation.action';

/**
 * A service responsible for managing the behavior and state of the side navigation panel. This includes
 * controlling whether the sidenav can be collapsed or closed, its expansion state, and its style based on
 * the viewport width. The service integrates with NgRx to manage state changes and responds to viewport
 * changes to adjust UI elements dynamically.
 *
 * @Injectable marks the class as a service that can be injected, provided at the root level to ensure it
 * is available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  /**
   * Indicates whether the sidenav is expanded or not.
   * @type {boolean}
   */
  isExpanded = false;

  /**
   * Observable of the current breakpoint from the AppState to manage responsive adjustments.
   * @type {Observable<string>}
   */
  breakpoint$: Observable<string>;

  /**
   * Observable that emits a list of activated modules.
   * @type {Observable<ModuleItem[]>}
   */
  activatedModules$: Observable<ModuleItem[]>;

  /**
   * BehaviorSubject to manage the state of the sidenav.
   * @type {BehaviorSubject<boolean>}
   */
  sidenavState = new BehaviorSubject<boolean>(false);

  /**
   * Observable for tracking the state of the sidenav (open/close).
   * Used for reactive programming within components that depend on the sidenav's visibility.
   *
   * @type {Observable<boolean>}
   */
  sidenavState$ = this.sidenavState.asObservable();

  /**
   * Constructor for the SidenavService, responsible for setting up the service with necessary dependencies and
   * initializing state management and responsiveness logic.
   *
   * This constructor injects NgRx Store for managing global and navigation-specific state and sets up an observable
   * to track current UI breakpoints, adjusting the sidenav's display properties accordingly. It also registers a
   * window resize listener to dynamically update sidenav modes based on the viewport size.
   *
   * @param {Store<NavigationState>} store - Injects the store managing navigation-related state.
   * @param {Store<AppState>} appStore - Injects the global application state store to access application-wide settings
   * and state such as breakpoints.
   * @param {Router} router - Injects the service for handling navigation.
   */
  constructor(
    private store: Store<NavigationState>,
    private appStore: Store<AppState>,
    private router: Router
  ) {
    this.activatedModules$ = this.store.select(selectActivatedModules);
    this.breakpoint$ = this.appStore.select(selectCurrentBreakpoint);
  }

  /**
   * Toggles the expansion states of the sidenav. If 'collapse' is specified, the sidenav is explicitly set to collapsed.
   * @param {string} [collapse] - Optional parameter to force collapse the sidenav.
   * @returns {void}
   */
  toggleSidenav(collapse?: 'collapse'): void {
    collapse ? (this.isExpanded = false) : (this.isExpanded = !this.isExpanded);

    this.breakpoint$
      .pipe(
        tap(breakpoint => {
          const root = document.querySelector(':root') as HTMLElement;
          switch (breakpoint) {
            case 'sm':
            case 'md':
              root.style.setProperty(
                '--current-sidenav-width',
                this.isExpanded ? '250px' : '0px'
              );
              break;
            case 'lg':
            case 'xl':
            default:
              root.style.setProperty(
                '--current-sidenav-width',
                this.isExpanded ? '250px' : '70px'
              );
          }
        })
      )
      .subscribe();
  }

  /**
   * Retrieve all activated modules for the current user from API
   * @return {void}
   */
  loadAllActivatedModules(): void {
    this.store.dispatch(NavigationActions.loadAllActivatedModules());
  }

  /**
   * Change the current module and the sidenav content
   * @param {string} module - The name of the new current module
   * @return {void}
   */
  changeCurrentModule(module: string): void {
    this.activatedModules$
      .pipe(
        skipWhile(modules => modules.length === 0),
        take(1),
        tap(modules => {
          if (
            modules.find(newModule => {
              return newModule.name === module;
            })
          )
            this.store.dispatch(
              NavigationActions.changeCurrentModule({ module })
            );
          else void this.router.navigate(['/unauthorized']);
        })
      )
      .subscribe();
  }

  /**
   * Opens the mobile sidenav.
   * @return {void}
   */
  openMobileSidenav(): void {
    this.sidenavState.next(true);
  }

  /**
   * Closes the mobile sidenav.
   * @return {void}
   */
  closeMobileSidenav(): void {
    this.sidenavState.next(false);
  }

  /**
   * Toggles the mobile sidenav.
   * @return {void}
   */
  toggleMobileSidenav(): void {
    this.sidenavState.next(!this.sidenavState.value);
  }
}
