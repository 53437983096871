import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordService } from '~/app/auth/services/reset-password.service';
import { passwordMatchValidator } from '~/app/auth/validators/password-match.validator';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * Component for managing user password reset functionality. It provides a form where users can enter a new password and confirm it.
 * The component ensures the passwords match and submits the new password for resetting. It uses Angular reactive forms for form creation and validation.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-reset-password' - The CSS selector that identifies this component in a template.
 * @templateUrl './reset-password.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./reset-password.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  /**
   * FormGroup to manage the reset password form
   * @type {FormGroup}
   */
  resetPasswordForm!: FormGroup;

  /**
   * Constructs the ResetPasswordComponent with the necessary dependencies.
   *
   * @param {FormBuilder} formBuilder - Service to create FormGroup based on provided configuration.
   * @param {ResetPasswordService} resetPasswordService - Service to handle the logic for resetting passwords.
   * @param {ActivatedRoute} activatedRoute - Service that contains the route information, used here to extract the reset token.
   * @param {ThemeService} theme - Service to manage theming across the application.
   */
  constructor(
    private formBuilder: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private activatedRoute: ActivatedRoute,
    public theme: ThemeService
  ) {
    this.initForm();
  }

  /**
   * Accessor for the 'password' form control
   * @type {FormControl}
   */
  public get password(): FormControl {
    return this.resetPasswordForm.get('password') as FormControl;
  }

  /**
   * Accessor for the 'confirmPassword' form control
   * @type {FormControl}
   */
  public get confirmPassword(): FormControl {
    return this.resetPasswordForm.get('confirmPassword') as FormControl;
  }

  /**
   * OnInit lifecycle hook that checks the reset password token validity via the reset password service.
   * @return {void}
   */
  ngOnInit(): void {
    this.resetPasswordService.checkResetPassword(
      this.activatedRoute.snapshot.params['token']
    );
  }

  /**
   * Initializes the reset password form with validators to ensure the password meets the required criteria and matches the confirmation.
   * @return {void}
   */
  initForm(): void {
    this.resetPasswordForm = this.formBuilder.nonNullable.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: passwordMatchValidator }
    );
  }

  /**
   * Handles form submission, collects the form data, and submits it to reset the password via the reset password service.
   * @return {void}
   */
  onSubmit(): void {
    this.resetPasswordService.patchPassword(
      this.activatedRoute.snapshot.params['token'],
      this.resetPasswordForm.value
    );
  }
}
