import { Component } from '@angular/core';

/**
 * Represents a job listing with various details such as company name, job title, and description.
 */
interface JobListing {
  /** The company logo. */
  companyLogo: string;
  /** The company name. */
  companyName: string;
  /** The job title. */
  jobTitle: string;
  /** The job description. */
  description: string;
  /** The job offer tag. */
  tags: string[];
  /** The job offer salary. */
  salary: string;
  /** The job offer location. */
  location: string;
}

/**
 * The `JobsComponent` component is responsible for displaying a list of job opportunities.
 * It uses the `JobListing` interface to define the structure of each job entry.
 * This component is rendered using the `app-jobs` selector and displays the job listings in the provided template.
 */
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss',
})
export class JobsComponent {
  /**
   * An array of job listings to be displayed in the component.
   * Each job listing contains details such as the company name, job title, and description.
   */
  jobListings: JobListing[] = [
    {
      companyLogo: 'assets/meta.svg',
      companyName: 'Meta',
      jobTitle: 'Digital & Marketing',
      description:
        "Que j'aime a faire apprendre ce nombre utile aux sages. Immortel Archimède, qui de ton jugement.",
      tags: ['remote', 'CDI'],
      salary: '€200 - 250 /day',
      location: 'Brussels',
    },
    {
      companyLogo: 'assets/proximus.svg',
      companyName: 'Proximus',
      jobTitle: 'UI/UX Designer',
      description:
        "Que j'aime a faire apprendre ce nombre utile aux sages. Immortel Archimède, qui de ton jugement.",
      tags: ['contract', 'remote'],
      salary: '€4500 /month',
      location: 'Antwerpen',
    },
    {
      companyLogo: 'assets/meta.svg',
      companyName: 'Metal',
      jobTitle: 'Project Manager',
      description:
        "Que j'aime a faire apprendre ce nombre utile aux sages. Immortel Archimède, qui de ton jugement.",
      tags: ['contract', 'remote'],
      salary: '€650 /day',
      location: 'Waterloo',
    },
  ];
}
