import { ClientCompanyNote } from '~/app/shared/interfaces/client-company/profile/client-company-note.interface';

/**
 * Interface representing the state of client company notes.
 *
 * @interface ClientCompanyNotesState
 */
export interface ClientCompanyNotesState {
  /**
   * Array of client company notes.
   *
   * @type {ClientCompanyNote[]}
   * @memberof ClientCompanyNotesState
   */
  clientCompanyNotes: ClientCompanyNote[];

  /**
   * Indicates if the notes are being loaded.
   *
   * @type {boolean}
   * @memberof ClientCompanyNotesState
   */
  loading: boolean;

  /**
   * Error message, if any, related to loading notes.
   *
   * @type {string | null}
   * @memberof ClientCompanyNotesState
   */
  error: string | null;
}

/**
 * Initial state for client company notes.
 *
 * @const {ClientCompanyNotesState}
 */
export const initialClientCompanyNotesState: ClientCompanyNotesState = {
  clientCompanyNotes: [],
  loading: false,
  error: null,
};
