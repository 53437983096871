import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ZodError } from 'zod';

/**
 * Service for displaying toast notifications using PrimeNG's MessageService.
 * This service offers a variety of toast types including action, success, information, warning,
 * error, and specialized Zod validation error toasts, each configured with custom styles and behaviors.
 *
 * @Injectable marks this class as available to be provided and injected as a singleton
 * within the root injector.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomToastService {
  /**
   * Creates an instance of CustomToastService with the injected PrimeNG MessageService.
   * @param {MessageService} messageService - The PrimeNG service used for displaying messages across the application.
   */
  constructor(private messageService: MessageService) {}

  /**
   * Displays a toast with custom actions attached. Useful for interactive notifications where user input is required.
   *
   * @param {string} title - The title of the toast.
   * @param {string} content - The detailed message content of the toast.
   * @param {Function} onAction - A callback function to execute when the user takes an action.
   * @param {Function} onCancel - A callback function to execute when the user dismisses or cancels the action.
   *
   * @return {void}
   */
  actionToast(
    title: string,
    content: string,
    onAction: () => void,
    onCancel: () => void
  ): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'action',
      summary: title,
      detail: content,
      data: { onAction, onCancel },
    });
  }

  /**
   * Displays a success notification toast, which typically signifies the successful completion of an operation.
   *
   * @param {string} title - The title of the toast.
   * @param {string} content - The detailed message content of the toast.
   *
   * @return {void}
   */
  successToast(title: string, content: string): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'success',
      summary: title,
      detail: content,
      life: 3000,
      severity: 'success',
    });
  }

  /**
   * Displays an informational toast, used to provide general information or guidance to the user.
   *
   * @param {string} title - The title of the toast.
   * @param {string} content - The detailed message content of the toast.
   *
   * @return {void}
   */
  infoToast(title: string, content: string): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'info',
      summary: title,
      detail: content,
      life: 5000,
      severity: 'info',
    });
  }

  /**
   * Displays a warning toast, which is used to alert the user about validations or issues that they need to be aware of.
   *
   * @param {string} title - The title of the toast.
   * @param {string} content - The detailed message content of the toast.
   *
   * @return {void}
   */
  warningToast(title: string, content: string): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'warning',
      summary: title,
      detail: content,
      sticky: true,
      severity: 'warn',
    });
  }

  /**
   * Displays a contrast toast, which is used to highlight important information or actions that require the user's attention.
   *
   * @param {string} content - The detailed message content of the toast.
   *
   * @return {void}
   *
   */
  contrastToast(content: string): void {
    this.messageService.clear();
    this.messageService.add({
      closable: false,
      detail: content,
      life: 1500,
      severity: 'contrast',
    });
  }

  /**
   * Displays an error toast, typically used to notify the user of an error that occurred during an operation.
   *
   * @param {string} title - The title of the toast.
   * @param {string} content - The detailed message content of the toast.
   *
   * @return {void}
   */
  errorToast(title: string, content: string): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'error',
      summary: title,
      detail: content,
      sticky: true,
      severity: 'error',
    });
  }

  /**
   * Displays a toast specifically for Zod validation errors, formatting each error message for clarity.
   *
   * @param {ZodError} error - The ZodError object containing detailed validation errors.
   *
   * @return {void}
   */
  zodErrorToast(error: ZodError): void {
    const detail = error.errors
      .map(err => {
        const path = err.path.join(',');
        return `${path}: ${err.message} `;
      })
      .join(', ');

    this.messageService.clear();
    this.messageService.add({
      key: 'zodError',
      summary: 'Zod Validation Error',
      detail: detail,
      sticky: true,
      severity: 'error',
    });
  }
}
