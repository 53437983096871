import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WsClientService } from '~/app/states/main/crm/ai-search/services/ws-client.service';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import * as AiClientActions from '~/app/states/main/crm/ai-search/states/client/client.actions';
import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { JobMatchingHistory } from '~/app/shared/interfaces/jobhub/job-matching/job-matching-history.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';
import { environment } from '~/environments/environment';

/**
 * `AiClientEffects` is responsible for managing side effects related to AI client operations in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading and updating AI client information from and to the server.
 *
 * Effects included in this class:
 * - `loadRequest$`: Fetches AI client job matching requests from the server.
 * - `loadRequestSuccess$`: Handles successful AI client job matching requests.
 * - `loadRequestFailure$`: Handles failed AI client job matching requests.
 * - `updateAdvancement$`: Updates the progress of an AI client job matching request.
 * - `loadCandidates$`: Fetches AI client job matching candidates from the server.
 * - `loadHistory$`: Fetches AI client job matching tasks from the server.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AiClientEffects {
  /**
   * Effect to load an AI client job matching request.
   * Listens for the loadAiClientJobMatchingRequest action, checks permissions, makes an API request,
   * and dispatches a success or failure action accordingly.
   */
  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiClientActions.loadAiClientJobMatchingRequest),
      checkPermission(AiClientActions.clientAISearchUnauthorized),
      mergeMap(({ formData }) =>
        this.http
          .post<WsRequest>(
            `${environment.apiUrl}/v1/ai/profiles/matching/client`,
            formData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(request =>
              AiClientActions.loadAiClientJobMatchingRequestSuccess({
                request,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiClientActions.loadAiClientJobMatchingRequestFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of loading an AI client job matching request.
   * Initializes a WebSocket connection with the provided request.
   */
  loadRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiClientActions.loadAiClientJobMatchingRequestSuccess),
        tap(({ request }) => {
          this.wsService.initializeConnection(request);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle the failure of loading an AI client job matching request.
   * Updates the progress to null.
   */
  loadRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiClientActions.loadAiClientJobMatchingRequestFailure),
        map(() => AiClientActions.updateAdvancement({ progress: null }))
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the advancement of an AI client job matching process.
   * Listens for the updateAdvancement action and dispatches a success or failure action accordingly.
   */
  updateAdvancement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiClientActions.updateAdvancement),
      map(({ progress }) =>
        AiClientActions.updateAdvancementSuccess({ progress })
      ),
      catchError(error =>
        of(AiClientActions.updateAdvancementFailure({ error }))
      )
    )
  );

  /**
   * Effect to load AI client job matching candidates.
   * Listens for the loadAiClientJobMatchingCandidates action, checks permissions, makes an API request,
   * and dispatches a success or failure action accordingly.
   */
  loadCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiClientActions.loadAiClientJobMatchingCandidates),
      checkPermission(AiClientActions.clientAISearchUnauthorized),
      mergeMap(({ url }) =>
        this.http
          .get<MatchingObject>(
            `${environment.apiUrl}/v1/matchingHistories/${url}`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(matching =>
              AiClientActions.loadAiClientJobMatchingCandidatesSuccess({
                matching,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiClientActions.loadAiClientJobMatchingCandidatesFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load AI client job matching tasks.
   * Listens for the loadAiConsultingJobMatchingHistory action, checks permissions, makes an API request,
   * and dispatches a success or failure action accordingly.
   */
  loadHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiClientActions.loadAiConsultingJobMatchingHistory),
      checkPermission(AiClientActions.clientAISearchUnauthorized),
      mergeMap(({ page, limit }) =>
        this.http
          .get<JobMatchingHistory>(
            `${environment.apiUrl}/v1/matchingHistories`,
            {
              withCredentials: true,
              params: { page, limit },
            }
          )
          .pipe(
            take(1),
            map(history =>
              AiClientActions.loadAiConsultingJobMatchingHistorySuccess({
                history,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                AiClientActions.loadAiConsultingJobMatchingHistoryFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Creates an instance of AiClientEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {WsClientService} wsService - The WebSocket service to handle WebSocket connections.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    public sidebarService: SidenavFormService,
    private httpErrors: HttpErrorsService,
    private wsService: WsClientService
  ) {}
}
