import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import * as RulesActions from '~/app/states/main/settings/states/rules/rules.actions';
import { AllRules } from '~/app/shared/interfaces/settings/access-control/rule/all-rules.interface';
import { GroupRule } from '~/app/shared/interfaces/settings/access-control/group/group-rule.interface';
import { environment } from '~/environments/environment';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';

/**
 * `RulesEffects` orchestrates the handling of asynchronous operations related to rules in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to rules, such as fetching and
 * updating data from and to the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * - `loadRules$`: Fetches all rules from the server.
 * - `loadRulesByGroup$`: Fetches rules for a specific group from the server.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class RulesEffects {
  /**
   * Effect to load all rules. It listens for the `loadRules` action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RulesActions.loadRules),
      checkPermission(RulesActions.rulesUnauthorized),
      mergeMap(() =>
        this.http
          .get<AllRules>(`${environment.apiUrl}/v1/companies/rules`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(allRules => RulesActions.loadRulesSuccess({ allRules })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RulesActions.loadRulesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load rules by group. It listens for the `loadRulesByGroup` action, checks permissions,
   * and fetches data from the server for a specific group. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadRulesByGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RulesActions.loadRulesByGroup),
      checkPermission(RulesActions.rulesUnauthorized),
      mergeMap(({ groupId }) =>
        this.http
          .get<GroupRule>(`${environment.apiUrl}/v1/rules/${groupId}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(groupRules =>
              RulesActions.loadRulesByGroupSuccess({ groupRules })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(RulesActions.loadRulesByGroupFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Creates an instance of RulesEffects.
   *
   * @param {Actions} actions$ - The actions observable.
   * @param {HttpClient} http - The HTTP client.
   * @param {HttpErrorsService} httpErrors - The service to handle HTTP errors.
   * @param {SidenavFormService} sidebarService - The service to manage the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    public sidebarService: SidenavFormService
  ) {}
}
