<section
  class="min-h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="absolute left-0 top-0 ml-3 mt-3">
    <a
      routerLink="/"
      class="p-button p-button-text p-component no-underline flex gap-3">
      <span class="p-button-icon pi pi-arrow-left"></span>
      <span i18n class="p-button-label">Home</span>
    </a>
  </div>
  <div
    class="surface-card p-4 shadow-2 border-round min-w-20rem w-30rem bg-cover">
    <div class="text-center mb-5">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="50"
        class="mb-3" />
      <div i18n class="text-900 text-3xl font-medium mb-3">
        Two Factor Authenticate
      </div>
      <span i18n class="text-600 font-medium line-height-3">
        Enter your TOTP code using an authentificator application.
      </span>
    </div>

    <form [formGroup]="secureLoginForm" (ngSubmit)="onSubmit()">
      <div>
        <ice-input
          [label]="'TOTP code'"
          [placeholder]="'TOTP code'"
          [formControlName]="'totp'"
          [formControl]="totp"
          [applyLowercase]="true">
        </ice-input>

        <div class="flex align-items-center justify-content-between mb-6">
          <a
            i18n
            routerLink="/forgot-totp"
            class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            >Forgot TOTP code?</a
          >
        </div>

        <ice-button
          [style]="{ width: '100%' }"
          label="Sign In"
          i18n-label
          icon="pi pi-user"
          i18n-icon
          type="submit"
          [disabled]="!secureLoginForm.valid"
          [loading]="(isLoading$ | async) ?? false" />
      </div>
    </form>
  </div>
</section>
