import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { Observable, of, take, tap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { AuthState } from '~/app/auth/auth.state';
import { EnumService } from '~/app/core/services/enum.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import * as AuthActions from '~/app/auth/auth.actions';
import { primeFlexBreakpoints } from '~/app/shared/const/breakpoints.const';
import * as BreakpointAction from '~/app/core/state/breakpoint/breakpoint.action';
import { PUBLIC_ROUTES } from '~/app/shared/const/public-routes.const';

/**
 * Factory function to initialize the application.
 *
 * @param {Store<AppState>} store - The NgRx store used for managing the state of the application.
 * @param {EnumService} enumService - The service for managing enumerations.
 * @param {BreakpointObserver} breakpointObserver - The observer for breakpoint changes.
 * @param {ThemeService} themeService - Service used for managing application theme.
 * @returns {Observable<AuthState | Error>} A function returning an observable for application initialization.
 */
export function appInitializerFactory(
  store: Store<AppState>,
  enumService: EnumService,
  breakpointObserver: BreakpointObserver,
  themeService: ThemeService
): () => Observable<AuthState | Error> {
  return () => {
    // Check if the current path is in PUBLIC_ROUTES
    const currentPath = window.location.pathname;
    const isPublicRoute = PUBLIC_ROUTES.some(route =>
      currentPath.startsWith(route)
    );
    // Dispatch refreshToken only if not on a public route
    if (!isPublicRoute) {
      store.dispatch(AuthActions.refreshToken());
    }
    // Observe breakpoint changes
    breakpointObserver
      .observe([
        primeFlexBreakpoints.sm,
        primeFlexBreakpoints.md,
        primeFlexBreakpoints.lg,
        primeFlexBreakpoints.xl,
      ])
      .pipe(
        tap((result: BreakpointState) => {
          if (result.matches) {
            const breakpoint = Object.entries(primeFlexBreakpoints).find(
              ([, value]) => result.breakpoints[value]
            );

            if (breakpoint)
              store.dispatch(
                BreakpointAction.changeCurrentBreakpoint({
                  breakpoint: breakpoint[0],
                })
              );
          } else {
            store.dispatch(
              BreakpointAction.changeCurrentBreakpoint({
                breakpoint: 'xl',
              })
            );
          }
        })
      )
      .subscribe();

    // Return an observable that completes when the token refresh is done
    return store
      .select(state => state.auth)
      .pipe(
        filter(
          authState =>
            !!authState.userData?.token ||
            !!authState.error ||
            (!authState.userData && isPublicRoute)
        ),
        take(1),
        tap(authState => {
          if (authState.userData) {
            // Do not need to be logged in for these ones
            themeService.initTheme();
            enumService.loadCecrlTypes();
            enumService.loadOfferUnitTypes();
            enumService.loadOfferDocumentTypes();
            enumService.loadProfileExperienceTypes();
            enumService.loadProfileFileTypes();
            enumService.loadProfileTypes();
            enumService.loadProfileTimeUnits();
            enumService.loadSocialTypes();
            enumService.loadGenderTypes();
            enumService.loadSkillExperiencesTypes();
            enumService.loadOfferStatusTypes();

            // Need to be logged in for these ones
            enumService.loadOfferVisibilityTypes();
            enumService.loadProfileTypes();
            enumService.loadProfileTimeUnits();
            enumService.loadProposalStatusTypes();
            enumService.loadReminderTypes();
            enumService.loadSaleInformationTypes();
            enumService.loadSocialTypes();
            enumService.loadGenderTypes();
            enumService.loadSkillExperiencesTypes();
            enumService.loadTargets();
            enumService.loadGoalTypes();
            enumService.loadRates();
          }
        }),
        catchError(error => {
          return of(error);
        })
      );
  };
}
