import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that modifies strings by inserting a space before each capital letter
 * and capitalizes the first letter of the resulting string. This is particularly useful for formatting camelCase
 * or PascalCase identifiers into a more readable form, often used in user interfaces to display variable names
 * or object keys more legibly.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'capitalizeWithSpace', enabling its use within Angular templates
 * to dynamically format strings according to the specified capitalization rule.
 */
@Pipe({
  name: 'capitalizeWithSpace',
})
export class CapitalizeWithSpacePipe implements PipeTransform {
  /**
   * Transforms a string by adding a space before each uppercase letter and ensuring the first letter is capitalized.
   * This makes concatenated words more readable, particularly useful for displaying code identifiers in a user-friendly format.
   *
   * @param {string} value - The string to be transformed.
   * @returns {string} - The transformed string with spaces inserted before each capital letter.
   */
  transform(value: string): string {
    if (!value) return value;
    return value.replace(/([A-Z])/g, ' $1').trim();
  }
}
