<div
  class="ice-status-tag flex align-items-center border-round-2lg py-1 px-2-5 gap-2-5 text-sm"
  [ngStyle]="{ background: color ?? 'var(--color-fancy-lime)' }">
  <i
    *ngIf="icon"
    [class]="'text-sm pi ' + icon"
    [ngClass]="{ 'color-light-100': !smartColor }">
  </i>
  <p
    class="m-0 text-sm white-space-nowrap max-w-17rem line-clamp-1"
    [ngClass]="{ 'color-light-100': !smartColor }"
    [style]="{ color: color ?? 'var(--color-light-100)' | smartTextColor }">
    {{ text | titlecase }}
  </p>
</div>
