import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ISSEEventType } from '@npmicedev/icemodule/lib/enums/ISSEEventType';
import { NotificationEvent } from '~/app/shared/interfaces/notificationEvent.interface';
import * as NotificationsActions from '~/app/states/main/states/notification/notification.actions';
import { IsseService } from '~/app/shared/services/IsseService';

/**
 * Service responsible for listening to a channel on the SSE Server.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class IsseNotificationService {
  /**
   * Constructor for IsseService.
   * @param {Store} store - Store to manage notification state.
   * @param {IsseService} isseService - Service to connect to the SSE Server.
   */
  constructor(
    private store: Store,
    private isseService: IsseService
  ) {}

  /**
   * Creates an EventSourcePolyfill & connect it to the SSE Server.
   * @returns {void}
   */
  listenToChannel(): void {
    const eventSourcePolyfill = this.isseService.eventSource;

    eventSourcePolyfill.addEventListener(
      ISSEEventType.NewNotification,
      event => {
        const newNotificationEvent = event as unknown as NotificationEvent;
        const { notification } = JSON.parse(newNotificationEvent.data).change;

        this.store.dispatch(
          NotificationsActions.createNotification({
            notification,
          })
        );
      }
    );
  }
}
