import { createReducer, on } from '@ngrx/store';
import { initialClientCompanyState } from '~/app/states/main/client-company/profile/states/client-company/client-company.state';
import * as ClientCompanyActions from '~/app/states/main/client-company/profile/states/client-company/client-company.actions';

export const clientCompanyReducer = createReducer(
  initialClientCompanyState,

  on(ClientCompanyActions.loadClientCompanyById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyActions.loadClientCompanyByIdSuccess,
    (state, { clientCompany }) => ({
      ...state,
      clientCompany,
      loading: false,
    })
  ),
  on(ClientCompanyActions.loadClientCompanyByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ClientCompanyActions.updateClientCompany, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanyActions.updateClientCompanySuccess,
    (state, { clientCompany }) => ({
      ...state,
      clientCompany,
      loading: false,
    })
  ),
  on(ClientCompanyActions.updateClientCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ClientCompanyActions.deleteClientCompany, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ClientCompanyActions.deleteClientCompanySuccess, state => ({
    ...state,
    clientCompany: null,
    loading: false,
  })),
  on(ClientCompanyActions.deleteClientCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ClientCompanyActions.clientCompanyUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
