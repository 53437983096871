import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as KanbanCardAttachmentsActions from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.actions';
import { KanbanAttachment } from '~/app/shared/interfaces/kanban/kanban-attachment.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `KanbanCardAttachmentsEffects` manages the side effects for kanban card attachment-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban card attachments,
 * such as loading all kanban card attachments, creating, updating, and deleting kanban card attachments.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllKanbanCardAttachments$`: Loads all kanban card attachments. It performs an HTTP GET request and dispatches a success action with the fetched attachments or a failure action if an error occurs.
 * — `createKanbanCardAttachment$`: Creates a new kanban card attachment. It performs an HTTP POST request and dispatches a success action with the created attachment or a failure action if an error occurs.
 * — `updateKanbanCardAttachment$`: Updates an existing kanban card attachment. It performs an HTTP PATCH request and dispatches a success action with the updated attachment or a failure action if an error occurs.
 * — `deleteKanbanCardAttachment$`: Deletes a kanban card attachment. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `createKanbanCardAttachmentSuccess$`: Handles the success of creating a kanban card attachment by displaying a success toast message.
 * — `updateKanbanCardAttachmentSuccess$`: Handles the success of updating a kanban card attachment by displaying a success toast message.
 * — `deleteKanbanCardAttachmentSuccess$`: Handles the success of deleting a kanban card attachment by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanCardAttachmentsEffects {
  /**
   * Effect to load all attachments of a card.
   * Listens for the `loadAllCardAttachments` action, checks permissions, and performs an HTTP GET request to load the attachments.
   * Dispatches a success or failure action based on the result.
   */
  loadAllCardAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardAttachmentsActions.loadAllCardAttachments),
      checkPermission(
        KanbanCardAttachmentsActions.kanbanAttachmentsUnauthorized
      ),
      mergeMap(({ kanbanCardId }) =>
        this.http
          .get<KanbanAttachment[]>(
            `${environment.apiUrl}/v1/kanbans/cards/attachments`,
            {
              params: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(kanbanAttachments =>
              KanbanCardAttachmentsActions.loadAllCardAttachmentsSuccess({
                kanbanAttachments,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardAttachmentsActions.loadAllCardAttachmentsFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new kanban card attachment.
   * Listens for the `createKanbanCardAttachment` action, checks permissions, and performs an HTTP POST request to create the attachment.
   * Dispatches a success or failure action based on the result.
   */
  createKanbanCardAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardAttachmentsActions.createKanbanCardAttachment),
      checkPermission(
        KanbanCardAttachmentsActions.kanbanAttachmentsUnauthorized
      ),
      mergeMap(({ kanbanAttachmentData, kanbanCardId, kanbanColumnId }) =>
        this.http
          .post<KanbanAttachment>(
            `${environment.apiUrl}/v1/kanbans/cards/attachments`,
            kanbanAttachmentData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanAttachment =>
              KanbanCardAttachmentsActions.createKanbanCardAttachmentSuccess({
                kanbanAttachment,
                kanbanCardId,
                kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardAttachmentsActions.createKanbanCardAttachmentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a kanban card attachment.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  createKanbanCardAttachmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardAttachmentsActions.createKanbanCardAttachmentSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Attachment created successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing kanban card attachment.
   * Listens for the `updateKanbanCardAttachment` action, checks permissions, and performs an HTTP PATCH request to update the attachment.
   * Dispatches a success or failure action based on the result.
   */
  updateKanbanCardAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardAttachmentsActions.updateKanbanCardAttachment),
      checkPermission(
        KanbanCardAttachmentsActions.kanbanAttachmentsUnauthorized
      ),
      mergeMap(({ uuid, kanbanAttachmentData }) =>
        this.http
          .patch<KanbanAttachment>(
            `${environment.apiUrl}/v1/kanbans/cards/attachments/${uuid}`,
            kanbanAttachmentData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(kanbanAttachment =>
              KanbanCardAttachmentsActions.updateKanbanCardAttachmentSuccess({
                kanbanAttachment,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardAttachmentsActions.updateKanbanCardAttachmentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a kanban card attachment.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  updateKanbanCardAttachmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardAttachmentsActions.updateKanbanCardAttachmentSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Attachment updated successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a kanban card attachment.
   * Listens for the `deleteKanbanCardAttachment` action, checks permissions, and performs an HTTP DELETE request to delete the attachment.
   * Dispatches a success or failure action based on the result.
   */
  deleteKanbanCardAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardAttachmentsActions.deleteKanbanCardAttachment),
      checkPermission(
        KanbanCardAttachmentsActions.kanbanAttachmentsUnauthorized
      ),
      mergeMap(({ uuid, kanbanCardId, kanbanColumnId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/kanbans/cards/attachments/${uuid}`,
            {
              body: { kanbanCardId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              KanbanCardAttachmentsActions.deleteKanbanCardAttachmentSuccess({
                uuid,
                kanbanCardId,
                kanbanColumnId,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardAttachmentsActions.deleteKanbanCardAttachmentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a kanban card attachment.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteKanbanCardAttachmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KanbanCardAttachmentsActions.deleteKanbanCardAttachmentSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Attachment deleted successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `KanbanCardAttachmentsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
