import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileRemindersActions from '~/app/states/main/crm/profile/states/profile-reminders/profile-reminders.actions';
import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileRemindersEffects` manages the side effects for profile reminder-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile reminders, such as loading, creating, updating, deleting, checking, and unchecking profile reminders.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileReminders$`: Loads profile reminders based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched reminders or a failure action if an error occurs.
 * — `createProfileReminder$`: Creates a new profile reminder. It performs an HTTP POST request and dispatches a success action with the created reminder or a failure action if an error occurs.
 * — `createProfileReminderSuccess$`: Handles the success of creating a new profile reminder by displaying a success toast message and closing the sidebar form.
 * — `updateProfileReminder$`: Updates an existing profile reminder based on its UUID and profile reminder data. It performs an HTTP PATCH request and dispatches a success action with the updated reminder or a failure action if an error occurs.
 * — `updateProfileReminderSuccess$`: Handles the success of updating a profile reminder by displaying a success toast message and closing the sidebar form.
 * — `deleteProfileReminder$`: Deletes a profile reminder based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileReminderSuccess$`: Handles the success of deleting a profile reminder by displaying a success toast message and closing the sidebar form.
 * — `checkProfileReminder$`: Checks a profile reminder based on its UUID and profile ID. It performs an HTTP PATCH request and dispatches a success action if the check is successful or a failure action if an error occurs.
 * — `checkProfileReminderSuccess$`: Handles the success of checking a profile reminder by displaying a success toast message and closing the sidebar form.
 * — `uncheckProfileReminder$`: Unchecks a profile reminder based on its UUID and profile ID. It performs an HTTP PATCH request and dispatches a success action if the uncheck is successful or a failure action if an error occurs.
 * — `uncheckProfileReminderSuccess$`: Handles the success of unchecking a profile reminder by displaying a success toast message and closing the sidebar form.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileRemindersEffects {
  /**
   * Effect to load profile reminders based on a given profile ID.
   * Listens for the `loadProfileReminders` action, checks permissions, and performs an HTTP GET request to fetch the reminders.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.loadProfileReminders),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ profileId }) => {
        return this.http
          .get<Reminder[]>(`${environment.apiUrl}/v1/profiles/reminders`, {
            params: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(profileReminders =>
              ProfileRemindersActions.loadProfileRemindersSuccess({
                profileReminders,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.loadProfileRemindersFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to create a new profile reminder.
   * Listens for the `createProfileReminder` action, checks permissions, and performs an HTTP POST request to create the reminder.
   * Dispatches a success or failure action based on the result.
   */
  createProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.createProfileReminder),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ profileReminderData }) =>
        this.http
          .post<Reminder>(
            `${environment.apiUrl}/v1/profiles/reminders/`,
            profileReminderData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileReminder =>
              ProfileRemindersActions.createProfileReminderSuccess({
                profileReminder,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.createProfileReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile reminder.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileRemindersActions.createProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing profile reminder based on its UUID and profile reminder data.
   * Listens for the `updateProfileReminder` action, checks permissions, and performs an HTTP PATCH request to update the reminder.
   * Dispatches a success or failure action based on the result.
   */
  updateProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.updateProfileReminder),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ uuid, profileReminderData }) =>
        this.http
          .patch<Reminder>(
            `${environment.apiUrl}/v1/profiles/reminders/${uuid}`,
            profileReminderData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileReminder =>
              ProfileRemindersActions.updateProfileReminderSuccess({
                profileReminder,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.updateProfileReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a profile reminder.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileRemindersActions.updateProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a profile reminder based on its UUID and profile ID.
   * Listens for the `deleteProfileReminder` action, checks permissions, and performs an HTTP DELETE request to delete the reminder.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.deleteProfileReminder),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/reminders/${uuid}`, {
            body: { profileId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              ProfileRemindersActions.deleteProfileReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.deleteProfileReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a profile reminder.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  deleteProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileRemindersActions.deleteProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to check a profile reminder based on its UUID and profile ID.
   * Listens for the `checkProfileReminder` action, checks permissions, and performs an HTTP PATCH request to check the reminder.
   * Dispatches a success or failure action based on the result.
   */
  checkProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.checkProfileReminder),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .patch<Reminder>(
            `${environment.apiUrl}/v1/profiles/reminders/check/${uuid}`,
            { profileId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              ProfileRemindersActions.checkProfileReminderSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.checkProfileReminderFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of checking a profile reminder.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  checkProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileRemindersActions.checkProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder checked successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to uncheck a profile reminder based on its UUID and profile ID.
   * Listens for the `uncheckProfileReminder` action, checks permissions, and performs an HTTP PATCH request to uncheck the reminder.
   * Dispatches a success or failure action based on the result.
   */
  uncheckProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileRemindersActions.uncheckProfileReminder),
      checkPermission(ProfileRemindersActions.profileRemindersUnauthorized),
      mergeMap(({ uuid, profileId }) => {
        return this.http
          .patch<Reminder>(
            `${environment.apiUrl}/v1/profiles/reminders/uncheck/${uuid}`,
            { profileId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              ProfileRemindersActions.uncheckProfileReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileRemindersActions.uncheckProfileReminderFailure({ error })
              );
            })
          );
      })
    )
  );

  /**
   * Effect to handle the success of unchecking a profile reminder.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  uncheckProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileRemindersActions.uncheckProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder unchecked successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileRemindersEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
