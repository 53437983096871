import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanColumnsActions from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.actions';
import {
  CreateKanbanColumn,
  UpdateKanbanColumn,
  UpdateKanbanColumnSequences,
} from '~/app/states/main/kanban/types/kanban-columns.types';
import { KanbanFilter } from '~/app/shared/interfaces/kanban/kanban-filter';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { OldSequence } from '~/app/shared/interfaces/kanban/old-sequence.interface';
import { KanbanColumn } from '~/app/shared/interfaces/kanban/kanban-column.interface';

/**
 * Service responsible for managing Kanban columns and their associated actions.
 * Provides methods to load, create, update, delete, reorder columns, and switch Kanban card columns.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanColumnsService {
  /**
   * Constructs a new KanbanColumnsService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/

  /**
   * Loads all Kanban columns for a given Kanban board.
   * Dispatches a loadAllKanbanColumns action with the Kanban board ID.
   *
   * @param {KanbanFilter} filters – The filters for the limit & the page.
   * @param {boolean} reloadAll - If we need to reload the list of columns or not.
   * @returns {void}
   */
  loadAllKanbanColumns(filters: KanbanFilter, reloadAll?: boolean): void {
    this.store.dispatch(
      KanbanColumnsActions.loadAllKanbanColumns({ filters, reloadAll })
    );
  }

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/

  /**
   * Creates a new Kanban column for a given Kanban board.
   * Dispatches a createKanbanColumn action with the Kanban board ID and default column data.
   *
   * @param {AllKanbansFilters} filters - Page & limit filters.
   * @param {CreateKanbanColumn} columnData – The info about the name of the column and the uuid of the kanban.
   * @returns {void}
   */
  createKanbanColumn(
    filters: AllKanbansFilters,
    columnData: CreateKanbanColumn
  ): void {
    if (columnData.kanbanId) {
      this.store.dispatch(
        KanbanColumnsActions.createKanbanColumn({
          filters,
          columnData,
        })
      );
    }
  }

  /**
   * Updates an existing Kanban column.
   * Dispatches an updateKanbanColumn action with the column UUID and updated column data.
   *
   * @param {string} uuid – The UUID of the Kanban column to update.
   * @param {UpdateKanbanColumn} columnData – The updated data for the Kanban column.
   * @returns {void}
   */
  updateKanbanColumn(uuid: string, columnData: UpdateKanbanColumn): void {
    this.store.dispatch(
      KanbanColumnsActions.updateKanbanColumn({
        uuid,
        columnData,
      })
    );
  }

  /**
   * Deletes a Kanban column from a given Kanban board.
   * Dispatches a deleteKanbanColumn action with the Kanban board ID and column UUID.
   *
   * @param {string | null} kanbanId – The ID of the Kanban board.
   * @param {string} uuid – The UUID of the Kanban column to delete.
   * @returns {void}
   */
  deleteKanbanColumn(kanbanId: string | null, uuid: string): void {
    if (kanbanId) {
      this.store.dispatch(
        KanbanColumnsActions.deleteKanbanColumn({
          kanbanId,
          uuid,
        })
      );
    }
  }

  /**
   * ************************************************************************
   * REORDER COLUMNS
   * ************************************************************************
   **/

  /**
   * Reorders the sequence of Kanban columns.
   * Dispatches an updateKanbanColumnSequences action with the updated column sequences data.
   *
   * @param {AllKanbansFilters} filters - Filters containing limit & page.
   * @param {UpdateKanbanColumnSequences} columnSequencesData – The updated sequence data for Kanban columns.
   * @param {OldSequence} oldSequence - The previous index.
   * @returns {void}
   */
  reOrderColumnSequence(
    filters: AllKanbansFilters,
    columnSequencesData: UpdateKanbanColumnSequences,
    oldSequence: OldSequence[]
  ): void {
    this.store.dispatch(
      KanbanColumnsActions.updateKanbanColumnSequences({
        filters,
        columnSequencesData,
        oldSequence,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Creates a new Kanban column for a given Kanban board.
   * Dispatches a createKanbanColumn action with the Kanban board ID and default column data.
   *
   * @param {KanbanColumn} column – The column to add.
   * @returns {void}
   */
  createKanbanColumnServer(column: KanbanColumn): void {
    this.store.dispatch(
      KanbanColumnsActions.createKanbanColumnServer({ column })
    );
  }

  /**
   * Updates an existing Kanban column.
   * Dispatches an updateKanbanColumnServer action.
   *
   * @param {KanbanColumn} kanbanColumn – The Kanban column to update.
   * @returns {void}
   */
  updateKanbanColumnServer(kanbanColumn: KanbanColumn): void {
    this.store.dispatch(
      KanbanColumnsActions.updateKanbanColumnServer({ kanbanColumn })
    );
  }

  /**
   * Deletes a Kanban column from a given Kanban board.
   * Dispatches a deleteKanbanColumnServer action with the column UUID.
   *
   * @param {string} uuid – The UUID of the Kanban column to delete.
   * @returns {void}
   */
  deleteKanbanColumnServer(uuid: string): void {
    this.store.dispatch(
      KanbanColumnsActions.deleteKanbanColumnServer({ uuid })
    );
  }

  /**
   * Reorders the sequence of Kanban columns.
   * Dispatches an updateKanbanColumnSequencesServer action with the updated column sequences data.
   *
   * @param {UpdateKanbanColumnSequences} columnSequencesData – The updated sequence data for Kanban columns.
   * @returns {void}
   */
  reOrderColumnSequenceServer(
    columnSequencesData: UpdateKanbanColumnSequences
  ): void {
    this.store.dispatch(
      KanbanColumnsActions.updateKanbanColumnSequencesServer({
        columnSequencesData,
      })
    );
  }
}
