<div class="container">
  <h4 class="display-h4 color-dark-100 m-0">Post a job offer</h4>
  <p class="text-base color-dark-70 mb-4-8">
    Be as precise as possible to attract the right candidates.
  </p>

  <form
    [formGroup]="offerForm"
    (ngSubmit)="onSubmit()"
    class="mt-4-8 stepper overflow-auto">
    <p-stepper orientation="vertical">
      <p-stepperPanel header="Offer">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index">
          <div class="flex flex-column gap-3-5 lg:p-4">
            <ice-input
              [label]="'Job offer title'"
              [formControlName]="'title'"
              placeholder="ex: Senior Developer"
              [formControl]="title"
              [required]="true"></ice-input>
            <p-editor
              [style]="{ height: '320px' }"
              placeholder="ex: We are looking for . . ."
              formControlName="description">
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <select
                    class="ql-header"
                    title="Heading"
                    i18n-title
                    aria-label="Heading"
                    i18n-aria-label></select>
                  <button
                    type="button"
                    class="ql-bold"
                    aria-label="Bold"
                    i18n-aria-label></button>
                  <button
                    type="button"
                    class="ql-italic"
                    aria-label="Italic"
                    i18n-aria-label></button>
                  <button
                    type="button"
                    class="ql-underline"
                    aria-label="Underline"
                    i18n-aria-label></button>
                  <button
                    type="button"
                    class="ql-list"
                    value="bullet"
                    aria-label="Unordered List"
                    i18n-aria-label></button>
                  <button
                    type="button"
                    class="ql-link"
                    aria-label="Insert Link"
                    i18n-aria-label></button>
                  <select
                    class="ql-align"
                    title="Align"
                    i18n-title
                    aria-label="Align"
                    i18n-aria-label></select>
                </span>
              </ng-template>
            </p-editor>
          </div>

          <div class="flex justify-content-end py-4">
            <ice-button
              label="Next"
              icon="pi pi-arrow-down"
              (onClick)="nextCallback.emit()" />
          </div>
        </ng-template>
      </p-stepperPanel>

      <p-stepperPanel header="Location">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index">
          <div class="flex justify-content-center w-full">
            <div class="flex flex-column py-1 lg:py-4 lg:px-8">
              <div class="p-4 border-round bg-dark-3">
                <ice-input
                  [label]="'Street Address'"
                  [formControlName]="'streetAddress'"
                  [formControl]="streetAddress">
                </ice-input>
                <div class="flex flex-column sm:flex-row gap-2">
                  <ice-input
                    [label]="'City'"
                    [formControlName]="'city'"
                    [formControl]="city">
                  </ice-input>
                  <ice-input
                    [label]="'Postal Code'"
                    [formControlName]="'postalCode'"
                    [formControl]="postalCode">
                  </ice-input>
                  <ice-input
                    [label]="'Country'"
                    [formControlName]="'country'"
                    [formControl]="country">
                  </ice-input>
                </div>
              </div>

              <div
                class="flex gap-3-5 align-items-center w-full justify-content-center">
                <p class="text-2xl">🏁</p>
                <p-calendar
                  dateFormat="yy-mm-dd"
                  [placeholder]="'2024-07-12'"
                  i18n-dateFormat
                  label="Start Date"
                  [formControlName]="'startDate'"
                  [formControl]="startDate"
                  appendTo="body"
                  i18n-appendTo></p-calendar>
              </div>
            </div>
          </div>

          <div class="flex justify-content-between py-4 gap-2">
            <ice-button
              label="Back"
              severity="secondary"
              (onClick)="prevCallback.emit()" />
            <ice-button
              label="Next"
              icon="pi pi-arrow-down"
              (onClick)="nextCallback.emit()" />
          </div>
        </ng-template>
      </p-stepperPanel>

      <p-stepperPanel header="Minimum requirements?">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index">
          <div
            class="flex flex-column gap-3-5 p-1 lg:p-4 border-round bg-dark-3">
            <div class="border-round bg-light-100 p-4">
              <div class="flex gap-3-5 flex-wrap">
                <div
                  *ngFor="let offerDocument of offerDocumentTypes$ | async"
                  class="flex gap-1 align-items-center">
                  <ice-checkbox
                    [checked]="isDocumentChecked(offerDocument.value)"
                    [binary]="true"
                    (onChange)="
                      onDocumentToggle($event.checked, offerDocument)
                    ">
                  </ice-checkbox>
                  <p class="text-sm m-0 color-dark-70">
                    {{ offerDocument.label }}
                  </p>
                </div>
              </div>
            </div>
            <div class="border-round bg-light-100 p-4">
              <div
                class="flex flex-column md:flex-row align-items-center gap-2">
                <ice-autocomplete
                  [dropdown]="false"
                  [optionLabel]="'label'"
                  [formControl]="skill"
                  formControlName="skill"
                  placeholder="Choose a skill"
                  i18n-placeholder
                  (onSelect)="onSelectSkill($event.value)"
                  (completeMethod)="searchSkills($event)"
                  [suggestions]="(skills$ | async) ?? []"></ice-autocomplete>

                <ice-autocomplete
                  [suggestions]="(skillExperiences$ | async) ?? []"
                  [formControl]="skillExperience"
                  formControlName="skillExperience"
                  [placeholder]="'Experience'"
                  [dropdown]="false"
                  optionLabel="label"
                  optionValue="value"
                  i18n-optionLabel></ice-autocomplete>

                <ice-button
                  class="mb-2"
                  type="button"
                  label="Add"
                  i18n-label
                  [disabled]="!skill.value || !skillExperience.value"
                  (onClick)="addSkill()"></ice-button>
              </div>
              <div formArrayName="skills" class="flex flex-wrap gap-1">
                <div *ngFor="let skillGroup of skills.controls; let i = index">
                  <p-chip
                    [label]="
                      getSkill(i).value.skillId.label +
                      '  |  ' +
                      getSkill(i).value.experienceMinimum
                    "
                    [icon]="'pi pi-bolt'"
                    [removable]="true"
                    (onRemove)="removeSkill(i)"></p-chip>
                </div>

                <div
                  *ngIf="skills.controls.length < 1"
                  class="hidden md:flex flex-wrap gap-1">
                  <p-skeleton width="4rem" height="2rem"></p-skeleton>
                  <p-skeleton width="8rem" height="2rem"></p-skeleton>
                </div>
              </div>
            </div>
            <div
              class="flex gap-7-75 justify-content-center align-items-center border-round bg-light-100 p-4">
              <div class="flex flex-column gap-3-5 w-fit">
                <p class="flex gap-2 text-sm color-dark-70 m-0">
                  <app-ice-info-tooltip
                    appendTo="body"
                    text="Voici plus d'informations sur ce sujet."
                    i18n-text></app-ice-info-tooltip>
                  Proposal Limit
                </p>
                <div
                  class="border-round border-1 surface-border inline-flex mb-5">
                  <p-inputNumber
                    [showButtons]="true"
                    buttonLayout="horizontal"
                    formControlName="proposalLimit"
                    [min]="0"
                    inputStyleClass="w-5rem text-center"
                    decrementButtonClass="p-button-text p-button-danger"
                    incrementButtonClass="p-button-text p-button-success"
                    incrementButtonIcon="pi pi-user-plus"
                    decrementButtonIcon="pi pi-user-minus"></p-inputNumber>
                </div>
              </div>

              <div class="flex flex-column gap-3-5 w-fit">
                <p class="flex gap-2 text-sm color-dark-70 m-0">
                  <app-ice-info-tooltip
                    appendTo="body"
                    text="Voici plus d'informations sur ce sujet."
                    i18n-text></app-ice-info-tooltip>
                  Profiles
                </p>
                <div
                  class="border-round border-1 surface-border inline-flex mb-5">
                  <p-inputNumber
                    [showButtons]="true"
                    formControlName="minimumProfiles"
                    buttonLayout="horizontal"
                    [min]="0"
                    inputStyleClass="w-5rem text-center"
                    decrementButtonClass="p-button-text p-button-danger"
                    incrementButtonClass="p-button-text p-button-success"
                    incrementButtonIcon="pi pi-user-plus"
                    decrementButtonIcon="pi pi-user-minus"></p-inputNumber>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-content-between py-4 gap-2">
            <ice-button
              severity="secondary"
              label="Back"
              (onClick)="prevCallback.emit()" />
            <ice-button
              label="Next"
              icon="pi pi-arrow-down"
              (onClick)="nextCallback.emit()" />
          </div>
        </ng-template>
      </p-stepperPanel>

      <p-stepperPanel header="Post settings">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-index="index">
          <div
            class="flex flex-column gap-7-75 p-1 lg:p-4 border-round bg-dark-3">
            <div class="flex gap-3-5 border-round bg-light-100 p-4">
              <ice-autocomplete
                [required]="true"
                [optionLabel]="'label'"
                [optionValue]="'value'"
                label="Visibility"
                i18n-label
                [suggestions]="(offerVisibilityTypes$ | async) ?? []"
                [formControlName]="'visibility'"
                [dropdown]="false"
                [formControl]="visibility">
                ></ice-autocomplete
              >
              <ice-autocomplete
                *ngIf="visibilityValue !== 'public'"
                [required]="true"
                label="Reference"
                i18n-label
                [suggestions]="(groupReferenceId$ | async) ?? []"
                [formControlName]="'groupReferenceId'"
                optionLabel="label"
                optionValue="value"
                i18n-optionLabel
                [dropdown]="false"
                [formControl]="groupReferenceId">
              </ice-autocomplete>
            </div>

            <div class="flex gap-3-5 border-round bg-light-100 p-4">
              <div class="flex flex-column sm:flex-row gap-2">
                <div class="text-900 font-medium mb-2">
                  <span class="block text-900 font-semibold text-sm mb-1">
                    Rate <span class="text-red-600">*</span>
                  </span>
                  <p-inputNumber
                    formControlName="rate"
                    mode="currency"
                    i18n-mode
                    currency="EUR"
                    i18n-currency
                    [required]="true"
                    [showButtons]="true"
                    [min]="0"
                    [step]="50"
                    [formControl]="rate"
                    inputId="rate"
                    i18n-inputId>
                  </p-inputNumber>
                </div>
                <ice-autocomplete
                  label="Rate Unit"
                  [required]="true"
                  [suggestions]="(offerUnitTypes$ | async) ?? []"
                  [formControlName]="'rateUnit'"
                  [optionValue]="'value'"
                  [optionLabel]="'label'"
                  [dropdown]="false"
                  [formControl]="rateUnit"></ice-autocomplete>
              </div>
            </div>
          </div>

          <div class="flex justify-content-between py-4">
            <ice-button
              severity="secondary"
              label="Back"
              (onClick)="prevCallback.emit()" />
            <ice-button
              type="submit"
              label="Post"
              i18n-label
              [disabled]="offerForm.invalid"
              class="mr-2 flex justify-content-end"
              [loading]="(isLoading$ | async) ?? false"></ice-button>
          </div>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
    <div class="ice-bottom-padding"></div>
  </form>
  <div class="ice-blur-border mt-7-75"></div>
</div>
