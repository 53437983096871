import { createReducer, on } from '@ngrx/store';
import { initialKanbanCandidateState } from '~/app/states/main/kanban/states/candidates/kanban-candidate.state';
import * as KanbanCandidateActions from '~/app/states/main/kanban/states/candidates/kanban-candidate.actions';

export const kanbanCandidateReducer = createReducer(
  initialKanbanCandidateState,
  on(KanbanCandidateActions.loadKanbanCandidates, state => ({
    ...state,
    loading: true,
  })),
  on(
    KanbanCandidateActions.loadKanbanCandidatesSuccess,
    (state, { candidates }) => ({
      ...state,
      candidates,
      loading: false,
    })
  ),
  on(
    KanbanCandidateActions.loadKanbanCandidatesFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  )
);
