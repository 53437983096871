import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as CandidateStatusActions from '~/app/states/main/settings/states/status/candidate-status/candidate-status.actions';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `CandidateStatusEffects` manages the side effects related to candidate statuses in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting candidate statuses from the server.
 *
 * The effects in this class include:
 * — `loadCandidateStatuses$`: Fetches candidate statuses from the server.
 * — `createCandidateStatus$`: Creates a new candidate status on the server.
 * — `createCandidateStatusSuccess$`: Handles successful candidate status creation.
 * — `updateCandidateStatus$`: Updates an existing candidate status on the server.
 * — `updateCandidateStatusSuccess$`: Handles successful candidate status updates.
 * — `deleteCandidateStatus$`: Deletes a candidate status from the server.
 * — `deleteCandidateStatusSuccess$`: Handles successful candidate status deletion.
 * — `updateProfileStatusSequence$`: Updates the sequence of candidate statuses on the server.
 * — `updateProfileStatusSequenceSuccess$`: Handles successful candidate status sequence updates.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class CandidateStatusEffects {
  /**
   * Effect to load candidate statuses from the server.
   * It listens for the `loadCandidateStatuses` action, checks permissions, and makes an HTTP GET request.
   * Upon success, it dispatches the `loadCandidateStatusesSuccess` action with the retrieved candidate statuses.
   */
  loadCandidateStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateStatusActions.loadCandidateStatuses),
      checkPermission(CandidateStatusActions.candidateStatusUnauthorized),
      mergeMap(() =>
        this.http
          .get<Status[]>(`${environment.apiUrl}/v1/profiles/status`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(candidateStatuses =>
              CandidateStatusActions.loadCandidateStatusesSuccess({
                candidateStatuses,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateStatusActions.loadCandidateStatusesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new candidate status on the server.
   * It listens for the `createCandidateStatus` action, checks permissions, and makes an HTTP POST request.
   * Upon success, it dispatches the `createCandidateStatusSuccess` action with the created candidate status.
   */
  createCandidateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateStatusActions.createCandidateStatus),
      checkPermission(CandidateStatusActions.candidateStatusUnauthorized),
      mergeMap(({ candidateStatusData }) =>
        this.http
          .post<Status>(
            `${environment.apiUrl}/v1/profiles/status`,
            candidateStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(status =>
              CandidateStatusActions.createCandidateStatusSuccess({
                status,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateStatusActions.createCandidateStatusFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of candidate status creation.
   * It listens for the `createCandidateStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  createCandidateStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateStatusActions.createCandidateStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Candidate status created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing candidate status on the server.
   * It listens for the `updateCandidateStatus` action, checks permissions, and makes an HTTP PATCH request.
   * Upon success, it dispatches the `updateCandidateStatusSuccess` action with the updated candidate status.
   */
  updateCandidateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateStatusActions.updateCandidateStatus),
      checkPermission(CandidateStatusActions.candidateStatusUnauthorized),
      mergeMap(({ uuid, candidateStatusData }) =>
        this.http
          .patch<Status>(
            `${environment.apiUrl}/v1/profiles/status/${uuid}`,
            candidateStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(status =>
              CandidateStatusActions.updateCandidateStatusSuccess({
                status,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateStatusActions.updateCandidateStatusFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of candidate status update.
   * It listens for the `updateCandidateStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  updateCandidateStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateStatusActions.updateCandidateStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Candidate status updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a candidate status from the server.
   * It listens for the `deleteCandidateStatus` action, checks permissions, and makes an HTTP DELETE request.
   * Upon success, it dispatches the `deleteCandidateStatusSuccess` action with the deleted candidate status UUID.
   */
  deleteCandidateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateStatusActions.deleteCandidateStatus),
      checkPermission(CandidateStatusActions.candidateStatusUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/profiles/status/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              CandidateStatusActions.deleteCandidateStatusSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                CandidateStatusActions.deleteCandidateStatusFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of candidate status deletion.
   * It listens for the `deleteCandidateStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  deleteCandidateStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateStatusActions.deleteCandidateStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Candidate status deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the sequence of candidate statuses on the server.
   * It listens for the `updateProfileStatusSequence` action, checks permissions, and makes an HTTP PATCH request.
   * Upon success, it dispatches the `updateProfileStatusSequenceSuccess` action with the updated statuses.
   */
  updateProfileStatusSequence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateStatusActions.updateProfileStatusSequence),
        checkPermission(CandidateStatusActions.candidateStatusUnauthorized),
        mergeMap(({ statuses }) =>
          this.http
            .patch<void>(
              `${environment.apiUrl}/v1/profiles/status/sequences`,
              { statuses },
              { withCredentials: true }
            )
            .pipe(
              take(1),
              map(() =>
                CandidateStatusActions.updateProfileStatusSequenceSuccess({
                  statuses,
                })
              ),
              catchError(error => {
                this.httpErrors.handleError(error);
                return of(
                  CandidateStatusActions.updateProfileStatusSequenceFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: true }
  );

  /**
   * Effect to handle the success of candidate status sequence update.
   * It listens for the `updateProfileStatusSequenceSuccess` action and shows a success toast message.
   */
  updateProfileStatusSequenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CandidateStatusActions.updateProfileStatusSequenceSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Candidate status sequence updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `CandidateStatusEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for showing toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
