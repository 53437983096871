import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, mergeMap, of, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TimelinesState } from '~/app/states/main/sales/states/timelines/timelines.state';
import { TimelineTask } from '~/app/shared/interfaces/sales/timelines/timeline-task.interface';
import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { withTimestamp } from '~/app/shared/operators/with-timestamp';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import * as TimelinesActions from '~/app/states/main/sales/states/timelines/timelines.actions';
import { TimelineTuple } from '~/app/shared/types/timeline-tuple.type';
import { environment } from '~/environments/environment';

/**
 * `TimelinesEffects` manages the side effects related to timelines and timeline tasks in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading timelines, creating timelines, updating timelines and their tasks, and deleting timelines.
 *
 * The effects in this class include:
 * — `loadTimelines$`: Fetches timelines from the server.
 * — `loadSelectedTimelines$`: Fetches a selected timeline from the server.
 * — `createTimeline$`: Creates a new timeline on the server.
 * — `createTimelineSuccess$`: Handles successful timeline creation.
 * — `deleteTimeline$`: Deletes a timeline from the server.
 * — `updateTimeline$`: Updates a timeline and its associated tasks on the server.
 * — `updateTimelineSuccess$`: Handles successful timeline update.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class TimelinesEffects {
  /**
   * Effect to load timelines. It listens for the `loadTimelines` action,
   * checks for permissions, and makes an HTTP GET request to fetch the timelines.
   * Upon successful fetch, it dispatches a success action with the fetched timelines.
   */
  loadTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelinesActions.loadTimelines),
      checkPermission(TimelinesActions.timelinesUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<TimelinesState>(`${environment.apiUrl}/v1/timelines`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(timelines =>
              TimelinesActions.loadTimelinesSuccess({ timelines })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TimelinesActions.loadTimelinesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load a selected timeline. It listens for the `loadSelectedTimelines` action,
   * checks for permissions, and makes an HTTP GET request to fetch the selected timeline.
   * Upon successful fetch, it dispatches a success action with the fetched selected timeline.
   */
  loadSelectedTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelinesActions.loadSelectedTimelines),
      checkPermission(TimelinesActions.timelinesUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Timeline>(`${environment.apiUrl}/v1/timelines/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(selectedTimeline =>
              TimelinesActions.loadSelectedTimelinesSuccess({
                selectedTimeline,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                TimelinesActions.loadSelectedTimelinesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a timeline. It listens for the `createTimeline` action,
   * checks for permissions, and makes an HTTP POST request to create the timeline.
   * Upon successful creation, it dispatches a success action with the created timeline.
   */
  createTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelinesActions.createTimeline),
      checkPermission(TimelinesActions.timelinesUnauthorized),
      mergeMap(({ name, description }) =>
        this.http
          .post<Timeline>(
            `${environment.apiUrl}/v1/timelines`,
            { name, description },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(timeline =>
              TimelinesActions.createTimelineSuccess({ timeline })
            ),
            catchError(error =>
              of(TimelinesActions.createTimelineFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * Effect to handle successful creation of a timeline. It listens for the
   * `createTimelineSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  createTimelineSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TimelinesActions.createTimelineSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Timeline created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a timeline. It listens for the `deleteTimeline` action,
   * checks for permissions, and makes an HTTP DELETE request to delete the timeline.
   * Upon successful deletion, it dispatches a success action with the UUID of the deleted timeline.
   */
  deleteTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelinesActions.deleteTimeline),
      checkPermission(TimelinesActions.timelinesUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/timelines/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => TimelinesActions.deleteTimelineSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TimelinesActions.deleteTimelineFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful deletion of a timeline. It listens for the
   * `deleteTimelineSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  deleteTimelineSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TimelinesActions.deleteTimeline),
        tap(() => {
          this.toast.successToast('Success', 'Timeline deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a timeline and its tasks. It listens for the `updateTimeline` action,
   * and makes HTTP requests to update the timeline, create new tasks, update existing tasks,
   * and delete tasks. Upon successful update, it dispatches a success action with the updated timeline
   * and associated tasks.
   */
  updateTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelinesActions.updateTimeline),
      mergeMap(
        ({ uuid, updateTimeline, createTasks, updateTasks, deleteTasks }) =>
          forkJoin({
            updateTimeline: withTimestamp(
              this.http.patch<Timeline>(
                `${environment.apiUrl}/v1/timelines/${uuid}`,
                updateTimeline,
                { withCredentials: true }
              ),
              'updateTimeline'
            ),
            createTasks: withTimestamp(
              this.http.post<TimelineTask[]>(
                `${environment.apiUrl}/v1/timelines/tasks/bulk`,
                createTasks,
                { withCredentials: true }
              ),
              'createTasks'
            ),
            updateTasks: withTimestamp(
              this.http.patch<TimelineTask[]>(
                `${environment.apiUrl}/v1/timelines/tasks/bulk`,
                updateTasks,
                { withCredentials: true }
              ),
              'updateTasks'
            ),
            deleteTasks: withTimestamp(
              this.http.delete<TimelineTask[]>(
                `${environment.apiUrl}/v1/timelines/tasks/bulk`,
                { body: deleteTasks, withCredentials: true }
              ),
              'deleteTasks'
            ),
          }).pipe(
            take(1),
            map(
              responses =>
                [
                  Object.values(responses),
                  responses.updateTimeline,
                ] as TimelineTuple
            ),
            map(
              ([responsesArray, updatedTimeline]) =>
                [
                  responsesArray.sort((a, b) => a.timestamp - b.timestamp),
                  updatedTimeline,
                ] as TimelineTuple
            ),
            map(
              ([sortedResponses, updatedTimeline]) =>
                [
                  sortedResponses.filter(
                    response => response.name !== 'updateTimeline'
                  ),
                  updatedTimeline,
                ] as TimelineTuple
            ),
            map(([filteredResponses, updatedTimeline]) => {
              const updateTimelineResponse = updatedTimeline.data;
              const tasks = filteredResponses[filteredResponses.length - 1]
                .data as TimelineTask[];
              return TimelinesActions.updateTimelineSuccess({
                timeline: updateTimelineResponse,
                tasks,
              });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(TimelinesActions.updateTimelineFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful update of a timeline. It listens for the
   * `updateTimelineSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  updateTimelineSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TimelinesActions.updateTimelineSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Timeline updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `TimelinesEffects`.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - Injectable service for displaying custom toast notifications.
   * @param {SidenavFormService} sidebarService - Injectable service for handling sidebar operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
