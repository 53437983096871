import { Component, Input } from '@angular/core';

/**
 * `IceTagComponent` is an Angular component that displays a customizable tag with text, background color, and an optional icon.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-tag' - The CSS selector that identifies this component in a template.
 * @templateUrl './ice-tag.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-tag.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'ice-tag',
  templateUrl: './ice-tag.component.html',
  styleUrls: ['./ice-tag.component.scss'],
})
export class IceTagComponent {
  /**
   * The text to display inside the tag. This input is required.
   *
   * @type {string | undefined}
   */
  @Input({ required: true }) text: string | undefined;

  /**
   * The background color of the tag. This input is optional.
   *
   * @type {string | undefined}
   */
  @Input({ required: false }) color: string | undefined;

  /**
   * The icon class to display inside the tag. This input is optional.
   *
   * @type {string | undefined}
   */
  @Input({ required: false }) icon: string | undefined;

  /**
   * Boolean to enable the color of the font directly by the component
   *
   * @type {boolean}
   */
  @Input({ required: false }) smartColor = false;
}
