import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ProposalHistoryActions from '~/app/states/main/jobhub/states/proposal-history/proposal-history.actions';
import { ProposalHistoryState } from '~/app/states/main/jobhub/states/proposal-history/proposal-history.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `ProposalHistoryEffects` manages the side effects for proposal tasks-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to proposal tasks,
 * such as loading the proposal tasks. It performs operations that involve checking permissions,
 * interacting with services for handling HTTP requests, and handling errors.
 *
 * The effect in this class includes:
 * — `loadProposalHistory$`: Loads the proposal tasks. It performs an HTTP GET request and dispatches a success action with the fetched tasks or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProposalHistoryEffects {
  /**
   * Effect to load the proposal tasks.
   * Listens for the `loadProposalHistory` action, checks permissions, and performs an HTTP GET request to fetch the proposal tasks.
   * Dispatches a success or failure action based on the result.
   */
  loadProposalHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProposalHistoryActions.loadProposalHistory),
      checkPermission(ProposalHistoryActions.proposalHistoryUnauthorized),
      mergeMap(() =>
        this.http
          .get<ProposalHistoryState>(
            `${environment.apiUrl}/v1/offers/proposals/history`,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(proposalHistory =>
              ProposalHistoryActions.loadProposalHistorySuccess({
                proposalHistory,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProposalHistoryActions.loadProposalHistoryFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `ProposalHistoryEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
