import { createReducer, on } from '@ngrx/store';
import { initialHomeUserState } from '~/app/states/main/sales/home/states/home-users/home-users.state';
import * as HomeUsersActions from '~/app/states/main/sales/home/states/home-users/home-users.actions';

export const homeUsersReducer = createReducer(
  initialHomeUserState,

  on(HomeUsersActions.loadHomeUsersAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HomeUsersActions.loadHomeUsersActionSuccess, (state, { users }) => ({
    ...state,
    users,
    loading: false,
  })),
  on(HomeUsersActions.loadHomeUsersActionFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);
