import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as HomeProfileRemindersActions from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.actions';
import { HomeProfileRemindersState } from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `HomeProfileRemindersEffects` orchestrates the handling of asynchronous operations related to home profile reminders in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to home profile reminders, such as fetching,
 * checking, and unchecking reminders from and to the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * — `loadHomeProfileReminders$`: Fetches home profile reminders from the server.
 * — `checkProfileReminder$`: Checks a profile reminder.
 * — `uncheckProfileReminder$`: Unchecks a profile reminder.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success
 * action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class HomeProfileRemindersEffects {
  /**
   * Effect to load home profile reminders.
   */
  loadHomeProfileReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeProfileRemindersActions.loadHomeProfileReminders),
      checkPermission(
        HomeProfileRemindersActions.homeProfileRemindersUnauthorized
      ),
      mergeMap(({ filters }) =>
        this.http
          .get<HomeProfileRemindersState>(
            `${environment.apiUrl}/v1/profiles/allReminders`,
            {
              params: { ...filters },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileReminders =>
              HomeProfileRemindersActions.loadHomeProfileRemindersSuccess({
                profileReminders,
                filters,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeProfileRemindersActions.loadHomeProfileRemindersFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to check a profile reminder.
   */
  checkProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeProfileRemindersActions.checkProfileReminder),
      checkPermission(
        HomeProfileRemindersActions.homeProfileRemindersUnauthorized
      ),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .patch(
            `${environment.apiUrl}/v1/profiles/reminders/check/${uuid}`,
            { profileId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              HomeProfileRemindersActions.checkProfileReminderSuccess({ uuid })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeProfileRemindersActions.checkProfileReminderFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful check of a profile reminder.
   */
  checkProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomeProfileRemindersActions.checkProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder checked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to uncheck a profile reminder.
   */
  uncheckProfileReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeProfileRemindersActions.uncheckProfileReminder),
      checkPermission(
        HomeProfileRemindersActions.homeProfileRemindersUnauthorized
      ),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .patch(
            `${environment.apiUrl}/v1/profiles/reminders/uncheck/${uuid}`,
            { profileId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() =>
              HomeProfileRemindersActions.uncheckProfileReminderSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                HomeProfileRemindersActions.uncheckProfileReminderFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful uncheck of a profile reminder.
   */
  uncheckProfileReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomeProfileRemindersActions.uncheckProfileReminderSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reminder unchecked successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for HomeProfileRemindersEffects.
   *
   * @param {Actions} actions$ - The stream of actions observed by this effect.
   * @param {HttpClient} http - The HTTP client used for making API calls.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors.
   * @param {CustomToastService} toast - Service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
