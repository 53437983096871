import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

/**
 * Represents the state for candidate tags in the CRM system.
 *
 * @interface CandidateTagsState
 */
export interface CandidateTagsState {
  /**
   * The list of candidate tags.
   *
   * @type {Tag[]}
   */
  candidateTags: Tag[];

  /**
   * Indicates if the candidate tags are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from loading the candidate tags.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for candidate tags.
 *
 * @constant {CandidateTagsState} initialCandidateTagsState
 * @description Provides a baseline definition for the candidate tags context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialCandidateTagsState: CandidateTagsState = {
  candidateTags: [],
  loading: false,
  error: null,
};
