/* eslint-disable @typescript-eslint/member-ordering */
/**
 * We are disabling the member-ordering rule here because the specific order of methods
 * is crucial for the functionality of this component. Moving the event handler
 * (onWindowScroll) above the other public methods breaks the scroll-related logic.
 * This choice ensures that the scroll logic and lifecycle methods work as intended.
 * We disable this rule only in this file to avoid unnecessary refactoring that could
 * affect the behavior of the component.
 */
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { fadeInOut } from '~/app/shared/animations/fade-in-out';

/**
 * The StorytellingComponent manages the progress bar animation
 * and updates the displayed message as the user scrolls down the page.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-storytelling' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './storytelling.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./storytelling.component.scss'] – Array of paths to the stylesheets used for this component.
 * @animations Contains the fadeInOut trigger, which animates the appearance and disappearance of elements.
 */
@Component({
  selector: 'app-storytelling',
  templateUrl: './storytelling.component.html',
  styleUrls: ['./storytelling.component.scss'],
  animations: [fadeInOut],
})
export class StorytellingComponent implements OnInit {
  /**
   * Tracks the progress of the scroll-based animation as a percentage (0-100).
   *
   * @type {number}
   */
  progress = 0;

  /**
   * The message displayed during the progress animation.
   *
   * @type {string}
   */
  message = 'Extracting primary infos...';

  /**
   * A flag indicating if the progress has reached 100%.
   *
   * @type {boolean}
   */
  isCompleted = false;

  /**
   * A flag indicating if the progress section is visible within the viewport.
   *
   * @type {boolean}
   */
  isVisible = false;

  /**
   * A map that links progress percentages to specific messages.
   *
   * @type {Map<number, string>}
   */
  private messages = new Map<number, string>([
    [15, 'Extracting primary infos...'],
    [30, 'Matching languages first...'],
    [70, 'Finding required skills...'],
    [90, 'Almost done...'],
    [95, 'Sorting the result...'],
  ]);

  /**
   * Constructor that injects the element reference into the component.
   *
   * @param {ElementRef} el - Reference to the element of this component.
   */
  constructor(private el: ElementRef) {}

  /**
   * Lifecycle hook that is called after the component's view has been initialized.
   * Initializes the observer that tracks the visibility of the progress section.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.createObserver();
  }

  /**
   * Event handler for window scroll events. It calculates the user's scroll position relative to
   * the progress container and updates the progress percentage and message accordingly.
   * When the scroll reaches 100%, it marks the progress as completed.
   *
   * @HostListener('window:scroll', []) Binds the window scroll event to this method.
   * @returns {void}
   */
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const section = this.el.nativeElement.querySelector(
      '.progress-container'
    ) as HTMLElement;
    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
    const sectionHeight = section.offsetHeight;

    const scrollPosition = window.scrollY;

    const startScroll = sectionTop - 250; // Adjusting for a top offset, don't know why it is 200 too high
    const endScroll = sectionTop + sectionHeight;
    const totalScroll = endScroll - startScroll;

    // Calculate the percentage of the section scrolled relative to the section height
    const progressPercentage = Math.min(
      ((scrollPosition - startScroll) / totalScroll) * 140,
      100
    );
    this.progress = Math.max(progressPercentage, 0);

    // Update the displayed message based on the current progress
    this.updateMessage(this.progress);

    // Mark as completed when the progress reaches 100%
    if (this.progress >= 100) {
      this.isCompleted = true;
    } else {
      this.isCompleted = false;
    }
  }

  /**
   * Creates an IntersectionObserver to detect when the progress container is visible within the viewport.
   * When 10% of the section is visible, it triggers the scroll animation logic.
   *
   * @returns {void}
   */
  createObserver(): void {
    const observerOptions = {
      root: null, // Uses the viewport
      threshold: 0.1, // Trigger when 10% of the section is visible
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
          this.onWindowScroll(); // Initialize scrolling logic
        } else {
          this.isVisible = false;
        }
      });
    }, observerOptions);

    observer.observe(this.el.nativeElement);
  }

  /**
   * Updates the displayed message based on the current progress percentage.
   * Messages are mapped to specific progress milestones (e.g., 15%, 30%).
   *
   * @param {number} progress - The current progress percentage.
   * @returns {void}
   */
  updateMessage(progress: number): void {
    for (const [key, value] of this.messages) {
      if (progress >= key) {
        this.message = value;
      }
    }
  }

  /**
   * Completes the progress animation by marking the progress as completed after a 500ms delay.
   * This method is typically called when the progress reaches 100%.
   *
   * @returns {void}
   */
  completeProgress(): void {
    setTimeout(() => {
      this.isCompleted = true;
    }, 500); // Delay to allow the message to appear
  }
}
