import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';

/**
 * Represents the state of the sales sector jobs module.
 *
 * @interface SalesSectorJobsState
 */
export interface SalesSectorJobsState {
  /**
   * The list of sales sector job records, or null if not loaded.
   *
   * @type {SectorJobs[] | null}
   */
  salesSectorJobs: SectorJobs[] | null;

  /**
   * Indicates whether the sales sector jobs are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales sector jobs within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesSectorJobsState} initialSalesSectorJobsState
 * @description Provides a baseline definition for the sales sector jobs context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesSectorJobsState: SalesSectorJobsState = {
  salesSectorJobs: [],
  loading: false,
  error: null,
};
