import { createSelector } from '@ngrx/store';
import { selectProfileState } from '~/app/main/crm/profile/profile.selector';

export const selectProfileQuickNav = createSelector(
  selectProfileState,
  state => state.quickNav
);

export const selectProfilesUUIDs = createSelector(
  selectProfileQuickNav,
  state => state.uuids
);
