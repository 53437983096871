import { Pipe, PipeTransform } from '@angular/core';

/**
 * NormalCasePipe transforms a string into a normal case format,
 * replacing underscores, hyphens, and camel case transitions with spaces.
 * It can also format the output to title case if specified.
 *
 * @Pipe
 * @name 'normalCase' — The name of the pipe to be used in templates.
 */
@Pipe({
  name: 'normalCase',
})
export class NormalCasePipe implements PipeTransform {
  /**
   * Transforms a given string into normal case.
   *
   * Normal case replaces:
   * - underscores (_) and hyphens (-) with spaces.
   * - camel case transitions with a space.
   * Also, it changes multiple spaces to a single space and trims the result.
   *
   * If upperCase is true, the first character of each word is capitalized.
   *
   * @param {string} value — The input string to be transformed.
   * @param {boolean} [upperCase=false] — Optional parameter to determine if the output should be in title case.
   * @return {string} The transformed string in normal case or title case if specified.
   */
  transform(value: string, upperCase = false): string {
    if (!value) {
      return value;
    }

    const result = value
      .replace(/_/g, ' ')
      .replace(/-/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\s+/g, ' ')
      .trim();

    if (upperCase) {
      return result
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    return result.toLowerCase();
  }
}
