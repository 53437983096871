import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnumsState } from '~/app/core/state/enum/enums.state';

export const enumState = createFeatureSelector<EnumsState>('enums');

export const selectEnumState = createSelector(enumState, state => state.enums);

export const selectCecrls = createSelector(
  selectEnumState,
  state => state.cecrls
);

export const selectOfferStatuses = createSelector(
  selectEnumState,
  state => state.offerStatuses
);

export const selectOfferUnits = createSelector(
  selectEnumState,
  state => state.offerUnits
);

export const selectOfferVisibilities = createSelector(
  selectEnumState,
  state => state.offerVisibilities
);

export const selectOfferDocumentTypes = createSelector(
  selectEnumState,
  state => state.offerDocumentTypes
);

export const selectProfileExperiences = createSelector(
  selectEnumState,
  state => state.profileExperiences
);

export const selectProfileFiles = createSelector(
  selectEnumState,
  state => state.profileFiles
);

export const selectProfileTimeUnits = createSelector(
  selectEnumState,
  state => state.profileTimeUnits
);

export const selectProfileTypes = createSelector(
  selectEnumState,
  state => state.profileTypes
);

export const selectProposalStatuses = createSelector(
  selectEnumState,
  state => state.proposalStatuses
);

export const selectReminders = createSelector(
  selectEnumState,
  state => state.reminders
);

export const selectSaleInformations = createSelector(
  selectEnumState,
  state => state.saleInformations
);

export const selectSocials = createSelector(
  selectEnumState,
  state => state.socials
);

export const selectGenders = createSelector(
  selectEnumState,
  state => state.genders
);

export const selectSkillExperiences = createSelector(
  selectEnumState,
  state => state.skillExperiences
);
