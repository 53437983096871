import { createAction, props } from '@ngrx/store';
import { AssignSkill } from '~/app/states/main/crm/profile/types/profile-skills.types';
import { Skill } from '~/app/shared/interfaces/shared/skill.interface';

export const loadProfileSkills = createAction(
  '[Profile Skills] Load Profile Skills',
  props<{ profileId: string }>()
);
export const loadProfileSkillsSuccess = createAction(
  '[Profile Skills] Load Profile Skills Success',
  props<{ skills: Skill[] }>()
);
export const loadProfileSkillsFailure = createAction(
  '[Profile Skills] Load Profile Skills Failure',
  props<{ error: string }>()
);

export const assignProfileSkill = createAction(
  '[Profile Skills] Assign Profile Skill',
  props<{ uuid: string; skillData: AssignSkill }>()
);
export const assignProfileSkillSuccess = createAction(
  '[Profile Skills] Assign Profile Skill Success',
  props<{ skills: Skill[] }>()
);
export const assignProfileSkillFailure = createAction(
  '[Profile Skills] Assign Profile Skill Failure',
  props<{ error: string }>()
);

export const unassignProfileSkill = createAction(
  '[Profile Skills] Unassign Profile Skill',
  props<{ profileId: string; skillId: string[] | string }>()
);
export const unassignProfileSkillSuccess = createAction(
  '[Profile Skills] Unassign Profile Skill Success',
  props<{ skills: Skill[] }>()
);
export const unassignProfileSkillFailure = createAction(
  '[Profile Skills] Unassign Profile Skill Failure',
  props<{ error: string }>()
);

export const profileSkillsUnauthorized = createAction(
  '[Profile Skills] Unauthorized',
  props<{ error: string }>()
);
