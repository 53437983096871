<div class="flex flex-column w-full px-4 pb-4 pt-0">
  <h4 class="display-h4 m-0 color-dark-100">Version 1.1.32</h4>
  <h5 class="m-0"></h5>
  <p class="text-lg color-dark-100">
    See what's new in <span class="gradiant text-bold-base">IceBerry</span>✨
  </p>
  <h6 class="display-h6 m-0 color-dark-100">🪲 Bug fix :</h6>
  <ul>
    <li *ngFor="let elm of bugFix">{{ elm }}</li>
  </ul>

  <h6 class="display-h6 m-0 color-dark-100">🚀 Upgrade :</h6>
  <ul>
    <li *ngFor="let elm of upgrades">{{ elm }}</li>
  </ul>

  <h6 class="display-h6 m-0 color-dark-100"></h6>
  <ul>
    <li *ngFor="let elm of features">{{ elm }}</li>
  </ul>
</div>
