import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { usersReducer } from '~/app/states/main/settings/states/users/users.reducer';
import { companyReducer } from '~/app/states/main/settings/states/company/company.reducer';
import { officesReducer } from '~/app/states/main/settings/states/offices/offices.reducer';
import { quickActionReducer } from '~/app/shared/states/quick-actions/quick-actions.reducer';
import { homeModulesReducer } from '~/app/states/main/states/home/home-modules.reducer';
import { sectorCategoryReducer } from '~/app/states/main/states/sectors/sectors.reducer';
import { UserSettingsEffects } from '~/app/states/main/settings/states/users/users.effects';
import { CompanyEffects } from '~/app/states/main/settings/states/company/company.effects';
import { OfficesEffects } from '~/app/states/main/settings/states/offices/offices.effects';
import { HomeModulesEffects } from '~/app/states/main/states/home/home-modules.effects';
import { SectorsEffects } from '~/app/states/main/states/sectors/sectors.effects';
import { profileLanguagesReducer } from '~/app/states/main/crm/profile/states/profile-languages/profile-languages.reducer';
import { socialNetworkReducer } from '~/app/states/main/crm/profile/states/profile-social-networks/profile-social-networks.reducer';
import { allSearchCandidatesReducer } from '~/app/states/main/crm/search-candidate/state/search-candidate.reducer';
import { aiConsultingReducer } from '~/app/states/main/crm/ai-search/states/consulting/consulting.reducer';
import { aiClientReducer } from '~/app/states/main/crm/ai-search/states/client/client.reducer';
import { aiCreationProfileReducer } from '~/app/states/main/crm/ai-creation/states/profile/ai-creation-profile.reducer';
import { RulesEffects } from '~/app/states/main/settings/states/rules/rules.effects';
import { ProfileLanguagesEffects } from '~/app/states/main/crm/profile/states/profile-languages/profile-languages.effects';
import { ProfileSocialNetworksEffects } from '~/app/states/main/crm/profile/states/profile-social-networks/profile-social-networks.effects';
import { SectorJobsEffects } from '~/app/states/main/crm/profile/states/profile-sectors/profile-sectors.effects';
import { SearchCandidateEffects } from '~/app/states/main/crm/search-candidate/state/search-candidate.effects';
import { AiConsultingEffects } from '~/app/states/main/crm/ai-search/states/consulting/consulting.effects';
import { AiClientEffects } from '~/app/states/main/crm/ai-search/states/client/client.effects';
import { AiCreationProfileEffects } from '~/app/states/main/crm/ai-creation/states/profile/ai-creation-profile.effects';
import { ProfileQuickNavEffects } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.effects';
import { salesStatusReducer } from '~/app/states/main/settings/states/status/sales-status/sales-status.reducer';
import { allSearchSalesReducer } from '~/app/states/main/sales/components/search-sales/states/search-sales.reducer';
import { timelinesReducer } from '~/app/states/main/sales/states/timelines/timelines.reducer';
import { allProjectsReducer } from '~/app/states/main/sales/states/search-projects/search-projects.reducer';
import { projectReducer } from '~/app/states/main/sales/states/project/project.reducer';
import { SearchSaleEffects } from '~/app/states/main/sales/components/search-sales/states/search-sales.effects';
import { TimelinesEffects } from '~/app/states/main/sales/states/timelines/timelines.effects';
import { AllProjectsEffects } from '~/app/states/main/sales/states/search-projects/search-projects.effects';
import { ProjectEffects } from '~/app/states/main/sales/states/project/project.effects';
import { allKanbansReducer } from '~/app/states/main/kanban/states/kanbans/kanbans.reducer';
import { kanbanColumnsReducer } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.reducer';
import { kanbanCardsReducer } from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.reducer';
import { kanbanCandidateReducer } from '~/app/states/main/kanban/states/candidates/kanban-candidate.reducer';
import { kanbanTagsReducer } from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.reducer';
import { kanbanUsersReducer } from '~/app/states/main/kanban/states/users/users.reducer';
import { kanbanCardAttachmentsReducer } from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.reducer';
import { kanbanCardCommentsReducer } from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.reducer';
import { kanbanCardTasksReducer } from '~/app/states/main/kanban/states/kanban-card-tasks/kanban-card-tasks.reducer';
import { AllKanbansEffects } from '~/app/states/main/kanban/states/kanbans/kanbans.effects';
import { KanbanColumnsEffects } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.effects';
import { KanbanTagsEffects } from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.effects';
import { KanbanCardsEffects } from '~/app/states/main/kanban/states/kanban-cards/kanban-cards.effects';
import { KanbanCardCommentsEffects } from '~/app/states/main/kanban/states/kanban-card-comments/kanban-card-comments.effects';
import { KanbanCardTasksEffects } from '~/app/states/main/kanban/states/kanban-card-tasks/kanban-card-tasks.effects';
import { KanbanUsersEffects } from '~/app/states/main/kanban/states/users/users.effects';
import { KanbanCardUsersEffects } from '~/app/states/main/kanban/states/kanban-card-users/kanban-card-users.effects';
import { KanbanCardAttachmentsEffects } from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.effects';
import { KanbanCandidateEffects } from '~/app/states/main/kanban/states/candidates/kanban-candidate.effects';
import { allSearchClientCompanyReducer } from '~/app/states/main/client-company/states/search-client-company/search-client-company.reducer';
import { AllSearchClientCompanyEffects } from '~/app/states/main/client-company/states/search-client-company/search-client-company.effects';
import { offersReducer } from '~/app/states/main/jobhub/states/offers/offers.reducer';
import { allCompanyOffersReducer } from '~/app/states/main/jobhub/states/company-offer/company-offers.reducer';
import { offerProposalReducer } from '~/app/states/main/jobhub/states/offer-proposal/offer-proposal.reducer';
import { proposalHistoryReducer } from '~/app/states/main/jobhub/states/proposal-history/proposal-history.reducer';
import { allSearchOfferProposalsReducer } from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.reducer';
import { profileFileReducer } from '~/app/states/main/jobhub/states/profile-file/profile-file.reducer';
import { applyOfferReducer } from '~/app/states/main/jobhub/states/assign-offer/apply-offer.reducer';
import { OffersEffects } from '~/app/states/main/jobhub/states/offers/offers.effects';
import { CompanyOffersEffects } from '~/app/states/main/jobhub/states/company-offer/company-offers.effects';
import { OfferProposalEffects } from '~/app/states/main/jobhub/states/offer-proposal/offer-proposal.effects';
import { ProposalHistoryEffects } from '~/app/states/main/jobhub/states/proposal-history/proposal-history.effects';
import { SearchOfferProposalEffects } from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.effects';
import { ProfileFileEffects } from '~/app/states/main/jobhub/states/profile-file/profile-file.effects';
import { ApplyOfferEffects } from '~/app/states/main/jobhub/states/assign-offer/apply-offer.effects';
import { ProfileSkillsEffects } from '~/app/states/main/crm/profile/states/profile-skills/profile-skills.effects';
import { clientCompanyReducer } from '~/app/states/main/client-company/profile/states/client-company/client-company.reducer';
import { clientCompanyInterviewsReducer } from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.reducer';
import { clientCompanyConsultantsReducer } from '~/app/states/main/client-company/profile/states/client-company-consultants/client-company-consultants.reducer';
import { clientCompanySalesReducer } from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.reducer';
import { clientCompanyNotesReducer } from '~/app/states/main/client-company/profile/states/client-company-notes/client-company-notes.reducer';
import { clientCompanySectorJobsReducer } from '~/app/states/main/client-company/profile/states/client-company-sector-jobs/client-company-sector-jobs.reducer';
import { ClientCompanyEffects } from '~/app/states/main/client-company/profile/states/client-company/client-company.effects';
import { ClientCompanyInterviewsEffects } from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.effects';
import { ClientCompanyConsultantsEffects } from '~/app/states/main/client-company/profile/states/client-company-consultants/client-company-consultants.effects';
import { ClientCompanySalesEffects } from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.effects';
import { ClientCompanyNotesEffects } from '~/app/states/main/client-company/profile/states/client-company-notes/client-company-notes.effects';
import { ClientCompanySectorJobsEffects } from '~/app/states/main/client-company/profile/states/client-company-sector-jobs/client-company-sector-jobs.effects';
import { homeProfileRemindersReducer } from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.reducer';
import { homeTeamsReducer } from '~/app/states/main/crm/home/states/home-teams/home-teams.reducer';
import { HomeTeamsEffects } from '~/app/states/main/crm/home/states/home-teams/home-teams.effects';
import { profileReducer } from '~/app/states/main/crm/profile/profile-layout/state/profile.reducer';
import { profileNotesReducer } from '~/app/states/main/crm/profile/states/profile-notes/profile-notes.reducer';
import { profileRemindersReducer } from '~/app/states/main/crm/profile/states/profile-reminders/profile-reminders.reducer';
import { profileSkillsReducer } from '~/app/states/main/crm/profile/states/profile-skills/profile-skills.reducer';
import { profileTagsReducer } from '~/app/states/main/crm/profile/states/profile-tags/profile-tags.reducer';
import { profileSectorJobReducer } from '~/app/states/main/crm/profile/states/profile-sectors/profile-sectors.reducer';
import { profileIndustryReducer } from '~/app/states/main/crm/profile/states/profile-industries/profile-industries.reducer';
import { profileFilesReducer } from '~/app/states/main/crm/profile/states/profile-files/profile-files.reducer';
import { profileReferencesReducer } from '~/app/states/main/crm/profile/states/profile-references/profile-references.reducer';
import { profileQuickNavReducer } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.reducer';
import { profileClientCompagniesReducer } from '~/app/states/main/crm/profile/states/client-companies/profile-client-compagnies.reducer';
import { ProfileEffects } from '~/app/states/main/crm/profile/profile-layout/state/profile.effects';
import { ProfileNotesEffects } from '~/app/states/main/crm/profile/states/profile-notes/profile-notes.effects';
import { ProfileRemindersEffects } from '~/app/states/main/crm/profile/states/profile-reminders/profile-reminders.effects';
import { ProfileTagsEffects } from '~/app/states/main/crm/profile/states/profile-tags/profile-tags.effects';
import { ProfileIndustryEffects } from '~/app/states/main/crm/profile/states/profile-industries/profile-industries.effects';
import { ProfileFilesEffects } from '~/app/states/main/crm/profile/states/profile-files/profile-files.effects';
import { ProfileReferencesEffects } from '~/app/states/main/crm/profile/states/profile-references/profile-references.effects';
import { ProfileClientCompagniesEffects } from '~/app/states/main/crm/profile/states/client-companies/profile-client-compagnies.effects';
import { saleReducer } from '~/app/states/main/sales/profile/states/sales/sales.reducer';
import { salesSectorJobReducer } from '~/app/states/main/sales/profile/states/sales-sectors-jobs/sales-sector-jobs.reducer';
import { salesIndustryReducer } from '~/app/states/main/sales/profile/states/sales-industries/sales-industries.reducer';
import { salesRemindersReducer } from '~/app/states/main/sales/profile/states/sales-reminders/sales-reminders.reducer';
import { salesLanguagesReducer } from '~/app/states/main/sales/profile/states/sales-languages/sales-languages.reducer';
import { salesNotesReducer } from '~/app/states/main/sales/profile/states/sales-notes/sales-notes.reducer';
import { salesInformationReducer } from '~/app/states/main/sales/profile/states/sales-informations/sales-informations.reducer';
import { salesQuickNavReducer } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.reducer';
import { socialReducer } from '~/app/states/main/sales/profile/states/sales-socials/sales-socials.reducer';
import { salesProjectsReducer } from '~/app/states/main/sales/profile/states/sales-projects/sales-projects.reducer';
import { SalesEffects } from '~/app/states/main/sales/profile/states/sales/sales.effects';
import { SalesSectorJobsEffects } from '~/app/states/main/sales/profile/states/sales-sectors-jobs/sales-sector-jobs.effects';
import { SalesIndustryEffects } from '~/app/states/main/sales/profile/states/sales-industries/sales-industries.effects';
import { SalesRemindersEffects } from '~/app/states/main/sales/profile/states/sales-reminders/sales-reminders.effects';
import { SalesLanguagesEffects } from '~/app/states/main/sales/profile/states/sales-languages/sales-languages.effects';
import { SalesNotesEffects } from '~/app/states/main/sales/profile/states/sales-notes/sales-notes.effects';
import { SalesInformationEffects } from '~/app/states/main/sales/profile/states/sales-informations/sales-informations.effects';
import { SalesQuickNavEffects } from '~/app/states/main/sales/states/sales-quick-nav/sales-quick-nav.effects';
import { SalesSocialEffects } from '~/app/states/main/sales/profile/states/sales-socials/sales-socials.effects';
import { SalesProjectsEffects } from '~/app/states/main/sales/profile/states/sales-projects/sales-projects.effects';
import { allTeamsReducer } from '~/app/states/main/settings/states/teams/teams.reducer';
import { AllTeamsEffects } from '~/app/states/main/settings/states/teams/teams.effects';
import { homeSalesRemindersReducer } from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.reducer';
import { homeUsersReducer } from '~/app/states/main/sales/home/states/home-users/home-users.reducer';
import { HomeSalesRemindersEffects } from '~/app/states/main/sales/home/states/home-sales-reminders/home-sales-reminders.effects';
import { HomeUsersEffects } from '~/app/states/main/sales/home/states/home-users/home-users.effects';
import { goalsReducer } from '~/app/states/main/stats/states/goals/goals.reducer';
import { GoalsEffects } from '~/app/states/main/stats/states/goals/goals.effects';
import { allShuffleListReducer } from '~/app/states/main/shuffle-list/states/shuffle-list/shuffle-list.reducer';
import { AllShuffleListEffects } from '~/app/states/main/shuffle-list/states/shuffle-list/shuffle-list.effects';
import { industriesReducer } from '~/app/states/main/settings/states/industries/industries.reducer';
import { noteCategoriesReducer } from '~/app/states/main/settings/states/notes/notes.reducer';
import { candidateStatusReducer } from '~/app/states/main/settings/states/status/candidate-status/candidate-status.reducer';
import { candidateTagsReducer } from '~/app/states/main/settings/states/tags/candidate-tags-state/candidate-tags.reducer';
import { salesTagsReducer } from '~/app/states/main/settings/states/tags/sales-tags-state/sales-tags.reducer';
import { clientCompanyStatusReducer } from '~/app/states/main/settings/states/status/client-company-status/client-company-status.reducer';
import { accessReducer } from '~/app/states/main/settings/states/access/access.reducer';
import { rulesReducer } from '~/app/states/main/settings/states/rules/rules.reducer';
import { IndustriesEffects } from '~/app/states/main/settings/states/industries/industries.effects';
import { NotesEffects } from '~/app/states/main/settings/states/notes/notes.effects';
import { CandidateStatusEffects } from '~/app/states/main/settings/states/status/candidate-status/candidate-status.effects';
import { SalesStatusEffects } from '~/app/states/main/settings/states/status/sales-status/sales-status.effects';
import { CandidateTagsEffects } from '~/app/states/main/settings/states/tags/candidate-tags-state/candidate-tags.effects';
import { SalesTagsEffects } from '~/app/states/main/settings/states/tags/sales-tags-state/sales-tags.effects';
import { ClientCompanyStatusEffects } from '~/app/states/main/settings/states/status/client-company-status/client-company-status.effects';
import { AccessEffects } from '~/app/states/main/settings/states/access/access.effects';
import { HomeProfileRemindersEffects } from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.effect';
import { ProfileInterviewsEffects } from '~/app/states/main/crm/profile/states/profile-interviews/profile-interviews.effects';
import { profileInterviewsReducer } from '~/app/states/main/crm/profile/states/profile-interviews/profile-interviews.reducer';
import { NotificationEffects } from '~/app/states/main/states/notification/notification.effects';
import { notificationReducer } from '~/app/states/main/states/notification/notification.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    // TODO: Search for doubles and only keep those in the right store
    // Main Store
    StoreModule.forFeature('main', {
      users: usersReducer,
      company: companyReducer,
      offices: officesReducer,
      quickActions: quickActionReducer,
      homeModules: homeModulesReducer,
      sectorCategory: sectorCategoryReducer,
    }),
    EffectsModule.forFeature([
      UserSettingsEffects,
      CompanyEffects,
      OfficesEffects,
      HomeModulesEffects,
      SectorsEffects,
    ]),

    // Notification Store
    StoreModule.forFeature('notifications', {
      allNotifications: notificationReducer,
    }),

    EffectsModule.forFeature([NotificationEffects]),

    // CRM Store
    StoreModule.forFeature('crm', {
      profileLanguages: profileLanguagesReducer,
      socialNetworks: socialNetworkReducer,
      allSearchCandidate: allSearchCandidatesReducer,
      aiConsulting: aiConsultingReducer,
      aiClient: aiClientReducer,
      aiCreationProfile: aiCreationProfileReducer,
    }),
    EffectsModule.forFeature([
      RulesEffects,
      ProfileLanguagesEffects,
      ProfileSocialNetworksEffects,
      SectorJobsEffects,
      SearchCandidateEffects,
      AiConsultingEffects,
      AiClientEffects,
      AiCreationProfileEffects,
      ProfileQuickNavEffects,
    ]),

    // CRM Home Store
    StoreModule.forFeature('crm-home', {
      profileReminders: homeProfileRemindersReducer,
      teams: homeTeamsReducer,
    }),
    EffectsModule.forFeature([HomeProfileRemindersEffects, HomeTeamsEffects]),

    // Profile Store
    StoreModule.forFeature('profile', {
      profile: profileReducer,
      note: profileNotesReducer,
      reminder: profileRemindersReducer,
      skills: profileSkillsReducer,
      tags: profileTagsReducer,
      interview: profileInterviewsReducer,
      sectorJobs: profileSectorJobReducer,
      industries: profileIndustryReducer,
      files: profileFilesReducer,
      references: profileReferencesReducer,
      quickNav: profileQuickNavReducer,
      clientCompanies: profileClientCompagniesReducer,
    }),
    EffectsModule.forFeature([
      ProfileEffects,
      ProfileNotesEffects,
      ProfileRemindersEffects,
      ProfileSkillsEffects,
      ProfileTagsEffects,
      ProfileInterviewsEffects,
      SectorJobsEffects,
      ProfileIndustryEffects,
      ProfileFilesEffects,
      ProfileReferencesEffects,
      ProfileClientCompagniesEffects,
    ]),

    // Sales Store
    StoreModule.forFeature('sales', {
      salesStatus: salesStatusReducer,
      allSearchSales: allSearchSalesReducer,
      timelines: timelinesReducer,
      allProjects: allProjectsReducer,
      project: projectReducer,
    }),
    EffectsModule.forFeature([
      SearchSaleEffects,
      TimelinesEffects,
      AllProjectsEffects,
      ProjectEffects,
    ]),

    // Sales Home Store
    StoreModule.forFeature('sales-home', {
      salesReminders: homeSalesRemindersReducer,
      users: homeUsersReducer,
    }),
    EffectsModule.forFeature([HomeSalesRemindersEffects, HomeUsersEffects]),

    // Sales Profile Store
    StoreModule.forFeature('salesProfile', {
      sales: saleReducer,
      sectorJobs: salesSectorJobReducer,
      industries: salesIndustryReducer,
      reminders: salesRemindersReducer,
      languages: salesLanguagesReducer,
      notes: salesNotesReducer,
      information: salesInformationReducer,
      quickNav: salesQuickNavReducer,
      allSearchSales: allSearchSalesReducer,
      socials: socialReducer,
      projects: salesProjectsReducer,
    }),
    EffectsModule.forFeature([
      SalesEffects,
      SalesSectorJobsEffects,
      SalesIndustryEffects,
      SalesRemindersEffects,
      SalesLanguagesEffects,
      SalesNotesEffects,
      SalesInformationEffects,
      SalesQuickNavEffects,
      SalesSocialEffects,
      SalesProjectsEffects,
    ]),

    // JobHub Store
    StoreModule.forFeature('jobhub', {
      offers: offersReducer,
      allCompanyOffers: allCompanyOffersReducer,
      offerProposalState: offerProposalReducer,
      proposalHistoryState: proposalHistoryReducer,
      allSearchOfferProposal: allSearchOfferProposalsReducer,
      profileFile: profileFileReducer,
      assignOffer: applyOfferReducer,
    }),
    EffectsModule.forFeature([
      OffersEffects,
      CompanyOffersEffects,
      OfferProposalEffects,
      ProposalHistoryEffects,
      SearchOfferProposalEffects,
      ProfileFileEffects,
      ApplyOfferEffects,
      ProfileSkillsEffects,
    ]),

    // Kanban Store
    StoreModule.forFeature('kanban', {
      allKanbans: allKanbansReducer,
      kanbanColumns: kanbanColumnsReducer,
      kanbanCards: kanbanCardsReducer,
      candidates: kanbanCandidateReducer,
      kanbanTags: kanbanTagsReducer,
      users: kanbanUsersReducer,
      kanbanAttachments: kanbanCardAttachmentsReducer,
      comments: kanbanCardCommentsReducer,
      kanbanTasks: kanbanCardTasksReducer,
    }),
    EffectsModule.forFeature([
      AllKanbansEffects,
      KanbanColumnsEffects,
      KanbanTagsEffects,
      KanbanCardsEffects,
      KanbanCardCommentsEffects,
      KanbanCardTasksEffects,
      KanbanUsersEffects,
      KanbanCardUsersEffects,
      KanbanCardAttachmentsEffects,
      KanbanCandidateEffects,
    ]),

    // Client Companies Store
    StoreModule.forFeature('clientCompany', {
      allSearchClientCompanies: allSearchClientCompanyReducer,
    }),
    EffectsModule.forFeature([AllSearchClientCompanyEffects]),

    // Client Companies Profile Store
    StoreModule.forFeature('clientCompanyProfile', {
      clientCompany: clientCompanyReducer,
      interviews: clientCompanyInterviewsReducer,
      consultants: clientCompanyConsultantsReducer,
      sales: clientCompanySalesReducer,
      notes: clientCompanyNotesReducer,
      sectorJobs: clientCompanySectorJobsReducer,
    }),
    EffectsModule.forFeature([
      ClientCompanyEffects,
      ClientCompanyInterviewsEffects,
      ClientCompanyConsultantsEffects,
      ClientCompanySalesEffects,
      ClientCompanyNotesEffects,
      ClientCompanySectorJobsEffects,
    ]),

    // Team Store
    StoreModule.forFeature('team', {
      allTeams: allTeamsReducer,
    }),
    EffectsModule.forFeature([AllTeamsEffects]),

    // Stats Store
    StoreModule.forFeature('stats', {
      goals: goalsReducer,
    }),
    EffectsModule.forFeature([GoalsEffects]),

    // Shuffle Lists Store
    StoreModule.forFeature('shuffleList', {
      allShuffleList: allShuffleListReducer,
      allTeams: allTeamsReducer,
    }),
    EffectsModule.forFeature([AllShuffleListEffects, AllTeamsEffects]),

    // Settings Store
    StoreModule.forFeature('settings', {
      industry: industriesReducer,
      noteCategories: noteCategoriesReducer,
      candidateStatus: candidateStatusReducer,
      candidateTags: candidateTagsReducer,
      salesStatus: salesStatusReducer,
      salesTags: salesTagsReducer,
      clientCompanyStatus: clientCompanyStatusReducer,
      company: companyReducer,
      offices: officesReducer,
      access: accessReducer,
      rules: rulesReducer,
      allTeams: allTeamsReducer,
    }),
    EffectsModule.forFeature([
      IndustriesEffects,
      NotesEffects,
      CandidateStatusEffects,
      SalesStatusEffects,
      CandidateTagsEffects,
      SalesTagsEffects,
      ClientCompanyStatusEffects,
      CompanyEffects,
      OfficesEffects,
      AccessEffects,
      RulesEffects,
      AllTeamsEffects,
    ]),
  ],
})
export class StatesModule {}
