import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TypedAction } from '@ngrx/store/src/models';
import { Router } from '@angular/router';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';
import * as AuthActions from '~/app/auth/auth.actions';
import { selectLastRequest } from '~/app/auth/state/login/login.selectors';
import { environment } from '~/environments/environment';

/**
 * Intercepts HTTP requests to handle authentication errors globally across the application.
 * If a 401 (Unauthorized) or 403 (Forbidden) error is received and the user is currently authenticated,
 * it will automatically trigger a logout or other appropriate error handling.
 *
 * @Injectable indicates that this class can be injected, though it doesn't specify 'providedIn',
 * meaning it needs to be explicitly provided in the module or component.
 */
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  /**
   * Observable watching the last executed request in order to resend it if it's needed
   * @type {Observable<TypedAction<any> | null>}
   */
  lastRequest$: Observable<TypedAction<any> | null>;

  /**
   * Constructs a new instance of AuthErrorInterceptor.
   * @param {Store<AppState>} store - The NgRx store used to access the authentication state.
   * @param {Router} router - The Angular router to navigate through the app.
   */
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.lastRequest$ = this.store.select(selectLastRequest);
  }

  /**
   * Method that intercepts all HTTP requests and applies error handling logic if a response error occurs.
   * It monitors for 401 and 403 HTTP status codes to manage session or permission errors.
   *
   * @param {HttpRequest<any>} request - The outgoing request object to handle.
   * @param {HttpHandler} next - The next interceptor in the chain, or the final handler that will send the request.
   * @returns {Observable<HttpEvent<any>>} An observable of the event stream for the request.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthState).pipe(
      take(1),
      switchMap(authState => {
        return next.handle(request).pipe(
          catchError(err => {
            if (
              [401, 403].includes(err.status) &&
              authState.userData !== null
            ) {
              if (err.url !== environment.apiUrl + '/v1/auth/refreshToken')
                this.store.dispatch(AuthActions.refreshToken());
              else {
                void this.router.navigate(['/login']);
              }
            }

            const error =
              (err && err.error && err.error.message) || err.statusText;
            return throwError(() => error);
          })
        );
      })
    );
  }
}
