import { createAction, props } from '@ngrx/store';
import {
  CreateShuffleList,
  UpdateShuffleList,
} from '~/app/states/main/shuffle-list/types/shuffle-list.types';
import { AllShuffleListFilters } from '~/app/shared/interfaces/shuffle-list/all-shuffle-list-filters.interface';
import { RandomShuffleList } from '~/app/shared/interfaces/shuffle-list/random-shuffle-list.interface';
import { ShuffleList } from '~/app/shared/interfaces/shuffle-list/shuffle-list.interface';

export const loadAllShuffleList = createAction(
  '[Shuffle List] Load All Shuffle List',
  props<{ filters?: AllShuffleListFilters }>()
);
export const loadAllShuffleListSuccess = createAction(
  '[Shuffle List] Load All Shuffle List Success',
  props<{ shuffleLists: ShuffleList[] }>()
);
export const loadAllShuffleListFailure = createAction(
  '[Shuffle List] Load All Shuffle List Failure',
  props<{ error: string }>()
);

export const createShuffleList = createAction(
  '[Shuffle List] Create Shuffle List',
  props<{ shuffleListData: CreateShuffleList }>()
);
export const createShuffleListSuccess = createAction(
  '[Shuffle List] Create Shuffle List Success',
  props<{ shuffleList: ShuffleList }>()
);
export const createShuffleListFailure = createAction(
  '[Shuffle List] Create Shuffle List Failure',
  props<{ error: string }>()
);

export const updateShuffleList = createAction(
  '[Shuffle List] Update Shuffle List',
  props<{ uuid: string; shuffleListData: UpdateShuffleList }>()
);
export const updateShuffleListSuccess = createAction(
  '[Shuffle List] Update Shuffle List Success',
  props<{ shuffleList: ShuffleList }>()
);
export const updateShuffleListFailure = createAction(
  '[Shuffle List] Update Shuffle List Failure',
  props<{ error: string }>()
);

export const deleteShuffleList = createAction(
  '[Shuffle List] Delete Shuffle List',
  props<{ uuid: string }>()
);
export const deleteShuffleListSuccess = createAction(
  '[Shuffle List] Delete Shuffle List Success',
  props<{ uuid: string }>()
);
export const deleteShuffleListFailure = createAction(
  '[Shuffle List] Delete Shuffle List Failure',
  props<{ error: string }>()
);

export const assignTeam = createAction(
  '[Shuffle List] Assign Team',
  props<{ uuid: string; teamId: string }>()
);
export const assignTeamSuccess = createAction(
  '[Shuffle List] Assign Team Success',
  props<{ shuffleList: ShuffleList }>()
);
export const assignTeamFailure = createAction(
  '[Shuffle List] Assign Team Failure',
  props<{ error: string }>()
);

export const unassignTeam = createAction(
  '[Shuffle List] Unassign Team',
  props<{ uuid: string; teamId: string }>()
);
export const unassignTeamSuccess = createAction(
  '[Shuffle List] Unassign Team Success',
  props<{ shuffleList: ShuffleList }>()
);
export const unassignTeamFailure = createAction(
  '[Shuffle List] Unassign Team Failure',
  props<{ error: string }>()
);

export const getShuffleListById = createAction(
  '[Shuffle List] Get shuffle list by Id',
  props<{ uuid: string }>()
);
export const getShuffleListByIdSuccess = createAction(
  '[Shuffle List] Get shuffle list by Id Success',
  props<{ shuffleList: ShuffleList }>()
);
export const getShuffleListByIdFailure = createAction(
  '[Shuffle List] Get shuffle list by Id Failure',
  props<{ error: string }>()
);

export const getRandom = createAction(
  '[Shuffle List] Get random Team',
  props<{ uuid: string }>()
);
export const getRandomSuccess = createAction(
  '[Shuffle List] Get random Team Success',
  props<{ randomShuffleList: RandomShuffleList }>()
);
export const getRandomFailure = createAction(
  '[Shuffle List] Get random Team Failure',
  props<{ error: string }>()
);

export const shuffleListUnauthorized = createAction(
  '[Shuffle List] Unauthorized',
  props<{ error: string }>()
);
