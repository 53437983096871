import { createSelector } from '@ngrx/store';
import { selectKanbanState } from '~/app/states/main/kanban/kanban.selectors';

export const selectKanbanColumnsState = createSelector(
  selectKanbanState,
  state => state.kanbanColumns
);

export const selectKanbanColumns = createSelector(
  selectKanbanColumnsState,
  state => state.data
);

export const selectKanbanColumnsHasNext = createSelector(
  selectKanbanColumnsState,
  state => state.hasNext
);

export const selectColumnsLoadingState = createSelector(
  selectKanbanState,
  state => state.kanbanColumns.loading
);
