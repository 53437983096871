import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EnumsActions from '~/app/core/state/enum/enums.actions';
import { MainState } from '~/app/main/main.state';

/**
 * A service that provides methods to dispatch actions for loading different types of enumerations (enums)
 * from the application state. These enums are typically used for dropdowns, filters, and other form controls
 * throughout the application. The service simplifies the process of initiating these loads by providing
 * specific methods for each type of enum.
 *
 * @Injectable Marks the class as one that participates in the dependency injection system.
 * @providedIn 'root' - Indicates that this service should be created by the root application injector.
 */
@Injectable({
  providedIn: 'root',
})
export class EnumService {
  /**
   * Creates an instance of EnumService.
   * @param {Store<MainState>} store - The NgRx store that allows interaction with the app state.
   */
  constructor(private store: Store<MainState>) {}

  /**
   * Dispatches an action to load CEFR levels for language proficiency.
   * @return {void}
   */
  loadCecrlTypes(): void {
    this.store.dispatch(EnumsActions.loadCecrls());
  }

  /**
   * Dispatches an action to load offer status types.
   * @return {void}
   */
  loadOfferStatusTypes(): void {
    this.store.dispatch(EnumsActions.loadOfferStatuses());
  }

  /**
   * Dispatches an action to load offer unit types.
   * @return {void}
   */
  loadOfferUnitTypes(): void {
    this.store.dispatch(EnumsActions.loadOfferUnits());
  }

  /**
   * Dispatches an action to load offer visibility types.
   * @return {void}
   */
  loadOfferVisibilityTypes(): void {
    this.store.dispatch(EnumsActions.loadOfferVisibilities());
  }

  /**
   * Dispatches an action to load offer document types.
   * @return {void}
   */
  loadOfferDocumentTypes(): void {
    this.store.dispatch(EnumsActions.loadOfferDocumentTypes());
  }

  /**
   * Dispatches an action to load profile experience types.
   * @return {void}
   */
  loadProfileExperienceTypes(): void {
    this.store.dispatch(EnumsActions.loadProfileExperiences());
  }

  /**
   * Dispatches an action to load profile file types.
   * @return {void}
   */
  loadProfileFileTypes(): void {
    this.store.dispatch(EnumsActions.loadProfileFiles());
  }

  /**
   * Dispatches an action to load time units for profiles.
   * @return {void}
   */
  loadProfileTimeUnits(): void {
    this.store.dispatch(EnumsActions.loadProfileTimeUnits());
  }

  /**
   * Dispatches an action to load different types of profiles.
   * @return {void}
   */
  loadProfileTypes(): void {
    this.store.dispatch(EnumsActions.loadProfileTypes());
  }

  /**
   * Dispatches an action to load proposal status types.
   * @return {void}
   */
  loadProposalStatusTypes(): void {
    this.store.dispatch(EnumsActions.loadProposalStatuses());
  }

  /**
   * Dispatches an action to load reminder types.
   * @return {void}
   */
  loadReminderTypes(): void {
    this.store.dispatch(EnumsActions.loadReminders());
  }

  /**
   * Dispatches an action to load sale information types.
   * @return {void}
   */
  loadSaleInformationTypes(): void {
    this.store.dispatch(EnumsActions.loadSaleInformations());
  }

  /**
   * Dispatches an action to load social types.
   * @return {void}
   */
  loadSocialTypes(): void {
    this.store.dispatch(EnumsActions.loadSocials());
  }

  /**
   * Dispatches an action to load gender types.
   * @return {void}
   */
  loadGenderTypes(): void {
    this.store.dispatch(EnumsActions.loadGenders());
  }

  /**
   * Dispatches an action to load skill experience types.
   * @return {void}
   */
  loadSkillExperiencesTypes(): void {
    this.store.dispatch(EnumsActions.loadSkillExperience());
  }
}
