<div *ngIf="label && unit" class="text-xs color-dark-100">
  {{ label }}<span class="color-dark-70"> /{{ unit }}</span>
</div>

<div style="height: 100px" class="w-full pt-4">
  <div class="inline-block px-1" style="height: 20%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 30%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 100%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 68%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 44%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 23%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 27%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 20%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 5%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 12%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 5%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 0%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 5%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
  <div class="inline-block px-1" style="height: 7%; width: 5%">
    <div class="border-round h-full w-full bg-primary-70"></div>
  </div>
</div>

<div class="slider-container mt-5">
  <input
    id="fromSlider"
    type="range"
    [value]="value[0]"
    [defaultValue]="min"
    (input)="patchValue($event)"
    [min]="min"
    [max]="max | async" />
  <input
    #slider
    id="toSlider"
    type="range"
    [value]="value[1]"
    (input)="patchValue($event)"
    [defaultValue]="max"
    [min]="min"
    [max]="max | async" />

  <div #fromTag id="fromTag" class="absolute bottom-100">{{ value[0] }}</div>
  <div #toTag id="toTag" class="absolute top-50">{{ value[1] }}</div>
</div>
