import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Rate } from '@npmicedev/icemodule/lib/enums/rate';
import { FormGroup } from '@angular/forms';
import { GoalScope } from '@npmicedev/icemodule/lib/enums/goalScope';
import {
  createGoalValidation,
  updateGoalByIdValidation,
} from '@npmicedev/icemodule/lib/validations/goals';
import { GoalsStates } from '~/app/main/stats/states/goals/goals.states';
import * as GoalsActions from '~/app/main/stats/states/goals/goals.actions';
import { GoalResponse } from '~/app/main/stats/types/goal-response.types';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * `GoalsService` provides methods to interact with the NgRx store for managing goals,
 * including loading goals, pinning, and unpinning them.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  /**
   * Constructor for `GoalsService`.
   *
   * @param {Store<GoalsStates>} store - The NgRx store for state management related to goals.
   * @param {CustomToastService} toast - The Service for handling the toasts.
   */
  constructor(
    private store: Store<GoalsStates>,
    private toast: CustomToastService
  ) {}

  /**
   * Dispatches an action to load pinned goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadPinnedGoals(limit: number, page: number): void {
    this.store.dispatch(GoalsActions.loadPinnedGoals({ limit, page }));
  }

  /**
   * Dispatches an action to load daily goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadDailyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Daily })
    );
  }

  /**
   * Dispatches an action to load weekly goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadWeeklyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Weekly })
    );
  }

  /**
   * Dispatches an action to load monthly goals with pagination.
   *
   * @param {number} limit - The number of goals to load.
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  loadMonthlyGoals(limit: number, page: number): void {
    this.store.dispatch(
      GoalsActions.loadGoals({ limit, page, rate: Rate.Monthly })
    );
  }

  /**
   * Dispatches an action to load the selected goal.
   *
   * @param {string} goalUUID - The uuid of the goal to load.
   *
   * @return {void}
   */
  loadGoalById(goalUUID: string): void {
    this.store.dispatch(GoalsActions.loadGoalById({ goalUUID }));
  }

  /**
   * Dispatches an action to delete the selected goal.
   *
   * @param {string} goalUUID - The uuid of the goal to load.
   *
   * @return {void}
   */
  deleteGoalById(goalUUID: string): void {
    this.store.dispatch(GoalsActions.deleteGoalById({ goalUUID }));
  }

  /**
   * Dispatches an action to create a goal.
   *
   * @param {FormGroup} formGroup - The formGroup containing all the information to create the goal.*
   * @return {void}
   */
  createGoal(formGroup: FormGroup): void {
    const getControlValue = (controlName: string): any => {
      const control = formGroup.get(controlName);
      const value = control ? control.value : null;
      return value && typeof value === 'object' ? value.value : value;
    };

    const goal = {
      target: getControlValue('target'),
      goalType: getControlValue('goalType'),
      goal: parseInt(getControlValue('goal'), 10),
      rate: getControlValue('rate'),
      scope: getControlValue('scope'),
      userId: getControlValue('userId'),
      teamId: getControlValue('teamId'),
    };

    switch (formGroup.get('scope')?.value) {
      case GoalScope.User:
        delete goal.teamId;
        break;
      case GoalScope.Team:
        delete goal.userId;
        break;
      case GoalScope.Company:
        delete goal.userId;
        delete goal.teamId;
        break;
    }

    const result = createGoalValidation.body.safeParse(goal);

    if (result.success) this.store.dispatch(GoalsActions.createGoal({ goal }));
    else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Dispatches an action to update the selected goal.
   *
   * @param {FormGroup} formGroup - The formGroup containing all the information to update the goal.
   * @param {string} uuid - The uuid of the goal to load.
   *
   * @return {void}
   */
  updateGoalById(formGroup: FormGroup, uuid: string): void {
    const getControlValue = (controlName: string): any => {
      const control = formGroup.get(controlName);
      const value = control ? control.value : null;
      return value && typeof value === 'object' ? value.value : value;
    };

    const goal = {
      uuid,
      target: getControlValue('target'),
      goalType: getControlValue('goalType'),
      goal: parseInt(getControlValue('goal'), 10),
      rate: getControlValue('rate'),
      scope: getControlValue('scope'),
      userId: getControlValue('userId'),
      teamId: getControlValue('teamId'),
    };

    switch (formGroup.get('scope')?.value) {
      case GoalScope.User:
        delete goal.teamId;
        break;
      case GoalScope.Team:
        delete goal.userId;
        break;
      case GoalScope.Company:
        delete goal.userId;
        delete goal.teamId;
        break;
    }

    const result = updateGoalByIdValidation.body.safeParse(goal);

    if (result.success)
      this.store.dispatch(GoalsActions.updateGoalById({ goal }));
    else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Dispatches an action to pin a goal.
   *
   * @param {GoalResponse} goal - The unique identifier of the goal to pin.
   *
   * @return {void}
   */
  pinGoal(goal: GoalResponse): void {
    this.store.dispatch(GoalsActions.pinGoal({ goal }));
  }

  /**
   * Dispatches an action to unpin a goal.
   *
   * @param {GoalResponse} goal - The unique identifier of the goal to unpin.
   *
   * @return {void}
   */
  unpinGoal(goal: GoalResponse): void {
    this.store.dispatch(GoalsActions.unpinGoal({ goal }));
  }

  /**
   * Dispatches an action to load the users.
   *
   * @param {string} name - The string which but be in the name of the users.
   *
   * @return {void}
   */
  loadGoalUsers(name?: string): void {
    this.store.dispatch(GoalsActions.loadGoalUsers({ name }));
  }

  /**
   * Dispatches an action to load the teams.
   *
   * @param {string} name - The string which but be in the name of the teams.
   *
   * @return {void}
   */
  loadGoalTeams(name?: string): void {
    this.store.dispatch(GoalsActions.loadGoalTeams({ name }));
  }
}
