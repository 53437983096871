import { createReducer, on } from '@ngrx/store';
import * as ProposalHistoryActions from '~/app/states/main/jobhub/states/proposal-history/proposal-history.actions';
import { initialProposalHistoryState } from '~/app/states/main/jobhub/states/proposal-history/proposal-history.state';

export const proposalHistoryReducer = createReducer(
  initialProposalHistoryState,

  on(ProposalHistoryActions.loadProposalHistory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProposalHistoryActions.loadProposalHistorySuccess,
    (state, { proposalHistory }) => ({
      ...state,
      ...proposalHistory,
      loading: false,
    })
  ),
  on(ProposalHistoryActions.loadProposalHistoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    ProposalHistoryActions.proposalHistoryUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
