import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanCardAttachmentsActions from '~/app/states/main/kanban/states/kanban-card-attachments/kanban-card-attachments.actions';
import { KanbanAttachment } from '~/app/shared/interfaces/kanban/kanban-attachment.interface';

/**
 * Service responsible for managing Kanban card attachments.
 * Provides methods to load, add, and delete attachments for Kanban cards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanCardAttachmentsService {
  /**
   * Constructs a new KanbanCardAttachmentsService.
   *
   * @param {Store<KanbanState>} store - The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * ************************************************************************
   * LOAD
   * ************************************************************************
   **/

  /**
   * Load all attachments of a card.
   * Dispatches a loadAllCardAttachments action with the card ID.
   * @param {string} kanbanCardId - The ID of the Kanban Card.
   * @returns {void}
   */
  loadAllCardAttachments(kanbanCardId: string): void {
    this.store.dispatch(
      KanbanCardAttachmentsActions.loadAllCardAttachments({ kanbanCardId })
    );
  }

  /**
   * ************************************************************************
   * CUD
   * ************************************************************************
   **/

  /**
   * Adds a new attachment to a specified Kanban card and column.
   * Dispatches a createKanbanCardAttachment action with the column ID and attachment data.
   *
   * @param {File} attachment - The attachment file to be added.
   * @param {string} kanbanCardId - The ID of the Kanban Card.
   * @param {string} kanbanColumnId - The ID of the Kanban column.
   * @returns {void}
   */
  addAttachment(
    attachment: File,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    const kanbanAttachmentData = new FormData();

    kanbanAttachmentData.append('attachment', attachment);
    kanbanAttachmentData.append('filename', attachment.name);
    kanbanAttachmentData.append('kanbanCardId', kanbanCardId);

    this.store.dispatch(
      KanbanCardAttachmentsActions.createKanbanCardAttachment({
        kanbanAttachmentData,
        kanbanColumnId,
        kanbanCardId,
      })
    );
  }

  /**
   * Deletes an attachment from a specified Kanban card and column.
   * Dispatches a deleteKanbanCardAttachment action with the attachment UUID, card ID, and column ID.
   *
   * @param {string} uuid - The UUID of the attachment to be deleted.
   * @param {string} kanbanCardId - The ID of the Kanban card.
   * @param {string} kanbanColumnId - The ID of the Kanban column.
   * @returns {void}
   */
  deleteAttachment(
    uuid: string,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardAttachmentsActions.deleteKanbanCardAttachment({
        uuid,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Adds a new attachment to a specified Kanban card and column.
   * Dispatches a createKanbanCardAttachmentSuccess action with the column ID and attachment data.
   *
   * @param {KanbanAttachment} kanbanAttachment - The kanban attachment to be added.
   * @param {string} kanbanCardId - The ID of the Kanban Card.
   * @param {string} kanbanColumnId - The ID of the Kanban column.
   * @returns {void}
   */
  addAttachmentServer(
    kanbanAttachment: KanbanAttachment,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardAttachmentsActions.createKanbanCardAttachmentServer({
        kanbanAttachment,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }

  /**
   * Updates an attachment by its UUID .
   * Dispatches a updateKanbanCardAttachmentServer action with the kanban attachment.
   *
   * @param {KanbanAttachment} kanbanAttachment - The kanban attachment to be updated.
   * @returns {void}
   */
  upadateAttachmentServer(kanbanAttachment: KanbanAttachment): void {
    this.store.dispatch(
      KanbanCardAttachmentsActions.updateKanbanCardAttachmentServer({
        kanbanAttachment,
      })
    );
  }

  /**
   * Deletes an attachment from a specified Kanban card and column.
   * Dispatches a deleteKanbanCardAttachment action with the attachment UUID, card ID, and column ID.
   *
   * @param {string} uuid - The UUID of the attachment to be deleted.
   * @param {string} kanbanCardId - The ID of the Kanban card.
   * @param {string} kanbanColumnId - The ID of the Kanban column.
   * @returns {void}
   */
  deleteAttachmentServer(
    uuid: string,
    kanbanCardId: string,
    kanbanColumnId: string
  ): void {
    this.store.dispatch(
      KanbanCardAttachmentsActions.deleteKanbanCardAttachmentServer({
        uuid,
        kanbanCardId,
        kanbanColumnId,
      })
    );
  }
}
