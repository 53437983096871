import { createSelector } from '@ngrx/store';
import { selectJobHubState } from '~/app/main/jobhub/jobhub.selector';

export const selectOffersState = createSelector(
  selectJobHubState,
  state => state.offers
);

export const selectOffersLoadingState = createSelector(
  selectOffersState,
  state => state.loading
);
