import { Pipe, PipeTransform } from '@angular/core';
import { OfferDocument } from '~/app/shared/interfaces/jobhub/offer/offer-document.interface';
import { CandidateWithFile } from '~/app/shared/interfaces/pipes/candidate-with-file.interface';

/**
 * A custom Angular pipe that checks if each candidate in a list has all the mandatory files required by a list of offer documents.
 * It uses a function to map document types to the labels used in the candidates' file types, ensuring accurate matching.
 * This pipe is useful for validating completion of document submission requirements in recruitment or similar contexts.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'isAllMandatoryFiles', enabling its use within Angular templates.
 */
@Pipe({
  name: 'isAllMandatoryFiles',
})
export class IsAllMandatoryFilesPipe implements PipeTransform {
  // eslint-disable-next-line valid-jsdoc
  /**
   * Transforms an array of candidates and checks if each one has all the mandatory files as defined by the provided offer documents.
   * The check considers a function that maps document type identifiers to the labels used in profile files.
   *
   * @param {CandidateWithFile[]} candidates - An array of candidates, each potentially containing an array of profile files.
   * @param {OfferDocument[]} [documents=[]] - An optional array of documents that define the required file types.
   * @param {(value: string) => string} getDocumentTypeLabel - A function to convert document type identifiers into the corresponding labels used in file types.
   * @returns {boolean} - True if all candidates have all the mandatory files required, false otherwise.
   */
  transform(
    candidates: CandidateWithFile[],
    documents: OfferDocument[] | undefined = [],
    getDocumentTypeLabel: (value: string) => string
  ): boolean {
    if (candidates.length) {
      for (const document of documents) {
        for (const candidate of candidates) {
          if (candidate.files?.length !== documents.length) {
            return false;
          }
          if (
            candidate.files.findIndex(
              file =>
                file?.type === getDocumentTypeLabel(document.type) && file.uuid
            ) === -1
          ) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  }
}
