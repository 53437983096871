import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

/**
 * Describes the state of industries within the application, including available and selected industries,
 * and status indicators for loading and errors.
 *
 * @interface IndustriesState
 */
export interface IndustriesState {
  /**
   * Array of industries available for selection.
   *
   * @type {Industry[]}
   */
  availableIndustries: Industry[];

  /**
   * Array of industries that have been selected.
   *
   * @type {Industry[]}
   */
  selectedIndustries: Industry[];

  /**
   * Indicates whether the industries are in the process of loading.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling industries actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of industries within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {IndustriesState}
 * @description Provides a baseline definition for the industries context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialIndustriesState: IndustriesState = {
  availableIndustries: [],
  selectedIndustries: [],
  loading: false,
  error: null,
};
