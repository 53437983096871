/**
 * Extends the Date interface to include additional methods.
 */
declare interface Date {
  /**
   * Adds a specified number of days to the date.
   *
   * @param {number} days - The number of days to add.
   * @returns {Date} A new Date instance with the added days.
   */
  addDays(days: number): Date;

  /**
   * Subtracts a specified number of days from the date.
   *
   * @param {number} days - The number of days to subtract.
   * @returns {Date} A new Date instance with the subtracted days.
   */
  subDays(days: number): Date;
}

/**
 * Adds a specified number of days to the date.
 *
 * @param {number} days - The number of days to add.
 * @returns {Date} A new Date instance with the added days.
 */
Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * Subtracts a specified number of days from the date.
 *
 * @param {number} days - The number of days to subtract.
 * @returns {Date} A new Date instance with the subtracted days.
 */
Date.prototype.subDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};
