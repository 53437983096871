import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Directive to manage and emit error messages for form controls within a reactive form.
 * It listens for changes in the specified form control and emits appropriate validation error messages,
 * especially useful in forms where immediate user feedback on input validation is needed.
 *
 * @Directive decorator specifies the CSS selector `[appFormError]`, indicating that this directive is applied as an attribute to HTML elements.
 */
@Directive({
  selector: '[appFormError]',
})
export class FormErrorDirective implements OnInit, OnChanges {
  /**
   * The name of the form control to which this directive is applied.
   */
  @Input('appFormError') controlName!: string;

  /**
   * Indicates whether the form has been submitted. This can trigger error message emission even if the control has not been touched.
   */
  @Input() isSubmitted = false;

  /**
   * Emits error messages or null if there are no current errors.
   */
  @Output() formError: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  /**
   * Reference to the form control associated with the `controlName` input.
   * This control is monitored for status changes to determine when to emit error messages.
   */
  private control!: AbstractControl | null;

  /**
   * Subscription to the status changes of the form control. This subscription allows the directive to react
   * to changes in the form control's validation status and emit corresponding error messages.
   */
  private statusChangeSubscription!: Subscription;

  /**
   * Constructor initializes with FormGroupDirective to access the form group associated with the directive.
   *
   * @param {FormGroupDirective} formGroupDirective - Directive providing access to the FormGroup to which this directive's element belongs.
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * OnInit lifecycle hook to set up the directive, including finding the control by name and subscribing to its status changes.
   * @return {void}
   */
  ngOnInit(): void {
    this.control = this.formGroupDirective.form.get(this.controlName);
    if (this.control) {
      this.statusChangeSubscription = this.control.statusChanges.subscribe(
        () => {
          this.emitError();
        }
      );
    }
  }

  /**
   * OnChanges lifecycle hook to respond to changes in input properties, particularly 'isSubmitted'.
   *
   * @param {SimpleChanges} changes - Contains the changes to the @Input properties.
   * @return {void}
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSubmitted'].currentValue === true) {
      this.emitError(); // Emit error immediately on form submission
    }
  }

  /**
   * Emits the current error message or null based on the control's validation state.
   * @return {void}
   */
  private emitError(): void {
    if (this.control) {
      if (this.isSubmitted || (this.control.touched && this.control.dirty)) {
        this.formError.emit(this.getErrorMessage(this.control));
      } else {
        this.formError.emit(null);
      }
    }
  }

  /**
   * Determines the appropriate error message based on the types of validation errors present on the control.
   *
   * @param {AbstractControl} control - The form control whose errors are to be evaluated.
   * @returns {string | null} - The error message or null if there are no errors.
   */
  private getErrorMessage(control: AbstractControl): string | null {
    if (control.errors) {
      if (control.hasError('required')) {
        return 'This field is required.';
      }
      if (control.hasError('minlength')) {
        const requiredLength = control.getError('minlength').requiredLength;
        return `Must be at least ${requiredLength} characters long.`;
      }
      if (control.hasError('maxlength')) {
        const requiredLength = control.getError('maxlength').requiredLength;
        return `Cannot be more than ${requiredLength} characters long.`;
      }
      if (control.hasError('email')) {
        return 'Please enter a valid email address.';
      }
      if (control.hasError('pattern')) {
        return 'The format of the input is incorrect.';
      }
      if (control.hasError('invalidPhoneNumber')) {
        return 'The phone number is invalid.';
      }
    }
    return null;
  }
}
