import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

/**
 * Represents the state of profile tags.
 *
 * @interface ProfileTagsState
 */
export interface ProfileTagsState {
  /**
   * An array of tags.
   *
   * @type {Tag[]}
   */
  tags: Tag[];

  /**
   * Indicates if the tags are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the tags.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile tags.
 *
 * @type {ProfileTagsState}
 */
export const initialProfileTagsState: ProfileTagsState = {
  tags: [],
  loading: false,
  error: null,
};
