import { createAction, props } from '@ngrx/store';
import {
  CreateTag,
  UpdateTag,
} from '~/app/states/main/settings/types/tags.types';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

export const loadCandidateTags = createAction(
  '[Candidate Tags] Load Candidate Tags'
);
export const loadCandidateTagsSuccess = createAction(
  '[Candidate Tags] Load Candidate Tags Success',
  props<{ candidateTags: Tag[] }>()
);
export const loadCandidateTagsFailure = createAction(
  '[Candidate Tags] Load Candidate Tags Failure',
  props<{ error: string }>()
);

export const createCandidateTag = createAction(
  '[Candidate Tags] Create Candidate Tag',
  props<{ candidateTagData: CreateTag }>()
);
export const createCandidateTagSuccess = createAction(
  '[Candidate Tags] Create Candidate Tag Success',
  props<{ candidateTag: Tag }>()
);
export const createCandidateTagFailure = createAction(
  '[Candidate Tags] Create Candidate Tag Failure',
  props<{ error: string }>()
);

export const updateCandidateTag = createAction(
  '[Candidate Tags] Update Candidate Tag',
  props<{ uuid: string; candidateTagData: UpdateTag }>()
);
export const updateCandidateTagSuccess = createAction(
  '[Candidate Tags] Update Candidate Tag Success',
  props<{ candidateTag: Tag }>()
);
export const updateCandidateTagFailure = createAction(
  '[Candidate Tags] Update Candidate Tag Failure',
  props<{ error: string }>()
);

export const deleteCandidateTag = createAction(
  '[Candidate Tags] Delete Candidate Tag',
  props<{ uuid: string }>()
);
export const deleteCandidateTagSuccess = createAction(
  '[Candidate Tags] Delete Candidate Tag Success',
  props<{ uuid: string }>()
);
export const deleteCandidateTagFailure = createAction(
  '[Candidate Tags] Delete Candidate Tag Failure',
  props<{ error: string }>()
);

export const candidateTagUnauthorized = createAction(
  '[Candidate Tags] Unauthorized',
  props<{ error: string }>()
);
