import { createAction, props } from '@ngrx/store';
import {
  AssignUsersInRuleGroup,
  UnassignedUsersInRuleGroup,
} from '~/app/states/main/settings/types/access-control.types';
import {
  AssignRulesInRuleGroup,
  CreateRuleGroup,
  UnassignedRulesInRuleGroup,
  UpdateRuleGroupById,
} from '~/app/states/main/settings/types/groups.types';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Group } from '~/app/shared/interfaces/settings/access-control/group/group.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

// Action types for loading all group rules
export const loadGroupRules = createAction(
  '[Group Rules] Load Group Rules',
  props<{ limit: number; page: number }>()
);
export const loadGroupRulesSuccess = createAction(
  '[Group Rules] Load Group Rules Success',
  props<{ groups: PaginationData<Group> }>()
);
export const loadGroupRulesFailure = createAction(
  '[Group Rules] Load Group Rules Failure',
  props<{ error: string }>()
);

// Action types for loading a group rule by ID
export const loadGroupRuleById = createAction(
  '[Group Rules] Load Group Rule By ID',
  props<{ uuid: string }>()
);
export const loadGroupRuleByIdSuccess = createAction(
  '[Group Rules] Load Group Rule By ID Success',
  props<{ rule: any }>()
);
export const loadGroupRuleByIdFailure = createAction(
  '[Group Rules] Load Group Rule By ID Failure',
  props<{ error: string }>()
);

// Action types for creating a group rule
export const createGroupRule = createAction(
  '[Group Rules] Create Group Rule',
  props<{ ruleGroupData: CreateRuleGroup }>()
);
export const createGroupRuleSuccess = createAction(
  '[Group Rules] Create Group Rule Success',
  props<{ group: Group }>()
);
export const createGroupRuleFailure = createAction(
  '[Group Rules] Create Group Rule Failure',
  props<{ error: string }>()
);

// Action types for updating a group rule
export const updateGroupRule = createAction(
  '[Group Rules] Update Group Rule',
  props<{ uuid: string; ruleGroupData: UpdateRuleGroupById }>()
);
export const updateGroupRuleSuccess = createAction(
  '[Group Rules] Update Group Rule Success',
  props<{ groupData: Group }>()
);
export const updateGroupRuleFailure = createAction(
  '[Group Rules] Update Group Rule Failure',
  props<{ error: string }>()
);

// Action types for deleting a group rule
export const deleteGroupRule = createAction(
  '[Group Rules] Delete Group Rule',
  props<{ uuid: string }>()
);
export const deleteGroupRuleSuccess = createAction(
  '[Group Rules] Delete Group Rule Success',
  props<{ groupId: string }>()
);
export const deleteGroupRuleFailure = createAction(
  '[Group Rules] Delete Group Rule Failure',
  props<{ error: string }>()
);

// Action types for assigning/unassigning users to a group rule
export const assignUserInGroup = createAction(
  '[Group Rules] Assign User To Group',
  props<{ userRuleGroupData: AssignUsersInRuleGroup }>()
);
export const assignUserInGroupSuccess = createAction(
  '[Group Rules] Assign User To Group Success',
  props<{ user: User }>()
);
export const assignUserInGroupFailure = createAction(
  '[Group Rules] Assign User To Group Failure',
  props<{ error: string }>()
);

export const unassignUserInGroup = createAction(
  '[Group Rules] Unassign User From Group',
  props<{ userRuleGroupData: UnassignedUsersInRuleGroup }>()
);
export const unassignUserInGroupSuccess = createAction(
  '[Group Rules] Unassign User From Group Success',
  props<{ user: User }>()
);
export const unassignUserInGroupFailure = createAction(
  '[Group Rules] Unassign User From Group Failure',
  props<{ error: string }>()
);

// Action types for assigning/unassigning rules or access-control to/from a group rule
export const assignRuleInGroup = createAction(
  '[Group Rules] Assign Rule To Group',
  props<{ rulesRuleGroupData: AssignRulesInRuleGroup }>()
);
export const assignRuleInGroupSuccess = createAction(
  '[Group Rules] Assign Rule To Group Success'
);
export const assignRuleInGroupFailure = createAction(
  '[Group Rules] Assign Rule To Group Failure',
  props<{ error: string }>()
);

export const unassignRuleInGroup = createAction(
  '[Group Rules] Unassign Rule From Group',
  props<{ rulesRuleGroupData: UnassignedRulesInRuleGroup }>()
);
export const unassignRuleInGroupSuccess = createAction(
  '[Group Rules] Unassign Rule From Group Success'
);
export const unassignRuleInGroupFailure = createAction(
  '[Group Rules] Unassign Rule From Group Failure',
  props<{ error: string }>()
);

export const groupRulesUnauthorized = createAction(
  '[Group Rules] Unauthorized',
  props<{ error: string }>()
);
