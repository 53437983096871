import { Skill } from '~/app/shared/interfaces/shared/skill.interface';

/**
 * Represents the state of profile skills.
 *
 * @interface ProfileSkillsState
 */
export interface ProfileSkillsState {
  /**
   * An array of profile skills.
   *
   * @type {Skill[]}
   * @memberof ProfileSkillsState
   */
  skills: Skill[];

  /**
   * Indicates if the profile skills are currently being loaded.
   *
   * @type {boolean}
   * @memberof ProfileSkillsState
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the profile skills.
   *
   * @type {string | null}
   * @memberof ProfileSkillsState
   */
  error: string | null;
}

/**
 * The initial state of profile skills within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileSkillsState} initialProfileSkillsState
 * @description Provides a baseline definition for the profile skills context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileSkillsState: ProfileSkillsState = {
  skills: [],
  loading: false,
  error: null,
};
