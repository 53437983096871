import { AllRules } from '~/app/shared/interfaces/settings/access-control/rule/all-rules.interface';
import { GroupRule } from '~/app/shared/interfaces/settings/access-control/group/group-rule.interface';

/**
 * Describes the state of rules within the application, including all rules, current group rules,
 * and status indicators for loading and errors.
 *
 * @interface RulesState
 */
export interface RulesState {
  /**
   * All rules categorized by modules.
   *
   * @type {AllRules | null}
   */
  allRules: AllRules | null;

  /**
   * Current group rules.
   *
   * @type {GroupRule | null}
   */
  currentGroupRules: GroupRule | null;

  /**
   * Indicates whether rule data is being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while handling rule-related actions.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the rules within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @type {RulesState}
 * @description Provides a baseline definition for the rules context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialRulesState: RulesState = {
  allRules: null,
  currentGroupRules: null,
  loading: false,
  error: null,
};
