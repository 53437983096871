import { BreakpointObserver } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { authReducer } from '~/app/auth/auth.reducer';
import { JwtInterceptor } from '~/app/auth/interceptors/jwt-interceptor';
import { LoginEffects } from '~/app/auth/state/login/login.effects';
import { RegisterEffects } from '~/app/auth/state/register/register.effects';
import { ResetPasswordEffects } from '~/app/auth/state/reset-password/reset-password.effects';
import { TwoFactorAuthEffects } from '~/app/auth/state/two-factor-auth/two-factor-auth.effects';
import { appInitializerFactory } from '~/app/core/app.initializer';
import { EnumService } from '~/app/core/services/enum.service';
import { breakpointReducer } from '~/app/core/state/breakpoint/breakpoint.reducer';
import { EnumsEffects } from '~/app/core/state/enum/enums.effects';
import { enumsReducer } from '~/app/core/state/enum/enums.reducer';
import { AuthErrorInterceptor } from '~/app/auth/interceptors/auth-error-interceptor';
import { ThemeService } from '~/app/core/services/theme.service';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({
      auth: authReducer,
      enums: enumsReducer,
      breakpoint: breakpointReducer,
    }),
    EffectsModule.forRoot([
      LoginEffects,
      RegisterEffects,
      ResetPasswordEffects,
      TwoFactorAuthEffects,
      EnumsEffects,
    ]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [Store, EnumService, BreakpointObserver, ThemeService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
