<div class="pb-3 relative">
  <label [for]="formControlName" class="font-bold text-sm mx-0">
    {{ label }} <span *ngIf="required" class="text-red-600">*</span>
  </label>
  <input
    [value]="value"
    (input)="onInput($event)"
    [id]="formControlName"
    type="text"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [appFormError]="formControlName"
    (formError)="onErrorMessageReceived($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [isSubmitted]="isSubmitted"
    pInputText
    class="w-full mt-2" />
  <div class="text-xs text-red-600 absolute bottom-0">
    {{ error }}
  </div>
</div>
