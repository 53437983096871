import { createReducer, on } from '@ngrx/store';
import * as SalesSectorJobsActions from '~/app/states/main/sales/profile/states/sales-sectors-jobs/sales-sector-jobs.actions';
import { initialSalesSectorJobsState } from '~/app/states/main/sales/profile/states/sales-sectors-jobs/sales-sector-jobs.state';

export const salesSectorJobReducer = createReducer(
  initialSalesSectorJobsState,

  on(SalesSectorJobsActions.loadSalesSectors, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSectorJobsActions.loadSalesSectorsSuccess,
    (state, { salesSectors }) => ({
      ...state,
      salesSectorJobs: salesSectors,
      loading: false,
    })
  ),
  on(SalesSectorJobsActions.loadSalesSectorsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesSectorJobsActions.unassignSalesSector, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSectorJobsActions.unassignSalesSectorSuccess,
    (state, { salesSectors }) => ({
      ...state,
      salesSectorJobs: salesSectors,
      loading: false,
    })
  ),
  on(SalesSectorJobsActions.unassignSalesSectorFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesSectorJobsActions.assignSalesSectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesSectorJobsActions.assignSalesSectorJobSuccess,
    (state, { salesSectors }) => ({
      ...state,
      salesSectorJobs: salesSectors,
      loading: false,
    })
  ),
  on(
    SalesSectorJobsActions.assignSalesSectorJobFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    SalesSectorJobsActions.salesSectorJobsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
