/**
 * Defines the CSS media query breakpoints for responsive design in the application. These breakpoints are used to apply different styles based on the current viewport width.
 *
 * @constant
 * @type {object}
 * @property {string} sm - Represents screens with a maximum width of 576 pixels. Styles defined under this breakpoint are applied for small devices like mobile phones.
 * @property {string} md - Represents screens with a maximum width of 768 pixels. Styles defined under this breakpoint are applied for medium devices like tablets.
 * @property {string} lg - Represents screens with a maximum width of 992 pixels. Styles defined under this breakpoint are applied for large devices like small laptops and large tablets.
 * @property {string} xl - Represents screens with a maximum width of 1200 pixels. Styles defined under this breakpoint are applied for extra-large devices like desktop monitors.
 */
export const primeFlexBreakpoints = {
  sm: '(max-width: 576px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 992px)',
  xl: '(max-width: 1200px)',
};
