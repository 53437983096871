import { createAction, props } from '@ngrx/store';
import {
  CreateSectorJobCategory,
  UpdateSectorJobCategoryById,
  UpdateSectorJobs,
} from '~/app/states/main/types/sectors.types';
import { SectorCategory } from '~/app/shared/interfaces/shared/sector/sector-category.interface';
import { SectorJob } from '~/app/shared/interfaces/shared/sector/sector-job.interface';

export const loadSectorCategories = createAction(
  '[Sector Category] Load Sector Categories'
);
export const loadSectorCategoriesSuccess = createAction(
  '[Sector Category] Load Sector Categories Success',
  props<{ sectorCategories: SectorCategory[]; allSectorJobs: SectorJob[] }>()
);
export const loadSectorCategoriesFailure = createAction(
  '[Sector Category] Load Sector Categories Failure',
  props<{ error: string }>()
);

export const loadSectorCategoryById = createAction(
  '[Sector Category] Load Sector Category',
  props<{ uuid: string }>()
);
export const loadSectorCategoryByIdSuccess = createAction(
  '[Sector Category] Load Sector Category Success',
  props<{ sectorCategory: SectorCategory }>()
);
export const loadSectorCategoryByIdFailure = createAction(
  '[Sector Category] Load Sector Category Failure',
  props<{ error: string }>()
);

export const createSectorCategory = createAction(
  '[Sector Category] Create Sector Category',
  props<{ sectorCategoryData: CreateSectorJobCategory }>()
);
export const createSectorCategorySuccess = createAction(
  '[Sector Category] Create Sector Category Success',
  props<{ sectorCategory: SectorCategory }>()
);
export const createSectorCategoryFailure = createAction(
  '[Sector Category] Create Sector Category Failure',
  props<{ error: string }>()
);

export const updateSectorCategory = createAction(
  '[Sector Category] Update Sector Category',
  props<{ uuid: string; sectorCategoryData: UpdateSectorJobCategoryById }>()
);
export const updateSectorCategorySuccess = createAction(
  '[Sector Category] Update Sector Category Success',
  props<{ sectorCategory: SectorCategory }>()
);
export const updateSectorCategoryFailure = createAction(
  '[Sector Category] Update Sector Category Failure',
  props<{ error: string }>()
);

export const deleteSectorCategory = createAction(
  '[Sector Category] Delete Sector Category',
  props<{ uuid: string }>()
);
export const deleteSectorCategorySuccess = createAction(
  '[Sector Category] Delete Sector Category Success',
  props<{ uuid: string }>()
);
export const deleteSectorCategoryFailure = createAction(
  '[Sector Category] Delete Sector Category Failure',
  props<{ error: string }>()
);

export const assignSectorJob = createAction(
  '[Sector Category] Assign Sector Job',
  props<{ sectorJobData: UpdateSectorJobs }>()
);
export const assignSectorJobSuccess = createAction(
  '[Sector Category] Assign Sector Job Success',
  props<{ sectorCategory: SectorCategory }>()
);
export const assignSectorJobFailure = createAction(
  '[Sector Category] Assign Sector Job Failure',
  props<{ error: string }>()
);

export const unassignSectorJob = createAction(
  '[Sector Category] Unassign Sector Job',
  props<{ sectorJobData: UpdateSectorJobs }>()
);
export const unassignSectorJobSuccess = createAction(
  '[Sector Category] Unassign Sector Job Success',
  props<{ sectorCategory: SectorCategory }>()
);
export const unassignSectorJobFailure = createAction(
  '[Sector Category] Unassign Sector Job Failure',
  props<{ error: string }>()
);

export const sectorJobUnauthorized = createAction(
  '[Sector Category] Unauthorized',
  props<{ error: string }>()
);
