<div class="mx-4 py-3 border-top-1 surface-border sm:w-30rem">
  <div class="flex flex-row gap-3 align-items-center sm:align-items-start pt-2">
    <div
      class="border-circle w-3rem h-3rem flex-shrink-0 flex align-items-center justify-content-center"
      [ngClass]="backgroundColor">
      <i class="text-3xl mb-1" [ngClass]="icon"></i>
    </div>

    <div class="text-center sm:text-left">
      <span class="my-2 font-semibold text-lg">{{ alert.title }}</span>
      <div class="mt-2">
        <p class="text-sm text-gray-700">{{ alert.message }}</p>
      </div>
    </div>
  </div>
</div>

<div
  class="bg-gray-100 px-4 py-3 flex flex-row-reverse justify-content-between">
  <ice-button
    [label]="alert.buttonActionMessage"
    (onClick)="alert.action(); close()"
    [severity]="alert.type">
  </ice-button>
  <ice-button
    [text]="true"
    severity="secondary"
    i18n-severity
    class="p-button-raised"
    [label]="'Cancel'"
    (onClick)="close()"></ice-button>
</div>
