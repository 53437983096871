import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';
import { Company } from '~/app/shared/interfaces/settings/company.interface';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { SharedState } from '~/app/shared/shared.state';
import {
  selectCandidateStatuses,
  selectCandidateTags,
  selectCategoriesWithSectors,
  selectCompanies,
  selectCompanyReferences,
  selectIndustries,
  selectNoteCategories,
  selectOffices,
  selectSalesStatuses,
  selectTeams,
  selectTimelines,
  selectUsers,
} from '~/app/shared/states/data/data.selectors';
import { CategoriesWithSectors } from '~/app/shared/interfaces/shared/sector/categories-with-sectors.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { CompanyReferences } from '~/app/shared/interfaces/company-references.interface';
import * as DataActions from '~/app/shared/states/data/data.actions';

/**
 * Service for interacting with the store to manage and retrieve data related to various business entities.
 * This service encapsulates the logic for dispatching actions to load data into the store and for selecting data
 * from the store using selectors. Each property in this service is responsible for dealing with specific data types,
 * making it a central point for data operations across the application.
 *
 * @Injectable marks this class as a service that can be injected with Angular's dependency injection system,
 * and provided at the root level means it's available throughout the application as a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class DataService {
  /**
   * Initializes a new instance of DataService with the injected NgRx Store.
   * @param {Store<SharedState>} store - The application-wide Redux store instance for managing state.
   */
  constructor(private store: Store<SharedState>) {}

  /**
   * Observable stream of candidate statuses loaded from the store. Dispatches an action to load these statuses if not already loaded.
   * @returns {Observable<Status[]>} An Observable that emits an array of candidate statuses.
   */
  get candidateStatuses$(): Observable<Status[]> {
    this.store.dispatch(DataActions.loadCandidateStatuses());
    return this.store.select(selectCandidateStatuses);
  }

  /**
   * Observable stream of sales statuses.
   * @returns {Observable<Status[]>} An Observable that emits an array of sales statuses.
   */
  get salesStatuses$(): Observable<Status[]> {
    this.store.dispatch(DataActions.loadSalesStatuses());
    return this.store.select(selectSalesStatuses);
  }

  /**
   * Observable stream of note categories.
   * @returns {Observable<NoteCategory[]>} An Observable that emits an array of note categories.
   */
  get noteCategories$(): Observable<NoteCategory[]> {
    this.store.dispatch(DataActions.loadNoteCategories());
    return this.store.select(selectNoteCategories);
  }

  /**
   * Observable stream of office locations.
   * @returns {Observable<Office[]>} An Observable that emits an array of office locations.
   */
  get offices$(): Observable<Office[]> {
    this.store.dispatch(DataActions.loadOffices());
    return this.store.select(selectOffices);
  }

  /**
   * Observable stream of industries.
   * @returns {Observable<Industry[]>} An Observable that emits an array of industries.
   */
  get industries$(): Observable<Industry[]> {
    this.store.dispatch(DataActions.loadIndustries());
    return this.store.select(selectIndustries);
  }

  /**
   * Observable stream of companies.
   * @returns {Observable<Company[]>} An Observable that emits an array of companies.
   */
  get companies$(): Observable<Company[]> {
    this.store.dispatch(DataActions.loadCompanies());
    return this.store.select(selectCompanies);
  }

  /**
   * Observable stream of teams within the organization.
   * @returns {Observable<Team[]>} An Observable that emits an array of teams.
   */
  get teams$(): Observable<Team[]> {
    this.store.dispatch(DataActions.loadTeams());
    return this.store.select(selectTeams);
  }

  /**
   * Observable stream of categories with their associated sectors.
   * @returns {Observable<CategoriesWithSectors[]>} An Observable that emits an array of categories with sectors.
   */
  get categoriesWithSectors$(): Observable<CategoriesWithSectors[]> {
    this.store.dispatch(DataActions.loadCategoriesWithSectors());
    return this.store.select(selectCategoriesWithSectors);
  }

  /**
   * Observable stream of candidate tags.
   * @returns {Observable<Tag[]>} An Observable that emits an array of candidate tags.
   */
  get candidateTags$(): Observable<Tag[]> {
    this.store.dispatch(DataActions.loadCandidateTags());
    return this.store.select(selectCandidateTags);
  }

  /**
   * Observable stream of users with pagination data.
   * @returns {Observable<PaginationData<User> | null>} An Observable that emits pagination data for users or null if no data is available.
   */
  get users$(): Observable<PaginationData<User> | null> {
    this.store.dispatch(DataActions.loadUsers());
    return this.store.select(selectUsers);
  }

  /**
   * Observable stream of company references.
   * @returns {Observable<CompanyReferences[]>} An Observable that emits an array of company references.
   */
  get companyReferences$(): Observable<CompanyReferences[]> {
    this.store.dispatch(DataActions.loadCompanyReferences());
    return this.store.select(selectCompanyReferences);
  }

  get timelines$(): Observable<PaginationData<Timeline> | null> {
    this.store.dispatch(DataActions.loadTimelines());
    return this.store.select(selectTimelines);
  }
}
