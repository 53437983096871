<form
  [formGroup]="profileForm"
  [appComponentPermission]="iceRule.ReadProfile"
  (ngSubmit)="onSubmit()">
  <ice-input
    [rule]="initialData ? iceRule.UpdateProfile : iceRule.CreateProfile"
    [label]="'First Name'"
    [placeholder]="'FirstName'"
    [formControlName]="'firstname'"
    [required]="true"
    [formControl]="firstname">
  </ice-input>
  <ice-input
    [rule]="initialData ? iceRule.UpdateProfile : iceRule.CreateProfile"
    [label]="'Last Name'"
    [placeholder]="'LastName'"
    [formControlName]="'lastname'"
    [required]="true"
    [formControl]="lastname"></ice-input>

  <div class="flex flex-column gap-1 mb-3">
    <span class="block text-900 font-semibold text-sm mb-1"> Birth Date </span>
    <p-calendar
      dateFormat="yy-mm-dd"
      i18n-dateFormat
      [placeholder]="'1950-01-01'"
      [formControlName]="'birthday'"
      [formControl]="birthday"
      [maxDate]="today"
      appendTo="body"
      i18n-appendTo></p-calendar>
  </div>

  <label i18n class="font-bold text-sm mx-0">Gender</label>
  <div class="flex flex-wrap gap-3 justify-content-center mt-2">
    <div class="flex align-items-center">
      <p-radioButton
        id="male"
        name="gender"
        value="male"
        formControlName="gender"
        inputId="male"
        i18n-inputId></p-radioButton>
      <label i18n for="male" class="ml-2 text-sm">Male</label>
    </div>

    <div class="flex align-items-center">
      <p-radioButton
        id="female"
        name="gender"
        value="female"
        formControlName="gender"
        inputId="female"
        i18n-inputId></p-radioButton>
      <label i18n for="female" class="ml-2 text-sm">Female</label>
    </div>

    <div class="flex align-items-center">
      <p-radioButton
        id="other"
        name="gender"
        value="other"
        formControlName="gender"
        inputId="other"
        i18n-inputId></p-radioButton>
      <label i18n for="other" class="ml-2 text-sm">Other</label>
    </div>
  </div>

  <div class="flex gap-3 mt-4">
    <ice-phone-iso
      [label]="'iso'"
      [placeholder]="'iso'"
      [formControlName]="'phoneIso'"
      [formControl]="phoneIso">
    </ice-phone-iso>
    <ice-phone-number
      class="w-full"
      [label]="'Phone'"
      [placeholder]="'Phone'"
      [formControlName]="'phone'"
      [formControl]="phone"
      [iso]="phoneIso.value"></ice-phone-number>
  </div>
  <ice-input
    [rule]="initialData ? iceRule.UpdateProfile : iceRule.CreateProfile"
    [label]="'Email'"
    [placeholder]="'Email'"
    [formControlName]="'email'"
    [required]="true"
    [formControl]="email"></ice-input>

  <ng-container *ngIf="initialData">
    <p-divider *ngIf="type.value"></p-divider>

    <div class="card flex justify-content-center my-2">
      <ice-select
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [formControl]="type"
        [formControlName]="'type'"
        [suggestions]="(profileTypes$ | async) ?? []">
      </ice-select>
    </div>

    <ng-container *ngIf="type.value === profileType.Consultant">
      <div class="pt-2">
        <span class="block text-900 font-semibold text-sm mb-1">
          Start Date <span class="text-red-600">*</span>
        </span>
        <p-calendar
          dateFormat="yy-mm-dd"
          i18n-dateFormat
          [placeholder]="'2024-05-21'"
          [formControlName]="'startDate'"
          [formControl]="startDate"
          appendTo="body"
          i18n-appendTo></p-calendar>
      </div>

      <div class="mt-3">
        <span class="block text-900 font-semibold text-sm mb-1">
          End Date <span class="text-red-600">*</span>
        </span>
        <p-calendar
          dateFormat="yy-mm-dd"
          i18n-dateFormat
          [placeholder]="'2024-08-12'"
          [formControlName]="'endDate'"
          [formControl]="endDate"
          appendTo="body"
          i18n-appendTo></p-calendar>
      </div>

      <div class="mt-3">
        <ice-autocomplete
          [rule]="iceRule.ReadClientCompany"
          [label]="'Client Company'"
          [formControl]="clientCompany"
          [formControlName]="'clientCompanyId'"
          [multiple]="false"
          [placeholder]="'Client company'"
          [dropdown]="false"
          [required]="true"
          [optionLabel]="'label'"
          (completeMethod)="searchClientCompanies($event)"
          [suggestions]="(clientCompanies$ | async)!"></ice-autocomplete>
      </div>
    </ng-container>
  </ng-container>

  <p-divider></p-divider>

  <div class="pt-3">
    <ice-autocomplete
      class="w-full"
      [label]="'Status'"
      [formControl]="statusId"
      [formControlName]="'statusId'"
      [multiple]="false"
      placeholder="Select a status"
      i18n-placeholder
      [dropdown]="false"
      [optionLabel]="'label'"
      [required]="true"
      [iceAutocompleteTemplate]="templateEnum.STATUS"
      [suggestions]="(statuses$ | async)!">
    </ice-autocomplete>
  </div>

  <ice-autocomplete
    class="w-full"
    [label]="'Office'"
    [required]="true"
    [formControl]="officeId"
    [formControlName]="'officeId'"
    [multiple]="false"
    [placeholder]="'Select a Office'"
    [dropdown]="false"
    [optionLabel]="'label'"
    [suggestions]="(offices$ | async)!"></ice-autocomplete>

  <p-divider></p-divider>

  <ice-input
    class="pt-3"
    [label]="'Summary'"
    [rule]="initialData ? iceRule.UpdateProfile : iceRule.CreateProfile"
    [textArea]="true"
    id="summary-input"
    [rows]="5"
    [cols]="30"
    placeholder="Summary"
    i18n-placeholder
    [formControl]="summary"
    [formControlName]="'summary'"></ice-input>

  <ice-autocomplete
    class="w-full"
    [label]="'Team'"
    [formControl]="teamId"
    [formControlName]="'teamId'"
    [multiple]="false"
    [placeholder]="'Select a Team'"
    [dropdown]="false"
    [optionLabel]="'label'"
    [suggestions]="(teams$ | async)!"></ice-autocomplete>

  <ice-autocomplete
    class="w-full"
    [required]="true"
    [label]="'Experience'"
    [formControl]="experience"
    [formControlName]="'experience'"
    [multiple]="false"
    placeholder="Select experience"
    i18n-placeholder
    [dropdown]="false"
    [optionLabel]="'label'"
    [optionValue]="'value'"
    [suggestions]="(experiences$ | async)!"></ice-autocomplete>

  <div class="flex flex-column gap-2 pb-3">
    <label i18n class="font-bold text-sm mx-0">Freelance Salary</label>
    <p-inputNumber
      placeholder="Freelance Salary"
      i18n-placeholder
      formControlName="freelanceSalary"
      mode="currency"
      i18n-mode
      currency="EUR"
      i18n-currency
      [showButtons]="true"
      [min]="0"
      [step]="50"
      [formControl]="freelanceSalary">
    </p-inputNumber>
  </div>

  <div class="sticky bottom-0 bg-light-100">
    <p-divider></p-divider>
    <ice-button
      type="submit"
      [rule]="initialData ? iceRule.UpdateProfile : iceRule.CreateProfile"
      [label]="initialData ? 'Update' : 'Create'"
      class="flex justify-content-end"
      [icon]="initialData ? 'pi pi-pencil' : 'pi pi-plus'"
      [disabled]="!isFormValid || !isFormDirty"
      [loading]="(isProfileLoading$ | async) ?? false">
    </ice-button>
  </div>
</form>
