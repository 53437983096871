import { createReducer, on } from '@ngrx/store';
import { initialAllProjectsState } from '~/app/states/main/sales/states/search-projects/search-projects.state';
import * as ProjectsActions from '~/app/states/main/sales/states/search-projects/search-projects.actions';

export const allProjectsReducer = createReducer(
  initialAllProjectsState,

  on(ProjectsActions.loadAllProjects, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectsActions.loadAllProjectsSuccess, (state, { allProjects }) => ({
    ...state,
    ...allProjects,
    loading: false,
  })),
  on(ProjectsActions.loadAllProjectsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectsActions.createProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectsActions.createProjectSuccess, (state, { project }) => ({
    ...state,
    data: [project, ...state.data],
    loading: false,
  })),
  on(ProjectsActions.createProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectsActions.updateProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectsActions.updateProjectSuccess, (state, { project }) => ({
    ...state,
    data: state.data.map(item =>
      item.uuid === project.uuid ? { ...item, ...project } : item
    ),
    loading: false,
  })),
  on(ProjectsActions.updateProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectsActions.deleteProject, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProjectsActions.deleteProjectSuccess, (state, { uuid }) => ({
    ...state,
    data: state.data.filter(item => item.uuid !== uuid),
    loading: false,
  })),
  on(ProjectsActions.deleteProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProjectsActions.projectsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
