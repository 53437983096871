import { Notifications } from '@npmicedev/icemodule/lib/entities/Notifications';
import { IsseUser } from '~/app/shared/interfaces/isse-user.interface';

/**
 * Represents the overall state of Notification-related data in the application.
 *
 * @interface NotificationState
 */
export interface NotificationState {
  /**
   * All the notifications.
   * The notifications are indexed by their unique identifiers (uuid).
   *
   * @type {{ [key: string]: Notifications }}
   */
  allNotifications: Notifications[];

  /**
   * The notifications for a specific user.
   * These notifications are indexed by their unique identifiers (uuid).
   *
   * @type {{ [key: string]: Notifications }}
   */
  userNotifications: { [key: string]: Notifications };

  /**
   * The expired notifications.
   * These notifications are indexed by their unique identifiers (uuid).
   *
   * @type {{ [key: string]: Notifications }}
   */
  expiredNotifications: { [key: string]: Notifications };

  /**
   * Indicates if notifications are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, that occurred during loading notifications.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * The token for the connection to the SSE server.
   * This token is used for subscribing to notification channels.
   *
   * @type {string}
   */
  token: string | null;

  /**
   * Indicates whether the user is connected to the SSE channel for notifications.
   *
   * @type {boolean}
   */
  connected: boolean;

  /**
   * The list of users currently typing or interacting with notifications (if applicable).
   * This can be used for collaborative notification features.
   *
   * @type { { [key: string]: IsseUser[] } }
   */
  typingUsers: { [key: string]: IsseUser[] };
}

/**
 * Represents the entire notifications feature state in the global store.
 *
 * This interface allows grouping all notification-related state
 * under one key (allNotifications), making it easier to integrate
 * into the global application state.
 *
 * @interface AllNotificationsState
 */
export interface AllNotificationsState {
  /**
   * The state that holds all notifications-related data, including:
   * - all notifications
   * - user-specific notifications
   * - expired notifications
   * - loading & error state
   * - SSE connection info
   * - typing users data (if collaborative features are used)
   *
   * @type {NotificationState}
   */
  allNotifications: NotificationState;
}

/**
 * The initial state for the notifications feature.
 *
 * @type {NotificationState}
 */
export const initialNotificationState: NotificationState = {
  allNotifications: [],
  userNotifications: {},
  expiredNotifications: {},
  loading: false,
  error: null,
  token: '',
  connected: false,
  typingUsers: {},
};
