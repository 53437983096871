import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from '~/app/auth/auth.state';
import * as LoginActions from '~/app/auth/state/login/login.actions';
import * as RegisterActions from '~/app/auth/state/register/register.actions';
import * as ResetPasswordActions from '~/app/auth/state/reset-password/reset-password.actions';
import * as TwoFactorAuthActions from '~/app/auth/state/two-factor-auth/two-factor-auth.actions';
import * as AuthActions from '~/app/auth/auth.actions';

export const authReducer = createReducer(
  initialAuthState,

  // Login reducers
  on(LoginActions.login, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    LoginActions.loginTwoFactorAuthentication,
    (state, { secureLoginToken }) => ({
      ...state,
      secureLoginToken,
      loading: false,
      error: null,
    })
  ),
  on(LoginActions.secureLogin, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(LoginActions.loginSuccess, (state, { userData }) => ({
    ...state,
    userData,
    loading: false,
  })),
  on(LoginActions.loginFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(LoginActions.logout, state => ({
    ...state,
    loading: true, // Optionally indicate loading during the logout process
  })),
  on(LoginActions.logoutSuccess, () => ({
    ...initialAuthState, // Reset the states to initial values on successful logout
  })),
  on(LoginActions.logoutFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error, // Handle any error that occurred during logout
  })),

  on(AuthActions.refreshTokenSuccess, (state, { userData }) => ({
    ...state,
    userData,
    // Update other relevant states properties as needed
  })),
  on(AuthActions.refreshTokenFailure, (state, { error }) => ({
    ...state,
    error: error,
    // Handle error states updates as needed
  })),

  // Register reducers
  on(RegisterActions.register, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RegisterActions.registerSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(RegisterActions.registerFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(RegisterActions.checkToken, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RegisterActions.checkTokenSuccess, (state, { userData }) => ({
    ...state,
    loading: false,
    userData,
  })),
  on(RegisterActions.checkTokenFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(RegisterActions.updateCompanyForm, (state, { companyData }) => ({
    ...state,
    companyFormData: companyData,
  })),

  // Handler for updating the office form data
  on(RegisterActions.updateOfficeForm, (state, { officeData }) => ({
    ...state,
    officeFormData: officeData,
  })),

  // Handler for updating the root user form data
  on(RegisterActions.updateRootUserForm, (state, { rootUserData }) => ({
    ...state,
    rootUserFormData: rootUserData,
  })),

  on(RegisterActions.checkInvitation, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(RegisterActions.userRegister, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RegisterActions.userRegisterSuccess, (state, { userData }) => ({
    ...state,
    userData,
    loading: false,
  })),
  on(RegisterActions.userRegisterFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Reset-password reducers
  on(ResetPasswordActions.resetPassword, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ResetPasswordActions.resetPasswordSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(ResetPasswordActions.resetPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ResetPasswordActions.patchPassword, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ResetPasswordActions.patchPasswordSuccess, (state, { userData }) => ({
    ...state,
    userData,
    loading: false,
  })),
  on(ResetPasswordActions.patchPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ResetPasswordActions.checkResetPassword, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Two-factor authentication reducers
  on(TwoFactorAuthActions.disableTwoFactorAuthenticationByRecovery, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    TwoFactorAuthActions.disableTwoFactorAuthenticationByRecoverySuccess,
    state => ({
      ...state,
      loading: false,
    })
  ),
  on(
    TwoFactorAuthActions.disableTwoFactorAuthenticationByRecoveryFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    TwoFactorAuthActions.updateTwoFactorAuthentication,
    (state, { twoFactorActivate }) => {
      if (state.userData)
        return {
          ...state,
          userData: {
            ...state.userData,
            user: {
              ...state.userData.user,
              twoFactorActivate,
            },
          },
        };
      else return { ...state };
    }
  ),

  on(AuthActions.registerAction, (state, { action }) => ({
    ...state,
    lastRequest: action,
  })),

  on(AuthActions.authUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
