import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesLanguagesActions from '~/app/states/main/sales/profile/states/sales-languages/sales-languages.actions';
import { SalesLanguage } from '~/app/shared/interfaces/sales/profile/sales-languages.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `SalesLanguagesEffects` manages the side effects for sales language-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales languages, such as loading, creating, and deleting sales language records.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesLanguages$`: Loads the sales languages associated with a specific sales record. It performs an HTTP GET request and dispatches a success action with the loaded sales languages or a failure action if an error occurs.
 * — `createSalesLanguage$`: Creates a new sales language record. It performs an HTTP POST request and dispatches a success action with the created sales language or a failure action if an error occurs.
 * — `createSalesLanguageSuccess$`: Handles the success of creating a new sales language record by displaying a success toast message.
 * — `deleteSalesLanguage$`: Deletes a sales language record. It performs an HTTP DELETE request and dispatches a success action or a failure action if an error occurs.
 * — `deleteSalesLanguageSuccess$`: Handles the success of deleting a sales language record by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesLanguagesEffects {
  /**
   * Effect to load sales languages associated with a specific sales record.
   * It listens for the `loadSalesLanguages` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales languages or a failure action if an error occurs.
   */
  loadSalesLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesLanguagesActions.loadSalesLanguages),
      checkPermission(SalesLanguagesActions.salesLanguagesUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<SalesLanguage[]>(`${environment.apiUrl}/v1/sales/languages`, {
            params: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesLanguages =>
              SalesLanguagesActions.loadSalesLanguagesSuccess({
                salesLanguages,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesLanguagesActions.loadSalesLanguagesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales language record.
   * It listens for the `createSalesLanguage` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales language or a failure action if an error occurs.
   */
  createSalesLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesLanguagesActions.createSalesLanguage),
      checkPermission(SalesLanguagesActions.salesLanguagesUnauthorized),
      mergeMap(({ salesLanguage }) =>
        this.http
          .post<SalesLanguage>(
            `${environment.apiUrl}/v1/sales/languages`,
            salesLanguage,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesLanguage =>
              SalesLanguagesActions.createSalesLanguageSuccess({
                salesLanguage,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesLanguagesActions.createSalesLanguageFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new sales language record.
   * It displays a success toast message.
   */
  createSalesLanguageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesLanguagesActions.createSalesLanguageSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales language created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sales language record.
   * It listens for the `deleteSalesLanguage` action, checks for permissions, and performs an HTTP DELETE request.
   * Upon successful deletion, it dispatches a success action or a failure action if an error occurs.
   */
  deleteSalesLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesLanguagesActions.deleteSalesLanguage),
      checkPermission(SalesLanguagesActions.salesLanguagesUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/sales/languages/${uuid}`, {
            body: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() =>
              SalesLanguagesActions.deleteSalesLanguageSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                SalesLanguagesActions.deleteSalesLanguageFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a sales language record.
   * It displays a success toast message.
   */
  deleteSalesLanguageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesLanguagesActions.deleteSalesLanguageSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales language deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesLanguagesEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
