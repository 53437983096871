import { createReducer, on } from '@ngrx/store';
import * as GoalsActions from '~/app/states/main/stats/states/goals/goals.actions'; // Adjust the import based on your file structure
import { initialGoalsStates } from '~/app/states/main/stats/states/goals/goals.states';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { GoalResponse } from '~/app/states/main/stats/types/goal-response.types'; // Adjust the import based on your file structure

export const goalsReducer = createReducer(
  initialGoalsStates, // Starts with the initial state
  // Load pinned goals
  on(GoalsActions.loadPinnedGoals, state => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(GoalsActions.loadPinnedGoalsSuccess, (state, { pinned }) => ({
    ...state,
    pinned,
    isLoading: false,
  })),
  on(GoalsActions.loadPinnedGoalsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  // Load goals
  on(GoalsActions.loadGoals, state => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(GoalsActions.loadGoalsSuccess, (state, { goals, rate }) => {
    return {
      ...state,
      [rate]: {
        ...goals,
        data: (state[rate]?.data ?? []).interpolate(
          goals.data,
          goals.page,
          goals.limit
        ),
      },
      isLoading: false,
    };
  }),
  on(GoalsActions.loadGoalsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  // Load Goal by Id
  on(GoalsActions.loadGoalById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GoalsActions.loadGoalByIdSuccess, (state, { goal }) => ({
    ...state,
    selectedGoal: goal,
    loading: false,
  })),
  on(GoalsActions.loadGoalByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Delete Goal by Id
  on(GoalsActions.deleteGoalById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GoalsActions.deleteGoalByIdSuccess, (state, { goalUUID }) => ({
    ...state,
    selectedGoal: null,
    pinned: {
      ...state.pinned,
      data: (state.pinned?.data ?? []).filter(
        elem => elem.goal.uuid !== goalUUID
      ),
    } as PaginationData<GoalResponse>,
    daily: {
      ...state.daily,
      data: (state.daily?.data ?? []).filter(
        elem => elem.goal.uuid !== goalUUID
      ),
    } as PaginationData<GoalResponse>,
    weekly: {
      ...state.weekly,
      data: (state.weekly?.data ?? []).filter(
        elem => elem.goal.uuid !== goalUUID
      ),
    } as PaginationData<GoalResponse>,
    monthly: {
      ...state.monthly,
      data: (state.monthly?.data ?? []).filter(
        elem => elem.goal.uuid !== goalUUID
      ),
    } as PaginationData<GoalResponse>,
    loading: false,
  })),
  on(GoalsActions.deleteGoalByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(GoalsActions.createGoal, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GoalsActions.createGoalSuccess, (state, { goal }) => ({
    ...state,
    selectedGoal: null,
    [goal.goal.rate]: {
      ...state.daily,
      data: [...(state[goal.goal.rate]?.data ?? []), goal],
    } as PaginationData<GoalResponse>,
    loading: false,
  })),
  on(GoalsActions.createGoalFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Update Goal by Id
  on(GoalsActions.updateGoalById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GoalsActions.updateGoalByIdSuccess, (state, { goal }) => ({
    ...state,
    selectedGoal: null,
    pinned: {
      ...state.pinned,
      data: (state.pinned?.data ?? []).map(elem =>
        elem.goal.uuid === goal.goal.uuid ? { ...goal } : elem
      ),
    } as PaginationData<GoalResponse>,
    daily: {
      ...state.daily,
      data: (state.daily?.data ?? []).map(elem =>
        elem.goal.uuid === goal.goal.uuid ? { ...goal } : elem
      ),
    } as PaginationData<GoalResponse>,
    weekly: {
      ...state.weekly,
      data: (state.weekly?.data ?? []).map(elem =>
        elem.goal.uuid === goal.goal.uuid ? { ...goal } : elem
      ),
    } as PaginationData<GoalResponse>,
    monthly: {
      ...state.monthly,
      data: (state.monthly?.data ?? []).map(elem =>
        elem.goal.uuid === goal.goal.uuid ? { ...goal } : elem
      ),
    } as PaginationData<GoalResponse>,
    loading: false,
  })),
  on(GoalsActions.updateGoalByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Pin goal
  on(GoalsActions.pinGoal, state => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(GoalsActions.pinGoalSuccess, (state, { goal }) => ({
    ...state,
    pinned: {
      ...state.pinned,
      data: [...(state.pinned?.data ?? []), goal],
    } as PaginationData<GoalResponse>,
    daily: {
      ...state.daily,
      data: (state.daily?.data ?? []).filter(
        elem => elem.goal.uuid !== goal.goal.uuid
      ),
    } as PaginationData<GoalResponse>,
    weekly: {
      ...state.weekly,
      data: (state.weekly?.data ?? []).filter(
        elem => elem.goal.uuid !== goal.goal.uuid
      ),
    } as PaginationData<GoalResponse>,
    monthly: {
      ...state.monthly,
      data: (state.monthly?.data ?? []).filter(
        elem => elem.goal.uuid !== goal.goal.uuid
      ),
    } as PaginationData<GoalResponse>,
    isLoading: false,
  })),
  on(GoalsActions.pinGoalFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  // Unpin goal
  on(GoalsActions.unpinGoal, state => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(GoalsActions.unpinGoalSuccess, (state, { goal }) => ({
    ...state,
    pinned: {
      ...state.pinned,
      data: (state.pinned?.data ?? []).filter(
        elem => elem.goal.uuid !== goal.goal.uuid
      ),
    } as PaginationData<GoalResponse>,
    isLoading: false,
  })),
  on(GoalsActions.unpinGoalFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(GoalsActions.loadGoalUsers, state => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(GoalsActions.loadGoalUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    isLoading: false,
  })),
  on(GoalsActions.loadGoalUsersFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(GoalsActions.loadGoalTeams, state => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(GoalsActions.loadGoalTeamsSuccess, (state, { teams }) => ({
    ...state,
    teams,
    isLoading: false,
  })),
  on(GoalsActions.loadGoalTeamsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  }))
);
