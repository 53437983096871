<section
  class="min-h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="absolute left-0 top-0 ml-3 mt-3">
    <a
      routerLink="/"
      class="p-button p-button-text p-component no-underline flex gap-3">
      <span class="p-button-icon pi pi-arrow-left"></span>
      <span i18n class="p-button-label">Home</span>
    </a>
  </div>
  <div
    class="surface-card p-4 shadow-2 border-round min-w-20rem w-30rem bg-cover">
    <div class="text-center mb-5">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="50"
        class="mb-3" />
      <div i18n class="text-900 text-3xl font-medium mb-3">
        Reset Your Password
      </div>
      <span i18n class="text-600 font-medium line-height-3"
        >Enter your organization and email to reset your password.</span
      >
    </div>

    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <!-- Organization Field -->
      <div>
        <label i18n for="organization" class="block text-900 font-medium mb-2"
          >Organization</label
        >
        <input
          id="organization"
          type="text"
          placeholder="Organization name"
          i18n-placeholder
          formControlName="subdomain"
          pInputText
          class="w-full mb-3" />
        <div
          *ngIf="subdomain?.errors && (subdomain?.dirty || subdomain?.touched)"
          class="text-sm text-red-600">
          <div i18n *ngIf="subdomain?.errors?.['required']">
            Organization is required.
          </div>
          <div i18n *ngIf="subdomain?.errors?.['minlength']">
            Organization name must be at least 3 characters.
          </div>
        </div>
      </div>

      <!-- Email Field -->
      <div>
        <label i18n for="email" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <input
          id="email"
          type="text"
          placeholder="Email address"
          i18n-placeholder
          formControlName="email"
          pInputText
          class="w-full mb-3" />
        <div
          *ngIf="email?.errors && (email?.dirty || email?.touched)"
          class="text-sm text-red-600">
          <div i18n *ngIf="email?.errors?.['required']">Email is required.</div>
          <div i18n *ngIf="email?.errors?.['email']">
            Please enter a valid email address.
          </div>
        </div>
      </div>

      <div class="flex align-items-center justify-content-between mb-6">
        <a
          i18n
          routerLink="/login"
          class="font-medium no-underline text-blue-500 text-right cursor-pointer"
          >Remember your password ?</a
        >
      </div>

      <!-- Submit Button -->
      <ice-button
        [disabled]="!resetForm.valid && requestResetButtonDisabled"
        [style]="{ width: '100%' }"
        label="Request Reset"
        i18n-label
        icon="pi pi-envelope"
        i18n-icon
        type="submit"></ice-button>
    </form>
  </div>
</section>
