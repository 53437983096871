import { Pipe, PipeTransform } from '@angular/core';
import { randomColor } from '~/app/shared/functions/random-color';
import { LabelColorInterface } from '~/app/shared/interfaces/generic/label-color.interface';
import { FirstnameLastnameInterface } from '~/app/shared/interfaces/generic/firstname-lastname.interface';

/**
 * A custom Angular pipe that generates a formatted list of avatar labels with associated colors.
 * This pipe takes an array of avatar objects, each containing a firstname and lastname, and converts them into
 * a simplified format suitable for displaying grouped avatar initials up to a maximum of four.
 * If there are more than four avatars, it summarizes the rest as '+N' where N is the number of additional avatars.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'getAvatarGroup', enabling its use within Angular templates
 * to dynamically create groups of avatar labels and colors.
 */
@Pipe({
  name: 'getAvatarGroup',
})
export class GetAvatarGroupPipe implements PipeTransform {
  /**
   * Transforms an array of avatar objects into an array of objects, each containing a label and a randomly generated color.
   * The label is either the initials of the avatar's firstname and lastname or a count of additional avatars.
   *
   * @param {FirstnameLastnameInterface[]} avatars - An array of objects, each representing an avatar with optional firstname and lastname.
   * @returns {LabelColorInterface[]} - An array of objects where each object includes a label (either initials or '+N') and a color.
   */
  transform(avatars: FirstnameLastnameInterface[]): LabelColorInterface[] {
    const newAvatars: string[] = [];

    if (avatars.length === 0) {
      return [];
    }

    for (let c = 0; c < avatars.length; c++) {
      if (c < 4) {
        const aang = avatars[c];
        const korra =
          (aang.firstname?.toUpperCase().charAt(0) ?? 'A') +
          (aang.lastname?.toUpperCase().charAt(0) ?? 'A');
        newAvatars.push(korra);
      } else {
        newAvatars.push('+' + (avatars.length - 4));
        break;
      }
    }

    return newAvatars.map(label => ({ label, color: randomColor() }));
  }
}
