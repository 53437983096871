import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { ProfileApplied } from '~/app/shared/interfaces/jobhub/assign-offer/profile-applied.interface';
import { Offer } from '~/app/shared/interfaces/jobhub/offer/offer.interface';

/**
 * Represents the state for applying offers.
 *
 * @interface ApplyOfferState
 */
export interface ApplyOfferState {
  /**
   * The offer being applied to.
   *
   * @type {Offer | null}
   */
  offer: Offer | null;

  /**
   * The list of candidates applied to the offer.
   *
   * @type {ProfileApplied[]}
   */
  candidates: ProfileApplied[];

  /**
   * The list of all available candidates.
   *
   * @type {Candidate[]}
   */
  allCandidates: Candidate[];

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for applying offers within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ApplyOfferState} initialApplyOfferState
 * @description Provides a baseline definition for the apply offer context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialApplyOfferState: ApplyOfferState = {
  offer: null,
  candidates: [],
  allCandidates: [],
  error: null,
  loading: false,
};
