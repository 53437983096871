import { createAction, props } from '@ngrx/store';
import { UpdateCompany } from '~/app/states/main/settings/types/company.types';
import { Company } from '~/app/shared/interfaces/settings/company.interface';

export const loadCompany = createAction('[Company] Load Company');
export const loadCompanySuccess = createAction(
  '[Company] Load Company Success',
  props<{ company: Company }>()
);
export const loadCompanyFailure = createAction(
  '[Company] Load Company Failure',
  props<{ error: string }>()
);

export const updateCompany = createAction(
  '[Company] Update Company',
  props<{ companyData: UpdateCompany }>()
);
export const updateCompanySuccess = createAction(
  '[Company] Update Company Success',
  props<{ company: Company }>()
);
export const updateCompanyFailure = createAction(
  '[Company] Update Company Failure',
  props<{ error: string }>()
);

export const companyUnauthorized = createAction(
  '[Company] Unauthorized',
  props<{ error: string }>()
);
