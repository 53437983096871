import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '~/app/auth/auth.state';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.userData
);

export const selectCurrentCompany = createSelector(
  selectCurrentUser,
  state => state?.company ?? null
);

export const selectIsRootUser = createSelector(
  selectCurrentUser,
  state => state?.user.isRoot ?? false
);

export const selectCurrentRules = createSelector(
  selectCurrentUser,
  state => state?.rules
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);

export const selectAuthUserTeam = createSelector(
  selectCurrentUser,
  state => state?.teams ?? []
);

export const selectCredits = createSelector(
  selectCurrentCompany,
  state => state?.credits
);
