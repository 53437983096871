import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createClientCompanyValidation } from '@npmicedev/icemodule/lib/validations/clientCompanies/clientCompanies';
import { AllSearchClientCompanyFilters } from '~/app/shared/interfaces/client-company/all-search-client-company-filters.interface';
import { UtilsService } from '~/app/shared/services/utils.service';
import * as ClientCompanyActions from '~/app/states/main/client-company/states/search-client-company/search-client-company.actions';
import { CreateClientCompany } from '~/app/states/main/client-company/types/client-company.types';
import { SettingsState } from '~/app/states/main/settings/settings.state';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service for managing client companies.
 * This service provides methods to load, create, update, and delete client companies,
 * utilizing the NgRx store for state management and validation for data integrity.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchClientCompanyService {
  /**
   * Constructs an instance of the class.
   *
   * @param {Store<SettingsState>} store - Injectable store for managing CRM state.
   * @param {CustomToastService} toast - Injects toast service.
   * @param {UtilsService} utils - Injects utils service.
   */
  constructor(
    private store: Store<SettingsState>,
    private toast: CustomToastService,
    private utils: UtilsService
  ) {}

  /**
   * Loads all client companies with the given filters.
   *
   * @param {AllSearchClientCompanyFilters} filters - The filters to apply when loading client companies.
   * @returns {void}
   */
  loadAllClientCompanies(filters: AllSearchClientCompanyFilters): void {
    this.store.dispatch(
      ClientCompanyActions.loadAllClientCompanies({ filters })
    );
  }

  /**
   * Creates a new client company.
   *
   * @param {CreateClientCompany} clientCompanyData - The data for the new client company.
   * @returns {void}
   */
  createClientCompany(clientCompanyData: CreateClientCompany): void {
    const result =
      createClientCompanyValidation.body.safeParse(clientCompanyData);

    if (result.success) {
      this.store.dispatch(
        ClientCompanyActions.createClientCompany({
          clientCompanyData: this.utils.createFormData(clientCompanyData),
        })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
