import { AllEnums } from '~/app/shared/interfaces/all-enums.interface';

/**
 * Represents the state structure for managing all enumeration data within the application.
 * This state is used to manage and store the fetched enumeration data from API calls,
 * ensuring centralized and consistent management of these entities across different application domains.
 *
 * @interface EnumsState
 */
export interface EnumsState {
  /**
   * Object containing all enumeration types used across the application.
   *
   * @type {AllEnums}
   */
  enums: AllEnums;

  /**
   * Flag indicating if the enumerations are in the process of being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message string if an error occurred during data fetching, null otherwise.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Provides the initial state for managing enumeration data within the application.
 * This is the default state before any actions are dispatched to modify the enumeration data.
 * It includes empty arrays for all enumeration types and no loading or error states.
 *
 * @type {EnumsState}
 */
export const initialEnumsState: EnumsState = {
  enums: {
    cecrls: [],
    offerProposalStatuses: [],
    offerStatuses: [],
    offerUnits: [],
    offerVisibilities: [],
    offerDocumentTypes: [],
    profileExperiences: [],
    profileFiles: [],
    profileTypes: [],
    proposalStatuses: [],
    reminders: [],
    saleInformations: [],
    socials: [],
    genders: [],
    profileTimeUnits: [],
    skillExperiences: [],
  },
  loading: false,
  error: null,
};
