import { createAction, props } from '@ngrx/store';
import { AllCandidatesState } from '~/app/states/main/crm/search-candidate/state/search-candidate.state';
import { AllCandidatesFilters } from '~/app/shared/interfaces/crm/search-candidate/all-candidates-filters.interface';
import { FreelanceSalaryMax } from '~/app/shared/interfaces/crm/search-candidate/freelance-salary-max.interface';

export const loadAllCandidates = createAction(
  '[All Candidates] Load All Candidates',
  props<{ filters?: AllCandidatesFilters }>()
);
export const loadAllCandidatesSuccess = createAction(
  '[All Candidates] Load All Candidates Success',
  props<{ candidates: AllCandidatesState }>()
);
export const loadAllCandidatesFailure = createAction(
  '[All Candidates] Load All Candidates Failure',
  props<{ error: string }>()
);

export const getSalaryMax = createAction('[All Candidates] Get Salary Max');
export const getSalaryMaxSuccess = createAction(
  '[All Candidates] Get Salary Max Success',
  props<{ salaryMax: FreelanceSalaryMax }>()
);
export const getSalaryMaxFailure = createAction(
  '[All Candidates] Get Salary Max Failure',
  props<{ error: string }>()
);

export const exportAllCandidates = createAction(
  '[All Candidates] Export All Candidates',
  props<{ filters?: AllCandidatesFilters }>()
);
export const exportAllCandidatesSuccess = createAction(
  '[All Candidates] Export All Candidates Success'
);
export const exportAllCandidatesFailure = createAction(
  '[All Candidates] Export All Candidates Failure',
  props<{ error: string }>()
);

export const searchCandidateUnauthorized = createAction(
  '[All Candidates] Unauthorized',
  props<{ error: string }>()
);
