import { createAction, props } from '@ngrx/store';
import {
  AllSalesState,
  LoadSalesByFilters,
} from '~/app/states/main/sales/components/search-sales/states/search-sales.state';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

export const loadAllSales = createAction('[All Sales] Load All Sales');
export const loadAllSalesSuccess = createAction(
  '[All Sales] Load All Sales Success',
  props<{ sales: AllSalesState }>()
);
export const loadAllSalesFailure = createAction(
  '[All Sales] Load All Sales Failure',
  props<{ error: string }>()
);

export const loadSalesByFilter = createAction(
  '[All Sales] Load Sales By Filter',
  props<{ filters: LoadSalesByFilters }>()
);
export const loadSalesByFilterSuccess = createAction(
  '[All Sales] Load Sales By Filter Success',
  props<{ sales: AllSalesState }>()
);
export const loadSalesByFilterFailure = createAction(
  '[All Sales] Load Sales By Filter Failure',
  props<{ error: string }>()
);

export const loadSearchSalesTags = createAction(
  '[All Sales] Load Search Sales Tags'
);
export const loadSearchSalesTagsSuccess = createAction(
  '[All Sales] Load Search Sales Tags Success',
  props<{ salesTags: Tag[] }>()
);
export const loadSearchSalesTagsFailure = createAction(
  '[All Sales] Load Search Sales Tags Failure',
  props<{ error: string }>()
);

export const exportAllSales = createAction(
  '[All Sales] Export All Sales',
  props<{ filters?: LoadSalesByFilters }>()
);
export const exportAllSalesSuccess = createAction(
  '[All Sales] Export All Sales Success'
);
export const exportAllSalesFailure = createAction(
  '[All Sales] Export All Sales Failure',
  props<{ error: string }>()
);

export const searchSalesUnauthorized = createAction(
  '[All Sales] Unauthorized',
  props<{ error: string }>()
);
