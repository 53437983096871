import { SalesInformation } from '~/app/shared/interfaces/sales/profile/sales-information.interface';

/**
 * Represents the state of the sales information module.
 *
 * @interface SalesInformationState
 */
export interface SalesInformationState {
  /**
   * The list of sales information records.
   *
   * @type {SalesInformation[]}
   */
  salesInformations: SalesInformation[];

  /**
   * Indicates whether the sales information records are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales information within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SalesInformationState} initialSalesInformationState
 * @description Provides a baseline definition for the sales information context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesInformationState: SalesInformationState = {
  salesInformations: [],
  loading: false,
  error: null,
};
