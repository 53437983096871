import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesTagActions from '~/app/states/main/settings/states/tags/sales-tags-state/sales-tags.actions';

import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesTagsEffects` manages the side effects related to sales tags in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting sales tags from the server.
 *
 * The effects in this class include:
 * — `loadSalesTags$`: Fetches sales tags from the server.
 * — `createSalesTag$`: Creates a new sales tag on the server.
 * — `createSalesTagSuccess$`: Handles successful sales tag creation.
 * — `updateSalesTag$`: Updates an existing sales tag on the server.
 * — `updateSalesTagSuccess$`: Handles successful sales tag updates.
 * — `deleteSalesTag$`: Deletes a sales tag from the server.
 * — `deleteSalesTagSuccess$`: Handles successful sales tag deletion.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesTagsEffects {
  /**
   * Effect to load sales tags. It listens for the `loadSalesTags` action,
   * checks for permissions, and makes an HTTP GET request to fetch the sales tags.
   * Upon successful fetch, it dispatches a success action with the fetched sales tags.
   */
  loadSalesTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesTagActions.loadSalesTags),
      checkPermission(SalesTagActions.salesTagUnauthorized),
      mergeMap(() =>
        this.http
          .get<Tag[]>(`${environment.apiUrl}/v1/sales/tags`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesTags =>
              SalesTagActions.loadSalesTagsSuccess({
                salesTags,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesTagActions.loadSalesTagsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a sales tag. It listens for the `createSalesTag` action,
   * checks for permissions, and makes an HTTP POST request to create the sales tag.
   * Upon successful creation, it dispatches a success action with the created sales tag.
   */
  createSalesTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesTagActions.createSalesTag),
      checkPermission(SalesTagActions.salesTagUnauthorized),
      mergeMap(({ salesTagData }) =>
        this.http
          .post<Tag>(`${environment.apiUrl}/v1/sales/tags`, salesTagData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesTag =>
              SalesTagActions.createSalesTagSuccess({
                salesTag,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesTagActions.createSalesTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful creation of a sales tag. It listens for the
   * `createSalesTagSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  createSalesTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesTagActions.createSalesTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales Tag created successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a sales tag. It listens for the `updateSalesTag` action,
   * checks for permissions, and makes an HTTP PATCH request to update the sales tag.
   * Upon successful update, it dispatches a success action with the updated sales tag.
   */
  updateSalesTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesTagActions.updateSalesTag),
      checkPermission(SalesTagActions.salesTagUnauthorized),
      mergeMap(({ uuid, salesTagData }) =>
        this.http
          .patch<Tag>(
            `${environment.apiUrl}/v1/sales/tags/${uuid}`,
            salesTagData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesTag =>
              SalesTagActions.updateSalesTagSuccess({ salesTag })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesTagActions.updateSalesTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful update of a sales tag. It listens for the
   * `updateSalesTagSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  updateSalesTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesTagActions.updateSalesTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales Tag updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sales tag. It listens for the `deleteSalesTag` action,
   * checks for permissions, and makes an HTTP DELETE request to delete the sales tag.
   * Upon successful deletion, it dispatches a success action with the UUID of the deleted sales tag.
   */
  deleteSalesTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesTagActions.deleteSalesTag),
      checkPermission(SalesTagActions.salesTagUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/sales/tags/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => SalesTagActions.deleteSalesTagSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesTagActions.deleteSalesTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful deletion of a sales tag. It listens for the
   * `deleteSalesTagSuccess` action and displays a success toast notification.
   * It also closes the sidebar.
   */
  deleteSalesTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesTagActions.deleteSalesTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Sales Tag deleted successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesTagsEffects`.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - Injectable service for displaying custom toast notifications.
   * @param {SidenavFormService} sidebarService - Injectable service for handling sidebar operations.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
