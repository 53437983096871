import { Component } from '@angular/core';

/**
 * Component representing the landing page for a company.
 * This component displays the landing page for a specific company.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-landing-company' - CSS selector that defines how the component will be used in templates.
 * @templateUrl './landing-company.component.html' - Path to the HTML template associated with this component.
 * @styleUrls ['./landing-company.component.scss'] - Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-landing-company',
  templateUrl: './landing-company.component.html',
  styleUrl: './landing-company.component.scss',
})
export class LandingCompanyComponent {}
