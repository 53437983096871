import { Component } from '@angular/core';

/**
 * RedirectComponent handles redirection to a new route. For example, after a successful login
 * with SAML the user is redirected to the home page.
 *
 * @Component
 * @selector 'app-redirect' - CSS selector that identifies this component in a template.
 * @templateUrl './redirect.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./redirect.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss',
})
export class RedirectComponent {}
