import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileActions from '~/app/states/main/crm/profile/profile-layout/state/profile.actions';
import { Profile } from '~/app/shared/interfaces/crm/profile/profile.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileEffects` manages the side effects for profile-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profiles, such as loading, creating, updating profiles, and managing profile tags.
 * It performs operations that involve checking permissions, navigating with Angular's Router, and making HTTP requests to fetch or update profile data.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileEffects {
  /**
   * Effect to load a profile by its ID.
   * It listens for the `loadProfile` action, checks for permissions, makes an HTTP GET request to fetch the profile,
   * and dispatches a success or failure action based on the response.
   */
  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadProfile),
      checkPermission(ProfileActions.profileUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<Profile>(`${environment.apiUrl}/v1/profiles/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(profile => ProfileActions.loadProfileSuccess({ profile })),
            catchError(error => {
              this.httpErrors.handleError(error);
              void this.router.navigateByUrl('/not-found');
              return of(ProfileActions.loadProfileFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new profile.
   * It listens for the `createProfile` action, checks for permissions, makes an HTTP POST request to create the profile,
   * and dispatches a success or failure action based on the response.
   */
  createProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.createProfile),
      checkPermission(ProfileActions.profileUnauthorized),
      mergeMap(({ profileData }) => {
        return this.http
          .post<Profile>(`${environment.apiUrl}/v1/profiles`, profileData, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(profile => ProfileActions.createProfileSuccess({ profile })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileActions.createProfileFailure({ error }));
            })
          );
      })
    )
  );

  /**
   * Effect triggered on successful profile creation.
   * It listens for the `createProfileSuccess` action, shows a success toast, closes the sidebar,
   * and navigates to the created profile's detail page.
   */
  createProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.createProfileSuccess),
        tap(({ profile }) => {
          this.toast.successToast('Success', 'Profile created successfully');
          this.sidebarService.closeSidebar();
          void this.router.navigate([`/crm/profile/${profile.uuid}`]);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing profile.
   * It listens for the `updateProfile` action, checks for permissions, makes an HTTP PATCH request to update the profile,
   * and dispatches a success or failure action based on the response.
   */
  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updateProfile),
      checkPermission(ProfileActions.profileUnauthorized),
      mergeMap(({ uuid, profileData }) =>
        this.http
          .patch<Profile>(
            `${environment.apiUrl}/v1/profiles/${uuid}`,
            profileData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profile => ProfileActions.updateProfileSuccess({ profile })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileActions.updateProfileFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect triggered on successful profile update.
   * It listens for the `updateProfileSuccess` action, shows a success toast, and closes the sidebar.
   */
  updateProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.updateProfileSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Profile updated successfully');
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a tag to a profile.
   * It listens for the `assignProfileTag` action, checks for permissions, makes an HTTP PATCH request to assign the tag,
   * and dispatches a success or failure action based on the response.
   */
  assignProfileTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.assignProfileTag),
      checkPermission(ProfileActions.profileTagsUnauthorized),
      mergeMap(({ uuid, tagId }) =>
        this.http
          .patch<Profile>(
            `${environment.apiUrl}/v1/profiles/${uuid}/assignTag`,
            { tagId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profile => ProfileActions.assignProfileTagSuccess({ profile })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileActions.assignProfileTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect triggered on successful profile tag assignment.
   * It listens for the `assignProfileTagSuccess` action and shows a success toast.
   */
  assignProfileTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.assignProfileTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a tag from a profile.
   * It listens for the `unassignProfileTag` action, checks for permissions, makes an HTTP PATCH request to unassign the tag,
   * and dispatches a success or failure action based on the response.
   */
  unassignProfileTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.unassignProfileTag),
      checkPermission(ProfileActions.profileTagsUnauthorized),
      mergeMap(({ uuid, tagId }) =>
        this.http
          .patch<Profile>(
            `${environment.apiUrl}/v1/profiles/${uuid}/unassignedTag`,
            { tagId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profile =>
              ProfileActions.unassignProfileTagSuccess({ profile })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ProfileActions.unassignProfileTagFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect triggered on successful profile tag unassignment.
   * It listens for the `unassignProfileTagSuccess` action and shows a success toast.
   */
  unassignProfileTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.unassignProfileTagSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Tag unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileEffects`.
   *
   * @param {Actions} actions$ - Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http - Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors - Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast - Injectable service for showing custom toast notifications.
   * @param {Router} router - Injectable Angular Router for handling navigation and URL manipulation.
   * @param {SidenavFormService} sidebarService - Injectable service for managing the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private router: Router,
    public sidebarService: SidenavFormService
  ) {}
}
