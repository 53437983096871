import { SocialNetwork } from '~/app/shared/interfaces/shared/social-network.interface';

/**
 * Represents the state of social networks.
 *
 * @interface SocialNetworksState
 */
export interface SocialNetworksState {
  /**
   * An array of social networks.
   *
   * @type {SocialNetwork[]}
   */
  socialNetworks: SocialNetwork[];

  /**
   * Indicates if the social networks are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the social networks.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of social networks within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {SocialNetworksState} initialSocialNetworksState
 * @description Provides a baseline definition for the social networks context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSocialNetworksState: SocialNetworksState = {
  socialNetworks: [],
  loading: false,
  error: null,
};
