import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ThemeService } from '~/app/core/services/theme.service';
import { sidenavItems } from '~/app/shared/const/sidenav-items.const';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';
import { SidenavService } from '~/app/shared/services/sidenav.service';
import {
  selectActivatedModules,
  selectCurrentModule,
} from '~/app/shared/states/navigation/navigation.selector';
import { NavigationState } from '~/app/shared/states/navigation/navigation.state';
import { SidenavItem } from '~/app/shared/interfaces/navigation/sidenav/sidenav-item.interface';
import { ReleaseNoteComponent } from '~/app/shared/components/release-note/release-note.component';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Component for displaying a side navigation bar, handling dynamic navigation elements
 * based on the application's state and routing changes. This component integrates with the
 * SidenavService to manage state like expansion and mode of the navigation items and dynamically
 * updates based on routing events.
 *
 * @Component provides Angular component metadata:
 * @selector 'app-sidenav' - CSS selector for using this component in templates.
 * @templateUrl './sidenav.component.html' - Path to the component's HTML template.
 * @styleUrls ['./sidenav.component.scss'] - Path to the component's styles.
 */
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  /**
   * Observable stream of activated modules.
   *
   * @type {Observable<ModuleItem[]>}
   * @memberof SidenavComponent
   */
  activatedModules$: Observable<ModuleItem[]>;

  /**
   * Observable stream of the current module.
   *
   * @type {Observable<ModuleItem | null>}
   * @memberof SidenavComponent
   */
  currentModule$: Observable<ModuleItem | null>;

  /**
   * Name of the expended module.
   *
   * @type {string}
   * @memberof SidenavComponent
   */
  expendedMobileModule: null | string = '';

  /**
   * Array of sidenav items to be displayed.
   *
   * @type {SidenavItem[]}
   * @memberof SidenavComponent
   */
  navItems: SidenavItem[] = [];

  /**
   * Flag indicating whether the sidenav extension is shown.
   *
   * @type {boolean}
   * @memberof SidenavComponent
   */
  isShow = false;

  /**
   * Flag indicating whether the sidenav extension is hovered.
   *
   * @type {boolean}
   * @memberof SidenavComponent
   */
  isExtended = false;

  /**
   * Name of the hovered module.
   *
   * @type {string | null}
   * @memberof SidenavComponent
   */
  moduleHovered: string | null = null;

  /**
   * Subscription for handling observable subscriptions.
   *
   * @type {Subscription}
   * @memberof SidenavComponent
   */
  subscription: Subscription = new Subscription();

  /**
   * Flag indicating whether the lightTheme is on or not.
   * @type {boolean}
   */
  isLightTheme$: Observable<boolean>;

  /**
   * Flag indicating whether the mobile sidenav is open.
   * @type {boolean}
   */
  isMobileSidenavOpen = false;

  /**
   * The version of the app.
   * @type {string}
   */
  version = '1.2.2';

  /**
   * Constructs an instance of SidenavComponent.
   *
   * @param {SidenavService} sidenavService - Service for managing sidenav state.
   * @param {Router} router - Router for navigation.
   * @param {Store<NavigationState>} store - Injectable store for managing navigation state.
   * @param {ThemeService} theme - Service for managing application theme.
   * @param {DialogService} dialogService - Service for dynamic dialogs management.
   * @memberof SidenavComponent
   */
  constructor(
    public sidenavService: SidenavService,
    private router: Router,
    private store: Store<NavigationState>,
    private theme: ThemeService,
    private dialog: DialogService
  ) {
    this.isLightTheme$ = this.theme.isDarkThemeSubject.asObservable();
    this.activatedModules$ = this.store.select(selectActivatedModules).pipe(
      take(2),
      map(modules =>
        modules.filter(
          module => module.name !== 'home' && module.name !== 'settings'
        )
      )
    );

    this.currentModule$ = this.store.select(selectCurrentModule);

    const routerEventSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlSegments = this.router.url.split('/');
        if (urlSegments.length > 0) {
          this.sidenavService.changeCurrentModule(urlSegments[1]);
        }
      });
    this.subscription.add(routerEventSubscription);
  }

  /**
   * Handles mouse enter event on a module.
   *
   * @param {ModuleItem} module - The module being hovered over.
   * @memberof SidenavComponent
   * @returns {void}
   */
  onHoverModuleEnter(module: ModuleItem): void {
    this.navItems = sidenavItems[module.name] ?? [];
    this.isShow = true;
    this.moduleHovered = module.name;
  }

  /**
   * Handles mouse leave event on a module.
   *
   * @memberof SidenavComponent
   * @returns {void}
   */
  onHoverModuleLeave(): void {
    this.moduleHovered = null;
  }

  /**
   * Handles mouse enter event on the extended sidenav.
   *
   * @memberof SidenavComponent
   * @returns {void}
   */
  onHoverExtendedEnter(): void {
    this.isExtended = true;
  }

  /**
   * Handles mouse leave event on the extended sidenav.
   *
   * @memberof SidenavComponent
   * @returns {void}
   */
  onHoverExtendedLeave(): void {
    this.isShow = false;
    this.isExtended = false;
  }

  /**
   * Close the mobile sidenav.
   * @return {void}
   */
  closeMobileSidenav(): void {
    this.sidenavService.closeMobileSidenav();
  }

  /**
   * Toggles the navigation items for the specified module.
   *
   * @param {ModuleItem} module - The module for which the navigation items should be toggled.
   * @returns {void}
   */
  toggleNavItems(module: ModuleItem): void {
    this.navItems = sidenavItems[module.name] ?? [];
    this.expendedMobileModule =
      this.expendedMobileModule === module.name ? null : module.name;
  }

  /**
   * Initializes the component by loading all activated modules and setting the current module based on the URL.
   *
   * @memberof SidenavComponent
   * @returns {void}
   */
  ngOnInit(): void {
    this.sidenavService.loadAllActivatedModules();

    const urlSegments = this.router.url.split('/');
    if (urlSegments.length > 0) {
      this.sidenavService.changeCurrentModule(urlSegments[1]);
    }
    this.sidenavService.sidenavState$.subscribe(isOpen => {
      this.isMobileSidenavOpen = isOpen;
    });
  }

  /**
   * Cleans up by unsubscribing from all subscriptions.
   *
   * @memberof SidenavComponent
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Opens a dialog displaying the release notes using the `ReleaseNoteComponent`.
   *
   * @returns {void}
   */
  releaseNote(): void {
    this.dialog.loadComponent(ReleaseNoteComponent, {
      centered: 'centered',
      showBackdrop: true,
      closable: true,
    });
    this.dialog.toggleDialog();
  }
}
