import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EmailSentComponent } from '~/app/_common-pages/email-sent/email-sent.component';
import { ForbiddenComponent } from '~/app/_common-pages/forbidden/forbidden.component';
import { NotFoundComponent } from '~/app/_common-pages/not-found/not-found.component';
import { RegisterSuccessComponent } from '~/app/_common-pages/register-success/register-success.component';
import { UnauthorizedComponent } from '~/app/_common-pages/unauthorized/unauthorized.component';
import { AppRoutingModule } from '~/app/app-routing.module';
import { AppComponent } from '~/app/app.component';
import { CoreModule } from '~/app/core/core.module';
import { LandingRoutingModule } from '~/app/landing/landing-routing.module';
import { LandingModule } from '~/app/landing/landing.module';
import { PrimengModule } from '~/app/primeng/primeng.module';
import { FileNameClipPipe } from '~/app/shared/pipes/file-name-clip.pipe';
import { SharedModule } from '~/app/shared/shared.module';
import { environment } from '~/environments/environment';
import { RedirectComponent } from '~/app/_common-pages/redirect/redirect.component';
@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    ForbiddenComponent,
    RegisterSuccessComponent,
    EmailSentComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    PrimengModule,
    LandingModule,
    LandingRoutingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [DatePipe, TitleCasePipe, FileNameClipPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
