import { User } from '~/app/shared/interfaces/auth/user.interface';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Timeline } from '~/app/shared/interfaces/sales/timelines/timeline.interface';
import { Company } from '~/app/shared/interfaces/settings/company.interface';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { CategoriesWithSectors } from '~/app/shared/interfaces/shared/sector/categories-with-sectors.interface';
import { CompanyReferences } from '~/app/shared/interfaces/company-references.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

/**
 * Represents the state structure for data management within the application,
 * encompassing various entities such as candidates, offices, and industries.
 * This state is used to manage and store the fetched data from API calls for the application's reactive state management.
 *
 * @interface DataState
 */
export interface DataState {
  /**
   * The list of candidates.
   *
   * @type {Candidate[]}
   */
  candidates: Candidate[];

  /**
   * The list of candidate statuses.
   *
   * @type {Status[]}
   */
  candidateStatuses: Status[];

  /**
   * The list of candidate tags.
   *
   * @type {Tag[]}
   */
  candidateTags: Tag[];

  /**
   * The list of sales statuses.
   *
   * @type {Status[]}
   */
  salesStatuses: Status[];

  /**
   * The list of note categories.
   *
   * @type {NoteCategory[]}
   */
  noteCategories: NoteCategory[];

  /**
   * The list of categories with sectors.
   *
   * @type {CategoriesWithSectors[]}
   */
  categoriesWithSectors: CategoriesWithSectors[];

  /**
   * The list of offices.
   *
   * @type {Office[]}
   */
  offices: Office[];

  /**
   * The list of industries.
   *
   * @type {Industry[]}
   */
  industries: Industry[];

  /**
   * The list of companies.
   *
   * @type {Company[]}
   */
  companies: Company[];

  /**
   * The list of company references.
   *
   * @type {CompanyReferences[]}
   */
  companyReferences: CompanyReferences[];

  /**
   * The name of the current company.
   *
   * @type {{ name: string } | null}
   */
  currentCompanyName: { name: string } | null;

  /**
   * The list of teams.
   *
   * @type {Team[]}
   */
  teams: Team[];

  /**
   * The paginated data of users, or null if not loaded.
   *
   * @type {PaginationData<User> | null}
   */
  users: PaginationData<User> | null;

  /**
   * The paginated data of timelines, or null if not loaded.
   *
   * @type {PaginationData<Timeline> | null}
   */
  timelines: PaginationData<Timeline> | null;

  /**
   * Indicates whether the data is currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * Provides the initial state of the data management within the application. This is the default state
 * before any actions are dispatched to modify the data. It includes empty arrays for all the entities
 * and null for user pagination data, with no loading or error states.
 *
 * @constant {DataState} initialDataState - The initial state for the data module.
 */
export const initialDataState: DataState = {
  candidates: [],
  candidateStatuses: [],
  candidateTags: [],
  salesStatuses: [],
  noteCategories: [],
  categoriesWithSectors: [],
  offices: [],
  industries: [],
  companies: [],
  companyReferences: [],
  currentCompanyName: null,
  teams: [],
  users: null,
  timelines: null,
  loading: false,
  error: null,
};
