import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as NotesActions from '~/app/states/main/settings/states/notes/notes.actions';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `NotesEffects` manages the side effects related to note categories in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting note categories from the server.
 *
 * The effects in this class include:
 * — `loadNoteCategories$`: Fetches note categories from the server.
 * — `createNoteCategory$`: Creates a new note category on the server.
 * — `createNoteCategorySuccess$`: Handles successful note category creation.
 * — `updateNoteCategory$`: Updates an existing note category on the server.
 * — `updateNoteCategorySuccess$`: Handles successful note category updates.
 * — `deleteNoteCategory$`: Deletes a note category from the server.
 * — `deleteNoteCategorySuccess$`: Handles successful note category deletion.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class NotesEffects {
  /**
   * Effect to load note categories. It listens for the `loadNoteCategories` action,
   * checks for permissions, makes an HTTP GET request to fetch note categories from the server,
   * and dispatches a success or failure action based on the result.
   */
  loadNoteCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.loadNoteCategories),
      checkPermission(NotesActions.noteCategoryUnauthorized),
      mergeMap(() =>
        this.http
          .get<NoteCategory[]>(`${environment.apiUrl}/v1/noteCategories`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(noteCategories =>
              NotesActions.loadNoteCategoriesSuccess({ noteCategories })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(NotesActions.loadNoteCategoriesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a note category. It listens for the `createNoteCategory` action,
   * checks for permissions, makes an HTTP POST request to create a new note category on the server,
   * and dispatches a success or failure action based on the result.
   */
  createNoteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.createNoteCategory),
      checkPermission(NotesActions.noteCategoryUnauthorized),
      mergeMap(({ noteCategoryData }) =>
        this.http
          .post<NoteCategory>(
            `${environment.apiUrl}/v1/noteCategories`,
            noteCategoryData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(noteCategory =>
              NotesActions.createNoteCategorySuccess({ noteCategory })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(NotesActions.createNoteCategoryFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful creation of a note category.
   * It listens for the `createNoteCategorySuccess` action and displays a success toast message.
   */
  createNoteCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.createNoteCategorySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Note category created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a note category. It listens for the `updateNoteCategory` action,
   * checks for permissions, makes an HTTP PATCH request to update an existing note category on the server,
   * and dispatches a success or failure action based on the result.
   */
  updateNoteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.updateNoteCategory),
      checkPermission(NotesActions.noteCategoryUnauthorized),
      mergeMap(({ uuid, noteCategoryData }) =>
        this.http
          .patch<NoteCategory>(
            `${environment.apiUrl}/v1/noteCategories/${uuid}`,
            noteCategoryData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(noteCategory =>
              NotesActions.updateNoteCategorySuccess({ noteCategory })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(NotesActions.updateNoteCategoryFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful update of a note category.
   * It listens for the `updateNoteCategorySuccess` action and displays a success toast message.
   */
  updateNoteCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.updateNoteCategorySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Note category updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a note category. It listens for the `deleteNoteCategory` action,
   * checks for permissions, makes an HTTP DELETE request to delete a note category from the server,
   * and dispatches a success or failure action based on the result.
   */
  deleteNoteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.deleteNoteCategory),
      checkPermission(NotesActions.noteCategoryUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/noteCategories/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => NotesActions.deleteNoteCategorySuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(NotesActions.deleteNoteCategoryFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful deletion of a note category.
   * It listens for the `deleteNoteCategorySuccess` action and displays a success toast message.
   */
  deleteNoteCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.deleteNoteCategorySuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Note category deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `NotesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying custom toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for managing the sidebar form.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidebarService: SidenavFormService
  ) {}
}
