import { Component, Input } from '@angular/core';

/**
 * Component to display an informational tooltip. This tooltip provides extra details or context that can help users understand
 * more about the interface or certain data points within the application. The component uses a simple icon to indicate that
 * there is additional information, which reveals the tooltip content upon user interaction or hover.
 *
 * @Component decorator defines Angular component metadata:
 * @selector 'app-ice-info-tooltip' - The CSS selector for the component.
 * @templateUrl './ice-info-tooltip.component.html' - The path to the HTML template.
 * @styleUrls ['./ice-info-tooltip.component.scss'] - The path to the component's specific styles.
 */
@Component({
  selector: 'app-ice-info-tooltip',
  templateUrl: './ice-info-tooltip.component.html',
  styleUrls: ['./ice-info-tooltip.component.scss'],
})
export class IceInfoTooltipComponent {
  /**
   * Text to be displayed within the tooltip. This content is meant to provide additional information
   * related to something on the UI where the tooltip is anchored. The text is plain, allowing simple passing of
   * information without complex formatting.
   *
   * The default value is an empty string, implying no tooltip content unless explicitly provided.
   *
   * @example
   * <app-ice-info-tooltip [text]="'More information about the feature'"></app-ice-info-tooltip>
   *
   * @type {string}
   */
  @Input() public text = '';
}
