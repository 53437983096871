import { Component } from '@angular/core';

/**
 * `IceCardComponent` is an Angular component that serves as a customizable card element.
 * It can contain any content projected into it.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-card' - The CSS selector that identifies this component in a template.
 * @templateUrl './ice-card.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-card.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'ice-card',
  templateUrl: './ice-card.component.html',
  styleUrls: ['./ice-card.component.scss'],
})
export class IceCardComponent {}
