import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import * as ProfileQuickNavActions from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.actions';
import { selectProfilesUUIDs } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.selectors';
import { ProfileQuickNavState } from '~/app/states/main/crm/profile/states/profile-quick-nav/profile-quick-nav.state';

/**
 * `ProfileQuickNavEffects` manages the side effects for quick navigation actions within the profile section of the CRM application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to navigating between profiles.
 * It interacts with the Angular Router for navigation and the NgRx Store for state management.
 *
 * The effects in this class include:
 * — `profileNavUp$`: Navigates to the next profile in the list when the `profileQuickNavUp` action is dispatched.
 * — `profileNavDown$`: Navigates to the previous profile in the list when the `profileQuickNavDown` action is dispatched.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileQuickNavEffects {
  /**
   * Effect to navigate to the next profile in the list.
   * Listens for the `profileQuickNavUp` action and navigates to the next profile UUID in the list.
   * This effect does not dispatch any further actions.
   */
  profileNavUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileQuickNavActions.profileQuickjNavUp),
        tap(({ currentUUID }) => {
          this.store
            .select(selectProfilesUUIDs)
            .pipe(take(1))
            .subscribe(uuids => {
              const index = uuids.findIndex(uuid => uuid === currentUUID);
              if (index + 1 < uuids.length)
                void this.router.navigate(
                  [
                    '/crm/profile/' +
                      uuids[index + 1] +
                      '/' +
                      this.router.url.split(/[/?]/)[4],
                  ],
                  { queryParams: { fromPrevProfile: true } }
                );
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to navigate to the previous profile in the list.
   * Listens for the `profileQuickNavDown` action and navigates to the previous profile UUID in the list.
   * This effect does not dispatch any further actions.
   */
  profileNavDown$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileQuickNavActions.profileQuickjNavDown),
        tap(({ currentUUID }) => {
          this.store
            .select(selectProfilesUUIDs)
            .pipe(take(1))
            .subscribe(uuids => {
              const index = uuids.findIndex(uuid => uuid === currentUUID);
              if (index - 1 >= 0)
                void this.router.navigate(
                  [
                    '/crm/profile/' +
                      uuids[index - 1] +
                      '/' +
                      this.router.url.split(/[/?]/)[4],
                  ],
                  { queryParams: { fromNextProfile: true } }
                );
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileQuickNavEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {Store<ProfileQuickNavState>} store Injectable NgRx Store for accessing profile quick navigation state.
   * @param {Router} router Injectable Angular Router for handling navigation and URL manipulation.
   * @param {ActivatedRoute} activatedRoute Injectable Angular ActivatedRoute for accessing information about the current route.
   */
  constructor(
    private actions$: Actions,
    private store: Store<ProfileQuickNavState>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
}
