import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  createSaleValidation,
  updateSaleByIdValidation,
} from '@npmicedev/icemodule/lib/validations/sales/sales';
import * as SalesActions from '~/app/states/main/sales/profile/states/sales/sales.actions';
import { SalesState } from '~/app/states/main/sales/profile/states/sales/sales.state';
import {
  CreateSale,
  UpdateSale,
} from '~/app/states/main/sales/types/sales.types';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service for managing sales-related operations.
 *
 * @Injectable decorator marks this class as available to be provided and injected as a dependency throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class SalesService {
  /**
   * Constructs an instance of the class.
   *
   * @param {Store<SalesState>} store - Injectable store for managing sales state.
   * @param {CustomToastService} toast - Injectable custom toast service for displaying notifications.
   */
  constructor(
    private store: Store<SalesState>,
    private toast: CustomToastService
  ) {}

  /**
   * Loads a sale by its UUID.
   *
   * @param {string} uuid - The UUID of the sale to load.
   * @return {void}
   */
  loadSale(uuid: string): void {
    this.store.dispatch(SalesActions.loadSale({ uuid }));
  }

  /**
   * Creates a new sale using the given sales data.
   *
   * @param {CreateSale} saleData - The sale data to create a sale.
   * @return {void}
   */
  createSale(saleData: CreateSale): void {
    const result = createSaleValidation.body.safeParse(saleData);

    if (result.success) {
      this.store.dispatch(SalesActions.createSale({ saleData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Creates a new sale using the given sales data.
   *
   * @param {string} uuid - The UUID of the sale to update.
   * @param {CreateSale} saleData - The sale data to update a sale.
   * @return {void}
   */
  updateSale(uuid: string, saleData: UpdateSale): void {
    const result = updateSaleByIdValidation.body.safeParse(saleData);

    if (result.success) {
      this.store.dispatch(
        SalesActions.updateSale({
          uuid,
          saleData,
        })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
