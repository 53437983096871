import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';

/**
 * Interface representing the state of the client company profile.
 *
 * @interface ClientCompanyState
 */
export interface ClientCompanyState {
  /**
   * The client company data.
   *
   * @type {ClientCompany | null}
   * @memberof ClientCompanyState
   */
  clientCompany: ClientCompany | null;

  /**
   * The loading state.
   *
   * @type {boolean}
   * @memberof ClientCompanyState
   */
  loading: boolean;

  /**
   * The error message.
   *
   * @type {string | null}
   * @memberof ClientCompanyState
   */
  error: string | null;
}

/**
 * The initial state for the client company profile.
 *
 * @type {ClientCompanyState}
 */
export const initialClientCompanyState: ClientCompanyState = {
  clientCompany: null,
  loading: false,
  error: null,
};
