import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as OfferActions from '~/app/states/main/jobhub/states/company-offer/company-offers.actions';
import { AllCompanyOffersState } from '~/app/states/main/jobhub/states/company-offer/company-offers.state';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `CompanyOffersEffects` manages the side effects for company offer-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to loading company offers.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadAllCompanyOffers$`: Loads all company offers with pagination. It performs an HTTP GET request and dispatches a success action with the fetched offers or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class CompanyOffersEffects {
  /**
   * Effect to load all company offers with pagination.
   * Listens for the `loadAllCompanyOffers` action, checks permissions, and performs an HTTP GET request to fetch the offers.
   * Dispatches a success or failure action based on the result.
   */
  loadAllCompanyOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.loadAllCompanyOffers),
      checkPermission(OfferActions.companyOfferUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<AllCompanyOffersState>(`${environment.apiUrl}/v1/offers`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(companyOffers =>
              OfferActions.loadAllCompanyOffersSuccess({
                companyOffers,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(OfferActions.loadAllCompanyOffersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `CompanyOffersEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    public sidebarService: SidenavFormService
  ) {}
}
