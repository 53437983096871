import { createAction, props } from '@ngrx/store';
import { HomeProfileRemindersState } from '~/app/states/main/crm/home/states/home-candidate-reminders/home-candidate-reminders.state';
import { HomeProfileRemindersFilters } from '~/app/shared/interfaces/crm/home/reminder/home-profile-reminders-filters.interface';

// --- LOAD HOME PROFILE REMINDERS
export const loadHomeProfileReminders = createAction(
  '[Home Profile Reminders] Load Home Profile Reminders',
  props<{ filters?: HomeProfileRemindersFilters }>()
);
export const loadHomeProfileRemindersSuccess = createAction(
  '[Home Profile Reminders] Load Home Profile Reminders Success',
  props<{
    profileReminders: HomeProfileRemindersState;
    filters?: HomeProfileRemindersFilters;
  }>()
);
export const loadHomeProfileRemindersFailure = createAction(
  '[Home Profile Reminders] Load Home Profile Reminders Failure',
  props<{ error: string }>()
);

export const checkProfileReminder = createAction(
  '[Home Profile Reminders] Check Profile Reminder',
  props<{ profileId: string; uuid: string }>()
);
export const checkProfileReminderSuccess = createAction(
  '[Home Profile Reminders] Check Profile Reminder Success',
  props<{ uuid: string }>()
);
export const checkProfileReminderFailure = createAction(
  '[Home Profile Reminders] Check Profile Reminder Failure',
  props<{ error: string }>()
);

export const uncheckProfileReminder = createAction(
  '[Home Profile Reminders] Uncheck Profile Reminder',
  props<{ profileId: string; uuid: string }>()
);
export const uncheckProfileReminderSuccess = createAction(
  '[Home Profile Reminders] Uncheck Profile Reminder Success',
  props<{ uuid: string }>()
);
export const uncheckProfileReminderFailure = createAction(
  '[Home Profile Reminders] Uncheck Profile Reminder Failure',
  props<{ error: string }>()
);

export const homeProfileRemindersUnauthorized = createAction(
  '[Home Profile Reminders] Unauthorized',
  props<{ error: string }>()
);
