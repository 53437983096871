import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AllNotificationsState,
  NotificationState,
} from '~/app/states/main/states/notification/notification.state';

export const allNotificationsState =
  createFeatureSelector<AllNotificationsState>('notifications');

export const selectNotificationState = createSelector(
  allNotificationsState,
  (state: AllNotificationsState) => state.allNotifications
);

export const selectUserNotifications = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.allNotifications
);
