import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { User } from '~/app/shared/interfaces/auth/user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import * as KanbanCardUsersActions from '~/app/states/main/kanban/states/kanban-card-users/kanban-card-users.actions';
import { environment } from '~/environments/environment';

/**
 * `KanbanCardUsersEffects` manages the side effects for kanban card user-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to kanban card users,
 * such as loading users associated with a specific kanban card. It performs operations that involve checking
 * permissions, interacting with services for handling HTTP requests, and handling errors.
 *
 * The effects in this class include:
 * — `loadUsersByKanbanCardId$`: Loads users by kanban card ID. It performs an HTTP GET request and dispatches
 *    a success action with the fetched users or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class KanbanCardUsersEffects {
  /**
   * Effect to load users by kanban card ID.
   * Listens for the `loadUsersByKanbanCardId` action, checks permissions, and performs an HTTP GET request to fetch the users.
   * Dispatches a success or failure action based on the result.
   */
  loadUsersByKanbanCardId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanCardUsersActions.loadUsersByKanbanCardId),
      checkPermission(KanbanCardUsersActions.kanbanCardUsersUnauthorized),
      mergeMap(({ kanbanCardId }) =>
        this.http
          .get<
            User[]
          >(`${environment.apiUrl}/v1/kanbans/cards/users/${kanbanCardId}`, { withCredentials: true })
          .pipe(
            take(1),
            map(kanbanUsers =>
              KanbanCardUsersActions.loadUsersByKanbanCardIdSuccess({
                kanbanUsers,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                KanbanCardUsersActions.loadUsersByKanbanCardIdFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Constructor for `KanbanCardUsersEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
