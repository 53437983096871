import { Pipe, PipeTransform } from '@angular/core';
import { ComparisonEnum } from '~/app/shared/enums/comparison.enum';

/**
 * A custom Angular pipe that transforms a ComparisonEnum value into a corresponding icon class.
 * This pipe is useful for dynamically displaying icons based on comparison values in UI components.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'comparisonIcon', enabling its use within Angular templates
 * to dynamically render icons for different comparison operations.
 */
@Pipe({
  name: 'comparisonIcon',
})
export class ComparisonIconPipe implements PipeTransform {
  /**
   * Transforms a ComparisonEnum value into a corresponding icon class.
   *
   * @param {ComparisonEnum} value - The comparison value to be transformed.
   * @returns {string} - The corresponding icon class for the given comparison value.
   */
  transform(value: ComparisonEnum): string {
    switch (value) {
      case ComparisonEnum.EQ:
        return 'pi pi-calendar';
      case ComparisonEnum.GT:
      case ComparisonEnum.GTE:
        return 'pi pi-calendar-plus';
      case ComparisonEnum.LT:
      case ComparisonEnum.LTE:
        return 'pi pi-calendar-minus';
      default:
        return ''; // Optional: Return a default icon class or handle unexpected values
    }
  }
}
