import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ShuffleListActions from '~/app/states/main/shuffle-list/states/shuffle-list/shuffle-list.actions';
import { RandomShuffleList } from '~/app/shared/interfaces/shuffle-list/random-shuffle-list.interface';
import { ShuffleList } from '~/app/shared/interfaces/shuffle-list/shuffle-list.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `AllShuffleListEffects` handles the side effects related to shuffle lists in the application using Angular's NgRx Effects.
 * It manages asynchronous operations such as loading, creating, updating, deleting shuffle lists, and assigning/unassigning teams.
 *
 * Effects included in this class:
 * — `loadAllShuffleList$`: Fetches all shuffle lists from the server.
 * — `createShuffleList$`: Creates a new shuffle list.
 * — `updateShuffleList$`: Updates an existing shuffle list.
 * — `deleteShuffleList$`: Deletes a shuffle list.
 * — `assignTeam$`: Assigns a team to a shuffle list.
 * — `unassignTeam$`: Unassigns a team from a shuffle list.
 *
 * Each effect listens for specific actions, checks permissions, makes API requests, and dispatches success or failure actions accordingly.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class AllShuffleListEffects {
  /**
   * Effect to load all shuffle lists with optional filters.
   */
  loadAllShuffleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.loadAllShuffleList),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ filters }) =>
        this.http
          .get<ShuffleList[]>(`${environment.apiUrl}/v1/shuffleLists`, {
            params: { ...filters },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(shuffleLists =>
              ShuffleListActions.loadAllShuffleListSuccess({
                shuffleLists,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ShuffleListActions.loadAllShuffleListFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new shuffle list.
   */
  createShuffleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.createShuffleList),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ shuffleListData }) =>
        this.http
          .post<ShuffleList>(
            `${environment.apiUrl}/v1/shuffleLists`,
            shuffleListData,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(shuffleList =>
              ShuffleListActions.createShuffleListSuccess({ shuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.createShuffleListFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful creation of a shuffle list.
   * Displays a success toast message.
   */
  createShuffleListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShuffleListActions.createShuffleListSuccess),
        tap(() => {
          this.sidebarService.closeSidebar();
          this.toast.successToast(
            'Success',
            'Shuffle list created successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing shuffle list.
   */
  updateShuffleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.updateShuffleList),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid, shuffleListData }) =>
        this.http
          .patch<ShuffleList>(
            `${environment.apiUrl}/v1/shuffleLists/${uuid}`,
            shuffleListData,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(shuffleList =>
              ShuffleListActions.updateShuffleListSuccess({ shuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.updateShuffleListFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful update of a shuffle list.
   * Displays a success toast message.
   */
  updateShuffleListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShuffleListActions.updateShuffleListSuccess),
        tap(() => {
          this.sidebarService.closeSidebar();
          this.toast.successToast(
            'Success',
            'Shuffle list updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a shuffle list.
   */
  deleteShuffleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.deleteShuffleList),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete(`${environment.apiUrl}/v1/shuffleLists/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => ShuffleListActions.deleteShuffleListSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.deleteShuffleListFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful deletion of a shuffle list.
   * Displays a success toast message.
   */
  deleteShuffleListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShuffleListActions.deleteShuffleListSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Shuffle list deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a team to a shuffle list.
   */
  assignTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.assignTeam),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid, teamId }) =>
        this.http
          .patch<ShuffleList>(
            `${environment.apiUrl}/v1/shuffleLists/${uuid}/team/assign`,
            { teamId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(shuffleList =>
              ShuffleListActions.assignTeamSuccess({ shuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.assignTeamFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful assignment of a team to a shuffle list.
   * Displays a success toast message.
   */
  assignTeamSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShuffleListActions.assignTeamSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Team assigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to unassign a team from a shuffle list.
   */
  unassignTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.unassignTeam),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid, teamId }) =>
        this.http
          .patch<ShuffleList>(
            `${environment.apiUrl}/v1/shuffleLists/${uuid}/team/unassign`,
            { teamId },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(shuffleList =>
              ShuffleListActions.unassignTeamSuccess({ shuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.unassignTeamFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful unassignment of a team from a shuffle list.
   * Displays a success toast message.
   */
  unassignTeamSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShuffleListActions.unassignTeamSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Team unassigned successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to get a shuffle list by its uuid.
   */
  getShuffleListById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.getShuffleListById),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<ShuffleList>(`${environment.apiUrl}/v1/shuffleLists/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(shuffleList =>
              ShuffleListActions.getShuffleListByIdSuccess({ shuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ShuffleListActions.getShuffleListByIdFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to get a random team for a suffle list.
   */
  getRandom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShuffleListActions.getRandom),
      checkPermission(ShuffleListActions.shuffleListUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .get<RandomShuffleList>(
            `${environment.apiUrl}/v1/shuffleLists/${uuid}/random`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(randomShuffleList =>
              ShuffleListActions.getRandomSuccess({ randomShuffleList })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(ShuffleListActions.getRandomFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for `AllShuffleListEffects`.
   *
   * @param {Actions} actions$ - Injects the Actions observable.
   * @param {HttpClient} http - Injects the HttpClient.
   * @param {HttpErrorsService} httpErrors - Injects the HttpErrorsService.
   * @param {CustomToastService} toast - Injects the CustomToastService.
   * @param {SidenavFormService} sidebarService - Injects the SidenavFormService.
   * @memberof AllShuffleListEffects
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
