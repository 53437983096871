import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesStatusActions from '~/app/states/main/settings/states/status/sales-status/sales-status.actions';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SalesStatusEffects` manages the side effects related to sales statuses in the application using Angular's NgRx Effects.
 * It orchestrates asynchronous operations such as loading, creating, updating, and deleting sales statuses from the server.
 *
 * The effects in this class include:
 * — `loadSalesStatuses$`: Fetches sales statuses from the server.
 * — `createSalesStatus$`: Creates a new sales status on the server.
 * — `createSalesStatusSuccess$`: Handles successful sales status creation.
 * — `updateSalesStatus$`: Updates an existing sales status on the server.
 * — `updateSalesStatusSuccess$`: Handles successful sales status updates.
 * — `deleteSalesStatus$`: Deletes a sales status from the server.
 * — `deleteSalesStatusSuccess$`: Handles successful sales status deletion.
 * — `updateSalesStatusSequence$`: Updates the sequence of sales statuses on the server.
 * — `updateSalesStatusSequenceSuccess$`: Handles successful sales status sequence updates.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesStatusEffects {
  /**
   * Effect to load sales statuses from the server.
   * It listens for the `loadSalesStatuses` action, checks permissions, and makes an HTTP GET request.
   * Upon success, it dispatches the `loadSalesStatusesSuccess` action with the retrieved sales statuses.
   */
  loadSalesStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesStatusActions.loadSalesStatuses),
      checkPermission(SalesStatusActions.salesStatusUnauthorized),
      mergeMap(() =>
        this.http
          .get<Status[]>(`${environment.apiUrl}/v1/sales/status`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesStatuses =>
              SalesStatusActions.loadSalesStatusesSuccess({
                salesStatuses,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesStatusActions.loadSalesStatusesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales status on the server.
   * It listens for the `createSalesStatus` action, checks permissions, and makes an HTTP POST request.
   * Upon success, it dispatches the `createSalesStatusSuccess` action with the created sales status.
   */
  createSalesStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesStatusActions.createSalesStatus),
      checkPermission(SalesStatusActions.salesStatusUnauthorized),
      mergeMap(({ salesStatusData }) =>
        this.http
          .post<Status>(
            `${environment.apiUrl}/v1/sales/status`,
            salesStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesStatus =>
              SalesStatusActions.createSalesStatusSuccess({
                salesStatus,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesStatusActions.createSalesStatusFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of sales status creation.
   * It listens for the `createSalesStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  createSalesStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesStatusActions.createSalesStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales status created successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing sales status on the server.
   * It listens for the `updateSalesStatus` action, checks permissions, and makes an HTTP PATCH request.
   * Upon success, it dispatches the `updateSalesStatusSuccess` action with the updated sales status.
   */
  updateSalesStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesStatusActions.updateSalesStatus),
      checkPermission(SalesStatusActions.salesStatusUnauthorized),
      mergeMap(({ uuid, salesStatusData }) =>
        this.http
          .patch<Status>(
            `${environment.apiUrl}/v1/sales/status/${uuid}`,
            salesStatusData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesStatus =>
              SalesStatusActions.updateSalesStatusSuccess({
                salesStatus,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesStatusActions.updateSalesStatusFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of sales status update.
   * It listens for the `updateSalesStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  updateSalesStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesStatusActions.updateSalesStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales status updated successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a sales status from the server.
   * It listens for the `deleteSalesStatus` action, checks permissions, and makes an HTTP DELETE request.
   * Upon success, it dispatches the `deleteSalesStatusSuccess` action with the deleted sales status UUID.
   */
  deleteSalesStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesStatusActions.deleteSalesStatus),
      checkPermission(SalesStatusActions.salesStatusUnauthorized),
      mergeMap(({ uuid }) =>
        this.http
          .delete<void>(`${environment.apiUrl}/v1/sales/status/${uuid}`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => SalesStatusActions.deleteSalesStatusSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesStatusActions.deleteSalesStatusFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of sales status deletion.
   * It listens for the `deleteSalesStatusSuccess` action and shows a success toast message.
   * Additionally, it closes the sidebar.
   */
  deleteSalesStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesStatusActions.deleteSalesStatusSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales status deleted successfully'
          );
          this.sidebarService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update the sequence of sales statuses on the server.
   * It listens for the `updateSalesStatusSequence` action, checks permissions, and makes an HTTP PATCH request.
   * Upon success, it dispatches the `updateSalesStatusSequenceSuccess` action with the updated statuses.
   */
  updateSalesStatusSequence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesStatusActions.updateSalesStatusSequence),
        checkPermission(SalesStatusActions.salesStatusUnauthorized),
        mergeMap(({ statuses }) =>
          this.http
            .patch<void>(
              `${environment.apiUrl}/v1/sales/status/sequences`,
              { statuses },
              { withCredentials: true }
            )
            .pipe(
              take(1),
              map(() =>
                SalesStatusActions.updateSalesStatusSequenceSuccess({
                  statuses,
                })
              ),
              catchError(error => {
                this.httpErrors.handleError(error);
                return of(
                  SalesStatusActions.updateSalesStatusSequenceFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: true }
  );

  /**
   * Effect to handle the success of sales status sequence update.
   * It listens for the `updateSalesStatusSequenceSuccess` action and shows a success toast message.
   */
  updateSalesStatusSequenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesStatusActions.updateSalesStatusSequenceSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Sales status sequence updated successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesStatusEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for showing toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
