<section
  class="min-h-screen w-screen flex align-items-center justify-content-center bg-cover p-3">
  <div class="surface-card p-4 shadow-2 border-round bg-cover w-30rem">
    <div class="text-center mb-5">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="50"
        class="mb-3" />
      <div i18n class="text-900 text-3xl font-medium mb-3">Join IceBerry</div>
      <span i18n class="text-600 font-medium line-height-3"
        >Enter your informations below.</span
      >
    </div>

    <form [formGroup]="userRegisterForm" (ngSubmit)="onSubmit()">
      <ice-input
        [label]="'Firstname'"
        [placeholder]="'Firstname'"
        [formControlName]="'firstname'"
        [formControl]="firstname"></ice-input>

      <ice-input
        [label]="'Lastname'"
        [placeholder]="'Lastname'"
        [formControlName]="'lastname'"
        [formControl]="lastname"></ice-input>

      <ice-password
        [label]="'Password'"
        [placeholder]="'Password'"
        [formControlName]="'password'"
        [formControl]="password"></ice-password>

      <ice-button
        [style]="{ width: '100%' }"
        label="Let's go !"
        i18n-label
        icon="pi pi-check"
        i18n-icon
        type="submit"
        [disabled]="!userRegisterForm.valid" />
    </form>
  </div>
</section>
