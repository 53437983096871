import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanTaskActions from '~/app/states/main/kanban/states/kanban-card-tasks/kanban-card-tasks.actions';
import {
  CreateKanbanTask,
  UpdateKanbanTask,
} from '~/app/states/main/kanban/types/kanban-tasks.types';
import { KanbanTask } from '~/app/shared/interfaces/kanban/kanban-task/kanban-task.interface';

/**
 * Service responsible for managing Kanban card tasks.
 * Provides methods to load, create, update, delete, check, and uncheck tasks for Kanban cards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanCardTasksService {
  /**
   * Constructs a new KanbanCardTasksService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * Loads all tasks for a specified Kanban card.
   * Dispatches a loadAllKanbanCardTasks action with the Kanban card ID and Kanban column ID.
   *
   * @param {string} kanbanCardId – The ID of the Kanban card.
   * @returns {void}
   */
  loadKanbanCardTasks(kanbanCardId: string): void {
    this.store.dispatch(
      KanbanTaskActions.loadAllKanbanCardTasks({ kanbanCardId })
    );
  }

  /**
   * Creates a new task for a specified Kanban card.
   * Dispatches a createKanbanCardTask action with the task data, Kanban card ID, and Kanban column ID.
   *
   * @param {string} kanbanCardId – The ID of the Kanban card.
   * @param {CreateKanbanTask} kanbanTaskData – The data for the new Kanban task.
   * @returns {void}
   */
  createTask(
    kanbanCardId: string,

    kanbanTaskData: CreateKanbanTask
  ): void {
    this.store.dispatch(
      KanbanTaskActions.createKanbanCardTask({
        kanbanCardId,
        kanbanTaskData,
      })
    );
  }

  /**
   * Updates an existing task for a specified Kanban card.
   * Dispatches an updateKanbanCardTask action with the task UUID, Kanban column ID, and updated task data.
   *
   * @param {string} uuid – The UUID of the task to be updated.
   * @param {UpdateKanbanTask} kanbanTaskData – The updated data for the Kanban task.
   * @returns {void}
   */
  updateTask(uuid: string, kanbanTaskData: UpdateKanbanTask): void {
    this.store.dispatch(
      KanbanTaskActions.updateKanbanCardTask({
        uuid,
        kanbanTaskData,
      })
    );
  }

  /**
   * Deletes a task from a specified Kanban card.
   * Dispatches a deleteKanbanCardTask action with the task UUID, Kanban card ID, and Kanban column ID.
   *
   * @param {string} uuid – The UUID of the task to be deleted.
   * @param {string} kanbanCardId – The ID of the Kanban card.
   * @returns {void}
   */
  deleteTask(uuid: string, kanbanCardId: string): void {
    this.store.dispatch(
      KanbanTaskActions.deleteKanbanCardTask({
        uuid,
        kanbanCardId,
      })
    );
  }

  /**
   * Checks a task for a specified Kanban card.
   * Dispatches a checkKanbanCardTask action with the task UUID, Kanban card ID, and Kanban column ID.
   *
   * @param {string} uuid – The UUID of the task to be checked.
   * @param {string} kanbanCardId – The ID of the Kanban card.

   * @returns {void}
   */
  checkKanbanTask(uuid: string, kanbanCardId: string): void {
    this.store.dispatch(
      KanbanTaskActions.checkKanbanCardTask({
        uuid,
        kanbanCardId,
      })
    );
  }

  /**
   * Unchecks a task for a specified Kanban card.
   * Dispatches an uncheckKanbanCardTask action with the task UUID, Kanban card ID, and Kanban column ID.
   *
   * @param {string} uuid – The UUID of the task to be unchecked.
   * @param {string} kanbanCardId – The ID of the Kanban card.
   * @returns {void}
   */
  uncheckKanbanTask(uuid: string, kanbanCardId: string): void {
    this.store.dispatch(
      KanbanTaskActions.uncheckKanbanCardTask({
        uuid,
        kanbanCardId,
      })
    );
  }

  /**
   * ************************************************************************
   * SSE SERVER
   * ************************************************************************
   **/

  /**
   * Creates a new task for a specified Kanban card.
   * Dispatches a createKanbanCardTaskServer action with the task.
   *
   * @param {KanbanTask} kanbanTask – The data for the new Kanban task.
   * @returns {void}
   */
  createTaskServer(kanbanTask: KanbanTask): void {
    this.store.dispatch(
      KanbanTaskActions.createKanbanCardTaskServer({
        kanbanTask,
      })
    );
  }

  /**
   * Updates an existing task for a specified Kanban card.
   * Dispatches an updateKanbanCardTaskServer action with the task.
   *
   * @param {KanbanTask} kanbanTask – The Kanban task.
   * @returns {void}
   */
  updateTaskServer(kanbanTask: KanbanTask): void {
    this.store.dispatch(
      KanbanTaskActions.updateKanbanCardTaskServer({
        kanbanTask,
      })
    );
  }

  /**
   * Deletes a task from a specified Kanban card.
   * Dispatches a deleteKanbanCardTask action with the task UUID.
   *
   * @param {string} uuid – The UUID of the task to be deleted.
   * @returns {void}
   */
  deleteTaskServer(uuid: string): void {
    this.store.dispatch(
      KanbanTaskActions.deleteKanbanCardTaskServer({
        uuid,
      })
    );
  }

  /**
   * Checks a task.
   * Dispatches a checkKanbanCardTaskServer action with the task.
   *
   * @param {KanbanTask} kanbanTask – The task.
   * @returns {void}
   */
  checkTaskServer(kanbanTask: KanbanTask): void {
    this.store.dispatch(
      KanbanTaskActions.checkKanbanCardTaskServer({
        kanbanTask,
      })
    );
  }

  /**
   * Unchecks a task.
   * Dispatches an uncheckKanbanCardTaskServer action with the task.
   *
   * @param {KanbanTask} kanbanTask – The task.
   * @returns {void}
   */
  uncheckTaskServer(kanbanTask: KanbanTask): void {
    this.store.dispatch(
      KanbanTaskActions.uncheckKanbanCardTaskServer({
        kanbanTask,
      })
    );
  }
}
