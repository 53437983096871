import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

/**
 * Represents the state of profile industries.
 *
 * @interface ProfileIndustryState
 */
export interface ProfileIndustryState {
  /**
   * An array of industries associated with the profile.
   *
   * @type {Industry[]}
   */
  industries: Industry[];

  /**
   * Indicates if the industries are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the industries.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of profile industries within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {ProfileIndustryState} initialProfileIndustryState
 * @description Provides a baseline definition for the profile industries context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialProfileIndustryState: ProfileIndustryState = {
  industries: [],
  loading: false,
  error: null,
};
