import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileSectorJobsActions from '~/app/states/main/crm/profile/states/profile-sectors/profile-sectors.actions';
import { SectorJobs } from '~/app/shared/interfaces/shared/sector/sector-jobs.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `SectorJobsEffects` manages the side effects for profile sector job-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile sector jobs, such as loading, assigning, and unassigning sector jobs.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileSectorJobs$`: Loads profile sector jobs based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched sector jobs or a failure action if an error occurs.
 * — `unasignProfileSector$`: Unassigns a sector job from a profile. It performs an HTTP PATCH request and dispatches a success action with the updated sector jobs or a failure action if an error occurs.
 * — `unassignProfileSectorSuccess$`: Handles the success of unassigning a profile sector job by displaying a success toast message.
 * — `assignProfileSectorJob$`: Assigns a sector job to a profile. It performs an HTTP PATCH request and dispatches a success action with the updated sector jobs or a failure action if an error occurs.
 * — `assignProfileSectorJobSuccess$`: Handles the success of assigning a profile sector job by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SectorJobsEffects {
  /**
   * Effect to load profile sector jobs based on a given profile ID.
   * Listens for the `loadProfileSectors` action, checks permissions, and performs an HTTP GET request to fetch the sector jobs.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileSectorJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSectorJobsActions.loadProfileSectors),
      checkPermission(ProfileSectorJobsActions.profileSectorsUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/profiles/sectorJobs/${profileId}`, { withCredentials: true })
          .pipe(
            take(1),
            map(profileSectors =>
              ProfileSectorJobsActions.loadProfileSectorsSuccess({
                profileSectors,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSectorJobsActions.loadProfileSectorsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to unassign a sector job from a profile.
   * Listens for the `unassignProfileSector` action, checks permissions, and performs an HTTP PATCH request to unassign the sector job.
   * Dispatches a success or failure action based on the result.
   */
  unasignProfileSector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSectorJobsActions.unassignProfileSector),
      checkPermission(ProfileSectorJobsActions.profileSectorsUnauthorized),
      mergeMap(({ profileId, profileSector }) =>
        this.http
          .patch<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/profiles/sectorJobs/${profileId}/unassignedSectorJob`, profileSector, { withCredentials: true })
          .pipe(
            take(1),
            map(profileSectors =>
              ProfileSectorJobsActions.unassignProfileSectorSuccess({
                profileSectors,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSectorJobsActions.unassignProfileSectorFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of unassigning a profile sector job.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  unassignProfileSectorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSectorJobsActions.unassignProfileSectorSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile sector unassigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to assign a sector job to a profile.
   * Listens for the `assignProfileSectorJob` action, checks permissions, and performs an HTTP PATCH request to assign the sector job.
   * Dispatches a success or failure action based on the result.
   */
  assignProfileSectorJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileSectorJobsActions.assignProfileSectorJob),
      checkPermission(ProfileSectorJobsActions.profileSectorsUnauthorized),
      mergeMap(({ profileId, profileSector }) =>
        this.http
          .patch<
            SectorJobs[]
          >(`${environment.apiUrl}/v1/profiles/sectorJobs/${profileId}/assignSectorJob`, profileSector, { withCredentials: true })
          .pipe(
            take(1),
            map(profileSectors =>
              ProfileSectorJobsActions.assignProfileSectorJobSuccess({
                profileSectors,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileSectorJobsActions.assignProfileSectorJobFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of assigning a profile sector job.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  assignProfileSectorJobSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSectorJobsActions.assignProfileSectorJobSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile sector assigned successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SectorJobsEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidebarService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    public sidebarService: SidenavFormService
  ) {}
}
