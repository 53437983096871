import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that converts newline characters in a string to HTML line break tags (`<br>`).
 * This pipe is particularly useful for rendering multi-line text in HTML from text that includes newline characters,
 * ensuring that the text layout is preserved in the web interface.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'lineBreak', enabling its use within Angular templates
 * to dynamically convert text content.
 */
@Pipe({
  name: 'lineBreak',
})
export class LineBreakPipe implements PipeTransform {
  /**
   * Transforms the input string by replacing all instances of newline characters (`\n`) with HTML `<br>` tags.
   * This allows for proper rendering of line breaks in HTML, which does not naturally interpret newline characters as breaks.
   *
   * @param {string} value - The input string to be transformed.
   * @returns {string} - The transformed string with newline characters replaced by `<br>` tags.
   */
  transform(value: string): string {
    return value.replace(/\n/g, '<br>');
  }
}
