import { createReducer, on } from '@ngrx/store';
import * as ProfileIndustryActions from '~/app/states/main/crm/profile/states/profile-industries/profile-industries.actions';
import { initialProfileIndustryState } from '~/app/states/main/crm/profile/states/profile-industries/profile-industries.state';

export const profileIndustryReducer = createReducer(
  initialProfileIndustryState,

  on(ProfileIndustryActions.loadProfileIndustries, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileIndustryActions.loadProfileIndustriesSuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(
    ProfileIndustryActions.loadProfileIndustriesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileIndustryActions.assignProfileIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileIndustryActions.assignProfileIndustrySuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(
    ProfileIndustryActions.assignProfileIndustryFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(ProfileIndustryActions.unassignProfileIndustry, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ProfileIndustryActions.unassignProfileIndustrySuccess,
    (state, { industries }) => ({
      ...state,
      industries,
      loading: false,
    })
  ),
  on(
    ProfileIndustryActions.unassignProfileIndustryFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ProfileIndustryActions.profileIndustryUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
