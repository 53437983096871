import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectActions from '~/app/states/main/sales/states/project/project.actions';
import { ProjectState } from '~/app/states/main/sales/states/project/project.state';
import { LoadSalesByFilters } from '~/app/states/main/sales/components/search-sales/states/search-sales.state';

/**
 * Service for managing project operations such as loading project details, users, and sales.
 *
 * This service handles the loading of project details, users, and sales information.
 * It interacts with the store and dispatches actions to manage the state of projects.
 *
 * @export
 * @class ProjectService
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  /**
   * Creates an instance of ProjectService.
   *
   * @param {Store<ProjectState>} store - Injectable store for managing project state.
   * @memberof ProjectService
   */
  constructor(private store: Store<ProjectState>) {}

  /**
   * Loads a project by its UUID.
   *
   * @param {string} uuid - The UUID of the project to load.
   * @returns {void}
   */
  loadProject(uuid: string): void {
    this.store.dispatch(ProjectActions.loadProject({ uuid }));
  }

  /**
   * Loads the users associated with a project.
   *
   * @param {string} uuid - The UUID of the project whose users are to be loaded.
   * @returns {void}
   */
  loadProjectUsers(uuid: string): void {
    this.store.dispatch(ProjectActions.loadProjectUsers({ uuid }));
  }

  /**
   * Loads the sales information for a project.
   *
   * @param {string} uuid - The UUID of the project whose sales information is to be loaded.
   * @returns {void}
   */
  loadProjectSales(uuid: string): void {
    this.store.dispatch(ProjectActions.loadProjectSales({ uuid }));
  }

  /**
   * Loads all project data including project details, users, and sales.
   *
   * @param {string} uuid - The UUID of the project to load.
   * @returns {void}
   */
  loadAllProjectData(uuid: string): void {
    this.loadProject(uuid);
    this.loadProjectUsers(uuid);
    this.loadProjectSales(uuid);
  }

  /**
   * Assigns a user to a project.
   *
   * @param {string} projectId - The UUID of the project to assign the user to.
   * @param {string} userId - The UUID of the user to assign to the project.
   * @returns {void}
   */
  assignUserToProject(projectId: string, userId: string): void {
    this.store.dispatch(
      ProjectActions.assignUserToProject({
        projectId,
        userId,
      })
    );
  }

  /**
   * Unassigns a user from a project.
   *
   * @param {string} projectId - The UUID of the project to unassign the user from.
   * @param {string} userId - The UUID of the user to unassign from the project.
   * @returns {void}
   */
  unassignUserFromProject(projectId: string, userId: string): void {
    this.store.dispatch(
      ProjectActions.unassignUserFromProject({
        projectId,
        userId,
      })
    );
  }

  /**
   * Assigns a sale to a project.
   *
   * @param {string} projectId - The UUID of the project to assign the sale to.
   * @param {string} saleId - The UUID of the sale to assign to the project.
   * @returns {void}
   */
  assignSaleToProject(projectId: string, saleId: string): void {
    this.store.dispatch(
      ProjectActions.assignSaleToProject({
        projectId,
        saleId,
      })
    );
  }

  /**
   * Unassigns a sale from a project.
   *
   * @param {string} projectId - The UUID of the project to unassign the sale from.
   * @param {string} saleId - The UUID of the sale to unassign from the project.
   * @returns {void}
   */
  unassignSaleFromProject(projectId: string, saleId: string): void {
    this.store.dispatch(
      ProjectActions.unassignSaleFromProject({
        projectId,
        saleId,
      })
    );
  }

  /**
   * Dispatches an action to assign sales to a project.
   *
   * @param {string} projectId - The uuid of the project.
   * @param {LoadSalesByFilters} filters - The filters.
   * @return {void}
   */
  assignSalesToProject(projectId: string, filters?: LoadSalesByFilters): void {
    this.store.dispatch(
      ProjectActions.assignSalesToProject({ projectId, filters })
    );
  }

  /**
   * Clears the selected project from the store.
   *
   * @returns {void}
   */
  clearSelectedProject(): void {
    this.store.dispatch(ProjectActions.clearSelectedProject());
  }
}
