import { createReducer, on } from '@ngrx/store';
import { initialConsultingReducerState } from '~/app/states/main/crm/ai-search/states/consulting/consulting.state';
import * as AiConsultingActions from '~/app/states/main/crm/ai-search/states/consulting/consulting.actions';

export const aiConsultingReducer = createReducer(
  initialConsultingReducerState,

  on(AiConsultingActions.loadAiConsultingJobMatchingRequest, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiConsultingActions.loadAiConsultingJobMatchingRequestSuccess,
    (state, { request }) => ({
      ...state,
      request,
      loading: false,
    })
  ),
  on(
    AiConsultingActions.loadAiConsultingJobMatchingRequestFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiConsultingActions.updateAdvancement, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AiConsultingActions.updateAdvancementSuccess, (state, { progress }) => ({
    ...state,
    progress,
    loading: false,
  })),
  on(AiConsultingActions.updateAdvancementFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(AiConsultingActions.loadAiConsultingJobMatchingCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    AiConsultingActions.loadAiConsultingJobMatchingCandidatesSuccess,
    (state, { matching }) => ({
      ...state,
      matching,
      loading: false,
    })
  ),
  on(
    AiConsultingActions.loadAiConsultingJobMatchingCandidatesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(AiConsultingActions.resetAiConsultingJobMatchingCandidates, state => ({
    ...state,
    matching: null,
    loading: false,
  })),

  on(
    AiConsultingActions.consultingAISearchUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
