import { Component } from '@angular/core';

/**
 * `NotFoundComponent` is a presentation component for displaying a 404 Not Found page.
 * This component is used when the application encounters a route that does not exist or cannot be located.
 * It is a simple, stateless component that relies on its HTML and SCSS for layout and styling.
 *
 * @selector `app-not-found`
 * Use this selector to integrate the Not Found page component within other components or templates.
 *
 * @templateUrl `./not-found.component.html`
 * The HTML template for the Not Found page. It contains the layout and elements for displaying the 404 error message.
 *
 * @styleUrls `./not-found.component.scss`
 * The SCSS stylesheet for this component. It provides the specific styling for the Not Found page, such as typography, colors, and layout.
 *
 * @example
 * This component is typically configured in the routing module to show a 404 page for undefined routes:
 * ```typescript
 * { path: '**', component: NotFoundComponent }
 * ```
 */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {}
