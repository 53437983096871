import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

/**
 * Directive that ensures only numerical input is allowed in the targeted input field.
 * This is particularly useful for input fields where only numbers are expected, such as age or quantity fields.
 *
 * The directive uses Angular's Renderer2 for DOM manipulations, which provides a layer of abstraction that ensures
 * automatic DOM updates are performed in a way that is optimized for Angular's change detection mechanisms.
 *
 * @Directive decorator specifies the CSS selector `[appNumber]` that targets input elements where this behavior should be applied.
 */
@Directive({
  selector: '[appNumber]',
})
export class NumberDirective {
  /**
   * Input property that allows enabling or disabling the numerical restriction behavior.
   * When set to `true`, the directive will enforce that only numbers can be input.
   *
   * @type {boolean}
   */
  @Input() appNumber = false;

  /**
   * Constructs an instance of the NumberDirective with injected dependencies.
   *
   * @param {ElementRef} el - A wrapper around the native element where the directive is applied, providing direct access to the DOM element.
   * @param {Renderer2} renderer - Angular's rendering abstraction used to modify the element in a way that's compatible with server-side rendering and optimized for Angular's change detection.
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  /**
   * Host listener for the 'input' event on the element. Whenever the user inputs data, the entered text is checked to ensure it is numerical.
   * If non-numerical characters are entered, they are removed from the input value.
   *
   * @return {void}
   */
  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement; // Access the native input element

    const inputValue = this.appNumber
      ? inputElement.value.replaceAll(/[^0-9]/g, '')
      : inputElement.value;
    this.renderer.setProperty(inputElement, 'value', inputValue); // Update the input element with the new value
  }
}
