<div class="pb-3 relative w-full flex flex-column gap-2">
  <label [for]="formControlName" class="font-bold text-sm mx-0">
    {{ label }} <span *ngIf="required" class="text-red-600">*</span>
  </label>

  <div class="ice-dropdown" (click)="toggleDropdown()">
    <div *ngIf="multiple" class="selected-options">
      <span *ngFor="let selected of selectedOptions" class="selected-option">
        {{ selected }} <span (click)="removeOption(selected)">×</span>
      </span>
    </div>
    <div class="flex w-full">
      <input
        *ngIf="!multiple; else multiplePlaceholder"
        [id]="formControlName"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [value]="value"
        (input)="onInput($event)"
        (blur)="onBlur()"
        class="ice-dropdown-input form-input w-full pr-10" />

      <ng-template #multiplePlaceholder>
        <div class="placeholder">{{ placeholder }}</div>
      </ng-template>
      <!-- Arrow Icon -->
      <div class="arrow-icon">
        <img src="assets/icons/shared/icon-chevron-down.svg" />
      </div>
    </div>

    <!-- Dropdown Options -->
    <div *ngIf="dropdownOpen" class="ice-dropdown-options">
      <div
        *ngFor="let option of filteredOptions"
        class="ice-dropdown-option"
        [class.selected]="isSelected(option)"
        (click)="selectOption(option)">
        {{ option }}
      </div>
    </div>
  </div>
</div>
