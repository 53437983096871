import { createSelector } from '@ngrx/store';
import { selectSharedState } from '~/app/shared/shared.selector';

export const selectAllSkillsState = createSelector(
  selectSharedState,
  state => state.allSkills
);

export const selectAllSkills = createSelector(
  selectAllSkillsState,
  state => state.data
);
