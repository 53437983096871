import { createAction, props } from '@ngrx/store';
import {
  CreateClientCompanyStatus,
  UpdateClientCompanyStatus,
} from '~/app/states/main/settings/types/client-company-status.types';
import { SequenceUuidInterface } from '~/app/shared/interfaces/generic/sequence-uuid.interface';
import { ClientCompanyStatus } from '~/app/shared/interfaces/settings/client-company-status.interface';

export const loadClientCompanyStatuses = createAction(
  '[Client Company Status] Load Client Company Statuses'
);
export const loadClientCompanyStatusesSuccess = createAction(
  '[Client Company Status] Load ClientCompany Statuses Success',
  props<{ clientCompanyStatuses: ClientCompanyStatus[] }>()
);
export const loadClientCompanyStatusesFailure = createAction(
  '[Client Company Status] Load Client Company Statuses Failure',
  props<{ error: string }>()
);

export const createClientCompanyStatus = createAction(
  '[Client Company Status] Create Client Company Status',
  props<{ clientCompanyStatusData: CreateClientCompanyStatus }>()
);
export const createClientCompanyStatusSuccess = createAction(
  '[Client Company Status] Create Client Company Status Success',
  props<{ clientCompanyStatus: ClientCompanyStatus }>()
);
export const createClientCompanyStatusFailure = createAction(
  '[Client Company Status] Create Client Company Status Failure',
  props<{ error: string }>()
);

export const updateClientCompanyStatus = createAction(
  '[Client Company Status] Update Client Company Status',
  props<{ uuid: string; clientCompanyStatusData: UpdateClientCompanyStatus }>()
);
export const updateClientCompanyStatusSuccess = createAction(
  '[Client Company Status] Update Client Company Status Success',
  props<{ status: ClientCompanyStatus }>()
);
export const updateClientCompanyStatusFailure = createAction(
  '[Client Company Status] Update Client Company Status Failure',
  props<{ error: string }>()
);

export const deleteClientCompanyStatus = createAction(
  '[Client Company Status] Delete Client Company Status',
  props<{ uuid: string }>()
);
export const deleteClientCompanyStatusSuccess = createAction(
  '[Client Company Status] Delete Client Company Status Success',
  props<{ uuid: string }>()
);
export const deleteClientCompanyStatusFailure = createAction(
  '[Client Company Status] Delete Client Company Status Failure',
  props<{ error: string }>()
);

export const updateClientCompanyStatusSequence = createAction(
  '[Client Company Status] Update Client Company Status Sequence',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateClientCompanyStatusSequenceSuccess = createAction(
  '[Client Company Status] Update Client Company Status Sequence Success',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateClientCompanyStatusSequenceFailure = createAction(
  '[Client Company Status] Update Client Company Status Sequence Failure',
  props<{ error: string }>()
);

export const clientCompanyStatusUnauthorized = createAction(
  '[Client Company Status] Unauthorized',
  props<{ error: string }>()
);
