import { createSelector } from '@ngrx/store';
import { selectStatsModuleState } from '~/app/states/main/stats/stats.selectors';

export const selectGoals = createSelector(
  selectStatsModuleState,
  state => state.goals
);

export const selectPinnedGoals = createSelector(
  selectGoals,
  state => state.pinned
);

export const selectDailyGoals = createSelector(
  selectGoals,
  state => state.daily
);

export const selectWeeklyGoals = createSelector(
  selectGoals,
  state => state.weekly
);

export const selectMonthlyGoals = createSelector(
  selectGoals,
  state => state.monthly
);

export const selectSelectedGoal = createSelector(
  selectGoals,
  state => state.selectedGoal
);

export const selectGoalUsers = createSelector(
  selectGoals,
  state => state.users
);

export const selectGoalTeams = createSelector(
  selectGoals,
  state => state.teams
);

export const selectIsLoading = createSelector(
  selectGoals,
  state => state.isLoading
);
