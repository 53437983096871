import { createAction, props } from '@ngrx/store';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

export const loadSalesIndustries = createAction(
  '[Sales Industries] Load Sales Industries',
  props<{ salesId: string }>()
);
export const loadSalesIndustriesSuccess = createAction(
  '[Sales Industries] Load Sales Industries Success',
  props<{ industries: Industry[] }>()
);
export const loadSalesIndustriesFailure = createAction(
  '[Sales Industries] Load Sales Industries Failure',
  props<{ error: string }>()
);

export const assignSalesIndustry = createAction(
  '[Sales Industries] Assign Sales Industry',
  props<{ salesId: string; industryId: string[] | string }>()
);
export const assignSalesIndustrySuccess = createAction(
  '[Sales Industries] Assign Sales Industry Success',
  props<{ industries: Industry[] }>()
);
export const assignSalesIndustryFailure = createAction(
  '[Sales Industries] Assign Sales Industry Failure',
  props<{ error: string }>()
);

export const unassignSalesIndustry = createAction(
  '[Sales Industries] Unassign Sales Industry',
  props<{ salesId: string; industryId: string[] | string }>()
);
export const unassignSalesIndustrySuccess = createAction(
  '[Sales Industries] Unassign Sales Industry Success',
  props<{ industries: Industry[] }>()
);
export const unassignSalesIndustryFailure = createAction(
  '[Sales Industries] Unassign Sales Industry Failure',
  props<{ error: string }>()
);

export const salesIndustriesUnauthorized = createAction(
  '[Sales Industries] Unauthorized',
  props<{ error: string }>()
);
