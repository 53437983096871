<!--TODO: Add rules-->
<ng-container>
  <form
    [formGroup]="fileForm"
    class="flex flex-column gap-3-5 mt-7-75"
    (ngSubmit)="onSubmit()">
    <div class="flex flex-column align-items-start gap-2o">
      <div class="flex flex-column w-full">
        <div
          *ngIf="!file"
          class="flex flex-column align-self-center w-full border-solid border-round-2xl"
          [style]="{ color: hovered }">
          <div
            class="flex flex-column gap-3-5 align-items-center p-3 border-dashed border-2 border-round-2xl border-dark-10"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave()"
            (drop)="onDrop($event)">
            <img
              alt="Drop files"
              i18n-alt
              height="150"
              width="300"
              src="assets/placeholder/drag_and_drop_pdf_word_placeholder.svg" />
            <p class="text-center color-dark-100">
              Drag & drop a CV <br />
              <span class="text-base color-dark-70"
                >or
                <span
                  class="color-fancy-sky underline cursor-pointer"
                  (click)="fileInput.click()"
                  >choose a file</span
                ></span
              >
            </p>
            <input
              type="file"
              accept="application/pdf"
              i18n-accept
              (change)="onFileSelect($event)"
              style="display: none"
              #fileInput />
          </div>
        </div>
        <div *ngIf="file" class="mt-2 w-full gap-1">
          <div
            class="flex justify-content-between align-items-center border-round-xl bg-dark-3 p-3 color-dark-100">
            <div class="flex align-items-center gap-3-5">
              <i
                *ngIf="file.type === 'application/pdf'"
                style="font-size: 1.5rem"
                class="pi pi-file-pdf color-danger-100"></i>
              <i
                *ngIf="file.type !== 'application/pdf'"
                style="font-size: 1.5rem"
                class="pi pi-file-word color-fancy-sky"></i>
              <p class="line-clamp-1 m-0">{{ file.name }}</p>
            </div>
            <ice-button
              [text]="true"
              [style]="{ color: 'var(--color-danger-90)' }"
              icon="pi pi-times"
              i18n-icon
              (onClick)="removeFile()"></ice-button>
          </div>
        </div>
      </div>
      <ice-autocomplete
        [label]="'Office'"
        [required]="true"
        formControlName="officeId"
        [formControl]="officeId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        class="w-full mt-7-75"
        [multiple]="false"
        [dropdown]="false"
        [suggestions]="(offices$ | async) ?? []"></ice-autocomplete>

      <ice-autocomplete
        [label]="'Team'"
        [required]="true"
        formControlName="teamId"
        [formControl]="teamId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        class="w-full"
        [multiple]="false"
        [dropdown]="false"
        [suggestions]="(teams$ | async) ?? []"></ice-autocomplete>
      <ice-autocomplete
        [label]="'Status'"
        [required]="true"
        id="statusId"
        formControlName="statusId"
        [formControl]="statusId"
        optionLabel="label"
        optionValue="value"
        i18n-optionLabel
        class="w-full"
        [multiple]="false"
        [dropdown]="false"
        [iceAutocompleteTemplate]="templateEnum.STATUS"
        [suggestions]="(statuses$ | async) ?? []">
      </ice-autocomplete>
    </div>
    <div class="flex flex-column w-full justify-content-end">
      <ice-button
        class="align-self-end"
        type="submit"
        label="Generate"
        i18n-label
        icon="pi pi-sparkles"
        i18n-icon
        [disabled]="!fileForm.valid"></ice-button>
      <p class="m-O text-xs color-dark-70 text-right">
        You’ll be redirected to the generated profile <br />once the process is
        complete.
      </p>
    </div>
  </form>
</ng-container>
