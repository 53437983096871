import { Status } from '~/app/shared/interfaces/shared/status.interface';

/**
 * Represents the state for sales statuses in the CRM system.
 *
 * @interface SalesStatusState
 */
export interface SalesStatusState {
  /**
   * The list of sales statuses.
   *
   * @type {Status[]}
   */
  salesStatuses: Status[];

  /**
   * Indicates if the sales statuses are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from loading the sales statuses.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for sales statuses.
 *
 * @constant {SalesStatusState} initialSalesStatusState
 * @description Provides a baseline definition for the sales statuses context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialSalesStatusState: SalesStatusState = {
  salesStatuses: [],
  loading: false,
  error: null,
};
