import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastService } from '~/app/shared/services/toast.service';
import { ButtonConfig } from '~/app/shared/interfaces/navigation/button-config.interface';
import { NavTabConfig } from '~/app/shared/interfaces/navigation/nav-tab-config.interface';

/**
 * Component responsible for displaying a dynamic heading for pages that includes titles, subtitles, badges,
 * buttons, and navigation tabs. This component is designed to be versatile, allowing for significant customization
 * through inputs while also responding dynamically to routing changes to highlight active navigation tabs.
 *
 * @Component decorator specifies the Angular component metadata:
 * @selector 'app-page-heading' - The CSS selector of the component.
 * @templateUrl './page-heading.component.html' - The path to the HTML template of the component.
 * @styleUrls ['./page-heading.component.scss'] - The path to the styles of the component.
 */
@Component({
  selector: 'app-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent {
  /**
   * Optional CSS class for the icon.
   * @type {string}
   */
  @Input() iconClass?: string;

  /**
   * Optional title text.
   * @type {string}
   */
  @Input() title?: string;

  /**
   * Optional subtitle text.
   * @type {string}
   */
  @Input() subtitle?: string;

  /**
   * Optional badge text.
   * @type {string}
   */
  @Input() badge?: string;

  /**
   * Optional main text for the title.
   * @type {string}
   */
  @Input() titleText?: string;

  /**
   * Configuration for optional buttons displayed next to the title. Each button can have its own
   * label, icon, styling, and associated action.
   * @type {ButtonConfig[]}
   */
  @Input() buttonsConfig: ButtonConfig[] = [];

  /**
   * Configuration for navigation tabs below the heading, used to switch between different views or sections
   * related to the page. Includes support for dynamic active state based on the current route.
   * @type {NavTabConfig[]}
   */
  @Input() navTabsConfig: NavTabConfig[] = [];

  /**
   * Constructs an instance of the class and subscribes to router events to update the active tab on navigation end.
   *
   * @param {ToastService} toast - Injectable toast service for displaying notifications.
   * @param {Router} router - Injectable Angular Router for handling navigation and URL manipulation.
   */
  constructor(
    private toast: ToastService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Displays a success toast.
   * @returns {void}
   */
  showSuccess(): void {
    this.toast.showSuccess('success', 'details details and details');
  }

  /**
   * Displays an informational toast.
   * @returns {void}
   */
  showInfo(): void {
    this.toast.showInfo('info', 'details details and details');
  }

  /**
   * Displays a warning toast.
   * @returns {void}
   */
  showWarning(): void {
    this.toast.showWarning('warning', 'details details and details');
  }

  /**
   * Displays an error toast.
   * @returns {void}
   */
  showError(): void {
    this.toast.showError('error', 'details details and details');
  }

  /**
   * Updates the active tab based on the provided URL.
   *
   * @param {string} activeUrl - The URL to determine the active tab.
   * @returns {void}
   */
  updateActiveTab(activeUrl: string): void {
    const lastSegment = activeUrl.split('/').pop();
    this.navTabsConfig.forEach(tab => {
      tab.isActive =
        tab.route && lastSegment ? tab.route.endsWith(lastSegment) : false;
    });
  }
}
