import { createAction, props } from '@ngrx/store';
import { ClientCompanyInterviewsState } from '~/app/states/main/client-company/profile/states/client-company-interviews/client-company-interviews.state';
import { ClientCompanyInterviewsFilters } from '~/app/shared/interfaces/client-company/profile/client-company-interviews-filters.interface';

export const loadClientCompanyInterviews = createAction(
  '[Client Company Interviews] Load Client Company Interviews',
  props<{ uuid: string; filters?: ClientCompanyInterviewsFilters }>()
);
export const loadClientCompanyInterviewsSuccess = createAction(
  '[Client Company Interviews] Load Client Company Interviews Success',
  props<{ clientCompanyInterviews: ClientCompanyInterviewsState }>()
);
export const loadClientCompanyInterviewsFailure = createAction(
  '[Client Company Interviews] Load Client Company Interviews Failure',
  props<{ error: string }>()
);

export const clientCompanyInterviewsUnauthorized = createAction(
  '[Client Company Interviews] Unauthorized',
  props<{ error: string }>()
);
