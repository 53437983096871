import { createAction, props } from '@ngrx/store';
import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';
import { JobMatchingHistory } from '~/app/shared/interfaces/jobhub/job-matching/job-matching-history.interface';
import { MatchingObject } from '~/app/shared/interfaces/jobhub/job-matching/matching-object.interface';

import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

export const loadAiClientJobMatchingRequest = createAction(
  '[Client AI Search] Load Ai Client Job Matching Request',
  props<{ formData: FormData }>()
);
export const loadAiClientJobMatchingRequestSuccess = createAction(
  '[Client AI Search] Load Ai Client Job Matching Request Success',
  props<{ request: WsRequest }>()
);
export const loadAiClientJobMatchingRequestFailure = createAction(
  '[Client AI Search] Load Ai Client Job Matching Request Failure',
  props<{ error: string }>()
);

export const updateAdvancement = createAction(
  '[Client AI Search] Update Advancement',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementSuccess = createAction(
  '[Client AI Search] Update Advancement Success',
  props<{ progress: Progress | null }>()
);
export const updateAdvancementFailure = createAction(
  '[Client AI Search] Update Advancement Failure',
  props<{ error: string }>()
);

export const loadAiClientJobMatchingCandidates = createAction(
  '[Client AI Search] Load Ai Client Job Matching Candidates',
  props<{ url: string }>()
);
export const loadAiClientJobMatchingCandidatesSuccess = createAction(
  '[Client AI Search] Load Ai Client Job Matching Candidates Success',
  props<{ matching: MatchingObject }>()
);
export const loadAiClientJobMatchingCandidatesFailure = createAction(
  '[Client AI Search] Load Ai Client Job Matching Candidates Failure',
  props<{ error: string }>()
);

export const resetAiClientJobMatchingCandidates = createAction(
  '[Client AI Search] Reset Ai Client Job Matching Candidates'
);

export const loadAiConsultingJobMatchingHistory = createAction(
  '[Client AI Search] Load Ai Consulting Job Matching History',
  props<{ limit: number; page: number }>()
);
export const loadAiConsultingJobMatchingHistorySuccess = createAction(
  '[Client AI Search] Load Ai Consulting Job Matching History Success',
  props<{ history: JobMatchingHistory }>()
);
export const loadAiConsultingJobMatchingHistoryFailure = createAction(
  '[Client AI Search] Load Ai Consulting Job Matching History Failure',
  props<{ error: string }>()
);

export const clientAISearchUnauthorized = createAction(
  '[Client AI Search] Unauthorized',
  props<{ error: string }>()
);
