import { createAction, props } from '@ngrx/store';
import { UpdateIndustries } from '~/app/states/main/settings/types/industries.types';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

export const loadIndustries = createAction('[Industry] Load Industries');
export const loadIndustriesSuccess = createAction(
  '[Industry] Load Industries Success',
  props<{ availableIndustries: Industry[]; selectedIndustries: Industry[] }>()
);
export const loadIndustriesFailure = createAction(
  '[Industry] Load Industries Failure',
  props<{ error: string }>()
);

export const assignIndustry = createAction(
  '[Industry] Assign Industry',
  props<{ industryData: UpdateIndustries }>()
);
export const assignIndustrySuccess = createAction(
  '[Industry] Assign Industry Success',
  props<{ industries: Industry[] }>()
);
export const assignIndustryFailure = createAction(
  '[Industry] Assign Industry Failure',
  props<{ error: string }>()
);

export const unassignIndustry = createAction(
  '[Industry] Unassign Industry',
  props<{ industryData: UpdateIndustries }>()
);
export const unassignIndustrySuccess = createAction(
  '[Industry] Unassign Industry Success',
  props<{ industries: Industry[] }>()
);
export const unassignIndustryFailure = createAction(
  '[Industry] Unassign Industry Failure',
  props<{ error: string }>()
);

export const industryUnauthorized = createAction(
  '[Industry] Unauthorized',
  props<{ error: string }>()
);
