import { AllKanbanCard } from '~/app/shared/interfaces/kanban/all-kanban-card.interface';
import { KanbanProfile } from '~/app/shared/interfaces/kanban/kanban-profile.interface';
import { IsseUser } from '~/app/shared/interfaces/isse-user.interface';

/**
 * Represents the state of Kanban cards.
 *
 * @interface KanbanCardsState
 */
export interface KanbanCardsState {
  /**
   * All the cards.
   *
   * @type {[key: string]: AllKanbanCard}
   */
  allCards: { [key: string]: AllKanbanCard };

  /**
   * Indicates if the Kanban columns are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban columns.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * The list of profiles assigned to a kanban card.
   * @type {KanbanCardProfile[]}
   */
  profiles: KanbanProfile[];

  /**
   * If the user is connected to the channel of a kanban.
   * @type {boolean}
   */
  connected: boolean;
  /**
   * The token for the connection to the SSE server.
   * @type {string}
   */
  token: string;

  /**
   * The users who are currently updating the current kanban card.
   *
   * @type {{ [key: string]: IsseUser[] }}
   */
  typingUsers: {
    [key: string]: IsseUser[];
  };
}

/**
 * The initial state of Kanban cards.
 *
 * @type {KanbanCardsState}
 */
export const initialKanbanCardsState: KanbanCardsState = {
  allCards: {},
  loading: false,
  error: null,
  profiles: [],
  connected: false,
  token: '',
  typingUsers: {},
};
