import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * IcePasswordComponent is a reusable Angular component that provides an enhanced input field for passwords.
 * It is designed to integrate seamlessly with Angular's reactive forms and includes features such as dynamic validation
 * and error display. The component implements the ControlValueAccessor interface, allowing it to interact directly
 * with form control objects.
 *
 * @Component decorator specifies metadata for the component.
 * @selector 'ice-password' - The CSS selector that identifies this component.
 * @templateUrl './ice-password.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./ice-password.component.scss'] - The path to the styles for this component.
 * @providers NG_VALUE_ACCESSOR - Dependency injection token that allows the component to act as a form control.
 */
@Component({
  selector: 'ice-password',
  templateUrl: './ice-password.component.html',
  styleUrls: ['./ice-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IcePasswordComponent),
      multi: true,
    },
  ],
})
export class IcePasswordComponent {
  /**
   * The label text for the password input field.
   * @type {string}
   */
  @Input({ required: true }) label!: string;

  /**
   * Placeholder text for the password input field.
   * @type {string}
   */
  @Input({ required: false }) placeholder!: string;

  /**
   * FormControl linked to the password input, used for validation and form state management.
   * @type {FormControl}
   */
  @Input({ required: true }) formControl!: FormControl;

  /**
   * Name of the FormControl within a FormGroup, used to link and manage form state.
   * @type {string}
   */
  @Input({ required: true }) formControlName!: string;

  /**
   * Specifies if the password input is a required field.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Current validation error message to display (if any).
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Flag indicating whether the form control has been submitted. Used for managing error visibility.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * The internal data model for the component, bound to the input's value.
   * @type {string}
   */
  value!: string;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Triggers the touch event callback when the password input gains focus.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Triggers the touch event callback when the password input loses focus.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a callback for value changes.
   * @param {void} fn - The callback function (value: any) => void.
   * @returns {void}
   */
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback for touch events.
   * @param {void} fn - The callback function () => void.
   * @returns {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Handles input events, updating the value and triggering change detection.
   * @param {Event} event - The input event (Event).
   * @returns {void}
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(input.value);
  }

  /**
   * Receives and processes an error message from the form validation process.
   * @param {string | null} message - The error message received from the validator.
   * @return {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }
}
