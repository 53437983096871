import { createAction, props } from '@ngrx/store';
import { Social } from '~/app/shared/interfaces/shared/social.interface';
import { CreateSaleSocialNetwork } from '~/app/states/main/sales/profile/types/sales-socials.types';

export const loadSocialNetworks = createAction(
  '[Sales Social Networks] Load Social Networks',
  props<{ saleId: string }>()
);
export const loadSocialNetworksSuccess = createAction(
  '[Sales Social Networks] Load Social Networks Success',
  props<{ socials: Social[] }>()
);
export const loadSocialNetworksFailure = createAction(
  '[Sales Social Networks] Load Social Networks Failure',
  props<{ error: string }>()
);

export const createSocialNetwork = createAction(
  '[Sales Social Networks] Create Social Network',
  props<{ saleSocialNetwork: CreateSaleSocialNetwork }>()
);
export const createSocialNetworkSuccess = createAction(
  '[Sales Social Networks] Create Social Network Success',
  props<{ social: Social }>()
);
export const createSocialNetworkFailure = createAction(
  '[Sales Social Networks] Create Social Network Failure',
  props<{ error: string }>()
);

export const deleteSocialNetwork = createAction(
  '[Sales Social Networks] Delete Social Network',
  props<{ saleId: string; uuid: string }>()
);
export const deleteSocialNetworkSuccess = createAction(
  '[Sales Social Networks] Delete Social Network Success',
  props<{ uuid: string }>()
);
export const deleteSocialNetworkFailure = createAction(
  '[Sales Social Networks] Delete Social Network Failure',
  props<{ error: string }>()
);

export const salesSocialNetworksUnauthorized = createAction(
  '[Sales Social Networks] Unauthorized',
  props<{ error: string }>()
);
