import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as TeamActions from '~/app/main/shuffle-list/states/teams/teams-shuffle-list.actions';
import { ShuffleListState } from '~/app/main/shuffle-list/shuffle-list.state';
import { AllTeamsFilters } from '~/app/shared/interfaces/crm/team/all-teams-filters.interface';

/**
 * `TeamSettingService` is responsible for managing team settings within the application.
 * It provides methods to initialize team settings, get teams by name, and load specific pages of team data.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class TeamShuffleListService {
  /**
   * Creates an instance of `TeamShuffleListService`.
   *
   * @param {Store<ShuffleListState>} store - The NgRx store instance, which is injected to dispatch actions and select state.
   */
  constructor(private store: Store<ShuffleListState>) {}

  /**
   * Initializes the team settings by dispatching an action to load teams with a default limit and page.
   *
   * @param {AllTeamsFilters} filters - Object with filters
   * @returns {void}
   */
  loadTeams(filters?: AllTeamsFilters): void {
    this.store.dispatch(TeamActions.loadAllTeamsShuffleList({ filters }));
  }
}
