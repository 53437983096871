import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, switchMap, take } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AllOfficesState } from '~/app/states/main/settings/states/offices/offices.state';
import { ProfileCategory } from '~/app/shared/interfaces/crm/profile/profile-category.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Company } from '~/app/shared/interfaces/settings/company.interface';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { SectorCategory } from '~/app/shared/interfaces/shared/sector/sector-category.interface';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { CompanyReferences } from '~/app/shared/interfaces/company-references.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { environment } from '~/environments/environment';
import * as DataActions from '~/app/shared/states/data/data.actions';

/**
 * `DataEffects` orchestrates the handling of asynchronous operations related to data fetching in the application.
 * This class uses Angular's NgRx Effects to manage side effects stemming from actions related to data management, such as fetching and updating data from and to the server.
 * It uses the HttpClient to perform API requests and handles the dispatching of success or failure actions based on the outcomes of these requests.
 *
 * The effects in this class include:
 * - `loadCandidates$`: Fetches candidate data from the server.
 * - `loadCandidateStatuses$`: Fetches candidate status data.
 * - `loadCandidateTags$`: Retrieves tags associated with candidates.
 * - `loadSalesStatuses$`: Fetches sales status data.
 * - `loadNoteCategories$`: Retrieves note categories.
 * - `loadCategoriesWithSectors$`: Fetches categories along with their related sectors.
 * - `loadOffices$`: Fetches data related to offices.
 * - `loadIndustries$`: Retrieves industry data.
 * - `loadClientCompanies$`: Fetches client company data.
 * - `loadCompanyReferences$`: Retrieves company references.
 * - `loadSkills$`: Fetches skills based on a provided name or returns all skills.
 * - `loadTeams$`: Fetches team data.
 * - `loadUsers$`: Retrieves user data.
 * - `loadTimelines$`: Fetches timeline data.
 *
 * Each effect listens for a specific action, checks for permissions, makes an API request, and then either dispatches a success action with the data or a failure action if an error occurs.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class DataEffects {
  /**
   * Creates an observable effect to load candidates. It listens for the loadCandidates action, checks permissions,
   * and fetches data from the server. Upon success or failure, it dispatches the corresponding success or failure actions.
   */
  loadCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCandidates),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .post<PaginationData>(
            `${environment.apiUrl}/v1/profiles/search`,
            { limit: 10000, page: 0 },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(candidates =>
              DataActions.loadCandidatesSuccess({ candidates: candidates.data })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadCandidatesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Similar to loadCandidates$, this effect handles loading candidate statuses.
   */
  loadCandidateStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCandidateStatuses),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Status[]>(`${environment.apiUrl}/v1/profiles/status`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(candidateStatuses =>
              DataActions.loadCandidateStatusesSuccess({
                candidateStatuses,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadCandidateStatusesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load candidate tags. It listens for the `loadCandidateTags` action, checks for permissions,
   * and makes an HTTP GET request to fetch candidate tags. Upon receiving the data, it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadCandidateTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCandidateTags),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Tag[]>(`${environment.apiUrl}/v1/profiles/tags`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(candidateTags =>
              DataActions.loadCandidateTagsSuccess({ candidateTags })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadCandidateTagsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load sales statuses. Listens for `loadSalesStatuses`, checks permissions, and fetches sales statuses.
   * Dispatches success or failure actions based on the result of the HTTP request.
   */
  loadSalesStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadSalesStatuses),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Status[]>(`${environment.apiUrl}/v1/sales/status`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesStatuses =>
              DataActions.loadSalesStatusesSuccess({
                salesStatuses,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadSalesStatusesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load note categories. Triggers on `loadNoteCategories`, fetches note categories,
   * and handles responses with success or failure actions.
   */
  loadNoteCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadNoteCategories),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<NoteCategory[]>(`${environment.apiUrl}/v1/noteCategories`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(noteCategories =>
              DataActions.loadNoteCategoriesSuccess({
                noteCategories,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadNoteCategoriesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load categories with sectors. It fetches the main categories and then fetches sector details for each category,
   * combining the results into a structured format suitable for the UI.
   */
  loadCategoriesWithSectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCategoriesWithSectors),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<ProfileCategory[]>(`${environment.apiUrl}/v1/sectorJobs`)
          .pipe(
            switchMap(categories => {
              if (!categories.length) return of([]);

              return forkJoin(
                categories.map(category =>
                  this.http
                    .get<SectorCategory>(
                      `${environment.apiUrl}/v1/sectorJobs/${category.uuid}`
                    )
                    .pipe(
                      map(sectorCategory => ({
                        groupLabel: category.name,
                        value: category.uuid,
                        items: sectorCategory.companySectorJobs.map(job => ({
                          label: job.sectorJob.name,
                          value: {
                            categoryId: category.uuid,
                            sectorId: job.sectorJob.uuid,
                          },
                        })),
                      }))
                    )
                )
              );
            }),
            map(categoriesWithSectors => {
              return DataActions.loadCategoriesWithSectorsSuccess({
                categoriesWithSectors,
              });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                DataActions.loadCategoriesWithSectorsFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load office data. Listens for `loadOffices` action, checks permissions,
   * and makes an HTTP GET request to fetch office data. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadOffices),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<AllOfficesState>(`${environment.apiUrl}/v1/offices`, {
            params: { limit: 100 },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(offices =>
              DataActions.loadOfficesSuccess({ offices: offices.data })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadOfficesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load industries. It listens for the `loadIndustries` action, checks for permissions,
   * and makes an HTTP GET request to fetch industry data. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadIndustries),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Industry[]>(`${environment.apiUrl}/v1/industries`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(industries =>
              DataActions.loadIndustriesSuccess({ industries })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadIndustriesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load client companies. It listens for the `loadClientCompanies` action, checks for permissions,
   * and makes an HTTP GET request to fetch client companies. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadClientCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCompanies),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<Company[]>(`${environment.apiUrl}/v1/companies/all`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(companies => DataActions.loadCompaniesSuccess({ companies })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadCompaniesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load company name from subdomain. It listens for the `loadCompanyNameFromSubdomain` action, checks for permissions,
   * and makes an HTTP GET request to fetch company name from subdomain. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  companyNameFromSubdomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCompanyNameFromSubdomain),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(({ subdomain }) =>
        this.http
          .get<{ name: string }>(
            `${environment.apiUrl}/v1/companies/${subdomain}`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(companyName =>
              DataActions.loadCompanyNameFromSubdomainSuccess({
                companyName: companyName,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                DataActions.loadCompanyNameFromSubdomainFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to load company references. It listens for the `loadCompanyReferences` action, checks for permissions,
   * and makes an HTTP GET request to fetch company references. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadCompanyReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCompanyReferences),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<
            CompanyReferences[]
          >(`${environment.apiUrl}/v1/companyReferences`, { withCredentials: true })
          .pipe(
            take(1),
            map(companyReferences =>
              DataActions.loadCompanyReferencesSuccess({ companyReferences })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadCompanyReferencesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load team data. It listens for the `loadTeams` action, checks for permissions,
   * and makes an HTTP GET request to fetch team data. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadTeams),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData<Team>>(`${environment.apiUrl}/v1/teams`, {
            params: { limit: 100 },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(teams => DataActions.loadTeamsSuccess({ teams: teams.data })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadTeamsFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load user data. It listens for the `loadUsers` action, checks for permissions,
   * and makes an HTTP GET request to fetch user data. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadUsers),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData>(`${environment.apiUrl}/v1/users`, {
            params: { limit: 1000 },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(users => DataActions.loadUsersSuccess({ users })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadUsersFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load timelines. It listens for the `loadTimelines` action, checks for permissions,
   * and makes an HTTP GET request to fetch timeline data. Upon receiving the data,
   * it dispatches a success action with the data or a failure action if an error occurs.
   */
  loadTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadTimelines),
      checkPermission(DataActions.dataActionsUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData>(`${environment.apiUrl}/v1/timelines`, {
            params: { limit: 1000 },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(timelines => DataActions.loadTimelinesSuccess({ timelines })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(DataActions.loadTimelinesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Constructor for DataEffects.
   * @param {Actions} actions$ Injected actions observable that allows the effect to react to actions dispatched to the store.
   * @param {HttpClient} http Injected HttpClient for making API calls.
   * @param {HttpErrorsService} httpErrors Injected service for handling HTTP error responses.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService
  ) {}
}
