import { createReducer, on } from '@ngrx/store';
import * as ClientCompanySalesActions from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.actions';
import { initialClientCompanySalesState } from '~/app/states/main/client-company/profile/states/client-company-sales/client-company-sales.state';

export const clientCompanySalesReducer = createReducer(
  initialClientCompanySalesState,

  on(ClientCompanySalesActions.loadClientCompanySales, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    ClientCompanySalesActions.loadClientCompanySalesSuccess,
    (state, { clientCompanySales }) => ({
      ...state,
      ...clientCompanySales,
      loading: false,
    })
  ),
  on(
    ClientCompanySalesActions.loadClientCompanySalesFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    ClientCompanySalesActions.clientCompanySalesUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
