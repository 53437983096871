import { createAction, props } from '@ngrx/store';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';

export const loadCecrls = createAction('[All Enums] Load Cecrls');
export const loadCecrlsSuccess = createAction(
  '[All Enums] Load Cecrls Success',
  props<{ cecrls: LabelValueInterface[] }>()
);
export const loadCecrlsFailure = createAction(
  '[All Enums] Load Cecrls Failure',
  props<{ error: string }>()
);

export const loadOfferStatuses = createAction(
  '[All Enums] Load Offer Statuses'
);
export const loadOfferStatusesSuccess = createAction(
  '[All Enums] Load Offer Statuses Success',
  props<{ offerStatuses: LabelValueInterface[] }>()
);
export const loadOfferStatusesFailure = createAction(
  '[All Enums] Load Offer Statuses Failure',
  props<{ error: string }>()
);

export const loadOfferUnits = createAction('[All Enums] Load Offer Units');
export const loadOfferUnitsSuccess = createAction(
  '[All Enums] Load Offer Units Success',
  props<{ offerUnits: LabelValueInterface[] }>()
);
export const loadOfferUnitsFailure = createAction(
  '[All Enums] Load Offer Units Failure',
  props<{ error: string }>()
);

export const loadOfferVisibilities = createAction(
  '[All Enums] Load Offer Visibilities'
);
export const loadOfferVisibilitiesSuccess = createAction(
  '[All Enums] Load Offer Visibilities Success',
  props<{ offerVisibilities: LabelValueInterface[] }>()
);
export const loadOfferVisibilitiesFailure = createAction(
  '[All Enums] Load Offer Visibilities Failure',
  props<{ error: string }>()
);

export const loadOfferDocumentTypes = createAction(
  '[All Enums] Load Offer Document Types'
);
export const loadOfferDocumentTypesSuccess = createAction(
  '[All Enums] Load Offer Document Types Success',
  props<{ offerDocumentTypes: LabelValueInterface[] }>()
);
export const loadOfferDocumentTypesFailure = createAction(
  '[All Enums] Load Offer Document Types Failure',
  props<{ error: string }>()
);

export const loadProfileExperiences = createAction(
  '[All Enums] Load Profile Experiences'
);
export const loadProfileExperiencesSuccess = createAction(
  '[All Enums] Load Profile Experiences Success',
  props<{ profileExperiences: LabelValueInterface[] }>()
);
export const loadProfileExperiencesFailure = createAction(
  '[All Enums] Load Profile Experiences Failure',
  props<{ error: string }>()
);

export const loadProfileFiles = createAction('[All Enums] Load Profile Files');
export const loadProfileFilesSuccess = createAction(
  '[All Enums] Load Profile Files Success',
  props<{ profileFiles: LabelValueInterface[] }>()
);
export const loadProfileFilesFailure = createAction(
  '[All Enums] Load Profile Files Failure',
  props<{ error: string }>()
);

export const loadProfileTimeUnits = createAction(
  '[All Enums] Load Profile Time Units'
);
export const loadProfileTimeUnitsSuccess = createAction(
  '[All Enums] Load Profile Time Units Success',
  props<{ profileTimeUnits: LabelValueInterface[] }>()
);
export const loadProfileTimeUnitsFailure = createAction(
  '[All Enums] Load Profile Time Units Failure',
  props<{ error: string }>()
);

export const loadProfileTypes = createAction('[All Enums] Load Profile Types');
export const loadProfileTypesSuccess = createAction(
  '[All Enums] Load Profile Types Success',
  props<{ profileTypes: LabelValueInterface[] }>()
);
export const loadProfileTypesFailure = createAction(
  '[All Enums] Load Profile Types Failure',
  props<{ error: string }>()
);

export const loadProposalStatuses = createAction(
  '[All Enums] Load Proposal Statuses'
);
export const loadProposalStatusesSuccess = createAction(
  '[All Enums] Load Proposal Statuses Success',
  props<{ proposalStatuses: LabelValueInterface[] }>()
);
export const loadProposalStatusesFailure = createAction(
  '[All Enums] Load Proposal Statuses Failure',
  props<{ error: string }>()
);

export const loadReminders = createAction('[All Enums] Load Reminders');
export const loadRemindersSuccess = createAction(
  '[All Enums] Load Reminders Success',
  props<{ reminders: LabelValueInterface[] }>()
);
export const loadRemindersFailure = createAction(
  '[All Enums] Load Reminders Failure',
  props<{ error: string }>()
);

export const loadSaleInformations = createAction(
  '[All Enums] Load Sale Informations'
);
export const loadSaleInformationsSuccess = createAction(
  '[All Enums] Load Sale Informations Success',
  props<{ saleInformations: LabelValueInterface[] }>()
);
export const loadSaleInformationsFailure = createAction(
  '[All Enums] Load Sale Informations Failure',
  props<{ error: string }>()
);

export const loadSocials = createAction('[All Enums] Load Socials');
export const loadSocialsSuccess = createAction(
  '[All Enums] Load Socials Success',
  props<{ socials: LabelValueInterface[] }>()
);
export const loadSocialsFailure = createAction(
  '[All Enums] Load Socials Failure',
  props<{ error: string }>()
);

export const loadGenders = createAction('[All Enums] Load Genders');
export const loadGendersSuccess = createAction(
  '[All Enums] Load Genders Success',
  props<{ genders: LabelValueInterface[] }>()
);
export const loadGendersFailure = createAction(
  '[All Enums] Load Genders Failure',
  props<{ error: string }>()
);

export const loadSkillExperience = createAction(
  '[All Enums] Load Skill Experiences'
);
export const loadSkillExperienceSuccess = createAction(
  '[All Enums] Load Skill Experiences Success',
  props<{ skillExperiences: LabelValueInterface[] }>()
);
export const loadSkillExperienceFailure = createAction(
  '[All Enums] Load Skill Experiences Failure',
  props<{ error: string }>()
);

export const loadRates = createAction('[All Enums] Load Rates');
export const loadRatesSuccess = createAction(
  '[All Enums] Load Rates Success',
  props<{ rates: LabelValueInterface[] }>()
);
export const loadRatesFailure = createAction(
  '[All Enums] Load Rates Failure',
  props<{ error: string }>()
);

export const loadTarget = createAction('[All Enums] Load Target');
export const loadTargetSuccess = createAction(
  '[All Enums] Load Target Success',
  props<{ targets: LabelValueInterface[] }>()
);
export const loadTargetFailure = createAction(
  '[All Enums] Load Target Failure',
  props<{ error: string }>()
);

export const loadGoalTypes = createAction('[All Enums] Load Goal Types');
export const loadGoalTypesSuccess = createAction(
  '[All Enums] Load Goal Types Success',
  props<{ goalTypes: LabelValueInterface[] }>()
);
export const loadGoalTypesFailure = createAction(
  '[All Enums] Load Goal Types Failure',
  props<{ error: string }>()
);

export const enumsUnauthorized = createAction(
  '[All Enums] Unauthorized',
  props<{ error: string }>()
);
