import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  selectSecureLoginToken,
  selectToken,
} from '~/app/auth/state/login/login.selectors';
import { AppState } from '~/app/core/state/app.state';

/**
 * Authentication guard for routing that checks for the presence of an authentication token.
 * If a token exists, it allows route activation. If no token is found, it redirects to an 'unauthorized' route.
 *
 * @returns {Observable<boolean|UrlTree>} - Returns true if a token exists, otherwise it returns a UrlTree for redirection.
 */
export const authGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectToken).pipe(
    map(token => {
      if (token) {
        return true;
      } else {
        return router.createUrlTree(['unauthorized']);
      }
    })
  );
};

/**
 * Route guard for secure paths that require a higher security authentication token.
 * It checks for the presence of a secure login token and allows route activation if the token exists.
 * If the token does not exist, it redirects the user to the login page.
 *
 * @returns {Observable<boolean|UrlTree>} - Returns true if a secure token exists, otherwise it returns a UrlTree for redirection to the login page.
 */
export const secureLoginGuard: CanActivateFn = (): Observable<
  boolean | UrlTree
> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectSecureLoginToken).pipe(
    map(token => {
      if (token) {
        return true;
      } else {
        return router.createUrlTree(['login']);
      }
    })
  );
};
