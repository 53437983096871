import { createAction, props } from '@ngrx/store';
import { AllCompanyOffersState } from '~/app/states/main/jobhub/states/company-offer/company-offers.state';
import { AllCompanyOffersFilters } from '~/app/shared/interfaces/jobhub/company/all-company-offers-filters.interface';

export const loadAllCompanyOffers = createAction(
  '[Company Offers] Load All Company Offers',
  props<{ filters?: AllCompanyOffersFilters }>()
);
export const loadAllCompanyOffersSuccess = createAction(
  '[Company Offers] Load All Company Offers Success',
  props<{ companyOffers: AllCompanyOffersState }>()
);
export const loadAllCompanyOffersFailure = createAction(
  '[Company Offers] Load All Company Offers Failure',
  props<{ error: string }>()
);

export const companyOfferUnauthorized = createAction(
  '[Company Offers] Unauthorized',
  props<{ error: string }>()
);
