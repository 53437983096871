import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FileRemoveEvent,
  FileSendEvent,
  FileUpload,
  FileUploadEvent,
  FileUploadHandlerEvent,
} from 'primeng/fileupload';
import { FileSelectEvent } from 'primeng/fileupload/fileupload.interface';
import { take } from 'rxjs';
import { FileNameClipPipe } from '~/app/shared/pipes/file-name-clip.pipe';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';

/**
 * Component responsible for handling file uploads with advanced configurations such as custom templates, multiple files,
 * and permission-based features. This component is built on top of PrimeNG's FileUpload component, providing additional
 * features like file size and name constraints, and custom UI options.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'ice-file-upload' - The CSS selector that identifies this component in a template.
 * @templateUrl './ice-file-upload.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./ice-file-upload.component.scss'] - The path to the styles for this component.
 */
@Component({
  selector: 'ice-file-upload',
  templateUrl: './ice-file-upload.component.html',
  styleUrls: ['./ice-file-upload.component.scss'],
})
export class IceFileUploadComponent implements AfterViewInit {
  /**
   * Label for the upload button.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Identifier for the input element; defaults to 'avatar'.
   * @type {string}
   */
  @Input() id = 'avatar';

  /**
   * Specifies the upload mode ('basic' or 'advanced').
   * @type {'advanced' | 'basic' | undefined}
   */
  @Input() mode: 'advanced' | 'basic' | undefined = 'basic';

  /**
   * Name attribute for the file input element.
   * @type {string}
   */
  @Input() name = 'avatar';

  /**
   * The server endpoint for file uploads.
   * @type {string}
   */
  @Input() url = './upload.php';

  /**
   * Accepted file types for the upload.
   * @type {string}
   */
  @Input() accept = '';

  /**
   * Maximum file size allowed for uploads.
   * @type {number}
   */
  @Input() maxFileSize = 1000000;

  /**
   * Maximum length of the file name.
   * @type {number}
   */
  @Input() maxFileName = 25;

  /**
   * CSS class for the upload component.
   * @type {string | undefined}
   */
  @Input() class: string | undefined;

  /**
   * Label for the choose button.
   * @type {string}
   */
  @Input() chooseLabel = 'Select';

  /**
   * Icon for the choose button.
   * @type {string | undefined}
   */
  @Input() chooseIcon: string | undefined;

  /**
   * Label for the upload button.
   * @type {string}
   */
  @Input() uploadLabel = 'Upload';

  /**
   * Label for the cancel button.
   * @type {string}
   */
  @Input() cancelLabel = 'Cancel';

  /**
   * Flag to disable the upload functionality.
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Limit on the number of files that can be uploaded.
   * @type {number}
   */
  @Input() fileLimit = 1;

  /**
   * Enables or disables custom file upload handling.
   * @type {boolean}
   */
  @Input() customUpload = false;

  /**
   * Allows multiple files to be selected if set to true.
   * @type {boolean}
   */
  @Input() multiple = false;

  /**
   * Array of files currently selected for upload.
   * @type {File[]}
   */
  @Input() files: File[] = [];

  /**
   * Indicates if the input is required.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Rule for permissions handling.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Behavior when the user does not have the required permissions.
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Dynamic condition for showing the component.
   * @type {any}
   */
  @Input() iceIf: any = true;

  /**
   * Provides a reference to the component's root DOM element.
   * @type {ElementRef}
   */
  @ViewChild('elementRef') element!: ElementRef;

  /**
   * Provides a reference to the PrimeNG FileUpload component.
   * @type {FileUpload}
   */
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  /**
   * Template reference for custom content.
   * @type {TemplateRef<any> | undefined}
   */
  @ContentChild(TemplateRef) contentTemplate?: TemplateRef<any>;

  /**
   * Emits events when files are selected.
   * @type {EventEmitter<FileSelectEvent>}
   */
  @Output() onSelect: EventEmitter<FileSelectEvent> =
    new EventEmitter<FileSelectEvent>();

  /**
   * Emits events when files are successfully uploaded.
   * @type {EventEmitter<FileUploadEvent>}
   */
  @Output() onUpload: EventEmitter<FileUploadEvent> =
    new EventEmitter<FileUploadEvent>();

  /**
   * Emits events when files are being sent to the server.
   * @type {EventEmitter<FileSendEvent>}
   */
  @Output() onSend: EventEmitter<FileSendEvent> =
    new EventEmitter<FileSendEvent>();

  /**
   * Emits events when files are removed.
   * @type {EventEmitter<FileRemoveEvent>}
   */
  @Output() onRemove: EventEmitter<FileRemoveEvent> =
    new EventEmitter<FileRemoveEvent>();

  /**
   * Emits events for custom upload handling.
   * @type {EventEmitter<FileUploadHandlerEvent>}
   */
  @Output() uploadHandler: EventEmitter<FileUploadHandlerEvent> =
    new EventEmitter<FileUploadHandlerEvent>();

  /**
   * Constructs the IceFileUploadComponent with necessary dependencies.
   *
   * @param {PermissionsService} permissionService - The service used to check user permissions to control the display and functionality of the upload component based on the permissions.
   * @param {FileNameClipPipe} fileNameClipPipe - A pipe used to shorten the filename that appears when a file is selected if it exceeds a set maximum length. This helps in maintaining UI aesthetics and readability.
   */
  constructor(
    private permissionService: PermissionsService,
    private fileNameClipPipe: FileNameClipPipe
  ) {}

  /**
   * Lifecycle hook that is called after the component's view has been fully initialized.
   * This method checks permissions and modifies the UI accordingly based on the provided rule and unauthorized behavior.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.DISABLE:
                this.disableElement();
                break;
              case PermissionBehaviourEnum.NONE:
              default:
                this.hideElement();
            }
          }
        });
  }

  /**
   * Emits a select event when a file is selected.
   * @param {FileSelectEvent} event - The event object containing details about the selected file.
   * @return {void}
   */
  onSelectFile(event: FileSelectEvent): void {
    this.onSelect.emit(event);

    if (this.fileUpload.files.length) {
      this.fileUpload.uploadLabel = this.fileNameClipPipe.transform(
        this.fileUpload.files[0].name,
        this.maxFileName
      );
    }
  }

  /**
   * Clears the file selection.
   * @return {void}
   */
  clear(): void {
    this.fileUpload.clear();
  }

  /**
   * Hides the upload element from the UI.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the upload functionality.
   * @return {void}
   */
  private disableElement(): void {
    this.disabled = true;
  }
}
