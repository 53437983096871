/**
 * Enum for defining permission behaviors.
 * This enum is used to specify how the system should behave when a user does not have the necessary permissions.
 *
 * @enum {string}
 * @property {string} NONE - No special behavior, the action will be allowed without restriction.
 * @property {string} WARNING - A warning will be shown to the user, but the action will still be allowed.
 * @property {string} DISABLE - The action will be disabled and the user will not be able to perform it.
 */
export enum PermissionBehaviourEnum {
  NONE = 'none',
  WARNING = 'warning',
  DISABLE = 'disable',
}
