import { Kanban } from '~/app/shared/interfaces/kanban/kanban.interface';

/**
 * Represents the state of Kanban boards.
 *
 * @interface AllKanbansState
 */
export interface AllKanbansState {
  /**
   * The total number of Kanban boards.
   *
   * @type {number}
   */
  count: number;

  /**
   * The current page number.
   *
   * @type {number}
   */
  page: number;

  /**
   * The maximum number of Kanban boards per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there is a next page.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * An array of Kanban boards.
   *
   * @type {Kanban[]}
   */
  data: Kanban[];

  /**
   * The currently selected Kanban board.
   *
   * @type {Kanban | null}
   */
  kanban: Kanban | null;

  /**
   * Indicates if the Kanban boards are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message, if any, from loading the Kanban boards.
   *
   * @type {string | null}
   */
  error: string | null;

  /**
   * If the user is connected to the channel of a kanban.
   * @type {boolean}
   */
  connected: boolean;
  /**
   * The token for the connection to the SSE server.
   * @type {string}
   */
  token: string;
}

/**
 * The initial state of Kanban boards.
 *
 * @type {AllKanbansState}
 */
export const initialAllKanbansState: AllKanbansState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  kanban: null,
  loading: false,
  error: null,
  connected: false,
  token: '',
};
