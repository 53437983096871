import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';

/**
 * Represents the state of the sales reminders module.
 *
 * @interface SalesReminderState
 */
export interface SalesReminderState {
  /**
   * The list of sales reminder records.
   *
   * @type {Reminder[]}
   */
  salesReminders: Reminder[];

  /**
   * Indicates whether the sales reminders are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the sales reminders module.
 *
 * @type {SalesReminderState}
 */
export const initialSalesRemindersState: SalesReminderState = {
  salesReminders: [],
  loading: false,
  error: null,
};
