import { createAction, props } from '@ngrx/store';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';

export const loadUsers = createAction(
  '[Kanban Users] Load Users',
  props<{
    limit: number;
    page: number;
    name?: string;
  }>()
);
export const loadUsersSuccess = createAction(
  '[Kanban Users] Load Users Success',
  props<{ users: PaginationData<User> }>()
);
export const loadUsersFailure = createAction(
  '[Kanban Users] Load Users Failure',
  props<{ error: string }>()
);

export const userUnauthorized = createAction(
  '[Kanban Users] Unauthorized',
  props<{ error: string }>()
);
