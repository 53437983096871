import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { registerUserValidation } from '@npmicedev/icemodule/lib/validations/users';
import { combineLatest, Observable, take } from 'rxjs';
import { ZodObject } from 'zod';
import * as RegisterActions from '~/app/auth/state/register/register.actions';
import { RegisterService } from '~/app/auth/services/register.service';
import {
  selectCompanyFormData,
  selectOfficeFormData,
  selectRootUserFormData,
} from '~/app/auth/state/register/register.selectors';
import { AppState } from '~/app/core/state/app.state';
import { CountryCodes } from '~/app/shared/interfaces/country-codes.interface';
import { ToastService } from '~/app/shared/services/toast.service';
import { UtilsService } from '~/app/shared/services/utils.service';
import { iso31661Codes } from '~/app/shared/const/country-code.const';

/**
 * Component for collecting and submitting root user registration details. It provides a form for entering user data
 * such as first name, last name, email, password, and phone number. This component also integrates complex form validation
 * and data submission logic.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-root-user-step' - The CSS selector that identifies this component in a template.
 * @templateUrl './root-user-step.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./root-user-step.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-root-user-step',
  templateUrl: './root-user-step.component.html',
  styleUrls: ['./root-user-step.component.scss'],
})
export class RootUserStepComponent implements OnInit {
  /**
   * FormGroup to manage the root user registration form
   * @type {FormGroup}
   */
  rootUserForm!: FormGroup;

  /**
   * Array of country codes for phone number input
   * @type {CountryCodes[]}
   */
  countryCodes: CountryCodes[] = iso31661Codes;

  /**
   * Flag to track if the form has been submitted to show validation feedback
   * @type {boolean}
   */
  submitted = false;

  /**
   * Constructs the RootUserStepComponent with necessary dependencies.
   *
   * @param {FormBuilder} formBuilder - Service to create FormGroup based on provided configuration.
   * @param {Store<AppState>} store - NgRx store for managing state.
   * @param {UtilsService} utils - Service for utility functions like filtering form data.
   * @param {ToastService} toast - Service to display toast notifications for errors or messages.
   * @param {RegisterService} register - Service to handle registration logic and API calls.
   */
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private utils: UtilsService,
    private toast: ToastService,
    private register: RegisterService
  ) {}

  /**
   * Accessor for the 'firstname' form control
   * @type {FormControl}
   */
  get firstname(): FormControl {
    return this.rootUserForm.get('firstname') as FormControl;
  }

  /**
   * Accessor for the 'lastname' form control
   * @type {FormControl}
   */
  get lastname(): FormControl {
    return this.rootUserForm.get('lastname') as FormControl;
  }

  /**
   * Accessor for the 'email' form control
   * @type {FormControl}
   */
  get email(): FormControl {
    return this.rootUserForm.get('email') as FormControl;
  }

  /**
   * Accessor for the 'password' form control
   * @type {FormControl}
   */
  get password(): FormControl {
    return this.rootUserForm.get('password') as FormControl;
  }

  /**
   * Accessor for the 'phone' form control
   * @type {FormControl}
   */
  get phone(): FormControl {
    return this.rootUserForm.get('phone') as FormControl;
  }

  /**
   * Accessor for the 'phoneIso' form control, typically for selecting the phone's country code
   * @type {FormControl}
   */
  get phoneIso(): FormControl {
    return this.rootUserForm.get('phoneIso') as FormControl;
  }

  /**
   * Initializes component logic and checks for completion of prior steps before allowing user to fill this form.
   * @return {void}
   */
  ngOnInit(): void {
    // this.store
    //   .select(selectCompanyFormData)
    //   .pipe(take(1))
    //   .subscribe(res => {
    //     if (res === null) {
    //       this.router.navigate(['register/company']);
    //       this.toast.showWarning(
    //         'Form not completed',
    //         'Please fill the first form before'
    //       );
    //     } else {
    //       this.store
    //         .select(selectOfficeFormData)
    //         .pipe(take(1))
    //         .subscribe(res => {
    //           if (res === null) {
    //             this.router.navigate(['register/office']);
    //             this.toast.showWarning(
    //               'Form not completed',
    //               'Please fill the second form before'
    //             );
    //           }
    //         });
    //     }
    //   });
    this.initRootUserForm();
  }

  /**
   * Initializes the root user registration form with required fields and validators.
   * @return {void}
   */
  initRootUserForm(): void {
    this.rootUserForm = this.formBuilder.group({
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      email: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(100)],
      ],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
      ],
      phone: [null, [Validators.maxLength(20)]],
      phoneIso: [{}, [Validators.maxLength(3)]],
    });

    this.store.select(selectRootUserFormData).subscribe(rootUserData => {
      if (rootUserData) {
        this.rootUserForm.patchValue(rootUserData);
      }
    });
  }

  /**
   * Submits the root user registration form, performs validation, and dispatches the data for final submission.
   * @return {void}
   */
  onRegister(): void {
    if (this.rootUserForm.valid) {
      const formValue = { ...this.rootUserForm.value };
      if (formValue.phoneIso && formValue.phoneIso.code) {
        formValue.phoneIso = formValue.phoneIso.code;
      }

      const rootUserSchema = (registerUserValidation.body as ZodObject<any>)
        .shape.user;

      const result = rootUserSchema?.safeParse(
        this.utils.filterValues(formValue)
      );

      if (result?.success) {
        this.store.dispatch(
          RegisterActions.updateRootUserForm({
            rootUserData: this.rootUserForm.value,
          })
        );

        const company$: Observable<any> = this.store.select(
          selectCompanyFormData
        );
        const office$: Observable<any> =
          this.store.select(selectOfficeFormData);
        const user$: Observable<any> = this.store.select(
          selectRootUserFormData
        );

        combineLatest([company$, office$, user$])
          .pipe(take(1))
          .subscribe(([companyData, officeData]) => {
            const formData = {
              company: this.utils.filterValues(companyData),
              office: this.utils.filterValues(officeData),
              user: this.utils.filterValues(formValue),
            };

            const filteredFormData = this.utils.filterValues(formData);

            this.register.register(filteredFormData);
          });
      } else {
        this.toast.showError(
          'Error',
          result?.error.errors[0]?.message ?? 'Validation failed'
        );
      }
    }

    this.submitted = true;
  }
}
