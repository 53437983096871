import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { take } from 'rxjs';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { PermissionsService } from '~/app/shared/services/permissions.service';

/**
 * `NewIceCheckboxComponent` is an Angular component that provides a checkbox with permission controls.
 * It checks for a specific permission rule before allowing interaction with the checkbox.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'new-ice-checkbox' - The CSS selector that identifies this component.
 * @templateUrl './new-ice-checkbox.component.html' - Path to the HTML template for this component.
 * @styleUrl './new-ice-checkbox.component.scss' - Path to the styles for this component.
 */
@Component({
  selector: 'new-ice-checkbox',
  templateUrl: './new-ice-checkbox.component.html',
  styleUrl: './new-ice-checkbox.component.scss',
})
export class NewIceCheckboxComponent implements AfterContentInit {
  /**
   * Determines whether the checkbox is checked or unchecked.
   *
   * @type {boolean}
   */
  @Input() checked = false;

  /**
   * Flag indicating whether the checkbox is disabled.
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Permission rule identifier used to check whether the user has access to interact with the checkbox.
   *
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Event emitted when the checkbox is clicked.
   *
   * @type {EventEmitter<MouseEvent>}
   */
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * Defines the behavior when the user does not have the required permission.
   * The behavior is determined by the `PermissionBehaviourEnum`.
   *
   * @type {PermissionBehaviourEnum | undefined}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum | undefined =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Constructor initializes the `PermissionsService` and the host element reference.
   *
   * @param {PermissionsService} permissionService - Service used to check for permissions.
   * @param {ElementRef} element - The host element reference for this component.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Lifecycle hook that runs after Angular projects content into the component.
   * It checks the provided permission rule, and if the user lacks the required permission,
   * the checkbox is either hidden or disabled based on the `unauthorizedBehaviour` setting.
   *
   * @return {void}
   */
  ngAfterContentInit(): void {
    if (this.rule) {
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            this.element.nativeElement.removeAllListeners('click');
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.NONE:
                this.hideElement(); // Hide the checkbox if no specific behavior is set
                break;
              case PermissionBehaviourEnum.DISABLE:
              default:
                this.disableElement(); // Disable the checkbox by default
            }
          }
        });
    }
  }

  /**
   * Hides the checkbox by setting its display style to 'none'.
   *
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the checkbox, making it non-interactive.
   * Also completes the `onClick` event emitter to signal the button is disabled.
   *
   * @return {void}
   */
  private disableElement(): void {
    this.disabled = true;
    this.onClick.complete(); // Completes the event to prevent further clicks
  }
}
