import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '~/app/states/main/crm/ai-creation/services/profile.service';
import { AlertService } from '~/app/shared/services/alert.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { BaseSocketService } from '~/app/core/services/base-socket.service';
import { AlertEnum } from '~/app/shared/enums/alert.enum';
import { ProgressToastService } from '~/app/shared/services/progress-toast.service';

/**
 * WebSocket profile service.
 */
@Injectable({
  providedIn: 'root',
})
export class WsProfileService extends BaseSocketService {
  /**
   * Constructs an instance of the WsProfileService.
   * @param {ProfileService} creationProfileService - The profile service.
   * @param {Router} router - The router service.
   * @param {HttpErrorsService} httpErrors - The HTTP errors service.
   * @param {AlertService} alertService - The alert service.
   * @param {ProgressToastService} progressToastService - The progress toast service.
   */
  constructor(
    private creationProfileService: ProfileService,
    private router: Router,
    private httpErrors: HttpErrorsService,
    private alertService: AlertService,
    private progressToastService: ProgressToastService
  ) {
    super();
  }

  /**
   * Sends the WebSocket request.
   * @returns {void}
   */
  onOpen = (): void => {
    this.wsClient?.send(
      JSON.stringify({
        action: this.request?.action,
        content: this.request?.content,
      })
    );
  };

  /**
   * Handles the message event.
   * @param {MessageEvent} message - The message event.
   * @returns {void}
   */
  onMessage = (message: MessageEvent): void => {
    try {
      const data = JSON.parse(message.data);
      if (data.content && data.progress !== undefined) {
        if (data.values) {
          this.progressToastService.toggleToast();
          this.alertService.showAlert('Profile Redirection', {
            title: 'Do you want to be redirected?',
            message: 'Your profile auto-creation is finished.',
            type: AlertEnum.INFO,
            buttonActionMessage: 'Confirm',
            action: () => {
              void this.router.navigateByUrl(
                '/crm/profile/' + data.values.profileId
              );
            },
          });

          this.creationProfileService.updateProgress(null);
          this.wsClient?.close();
        } else {
          this.creationProfileService.updateProgress({
            progress: data.progress,
            content: data.content,
          });
        }
      }
      if (data.error) {
        this.httpErrors.handleError(data.error);
        this.creationProfileService.updateProgress(null);
        this.wsClient?.close();
      }
    } catch (error) {
      this.httpErrors.handleError(error);
      this.creationProfileService.updateProgress(null);
      this.wsClient?.close();
    }
  };

  /**
   * Handles the WebSocket onclose event.
   * @param {CloseEvent} event - The close event.
   * @returns {void}
   */
  onError = (event: Event): void => {
    this.httpErrors.handleError(event.target);
    this.creationProfileService.updateProgress(null);
    this.wsClient?.close();
  };
}
