import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { CountryCodes } from '~/app/shared/interfaces/country-codes.interface';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * A custom phone number input component that integrates with Angular forms and supports formatting based on
 * country codes. The component also supports validation and error handling related to phone number input.
 *
 * @Component decorator defines the Angular component metadata.
 * @selector 'ice-phone-number' - The selector used to use this component in HTML templates.
 * @templateUrl './ice-phone-number.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./ice-phone-number.component.scss'] - The styles for this component.
 * @providers NG_VALUE_ACCESSOR - Provides a token that allows the component to register as a ControlValueAccessor,
 * enabling it to interact with Angular forms.
 */
@Component({
  selector: 'ice-phone-number',
  templateUrl: './ice-phone-number.component.html',
  styleUrls: ['./ice-phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IcePhoneNumberComponent),
      multi: true,
    },
  ],
})
export class IcePhoneNumberComponent {
  /**
   * The descriptive label for the phone number input field.
   * @type {string}
   */
  @Input({ required: true }) label!: string;

  /**
   * Placeholder text for the phone number input field.
   * @type {string}
   */
  @Input({ required: true }) placeholder!: string;

  /**
   * FormControl linked to the phone number input. It facilitates integration with Angular's reactive forms.
   * @type {FormControl}
   */
  @Input({ required: true }) formControl!: FormControl;

  /**
   * The name of the FormControl within a FormGroup. Used for linking and error handling.
   * @type {string}
   */
  @Input({ required: true }) formControlName!: string;

  /**
   * The ISO country codes used for formatting and validating the phone number.
   * @type {CountryCodes | null}
   */
  @Input() iso!: CountryCodes | null;

  /**
   * Specifies if the phone number input is a required field.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Current validation error message to display for the phone number input.
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Indicates whether the form has been submitted, used for error display handling.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * The current value of the phone number input.
   * @type {string}
   */
  value!: string;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Handles the focus event by triggering the onTouched callback.
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Handles the blur event by triggering the onTouched callback.
   * @return {void}
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Writes a new value to the input.
   * @param {string} value  - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a callback for value changes.
   * @param {void} fn - The callback function (value: any) => void.
   * @returns {void}
   */
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback for touch events.
   * @param {void} fn - The callback function () => void.
   * @returns {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Handles input events, updating the value and triggering change detection.
   * @param {Event} event - The input event (Event).
   * @returns {void}
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value.replace(/\D/g, '');
    this.formatNumber();
  }

  /**
   * Receives and processes an error message from form validation.
   * @param {string | null} message - The error message to display.
   * @returns {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }

  /**
   * Formats the phone number according to the specified ISO country code. Validates the phone number and sets form errors accordingly.
   * @return {void}
   */
  formatNumber(): void {
    if (this.iso) {
      const parsedInput = parsePhoneNumber(this.value, {
        regionCode: this.iso.code,
      });

      if (parsedInput.valid) {
        this.value = parsedInput.number.national;
      }
      if (!parsedInput.valid) {
        this.formControl.setErrors({ invalidPhoneNumber: true });
      }
    }
  }
}
