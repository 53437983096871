<ng-container *ngIf="isCompany; else landingCompany">
  <app-hero></app-hero>
  <app-jobs></app-jobs>
  <app-testimonials></app-testimonials>
  <app-storytelling></app-storytelling>
  <app-features></app-features>
</ng-container>
<ng-template #landingCompany>
  <app-landing-company></app-landing-company>
</ng-template>
