import { Pipe, PipeTransform } from '@angular/core';

/**
 * `IsInPipe` is a custom Angular pipe that checks if a given element exists in a list of objects
 * based on a specified attribute.
 *
 * @Pipe decorator registers the pipe with the name 'isIn'.
 */
@Pipe({
  name: 'isIn',
})
export class IsInPipe implements PipeTransform {
  /**
   * Transforms the input element by checking if it exists in the provided list of objects
   * based on the specified attribute.
   *
   * @param {string} elem - The element to search for in the list.
   * @param {any[] | undefined} list - The array of objects where the element will be searched. Defaults to an empty array if undefined.
   * @param {string} [attr='uuid'] - The attribute of each object to compare with the element. Defaults to 'uuid'.
   *
   * @returns {boolean} - Returns `true` if the element is found in the list, otherwise `false`.
   *
   * @example
   * // Example usage in a template
   * <div *ngIf="'123' | isIn:usersList:'uuid'">User exists</div>
   */
  transform(elem: string, list: any[] | undefined, attr = 'uuid'): boolean {
    let result = false;

    (list ?? []).forEach(item => {
      if (item[attr] === elem) result = true;
    });

    return result;
  }
}
