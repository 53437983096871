import { createAction, props } from '@ngrx/store';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { HomeModule } from '~/app/shared/interfaces/module/home-module.interface';

export const loadHomeModules = createAction('[Home] Load Modules');
export const loadHomeModulesSuccess = createAction(
  '[Home] Load Modules Success',
  props<{ homeModules: PaginationData<HomeModule> }>()
);
export const loadHomeModulesFailure = createAction(
  '[Home] Load Modules Failure',
  props<{ error: string }>()
);

export const loadActiveModule = createAction('[Home] Load Active Module');
export const loadActiveModuleSuccess = createAction(
  '[Home] Load Active Module Success',
  props<{ activeModules: PaginationData<HomeModule> }>()
);
export const loadActiveModuleFailure = createAction(
  '[Home] Load Active Module Failure',
  props<{ error: string }>()
);

export const activateModule = createAction(
  '[Home] Activate Module',
  props<{ moduleId: string }>()
);
export const activateModuleSuccess = createAction(
  '[Home] Activate Module Success',
  props<{ moduleId: string }>()
);
export const activateModuleFailure = createAction(
  '[Home] Activate Module Failure',
  props<{ error: string }>()
);

export const homeModulesUnauthorized = createAction(
  '[Home] Modules Unauthorized',
  props<{ error: string }>()
);
