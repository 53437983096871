import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { VoidFunction } from '~/app/shared/types/function/void-function.type';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';
import { PermissionsService } from '~/app/shared/services/permissions.service';

/**
 * The `IceDropdownComponent` is a versatile dropdown component that integrates with Angular forms.
 * It supports both single and multiple selection modes, option filtering, and flexible configuration.
 *
 * @template T - Represents the type of the options used in the dropdown.
 * @selector 'ice-dropdown' - The component's selector for usage in templates.
 * @templateUrl './ice-dropdown.component.html' - The location of the HTML template.
 * @styleUrls ['./ice-dropdown.component.scss'] - The location of the component styles.
 * @providers NG_VALUE_ACCESSOR - Registers this component as a `ControlValueAccessor`, making it compatible with Angular forms.
 */
@Component({
  selector: 'ice-dropdown',
  templateUrl: './ice-dropdown.component.html',
  styleUrls: ['./ice-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceDropdownComponent),
      multi: true,
    },
  ],
})
export class IceDropdownComponent<T = string | object>
  implements AfterViewInit
{
  /**
   * Label text displayed above the dropdown.
   * @type {string}
   */
  @Input() label!: string;

  /**
   * Placeholder text displayed when no option is selected.
   * @type {string}
   */
  @Input() placeholder!: string;

  /**
   * An array of options for the dropdown to display.
   * @type {T[]}
   */
  @Input() options!: T[];

  /**
   * Form control linked to the dropdown for integration with Angular forms.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * The form control name attribute.
   * @type {string}
   */
  @Input() formControlName!: string;

  /**
   * Enables filtering functionality for the dropdown options.
   * @type {boolean}
   */
  @Input() filter = false;

  /**
   * Whether the dropdown allows selecting multiple options.
   * @type {boolean}
   */
  @Input() multiple = true;

  /**
   * Whether the field is required.
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Whether the form control has been submitted. Useful for controlling error visibility.
   * @type {boolean}
   */
  @Input() isSubmitted = false;

  /**
   * Current validation error message to display (if any).
   * @type {string | null}
   */
  @Input() error!: string | null;

  /**
   * Permission rule identifier for checking accessibility.
   * @type {string | undefined}
   */
  @Input() rule: string | undefined;

  /**
   * Defines the behavior when the user does not have the required permission.
   * @type {PermissionBehaviourEnum}
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Controls the visibility of the dropdown options.
   * @type {boolean}
   */
  dropdownOpen = false;

  /**
   * List of options filtered based on the input query.
   * @type {T[]}
   */
  filteredOptions!: T[];

  /**
   * List of selected options in multiple selection mode.
   * @type {T[]}
   */
  selectedOptions: T[] = [];

  /**
   * Stores the currently selected value(s) of the dropdown.
   * Can be a single value or an array depending on the `multiple` input.
   * @type {T | T[]}
   */
  value!: T | T[];

  /**
   * Callback function for when the value changes.
   * Initialized to an empty function, but overridden when `registerOnChange` is called.
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback function for when the dropdown is touched (e.g., on focus).
   * Initialized to an empty function, but overridden when `registerOnTouched` is called.
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Constructor initializes the component and sets the filtered options.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {
    this.filteredOptions = this.options;
  }

  /**
   * Lifecycle hook that initializes filtered options after the view is initialized.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    this.filteredOptions = this.options;
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.DISABLE:
                this.disableElement();
                break;
              case PermissionBehaviourEnum.NONE:
              default:
                this.hideElement();
            }
          }
        });
  }

  /**
   * Marks the form control as touched when the dropdown gains focus.
   *
   * @return {void}
   */
  onFocus(): void {
    this.onTouched();
  }

  /**
   * Closes the dropdown after it loses focus, with a delay to allow for click events.
   *
   * @return {void}
   */
  onBlur(): void {
    setTimeout(() => {
      this.dropdownOpen = false;
    }, 200);
  }

  /**
   * Writes a new value to the dropdown. It accepts either a single value or an array, depending on the `multiple` setting.
   * @param {T | T[]} value - The new value(s) to write to the dropdown.
   *
   * @return {void}
   */
  writeValue(value: T | T[]): void {
    if (this.multiple) {
      this.selectedOptions = Array.isArray(value) ? value : [value];
    } else {
      this.value = value as T;
    }
  }

  /**
   * Registers a callback function to be called when the dropdown value changes.
   * @param {AnyVoidFunction} fn - The callback function.
   *
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function to be called when the dropdown is touched.
   * @param {VoidFunction} fn - The callback function.
   *
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /**
   * Filters the dropdown options based on user input.
   * @param {Event} event - The input event.
   *
   * @return {void}
   */
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterOptions(inputElement.value);
  }

  /**
   * Toggles the visibility of the dropdown options.
   *
   * @return {void}
   */
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  /**
   * Opens the dropdown.
   *
   * @return {void}
   */
  openDropdown(): void {
    this.dropdownOpen = true;
  }

  /**
   * Filters the options based on a query string if filtering is enabled.
   * @param {string} query - The query string to filter options by.
   *
   * @return {void}
   */
  filterOptions(query: string): void {
    if (this.filter && query) {
      this.openDropdown();
      this.filteredOptions = this.options.filter(option =>
        (option as string | object)
          .toString()
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    } else {
      this.filteredOptions = this.options;
    }
  }

  /**
   * Selects or deselects an option based on whether the `multiple` input is true.
   * @param {T} option - The option to select or deselect.
   *
   * @return {void}
   */
  selectOption(option: T): void {
    if (this.multiple) {
      const index = this.selectedOptions.indexOf(option);
      if (index === -1) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions.splice(index, 1);
      }
      this.onChange(this.selectedOptions);
    } else {
      this.value = option;
      this.onChange(this.value);
      this.dropdownOpen = false;
    }
  }

  /**
   * Checks whether a specific option is selected.
   * @param {T} option - The option to check.
   * @returns {boolean} - True if the option is selected, false otherwise.
   */
  isSelected(option: T): boolean {
    return this.multiple
      ? this.selectedOptions.includes(option)
      : this.value === option;
  }

  /**
   * Removes a selected option in multiple selection mode.
   * @param {T} option - The option to remove.
   *
   * @return {void}
   */
  removeOption(option: T): void {
    if (this.multiple) {
      const index = this.selectedOptions.indexOf(option);
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
        this.onChange(this.selectedOptions);
      }
    }
  }

  /**
   * Receives and handles an error message from form validation.
   * @param {string | null} message - The error message to be displayed.
   * @return {void}
   */
  onErrorMessageReceived(message: string | null): void {
    this.error = message;
  }

  /**
   * Hides the dropdown element by applying a CSS style.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the form control associated with the dropdown element.
   * @return {void}
   */
  private disableElement(): void {
    this.formControl.disable();
  }
}
