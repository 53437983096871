import { createReducer, on } from '@ngrx/store';
import { initialNavigationState } from '~/app/shared/states/navigation/navigation.state';
import * as NavigationActions from '~/app/shared/states/navigation/navigation.action';

export const navigationReducer = createReducer(
  initialNavigationState,

  on(NavigationActions.loadAllAvailableModules, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    NavigationActions.loadAllAvailableModulesSuccess,
    (state, { availableModules }) => ({
      ...state,
      availableModules,
      loading: false,
    })
  ),
  on(NavigationActions.loadAllAvailableModulesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NavigationActions.loadAllActivatedModules, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    NavigationActions.loadAllActivatedModulesSuccess,
    (state, { activatedModules }) => ({
      ...state,
      activatedModules: activatedModules,
      loading: false,
    })
  ),
  on(NavigationActions.loadAllActivatedModulesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NavigationActions.loadCurrentModule, state => ({
    ...state,
    loading: true,
  })),
  on(
    NavigationActions.loadCurrentModuleSuccess,
    (state, { currentModule }) => ({
      ...state,
      currentModule,
      loading: false,
    })
  ),
  on(NavigationActions.loadCurrentModuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(NavigationActions.changeCurrentModule, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NavigationActions.changeCurrentModuleSuccess, (state, { module }) => ({
    ...state,
    currentModule:
      state.activatedModules.find(item => item.name === module) ?? null,
    loading: false,
  })),
  on(NavigationActions.changeCurrentModuleFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
