import { SalesLanguage } from '~/app/shared/interfaces/sales/profile/sales-languages.interface';

/**
 * Represents the state of the sales languages module.
 *
 * @interface SalesLanguagesState
 */
export interface SalesLanguagesState {
  /**
   * The list of sales language records.
   *
   * @type {SalesLanguage[]}
   */
  salesLanguages: SalesLanguage[];

  /**
   * Indicates whether the sales language records are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, from the last operation.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of the sales languages module.
 *
 * @type {SalesLanguagesState}
 */
export const initialSalesLanguagesState: SalesLanguagesState = {
  salesLanguages: [],
  loading: false,
  error: null,
};
