import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { hasIceRules } from '@npmicedev/icemodule/lib/utils/rules';
import { actionToPermissionMap } from '~/app/shared/const/action-permission-map.const';
import {
  selectCurrentRules,
  selectIsRootUser,
} from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';

/**
 * Service to handle permission checks within the application. This service provides methods to
 * determine if the current user has the necessary permissions to perform various actions,
 * based on the rules defined in the application's state and an action-permission mapping.
 *
 * @Injectable marks this class as a service that can be injected with Angular's dependency injection,
 * making it available throughout the application as a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  /**
   * Creates an instance of the PermissionsService with the injected NgRx Store containing the app's state.
   * @param {Store<AppState>} store - The application-wide Redux store instance for managing state.
   */
  constructor(private store: Store<AppState>) {}

  /**
   * Checks if the current user has permission to perform a specified action.
   * This method considers the user's role and specific permissions assigned to the user.
   *
   * @param {string} actionType - The type of action to check permissions for.
   * @returns {Observable<boolean>} An Observable that resolves to true if the user has the required permissions, otherwise false.
   */
  hasPermissionForAction(actionType: string): Observable<boolean> {
    return this.store.select(selectIsRootUser).pipe(
      combineLatestWith(this.store.select(selectCurrentRules)),
      map(([isRootUser, userPermissions]) => {
        if (isRootUser) {
          return true;
        }
        const requiredPermissionUUID = actionToPermissionMap[actionType];
        if (requiredPermissionUUID === null) {
          return true;
        }
        const rulesUUID = userPermissions?.map(rule => rule.uuid) ?? [];

        return hasIceRules(requiredPermissionUUID, rulesUUID);
      })
    );
  }

  /**
   * Checks if the current user has a specific rule based on the rule's UUID.
   *
   * @param {string} ruleId - The UUID of the rule to check against the user's permissions.
   * @returns {Observable<boolean>} An Observable that resolves to true if the user has the rule, otherwise false.
   */
  hasPermissionForRule(ruleId: string): Observable<boolean> {
    return this.store.select(selectIsRootUser).pipe(
      combineLatestWith(this.store.select(selectCurrentRules)),
      map(([isRootUser, userPermissions]) => {
        if (isRootUser) {
          return true;
        }
        const rulesUUID = userPermissions?.map(rule => rule.uuid) ?? [];
        return rulesUUID.includes(ruleId);
      })
    );
  }
}
