import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatestWith, of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LoginService } from '~/app/auth/services/login.service';
import { loginSuccess } from '~/app/auth/state/login/login.actions';
import * as LoginActions from '~/app/auth/state/login/login.actions';
import * as AuthActions from '~/app/auth/auth.actions';
import { EnumService } from '~/app/core/services/enum.service';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { SecureUser } from '~/app/shared/interfaces/auth/secure-user.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { DialogService } from '~/app/shared/services/dialog.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';
import { Token } from '~/app/shared/interfaces/token.interface';
import * as NotificationActions from '~/app/states/main/states/notification/notification.actions';
import { selectCurrentUser } from '~/app/auth/auth.selectors';

/**
 * `LoginEffects` orchestrates the side effects related to user authentication within the application.
 * Leveraging Angular's NgRx effects library, it listens for actions related to the login process,
 * performing tasks such as issuing login and logout requests to the server, handling the authentication flow,
 * including conditions where two-factor authentication is required, and managing redirections after successful
 * or failed authentication attempts.
 *
 * Effects included in this class:
 * - Processing user login requests and responding to the outcome.
 * - Handling two-factor authentication setup and verification.
 * - Executing logout procedures and cleaning up user session data.
 * - Refreshing authentication tokens to maintain user sessions.
 * - Navigating the user to appropriate routes based on authentication actions and results.
 *
 * @Injectable Decorator that marks the class as one that participates in the dependency injection system.
 */
@Injectable()
export class LoginEffects {
  /**
   * Effect to handle user login. It triggers on the login action, checks user permissions,
   * and attempts to log the user in via the API. Depending on the response, it either triggers a two-factor
   * authentication action or a successful login action.
   */
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.login),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ loginData }) =>
        this.http
          .post<
            AuthUser | SecureUser
          >(`${environment.apiUrl}/v1/auth/login`, loginData, { withCredentials: true })
          .pipe(
            take(1),
            map(userData => {
              return userData.hasOwnProperty('secureLogin')
                ? LoginActions.loginTwoFactorAuthentication({
                    secureLoginToken: userData.token,
                  })
                : LoginActions.loginSuccess({ userData: userData as AuthUser });
            }),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.loginFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle user login success. It triggers when the login action is successful
   * and closes the login dialog.
   */
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      tap(() => {
        console.log('loginSuccess');
        if (this.dialog.dialogVisible) {
          this.dialog.toggleDialog();
        }
      }),
      map(() => LoginActions.generateISSEToken())
    )
  );

  /**
   * Effect to redirect the user to the two-factor authentication page upon successful login
   * that requires secondary verification.
   */
  loginTwoFactorAuthenticationRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.loginTwoFactorAuthentication),
        tap(() => this.router.navigate(['/login-secure']))
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle secure login after initial login requires two-factor authentication.
   * This effect handles the submission of two-factor authentication data to the API.
   */
  secureLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.secureLogin),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ loginSecureData }) =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/auth/login-secure`,
            loginSecureData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => LoginActions.loginSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.loginFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to initiate the SAML login process. It redirects the user to the SAML login page.
   */
  samlLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.samlLogin),
      checkPermission(AuthActions.authUnauthorized),
      tap(({ companySubdomain }) => {
        window.location.href = `${environment.apiUrl}/v1/auth/${companySubdomain}/saml`;
      }),
      map(() => LoginActions.samlLoginSuccess())
    )
  );

  /**
   * Effect to know if SAML is configured for the company.
   */
  isSamlConfigured$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.isSamlConfigured),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(({ companySubdomain }) =>
        this.http
          .get<{
            configured: boolean;
          }>(
            `${environment.apiUrl}/v1/saml/${companySubdomain}/isSamlConfigured`
          )
          .pipe(
            take(1),
            map(({ configured }) =>
              AuthActions.isSamlConfiguredSuccess({
                isSamlConfigured: configured,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AuthActions.isSamlConfiguredFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect the user to the main CRM page upon successful login.
   */
  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.loginSuccess),
        tap(() => {
          this.enumService.loadCecrlTypes();
          this.enumService.loadOfferStatusTypes();
          this.enumService.loadOfferUnitTypes();
          this.enumService.loadOfferVisibilityTypes();
          this.enumService.loadProfileExperienceTypes();
          this.enumService.loadProfileFileTypes();
          this.enumService.loadProfileTypes();
          this.enumService.loadProfileTimeUnits();
          this.enumService.loadProposalStatusTypes();
          this.enumService.loadReminderTypes();
          this.enumService.loadSaleInformationTypes();
          this.enumService.loadSocialTypes();
          this.enumService.loadGenderTypes();
          this.enumService.loadSkillExperiencesTypes();
          void this.router.navigate(['/jobseeker/jobhub']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle user logout. It sends a logout request to the API and handles
   * the response to clear user session data.
   */
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.logout),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(() =>
        this.http
          .post<void>(
            `${environment.apiUrl}/v1/auth/logout`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => LoginActions.logoutSuccess()),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.logoutFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to redirect the user to the login page after successful logout.
   */
  logoutRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.logoutSuccess),
        tap(() => {
          this.loginService.stopRefreshTokenTimer();
          window.location.href = '/';
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to handle token refresh requests. It sends a request to refresh the authentication token
   * and dispatches success or failure actions based on the response.
   */
  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(() =>
        this.http
          .post<AuthUser>(
            `${environment.apiUrl}/v1/auth/refreshToken`,
            {},
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(userData => AuthActions.refreshTokenSuccess({ userData })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(AuthActions.refreshTokenFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle successful token refresh.
   *
   * @returns An observable of the action to dispatch.
   */
  refreshTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      map(() => LoginActions.generateISSEToken())
    )
  );

  /**
   * Effect to generate a token.
   * Listens for the `generateToken` action, checks permissions, and performs an HTTP GET request.
   * Dispatches a success or failure action based on the result.
   */
  generateToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.generateISSEToken),
      checkPermission(AuthActions.authUnauthorized),
      mergeMap(() =>
        this.http
          .get<Token>(`${environment.apiUrl}/v1/isse/tokens`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(serverToken =>
              LoginActions.generateISSETokenSuccess({ serverToken })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(LoginActions.generateISSETokenFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect that handles the success of token generation and establishes a connection
   * to the notification channel for the current user.
   *
   * This effect listens for the `generateISSETokenSuccess` action, then retrieves the
   * current user from the store and proceeds to connect to the notification channel
   * using the generated server token. If there is no current user, a failure action
   * is dispatched.
   *
   * `NotificationActions.connectToChannel` or `NotificationActions.connectToChannelFailure`.
   */
  generateTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.generateISSETokenSuccess),
      combineLatestWith(this.store.select(selectCurrentUser)),
      map(([{ serverToken }, currentUser]) => {
        if (currentUser)
          return NotificationActions.connectToChannel({
            token: serverToken.token,
            connectionDetails: [
              {
                topic: 'notifications',
                channels: [currentUser.user.uuid],
              },
            ],
          });
        return NotificationActions.connectToChannelFailure({
          error: 'No current User',
        });
      })
    )
  );

  /**
   * Constructs the `LoginEffects` instance.
   *
   * @param {LoginService} loginService - The service used for managing login operations and handling token refresh logic.
   * @param {Actions} actions$ - An observable of actions. It serves as the source of dispatched actions for the effects.
   * @param {HttpClient} http - The HTTP client service for making API calls to the backend.
   * @param {Router} router - The Angular Router service used for navigating between routes.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors and providing user feedback.
   * @param {EnumService} enumService - Service for ahandling enums.
   * @param {DialogService} dialog - Service for managing dialog components.
   * @param {Store} store - Store.
   */
  constructor(
    private loginService: LoginService,
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private httpErrors: HttpErrorsService,
    private enumService: EnumService,
    private dialog: DialogService,
    private store: Store
  ) {}
}
