import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that truncates a given string to a specified number of characters and appends an ellipsis ('...')
 * if the string exceeds that length. This is useful for creating previews or summaries of longer text content in the UI.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'lineClip', enabling its use within Angular templates.
 */
@Pipe({
  name: 'lineClip',
})
export class LineClipPipe implements PipeTransform {
  /**
   * Transforms the input string by clipping it to a specified maximum number of characters.
   * If the input string exceeds the specified length, it is truncated and '...' is appended.
   *
   * @param {string | null} value - The input string to be clipped.
   * @param {number} [nbCaracters=10] - The maximum number of characters allowed before clipping. Defaults to 10 if not specified.
   * @returns {string} - The clipped string, possibly appended with '...'.
   */
  transform(value: string | null, nbCaracters = 10): string {
    if (!value) return '';
    if (value.length > nbCaracters) {
      return value.substring(0, nbCaracters) + '...';
    } else return value;
  }
}
