import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from '~/app/auth/auth-routing.module';
import { DisableTwoFactorAuthComponent } from '~/app/auth/components/disable-two-factor-auth/disable-two-factor-auth.component';
import { LoginCompanyComponent } from '~/app/auth/components/login/login-company/login-company.component';
import { LoginComponent } from '~/app/auth/components/login/login.component';
import { CompanyStepComponent } from '~/app/auth/components/register/company-step/company-step.component';
import { OfficeStepComponent } from '~/app/auth/components/register/office-step/office-step.component';
import { RegisterRedirectionComponent } from '~/app/auth/components/register/register-redirection/register-redirection.component';
import { RegisterStepperComponent } from '~/app/auth/components/register/register-stepper/register-stepper.component';
import { RootUserStepComponent } from '~/app/auth/components/register/root-user-step/root-user-step.component';
import { RequestNewPasswordComponent } from '~/app/auth/components/request-new-password/request-new-password.component';
import { ResetPasswordComponent } from '~/app/auth/components/reset-password/reset-password.component';
import { SecureLoginComponent } from '~/app/auth/components/secure-login/secure-login.component';
import { UserRegisterComponent } from '~/app/auth/components/user-register/user-register.component';
import { PrimengModule } from '~/app/primeng/primeng.module';
import { SharedModule } from '~/app/shared/shared.module';
import { StatesModule } from '~/app/states/states.module';

@NgModule({
  declarations: [
    LoginComponent,
    LoginCompanyComponent,
    ResetPasswordComponent,
    RequestNewPasswordComponent,
    RegisterStepperComponent,
    CompanyStepComponent,
    RootUserStepComponent,
    OfficeStepComponent,
    RegisterRedirectionComponent,
    UserRegisterComponent,
    SecureLoginComponent,
    DisableTwoFactorAuthComponent,
  ],
  imports: [
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimengModule,
    SharedModule,
    StatesModule,
  ],
  exports: [LoginCompanyComponent],
})
export class AuthModule {}
