import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import * as KanbanCardTagsActions from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.actions';
import {
  CreateKanbanTag,
  UpdateKanbanTag,
} from '~/app/states/main/kanban/types/kanban-tags.types';
import { KanbanTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-tag.interface';

/**
 * Service responsible for managing Kanban card tags.
 * Provides methods to load, create, and delete tags for Kanban cards.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class KanbanCardTagsService {
  /**
   * Constructs a new KanbanCardTagsService.
   *
   * @param {Store<KanbanState>} store – The NgRx store instance for state management.
   */
  constructor(private store: Store<KanbanState>) {}

  /**
   * Loads all tags for a specified Kanban.
   * Dispatches a loadAllKanbanTags action with the Kanban ID.
   *
   * @param {string} kanbanId – The ID of the Kanban.
   * @returns {void}
   */
  loadTags(kanbanId: string): void {
    this.store.dispatch(KanbanCardTagsActions.loadAllKanbanTags({ kanbanId }));
  }

  /**
   * Creates a new tag for a specified Kanban card.
   * Dispatches a createKanbanTag action with the tag data and optional Kanban card ID.
   *
   * @param {CreateKanbanTag} kanbanTagData – The data for the new Kanban tag.
   * @param {string} [kanbanCardId] – The optional ID of the Kanban card.
   * @returns {void}
   */
  createTag(kanbanTagData: CreateKanbanTag, kanbanCardId?: string): void {
    this.store.dispatch(
      KanbanCardTagsActions.createKanbanTag({ kanbanCardId, kanbanTagData })
    );
  }

  /**
   * Creates a new tag for a specified Kanban card.
   * Dispatches a createKanbanTag action with the tag data and optional Kanban card ID.
   *
   * @param {string} uuid - The uuid of the tag to update.
   * @param {UpdateKanbanTag} kanbanTagData - The information needed to update the tag.
   * @returns {void}
   */
  updateTag(uuid: string, kanbanTagData: UpdateKanbanTag): void {
    this.store.dispatch(
      KanbanCardTagsActions.updateKanbanTag({ uuid, kanbanTagData })
    );
  }

  /**
   * Deletes a tag from a specified Kanban.
   * Dispatches a deleteKanbanTag action with the tag UUID and Kanban ID.
   *
   * @param {string} uuid – The UUID of the tag to be deleted.
   * @param {string} kanbanId – The ID of the Kanban.
   * @returns {void}
   */
  deleteTags(uuid: string, kanbanId: string): void {
    this.store.dispatch(
      KanbanCardTagsActions.deleteKanbanTag({ uuid, kanbanId })
    );
  }

  /**
   * Creates a new tag for a specified Kanban card.
   * Dispatches a createKanbanTagServer action with the tag.
   *
   * @param {KanbanTag} kanbanTag – The data for the new Kanban tag.
   * @returns {void}
   */
  createTagServer(kanbanTag: KanbanTag): void {
    this.store.dispatch(
      KanbanCardTagsActions.createKanbanTagServer({ kanbanTag })
    );
  }

  /**
   * Deletes a tag from a specified Kanban.
   * Dispatches a deleteKanbanTagServer action with the tag UUID.
   *
   * @param {string} uuid – The UUID of the tag to be deleted.
   * @returns {void}
   */
  deleteTagsServer(uuid: string): void {
    this.store.dispatch(KanbanCardTagsActions.deleteKanbanTagServer({ uuid }));
  }
}
