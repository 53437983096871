import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as HomeModulesActions from '~/app/states/main/states/home/home-modules.actions';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { HomeModule } from '~/app/shared/interfaces/module/home-module.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `HomeModulesEffects` manages the side effects for home module operations within the application.
 * Utilizing the NgRx Effects library, this class responds to actions dispatched from components or services,
 * performs asynchronous HTTP requests to interact with the backend API, and manages the state updates based on the results of these requests.
 * This service effectively handles the loading, activating, and state management of home modules, ensuring that all module data is up-to-date and synchronized with the server.
 *
 * The effects in this class include:
 * - `loadHomeModules$`: Fetches all home modules from the server, dispatching success or failure actions based on the response.
 * - `loadActiveModule$`: Fetches the currently active module, handling the response similarly by dispatching success or failure actions.
 * - `activateModule$`: Submits a request to activate a selected module, and handles the response by dispatching the corresponding success or failure actions.
 * - `activateModuleSuccess$`: Triggers a success toast notification upon successful module activation. This effect does not dispatch further actions.
 * - `activateModuleFailure$`: Triggers an error toast notification upon failed module activation. This effect also does not dispatch further actions.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, simplifying its use across the application.
 */
@Injectable()
export class HomeModulesEffects {
  /**
   * Effect to load all home modules. It listens for the `loadHomeModules` action,
   * checks permissions, then makes an HTTP GET request to fetch all home modules.
   * On success, it dispatches a `loadHomeModulesSuccess` action with the fetched data.
   * On failure, it dispatches a `loadHomeModulesFailure` action with the error.
   */
  loadHomeModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeModulesActions.loadHomeModules),
      checkPermission(HomeModulesActions.homeModulesUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData<HomeModule>>(`${environment.apiUrl}/v1/modules`, {
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(homeModules =>
              HomeModulesActions.loadHomeModulesSuccess({ homeModules })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(HomeModulesActions.loadHomeModulesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to load the active home module. It listens for the `loadActiveModule` action,
   * checks permissions, then makes an HTTP GET request to fetch the active module.
   * On success, it dispatches a `loadActiveModuleSuccess` action with the fetched data.
   * On failure, it dispatches a `loadActiveModuleFailure` action with the error.
   */
  loadActiveModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeModulesActions.loadActiveModule),
      checkPermission(HomeModulesActions.homeModulesUnauthorized),
      mergeMap(() =>
        this.http
          .get<PaginationData<HomeModule>>(
            `${environment.apiUrl}/v1/companyModules`,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(activeModules =>
              HomeModulesActions.loadActiveModuleSuccess({ activeModules })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(HomeModulesActions.loadActiveModuleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to activate a module. It listens for the `activateModule` action,
   * checks permissions, and performs an HTTP POST request to activate a module.
   * On success, it dispatches `activateModuleSuccess` and shows a success toast.
   * On failure, it dispatches `activateModuleFailure` and shows an error toast.
   */
  activateModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeModulesActions.activateModule),
      checkPermission(HomeModulesActions.homeModulesUnauthorized),
      mergeMap(({ moduleId }) =>
        this.http
          .post<void>(
            `${environment.apiUrl}/v1/modules`,
            {
              moduleId,
            },
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(() => HomeModulesActions.activateModuleSuccess({ moduleId })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(HomeModulesActions.activateModuleFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Success effect for module activation. Does not dispatch a new action;
   * instead, it directly triggers a success toast message.
   */
  activateModuleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomeModulesActions.activateModuleSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Module activated successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructs the effects service with necessary dependencies.
   * @param {Actions} actions$ - Observable stream of actions, provided by NgRx.
   * @param {HttpClient} http - HTTP client for making API requests.
   * @param {HttpErrorsService} httpErrors - Service for handling HTTP errors.
   * @param {CustomToastService} toast - Service for displaying toast messages.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
