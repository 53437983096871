<section class="flex justify-content-center align-items-center p-4 h-screen">
  <div
    *ngIf="loading$ | async; else tokenVerified"
    class="flex flex-column gap-7-75 w-full max-w-30rem text-center">
    <p-progressBar
      [mode]="'indeterminate'"
      [showValue]="false"
      [style]="{ height: '6px' }">
    </p-progressBar>
    <div>
      <h4 class="display-h4 m-0 color-dark-100">Verifying</h4>
      <p i18n class="text-base color-dark-70">
        Please wait while we verify your account...
      </p>
    </div>

    <div [style]="{ height: '2.43rem' }"></div>
  </div>
  <ng-template #tokenVerified>
    <div
      *ngIf="user$ | async; else failedTemplate"
      class="flex flex-column gap-7-75 w-full max-w-30rem text-center">
      <p-progressBar
        [value]="100"
        [color]="'green'"
        [showValue]="false"
        [style]="{ height: '6px' }">
      </p-progressBar>
      <div>
        <h4 class="display-h4 m-0 color-dark-100">Account Verified</h4>
        <p i18n class="text-base color-dark-70">
          Your account has been successfully verified.
          <span class="text-2xl">🔥</span>
        </p>
      </div>
      <ice-button
        routerLink="/"
        label="Go to login"
        i18n-label
        icon="pi pi-sign-in"
        i18n-icon>
      </ice-button>
    </div>
    <ng-template #failedTemplate>
      <div class="flex flex-column gap-7-75 w-full max-w-30rem text-center">
        <p-progressBar
          [value]="100"
          [color]="'red'"
          [showValue]="false"
          [style]="{ height: '6px' }">
        </p-progressBar>
        <div>
          <h4 class="display-h4 m-0 color-dark-100">Verification Failed</h4>
          <p i18n class="text-base color-dark-70">
            Failed to verify your account.
          </p>
        </div>
        <ice-button
          label="Go to registration"
          i18n-label
          icon="pi pi-sign-out"
          i18n-icon
          (click)="navigateThenOpenRegister()">
        </ice-button>
      </div>
    </ng-template>
  </ng-template>
  <div class="absolute" [style]="{ bottom: '2rem', right: '2rem' }">
    <ice-button
      routerLink="/"
      [text]="true"
      icon="pi pi-question-circle"
      i18n-icon>
    </ice-button>
  </div>
</section>
