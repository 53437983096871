import { createAction, props } from '@ngrx/store';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';

export const loadProfileIndustries = createAction(
  '[Profile Industries] Load Profile Industries',
  props<{ profileId: string }>()
);
export const loadProfileIndustriesSuccess = createAction(
  '[Profile Industries] Load Profile Industries Success',
  props<{ industries: Industry[] }>()
);
export const loadProfileIndustriesFailure = createAction(
  '[Profile Industries] Load Profile Industries Failure',
  props<{ error: string }>()
);

export const assignProfileIndustry = createAction(
  '[Profile Industries] Assign Profile Industry',
  props<{ profileId: string; industryId: string }>()
);
export const assignProfileIndustrySuccess = createAction(
  '[Profile Industries] Assign Profile Industry Success',
  props<{ industries: Industry[] }>()
);
export const assignProfileIndustryFailure = createAction(
  '[Profile Industries] Assign Profile Industry Failure',
  props<{ error: string }>()
);

export const unassignProfileIndustry = createAction(
  '[Profile Industries] Unassign Profile Industry',
  props<{ profileId: string; industryId: string }>()
);
export const unassignProfileIndustrySuccess = createAction(
  '[Profile Industries] Unassign Profile Industry Success',
  props<{ industries: Industry[] }>()
);
export const unassignProfileIndustryFailure = createAction(
  '[Profile Industries] Unassign Profile Industry Failure',
  props<{ error: string }>()
);

export const profileIndustryUnauthorized = createAction(
  '[Profile Industries] Unauthorized',
  props<{ error: string }>()
);
