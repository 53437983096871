import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProfileReferencesActions from '~/app/states/main/crm/profile/states/profile-references/profile-references.actions';
import { ProfileReference } from '~/app/shared/interfaces/crm/profile/profile-reference.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { environment } from '~/environments/environment';

/**
 * `ProfileReferencesEffects` manages the side effects for profile reference-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to profile references, such as loading, creating, updating, and deleting profile references.
 * It performs operations that involve checking permissions, interacting with services for handling HTTP requests, and displaying notifications.
 *
 * The effects in this class include:
 * — `loadProfileReferences$`: Loads profile references based on a given profile ID. It performs an HTTP GET request and dispatches a success action with the fetched references or a failure action if an error occurs.
 * — `loadProfileReferenceById$`: Loads a specific profile reference based on its UUID and profile ID. It performs an HTTP GET request and dispatches a success action with the fetched reference or a failure action if an error occurs.
 * — `createProfileReference$`: Creates a new profile reference. It performs an HTTP POST request and dispatches a success action with the created reference or a failure action if an error occurs.
 * — `createProfileReferenceSuccess$`: Handles the success of creating a new profile reference by displaying a success toast message and closing the sidebar form.
 * — `updateProfileReference$`: Updates an existing profile reference based on its UUID and profile reference data. It performs an HTTP PATCH request and dispatches a success action with the updated reference or a failure action if an error occurs.
 * — `updateProfileReferenceSuccess$`: Handles the success of updating a profile reference by displaying a success toast message and closing the sidebar form.
 * — `deleteProfileReference$`: Deletes a profile reference based on its UUID and profile ID. It performs an HTTP DELETE request and dispatches a success action if the deletion is successful or a failure action if an error occurs.
 * — `deleteProfileReferenceSuccess$`: Handles the success of deleting a profile reference by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class ProfileReferencesEffects {
  /**
   * Effect to load profile references based on a given profile ID.
   * Listens for the `loadProfileReferences` action, checks permissions, and performs an HTTP GET request to fetch the references.
   * Dispatches a success or failure action based on the result.
   */
  loadProfileReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileReferencesActions.loadProfileReferences),
      checkPermission(ProfileReferencesActions.profileReferenceUnauthorized),
      mergeMap(({ profileId }) =>
        this.http
          .get<ProfileReference[]>(
            `${environment.apiUrl}/v1/profiles/references`,
            {
              params: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(profileReferences =>
              ProfileReferencesActions.loadProfileReferencesSuccess({
                profileReferences,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileReferencesActions.loadProfileReferencesFailure({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to create a new profile reference.
   * Listens for the `createProfileReference` action, checks permissions, and performs an HTTP POST request to create the reference.
   * Dispatches a success or failure action based on the result.
   */
  createProfileReference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileReferencesActions.createProfileReference),
      checkPermission(ProfileReferencesActions.profileReferenceUnauthorized),
      mergeMap(({ profileReference }) =>
        this.http
          .post<ProfileReference>(
            `${environment.apiUrl}/v1/profiles/references`,
            profileReference,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileReference =>
              ProfileReferencesActions.createProfileReferenceSuccess({
                profileReference,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileReferencesActions.createProfileReferenceFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new profile reference.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  createProfileReferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileReferencesActions.createProfileReferenceSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reference created successfully'
          );
          this.sidenavService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update an existing profile reference based on its UUID and profile reference data.
   * Listens for the `updateProfileReference` action, checks permissions, and performs an HTTP PATCH request to update the reference.
   * Dispatches a success or failure action based on the result.
   */
  updateProfileReference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileReferencesActions.updateProfileReference),
      checkPermission(ProfileReferencesActions.profileReferenceUnauthorized),
      mergeMap(({ uuid, profileReference }) =>
        this.http
          .patch<ProfileReference>(
            `${environment.apiUrl}/v1/profiles/references/${uuid}`,
            profileReference,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(profileReference =>
              ProfileReferencesActions.updateProfileReferenceSuccess({
                profileReference,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileReferencesActions.updateProfileReferenceFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of updating a profile reference.
   * Displays a success toast message and closes the sidebar form.
   * This effect does not dispatch any further actions.
   */
  updateProfileReferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileReferencesActions.updateProfileReferenceSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reference updated successfully'
          );
          this.sidenavService.closeSidebar();
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to delete a profile reference based on its UUID and profile ID.
   * Listens for the `deleteProfileReference` action, checks permissions, and performs an HTTP DELETE request to delete the reference.
   * Dispatches a success or failure action based on the result.
   */
  deleteProfileReference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileReferencesActions.deleteProfileReference),
      checkPermission(ProfileReferencesActions.profileReferenceUnauthorized),
      mergeMap(({ uuid, profileId }) =>
        this.http
          .delete<void>(
            `${environment.apiUrl}/v1/profiles/references/${uuid}`,
            {
              body: { profileId },
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(() =>
              ProfileReferencesActions.deleteProfileReferenceSuccess({
                uuid,
              })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(
                ProfileReferencesActions.deleteProfileReferenceFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a profile reference.
   * Displays a success toast message.
   * This effect does not dispatch any further actions.
   */
  deleteProfileReferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileReferencesActions.deleteProfileReferenceSuccess),
        tap(() => {
          this.toast.successToast(
            'Success',
            'Profile reference deleted successfully'
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `ProfileReferencesEffects`.
   *
   * @param {Actions} actions$ Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast Injectable service for displaying toast notifications.
   * @param {SidenavFormService} sidenavService Injectable service for handling sidebar form actions.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService,
    private sidenavService: SidenavFormService
  ) {}
}
