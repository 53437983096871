<div class="pb-3 relative">
  <label class="font-bold text-sm mx-0" [for]="formControlName">
    Iso <span *ngIf="required" class="text-red-600">*</span>
  </label>
  <p-dropdown
    [options]="countryCodes"
    optionLabel="name"
    i18n-optionLabel
    placeholder="Country"
    i18n-placeholder
    [filter]="true"
    filterBy="name"
    i18n-filterBy
    class="w-9rem block mt-2"
    [id]="formControlName"
    [formControl]="formControl"
    [appFormError]="formControlName"
    (formError)="onErrorMessageReceived($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [isSubmitted]="isSubmitted">
    <ng-template let-select pTemplate="selectedItem">
      <div
        class="flex align-items-center gap-2"
        *ngIf="select?.emoji && select?.dialCode; else placeholder">
        <span>{{ select.emoji }} </span>
        {{ select.dialCode }}
      </div>
      <ng-template #placeholder>
        <span i18n class="text-500"> Country </span>
      </ng-template>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div>{{ item.name }} ({{ item.dialCode }})</div>
    </ng-template>
  </p-dropdown>
</div>
