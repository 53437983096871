import { createReducer, on } from '@ngrx/store';
import * as SearchCandidateActions from '~/app/states/main/crm/search-candidate/state/search-candidate.actions';
import { initialAllCandidatesState } from '~/app/states/main/crm/search-candidate/state/search-candidate.state';

export const allSearchCandidatesReducer = createReducer(
  initialAllCandidatesState,

  on(SearchCandidateActions.loadAllCandidates, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SearchCandidateActions.loadAllCandidatesSuccess,
    (state, { candidates }) => ({
      ...state,
      ...candidates,
      loading: false,
    })
  ),
  on(SearchCandidateActions.loadAllCandidatesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SearchCandidateActions.getSalaryMax, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SearchCandidateActions.getSalaryMaxSuccess, (state, { salaryMax }) => ({
    ...state,
    loading: false,
    salaryMax: salaryMax.freelanceSalaryMax,
  })),
  on(SearchCandidateActions.getSalaryMaxFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    SearchCandidateActions.searchCandidateUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
