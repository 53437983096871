import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { take } from 'rxjs';
import { PermissionsService } from '~/app/shared/services/permissions.service';
import { PermissionBehaviourEnum } from '~/app/shared/enums/permission-behaviour.enum';

/**
 * Component to render a customizable button with various configuration options including styling, icons, and behaviors.
 *
 * @Component decorator specifies metadata for the component.
 * @selector 'ice-button' - CSS selector that defines the component.
 * @templateUrl './ice-button.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-button.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'ice-button',
  templateUrl: './ice-button.component.html',
  styleUrls: ['./ice-button.component.scss'],
})
export class IceButtonComponent implements AfterViewInit {
  /**
   * Custom styles to be applied to the button.
   */
  @Input() style: { [p: string]: any } | null | undefined;

  /**
   * Flag indicating whether the button should take up the full width of its container on mobile (< md).
   */
  @Input() fullWidth = false;

  /**
   * Text label to be displayed on the button.
   */
  @Input() label: string | undefined;

  /**
   * Icon class to be used for the button's icon.
   */
  @Input() icon: string | undefined;

  /**
   * Button type attribute (e.g., 'button', 'submit', 'reset').
   */
  @Input() type = 'button';

  /**
   * Flag indicating whether the button is disabled.
   */
  @Input() disabled = false;

  /**
   * Indicates if the button is in a loading state.
   */
  @Input() loading = false;

  /**
   * Custom classes for styling the button.
   */
  @Input() class: string | undefined;

  /**
   * Position of the icon relative to the text ('left', 'right', 'top', 'bottom').
   */
  @Input() iconPos: 'left' | 'right' | 'top' | 'bottom' = 'left';

  /**
   * Hyperlink for the button when it acts as a link.
   */
  @Input() link: string | undefined;

  /**
   * Makes the button edges rounded.
   */
  @Input() rounded = false;

  /**
   * Defines the aria-label.
   */
  @Input() ariaLabel: string | undefined;

  /**
   * Treats the button as a text button without background.
   */
  @Input() text = false;

  /**
   * Router link for use with Angular Router.
   */
  @Input() routerLink: string | string[] | undefined;

  /**
   * Severity of the button for theming purposes (e.g., 'success', 'info', 'warning').
   */
  @Input() severity:
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'help'
    | 'danger'
    | undefined;

  /**
   * Renders the button with a plain style.
   */
  @Input() plain = false;

  /**
   * Renders the button with an outlined style.
   */
  @Input() outlined = false;

  /**
   * Conditional visibility of the button.
   */
  @Input() iceIf: any = true;

  /**
   * Rule identifier for permission checks.
   */
  @Input() rule: string | undefined;

  /**
   * Defines the behavior when the user does not have the required permission.
   */
  @Input() unauthorizedBehaviour: PermissionBehaviourEnum | undefined =
    PermissionBehaviourEnum.DISABLE;

  /**
   * Event emitted when the button is clicked.
   */
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * Constructs a new `IceButtonComponent` instance.
   *
   * @param {PermissionsService} permissionService - The service used to check permissions. This service allows the button to determine if the current user has the appropriate permissions to interact with this component.
   * @param {ElementRef} element - A wrapper around the native element this directive is attached to. Provides direct access to the DOM element via its nativeElement property.
   */
  constructor(
    private permissionService: PermissionsService,
    private element: ElementRef
  ) {}

  /**
   * Responds after Angular initializes the component's views and child views.
   * It checks for user permissions and disables or hides the button based on the lack of permissions.
   * If the user does not have the necessary permission as determined by the `rule` input, the button's functionality or visibility is adjusted according to the `unauthorizedBehaviour` input.
   *
   * - `PermissionBehaviourEnum.NONE`: Hides the button if no permissions.
   * - `PermissionBehaviourEnum.DISABLE`: Disables the button if no permissions.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.rule)
      this.permissionService
        .hasPermissionForRule(this.rule)
        .pipe(take(1))
        .subscribe(hasPermission => {
          if (!hasPermission) {
            this.element.nativeElement.removeAllListeners('click');
            switch (this.unauthorizedBehaviour) {
              case PermissionBehaviourEnum.NONE:
                this.hideElement();
                break;
              case PermissionBehaviourEnum.DISABLE:
              default:
                this.disableElement();
            }
          }
        });
  }

  /**
   * Hides the button by applying a CSS style.
   * @return {void}
   */
  private hideElement(): void {
    this.element.nativeElement.style.setProperty(
      'display',
      'none',
      'important'
    );
  }

  /**
   * Disables the button, making it non-interactive.
   * @return {void}
   */
  private disableElement(): void {
    this.disabled = true;
  }
}
