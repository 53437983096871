import { ClientCompany } from '~/app/shared/interfaces/client-company/client-company.interface';

/**
 * Describes the state of all client companies within the application, including pagination details, loading status, and error information.
 *
 * @interface AllSearchClientCompaniesState
 */
export interface AllSearchClientCompaniesState {
  /**
   * Total number of client companies.
   *
   * @type {number}
   */
  count: number;

  /**
   * Current page number in the pagination.
   *
   * @type {number}
   */
  page: number;

  /**
   * Number of client companies per page.
   *
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there is a next page available.
   *
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page available.
   *
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Array of client companies.
   *
   * @type {ClientCompany[]}
   */
  data: ClientCompany[];

  /**
   * Indicates if the client companies data is being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Holds error information if an error occurs while loading client companies.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of all client companies within the application, used when the state is first initialized
 * in the Redux store or when a reset is needed.
 *
 * @constant {AllSearchClientCompaniesState} initialAllSearchClientCompaniesState
 * @description Provides a baseline definition for the client companies context, ensuring the application can
 * gracefully handle an undefined or initial state scenario.
 */
export const initialAllSearchClientCompaniesState: AllSearchClientCompaniesState =
  {
    count: 0,
    page: 0,
    limit: 10,
    hasNext: false,
    hasPrevious: false,
    data: [],
    loading: false,
    error: null,
  };
