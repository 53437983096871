import { QuickActionItems } from '~/app/shared/interfaces/navigation/quick-action/quick-action-items.interface';

/**
 * Represents the quick action items configuration for different sections of the application.
 * Each section (home, crm, sales, jobhub, kanban, shuffle-list) has its own array of quick action items.
 * These items include a name, an icon, and a tooltip for the action.
 *
 * @type {QuickActionItems}
 * @constant
 */
export const quickActionItems: QuickActionItems = {
  home: [
    {
      name: 'Offer',
      icon: 'pi pi-receipt',
      tooltip: 'Create offer',
    },
    {
      name: 'Profile',
      icon: 'pi pi-user-plus',
      tooltip: 'Create profile',
    },
    {
      name: 'Sale',
      icon: 'pi pi-cart-plus',
      tooltip: 'Create sale',
    },
  ],

  crm: [
    {
      name: 'Profile',
      icon: 'pi pi-user-plus',
      tooltip: 'Create profile',
    },
    {
      name: 'Offer',
      icon: 'pi pi-receipt',
      tooltip: 'Create offer',
    },
  ],

  sales: [
    {
      name: 'Sale',
      icon: 'pi pi-cart-plus',
      tooltip: 'Create sale',
    },
    {
      name: 'Offer',
      icon: 'pi pi-receipt',
      tooltip: 'Create offer',
    },
  ],

  jobhub: [
    {
      name: 'Offer',
      icon: 'pi pi-receipt',
      tooltip: 'Create offer',
    },
    {
      name: 'Profile',
      icon: 'pi pi-user-plus',
      tooltip: 'Create profile',
    },
    {
      name: 'Sale',
      icon: 'pi pi-cart-plus',
      tooltip: 'Create sale',
    },
  ],

  kanban: [
    {
      name: 'Kanban',
      icon: 'pi pi-plus',
      tooltip: 'Kanban',
    },
  ],

  // eslint-disable-next-line @typescript-eslint/naming-convention
  'shuffle-list': [
    {
      name: 'Shuffle-list',
      icon: 'pi pi-plus',
      tooltip: 'Create Shuffle-list',
    },
  ],
};
