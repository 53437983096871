import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ResetPasswordService } from '~/app/auth/services/reset-password.service';
import { ThemeService } from '~/app/core/services/theme.service';

/**
 * Component responsible for facilitating user requests for password resets. It offers a form where users can enter their
 * subdomain and email address, which are then submitted to the ResetPasswordService for processing the password reset request.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-request-new-password' - The CSS selector that identifies this component in a template.
 * @templateUrl './request-new-password.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./request-new-password.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-request-new-password',
  templateUrl: './request-new-password.component.html',
  styleUrls: ['./request-new-password.component.scss'],
})
export class RequestNewPasswordComponent {
  /**
   * FormGroup to manage the request new password form
   * @type {FormGroup}
   */
  resetForm!: FormGroup;

  /**
   * Flag to disable the request reset button based on certain conditions
   * @type {boolean}
   */
  requestResetButtonDisabled = true;

  /**
   * Constructs the RequestNewPasswordComponent with the necessary dependencies.
   *
   * @param {FormBuilder} formBuilder - Service to create FormGroup based on provided configuration.
   * @param {ResetPasswordService} resetPasswordService - Service to handle the logic for requesting new passwords.
   * @param {ThemeService} theme - Service to manage theming across the application.
   */
  constructor(
    private formBuilder: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    public theme: ThemeService
  ) {
    this.initForm();
  }

  /**
   * Accessor for the 'subdomain' form control
   * @type {AbstractControl}
   */
  get subdomain(): AbstractControl<any, any> | null {
    return this.resetForm.get('subdomain');
  }

  /**
   * Accessor for the 'email' form control
   * @type {AbstractControl}
   */
  get email(): AbstractControl<any, any> | null {
    return this.resetForm.get('email');
  }

  /**
   * Initializes the form with validators to ensure the subdomain and email meet the required criteria.
   * @return {void}
   */
  initForm(): void {
    this.resetForm = this.formBuilder.group({
      subdomain: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  /**
   * Handles form submission, collects the form data, and submits it for initiating the password reset process.
   * @return {void}
   */
  onSubmit(): void {
    const forgotPasswordData = {
      subdomain: this.resetForm.value.subdomain,
      email: this.resetForm.value.email,
    };

    this.resetPasswordService.resetPassword(forgotPasswordData);
  }
}
