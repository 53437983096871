import { createReducer, on } from '@ngrx/store';
import * as ProfileTagsAction from '~/app/states/main/crm/profile/states/profile-tags/profile-tags.actions';
import { initialProfileTagsState } from '~/app/states/main/crm/profile/states/profile-tags/profile-tags.state';

export const profileTagsReducer = createReducer(
  initialProfileTagsState,

  on(ProfileTagsAction.loadProfileTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileTagsAction.loadProfileTagsSuccess, (state, { tags }) => ({
    ...state,
    tags,
    loading: false,
  })),
  on(ProfileTagsAction.loadProfileTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileTagsAction.profileTagsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
