import { createAction, props } from '@ngrx/store';
import { Candidate } from '~/app/shared/interfaces/crm/search-candidate/candidate.interface';
import { Team } from '~/app/shared/interfaces/crm/team/team.interface';
import { Company } from '~/app/shared/interfaces/settings/company.interface';
import { Industry } from '~/app/shared/interfaces/settings/industry.interface';
import { NoteCategory } from '~/app/shared/interfaces/settings/note-category.interface';
import { Office } from '~/app/shared/interfaces/settings/office.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { CategoriesWithSectors } from '~/app/shared/interfaces/shared/sector/categories-with-sectors.interface';
import { CompanyReferences } from '~/app/shared/interfaces/company-references.interface';
import { PaginationData } from '~/app/shared/interfaces/generic/pagination-data.interface';
import { Tag } from '~/app/shared/interfaces/shared/tag.interface';

export const loadCandidates = createAction('[Data Actions] Load Candidates');
export const loadCandidatesSuccess = createAction(
  '[Data Actions] Load Candidates Success',
  props<{ candidates: Candidate[] }>()
);
export const loadCandidatesFailure = createAction(
  '[Data Actions] Load Candidates Failure',
  props<{ error: string }>()
);

export const loadCandidateStatuses = createAction(
  '[Data Actions] Load Candidate Statuses'
);
export const loadCandidateStatusesSuccess = createAction(
  '[Data Actions] Load Candidate Statuses Success',
  props<{ candidateStatuses: Status[] }>()
);
export const loadCandidateStatusesFailure = createAction(
  '[Data Actions] Load Candidate Statuses Failure',
  props<{ error: string }>()
);

export const loadCandidateTags = createAction(
  '[Data Actions] Load Candidate Tags'
);
export const loadCandidateTagsSuccess = createAction(
  '[Data Actions] Load Candidate Tags Success',
  props<{ candidateTags: Tag[] }>()
);
export const loadCandidateTagsFailure = createAction(
  '[Data Actions] Load Candidate Tags Failure',
  props<{ error: string }>()
);

export const loadSalesStatuses = createAction(
  '[Data Actions] Load Sales Statuses'
);
export const loadSalesStatusesSuccess = createAction(
  '[Data Actions] Load Sales Statuses Success',
  props<{ salesStatuses: Status[] }>()
);
export const loadSalesStatusesFailure = createAction(
  '[Data Actions] Load Sales Statuses Failure',
  props<{ error: string }>()
);

export const loadNoteCategories = createAction(
  '[Data Actions] Load Note Categories'
);
export const loadNoteCategoriesSuccess = createAction(
  '[Data Actions] Load Note Categories Success',
  props<{ noteCategories: NoteCategory[] }>()
);
export const loadNoteCategoriesFailure = createAction(
  '[Data Actions] Load Note Categories Failure',
  props<{ error: string }>()
);

export const loadCategoriesWithSectors = createAction(
  '[Data Actions] Load Categories With Sectors'
);
export const loadCategoriesWithSectorsSuccess = createAction(
  '[Data Actions] Load Categories With Sectors Success',
  props<{ categoriesWithSectors: CategoriesWithSectors[] }>()
);
export const loadCategoriesWithSectorsFailure = createAction(
  '[Data Actions] Load Categories With Sectors Failure',
  props<{ error: string }>()
);

export const loadOffices = createAction('[Data Actions] Load Offices');
export const loadOfficesSuccess = createAction(
  '[Data Actions] Load Offices Success',
  props<{ offices: Office[] }>()
);
export const loadOfficesFailure = createAction(
  '[Data Actions] Load Offices Failure',
  props<{ error: string }>()
);

export const loadIndustries = createAction('[Data Actions] Load Industries');
export const loadIndustriesSuccess = createAction(
  '[Data Actions] Load Industries Success',
  props<{ industries: Industry[] }>()
);
export const loadIndustriesFailure = createAction(
  '[Data Actions] Load Industries Failure',
  props<{ error: string }>()
);

export const loadCompanies = createAction('[Data Actions] Load Companies');
export const loadCompaniesSuccess = createAction(
  '[Data Actions] Load Companies Success',
  props<{ companies: Company[] }>()
);
export const loadCompaniesFailure = createAction(
  '[Data Actions] Load Companies Failure',
  props<{ error: string }>()
);

export const loadCompanyReferences = createAction(
  '[Data Actions] Load Company References'
);
export const loadCompanyReferencesSuccess = createAction(
  '[Data Actions] Load Company References Success',
  props<{ companyReferences: CompanyReferences[] }>()
);
export const loadCompanyReferencesFailure = createAction(
  '[Data Actions] Load Company References Failure',
  props<{ error: string }>()
);

export const loadTeams = createAction('[Data Actions] Load Teams');
export const loadTeamsSuccess = createAction(
  '[Data Actions] Load Teams Success',
  props<{ teams: Team[] }>()
);
export const loadTeamsFailure = createAction(
  '[Data Actions] Load Teams Failure',
  props<{ error: string }>()
);

export const loadUsers = createAction('[Data Actions] Load Users');
export const loadUsersSuccess = createAction(
  '[Data Actions] Load Users Success',
  props<{ users: PaginationData }>()
);
export const loadUsersFailure = createAction(
  '[Data Actions] Load Users Failure',
  props<{ error: string }>()
);

export const loadTimelines = createAction('[Data Actions] Load Timelines');
export const loadTimelinesSuccess = createAction(
  '[Data Actions] Load Timelines Success',
  props<{ timelines: PaginationData }>()
);
export const loadTimelinesFailure = createAction(
  '[Data Actions] Load Timelines Failure',
  props<{ error: string }>()
);

export const dataActionsUnauthorized = createAction(
  '[Data Actions] Unauthorized',
  props<{ error: string }>()
);
