import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { anyEmptyFunction } from '~/app/shared/functions/any-empty-function';
import { emptyFunction } from '~/app/shared/functions/empty-function';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { AnyVoidFunction } from '~/app/shared/types/function/any-void-function.type';

/**
 * `IceSelectComponent` is an Angular component that provides a custom select input field.
 * It integrates with Angular forms and supports validation and custom options.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-select' - The CSS selector that identifies this component.
 * @templateUrl './ice-select.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-select.component.scss'] - Path to the styles for this component.
 * @providers NG_VALUE_ACCESSOR - Dependency injection token that allows the component to act as a form control.
 */
@Component({
  selector: 'ice-select',
  templateUrl: './ice-select.component.html',
  styleUrls: ['./ice-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IceSelectComponent),
      multi: true,
    },
  ],
})
export class IceSelectComponent {
  /**
   * FormControl linked to the input, used for validation and managing form state.
   * @type {FormControl}
   */
  @Input() formControl!: FormControl;

  /**
   * The name of the FormControl within a FormGroup, used for accessing and validating the control.
   * @type {string}
   */
  @Input() formControlName!: string;

  /**
   * Label field of the suggestion object.
   * @type {string}
   */
  @Input() optionLabel!: string;

  /**
   * Value field of the suggestion object.
   * @type {string}
   */
  @Input() optionValue!: string;

  /**
   * Array of suggestions to display.
   * @type {(unknown & LabelValueInterface)[]}
   */
  @Input() suggestions: (unknown & LabelValueInterface)[] = [];

  /**
   * Current value of the select.
   * @type {string | null}
   */
  value!: string | null;

  /**
   * Callback method to be triggered when the component value changes, to update the form model.
   * To be overridden by registerOnChange
   * @type {AnyVoidFunction}
   */
  onChange: AnyVoidFunction = anyEmptyFunction;

  /**
   * Callback method to be triggered when the component is touched, to mark the form control as touched.
   * To be overridden by registerOnTouched
   * @type {VoidFunction}
   */
  onTouched: VoidFunction = emptyFunction;

  /**
   * Writes a new value to the input.
   * @param {string} value - The new value (string).
   * @returns {void}
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Registers a function to be called when the input value changes.
   * @param {AnyVoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnChange(fn: AnyVoidFunction): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the input is touched.
   * @param {VoidFunction} fn - The function to register.
   * @return {void}
   */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }
}
