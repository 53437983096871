import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SalesNotesActions from '~/app/states/main/sales/profile/states/sales-notes/sales-notes.actions';
import { SalesNote } from '~/app/shared/interfaces/sales/profile/sales-note.interface';
import { checkPermission } from '~/app/shared/operators/check-permission';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { HttpErrorsService } from '~/app/shared/services/http-errors.service';
import { environment } from '~/environments/environment';

/**
 * `SalesNotesEffects` manages the side effects for sales notes-related actions within the application.
 * This class leverages the NgRx Effects library to handle asynchronous operations related to sales notes, such as loading, creating, and deleting sales note records.
 * It performs operations that involve checking permissions, making HTTP requests, and updating the application's state based on the results.
 *
 * The effects in this class include:
 * — `loadSalesNotes$`: Loads the sales notes associated with a specific sales record. It performs an HTTP GET request and dispatches a success action with the loaded sales notes or a failure action if an error occurs.
 * — `createSalesNote$`: Creates a new sales note record. It performs an HTTP POST request and dispatches a success action with the created sales note or a failure action if an error occurs.
 * — `createSalesNoteSuccess$`: Handles the success of creating a new sales note record by displaying a success toast message.
 * — `deleteSalesNote$`: Deletes a sales note record. It performs an HTTP DELETE request and dispatches a success action or a failure action if an error occurs.
 * — `deleteSalesNoteSuccess$`: Handles the success of deleting a sales note record by displaying a success toast message.
 *
 * @Injectable Marks the class as available to be provided and injected as a dependency, facilitating its use throughout the application.
 */
@Injectable()
export class SalesNotesEffects {
  /**
   * Effect to load sales notes associated with a specific sales record.
   * It listens for the `loadSalesNotes` action, checks for permissions, and performs an HTTP GET request.
   * Upon successful loading, it dispatches a success action with the loaded sales notes or a failure action if an error occurs.
   */
  loadSalesNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesNotesActions.loadSalesNotes),
      checkPermission(SalesNotesActions.salesNotesUnauthorized),
      mergeMap(({ saleId }) =>
        this.http
          .get<SalesNote[]>(`${environment.apiUrl}/v1/sales/notes`, {
            params: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(salesNotes =>
              SalesNotesActions.loadSalesNotesSuccess({ salesNotes })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesNotesActions.loadSalesNotesFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to create a new sales note record.
   * It listens for the `createSalesNote` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales note or a failure action if an error occurs.
   */
  createSalesNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesNotesActions.createSalesNote),
      checkPermission(SalesNotesActions.salesNotesUnauthorized),
      mergeMap(({ salesNoteData }) =>
        this.http
          .post<SalesNote>(
            `${environment.apiUrl}/v1/sales/notes`,
            salesNoteData,
            { withCredentials: true }
          )
          .pipe(
            take(1),
            map(salesNote =>
              SalesNotesActions.createSalesNoteSuccess({ salesNote })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesNotesActions.createSalesNoteFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of creating a new sales note record.
   * It displays a success toast message.
   */
  createSalesNoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesNotesActions.createSalesNoteSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Note created successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect to update a sales note.
   * It listens for the `updateSalesNote` action, checks for permissions, and performs an HTTP POST request.
   * Upon successful creation, it dispatches a success action with the created sales note or a failure action if an error occurs.
   */
  updateSalesNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesNotesActions.updateSalesNote),
      checkPermission(SalesNotesActions.salesNotesUnauthorized),
      mergeMap(({ uuid, body }) =>
        this.http
          .patch<SalesNote>(
            `${environment.apiUrl}/v1/sales/notes/${uuid}`,
            body,
            {
              withCredentials: true,
            }
          )
          .pipe(
            take(1),
            map(salesNote =>
              SalesNotesActions.updateSalesNoteSuccess({ salesNote })
            ),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesNotesActions.updateSalesNoteFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to delete a sales note record.
   * It listens for the `deleteSalesNote` action, checks for permissions, and performs an HTTP DELETE request.
   * Upon successful deletion, it dispatches a success action or a failure action if an error occurs.
   */
  deleteSalesNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesNotesActions.deleteSalesNote),
      checkPermission(SalesNotesActions.salesNotesUnauthorized),
      mergeMap(({ uuid, saleId }) =>
        this.http
          .delete<SalesNote>(`${environment.apiUrl}/v1/sales/notes/${uuid}`, {
            body: { saleId },
            withCredentials: true,
          })
          .pipe(
            take(1),
            map(() => SalesNotesActions.deleteSalesNoteSuccess({ uuid })),
            catchError(error => {
              this.httpErrors.handleError(error);
              return of(SalesNotesActions.deleteSalesNoteFailure({ error }));
            })
          )
      )
    )
  );

  /**
   * Effect to handle the success of deleting a sales note record.
   * It displays a success toast message.
   */
  deleteSalesNoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesNotesActions.deleteSalesNoteSuccess),
        tap(() => {
          this.toast.successToast('Success', 'Note deleted successfully');
        })
      ),
    { dispatch: false }
  );

  /**
   * Constructor for `SalesNotesEffects`.
   *
   * @param {Actions} actions$ — Injectable RxJS Actions stream that listens for all dispatched actions in the application.
   * @param {HttpClient} http — Injectable HttpClient for making HTTP requests.
   * @param {HttpErrorsService} httpErrors — Injectable service for handling HTTP errors.
   * @param {CustomToastService} toast — Injectable service for displaying toast notifications.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private httpErrors: HttpErrorsService,
    private toast: CustomToastService
  ) {}
}
