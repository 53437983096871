import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  selectCompanyFormData,
  selectOfficeFormData,
  selectRootUserFormData,
} from '~/app/auth/state/register/register.selectors';
import { ThemeService } from '~/app/core/services/theme.service';
import { CompanyFormData } from '~/app/shared/interfaces/auth/register/company-form-data.interface';
import { OfficeFormData } from '~/app/shared/interfaces/auth/register/office-form-data.interface';
import { RootUserFormData } from '~/app/shared/interfaces/auth/register/root-user-form-data.interface';

/**
 * Component responsible for managing the registration process through a stepper interface. It handles navigation and transitions between the registration steps (Company, Office, Root User),
 * and loads the respective form data for each step from the state.
 *
 * @Component decorator provides configuration metadata:
 * @selector 'app-register-stepper' - The CSS selector that identifies this component in a template.
 * @templateUrl './register-stepper.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./register-stepper.component.scss'] - The paths to the styles for this component.
 * @animations - Defines animations for the transitions between steps, creating a dynamic visual effect as users navigate through the registration process.
 */
@Component({
  selector: 'app-register-stepper',
  templateUrl: './register-stepper.component.html',
  styleUrls: ['./register-stepper.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ width: 0 }),
        animate('0.5s ease', style({ width: '*' })),
      ]),
      transition(':leave', [
        style({ width: '*' }),
        animate('0.5s ease', style({ width: 0 })),
      ]),
    ]),
    trigger('slideOut', [
      transition(':enter', [
        style({ width: '*' }),
        animate('0.5s ease', style({ width: 0 })),
      ]),
      transition(':leave', [
        style({ width: 0 }),
        animate('0.5s ease', style({ width: '*' })),
      ]),
    ]),
  ],
})
export class RegisterStepperComponent {
  /**
   * Observable stream holding the form data for the company registration step.
   * @type {Observable<CompanyFormData | null>}
   */
  companyFormData$: Observable<CompanyFormData | null>;

  /**
   * Observable stream holding the form data for the office registration step.
   * @type {Observable<OfficeFormData | null>}
   */
  officeFormData$: Observable<OfficeFormData | null>;

  /**
   * Observable stream holding the form data for the root user registration step.
   * @type {Observable<RootUserFormData | null>}
   */
  rootUserFormData$: Observable<RootUserFormData | null>;

  /**
   * Current active step in the registration process.
   * @type {string | undefined}
   */
  currentStep: string | undefined;

  /**
   * Defines the steps of the registration process with navigation links and icons.
   * @type {Array<{label: string, routerLink: string, icon: string}>}
   */
  steps: { label: string; routerLink: string; icon: string }[] = [
    {
      label: 'Company',
      routerLink: 'register/company',
      icon: 'pi pi-home',
    },
    {
      label: 'Office',
      routerLink: 'register/office',
      icon: 'pi pi-briefcase',
    },
    {
      label: 'Root User',
      routerLink: 'register/root-user',
      icon: 'pi pi-user',
    },
  ];

  /**
   * Constructs the RegisterStepperComponent with necessary dependencies for routing and state management.
   *
   * @param {Router} router - Angular Router for navigation.
   * @param {Store} store - NgRx store for accessing the application state.
   * @param {ActivatedRoute} activatedRoute - Provides access to information about a route associated with a component that is loaded in an outlet.
   * @param {ThemeService} theme - Service to manage theming across the application.
   */
  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    public theme: ThemeService
  ) {
    this.companyFormData$ = this.store.select(selectCompanyFormData);
    this.officeFormData$ = this.store.select(selectOfficeFormData);
    this.rootUserFormData$ = this.store.select(selectRootUserFormData);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.firstChild?.snapshot.url[0].path)
      )
      .subscribe(route => {
        this.currentStep = route;
      });
  }

  /**
   * Navigates to the specified route.
   *
   * @param {string} route The route path to navigate to.
   * @return {void}
   */
  navigate(route: string): void {
    void this.router.navigate([route]);
  }

  /**
   * Retrieves form data based on the current registration step.
   *
   * @param {string} label The label of the current step to fetch form data for.
   * @returns {Observable<any | null>} Observable stream of the form data.
   */
  getFormData(label: string): Observable<any | null> {
    switch (label) {
      case 'Company':
        return this.companyFormData$;
      case 'Office':
        return this.officeFormData$;
      case 'Root User':
        return this.rootUserFormData$;
      default:
        return of(null);
    }
  }
}
