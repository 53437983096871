import { createReducer, on } from '@ngrx/store';
import * as QuickActionsActions from '~/app/shared/states/quick-actions/quick-actions.actions';
import { initialQuickActionState } from '~/app/shared/states/quick-actions/quick-actions.state';

export const quickActionReducer = createReducer(
  initialQuickActionState,

  on(QuickActionsActions.setQuickActions, (state, { quickActions }) => ({
    ...state,
    quickActions,
  })),
  on(QuickActionsActions.clearQuickActions, state => ({
    ...state,
    quickActions: [],
  }))
);
