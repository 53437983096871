import { Profile } from '~/app/shared/interfaces/crm/profile/profile.interface';

/**
 * Interface representing the state of client company consultants.
 *
 * This state includes the count, pagination, data, loading status, and error information for client company consultants.
 *
 * @interface ClientCompanyConsultantsState
 */
export interface ClientCompanyConsultantsState {
  /**
   * The total count of client company consultants.
   *
   * @type {number}
   * @memberof ClientCompanyConsultantsState
   */
  count: number;

  /**
   * The current page of client company consultants.
   *
   * @type {number}
   * @memberof ClientCompanyConsultantsState
   */
  page: number;

  /**
   * The limit of client company consultants per page.
   *
   * @type {number}
   * @memberof ClientCompanyConsultantsState
   */
  limit: number;

  /**
   * Whether there is a next page of client company consultants.
   *
   * @type {boolean}
   * @memberof ClientCompanyConsultantsState
   */
  hasNext: boolean;

  /**
   * Whether there is a previous page of client company consultants.
   *
   * @type {boolean}
   * @memberof ClientCompanyConsultantsState
   */
  hasPrevious: boolean;

  /**
   * The list of client company consultants.
   *
   * @type {Profile[]}
   * @memberof ClientCompanyConsultantsState
   */
  data: Profile[];

  /**
   * Whether the client company consultants are currently being loaded.
   *
   * @type {boolean}
   * @memberof ClientCompanyConsultantsState
   */
  loading: boolean;

  /**
   * The error message, if any.
   *
   * @type {string | null}
   * @memberof ClientCompanyConsultantsState
   */
  error: string | null;
}

/**
 * The initial state for client company consultants.
 *
 * @type {ClientCompanyConsultantsState}
 */
export const initialClientCompanyConsultantsState: ClientCompanyConsultantsState =
  {
    count: 0,
    page: 0,
    limit: 10,
    hasNext: false,
    hasPrevious: false,
    data: [],
    loading: false,
    error: null,
  };
