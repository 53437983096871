import { Component } from '@angular/core';
import {
  releaseNoteFeature,
  releaseNoteBugFix,
  releaseNoteUpgrade,
} from '~/app/shared/const/release-note.const';

/**
 * Represents a component for displaying release notes in a modal dialog.
 * This component displays categorized release notes, such as bug fixes,
 * new features, and upgrades.
 *
 * @Component The decorator that provides metadata for the component, including
 * the selector, template URL, and style URLs.
 */
@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss'],
})
export class ReleaseNoteComponent {
  /**
   * Array containing the list of bug fixes from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  bugFix = releaseNoteBugFix;

  /**
   * Array containing the list of new features from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  features = releaseNoteFeature;

  /**
   * Array containing the list of upgrades from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  upgrades = releaseNoteUpgrade;
}
