import { createAction, props } from '@ngrx/store';
import { UpdateProfileReminder } from '~/app/states/main/crm/profile/types/profile-reminders.types';
import { Reminder } from '~/app/shared/interfaces/shared/reminder.interface';

export const loadProfileReminders = createAction(
  '[Profile Reminders] Load Profile Reminders',
  props<{ profileId: string }>()
);
export const loadProfileRemindersSuccess = createAction(
  '[Profile Reminders] Load Profile Reminders Success',
  props<{ profileReminders: Reminder[] }>()
);
export const loadProfileRemindersFailure = createAction(
  '[Profile Reminders] Load Profile Reminders Failure',
  props<{ error: string }>()
);

export const createProfileReminder = createAction(
  '[Profile Reminders] Create Profile Reminder',
  props<{ profileReminderData: FormData }>()
);
export const createProfileReminderSuccess = createAction(
  '[Profile Reminders] Create Profile Reminder Success',
  props<{ profileReminder: Reminder }>()
);
export const createProfileReminderFailure = createAction(
  '[Profile Reminders] Create Profile Reminder Failure',
  props<{ error: string }>()
);

export const updateProfileReminder = createAction(
  '[Profile Reminders] Update Profile Reminder',
  props<{ uuid: string; profileReminderData: UpdateProfileReminder }>()
);
export const updateProfileReminderSuccess = createAction(
  '[Profile Reminders] Update Profile Reminder Success',
  props<{ profileReminder: Reminder }>()
);
export const updateProfileReminderFailure = createAction(
  '[Profile Reminders] Update Profile Reminder Failure',
  props<{ error: string }>()
);

export const deleteProfileReminder = createAction(
  '[Profile Reminders] Delete Profile Reminder',
  props<{ uuid: string; profileId: string }>()
);
export const deleteProfileReminderSuccess = createAction(
  '[Profile Reminders] Delete Profile Reminder Success',
  props<{ uuid: string }>()
);
export const deleteProfileReminderFailure = createAction(
  '[Profile Reminders] Delete Profile Reminder Failure',
  props<{ error: string }>()
);

export const checkProfileReminder = createAction(
  '[Profile Reminders] Check Profile Reminder',
  props<{ uuid: string; profileId: string }>()
);
export const checkProfileReminderSuccess = createAction(
  '[Profile Reminders] Check Profile Reminder Success',
  props<{ uuid: string }>()
);
export const checkProfileReminderFailure = createAction(
  '[Profile Reminders] Check Profile Reminder Failure',
  props<{ error: string }>()
);

export const uncheckProfileReminder = createAction(
  '[Profile Reminders] Uncheck Profile Reminder',
  props<{ uuid: string; profileId: string }>()
);
export const uncheckProfileReminderSuccess = createAction(
  '[Profile Reminders] Uncheck Profile Reminder Success',
  props<{ uuid: string }>()
);
export const uncheckProfileReminderFailure = createAction(
  '[Profile Reminders] Uncheck Profile Reminder Failure',
  props<{ error: string }>()
);

export const profileRemindersUnauthorized = createAction(
  '[Profile Reminders] Unauthorized',
  props<{ error: string }>()
);
