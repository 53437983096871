import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CrmState } from '~/app/states/main/crm/crm.state';
import * as AiConsultingActions from '~/app/states/main/crm/ai-search/states/consulting/consulting.actions';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Service for managing consulting-related operations such as loading requests, updating progress,
 * loading candidates, and resetting job matching candidates.
 * This service is provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class ConsultingService {
  /**
   * Creates an instance of ConsultingService.
   *
   * @param {Store<CrmState>} store - The store to manage state.
   */
  constructor(private store: Store<CrmState>) {}

  /**
   * Loads a consulting job matching request with the provided form data.
   *
   * @param {FormData} formData - The form data containing job matching request information.
   * @returns {void}
   */
  loadRequest(formData: FormData): void {
    this.updateProgress({ progress: 0, content: 'Connecting' });
    this.store.dispatch(
      AiConsultingActions.loadAiConsultingJobMatchingRequest({ formData })
    );
  }

  /**
   * Updates the progress of a consulting job matching request.
   *
   * @param {Progress | null} progress - The progress information to update.
   * @returns {void}
   */
  updateProgress(progress: Progress | null): void {
    this.store.dispatch(AiConsultingActions.updateAdvancement({ progress }));
  }

  /**
   * Loads job matching candidates based on the provided URL.
   *
   * @param {string} url - The URL to fetch job matching candidates from.
   * @returns {void}
   */
  loadCandidates(url: string): void {
    this.store.dispatch(
      AiConsultingActions.loadAiConsultingJobMatchingCandidates({ url })
    );
  }

  /**
   * Resets the list of job matching candidates.
   * @returns {void}
   */
  resetCandidates(): void {
    this.store.dispatch(
      AiConsultingActions.resetAiConsultingJobMatchingCandidates()
    );
  }
}
