import { createReducer, on } from '@ngrx/store';
import * as SectorCategoryActions from '~/app/states/main/states/sectors/sectors.actions';
import { initialSectorCategoryState } from '~/app/states/main/states/sectors/sectors.state';

export const sectorCategoryReducer = createReducer(
  initialSectorCategoryState,

  on(SectorCategoryActions.loadSectorCategories, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.loadSectorCategoriesSuccess,
    (state, { sectorCategories, allSectorJobs }) => ({
      ...state,
      sectorCategories,
      allSectorJobs: allSectorJobs, // Update the states with the full list of sector jobs
      loading: false,
    })
  ),
  on(SectorCategoryActions.loadSectorCategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.loadSectorCategoryById, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.loadSectorCategoryByIdSuccess,
    (state, { sectorCategory }) => {
      const transformedSectorCategory = sectorCategory.companySectorJobs.map(
        companySectorJob => companySectorJob.sectorJob
      );

      const filteredSectorJobs = state.allSectorJobs.filter(
        sectorJob =>
          !transformedSectorCategory.some(
            transformedSectorJob => transformedSectorJob.uuid === sectorJob.uuid
          )
      );

      return {
        ...state,
        filteredSectorJobs: filteredSectorJobs, // Update the states with the filtered sector jobs
        selectedCategory: transformedSectorCategory,
        loading: false,
      };
    }
  ),
  on(
    SectorCategoryActions.loadSectorCategoryByIdFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SectorCategoryActions.createSectorCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.createSectorCategorySuccess,
    (state, { sectorCategory }) => ({
      ...state,
      sectorCategories: [...state.sectorCategories, sectorCategory],
      loading: false,
    })
  ),
  on(SectorCategoryActions.createSectorCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.updateSectorCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.updateSectorCategorySuccess,
    (state, { sectorCategory }) => {
      return {
        ...state,
        sectorCategories: state.sectorCategories.map(cat =>
          cat.uuid === sectorCategory.uuid ? sectorCategory : cat
        ),
        loading: false,
      };
    }
  ),
  on(SectorCategoryActions.updateSectorCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.deleteSectorCategory, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SectorCategoryActions.deleteSectorCategorySuccess, (state, { uuid }) => ({
    ...state,
    sectorCategories: state.sectorCategories.filter(
      sectorCategory => sectorCategory.uuid !== uuid
    ),
    loading: false,
  })),
  on(SectorCategoryActions.deleteSectorCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.assignSectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.assignSectorJobSuccess,
    (state, { sectorCategory }) => ({
      ...state,
      selectedCategory: sectorCategory.companySectorJobs.map(
        item => item.sectorJob
      ),
      sectorCategories: [
        ...state.sectorCategories.map(item =>
          item.uuid === sectorCategory.uuid ? sectorCategory : item
        ),
      ],
      loading: false,
    })
  ),
  on(SectorCategoryActions.assignSectorJobFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.unassignSectorJob, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SectorCategoryActions.unassignSectorJobSuccess,
    (state, { sectorCategory }) => ({
      ...state,
      selectedCategory: sectorCategory.companySectorJobs.map(
        item => item.sectorJob
      ),
      sectorCategories: [
        ...state.sectorCategories.map(item =>
          item.uuid === sectorCategory.uuid ? sectorCategory : item
        ),
      ],
      loading: false,
    })
  ),
  on(SectorCategoryActions.unassignSectorJobFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SectorCategoryActions.sectorJobUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
