import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthState } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';

/**
 * Public guard for routing that allows only unauthenticated users.
 * If a user is authenticated, it redirects to a specified page.
 *
 * @returns {Observable<boolean|UrlTree>} - Returns true if the user is not authenticated, otherwise it redirects.
 */
export const publicGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectAuthState).pipe(
    map(authState => {
      if (!authState.userData) {
        return true;
      } else {
        // User is authenticated, redirect to the logged in version (my-offers)
        return router.createUrlTree(['/jobhub/my-offers']);
      }
    })
  );
};
