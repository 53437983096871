import { createReducer, on } from '@ngrx/store';
import * as SalesRemindersActions from '~/app/states/main/sales/profile/states/sales-reminders/sales-reminders.actions';
import { initialSalesRemindersState } from '~/app/states/main/sales/profile/states/sales-reminders/sales-reminders.state';

export const salesRemindersReducer = createReducer(
  initialSalesRemindersState,

  on(SalesRemindersActions.loadSalesReminders, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesRemindersActions.loadSalesRemindersSuccess,
    (state, { salesReminders }) => ({
      ...state,
      salesReminders,
      loading: false,
    })
  ),
  on(SalesRemindersActions.loadSalesRemindersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.createSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesRemindersActions.createSalesReminderSuccess,
    (state, { salesReminder }) => ({
      ...state,
      salesReminders: [...state.salesReminders, salesReminder],
      loading: false,
    })
  ),
  on(SalesRemindersActions.createSalesReminderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.updateSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesRemindersActions.updateSalesReminderSuccess,
    (state, { salesReminder }) => ({
      ...state,
      salesReminders: state.salesReminders.map(item =>
        item.uuid === salesReminder.uuid ? salesReminder : item
      ),
      loading: false,
    })
  ),
  on(SalesRemindersActions.updateSalesReminderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.deleteSalesReminder, (state, {}) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesRemindersActions.deleteSalesReminderSuccess, (state, { uuid }) => ({
    ...state,
    salesReminders: state.salesReminders.filter(
      salesReminder => salesReminder.uuid !== uuid
    ),
    loading: false,
  })),
  on(SalesRemindersActions.deleteSalesReminderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.checkSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesRemindersActions.checkSalesReminderSuccess, (state, { uuid }) => ({
    ...state,
    salesReminders: state.salesReminders.map(item =>
      item.uuid === uuid ? { ...item, doneAt: new Date().toISOString() } : item
    ),
    loading: false,
  })),
  on(SalesRemindersActions.checkSalesReminderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.uncheckSalesReminder, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SalesRemindersActions.uncheckSalesReminderSuccess, (state, { uuid }) => ({
    ...state,
    salesReminders: state.salesReminders.map(item =>
      item.uuid === uuid ? { ...item, doneAt: null } : item
    ),
    loading: false,
  })),
  on(SalesRemindersActions.uncheckSalesReminderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesRemindersActions.salesRemindersUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
