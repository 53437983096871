import { createReducer, on } from '@ngrx/store';
import { initialProfileState } from '~/app/states/main/crm/profile/profile-layout/state/profile.state';
import * as ProfileActions from '~/app/states/main/crm/profile/profile-layout/state/profile.actions';

export const profileReducer = createReducer(
  initialProfileState,

  on(ProfileActions.loadProfile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileActions.loadProfileSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(ProfileActions.loadProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(ProfileActions.createProfile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileActions.createProfileSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(ProfileActions.createProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(ProfileActions.updateProfile, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileActions.updateProfileSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(ProfileActions.updateProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(ProfileActions.assignProfileTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileActions.assignProfileTagSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(ProfileActions.assignProfileTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileActions.unassignProfileTag, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ProfileActions.unassignProfileTagSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(ProfileActions.unassignProfileTagFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ProfileActions.profileUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
