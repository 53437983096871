import { Project } from '~/app/shared/interfaces/sales/project/project.interface';

/**
 * Interface representing the state of all projects.
 *
 * @interface AllProjectsState
 */
export interface AllProjectsState {
  /**
   * Total number of projects.
   * @type {number}
   */
  count: number;

  /**
   * Current page number in the pagination.
   * @type {number}
   */
  page: number;

  /**
   * Number of projects per page.
   * @type {number}
   */
  limit: number;

  /**
   * Indicates if there is a next page available.
   * @type {boolean}
   */
  hasNext: boolean;

  /**
   * Indicates if there is a previous page available.
   * @type {boolean}
   */
  hasPrevious: boolean;

  /**
   * Array of projects.
   * @type {Project[]}
   */
  data: Project[];

  /**
   * Indicates if the projects data is being loaded.
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Error message related to project operations.
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state for all projects.
 *
 * @type {AllProjectsState}
 */
export const initialAllProjectsState: AllProjectsState = {
  count: 0,
  page: 0,
  limit: 10,
  hasNext: false,
  hasPrevious: false,
  data: [],
  loading: false,
  error: null,
};
