import { createReducer, on } from '@ngrx/store';
import * as SearchOfferProposalActions from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.actions';
import { initialAllSearchOfferProposalsState } from '~/app/states/main/jobhub/states/search-offer-proposal/search-offer-proposal.state';

export const allSearchOfferProposalsReducer = createReducer(
  initialAllSearchOfferProposalsState,

  on(SearchOfferProposalActions.loadAllSearchOfferProposals, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SearchOfferProposalActions.loadAllSearchOfferProposalsSuccess,
    (state, { searchOfferProposals }) => ({
      ...state,
      ...searchOfferProposals,
      loading: false,
    })
  ),
  on(
    SearchOfferProposalActions.loadAllSearchOfferProposalsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    SearchOfferProposalActions.searchOfferProposalsUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SearchOfferProposalActions.loadAllSearchOfferProposalsPublic, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SearchOfferProposalActions.loadAllSearchOfferProposalsPublicSuccess,
    (state, { searchOfferProposals }) => ({
      ...state,
      ...searchOfferProposals,
      loading: false,
    })
  ),
  on(
    SearchOfferProposalActions.loadAllSearchOfferProposalsPublicFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(
    SearchOfferProposalActions.searchOfferProposalsPublicUnauthorized,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
