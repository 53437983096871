import { createAction, props } from '@ngrx/store';
import { LoginSecureUser, LoginUser } from '~/app/auth/types/login.types';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';

export const login = createAction(
  '[Auth] Login',
  props<{ loginData: LoginUser }>()
);

export const loginTwoFactorAuthentication = createAction(
  '[Auth] Login Two Factor Authentication',
  props<{ secureLoginToken: string }>()
);

export const secureLogin = createAction(
  '[Auth] Secure Login',
  props<{ loginSecureData: LoginSecureUser }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ userData: AuthUser }>()
);
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

export const samlLogin = createAction(
  '[Auth] SAML Login',
  props<{ companySubdomain: string }>()
);
export const samlLoginSuccess = createAction('[Auth] SAML Login Success');
export const samlLoginFailure = createAction(
  '[Auth] SAML Login Failure',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFailure = createAction(
  '[Auth] Logout Failure',
  props<{ error: string }>()
);
