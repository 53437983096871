import { Component, Input } from '@angular/core';
import { Status } from '~/app/shared/interfaces/shared/status.interface';

/**
 * `IceStatusTagComponent` is an Angular component that displays a tag with a status name and a background color.
 * The background color is processed using a gradient pipe.
 *
 * @Component decorator defines metadata for the component:
 * @selector 'ice-status-tag' - The CSS selector that identifies this component in a template.
 * @templateUrl './ice-status-tag.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./ice-status-tag.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'ice-status-tag',
  templateUrl: './ice-status-tag.component.html',
  styleUrls: ['./ice-status-tag.component.scss'],
})
export class IceStatusTagComponent {
  /**
   * The status object containing the name and color of the status.
   * This input is required.
   * @type {Status}
   */
  @Input({ required: true }) status!: Status;
}
