/**
 * Enum for alert types used throughout the application to standardize the styling and response
 * associated with different levels of user notifications or system alerts.
 *
 * @enum {string}
 * @property {string} DANGER - Represents a danger or error condition that requires immediate attention or action from the user. Typically used to signify errors or critical issues.
 * @property {string} WARNING - Represents a warning condition that indicates potential issues or requires caution from the user. Typically used to warn the user before potentially irreversible actions.
 * @property {string} INFO - Represents an information condition that provides information on actions. Typically used to indicate if the user wants to be redirected or not.
 */
export enum AlertEnum {
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}
