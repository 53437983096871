import { createAction, props } from '@ngrx/store';
import {
  CreateStatus,
  UpdateStatus,
} from '~/app/states/main/settings/types/status.types';
import { SequenceUuidInterface } from '~/app/shared/interfaces/generic/sequence-uuid.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';

export const loadSalesStatuses = createAction(
  '[Sales Status] Load Sales Statuses'
);
export const loadSalesStatusesSuccess = createAction(
  '[Sales Status] Load Sales Statuses Success',
  props<{ salesStatuses: Status[] }>()
);
export const loadSalesStatusesFailure = createAction(
  '[Sales Status] Load Sales Statuses Failure',
  props<{ error: string }>()
);

export const createSalesStatus = createAction(
  '[Sales Status] Create Sales Status',
  props<{ salesStatusData: CreateStatus }>()
);
export const createSalesStatusSuccess = createAction(
  '[Sales Status] Create Sales Status Success',
  props<{ salesStatus: Status }>()
);
export const createSalesStatusFailure = createAction(
  '[Sales Status] Create Sales Status Failure',
  props<{ error: string }>()
);

export const updateSalesStatus = createAction(
  '[Sales Status] Update Sales Status',
  props<{ uuid: string; salesStatusData: UpdateStatus }>()
);
export const updateSalesStatusSuccess = createAction(
  '[Sales Status] Update Sales Status Success',
  props<{ salesStatus: Status }>()
);
export const updateSalesStatusFailure = createAction(
  '[Sales Status] Update Sales Status Failure',
  props<{ error: string }>()
);

export const deleteSalesStatus = createAction(
  '[Sales Status] Delete Sales Status',
  props<{ uuid: string }>()
);
export const deleteSalesStatusSuccess = createAction(
  '[Sales Status] Delete Sales Status Success',
  props<{ uuid: string }>()
);
export const deleteSalesStatusFailure = createAction(
  '[Sales Status] Delete Sales Status Failure',
  props<{ error: string }>()
);

export const updateSalesStatusSequence = createAction(
  '[Sales Status] Update Profile Status Sequence',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateSalesStatusSequenceSuccess = createAction(
  '[Sales Status] Update Profile Status Sequence Success',
  props<{ statuses: SequenceUuidInterface[] }>()
);
export const updateSalesStatusSequenceFailure = createAction(
  '[Sales Status] Update Profile Status Sequence Failure',
  props<{ error: string }>()
);
export const salesStatusUnauthorized = createAction(
  '[Sales Status] Unauthorized',
  props<{ error: string }>()
);
