import { ClientCompanyStatus } from '~/app/shared/interfaces/settings/client-company-status.interface';

/**
 * Represents the state of client company statuses within the application.
 *
 * @interface ClientCompanyStatusState
 */
export interface ClientCompanyStatusState {
  /**
   * The array of client company statuses.
   *
   * @type {ClientCompanyStatus[]}
   */
  clientCompanyStatuses: ClientCompanyStatus[];

  /**
   * Indicates whether the client company statuses are currently being loaded.
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The error message, if any, that occurred while loading the client company statuses.
   *
   * @type {string | null}
   */
  error: string | null;
}

/**
 * The initial state of client company statuses.
 *
 * @constant {ClientCompanyStatusState}
 */
export const initialClientCompanyStatusState: ClientCompanyStatusState = {
  clientCompanyStatuses: [],
  loading: false,
  error: null,
};
