import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, skipWhile } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { Router } from '@angular/router';
import { isKanban } from '@npmicedev/icemodule/lib/utils/regex/kanban';
import { KanbanState } from '~/app/states/main/kanban/kanban.state';
import { KanbanCardTagsService } from '~/app/states/main/kanban/services/kanban-card-tags.service';
import {
  selectKanbanTags,
  selectTagsLoadingState,
} from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.selectors';
import { KanbanTag } from '~/app/shared/interfaces/kanban/kanban-tag/kanban-tag.interface';
import { AlertService } from '~/app/shared/services/alert.service';
import { KanbanTagsState } from '~/app/states/main/kanban/states/kanban-tags/kanban-tags.state';
import { selectKanbanUsers } from '~/app/states/main/kanban/states/kanbans/kanbans.selectors';
import { selectUsersData } from '~/app/states/main/kanban/states/users/kanban.selectors';
import { randomColor } from '~/app/shared/functions/random-color';
import { KanbanUser } from '~/app/shared/interfaces/kanban/kanban-user/kanban-user.interface';
import { User } from '~/app/shared/interfaces/auth/user.interface';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { KanbanUsersService } from '~/app/states/main/kanban/services/kanban-users.service';
import { KanbansService } from '~/app/states/main/kanban/services/kanbans.service';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';

/**
 * CudKanbanSettingsComponent is responsible for creating, updating, and deleting kanban tags.
 * It provides a form for user input and handles form submission and tag deletion events.
 *
 * @Component
 * @selector 'app-cud-kanban-settings' — CSS selector that identifies this component in a template.
 * @templateUrl './cud-kanban-settings.component.html' — Path to the HTML template for this component.
 * @styleUrls ['./cud-kanban-settings.component.scss'] — Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-cud-kanban-settings',
  templateUrl: './cud-kanban-settings.component.html',
  styleUrls: ['./cud-kanban-settings.component.scss'],
})
export class CudKanbanSettingsComponent implements OnInit {
  /**
   * ID of the kanban to which the tags belong.
   * @type {string}
   */
  @Input({ required: true }) kanbanId!: string;

  /**
   * Observable that emits the current list of kanban tags.
   * @type {Observable<KanbanTag[]>}
   */
  kanbanTags$: Observable<KanbanTag[]>;

  /**
   * Observable that emits the list of users assigned to the Kanban board.
   * @type {Observable<(KanbanUser & { userId: { color: string } })[]>}
   */
  kanbanUsers$: Observable<(KanbanUser & { userId: { color: string } })[]>;

  /**
   * Observable that emits the list of users available for assignment.
   *
   * @type {Observable<(User & LabelValueInterface)[]>}
   */
  users$: Observable<(User & LabelValueInterface)[]>;

  /**
   * FormGroup that encapsulates the tag form controls and validators.
   * @type {FormGroup}
   */
  kanbanForm!: FormGroup;

  /**
   * Observable for the loading state of tags.
   *
   * @type {Observable<boolean>}
   */
  isTagLoading$: Observable<boolean>;

  /**
   * Boolean to know if a tag has been selected to be updated.
   *
   * @type {boolean}
   */
  toUpdate = false;

  /**
   * String containing the uuid of the tag to update.
   *
   * @type {string}
   */
  uuidUpdate = '';

  /**
   * Enum for autocomplete template types.
   * @type {typeof IceAutocompleteTemplateEnum}
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Constructor for CudKanbanTagsComponent.
   *
   * Initializes necessary services for form building, state management, kanban tag operations, and alert handling.
   *
   * @param {Store<KanbanState>} store — Provides access to the kanban state.
   * @param {FormBuilder} formBuilder — Manages form controls and validation.
   * @param {KanbanCardTagsService} kanbanCardTagsService — Service for managing kanban tag operations.
   * @param {AlertService} alertService — Service for displaying alerts.
   * @param {Store<KanbanTagsState>} tagsStore - Store for managing the kanban tags state.
   * @param {KanbanUsersService} usersService — Service for managing user operations.
   * @param {KanbansService} kanbanService — Service for managing Kanban operations.
   * @param {Router} router - Angular's Router for subscribing to and handling navigation changes.
   */
  constructor(
    private store: Store<KanbanState>,
    private formBuilder: FormBuilder,
    private kanbanCardTagsService: KanbanCardTagsService,
    private alertService: AlertService,
    private tagsStore: Store<KanbanTagsState>,
    private usersService: KanbanUsersService,
    private kanbanService: KanbansService,
    private router: Router
  ) {
    this.kanbanTags$ = this.store.select(selectKanbanTags);
    this.isTagLoading$ = this.tagsStore.select(selectTagsLoadingState);

    this.kanbanUsers$ = this.store.select(selectKanbanUsers);
    this.users$ = this.store.select(selectUsersData).pipe(
      skipWhile(items => !items),
      map(users =>
        users.map(user => ({
          ...user,
          label: user.firstname + ' ' + user.lastname,
          value: user.uuid,
          color: randomColor(),
        }))
      )
    );

    const urlSplit = this.router.url.split('/');
    if (urlSplit.length > 2 && urlSplit[2].match(isKanban)) {
      this.kanbanId = urlSplit[2];
    }
  }

  /**
   * Getter for accessing the 'name' FormControl within the tag form.
   * @type {FormControl}
   */
  get name(): FormControl {
    return this.kanbanForm.get('name') as FormControl;
  }

  /**
   * Getter for accessing the 'color' FormControl within the tag form.
   * @type {FormControl}
   */
  get color(): FormControl {
    return this.kanbanForm.get('color') as FormControl;
  }

  /**
   * Getter for accessing the 'user' FormControl within the user form.
   * @type {FormControl}
   */
  get user(): FormControl {
    return this.kanbanForm.get('user') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the form.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the tag form with default values and validators.
   * Sets up the form controls with required validators and specific constraints for each field.
   * @return {void}
   */
  initForm(): void {
    this.kanbanForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(64),
        ],
      ],
      color: ['#FFFFFF', Validators.required],
      user: [],
    });
  }

  /**
   * Resets the tag form to its default values.
   * @return {void}
   */
  resetForm(): void {
    this.kanbanForm.reset();
    this.color.patchValue('#FFFFFF');
  }

  /**
   * Handles form submission for creating a new tag.
   * Dispatches an action to create the tag and then resets the form.
   * @return {void}
   */
  onSubmit(): void {
    const info = {
      name: this.name.value,
      color: this.color.value,
      kanbanId: this.kanbanId,
    };
    if (this.toUpdate) {
      this.kanbanCardTagsService.updateTag(this.uuidUpdate, info);
      this.uuidUpdate = '';
      this.toUpdate = false;
    } else {
      this.kanbanCardTagsService.createTag(info);
    }

    this.resetForm();
  }

  /**
   * Handles the update of a tag.
   * Dispatches an action to update the tag.
   * @param {KanbanTag} tag — The tag to update.
   * @return {void}
   */
  onUpdate(tag: KanbanTag): void {
    this.color.patchValue(tag.color);
    this.name.patchValue(tag.name);
    this.toUpdate = true;
    this.uuidUpdate = tag.uuid;
  }

  /**
   * Handles the canceling of an update of a tag.
   * @returns {void}
   */
  onCancel(): void {
    this.toUpdate = false;
    this.uuidUpdate = '';
    this.resetForm();
  }

  /**
   * Handles the deletion of a tag and shows a confirmation alert.
   * Dispatches an action to delete the tag if confirmed.
   * @param {string} tagId — The ID of the tag to delete.
   * @return {void}
   */
  onDelete(tagId: string): void {
    this.alertService.showAlert('Delete Confirmation', {
      title: 'Are you sure you want to delete this tag?',
      message: 'This action cannot be undone',
      action: () => {
        this.kanbanCardTagsService.deleteTags(tagId, this.kanbanId);
      },
    });
  }

  /**
   * Searches for user suggestions based on the input query.
   *
   * @param {AutoCompleteCompleteEvent} event — The autocomplete event containing the query.
   * @return {void}
   */
  searchUsersSuggestions(event: AutoCompleteCompleteEvent): void {
    this.usersService.loadUsers(10, 0, event.query.toLowerCase());
  }

  /**
   * Unassigns a user from the Kanban board.
   *
   * @param {KanbanUser} user — The user to be unassigned.
   * @return {void}
   */
  unassignUser(user: KanbanUser): void {
    if (user.userId.uuid) {
      this.kanbanService.unassignUser(this.kanbanId, {
        userId: user.userId.uuid,
      });
    }
  }

  /**
   * Assigns a user to the Kanban board based on the selection event.
   *
   * @param {AutoCompleteSelectEvent} event — The autocomplete select event containing the selected user.
   * @return {void}
   */
  assignUser(event: AutoCompleteSelectEvent): void {
    if (event.value.uuid) {
      this.kanbanService.assignUser(this.kanbanId, {
        userId: event.value.uuid,
      });

      this.user.reset();
    }
  }
}
