import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe that truncates filenames while preserving the start and end of the filename.
 * It is designed to provide a concise but informative representation of long filenames,
 * ensuring that both the prefix and the suffix of the name are visible, which can be crucial
 * for understanding the context of the file based on naming conventions that use meaningful suffixes or extensions.
 *
 * @Pipe decorator marks this class as an Angular pipe named 'fileNameClip', enabling its use within Angular templates
 * to format file names dynamically.
 */
@Pipe({
  name: 'fileNameClip',
})
export class FileNameClipPipe implements PipeTransform {
  /**
   * Transforms a filename string by truncating it to a specified maximum length while preserving
   * the beginning and end of the string with an ellipsis in the middle.
   * This method ensures that users can still recognize the file by its start and end,
   * which often include important identifiers or file extensions.
   *
   * @param {string} value - The filename to be transformed.
   * @param {number} maxCharacters - The maximum length of the resulting string, including the ellipsis.
   * @returns {string} - The transformed filename, shortened to fit within the specified maximum length.
   */
  transform(value: string, maxCharacters: number): string {
    if (value.length <= maxCharacters) {
      return value;
    }
    return (
      value.substring(0, maxCharacters - 11) +
      '...' +
      value.substring(value.length - 8)
    );
  }
}
