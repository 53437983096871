import { createAction, props } from '@ngrx/store';
import { AllKanbansState } from '~/app/main/kanban/states/kanbans/kanbans.state';
import {
  AssignKanbanUser,
  UnassignKanbanUser,
} from '~/app/main/kanban/types/kanbans.types';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { Kanban } from '~/app/shared/interfaces/kanban/kanban.interface';

export const loadAllKanbans = createAction(
  '[Kanbans] Load All Kanbans',
  props<{ filters?: AllKanbansFilters }>()
);
export const loadAllKanbansSuccess = createAction(
  '[Kanbans] Load All Kanbans Success',
  props<{ kanbans: AllKanbansState }>()
);
export const loadAllKanbansFailure = createAction(
  '[Kanbans] Load All Kanbans Failure',
  props<{ error: string }>()
);

export const loadKanbanById = createAction(
  '[Kanbans] Load Kanban By Id',
  props<{ uuid: string }>()
);
export const loadKanbanByIdSuccess = createAction(
  '[Kanbans] Load Kanban By Id Success',
  props<{ kanban: Kanban }>()
);
export const loadKanbanByIdFailure = createAction(
  '[Kanbans] Load Kanban By Id Failure',
  props<{ error: string }>()
);

export const createKanban = createAction(
  '[Kanbans] Create Kanban',
  props<{ kanban: FormData }>()
);
export const createKanbanSuccess = createAction(
  '[Kanbans] Create Kanban Success',
  props<{ kanban: Kanban }>()
);
export const createKanbanFailure = createAction(
  '[Kanbans] Create Kanban Failure',
  props<{ error: string }>()
);

export const updateKanban = createAction(
  '[Kanbans] Update Kanban',
  props<{ uuid: string; kanban: FormData }>()
);
export const updateKanbanSuccess = createAction(
  '[Kanbans] Update Kanban Success',
  props<{ kanban: Kanban }>()
);
export const updateKanbanFailure = createAction(
  '[Kanbans] Update Kanban Failure',
  props<{ error: string }>()
);

export const deleteKanban = createAction(
  '[Kanbans] Delete Kanban',
  props<{ uuid: string }>()
);
export const deleteKanbanSuccess = createAction(
  '[Kanbans] Delete Kanban Success',
  props<{ uuid: string }>()
);
export const deleteKanbanFailure = createAction(
  '[Kanbans] Delete Kanban Failure',
  props<{ error: string }>()
);

export const assignKanbanUser = createAction(
  '[Kanbans] Assign Kanban User',
  props<{ uuid: string; assignKanbanUser: AssignKanbanUser }>()
);
export const assignKanbanUserSuccess = createAction(
  '[Kanbans] Assign Kanban User Success',
  props<{ kanban: Kanban }>()
);
export const assignKanbanUserFailure = createAction(
  '[Kanbans] Assign Kanban User Failure',
  props<{ error: string }>()
);

export const unassignKanbanUser = createAction(
  '[Kanbans] Unassign Kanban User',
  props<{ uuid: string; unassignKanbanUser: UnassignKanbanUser }>()
);
export const unassignKanbanUserSuccess = createAction(
  '[Kanbans] Unassign Kanban User Success',
  props<{ kanban: Kanban }>()
);
export const unassignKanbanUserFailure = createAction(
  '[Kanbans] Unassign Kanban User Failure',
  props<{ error: string }>()
);

export const resetKanban = createAction('[Kanbans] Reset Kanban');

export const kanbansUnauthorized = createAction(
  '[Kanbans] Unauthorized',
  props<{ error: string }>()
);
