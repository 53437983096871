import { createAction, props } from '@ngrx/store';
import {
  CreateKanbanColumn,
  UpdateKanbanColumn,
  UpdateKanbanColumnSequences,
} from '~/app/states/main/kanban/types/kanban-columns.types';
import { KanbanFilter } from '~/app/shared/interfaces/kanban/kanban-filter';
import { KanbanColumn } from '~/app/shared/interfaces/kanban/kanban-column.interface';
import { KanbanColumnsState } from '~/app/states/main/kanban/states/kanban-columns/kanban-columns.state';
import { AllKanbansFilters } from '~/app/shared/interfaces/kanban/all-kanbans-filters.interface';
import { OldSequence } from '~/app/shared/interfaces/kanban/old-sequence.interface';

/**
 * ************************************************************************
 * LOAD
 * ************************************************************************
 **/
export const loadAllKanbanColumns = createAction(
  '[Kanban Columns] Load All Kanban Columns',
  props<{ filters: KanbanFilter; reloadAll?: boolean }>()
);
export const loadAllKanbanColumnsSuccess = createAction(
  '[Kanban Columns] Load All Kanban Columns Success',
  props<{
    kanbanColumns: KanbanColumnsState;
    reloadAll?: boolean;
  }>()
);
export const loadAllKanbanColumnsFailure = createAction(
  '[Kanban Columns] Load All Kanban Columns Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * CUD
 * ************************************************************************
 **/
export const createKanbanColumn = createAction(
  '[Kanban Columns] Create Kanban Column',
  props<{ filters: AllKanbansFilters; columnData: CreateKanbanColumn }>()
);
export const createKanbanColumnSuccess = createAction(
  '[Kanban Columns] Create Kanban Column Success',
  props<{ column: KanbanColumn }>()
);
export const createKanbanColumnFailure = createAction(
  '[Kanban Columns] Create Kanban Column Failure',
  props<{ error: string }>()
);

export const updateKanbanColumn = createAction(
  '[Kanban Columns] Update Kanban Column',
  props<{ uuid: string; columnData: UpdateKanbanColumn }>()
);
export const updateKanbanColumnSuccess = createAction(
  '[Kanban Columns] Update Kanban Column Success',
  props<{ kanbanColumn: KanbanColumn }>()
);
export const updateKanbanColumnFailure = createAction(
  '[Kanban Columns] Update Kanban Column Failure',
  props<{ error: string }>()
);

export const deleteKanbanColumn = createAction(
  '[Kanban Columns] Delete Kanban Column',
  props<{ uuid: string; kanbanId: string }>()
);
export const deleteKanbanColumnSuccess = createAction(
  '[Kanban Columns] Delete Kanban Column Success',
  props<{ uuid: string }>()
);
export const deleteKanbanColumnFailure = createAction(
  '[Kanban Columns] Delete Kanban Column Failure',
  props<{ error: string }>()
);

/**
 * ************************************************************************
 * REORDER COLUMNS
 * ************************************************************************
 **/
export const updateKanbanColumnSequences = createAction(
  '[Kanban Columns] Update Kanban Column Sequences',
  props<{
    filters: AllKanbansFilters;
    columnSequencesData: UpdateKanbanColumnSequences;
    oldSequence: OldSequence[];
  }>()
);
export const updateKanbanColumnSequencesSuccess = createAction(
  '[Kanban Columns] Update Kanban Column Sequences Success',
  props<{
    columnSequencesData: UpdateKanbanColumnSequences;
  }>()
);
export const updateKanbanColumnSequencesFailure = createAction(
  '[Kanban Columns] Update Kanban Column Sequences Failure',
  props<{
    error: string;
    oldSequence: OldSequence[];
  }>()
);

/**
 * ************************************************************************
 * SSE SERVER
 * ************************************************************************
 **/
export const createKanbanColumnServer = createAction(
  '[Kanban Columns] Create Kanban Column Server',
  props<{ column: KanbanColumn }>()
);
export const updateKanbanColumnServer = createAction(
  '[Kanban Columns] Update Kanban Column Server',
  props<{ kanbanColumn: KanbanColumn }>()
);
export const deleteKanbanColumnServer = createAction(
  '[Kanban Columns] Delete Kanban Column Server',
  props<{ uuid: string }>()
);
export const updateKanbanColumnSequencesServer = createAction(
  '[Kanban Columns] Update Kanban Column Sequences Server',
  props<{
    columnSequencesData: UpdateKanbanColumnSequences;
  }>()
);

/**
 * ************************************************************************
 * UNAUTHORIZED
 * ************************************************************************
 **/
export const kanbanColumnsUnauthorized = createAction(
  '[Kanban Columns] Unauthorized',
  props<{ error: string }>()
);
