import { createReducer, on } from '@ngrx/store';
import * as OffersActions from '~/app/states/main/jobhub/states/offers/offers.actions';
import { initialOffersState } from '~/app/states/main/jobhub/states/offers/offers.state';

export const offersReducer = createReducer(
  initialOffersState,

  on(OffersActions.createOffer, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OffersActions.createOfferSuccess, (state, { offer }) => ({
    ...state,
    offer,
    loading: false,
  })),
  on(OffersActions.createOfferFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OffersActions.updateOffer, state => ({
    ...state,
    loading: true,
  })),
  on(OffersActions.updateOfferSuccess, (state, { offer }) => ({
    ...state,
    offer,
    loading: false,
  })),
  on(OffersActions.updateOfferFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(OffersActions.offersUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
