import { createReducer, on } from '@ngrx/store';
import * as SearchSaleActions from '~/app/states/main/sales/components/search-sales/states/search-sales.actions';
import { initialAllSalesState } from '~/app/states/main/sales/components/search-sales/states/search-sales.state';

export const allSearchSalesReducer = createReducer(
  initialAllSalesState,
  on(SearchSaleActions.loadAllSales, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SearchSaleActions.loadAllSalesSuccess, (state, { sales }) => ({
    ...state,
    loading: false,
    error: null,
    data: sales.data,
    count: sales.count,
    page: sales.page,
    limit: sales.limit,
    hasNext: sales.hasNext,
    hasPrevious: sales.hasPrevious,
  })),
  on(SearchSaleActions.loadAllSalesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SearchSaleActions.loadSalesByFilter, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SearchSaleActions.loadSalesByFilterSuccess, (state, { sales }) => {
    return {
      ...state,
      ...sales,
      loading: false,
      error: null,
    };
  }),
  on(SearchSaleActions.loadSalesByFilterFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SearchSaleActions.loadSearchSalesTags, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SearchSaleActions.loadSearchSalesTagsSuccess, (state, { salesTags }) => ({
    ...state,
    salesTags,
    loading: false,
  })),
  on(SearchSaleActions.loadSearchSalesTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SearchSaleActions.searchSalesUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
