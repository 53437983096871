/**
 * Contains the release notes for bug fixes as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteBugFix = [
  'Goals: Goals are now 100% live! Counts are now accurate and up-to-date.',
  'Create offer: Fixed an issue where the "Create offer" button was not working.',
];

/**
 * Contains the release notes for upgrades as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteUpgrade = [
  'Create offer form: The form is now in a modal dialog for a better user experience.',
];

/**
 * Contains the release notes for features as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteFeature = [''];
