<div class="pb-3 relative flex flex-column gap-2">
  <label [for]="formControlName" class="color-dark-80 text-sm mx-0">
    {{ label }} <span *ngIf="required" class="text-red-600">*</span>
  </label>

  <p-password
    [id]="formControlName"
    [toggleMask]="true"
    [feedback]="false"
    [placeholder]="placeholder"
    [formControl]="formControl"
    class="w-full"
    [appFormError]="formControlName"
    (formError)="onErrorMessageReceived($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [isSubmitted]="isSubmitted">
  </p-password>
  <div *ngIf="error" class="text-xs text-red-600 absolute bottom-0">
    {{ error }}
  </div>
</div>
