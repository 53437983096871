<a *ngIf="href" [target]="target" [href]="href" class="no-underline">
  <p-button
    #iceButton
    [class]="class"
    [style]="style"
    [label]="label"
    [icon]="icon"
    [type]="type"
    [disabled]="disabled"
    [iconPos]="iconPos"
    [rounded]="rounded"
    [text]="text"
    [severity]="severity"
    [plain]="plain"
    [outlined]="outlined"
    [ariaLabel]="ariaLabel"
    [styleClass]="
      fullWidth ? 'w-full flex justify-content-center md:w-fit' : ''
    "
    [loading]="loading">
    <ng-content></ng-content>
  </p-button>
</a>

<p-button
  #iceButton
  *ngIf="!href"
  [class]="class"
  [style]="style"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [disabled]="disabled"
  [iconPos]="iconPos"
  [rounded]="rounded"
  [text]="text"
  [routerLink]="routerLink"
  [severity]="severity"
  [plain]="plain"
  [outlined]="outlined"
  [ariaLabel]="ariaLabel"
  (onClick)="onClick.emit($event)"
  [styleClass]="fullWidth ? 'w-full flex justify-content-center md:w-fit' : ''"
  [loading]="loading">
  <ng-content></ng-content>
</p-button>
