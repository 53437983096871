import { createSelector } from '@ngrx/store';
import { selectShuffleListState } from '~/app/states/main/shuffle-list/shuffle-list.selector';

export const selectAllShuffleListState = createSelector(
  selectShuffleListState,
  state => state.allShuffleList
);

export const selectShuffleLists = createSelector(
  selectAllShuffleListState,
  state => state.shuffleLists
);

export const selectShuffleList = createSelector(
  selectAllShuffleListState,
  state => state.selectedShuffleList
);

export const selectRandomShuffleList = createSelector(
  selectAllShuffleListState,
  state => state.selectedRandomShuffleList
);

export const selectShuffleListLoadingState = createSelector(
  selectAllShuffleListState,
  state => state.loading
);
