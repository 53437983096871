import { createAction, props } from '@ngrx/store';

import { User } from '~/app/shared/interfaces/auth/user.interface';

export const loadUsersByKanbanCardId = createAction(
  '[Kanban Cards] Load Users By Kanban Card Id',
  props<{ kanbanCardId: string }>()
);

export const loadUsersByKanbanCardIdSuccess = createAction(
  '[Kanban Cards] Load Users By Kanban Card Id Success',
  props<{
    kanbanUsers: User[];
  }>()
);

export const loadUsersByKanbanCardIdFailure = createAction(
  '[Kanban Cards] Load Users By Kanban Card Id Failure',
  props<{ error: string }>()
);

export const kanbanCardUsersUnauthorized = createAction(
  '[Kanban Card Tasks] Unauthorized',
  props<{ error: string }>()
);
