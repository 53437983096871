import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  createOfferValidation,
  updateOfferByIdValidation,
} from '@npmicedev/icemodule/lib/validations/offers';
import { AppState } from '~/app/core/state/app.state';
import * as OffersActions from '~/app/states/main/jobhub/states/offers/offers.actions';
import {
  CreateOffer,
  UpdateOffer,
} from '~/app/states/main/jobhub/types/offers.types';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service responsible for managing offers.
 * Provides methods to create, update, and delete offers.
 *
 * @Injectable decorator provides metadata for the service, indicating that it can be injected into any Angular component or service.
 * @providedIn 'root' – Specifies that the service should be provided at the root level, making it available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class OfferService {
  /**
   * Service for handling offer-related operations.
   *
   * @param {Store<AppState>} store – The NgRx store instance for state management.
   * @param {CustomToastService} toast – Service for displaying toast notifications.
   */
  constructor(
    private store: Store<AppState>,
    private toast: CustomToastService
  ) {}

  /**
   * Creates a new offer by dispatching an action with the validated offer data.
   *
   * @param {CreateOffer} offerData – The data for the new offer.
   * @returns {void}
   */
  createOffer(offerData: CreateOffer): void {
    const result = createOfferValidation.body.safeParse(offerData);

    if (result.success) {
      this.store.dispatch(OffersActions.createOffer({ offerData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Updates an existing offer by dispatching an action with the validated offer data.
   *
   * @param {string} uuid – The UUID of the offer to update.
   * @param {UpdateOffer} offerData – The data to update the offer.
   * @returns {void}
   */
  updateOffer(uuid: string, offerData: UpdateOffer): void {
    const result = updateOfferByIdValidation.body.safeParse(offerData);

    if (result.success) {
      this.store.dispatch(OffersActions.updateOffer({ uuid, offerData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }
}
