import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BreakpointState } from '~/app/core/state/breakpoint/breakpoint.state';

export const breakpointState =
  createFeatureSelector<BreakpointState>('breakpoint');

export const selectCurrentBreakpoint = createSelector(
  breakpointState,
  state => state.currentBreakpoint
);
