import { createSelector } from '@ngrx/store';
import { selectSalesProfileState } from '~/app/main/sales/profile/sales-profile.selector';

export const selectSalesState = createSelector(
  selectSalesProfileState,
  state => state.sales
);

export const selectSales = createSelector(
  selectSalesState,
  state => state.sales
);

export const selectCreateLoadingState = createSelector(
  selectSalesState,
  state => state.loading
);
