import { createReducer, on } from '@ngrx/store';
import * as SalesProjectsActions from '~/app/states/main/sales/profile/states/sales-projects/sales-projects.actions';
import { initialSalesProjectsState } from '~/app/states/main/sales/profile/states/sales-projects/sales-projects.state';

export const salesProjectsReducer = createReducer(
  initialSalesProjectsState,
  on(SalesProjectsActions.loadSalesProjects, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SalesProjectsActions.loadSalesProjectsSuccess,
    (state, { salesProjects }) => ({
      ...state,
      salesProjects,
      loading: false,
    })
  ),
  on(SalesProjectsActions.loadSalesProjectsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SalesProjectsActions.updateAssignedTaskStatus, state => ({ ...state })),
  on(
    SalesProjectsActions.updateAssignedTaskStatusSuccess,
    (state, { task }) => ({
      ...state,
      salesProjects: state.salesProjects.map(project => ({
        ...project,
        timeline: project.timeline
          ? {
              ...project.timeline,
              timelineSales: project.timeline.timelineSales.map(
                timelineSale => ({
                  ...timelineSale,
                  timelineTaskSales: timelineSale.timelineTaskSales.map(
                    timelineTaskSale =>
                      timelineTaskSale.uuid === task.uuid
                        ? {
                            ...task,
                            timelineTask: timelineTaskSale.timelineTask,
                          }
                        : timelineTaskSale
                  ),
                })
              ),
            }
          : null,
      })),
      loading: false,
    })
  ),
  on(
    SalesProjectsActions.updateAssignedTaskStatusFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  on(SalesProjectsActions.salesProjectsUnauthorized, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
